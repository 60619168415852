import React, { Suspense } from "react";
import { Provider } from "react-redux";
import Routes from "./Router";
import store from "./store";
import { Loader } from "./components/CustomLoader";
import { useSelector } from "react-redux";
import { Center } from "@chakra-ui/react";
import useNetworkStatus from "hooks/useNetworkStatus";
import NoInternet from "pages/Others/NoInternet";
function App() {
  const status = useNetworkStatus();
  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <Center h="100vh">
            <Loader />
          </Center>
        }
      >
        {status ? <Main /> : <NoInternet />}
      </Suspense>
    </Provider>
  );
}

function Main() {
  const { loading } = useSelector((state) => state.common);
  return (
    <>
      <Routes />
      {loading && <Loader />}
    </>
  );
}

export default App;
