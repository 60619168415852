import { useQuery } from "react-query";
import api, { API_PARTIES_CREATE } from "utils/axios";

const getSingleParty = ({ queryKey }) => {
  const [_, id] = queryKey;

  if (id) {
    return api.get(`${API_PARTIES_CREATE}${id}`).then((res) => res.data);
  }
};

export function useSingleParty(id) {
  return useQuery(["singleparty", id], getSingleParty);
}
