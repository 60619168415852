import { useMutation, useQueryClient } from "react-query";
import api, { API_PRODUCTION_LIST } from "utils/axios";

const deleteProduction = (production) =>
  api.delete(`${API_PRODUCTION_LIST}${production.id}`).then((res) => res.data);

export function useDeleteProduction() {
  const queryClient = useQueryClient();
  return useMutation(deleteProduction, {
    onSuccess: () => queryClient.refetchQueries("production"),
  });
}
