import { useQuery } from "react-query";
import api, { API_VOUCHER_PRINT_SETTINGS } from "utils/axios";

const getVoucherPrintSettings = () =>
  api.get(API_VOUCHER_PRINT_SETTINGS).then((res) => res.data);

export function useVoucherPrintSettings() {
  return useQuery("voucherPrintSettings", getVoucherPrintSettings, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
