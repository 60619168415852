import { useMutation, useQueryClient } from "react-query";
import api, { API_MASTER_COMPANY_GET_EDIT_DELETE } from "utils/axios";

const updateMainSettings = (mainSettings) => {
  return api
    .patch(
      API_MASTER_COMPANY_GET_EDIT_DELETE.replace("{id}", mainSettings.id),
      mainSettings
    )
    .then((res) => res.data);
};

export function useUpdateMainSettings() {
  const queryClient = useQueryClient();
  return useMutation(updateMainSettings, {
    onSuccess: (response) => {
      queryClient.setQueriesData(
        ["mainSettings", response.data.id.toString()],
        response
      );
    },
  });
}
