import { useMutation, useQueryClient } from "react-query";
import api, { API_STORE_LIST_CREATE } from "utils/axios";

const updateStore = (store) =>
  api
    .patch(`${API_STORE_LIST_CREATE}${store.id}`, store)
    .then((res) => res.data);

export function useUpdateStore() {
  const queryClient = useQueryClient();
  return useMutation(updateStore, {
    onSuccess: () => queryClient.refetchQueries("store"),
  });
}
