import { useMutation, useQueryClient } from "react-query";
import api, { API_VOUCHER_PRINT_SETTINGS } from "utils/axios";

const createVoucherPrintSettings = (data) =>
  api.post(API_VOUCHER_PRINT_SETTINGS, data).then((res) => res.data);

export function useCreateVoucherPrintSettings() {
  const queryClient = useQueryClient();
  return useMutation(createVoucherPrintSettings, {
    onSuccess: (response) => {
      queryClient.setQueriesData(
        ["voucherPrintSettings", response.data.id],
        response
      );
      queryClient.refetchQueries("voucherPrintSettings");
    },
  });
}
