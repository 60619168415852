import { useMutation, useQueryClient } from "react-query";
import api, { API_PARTIES_GET_EDIT_DELETE } from "utils/axios";

const deleteCustomer = (customer) =>
  api
    .delete(API_PARTIES_GET_EDIT_DELETE.replace("{id}", customer.id))
    .then((res) => res.data);

export function useDeleteCustomer() {
  const queryClient = useQueryClient();
  return useMutation(deleteCustomer, {
    onSuccess: () => {
      queryClient.refetchQueries("customers");
    },
  });
}
