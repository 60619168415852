import { createSlice } from '@reduxjs/toolkit'

import api, { SALE_WITHOUT_ITEM } from '../utils/axios'
import { useDispatch, useSelector } from 'react-redux'

import { showLoading, hideLoading } from './common.slice';
const initialState = {
  data: {},
}

const salewithoutitem = createSlice({
  name: 'salewithoutitem',
  initialState,
  reducers: {
    // setLoading: (state, action) => {
    //   state.loading = action.payload.loading
    // },
    setData: (state, action) => {
      state.data = action.payload.data
    },
    // setErrorMessage: (state, action) => {
    //   state.error = action.payload.message
    // }
  }
})

export const {
  setData,
} = salewithoutitem.actions
export default salewithoutitem.reducer

export const getSaleWithoutItem = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    const response = await api.get(`${SALE_WITHOUT_ITEM}${id}`)
    if (response?.data?.status === "success") {
      dispatch(setData({ data: response.data.data }))
    }
    dispatch(hideLoading())
    return response
  } catch (error) {
    dispatch(hideLoading())
  }
}

export const addSaleWithoutItem = ({ data }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    const response = await api.post(SALE_WITHOUT_ITEM, data)
    if (response?.data?.status === "success") {
      // dispatch(setData({ data: response.data.data }))
    }
    dispatch(hideLoading())
    return response
  } catch (error) {
    dispatch(hideLoading())
  }
}

export const editSaleWithoutItem = ({ data, id }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    const response = await api.patch(`${SALE_WITHOUT_ITEM}${id}`, data)
    if (response?.data?.status === "success") {
      // dispatch(setData({ data: response.data.data }))
    }
    dispatch(hideLoading())
    return response
  } catch (error) {
    dispatch(hideLoading())
  }
}
