import { useQuery } from "react-query";
import api, { API_STORE_LIST_CREATE } from "utils/axios";

const getStore = () => api.get(API_STORE_LIST_CREATE).then((res) => res.data);

export function useStore() {
  return useQuery("store", getStore, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
