import { useQuery } from "react-query";
import api, { API_ITEM_COSTCENTRE_LIST_CREATE } from "utils/axios";

const getCostCenters = ({ queryKey }) => {
  const [_, data] = queryKey;

  let url = API_ITEM_COSTCENTRE_LIST_CREATE;
  if (data?.isSuspended && data?.isSuspended !== "") {
    url = url + `?suspened=${data.isSuspended}`;
  }
  return api.get(url).then((res) => res.data);
};

export function useCostCenters(data) {
  return useQuery(["costcenters", data], getCostCenters, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
