export const cashInHandGroupId = "6eee1850-afb8-4d5b-98ad-a95cacf32d12"; //'ea46bab0-ed52-4f01-bbc2-4cdafbf6eeb4'
export const bankAccountGroupId = "34330030-1779-421e-a82f-9eb11a5a28fb";
export const BE_DATE_FORMAT = "YYYY-MM-DD";
export const FE_DATE_FORMAT = "DD/MM/YYYY";

export const Transactions = Object.freeze({
  Sale: "Sale",
  DeliveryOrder: "Delivery order",
  Purchase: "Purchase",
  Estimate: "Estimate",
  SaleReturn: "Sale Return",
  PurchaseReturn: "Purchase Return",
  Expense: "Expense",
  Receipt: "Sale Receipt",
  PurchaseReturnReceipt: "Purchase Return Receipt",
  OtherReceipt: "Other Receipt",
  PurchasePayment: "Purchase Payment",
  ExpensePayment: "Expense Payment",
  SaleReturnPayment: "Sale Return Payment",
  OtherPayment: "Other Payment",
  Contra: "Contra",
  CustomerSetOff: "Customer Set Off",
  SupplierSetOff: "Supplier Set Off",
  JournalEntry: "Journal Entry",
  StockJournal: "Stock Journal",
  StoreTransfer: "Store Transfer",
});

export const CustomFields = Object.freeze({
  CostCenter: "Cost center",
  DoneBy: "Done by",
  TaxType: "Tax type",
  BuyersRef: "Buyers ref",
  SuplierRef: "Supplier ref",
  PriceCategory: "Price category",
  ModeOfPayment: "Mode of payment",
  PlaceOfSupply: "Place of supply",
  CreditTerms: "Credit terms",
  VoucherNumber: "Voucher number",
  Store: "Store",
});

export const RequiredFields = Object.freeze({
  CostCenter: "Cost center",
  DoneBy: "Done by",
  TaxType: "Tax type",
  ModeOfPayment: "Mode of payment",
  PlaceOfSupply: "Place of supply",
});

export const Reports = Object.freeze({
  Sales: "Sales",
  DeliveryOrder: "Delivery order",
  Purchase: "Purchase",
  Estimate: "Estimate",
  SalesReturn: "Sales Return",
  PurchaseReturn: "Purchase Return",
  Expense: "Expense",
  Receipt: "Sale Receipt",
  PurchaseReturnReceipt: "Purchase Return Receipt",
  OtherReceipt: "Other Receipt",
  PurchasePayment: "Purchase Payment",
  ExpensePayment: "Expense Payment",
  SaleReturnPayment: "Sale Return Payment",
  OtherPayment: "Other Payment",
  Contra: "Contra",
  CustomerSetOff: "Customer Set Off",
  SupplierSetOff: "Supplier Set Off",
  JournalEntry: "Journal Entry",
  StockJournal: "Stock Journal",
  StoreTransfer: "Store Transfer",
  MasterGroup: "Master Group",
  CustomerBalance: "Customer Balance",
  SupplierBalance: "Supplier Balance",
  PDCManagement: "PDC Management",
  SalesEstimate: "Sales Estimate",
  GoodsIssueOrder: "Goods Issue Order",
  ProformaInvoice: "Proforma Invoice",
  RetainerInvoice: "Retainer Invoice",
  SalesOrder: "Sales Order",
  GoodsReceiptNote: "Goods Receipt Note",
  PurchaseOrder: "PurchaseOrder",
  PurchaseRequisition: "Purchase Requisition",
  ConsumptionIssue: "Consumption Issue",
  StockHold: "Stock Hold",
  ComplimentIssue: "Compliment Issue",
});
export const TransactionSettings = Object.freeze({
  Sale: "SALE",
  DeliveryOrder: "DELIVERY_ORDER",
  Purchase: "PURCHASE",
  SaleReturn: "SALE_RETURN",
  PurchaseReturn: "PURCHASE_RETURN",
  Expense: "EXPENSE",
  Receipt: "RECEIPT_AGAINST_INVOICE",
  PurchaseReturnReceipt: "PURCHASE_RETURN_RECEIPT",
  OtherReceipt: "OTHER_RECEIPT",
  PurchasePayment: "PURCHASE_PAYMENT",
  ExpensePayment: "EXPENSE_PAYMENT",
  SaleReturnPayment: "SALE_RETURN_PAYMENT",
  OtherPayment: "OTHER_PAYMENT",
  Contra: "CONTRA",
  CustomerSetOff: "CUSTOMER_SETOFF",
  SupplierSetOff: "SUPPLIER_SETOFF",
  JournalEntry: "JOURNAL_ENTRY",
  StockJournal: "STOCK_JOURNAL",
  StoreTransfer: "STORE_TRANSFER",
  SaleEstimate: "SALE_ESTIMATE",
  GoodsReceiptNote: "GOODS_RECEIPT_NOTE",
  GoodsIssueOrder: "GOODS_ISSUE_ORDER",
  ProformaInvoice: "PROFORMA_INVOICE",
  PurchaseOrder: "PURCHASE_ORDER",
  PurchaseRequisition: "PURCHASE_REQUISITION",
  RetainerInvoice: "RETAINER_INVOICE",
  SalesOrder: "SALES_ORDER",
  ConsumptionIssue: "CONSUMPTION_ISSUE",
  StockHold: "STOCK_HOLD",
  ComplimentIssue: "COMPLIMENT_ISSUE",
});

export const TransactionTypes = Object.freeze({
  SL: "sales",
  PAE: "purchaseAndExpense",
  OTH: "other",
  ST: "stockTransaction",
});
