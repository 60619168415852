import {
  RequiredFields,
  CustomFields,
  TransactionSettings,
} from "utils/utils/constant";
import { useTransactionSettings } from "hooks/TransactionSettings/useTransactionSettings";
import { useEffect, useState } from "react";

export const useTransactionsSettings = (transaction) => {
  const { data: transactionSettingsData } = useTransactionSettings();

  const [settings, setSettings] = useState({});

  useEffect(() => {
    const transactionSettings = transactionSettingsData?.data?.find(
      (item) => item.transaction_type === transaction
    );
    if (transactionSettings) {
      setSettings(transactionSettings);
      updateCredit(transactionSettings.always_active_credit);
      let value = getCreditEnabled();
    }
  }, [transactionSettingsData]);

  const getRequiredFields = () => {
    const requiredFields = Object.entries(RequiredFields).map(
      ([key, value]) => ({
        name: value,
        required: false,
        name_key: getApiRequiredFieldKeyByFieldName(value),
      })
    );
    if (
      [
        TransactionSettings.Receipt,
        TransactionSettings.PurchaseReturnReceipt,
        TransactionSettings.OtherReceipt,
        TransactionSettings.PurchasePayment,
        TransactionSettings.ExpensePayment,
        TransactionSettings.SaleReturnPayment,
        TransactionSettings.OtherPayment,
        TransactionSettings.CustomerSetOff,
        TransactionSettings.SupplierSetOff,
        TransactionSettings.JournalEntry,
        TransactionSettings.StockJournal,
        TransactionSettings.StoreTransfer,
        TransactionSettings.Contra,
      ].includes(transaction)
    ) {
      return requiredFields.filter((field) =>
        [RequiredFields.CostCenter, RequiredFields.DoneBy].includes(field.name)
      );
    } else return requiredFields;
  };
  const getCustomFields = () => {
    const customFields = Object.entries(CustomFields).map(
      ([key, value], index) => ({
        id: null,
        name: value,
        isInBaseTab: false,
        isHide: true,
        order: null,
        name_key: getApiCustomFieldKeyByFieldName(value),
      })
    );

    if (
      [
        TransactionSettings.Receipt,
        TransactionSettings.PurchaseReturnReceipt,
        TransactionSettings.OtherReceipt,
        TransactionSettings.PurchasePayment,
        TransactionSettings.ExpensePayment,
        TransactionSettings.SaleReturnPayment,
        TransactionSettings.OtherPayment,
        TransactionSettings.CustomerSetOff,
        TransactionSettings.SupplierSetOff,
        TransactionSettings.JournalEntry,
        TransactionSettings.StockJournal,
        TransactionSettings.StoreTransfer,
        TransactionSettings.Contra,
      ].includes(transaction)
    ) {
      return customFields.filter((field) =>
        [CustomFields.CostCenter, CustomFields.DoneBy].includes(field.name)
      );
    } else if (
      [
        TransactionSettings.GoodsReceiptNote,
        TransactionSettings.GoodsIssueOrder,
        TransactionSettings.ProformaInvoice,
        TransactionSettings.PurchaseOrder,
        TransactionSettings.PurchaseRequisition,
        TransactionSettings.RetainerInvoice,
        TransactionSettings.SalesOrder,
        TransactionSettings.ConsumptionIssue,
        TransactionSettings.StockHold,
        TransactionSettings.ComplimentIssue,
        TransactionSettings.DeliveryOrder,
        TransactionSettings.SaleEstimate,
      ].includes(transaction)
    ) {
      return customFields.filter(
        (field) =>
          ![CustomFields.SuplierRef, CustomFields.VoucherNumber].includes(
            field.name
          )
      );
    } else if (transaction === TransactionSettings.Expense) {
      return customFields.filter(
        (field) =>
          ![CustomFields.BuyersRef, CustomFields.CreditTerms].includes(
            field.name,
            CustomFields.Store
          )
      );
    } else if (transaction === TransactionSettings.Purchase) {
      return customFields.filter(
        (field) =>
          ![CustomFields.BuyersRef, CustomFields.CreditTerms].includes(
            field.name
          )
      );
    } else if (
      [TransactionSettings.Sale, TransactionSettings.SaleReturn].includes(
        transaction
      )
    ) {
      return customFields.filter(
        (field) =>
          ![
            CustomFields.SuplierRef,
            CustomFields.VoucherNumber,
            CustomFields.CreditTerms,
          ].includes(field.name)
      );
    } else if (TransactionSettings.PurchaseReturn === transaction) {
      return customFields.filter(
        (field) =>
          ![
            CustomFields.BuyersRef,
            CustomFields.CreditTerms,
            CustomFields.VoucherNumber,
          ].includes(field.name)
      );
    }
  };

  const updateCredit = (isCredit) => {
    localStorage.setItem(`${transaction}_isCredit`, isCredit);
  };

  const getCreditEnabled = () => {
    let data = localStorage.getItem(`${transaction}_isCredit`);
    return data == "true";
  };
  const isFieldRequired = (field) => {
    const key = getApiRequiredFieldKeyByFieldName(field);
    return settings[key];
  };
  const getApiCustomFieldKeyByFieldName = (field) => {
    switch (field) {
      case CustomFields.CostCenter:
        return "cost_centre";
      case CustomFields.DoneBy:
        return "doneby";
      case CustomFields.TaxType:
        return "tax_type";
      case CustomFields.BuyersRef:
        return "buyers_reference";
      case CustomFields.SuplierRef:
        return "suppliers_reference";
      case CustomFields.PriceCategory:
        return "price_category";
      case CustomFields.ModeOfPayment:
        return "mode_of_payment";
      case CustomFields.PlaceOfSupply:
        return "place_of_supply";
      case CustomFields.CreditTerms:
        return "credit_term";
      case CustomFields.VoucherNumber:
        return "voucher_no";
      case CustomFields.Store:
        return "store";
    }
  };
  const getApiRequiredFieldKeyByFieldName = (field) => {
    switch (field) {
      case RequiredFields.CostCenter:
        return "make_cost_center_is_compulsory";
      case RequiredFields.DoneBy:
        return "make_done_by_is_compulsory";
      case RequiredFields.TaxType:
        return "make_tax_type_is_compulsory";
      case RequiredFields.ModeOfPayment:
        return "make_mode_of_payment_is_compulsory";
      case RequiredFields.PlaceOfSupply:
        return "make_place_of_supply_is_compulsory";
    }
  };

  return {
    isFieldRequired,
    getRequiredFields,
    getCustomFields,
    getCreditEnabled,
  };
};
