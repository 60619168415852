import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import { v4 as uuidv4 } from "uuid";
import { useToast } from "@chakra-ui/react";

import Form from "../Form";
import RenderComponent from "./RenderComponent";

import { useLedgerGroups } from "hooks/LedgerGroup/useLedgerGroups";
import { useCreateLedgerGroups } from "hooks/LedgerGroup/useCreateLedgerGroups";
import { useUpdateLedgerGroups } from "hooks/LedgerGroup/useUpdateLedgerGroups";
import { getLocalStore } from "utils/utils";
import { useCostCenters } from "hooks/CostCenter/useCostCenters";
import { useDoneBy } from "hooks/DoneBy/useDoneBy";

const AddLedgerGroup = (props) => {
  const {
    type = "add",
    editValue,
    onAddSuccess,
    isApproval = false,
    rejectFunc,
    isRejectLoading = false,
  } = props;
  const [isToggledFav, setIsToggledFav] = useState(false);
  const [suspand, setSuspand] = useState(false);
  const auth = getLocalStore("AUTH_DETAILS");
  // const { type, editValue, changeType, listData, clearSearch, onSuccess } =
  //   props;

  const toast = useToast();
  const { data: ledgerGroupData } = useLedgerGroups();
  const { data: costCenterData } = useCostCenters();
  const { data: doneByData } = useDoneBy();

  const {
    mutate: createLedgerGroups,
    data,
    isSuccess,
    isLoading: isCreateLoading,
  } = useCreateLedgerGroups();
  const {
    mutate: updateLedgerGroups,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useUpdateLedgerGroups();

  const initialValue = {
    name: "",
    master: "",
    is_suspended: suspand,
    favourites: isToggledFav,
  };

  const [formValues, setformValues] = useState(initialValue);
  const [listData, setlistData] = useState([]);
  const [sortedListData, setSortedListData] = useState(null);
  const [costCenter, setCostCenter] = useState([]);
  const [doneBy, setDoneBy] = useState([]);

  const handleFavourite = () => {
    setIsToggledFav(!isToggledFav);
  };

  useEffect(() => {
    if (isSuccess) {
      if (data?.status === "success") {
        toastWithMessage("Ledger group created successfully");
        onAddSuccess(data?.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Ledger group updated successfully");
        onAddSuccess(updateResponse?.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (type === "add") {
      setformValues(initialValue);
      if (costCenterData && doneByData) {
        setCostCenter(
          costCenterData.data?.filter((i) =>
            [
              getLocalStore("DEFAULT_SETTINGS_DATA")?.default_cost_centre,
            ]?.includes(i.id)
          ) || []
        );
        setDoneBy(
          doneByData.data?.filter((i) =>
            [getLocalStore("DEFAULT_SETTINGS_DATA")?.default_done_by]?.includes(
              i.id
            )
          ) || []
        );
      }
    } else {
      setformValues(editValue);
      setIsToggledFav(editValue.favourites);
      setSuspand(editValue.is_suspended);
      if (costCenterData && doneByData) {
        setCostCenter(
          costCenterData.data?.filter((i) =>
            editValue.extras?.cost_centres?.includes(i.id)
          ) || []
        );
        setDoneBy(
          doneByData.data?.filter((i) =>
            editValue.extras?.done_bys?.includes(i.id)
          ) || []
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, editValue, doneByData, costCenterData]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  useEffect(() => {
    if (type === "add") setformValues(initialValue);
    else {
      const editObj = listData?.filter(
        (item) => item.name === editValue?.under
      )[0];
      if (editValue.master) {
        setformValues({
          ...editValue,
          master: editObj?.id,
        });
      } else {
        setformValues({ ...editValue, master: "Master Group" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, editValue, listData]);

  useEffect(() => {
    let groups = ledgerGroupData?.data
      ?.map((group) => {
        let under = ledgerGroupData?.data.find(
          (item) => item.uuid === group.master
        );
        let master = ledgerGroupData?.data.find(
          (item) => item.uuid === group.super_parent
        );
        return { ...group, under: under?.name, master: master?.name };
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    setlistData(groups);
    setSortedListData(groups);
  }, [ledgerGroupData]);

  return (
    <Form
      initialValues={{ ...formValues }}
      RenderComponent={RenderComponent}
      handleOnSubmit={(fields) => {
        fields.is_suspended = suspand;
        fields.favourites = isToggledFav;
        const extrasData = {
          cost_centres: costCenter?.map((i) => i.id),
          done_bys: doneBy?.map((i) => i.id),
        };
        fields.extras = extrasData;
        if (type === "edit") {
          if (isApproval) {
            fields.approved_by = auth?.data?.id;
          } else {
            delete fields.approved_by;
          }
          fields.master = parseInt(fields.master);
          updateLedgerGroups(fields);
        } else {
          fields.uuid = uuidv4();
          fields.master = parseInt(fields.master);
          createLedgerGroups(fields);
        }
      }}
      customProps={{
        type: type,
        listData: sortedListData,
        costCenter,
        setCostCenter,
        doneBy,
        setDoneBy,
        isLoading: isCreateLoading || isUpdateLoading,
        isSubmitted: isSuccess || isUpdateSuccess,
        handleFavourite,
        setSuspand,
        isToggledFav,
        suspand,
        isApproval,
        rejectFunc,
        isRejectLoading,
        doneByData,
        costCenterData,
      }}
      validationSchema={object().shape({
        name: string().required("Name Required"),
        master: string().required("Group Required"),
      })}
    />
  );
};

export default AddLedgerGroup;
