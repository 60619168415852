import React, { useState } from "react";
import { object } from "yup";
import { Formik } from "formik";

const Form = ({ initialValues, validationSchema, ...rest }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema || object().shape({})}
      enableReinitialize
      onSubmit={() => {}}
    >
      {(props) => {
        return <FormComponent {...props} {...rest} />;
      }}
    </Formik>
  );
};

export default Form;

const FormComponent = ({
  initialValues,
  errors,
  validateForm,
  values,
  handleOnSubmit,
  setFieldValue,
  setValues,
  RenderComponent,
  className,
  customProps,
  handleOnCancel,
  Styled,
  resetForm,
  ...rest
}) => {
  const [isNotValid, setIsNotvalid] = useState(true);
  const handleEdit = (event, source, isToggleField = false) => {
    let value = event;
    if (isToggleField && value == false) {
      setFieldValue(source?.field, false);
      return;
    }
    if (value) {
      setFieldValue(source?.field, value);
    } else {
      setFieldValue(source?.field, null);
    }
  };

  const getFieldValues = (source) => {
    const result = {};
    Object.keys(source).map((key) => {
      return (result[key] = source[key]);
    });
    return result;
  };
  return (
    <>
      <RenderComponent
        values={values}
        handleEdit={handleEdit}
        errors={errors}
        isNotValid={isNotValid}
        validateForm={validateForm}
        {...rest}
        onClear={() => {
          setValues({ ...initialValues });
        }}
        cancelAction={(val) => {
          val
            ? setValues({ ...values, ...val })
            : setValues({ ...initialValues });
        }}
        customProps={customProps}
        submitAction={(data) => {
          if (Object.keys(data).length === 0) {
            setIsNotvalid(true);
            handleOnSubmit(getFieldValues(values));
          } else {
            setIsNotvalid(false);
          }
        }}
      />
    </>
  );
};
