import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
  Text,
  Grid,
  GridItem,
  FormControl,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import StoreSelectionField from "components/StoreSelectionField";
import { useItemUnit } from "hooks/ItemUnit/useItemUnit";
import { useSingleMultiUnitPrice } from "hooks/MultiUnitPricing/useSingleMultiUnitPrice";
import { useDecimalLength } from "hooks/useDecimalLength";
import React, { useEffect, useRef, useState } from "react";
import { getLocalStore } from "utils/utils";

export default function EditProductionItemModal(props) {
  const {
    initialValue,
    onSubmit,
    finalFocusRef,
    isOpen,
    onClose,
    isCentered,
    scrollBehaviour,
    transactionType,
    isMultiStore,
  } = props;

  const hideStoreInAmountPopup = getLocalStore("HIDE_STORE_IN_AMOUNT_POPUP");
  const toast = useToast();
  const [decimalLength] = useDecimalLength(2);
  const quantityRef = useRef();
  const unitRef = useRef();
  const rateRef = useRef();
  const storeRef = useRef();
  const descRef = useRef();

  const [item, setItem] = useState({});
  const [multiUnit, setMultiUnit] = useState("");
  const [multiUnitPricing, setMultiUnitPricing] = useState([]);
  const [baseUnitPricing, setBaseUnitPricing] = useState(null);
  const { data: unitData } = useItemUnit();
  const { data: singleMultiUnitPrice } = useSingleMultiUnitPrice(
    initialValue?.secondary_units && initialValue?.secondary_units.length > 0
      ? initialValue?.item
      : null
  );

  useEffect(() => {
    if (
      unitData &&
      singleMultiUnitPrice &&
      singleMultiUnitPrice?.data?.secondary_unit_pricing?.length > 0
    ) {
      const baseUnitId = unitData?.data?.find(
        (unit) => unit.unit === singleMultiUnitPrice?.data?.unit?.unit
      )?.id;
      setMultiUnitPricing(singleMultiUnitPrice?.data?.secondary_unit_pricing);
      setBaseUnitPricing({
        ...singleMultiUnitPrice?.data?.unit,
        unit_id: baseUnitId,
      });
    } else {
      setMultiUnitPricing([]);
      setBaseUnitPricing(null);
    }
  }, [singleMultiUnitPrice, unitData]);

  useEffect(() => {
    if (initialValue) {
      let storeID = "";
      if (isMultiStore) {
        if (isMultiStore !== "multiStore") {
          storeID = isMultiStore;
        } else if (initialValue?.store) {
          storeID = initialValue?.store;
        }
      } else if (initialValue?.store) {
        storeID = initialValue?.store;
      }

      let secondaryUnits = [];
      if (
        initialValue?.secondary_units &&
        initialValue?.secondary_units.length > 0
      ) {
        initialValue.secondary_units.push({
          id: null,
          unit: initialValue.unit,
          rate: "",
        });
        const inputArray = initialValue.secondary_units.filter(
          (item, index, self) =>
            index === self.findIndex((i) => i.unit === item.unit)
        );
        secondaryUnits = inputArray.map((unit) => {
          const actualData = unitData?.data?.find(
            (data) => data.id === unit.unit
          );
          return { ...unit, name: actualData?.unit_symbol };
        });

        if (initialValue?.secondaryUnit) {
          setMultiUnit(
            secondaryUnits.find(
              (item) => item.unit === initialValue.secondaryUnit
            )
          );
        } else {
          setMultiUnit(secondaryUnits[secondaryUnits.length - 1]);
        }
      }

      setItem({
        quantity: 1,
        ...initialValue,
        rate: initialValue.rate
          ? _parseFloat(initialValue.rate).toFixed(decimalLength)
          : _parseFloat(initialValue.price).toFixed(decimalLength),
        store: transactionType !== "production" ? storeID : null,
        secondary_units: secondaryUnits || [],
      });
    }
  }, [isOpen, initialValue]);

  const submit = () => {
    if (transactionType !== "production" && !item.store) {
      return toast({
        title: "Store Is Required",
        status: "warning",
        duration: 2000,
        isClosable: true,
      });
    }
    let totalAmount = item.rate * item.quantity;
    item.amount = _parseFloat(totalAmount).toFixed(decimalLength);
    onSubmit({ ...item });
  };

  const onCloseModal = () => {
    setItem({});
    onClose();
  };

  const checkDecimal = (value) => {
    let decimals = value?.toString()?.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      let decimalValue = _parseFloat(value).toFixed(decimalLength);
      return decimalValue;
    } else {
      return value;
    }
  };

  const handleMultiUnitPricing = (unitId, secondaryUnit) => {
    if (multiUnitPricing.length === 0) {
      setItem({
        ...item,
        secondaryUnit: secondaryUnit,
      });
    } else {
      if (unitId) {
        const findUnit = multiUnitPricing.find((f) => f.unit_id === unitId);
        if (findUnit) {
          setItem({
            ...item,
            secondaryUnit: secondaryUnit,
            rate: _parseFloat(findUnit.purchase_price_without_tax).toFixed(
              decimalLength
            ),
            amount: _parseFloat(
              findUnit.purchase_price_without_tax * item.quantity
            ).toFixed(decimalLength),
          });
        } else {
          setItem({
            ...item,
            secondaryUnit: secondaryUnit,
            rate: _parseFloat(
              baseUnitPricing.purchase_price_without_tax * item.quantity
            ).toFixed(decimalLength),
          });
        }
      } else if (baseUnitPricing?.unit_id === secondaryUnit) {
        setItem({
          ...item,
          secondaryUnit: secondaryUnit,
          rate: _parseFloat(
            baseUnitPricing.purchase_price_without_tax * item.quantity
          ).toFixed(decimalLength),
        });
      } else {
        setItem({
          ...item,
          secondaryUnit: secondaryUnit,
        });
      }
    }
  };

  return (
    <Modal
      finalFocusRef={finalFocusRef}
      isOpen={isOpen}
      onClose={onCloseModal}
      isCentered={isCentered}
      scrollBehavior={scrollBehaviour}
      initialFocusRef={quantityRef}
    >
      <ModalOverlay />
      <ModalContent maxW="30rem">
        <ModalHeader>
          <Box p={2}>
            <Text size="md" textColor="brand.bg_blue">
              {item.name}
            </Text>
            <Text fontSize="sm">{item.name_alias}</Text>
            <Text fontSize="sm">
              Item Code: {item.item_code} &nbsp;&nbsp;Bar Code: {item.bar_code}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box bg="white" p="0" py="2" rounded="sm" shadow="sm">
            <Grid templateColumns="repeat(2, 1fr)" gap={(0, 4)}>
              <GridItem colSpan={1}>
                <FormControl id="qty">
                  <TextField
                    label="Quantity"
                    variant="outlined"
                    size="small"
                    type="number"
                    name="quantity"
                    value={item.quantity}
                    inputRef={quantityRef}
                    onChange={(event) => {
                      setItem((item) => ({
                        ...item,
                        quantity: checkDecimal(event.target.value),
                      }));
                    }}
                    onFocus={(e) => {
                      if (e) {
                        e.target.select();
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        rateRef.current?.focus();
                      }
                    }}
                  />
                </FormControl>
              </GridItem>
              {!(item.secondary_units && item.secondary_units.length > 0) ? (
                <GridItem colSpan={1}>
                  <FormControl id="trn">
                    <TextField
                      label="Unit"
                      variant="outlined"
                      size="small"
                      type="text"
                      value={item.unit_data?.unit_symbol}
                      disabled
                    />
                  </FormControl>
                </GridItem>
              ) : (
                <GridItem colSpan={1}>
                  <Autocomplete
                    disablePortal={true}
                    value={multiUnit || ""}
                    options={item.secondary_units}
                    getOptionLabel={(option) => option.name || ""}
                    size="small"
                    onChange={(event, value) => {
                      setMultiUnit(value);
                      // setItem({
                      //   ...item,
                      //   secondaryUnit: value?.unit,
                      //   unit: value?.unit,
                      //   unit_data: unitData?.data?.find(
                      //     (f) => f.id === value?.unit
                      //   ),
                      // });
                      handleMultiUnitPricing(value?.id, value?.unit);
                    }}
                    freeSolo
                    clearOnBlur
                    renderOption={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Unit"
                        variant="outlined"
                        onFocus={(e) => {
                          if (e) {
                            e.target.select();
                          }
                        }}
                        inputRef={unitRef}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            rateRef.current?.focus();
                          }
                        }}
                      />
                    )}
                  />
                </GridItem>
              )}
              <GridItem colSpan={1}>
                <FormControl id="rate">
                  <TextField
                    label="Rate"
                    variant="outlined"
                    size="small"
                    type="number"
                    name="rate"
                    value={item.rate}
                    onChange={(event) => {
                      setItem((item) => ({
                        ...item,
                        rate: event.target.value,
                      }));
                    }}
                    onFocus={(e) => {
                      if (e) {
                        e.target.select();
                      }
                    }}
                    inputRef={rateRef}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (
                          !hideStoreInAmountPopup?.includes("on") &&
                          !isMultiStore
                        ) {
                          storeRef.current?.focus();
                        } else if (isMultiStore === "multiStore") {
                          storeRef.current?.focus();
                        } else {
                          descRef.current?.focus();
                        }
                      }
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl id="amount">
                  <TextField
                    label="Amount"
                    variant="outlined"
                    size="small"
                    type="number"
                    name="amount"
                    disabled
                    value={_parseFloat(item.quantity * item.rate).toFixed(
                      decimalLength
                    )}
                  />
                </FormControl>
              </GridItem>
              {transactionType !== "production" &&
                !hideStoreInAmountPopup?.includes("on") && (
                  <GridItem colSpan={2}>
                    <StoreSelectionField
                      disabled={
                        isMultiStore && isMultiStore !== "multiStore"
                          ? true
                          : false
                      }
                      inputRef={storeRef}
                      store={item.store}
                      setStore={(store) => {
                        setItem((item) => ({
                          ...item,
                          store: store,
                        }));
                      }}
                      onFocus={(e) => {
                        if (e) {
                          e.target.select();
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          descRef.current?.focus();
                        }
                      }}
                    />
                  </GridItem>
                )}
              <GridItem colSpan={2}>
                <FormControl id="desc">
                  <Textarea
                    placeholder="Description"
                    type="text"
                    value={item.description}
                    onChange={(event) => {
                      setItem((item) => ({
                        ...item,
                        description: event.target.value,
                      }));
                    }}
                    onFocus={(e) => {
                      if (e) {
                        e.target.select();
                      }
                    }}
                    ref={descRef}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        submit();
                      }
                    }}
                  />
                </FormControl>
              </GridItem>
            </Grid>
            <Grid marginTop={3} templateColumns="repeat(2, 1fr)" gap={2}>
              <GridItem colSpan={1}>
                <FormControl id="cancel">
                  <Button
                    w="100%"
                    colorScheme="blue"
                    variant="outline"
                    onClick={onCloseModal}
                  >
                    Cancel
                  </Button>
                </FormControl>
              </GridItem>
              <GridItem colSpan={1}>
                <FormControl id="submit">
                  <Button
                    w="100%"
                    p={0}
                    colorScheme="blue"
                    textColor="white"
                    variant="solid"
                    onClick={submit}
                    //   disabled={formErrors}
                  >
                    Add
                  </Button>
                </FormControl>
              </GridItem>
            </Grid>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const _parseFloat = (num) => {
  return parseFloat(num || 0);
};
