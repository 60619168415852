import { useMutation, useQueryClient } from "react-query";
import api, { API_ITEM_COSTCENTRE_LIST_CREATE } from "utils/axios";

const createCostCenter = (costCenter) =>
  api.post(API_ITEM_COSTCENTRE_LIST_CREATE, costCenter).then((res) => res.data);

export function useCreateCostCenter() {
  const queryClient = useQueryClient();
  return useMutation(createCostCenter, {
    onSuccess: () => queryClient.refetchQueries("costcenters"),
  });
}
