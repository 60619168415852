import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import api, { API_LOGOUT } from "utils/axios";
import { clearLocalStore } from "utils/utils";

const performLogout = async () => {
  return api.delete(API_LOGOUT).then((res) => res?.data);
};

export function useLogout() {
  const history = useHistory();

  return useMutation(performLogout, {
    onSuccess: async () => {
      clearLocalStore();
      history.push("/");
    },
    onError: (error) => {
      console.error("Logout failed:", error);
    },
  });
}
