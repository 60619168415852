import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import { useDisclosure, useToast } from "@chakra-ui/react";
import Form from "../Form";
import RenderComponent from "./RenderComponent";
import { useCreateProduction } from "hooks/Production/useCreateProduction";
import { useUpdateProduction } from "hooks/Production/useUpdateProduction";
import { useItems } from "hooks/Item/useItems";
import { useLedgers } from "hooks/Ledger/useLedgers";
import { useDecimalLength } from "hooks/useDecimalLength";
import { getLocalStore } from "utils/utils";
import { useHistory, useLocation } from "react-router-dom";
import { useItemUnit } from "hooks/ItemUnit/useItemUnit";
import { useDoneBy } from "hooks/DoneBy/useDoneBy";
import { useCostCenters } from "hooks/CostCenter/useCostCenters";

const AddProduction = (props) => {
  const {
    // type,
    changeType,
    searchData,
    // editValue,
    isApproval = false,
    rejectFunc,
    isRejectLoading = false,
  } = props;
  const auth = getLocalStore("AUTH_DETAILS");
  const [suspand, setSuspand] = useState(false);
  const toast = useToast();
  const [decimalLength] = useDecimalLength();
  const editItemModal = useDisclosure();
  const { data: itemsData } = useItems();
  const { data: ledgersData } = useLedgers();
  const history = useHistory();
  const { data: doneByData } = useDoneBy();
  const { data: costCenterData } = useCostCenters();
  const [costCenters, setCostCenters] = useState([]);
  const [doneBys, setDoneBys] = useState([]);

  const location = useLocation();
  const editValue = location.state?.editValue;
  const type = location.state?.type;

  const {
    mutate: createProduction,
    data,
    isSuccess,
    isLoading: isCreateLoading,
  } = useCreateProduction();

  const {
    mutate: updateProduction,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useUpdateProduction();

  const initialValue = {
    id: editValue?.id !== null ? editValue?.id : null,
    name: editValue?.name !== "" ? editValue?.name : "",
    is_suspended: suspand,
    raw_items: [],
    products: [],
    cost_centre: getLocalStore("DEFAULT_SETTINGS_DATA")?.default_cost_centre
      ? [getLocalStore("DEFAULT_SETTINGS_DATA")?.default_cost_centre]
      : [],
    done_by: getLocalStore("DEFAULT_SETTINGS_DATA")?.default_done_by
      ? [getLocalStore("DEFAULT_SETTINGS_DATA")?.default_done_by]
      : [],
  };

  useEffect(() => {
    if (isSuccess) {
      if (data?.status === "success") {
        toastWithMessage("Production created successfully");
        history.push({
          pathname: `/add-production-list`,
        });
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Production updated successfully");
        history.push({
          pathname: `/add-production-list`,
        });
      }
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (editValue && itemsData && ledgersData) {
      setSuspand(editValue.is_suspended);
      if (costCenterData && doneByData) {
        setCostCenters(
          costCenterData.data?.filter((i) =>
            editValue.cost_centre?.includes(i.id)
          ) || []
        );
        setDoneBys(
          doneByData.data?.filter((i) => editValue.done_by?.includes(i.id)) ||
            []
        );
      }

      if (editValue.raw_items && editValue.raw_items.length > 0) {
        let rawItems = editValue.raw_items.map((item, index) => {
          const actualData =
            (item.item
              ? itemsData?.data.find((i) => i.id === item.item)
              : ledgersData?.data.find((i) => i.id === item.ledger)) || {};

          return {
            ...actualData,
            index,
            rawId: item.id,
            name: actualData.name,
            quantity: parseFloat(item.quantity).toFixed(decimalLength),
            description: item.description,
            store: item.store,
            rate: parseFloat(item.rate).toFixed(decimalLength),
            amount: parseFloat(item.amount).toFixed(decimalLength),
            unit: item.unit,
            unit_data: item.unit_data,
            secondaryUnit: item.unit,
          };
        });

        setStockInItems(rawItems);
      }

      if (editValue.products && editValue.products.length > 0) {
        let products = editValue.products.map((item, index) => {
          const actualData = item.item
            ? itemsData?.data.find((i) => i.id === item.item)
            : ledgersData?.data.find((i) => i.id === item.ledger);

          return {
            ...actualData,
            index,
            productId: item.id,
            name: actualData.name,
            quantity: parseFloat(item.quantity).toFixed(decimalLength),
            description: item.description,
            store: item.store,
            rate: parseFloat(item.rate).toFixed(decimalLength),
            amount: parseFloat(item.amount).toFixed(decimalLength),
            unit: item.unit,
            unit_data: item.unit_data,
            secondaryUnit: item.unit,
          };
        });

        setStockOutItems(products);
      }
    }
  }, [type, editValue, itemsData, ledgersData, costCenterData, doneByData]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const [formValues, setformValues] = useState(initialValue);
  const [editingItem, setEditingItem] = useState(null); // currently selected Item
  const [stockInItems, setStockInItems] = useState([]);
  const [stockOutItems, setStockOutItems] = useState([]);
  const [isOpenItemSelectionModal, setIsOpenItemSelectionModal] =
    useState(false);
  const [isOpenServiceModal, setIsOpenServiceModal] = useState("");
  const [stockInItemOpen, setStockInItemOpen] = useState(false);
  const [stockOutItemOpen, setStockOutItemOpen] = useState(false);

  useEffect(() => {
    setIsOpenItemSelectionModal(editItemModal.isOpen);
  }, [editItemModal.isOpen]);

  const handleEditStockInItemSubmit = (item) => {
    let taxUpdateditem = {
      ...item,
      // notes: item.description,
      // description: null,
    };
    let index =
      taxUpdateditem.index !== undefined
        ? taxUpdateditem.index
        : stockInItems?.length;
    let items = [
      ...stockInItems?.filter((i) => i.index !== taxUpdateditem.index),
      { ...taxUpdateditem, index: index },
    ];
    // Sort to maintain the index order
    let sorted = items.sort((a, b) => (a.index > b.index ? 1 : -1));

    setStockInItems(sorted);
    setEditingItem(null);
    setStockInItemOpen(false);
    editItemModal.onClose();
  };

  const handleEditStockOutItemSubmit = (item) => {
    let taxUpdateditem = {
      ...item,
      // notes: item.description,
      // description: null,
    };
    let index =
      taxUpdateditem.index !== undefined
        ? taxUpdateditem.index
        : stockOutItems?.length;
    let items = [
      ...stockOutItems?.filter((i) => i.index !== taxUpdateditem.index),
      { ...taxUpdateditem, index: index },
    ];
    // Sort to maintain the index order
    let sorted = items.sort((a, b) => (a.index > b.index ? 1 : -1));

    setStockOutItems(sorted);
    setEditingItem(null);
    setStockOutItemOpen(false);
    editItemModal.onClose();
  };

  const handleEditStockOutItem = (item) => {
    setStockOutItemOpen(true);
    setEditingItem(item);
    editItemModal.onOpen();
  };

  const handleEditStockInItem = (item) => {
    setStockInItemOpen(true);
    setEditingItem(item);
    editItemModal.onOpen();
  };

  const handleDeleteStockInItem = (item) => {
    let items = stockInItems
      .filter((i) => i.index !== item.index)
      .map((item, index) => ({ ...item, index: index }));
    setStockInItems(items);
  };

  const handleDeleteStockOutItem = (item) => {
    let items = stockOutItems
      .filter((i) => i.index !== item.index)
      .map((item, index) => ({ ...item, index: index }));
    setStockOutItems(items);
  };

  const onSubmit = (fields) => {
    fields.is_suspended = suspand;
    fields.cost_centre = costCenters?.map((i) => i.id);
    fields.done_by = doneBys?.map((i) => i.id);

    if (type === "edit") {
      if (isApproval) {
        fields.approved_by = auth?.data?.id;
      } else {
        delete fields.approved_by;
      }
      updateProduction(fields);
    } else {
      createProduction(fields);
    }
  };

  return (
    <Form
      initialValues={{ ...formValues }}
      RenderComponent={RenderComponent}
      handleOnSubmit={onSubmit}
      customProps={{
        type: type,
        isLoading: isCreateLoading || isUpdateLoading,
        decimalLength: decimalLength,
        editItemModal: editItemModal,
        editingItem: editingItem,
        setEditingItem: setEditingItem,
        stockInItems: stockInItems,
        setStockInItems: setStockInItems,
        stockOutItems: stockOutItems,
        setStockOutItems: setStockOutItems,
        setIsOpenItemSelectionModal: setIsOpenItemSelectionModal,
        setIsOpenServiceModal: setIsOpenServiceModal,
        handleEditStockInItemSubmit: handleEditStockInItemSubmit,
        handleEditStockOutItemSubmit: handleEditStockOutItemSubmit,
        handleEditStockOutItem: handleEditStockOutItem,
        handleEditStockInItem: handleEditStockInItem,
        handleDeleteStockInItem: handleDeleteStockInItem,
        handleDeleteStockOutItem: handleDeleteStockOutItem,
        stockInItemOpen: stockInItemOpen,
        stockOutItemOpen: stockOutItemOpen,
        setStockInItemOpen: setStockInItemOpen,
        setStockOutItemOpen: setStockOutItemOpen,
        setSuspand,
        suspand,
        isApproval,
        rejectFunc,
        isRejectLoading,
        costCenters,
        doneBys,
        setCostCenters,
        setDoneBys,
      }}
      validationSchema={object().shape({
        name: string().required("Name Required").nullable(),
      })}
    />
  );
};

export default AddProduction;
