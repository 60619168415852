import { useEffect, useState } from "react";
import { setLocalStore } from "utils/utils";
import { getLocalStore } from "utils/utils";
import { DIGIT_STANDARDS } from "utils/utils/digitStandards";
import { useUpdateMainSettings } from "./MainSettings/useUpdateMainSettings";
import { useToast } from "@chakra-ui/react";

export function useDigitStandardModel() {
  const toast = useToast();
  const {
    mutate: updateMainSettings,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateMainSettings();

  const auth = getLocalStore("AUTH_DETAILS");
  let companyID = auth?.data?.company.id || "";

  const [digitStandardModel, setDigitStandardModel] = useState(
    getLocalStore("STANDARD_MODEL") ?? DIGIT_STANDARDS[0].value
  );

  function changeStandardModel(standardModel) {
    setLocalStore("STANDARD_MODEL", standardModel);
    setDigitStandardModel(standardModel);
  }

  function updateDigitStandardModel(standardModel) {
    setLocalStore("STANDARD_MODEL", standardModel);
    setDigitStandardModel(standardModel);

    console.log("standardModel", standardModel);
    const data = {
      id: companyID,
      settings: {
        invoice_fields_in_print: [],
        item_fields_in_print: {},
        qr_code_in_invoice: null,
        keyword_settings: [],
        name_duplication_alllowed_in: [],
        default_numeral_system:
          standardModel === "indian"
            ? "INDIAN_STANDARDS"
            : standardModel === "international"
            ? "INTERNATIONAL_STANDARDS"
            : null,
      },
    };

    updateMainSettings(data);
  }

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Settings Updated Successfully");
      }
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isUpdateError) {
      toast({
        title: "",
        description: `Settings update failed`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [isUpdateError]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return [digitStandardModel, changeStandardModel, updateDigitStandardModel];
}
