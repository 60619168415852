import { useMutation, useQueryClient } from "react-query";
import api, { API_ITEM_UNIT_GET_EDIT_DELETE } from "utils/axios";

const updateItemUnit = (ItemUnit) =>
  api
    .patch(API_ITEM_UNIT_GET_EDIT_DELETE.replace("{id}", ItemUnit.id), ItemUnit)
    .then((res) => res.data);

export function useUpdateItemUnit() {
  const queryClient = useQueryClient();
  return useMutation(updateItemUnit, {
    onSuccess: () => queryClient.refetchQueries("itemunit"),
  });
}
