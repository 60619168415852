import { useQuery } from "react-query";
import api, { API_ITEM_CATEGORY_LIST_CREATE } from "utils/axios";

const getItemCategory = ({ queryKey }) => {
  const [_, filter] = queryKey;
  let url = API_ITEM_CATEGORY_LIST_CREATE;
  if (filter?.favourites) {
    url = url + `?favourites=true`;
  }
  if (filter?.isSuspended && filter?.isSuspended !== "") {
    url = url + `?suspened=${filter.isSuspended}`;
  }

  return api.get(url).then((res) => res.data);
};

export function useItemCategory(filter) {
  return useQuery(["itemcategory", filter], getItemCategory, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
