import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { number, object, string } from "yup";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useToast } from "@chakra-ui/react";

import Form from "../Form";
import RenderComponent from "./RenderComponent";
import { useCreateItemUnit } from "hooks/ItemUnit/uesCreateItemUnit";
import { useUpdateItemUnit } from "hooks/ItemUnit/useUpdateItemUnit";

const AddUnit = (props) => {
  const {
    type,
    editValue,
    changeType,
    listData,
    clearSearch,
    onClose,
    onAddSuccess,
    searchData,
    approveFunc,
    rejectFunc,
    isApproval = false,
    isRejectLoading = false,
    isApproveLoading = false,
  } = props;
  const [suspand, setSuspand] = useState(false);
  const history = useHistory();
  const toast = useToast();

  const {
    mutate: createItemUnit,
    data,
    isSuccess: isCreateSuccess,
    isLoading: isCreateLoading,
  } = useCreateItemUnit();

  const {
    mutate: updateItemUnit,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useUpdateItemUnit();

  const initialValue = {
    unit_symbol: searchData,
    unit: "",
    no_of_decimal: 0,
    is_suspended: suspand,
  };
  const [formValues, setformValues] = useState(initialValue);
  const [clearValues, setClearValues] = useState(false);

  useEffect(() => {
    if (isCreateSuccess) {
      if (data?.status === "success") {
        toastWithMessage("Unit created successfully");
        onAddSuccess(data?.data);
      }
      setClearValues(true);
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Unit updated successfully");
        onAddSuccess(updateResponse?.data);
      }
    }
  }, [isUpdateSuccess]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  useEffect(() => {
    const editObj = listData?.filter(
      (item) => item.uuid === editValue?.group
    )[0];
    if (type === "add") {
      setformValues({
        ...initialValue,
        ...editValue,
      });
    } else {
      if (editValue.group) {
        setformValues({
          ...editValue,
          group: {
            label: editObj.name,
            value: editObj.id,
          },
        });
      } else {
        setformValues({ ...editValue, group: "" });
        setSuspand(editValue.is_suspended);
      }
    }
  }, [type, editValue, listData]);

  const resetFormValues = () => {
    setformValues(initialValue);
    changeType();
  };
  return (
    <Form
      initialValues={{ ...formValues }}
      RenderComponent={RenderComponent}
      handleOnSubmit={async (fields) => {
        fields.is_suspended = suspand;
        if (type === "edit") {
          updateItemUnit(fields);
        } else {
          createItemUnit(fields);
        }
        clearSearch && clearSearch();
        onClose && onClose();
      }}
      customProps={{
        type: type,
        clearInitialValues: () => resetFormValues(),
        handleResetToComponent: clearValues,
        listData: listData,
        isLoading: isCreateLoading || isUpdateLoading,
        isSubmitted: isCreateSuccess || isUpdateSuccess,
        setSuspand,
        suspand,
        isApproval,
        approveFunc,
        rejectFunc,
        isRejectLoading,
        isApproveLoading,
      }}
      validationSchema={object().shape({
        unit_symbol: string().required("Symbol is Required"),
        unit: string().required("Formal name is Required"),
        no_of_decimal: number()
          .moreThan(-1, "Enter positive value")
          .lessThan(4, "Should be less than 4")
          .required("Number of decimal is Required"),
      })}
    />
  );
};
export default AddUnit;
