/* eslint-disable react-hooks/exhaustive-deps */
import { VStack } from "@chakra-ui/react";
const IVStack = ({ children }) => {
  return (
    <VStack align="stretch" spacing={4}>
      {children}
    </VStack>
  );
};
export default IVStack;
