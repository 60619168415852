import { createSlice } from '@reduxjs/toolkit'

import api, { DASHBOARD_DATA } from '../utils/axios'
import { useDispatch, useSelector } from 'react-redux'

const initialState = {
  loading: false,
  data: {},
}

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setData: (state, action) => {
      state.data = action.payload.data
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message
    }
  }
})

export const {
  setLoading,
  setData,
  setErrorMessage
} = dashboard.actions
export default dashboard.reducer

export const fetchDashboardData = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    // api call
    const response = await api.get(DASHBOARD_DATA)
    if (response?.data?.status === "success") {
      dispatch(setData({ data: response.data.data }))
      dispatch(setLoading({ loading: false }))
      // callback && callback()
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))

  }
}
