import { useEffect, useState } from "react";

import AutocompleteWithAddOption from "FormComponents/AutoComplete/component";
import AddCostCentre from "components/AddCostCentre";
import { useStore } from "hooks/Store/useStore";
import AddStore from "components/AddStore";

const StoreSelectionField = (props) => {
  const {
    store,
    disabled,
    setStore,
    required,
    inputRef,
    onFocus,
    inputLabel,
    multiOption = false,
    isReportField = false,
    reportRequiredMultiStore = false,
    onKeyDown,
    setIsOpenStoreModal,
  } = props;

  const { data, isLoading } = useStore();

  const [storeObject, setStoreObject] = useState();
  const [stores, setStores] = useState([]);

  useEffect(() => {
    if (data) {
      let datas = data.data?.results;
      if (multiOption) {
        let multiStoreAvailable = datas.find((f) => f.id === "multiStore");
        if (datas && !multiStoreAvailable)
          datas.push({ id: "multiStore", name: "MULTI STORE" });
      } else {
        datas = datas.filter((f) => f.id !== "multiStore");
      }
      if (isReportField) {
        datas = [{ name: "-Not Available-", id: "notAvailableId" }, ...datas];
      }
      if (reportRequiredMultiStore) {
        datas = [{ name: "Multi Store", id: "multi" }, ...datas];
      }
      setStores(datas);
    }
  }, [data]);

  useEffect(() => {
    if (stores && store) {
      setStoreObject(stores?.find((i) => i.id === store));
    } else {
      setStoreObject(null);
    }
  }, [store, stores]);

  const setSelectedStore = (selectedStore) => {
    setStore(selectedStore?.id);
  };

  return (
    <AutocompleteWithAddOption
      value={storeObject}
      options={stores}
      onChange={setSelectedStore}
      optionLabel="name"
      inputLabel={inputLabel || "Select Store"}
      disabled={disabled}
      required={required}
      setIsOpen={() => {}}
      inputRef={inputRef}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      setIsOpenModal={setIsOpenStoreModal}
      isLoading={isLoading}
    >
      <AddStore
        type="add"
        listData={stores}
        onAddSuccess={(store) => {
          setSelectedStore(store);
        }}
      />
    </AutocompleteWithAddOption>
  );
};

export default StoreSelectionField;
