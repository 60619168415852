import { useMutation, useQueryClient } from "react-query";
import api, { API_TRANSACTION_SETTINGS } from "utils/axios";

const createTransactionSettings = (data) => {
  let url = `${API_TRANSACTION_SETTINGS}?transaction_type=${data.transaction_type}`;
  return api.post(url, data).then((res) => res.data);
};

export function useCreateTransactionSettings() {
  const queryClient = useQueryClient();
  return useMutation(createTransactionSettings, {
    onSuccess: () => queryClient.refetchQueries("transactionSettings"),
  });
}
