import {
  Button,
  Box,
  FormControl,
  Heading,
  Select,
  Stack,
  Checkbox,
  Tabs,
  TabList,
  Tab,
  Switch,
  VStack,
  Text,
  Spacer,
  HStack,
  TabPanels,
  TabPanel,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { TextField } from "@material-ui/core";
import Message from "components/Message";
import React, { useEffect, useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import { saveAndPrintShortKey } from "utils/utils/saveAndPrintShortkey";
import MultiSelectionField from "components/MultiSelectionField";
import { PRINTING_MODELS } from "utils/utils/printingModels";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useInvoiceOptionModel } from "hooks/useInvoiceOptionModel";
import { firstInvoiceNoArray } from "utils/utils/settingsLists";
import { getLocalStore } from "utils/utils";
import useKeypress from "react-use-keypress";

import {
  ITab,
  IButton,
  IHead,
  IModalOut,
  IModalIn,
  ISelect,
  IOption,
  ITextField,
  IButtonOut,
} from "components/IModal";
import { InvoiceDataOptions } from "utils/utils/invoiceDataOptionsModel";
const underList = [
  { name: "Sales", value: "sales" },
  { name: "Purchase", value: "Purchase" },
  { name: "Expenses", value: "Expenses" },
  { name: "D.O", value: "D.O" },
  { name: "Sales Estimate", value: "Sales Estimate" },
  { name: "Sales Receipt", value: "Sales Receipt" },
  { name: "Purchase Payment", value: "Purchase payment" },
  { name: "Expenenses Payment", value: "Expenenses payment" },
  { name: "Other Receipt", value: "other receipt" },
  { name: "Other Payment", value: "other payment" },
  { name: "Sales Return", value: "Sales return" },
  { name: "Purchase Return", value: "Purchase return" },
  { name: "Journal Entry", value: "Journal entry" },
  { name: "Goods Receipt Note", value: "Goods Receipt Note" },
  { name: "Goods Issue Order", value: "Goods issue order" },
  { name: "Proforma Invoice", value: "Proforma invoice" },
  { name: "Purchase Order", value: "Purchase Order" },
  { name: "Purchase Requesition", value: "Purchase Requesition" },
  { name: "Retainer Invoice", value: "Retainer Invoice" },
  { name: "Sales Order", value: "Sales Order" },
];

const RenderComponent = ({
  handleEdit,
  values,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid,
}) => {
  const {
    type,
    isLoading,
    isSubmitted,
    setSuspand,
    suspand,
    isApproval,
    rejectFunc,
    isRejectLoading,
    costCenter,
    setCostCenter,
    doneBy,
    setDoneBy,
    doneByData,
    costCenterData,
    printSettings,
    setPrintSettings,
  } = customProps;
  const [clickKey, setClickKey] = useState({
    keyA: false,
    // keyP: false,
  });
  const invoiceModel = getLocalStore("INVOICEOPTION_MODEL") ?? [];
  const printingModels = PRINTING_MODELS;
  let [printingModelPreview, setPrintingModelPreview] = useState();
  const [invoiceDataOptions, setInvoiceDataOptions] = useState(invoiceModel);
  const [firstInvoiceNo, setFirstInvoiceNo] = useState(firstInvoiceNoArray);

  const [isPrintSettingsEnabled, setIsPrintSettingsEnabled] = useState(false);
  const [isNumberingRequired, setIsNumberingRequired] = useState(false);
  let [termsConInvoice, setTermsConInvoice] = useState("");
  let [termsConPos, setTermsConPos] = useState("");
  const [invoiceNoMethod, setInvoiceNoMethod] = useState("auto");
  const [filteredInvoiceDataOptions, setFilteredInvoiceDataOptions] = useState(
    []
  );

  // useEffect(() => {
  //   handleEdit(isPrintSettingsEnabled, {
  //     field: "seperate_print_settings_required",
  //   });
  // }, [isPrintSettingsEnabled]);

  // useEffect(()=>{

  // },[values.seperate_print_settings_required])

  useEffect(() => {
    const tmpInvoiceDataOptions = [];
    const newValueIds = printSettings.invoice_fields_in_print?.map(
      (item) => item.id
    );
    InvoiceDataOptions.forEach((element) => {
      if (!newValueIds?.includes(element.id))
        tmpInvoiceDataOptions.push(element);
    });
    tmpInvoiceDataOptions.sort(function (a, b) {
      const nameA = a.label.toUpperCase();
      const nameB = b.label.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    if (tmpInvoiceDataOptions)
      setFilteredInvoiceDataOptions(tmpInvoiceDataOptions);
  }, [printSettings.invoice_fields_in_print]);

  const handleKeyDown = (event) => {
    const newLineCount = (event.target.value.split("\n") || []).length;
    if (newLineCount > 19 && event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [fieldInFocus, setFieldInFocus] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);

  const nameRef = useRef();
  const underRef = useRef();
  const costCenterRef = useRef();
  const doneByRef = useRef();
  useEffect(() => {
    if (!isMobile) {
      if (tabIndex === 0) {
        if (fieldInFocus === 1) nameRef.current?.focus();
        if (fieldInFocus === 2) underRef.current?.focus();
      } else {
        if (fieldInFocus === 1) costCenterRef.current?.focus();
        if (fieldInFocus === 2) doneByRef.current?.focus();
      }
    }
  }, [fieldInFocus]);
  useKeypress(["Enter", "Tab"], (event) => {
    if (event.key === "Enter" && fieldInFocus <= 2) {
      let next = 1;
      if (fieldInFocus === 2 && tabIndex === 0) {
        onSubmit();
      } else if (fieldInFocus === 2 && tabIndex === 1) {
        onSubmit();
      }
      setFieldInFocus(fieldInFocus + next);
    }
    event.stopPropagation();
    event.preventDefault();
  });

  const handleClick = (fieldId) => {
    if (fieldId <= 2) {
      setFieldInFocus(fieldId);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (tabIndex === 0) {
        nameRef.current?.focus();
      } else if (tabIndex === 1) {
        costCenterRef.current?.focus();
      }
    }, 200);
  }, [tabIndex]);
  const [buttonClick, setButtonClick] = useState(false);
  const onSubmit = () => {
    setButtonClick(!buttonClick);
    const validateResponse = validateForm();
    validateResponse.then((data) => {
      submitAction(data);
    });
  };
  useKeypress(["f1", "F1", "f2", "F2"], (event) => {
    event.preventDefault();
    if (["f1", "F1"].includes(event.key)) {
      setTabIndex(0);
    }
    if (["f2", "F2"].includes(event.key)) {
      setTabIndex(1);
    }
    if (!["f1", "F1", "f2", "F2"].includes(event.key)) {
      event.stopPropagation();
      event.preventDefault();
    }
  });
  useEffect(() => {
    if (clickKey?.keyA && !isLoading && !isSubmitted) {
      onSubmit();
    }
  }, [clickKey]);
  saveAndPrintShortKey(setClickKey);

  return (
    <IModalOut>
      <IHead
        text={
          isApproval
            ? "Approve Voucher Type -" + values.name
            : type === "edit"
            ? "Edit Voucher Type -" + values.name
            : "Add voucher Type"
        }
      />
      <Tabs
        isFitted
        onChange={(index) => setTabIndex(index)}
        index={tabIndex}
        colorScheme="blue"
      >
        <TabList mb={3}>
          <ITab tooltipLabel="f1">Basic Info </ITab>
          <ITab tooltipLabel="f2">Other Info</ITab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <IModalIn>
              <FormControl>
                <ITextField
                  disabled={isApproval}
                  fullWidth
                  label="Voucher Type Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="name"
                  onChange={(event) =>
                    handleEdit(event.target.value, { field: "name" })
                  }
                  inputRef={nameRef}
                  onFocus={(e) => {
                    if (e) {
                      handleClick(1);
                    }
                  }}
                  value={values.name || ""}
                  isValid={isNotValid}
                  errorMessage={errors.name}
                  isRequired={true}
                  buttonClick={buttonClick}
                />
              </FormControl>
              <FormControl>
                <ISelect
                  disabled={isApproval}
                  placeholder="Select Under"
                  id="under"
                  name="under"
                  as={Select}
                  value={values.under}
                  onChange={(event) =>
                    handleEdit(event.target.value, { field: "under" })
                  }
                  inputRef={underRef}
                  onFocus={(e) => {
                    if (e) {
                      handleClick(2);
                    }
                  }}
                  errorMessage={errors.under}
                  isRequired={true}
                  isValid={errors.under === undefined}
                  buttonClick={buttonClick}
                >
                  {underList.map((item) => (
                    <IOption value={item.value}>{item.name}</IOption>
                  ))}
                </ISelect>
              </FormControl>
              <Checkbox
                disabled={isApproval}
                isChecked={suspand}
                onChange={(e) => {
                  setSuspand(!suspand);
                }}
              >
                Required Suspend
              </Checkbox>
              <HStack
                pl="5px"
                pr="5px"
                borderY="1px"
                borderColor="gray.200"
                py={2}
              >
                <VStack align="left" spacing="1">
                  <Heading size="sm">
                    Enable Voucher Wise Print Settings
                  </Heading>
                </VStack>
                <Spacer />
                <Switch
                  isChecked={values.seperate_print_settings_required}
                  onChange={(e) =>
                    handleEdit(e.target.checked, {
                      field: "seperate_print_settings_required",
                    })
                  }
                />
              </HStack>
              {values.seperate_print_settings_required && (
                <>
                  <HStack borderY="1px" borderColor="gray.200" pl="10px" py={2}>
                    <VStack align="left" spacing="1">
                      <Text size="sm">1.Printing Model</Text>
                    </VStack>
                    <Spacer />
                    <VStack align="left" mr="40px" spacing="1">
                      <Select
                        width="100%"
                        onChange={(event) =>
                          setPrintSettings({
                            ...printSettings,
                            printing_model: event.target.value,
                          })
                        }
                        value={printSettings.printing_model}
                      >
                        {printingModels.map((model, key) => (
                          <option value={model.value} key={key}>
                            {model.name}
                          </option>
                        ))}
                      </Select>
                    </VStack>
                  </HStack>
                  <HStack borderY="1px" borderColor="gray.200" pl="10px" py={2}>
                    <VStack align="left" spacing="1">
                      <Text size="sm">2.Invoice Options</Text>
                      <Text color="gray.600" fontSize="xs">
                        Data included in the Invoice page
                      </Text>
                    </VStack>
                    <Spacer />
                    <Box w="210px">
                      <Autocomplete
                        size="small"
                        id="invoice-options"
                        multiple
                        autoHighlight
                        freeSolo
                        disablePortal
                        defaultValue={printSettings.invoice_fields_in_print}
                        options={filteredInvoiceDataOptions}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Invoice Data"
                          />
                        )}
                        onChange={(event, newValue) =>
                          setPrintSettings({
                            ...printSettings,
                            invoice_fields_in_print: newValue,
                          })
                        }
                      />
                    </Box>
                  </HStack>
                  <HStack borderY="1px" borderColor="gray.200" pl="10px" py={2}>
                    <VStack align="left" spacing="1" flex={1}>
                      <Text size="sm">
                        3.General Terms and conditions to print invoice
                      </Text>
                      <Box>
                        <FormControl>
                          <TextField
                            variant="outlined"
                            size="small"
                            type="text"
                            multiline
                            maxRows={5}
                            onChange={(event) => {
                              setPrintSettings({
                                ...printSettings,
                                terms_and_condition_to_print_invoice:
                                  event.target.value || null,
                              });
                            }}
                            value={
                              printSettings.terms_and_condition_to_print_invoice ||
                              null
                            }
                            onKeyDown={handleKeyDown}
                            fullWidth
                          />
                        </FormControl>
                      </Box>
                    </VStack>
                    {/* <Spacer /> */}
                  </HStack>
                  <HStack pl="10px" py={2} borderY="1px" borderColor="gray.200">
                    <VStack align="left" spacing="1" flex={1}>
                      <Text size="sm">
                        4.General Terms and conditions to POS print
                      </Text>
                      <Box>
                        <FormControl>
                          <TextField
                            variant="outlined"
                            size="small"
                            type="text"
                            multiline
                            maxRows={5}
                            onChange={(event) => {
                              setPrintSettings({
                                ...printSettings,
                                terms_and_condition_to_pos:
                                  event.target.value || null,
                              });
                            }}
                            value={printSettings.terms_and_condition_to_pos}
                            onKeyDown={handleKeyDown}
                            fullWidth
                          />
                        </FormControl>
                      </Box>
                    </VStack>
                  </HStack>
                  <HStack pl="10px" py={2} borderY="1px" borderColor="gray.200">
                    <VStack align="left" spacing="1" flex={1}>
                      <Text size="sm">5.Our Company Name Want To Print</Text>
                      <Box>
                        <FormControl>
                          <TextField
                            variant="outlined"
                            size="small"
                            type="text"
                            multiline
                            maxRows={5}
                            onChange={(event) => {
                              setPrintSettings({
                                ...printSettings,
                                company_name_in_print: event.target.value,
                              });
                            }}
                            value={printSettings.company_name_in_print}
                            onKeyDown={handleKeyDown}
                            fullWidth
                          />
                        </FormControl>
                      </Box>
                    </VStack>
                  </HStack>
                  <HStack pl="10px" py={2} borderY="1px" borderColor="gray.200">
                    <VStack align="left" spacing="1" flex={1}>
                      <Text size="sm">6.Our Company Address Want To Print</Text>
                      <Box>
                        <FormControl>
                          <TextField
                            variant="outlined"
                            size="small"
                            type="text"
                            multiline
                            maxRows={5}
                            onChange={(event) => {
                              setPrintSettings({
                                ...printSettings,
                                company_address_in_print: event.target.value,
                              });
                            }}
                            value={printSettings.company_address_in_print}
                            onKeyDown={handleKeyDown}
                            fullWidth
                          />
                        </FormControl>
                      </Box>
                    </VStack>
                  </HStack>
                  <HStack
                    pl="10px"
                    pr="5px"
                    py={2}
                    borderY="1px"
                    borderColor="gray.200"
                  >
                    <VStack align="left" spacing="1">
                      <Text size="sm">7.Print Previous Balance Also</Text>
                    </VStack>
                    <Spacer />
                    <Switch
                      isChecked={printSettings.print_previous_balance_also}
                      onChange={(e) => {
                        setPrintSettings({
                          ...printSettings,
                          print_previous_balance_also:
                            !printSettings.print_previous_balance_also,
                        });
                      }}
                    />
                  </HStack>
                </>
              )}
              <HStack
                pl="5px"
                pr="5px"
                borderY="1px"
                borderColor="gray.200"
                py={2}
              >
                <VStack align="left" spacing="1">
                  <Heading size="sm">Voucher Numbering Required</Heading>
                </VStack>
                <Spacer />
                <Switch
                  isChecked={isNumberingRequired}
                  onChange={(e) => setIsNumberingRequired(!isNumberingRequired)}
                />
              </HStack>
              {isNumberingRequired && (
                <>
                  <HStack
                    pl="5px"
                    pr="5px"
                    borderY="1px"
                    borderColor="gray.200"
                    py={2}
                  >
                    <VStack align="left" spacing="1">
                      <Text size="sm">1.Invoice Numbering Method</Text>
                    </VStack>
                    <Spacer />
                    <Select
                      // disabled={!printBarcode}
                      maxW="210px"
                      onChange={(e) => {
                        setInvoiceNoMethod(e.target.value);
                        // setQrCodeDataOptions([]);
                      }}
                    >
                      <option
                        value="auto"
                        selected={invoiceNoMethod === "auto"}
                      >
                        Automatic
                      </option>
                      <option
                        value="manual"
                        selected={invoiceNoMethod === "manual"}
                      >
                        Manual
                      </option>
                    </Select>
                  </HStack>
                  {invoiceNoMethod === "auto" && (
                    <>
                      <HStack py={2} pl="5px" pr="5px">
                        <VStack
                          align="left"
                          // spacing="1"
                          display="flex"
                          flexDirection="row"
                          gap={1}
                        >
                          <>
                            <FormControl>
                              Prefix
                              <TextField
                                // disabled={item.prefix === "Not Available"}
                                variant="outlined"
                                size="small"
                                type="text"
                                name="prefix"
                                // value={
                                //   item.prefix === "Not Available"
                                //     ? ""
                                //     : item.prefix
                                // }
                                // onChange={(e) => {
                                //   setFirstInvoiceNo(
                                //     firstInvoiceNo.map((i) =>
                                //       i.text === item.text
                                //         ? {
                                //             ...item,
                                //             prefix: e.target.value,
                                //           }
                                //         : i
                                //     )
                                //   );
                                // }}
                              />
                            </FormControl>
                            <FormControl>
                              Number
                              <TextField
                                variant="outlined"
                                size="small"
                                type="number"
                                name="number"
                                // value={item.number}
                                // onChange={(e) => {
                                //   setFirstInvoiceNo(
                                //     firstInvoiceNo.map((i) =>
                                //       i.text === item.text
                                //         ? {
                                //             ...item,
                                //             number: e.target.value,
                                //           }
                                //         : i
                                //     )
                                //   );
                                // }}
                              />
                            </FormControl>
                            <FormControl>
                              Suffix
                              <TextField
                                // disabled={item.suffix === "Not Available"}
                                variant="outlined"
                                size="small"
                                type="text"
                                name="suffix"
                                // value={
                                //   item.suffix === "Not Available"
                                //     ? ""
                                //     : item.suffix
                                // }
                                // onChange={(e) => {
                                //   setFirstInvoiceNo(
                                //     firstInvoiceNo.map((i) =>
                                //       i.text === item.text
                                //         ? {
                                //             ...item,
                                //             suffix: e.target.value,
                                //           }
                                //         : i
                                //     )
                                //   );
                                // }}
                              />
                            </FormControl>
                          </>
                        </VStack>
                      </HStack>
                    </>
                  )}
                </>
              )}
            </IModalIn>
          </TabPanel>
          <TabPanel p={0}>
            <IModalIn>
              <MultiSelectionField
                placeholder="Cost Centers"
                datas={costCenterData?.data}
                options={costCenter}
                setOptions={setCostCenter}
                inputRef={costCenterRef}
                onFocus={(e) => {
                  if (e) {
                    handleClick(1);
                  }
                }}
              />
              <MultiSelectionField
                placeholder="Done Bys"
                datas={doneByData?.data}
                options={doneBy}
                setOptions={setDoneBy}
                inputRef={doneByRef}
                onFocus={(e) => {
                  if (e) {
                    handleClick(2);
                  }
                }}
              />
            </IModalIn>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <IButtonOut>
        {isApproval && (
          <Button
            w="100%"
            isLoading={isRejectLoading}
            minH="40px"
            type="submit"
            size="lg"
            variant="solid"
            bg="red.600"
            colorScheme="red"
            onClick={() => {
              rejectFunc();
            }}
          >
            Reject
          </Button>
        )}
        <IButton
          isLoading={isLoading}
          onClick={(e) => {
            if (e.detail === 1) {
              onSubmit();
            }
          }}
          isApproval={isApproval}
          type={type}
        />
      </IButtonOut>
    </IModalOut>
  );
};

export default RenderComponent;
