import { Heading, Box } from "@chakra-ui/react";

const IHead = ({ text, mt = 2, mb = 6, pt, pb }) => {
  return (
    <Box mt={mt} mb={mb} pt={pt} pb={pb}>
      <Heading className="poppins-medium">{text}</Heading>
    </Box>
  );
};
export default IHead;
