import { useMutation, useQueryClient } from "react-query";
import api, { API_VOUCHER_TYPE_LIST } from "utils/axios";

const createVoucherType = (vouchertype) =>
  api.post(API_VOUCHER_TYPE_LIST, vouchertype).then((res) => res.data);

export function useCreateVoucherType() {
  const queryClient = useQueryClient();
  return useMutation(createVoucherType, {
    onSuccess: () => queryClient.refetchQueries("vouchertype"),
  });
}
