import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Box,
  Select,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import useKeypress from "react-use-keypress";
import { isMobile } from "react-device-detect";

import LedgerSelectionList from "components/LedgerSelectionList";
import { getLocalStore } from "utils/utils";
import { useLedgerGroups } from "hooks/LedgerGroup/useLedgerGroups";
import { useLedgers } from "hooks/Ledger/useLedgers";
import AddLedgerForm from "components/AddLedgerForm";
import { FiEdit3, FiEye } from "react-icons/fi";
import AutocompleteWithAddOption from "FormComponents/AutoComplete/component";

const ServiceSelectionModal = (props) => {
  const {
    isOpen: isOpenServiceSelectionModal,
    onOpen: onOpenServiceSelectionModal,
    onClose: onCloseServiceSelectionModal,
  } = useDisclosure();

  const {
    addServiceRef,
    allGroupList,
    setEditingItem,
    onOpenEditItemModal,
    transactionName,
    setIsOpenServiceModal,
    fieldInFocus,
    setIsAddService,
    disabled = false,
    itemSearchWithoutPopup = false,
    addItemWithoutAmountPopup = false,
    ledgerAmountCalc,
    onFocus,
    isOpenAddLedgerModalOnFocus,
  } = props;

  const ledgerSearchModel = getLocalStore("LEDGER_SEARCH_DEFAULT_MODEL");
  const { data: ledgerGroupsData } = useLedgerGroups();
  const { data: ledgers } = useLedgers();
  const [ledgerList, setLedgerList] = useState([]);
  const [ledgerValue, setLedgerValue] = useState(null);
  const [searchType, setSearchType] = useState("name");
  const [editValue, setEditValue] = useState(null);
  const ledgerEditModal = useDisclosure();

  const onCloseCallback = () => {
    onCloseServiceSelectionModal();
  };

  //Short key
  useKeypress(["f1", "F1", "f6", "F6"], (event) => {
    event.preventDefault();
    if (transactionName === "Expense") {
      if (event.key === "f1" || event.key === "F1") {
        onOpenServiceSelectionModal(true);
      }
    } else if (event.key === "f6" || event.key === "F6") {
      onOpenServiceSelectionModal(true);
    }
  });

  useEffect(() => {
    setIsOpenServiceModal(isOpenServiceSelectionModal);
  }, [isOpenServiceSelectionModal]);
  useEffect(() => {
    if (isOpenAddLedgerModalOnFocus && !itemSearchWithoutPopup) {
      onOpenServiceSelectionModal();
    }
  }, [isOpenAddLedgerModalOnFocus]);
  useEffect(() => {
    if (fieldInFocus === 9) {
      onOpenServiceSelectionModal();
    }
  }, [fieldInFocus]);

  useEffect(() => {
    if (ledgers?.data && ledgerGroupsData?.data) {
      const getChildrenIds = (parentId) => {
        const uuids = [];
        ledgerGroupsData?.data.forEach((item) => {
          if (item.master === parentId) {
            uuids.push(item.uuid);
            uuids.push(...getChildrenIds(item.uuid));
          }
        });
        return uuids;
      };

      const sundryDebtors = ledgerGroupsData?.data
        .filter((item) => item.name === "Sundry Debtors")
        .map((ids) => {
          return { uuid: ids.uuid, id: ids.id };
        })[0];
      const sundryCreditors = ledgerGroupsData?.data
        .filter((item) => item.name === "Sundry Creditors")
        .map((ids) => {
          return { uuid: ids.uuid, id: ids.id };
        })[0];
      const bankAccounts = ledgerGroupsData?.data
        .filter((item) => item.name === "Bank Accounts")
        .map((ids) => {
          return { uuid: ids.uuid, id: ids.id };
        })[0];
      const cashInHand = ledgerGroupsData?.data
        .filter((item) => item.name === "Cash-in-Hand")
        .map((ids) => {
          return { uuid: ids.uuid, id: ids.id };
        })[0];

      const sundryDebtorsIds = getChildrenIds(sundryDebtors.uuid);
      sundryDebtorsIds.push(sundryDebtors.uuid);

      const sundryCreditorsIds = getChildrenIds(sundryCreditors.uuid);
      sundryCreditorsIds.push(sundryCreditors.uuid);

      const bankAccountsIds = getChildrenIds(bankAccounts.uuid);
      bankAccountsIds.push(bankAccounts.uuid);

      const cashInHandIds = getChildrenIds(cashInHand.uuid);
      cashInHandIds.push(cashInHand.uuid);

      const filteredLedgers = ledgers.data?.filter(
        (item) =>
          ![
            ...sundryDebtorsIds,
            ...sundryCreditorsIds,
            ...bankAccountsIds,
            ...cashInHandIds,
          ].includes(item.group)
      );

      setLedgerList(filteredLedgers);
    }
  }, [ledgers, ledgerGroupsData]);

  useEffect(() => {
    if (ledgerSearchModel) {
      switch (ledgerSearchModel) {
        case "NAME":
          setSearchType("name");
          break;
        case "CODE":
          setSearchType("ledger_code");
          break;
        case "OPENING_BALANCE":
          setSearchType("opening_balance");
          break;
        case "GROUP":
          setSearchType("group");
          break;
        case "DESCRIPTION":
          setSearchType("description");
          break;
        default:
          setSearchType("name");
          break;
      }
    }
  }, [ledgerSearchModel]);
  const openLedger = (ledger) => {
    if (ledger) {
      const newWindow = window.open(`/ledger-report`, "_blank");
      newWindow.ledger = ledger;
    }
  };

  const renderMoreOptions = (value) => {
    return (
      <Box display="flex" gap={2}>
        <IconButton
          aria-label="view"
          icon={<Icon as={FiEye} />}
          size="sm"
          color="blue.600"
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            openLedger(value);
          }}
        />
        <IconButton
          aria-label="edit"
          icon={<Icon as={FiEdit3} />}
          size="sm"
          color="orange.600"
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            setEditValue(value);
            ledgerEditModal.onOpen();
          }}
        />
      </Box>
    );
  };

  return (
    <>
      {itemSearchWithoutPopup && !isMobile ? (
        <Box display="flex">
          <Select
            w={120}
            borderRadius={0}
            bg="gray.200"
            placeholder="select"
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option value="name">Item name</option>
            <option value="ledger_code">Ledger code</option>
            <option value="opening_balance">Opening balance</option>
          </Select>
          <AutocompleteWithAddOption
            style={{ width: "350px" }}
            value={ledgerValue?.name}
            options={ledgerList}
            onChange={(newValue) => {
              setLedgerValue(newValue);
              if (newValue) {
                if (addItemWithoutAmountPopup) {
                  !!ledgerAmountCalc &&
                    ledgerAmountCalc(newValue, onCloseCallback);
                } else {
                  setEditingItem({ ...newValue, tax: 5, ledger: newValue.id });
                  setIsAddService(true);
                  onCloseCallback();
                  onOpenEditItemModal();
                }
              }
            }}
            optionLabel="name"
            inputLabel="Add Ledger"
            freeSolo
            clearOnBlur
            disablePortal={false}
            disabled={disabled}
            setIsOpen={() => {}}
            inputRef={addServiceRef}
            // textFieldClick={() => handleClick(7)}
            voucherType={"addStockItem"}
            renderMoreOptions={renderMoreOptions}
            searchFilter={searchType}
            size="2xl"
          >
            <AddLedgerForm type="add" onAddSuccess={(ledger) => {}} />
          </AutocompleteWithAddOption>
        </Box>
      ) : (
        <Button
          h="30px"
          leftIcon={<AddIcon />}
          colorScheme="blue"
          variant="ghost"
          onClick={onOpenServiceSelectionModal}
          ref={addServiceRef}
          disabled={disabled}
          onFocus={onFocus}
        >
          {(transactionName === "Expense" ? "Add Expenses" : "Add Ledger") +
            (isMobile ? "" : transactionName === "Expense" ? " (F1)" : " (F6)")}
        </Button>
      )}

      <Modal isOpen={isOpenServiceSelectionModal} onClose={onCloseCallback}>
        <ModalOverlay />
        <ModalContent maxW={isMobile ? "" : "500px"} marginTop={0}>
          <ModalBody>
            <LedgerSelectionList
              compact
              maxH="60vh"
              listDataGroup={allGroupList}
              applyFilter={true}
              onClick={(itemData) => {
                setEditingItem({ ...itemData, tax: 5, ledger: itemData.id });
                setIsAddService(true);
                onCloseCallback();
                onOpenEditItemModal();
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onCloseCallback}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={ledgerEditModal.isOpen}
        onClose={ledgerEditModal.onClose}
        scrollBehavior="inside"
        isCentered
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent width={"100%"}>
          <ModalCloseButton />
          <ModalBody>
            <AddLedgerForm
              type={"edit"}
              editValue={editValue}
              onAddSuccess={(ledger) => {
                ledgerEditModal.onClose();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ServiceSelectionModal;
