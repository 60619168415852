import { React, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  useToast,
  IconButton,
  Icon,
  Box,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import ItemSelectionList from "../../../../components/ItemSelectionList";
import useKeypress from "react-use-keypress";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import AutocompleteWithAddOption from "FormComponents/AutoComplete/component";
import { useItems } from "hooks/Item/useItems";
import { FiEdit3, FiEye, FiMoreVertical } from "react-icons/fi";
import { getLocalStore } from "utils/utils";
import AddItemForm from "components/AddItemForm";
import { getStandardFormat } from "utils/utils/digitStandards";
import { useDigitStandardModel } from "hooks/useDigitStandardModel";
import { useDecimalLength } from "hooks/useDecimalLength";

const ItemSelectionModal = (props) => {
  const toast = useToast();
  const {
    isOpen: isOpenItemSelectionModal,
    onOpen: onOpenItemSelectionModal,
    onClose: onCloseItemSelectionModal,
  } = useDisclosure();

  const {
    setEditingItem,
    onOpenEditItemModal,
    fieldInFocus,
    addStockItemRef,
    setIsOpenItemSelectionModal,
    handleAddStockItemData,
    // setCloseModal,
    closeModal,
    disabled = false,
    storeRequired = false,
    itemSearchWithoutPopup = false,
    stockItemAmountCalc,
    addItemWithoutAmountPopup,
    type = undefined,
    onFocus,
    transactionName,
    isOpenAddItemModalOnFocus,
    isStockOutAddItemModal = false,
  } = props;

  const { data: itemData } = useItems();
  const [itemList, setItemList] = useState([]);
  const [itemValue, setItemValue] = useState(null);
  const [searchType, setSearchType] = useState("name");
  const stockItemSearchModel = getLocalStore("STOCKITEM_SEARCH_DEFAULT_MODEL");
  const [editValue, setEditValue] = useState(null);
  const itemEditModal = useDisclosure();
  const [datasInPopup, setDatasInPopup] = useState(null);
  const [digitStandardModel] = useDigitStandardModel();
  const [decimalLength] = useDecimalLength(2);
  const datasInItemPopupModel = getLocalStore("DATAS_IN_ITEM_POPUP_MODEL");

  useEffect(() => {
    if (itemData) {
      setItemList(itemData.data);
    }
  }, [itemData]);

  useEffect(() => {
    setDatasInPopup(datasInItemPopupModel || null);
  }, [datasInItemPopupModel]);

  //Short key
  useKeypress(["f1", "F1", "f6", "F6"], (event) => {
    if (event.key === "f1" || event.key === "F1") {
      event.preventDefault();
      if (!isStockOutAddItemModal) onOpenItemSelectionModal(true);
    }
    if (event.key === "f6" || event.key === "F6") {
      event.preventDefault();
      if (isStockOutAddItemModal) onOpenItemSelectionModal(true);
    }
    if (
      event.key !== "F1" &&
      event.key !== "f1" &&
      event.key !== "f6" &&
      event.key !== "F6"
    ) {
      event.stopPropagation();
      event.preventDefault();
    }
  });
  useEffect(() => {
    if (isOpenAddItemModalOnFocus && !itemSearchWithoutPopup) {
      onOpenItemSelectionModal();
    }
  }, [isOpenAddItemModalOnFocus]);

  useEffect(() => {
    setIsOpenItemSelectionModal(isOpenItemSelectionModal);
  }, [isOpenItemSelectionModal]);
  // useEffect(() => {
  //   if (closeModal) {
  //     onCloseItemSelectionModal();
  //     setCloseModal(false)
  //   }
  // }, [closeModal]);

  const handleOnOpenItemSelectionModal = () => {
    if (!storeRequired) {
      onOpenItemSelectionModal();
    } else {
      toast({
        description: "Please select store before adding item",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const openItemDetail = (value) => {
    window.open(`/item-detailed-report/${value.id}/`, "_blank");
  };
  const renderMoreOptions = (value) => {
    return (
      <Box display="flex" gap={2}>
        {datasInPopup === "PRICE_WITH_TAX" && (
          <Text fontSize="sm" align="right">
            {getStandardFormat(
              [
                "Purchase",
                "goods-receipt-note",
                "purchase-requesition",
                "purchase-order",
                "stock-journal",
                "store-transfer",
              ].includes(transactionName)
                ? parseFloat(value.purchase_price_with_tax || 0)
                : parseFloat(value.price_with_tax || 0),
              digitStandardModel,
              decimalLength
            )}
          </Text>
        )}
        {datasInPopup === "PRICE_WITHOUT_TAX" && (
          <Text fontSize="sm" align="right">
            {getStandardFormat(
              [
                "Purchase",
                "goods-receipt-note",
                "purchase-requesition",
                "purchase-order",
                "stock-journal",
                "store-transfer",
              ].includes(transactionName)
                ? parseFloat(value.purchase_price_without_tax || 0)
                : parseFloat(value.price || 0),
              digitStandardModel,
              decimalLength
            )}
          </Text>
        )}
        {datasInPopup === "BOTH_PRICE" && (
          <Text fontSize="sm" align="right">
            {getStandardFormat(
              [
                "Purchase",
                "goods-receipt-note",
                "purchase-requesition",
                "purchase-order",
                "stock-journal",
                "store-transfer",
              ].includes(transactionName)
                ? parseFloat(value.purchase_price_with_tax || 0)
                : parseFloat(value.price_with_tax || 0),
              digitStandardModel,
              decimalLength
            )}
            <Text fontSize="10px" align="right">
              {getStandardFormat(
                [
                  "Purchase",
                  "goods-receipt-note",
                  "purchase-requesition",
                  "purchase-order",
                  "stock-journal",
                  "store-transfer",
                ].includes(transactionName)
                  ? parseFloat(value.purchase_price_without_tax || 0)
                  : parseFloat(value.price || 0),
                digitStandardModel,
                decimalLength
              )}
            </Text>
          </Text>
        )}
        {datasInPopup === "QUANTITY" && (
          <Text fontSize="sm" align="right">
            {" "}
          </Text>
        )}
        {datasInPopup === "EMPTY" && (
          <Text fontSize="sm" align="right">
            {" "}
          </Text>
        )}
        {datasInPopup === "TAX%" && (
          <Text fontSize="sm" align="right">
            {parseInt(value.tax)}%
          </Text>
        )}
        {datasInPopup === "HSN" && (
          <Text fontSize="sm" align="right">
            {value.hsn_number}
          </Text>
        )}
        {datasInPopup === "BAR_CODE" && (
          <Text fontSize="sm" align="right">
            {value.bar_code}
          </Text>
        )}
        {datasInPopup === "ITEM_CODE" && (
          <Text fontSize="sm" align="right">
            {value.item_code}
          </Text>
        )}
        {datasInPopup === "MRP" && (
          <Text fontSize="sm" align="right">
            {getStandardFormat(
              parseFloat(value.maximum_retail_price || 0),
              digitStandardModel,
              decimalLength
            )}
          </Text>
        )}
        <IconButton
          aria-label="view"
          icon={<Icon as={FiEye} />}
          size="sm"
          color="blue.600"
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            openItemDetail(value);
          }}
        />
        <IconButton
          aria-label="edit"
          icon={<Icon as={FiEdit3} />}
          size="sm"
          color="orange.600"
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            setEditValue(value);
            itemEditModal.onOpen();
          }}
        />
      </Box>
    );
  };

  useEffect(() => {
    if (stockItemSearchModel) {
      switch (stockItemSearchModel) {
        case "NAME":
          setSearchType("name");
          break;
        case "ALIAS_NAME":
          setSearchType("name_alias");
          break;
        case "BAR_CODE":
          setSearchType("bar_code");
          break;
        case "ITEM_CODE":
          setSearchType("item_code");
          break;
        case "HSN_CODE":
          setSearchType("hsn_number");
          break;
        default:
          setSearchType("name");
          break;
      }
    }
  }, [stockItemSearchModel]);

  return (
    <>
      {itemSearchWithoutPopup && !isMobile ? (
        <Box display="flex">
          <Select
            w={120}
            borderRadius={0}
            bg="gray.200"
            placeholder="select"
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          >
            <option value="name">Item name</option>
            <option value="name_alias">Alias name</option>
            <option value="bar_code">Bar code</option>
            <option value="hsn_number">HSN code</option>
            <option value="item_code">Item code</option>
          </Select>
          <AutocompleteWithAddOption
            style={{ width: "550px" }}
            value={itemValue?.name}
            options={itemList}
            onChange={(newValue) => {
              setItemValue(newValue);
              if (newValue) {
                if (addItemWithoutAmountPopup) {
                  !!stockItemAmountCalc &&
                    stockItemAmountCalc(newValue, onCloseItemSelectionModal);
                } else {
                  setEditingItem({ ...newValue, item: newValue?.id });
                  onCloseItemSelectionModal();
                  onOpenEditItemModal();
                }
              }
            }}
            optionLabel="name"
            inputLabel="Add Stock Item"
            freeSolo
            clearOnBlur
            disablePortal={false}
            disabled={disabled}
            setIsOpen={() => {}}
            inputRef={addStockItemRef}
            voucherType={"addStockItem"}
            renderMoreOptions={renderMoreOptions}
            searchFilter={searchType}
            size="2xl"
          >
            <AddItemForm
              type="add"
              changeType={() => {}}
              onSuccess={itemEditModal.onClose}
              handleAddStockItemData={handleAddStockItemData}
            />
          </AutocompleteWithAddOption>
        </Box>
      ) : (
        <Button
          h="30px"
          leftIcon={<AddIcon />}
          colorScheme="blue"
          variant="ghost"
          onFocus={onFocus}
          onClick={() => {
            handleOnOpenItemSelectionModal();
          }}
          ref={addStockItemRef}
          disabled={disabled}
        >
          {"Add Stock Item" +
            (isMobile ? "" : isStockOutAddItemModal ? " (F6)" : " (F1)")}
        </Button>
      )}
      <Modal
        isOpen={isOpenItemSelectionModal}
        onClose={onCloseItemSelectionModal}
      >
        <ModalOverlay />
        <ModalContent maxW={isMobile ? "" : "500px"} marginTop={0}>
          <ModalHeader></ModalHeader>
          <ModalBody>
            <ItemSelectionList
              compact
              maxH="60vh"
              onClick={(itemData) => {
                if (addItemWithoutAmountPopup) {
                  !!stockItemAmountCalc &&
                    stockItemAmountCalc(itemData, onCloseItemSelectionModal);
                } else {
                  setEditingItem({ ...itemData, item: itemData?.id });
                  onCloseItemSelectionModal();
                  onOpenEditItemModal();
                }
              }}
              handleAddStockItemData={handleAddStockItemData}
              transactionName={transactionName}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={onCloseItemSelectionModal}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={itemEditModal.isOpen}
        onClose={itemEditModal.onClose}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <AddItemForm
              type={"edit"}
              editValue={editValue}
              changeType={() => {}}
              onSuccess={itemEditModal.onClose}
              handleAddStockItemData={handleAddStockItemData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ItemSelectionModal;
