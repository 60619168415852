//Transaction
import sale from "./assets/Icons/Transactions/sale.svg";
import expense from "./assets/Icons/Transactions/expense.svg";
import journal from "./assets/Icons/Transactions/journal.svg";
import payment from "./assets/Icons/Transactions/payment.svg";
import purchase from "./assets/Icons/Transactions/purchase.svg";
import reciept from "./assets/Icons/Transactions/reciept.svg";
import delivery_order from "./assets/Icons/Transactions/delivery-order.svg";
import expense_payment from "./assets/Icons/Transactions/expense-payment.svg";
import other_transaction from "./assets/Icons/Transactions/other-transaction.svg";
import receipt_and_payment from "./assets/Icons/Transactions/receipt&payment.svg";

//Reports
import sale_report from "./assets/Icons/Reports/sale-report.svg";
import expense_report from "./assets/Icons/Reports/expense-report.svg";
import payment_report from "./assets/Icons/Reports/payment-report.svg";
import purchase_report from "./assets/Icons/Reports/purchase-report.svg";
import reciept_report from "./assets/Icons/Reports/reciept-report.svg";
import other_report from "./assets/Icons/Reports/other-report.svg";
import financial_report from "./assets/Icons/Reports/financial-report.svg";
import profit_report from "./assets/Icons/Reports/profit-report.svg";
import stock_reportt from "./assets/Icons/Reports/stock-report.svg";

//Add
import add_item from "./assets/Icons/Add/add-item.svg";
import add_customer from "./assets/Icons/Add/add-customer.svg";
import add_supplier from "./assets/Icons/Add/add-supplier.svg";
import add_group from "./assets/Icons/Add/add-group.svg";
import add_ledger from "./assets/Icons/Add/add-ledger.svg";
import add_other from "./assets/Icons/Add/add-other.svg";

//Others
import generate_report from "./assets/Icons/Others/generate-report.svg";
import import_report from "./assets/Icons/Others/import-report.svg";
import upload_image from "./assets/Icons/Others/upload-image.svg";
import sharing_room from "./assets/Icons/Others/shared-folder.svg";

const _navList = [
  {
    title: "Transaction",
    option: true,
    children: [
      {
        _title: "Stock Items",
        _path: "/",
        _src: sale,
        _option: true,
        _optionList: [
          {
            title: "Stock Item",
            path: "/add-item",
            add: true,
            index: 0,
            isModal: true,
            addPath: "item",
          },
          {
            title: "Stock Summary Report",
            path: "/stock-summary-report",
            add: false,
            isModal: false,
            addPath: "",
          },
          {
            title: "Stock Detailed Report",
            path: "/stock-detailed-report",
            add: false,
            isModal: false,
            addPath: "",
          },
        ],
      },
      {
        _title: "Sale",
        _path: "/",
        _src: sale,
        _option: true,
        _optionList: [
          // { title: "Sale With Items", path: "/sale-with-item" },
          // { title: "Sale Without Items", path: "/sale-without-item" },
          {
            title: "Customer",
            path: "/add-customer",
            add: true,
            addPath: "customer",
            isModal: true,
            index: 0,
          },
          {
            title: "Sale Invoice",
            path: "/sales-report",
            add: true,
            addPath: "/sale-with-item",
            isModal: false,
            index: 1,
          },
          {
            title: "Sales Receipt",
            path: "/reciept-report",
            add: true,
            addPath: "/reciept-against-invoice",
            isModal: false,
            index: 2,
          },
          {
            title: "Delivery Order",
            path: "/delivery-order-report",
            add: true,
            addPath: "/delivery-order",
            isModal: false,
            index: 3,
          },
          {
            title: "Sales Estimate",
            path: "/sale-estimate-report/sale-estimate",
            add: true,
            addPath: "/estimate-with-item/sale-estimate",
            isModal: false,
            index: 4,
          },
          {
            title: "Sales Return",
            path: "/sales-return-report",
            add: true,
            addPath: "/sales-return-with-item",
            isModal: false,
            index: 5,
          },
          {
            title: "Customer Setoff",
            path: "/customer-set-off-report",
            add: true,
            addPath: "/customer-set-off",
            isModal: false,
            index: 6,
          },
          {
            title: "Sale Return Payment",
            path: "/payment-report",
            add: true,
            addPath: "/payment-against-sales-return",
            isModal: false,
            index: 7,
          },
          {
            title: "POS",
            path: "/sales-pos",
            add: true,
            addPath: "/sales-pos",
            isModal: false,
            index: 8,
          },
        ],
      },
      {
        _title: "Purchase",
        _path: "/",
        _src: purchase,
        _option: true,
        _optionList: [
          // { title: "Purchase With Items", path: "/purchase-with-item" },
          // { title: "Purchase Without Items", path: "/purchase-without-item" },
          {
            title: "Supplier",
            path: "/add-supplier",
            add: true,
            addPath: "supplier",
            isModal: true,
            index: 0,
          },
          {
            title: "Purchase Invoice",
            path: "/purchase-report",
            add: true,
            addPath: "/purchase-with-item",
            isModal: false,
            index: 1,
          },
          {
            title: "Purchase Payment",
            path: "/payment-report",
            add: true,
            addPath: "/payment-against-purchase",
            isModal: false,
            index: 2,
          },
          {
            title: "Purchase Return",
            path: "/purchase-return-report",
            add: true,
            addPath: "/purchase-return-with-item",
            isModal: false,
            index: 3,
          },
          {
            title: "Supplier Setoff",
            path: "/supplier-set-off-report",
            add: true,
            addPath: "/supplier-set-off",
            isModal: false,
            index: 4,
          },
          {
            title: "Purchase Return Receipt ",
            path: "/reciept-report",
            add: true,
            addPath: "/receipt-against-purchase-return",
            isModal: false,
            index: 5,
          },
        ],
      },
      {
        _title: "Expense",
        _path: "/expense",
        _src: expense,
        _option: true,
        _optionList: [
          {
            title: "Supplier",
            path: "/add-supplier",
            add: true,
            addPath: "supplier",
            isModal: true,
            index: 0,
          },
          {
            title: "Expenses Invoice",
            path: "/expense-report",
            add: true,
            addPath: "/expense",
            isModal: false,
            index: 1,
          },
          {
            title: "Expenses Payment",
            path: "/payment-report",
            add: true,
            addPath: "/payment-against-expense",
            isModal: false,
            index: 2,
          },
          {
            title: "Supplier Setoff",
            path: "/supplier-set-off-report",
            add: true,
            addPath: "/supplier-set-off",
            isModal: false,
            index: 3,
          },
        ],
      },
      {
        _title: "Others",
        _path: "",
        _src: expense,
        _option: true,
        _optionList: [
          {
            title: "Other Receipt",
            path: "/reciept-report",
            add: true,
            addPath: "/other-reciept",
            isModal: false,
            index: 0,
          },
          {
            title: "Other Payment",
            path: "/payment-report",
            add: true,
            addPath: "/other-payment",
            isModal: false,
            index: 1,
          },
          {
            title: "Journal Entry",
            path: "/journal-entries-report",
            add: true,
            addPath: "/journal-entry",
            isModal: false,
            index: 2,
          },
        ],
      },
      {
        _title: "Stock Transactions",
        _path: "",
        _src: expense,
        _option: true,
        _optionList: [
          {
            title: "Store Transfer",
            path: "/stock-journal-report/store-transfer",
            add: true,
            addPath: "/stock-journal/store-transfer",
            isModal: false,
            index: 0,
          },
          {
            title: "Stock Journal",
            path: "/stock-journal-report/stock-journal",
            add: true,
            addPath: "/stock-journal/stock-journal",
            isModal: false,
            index: 1,
          },
          {
            title: "Stock Hold",
            path: "/sale-estimate-report/stock-hold",
            add: true,
            addPath: "/estimate-with-item/stock-hold",
            isModal: false,
            index: 2,
          },
          {
            title: "Consumption Issue",
            path: "/sale-estimate-report/consumption-issue",
            add: true,
            addPath: "/estimate-with-item/consumption-issue",
            isModal: false,
            index: 3,
          },
          {
            title: "Compliment Issue",
            path: "/sale-estimate-report/compliment-issue",
            add: true,
            addPath: "/estimate-with-item/compliment-issue",
            isModal: false,
            index: 4,
          },
        ],
      },
      {
        _title: "Sales Others",
        _path: "",
        _src: expense,
        _option: true,
        _optionList: [
          {
            title: "Compliment Issue",
            path: "/sale-estimate-report/goods-issue-order",
            add: true,
            addPath: "/estimate-with-item/goods-issue-order",
            isModal: false,
            index: 0,
          },
          {
            title: "Proforma Invoice",
            path: "/sale-estimate-report/proforma-invoice",
            add: true,
            addPath: "/estimate-with-item/proforma-invoice",
            isModal: false,
            index: 1,
          },
          {
            title: "Retainer Invoice",
            path: "/sale-estimate-report/retainer-invoice",
            add: true,
            addPath: "/estimate-with-item/retainer-invoice",
            isModal: false,
            index: 2,
          },
          {
            title: "Sales Order",
            path: "/sale-estimate-report/sales-order",
            add: true,
            addPath: "/estimate-with-item/sales-order",
            isModal: false,
            index: 3,
          },
        ],
      },
      {
        _title: "Purchase Others",
        _path: "",
        _src: expense,
        _option: true,
        _optionList: [
          {
            title: "Goods Reciept Note",
            path: "/sale-estimate-report/goods-receipt-note",
            add: true,
            addPath: "/estimate-with-item/goods-receipt-note",
            isModal: false,
            index: 0,
          },
          {
            title: "Purchase Order",
            path: "/sale-estimate-report/purchase-order",
            add: true,
            addPath: "/estimate-with-item/purchase-order",
            isModal: false,
            index: 1,
          },
          {
            title: "Purchase Requesition",
            path: "/sale-estimate-report/purchase-requesition",
            add: true,
            addPath: "/estimate-with-item/purchase-requesition",
            isModal: false,
            index: 2,
          },
        ],
      },
    ],
  },
  {
    title: "Reports",
    option: false,
    children: [],
  },
  {
    title: "Add",
    option: true,
    children: [
      {
        _title: "Items",
        _path: "/",
        _src: add_other,
        add: false,
        _addPath: "",
        _isModal: false,
        _option: true,
        _optionList: [
          {
            title: "Stock Item",
            path: "/add-item",
            add: true,
            index: 0,
            isModal: true,
            addPath: "item",
          },
          {
            title: "Item Unit",
            path: "/add-unit",
            add: true,
            index: 1,
            isModal: true,
            addPath: "addunit",
          },
          {
            title: "Item Group",
            path: "/add-item-group",
            add: true,
            index: 2,
            isModal: true,
            addPath: "additemgroup",
          },
          {
            title: "Item Category",
            path: "/add-category",
            add: true,
            index: 3,
            isModal: true,
            addPath: "addcategory",
          },
          {
            title: "Item Brand",
            path: "/add-brand",
            add: true,
            index: 4,
            isModal: true,
            addPath: "addbrand",
          },
          {
            title: "Item Store",
            path: "/add-store",
            add: true,
            index: 5,
            isModal: true,
            addPath: "addstore",
          },
        ],
      },
      {
        _title: "Customer",
        _path: "/add-customer",
        _src: add_customer,
        _option: false,
        _optionList: [],
        index: 6,
        add: true,
        addPath: "customer",
        isModal: true,
      },
      {
        _title: "Supplier",
        _path: "/add-supplier",
        _src: add_supplier,
        _option: false,
        index: 7,
        add: true,
        _optionList: [],
        addPath: "supplier",
        isModal: true,
      },
      {
        _title: "Ledger Group",
        _path: "/ledger-group",
        _src: add_group,
        _option: false,
        _optionList: [],
        index: 8,
        add: true,
        isModal: true,
        addPath: "group",
      },
      {
        _title: "Ledger",
        _path: "/add-ledger",
        _src: add_ledger,
        _option: false,
        index: 9,
        add: true,
        _optionList: [],
        isModal: true,
        addPath: "ledger",
      },
      {
        _title: "Others",
        _path: "/",
        _src: add_other,
        _option: true,
        _optionList: [
          {
            title: "Cost Center",
            path: "/add-cost-centre",
            add: true,
            index: 0,
            isModal: true,
            addPath: "costcentre",
          },
          {
            title: "Done By",
            path: "/add-done-by",
            add: true,
            index: 1,
            isModal: true,
            addPath: "doneby",
          },
          {
            title: "Standard Product",
            path: "/add-production-list",
            add: true,
            index: 2,
            isModal: true,
            addPath: "addproduction",
          },
          {
            title: "Voucher Type",
            path: "/add-voucher-type",
            add: true,
            index: 3,
            isModal: true,
            addPath: "addvoucher",
          },
          {
            title: "Estimate Status",
            path: "/add-estimate-status",
            add: true,
            index: 4,
            isModal: true,
            addPath: "addesitmate",
          },
          {
            title: "Mode of Payment",
            path: "/add-mode-of-payment",
            add: true,
            index: 5,
            isModal: true,
            addPath: "addmode",
          },
        ],
      },
    ],
  },
  // {
  //   title: "Other",
  //   option: true,
  //   children: [
  //     {
  //       _title: "Upload Image",
  //       _path: "/",
  //       _src: upload_image,
  //       _option: true,
  //       _optionList: [
  //         { title: "Upload Sales Image", path: "/upload-sales-image" },
  //         { title: "Upload Purchase Image", path: "/upload-purchase-image" },
  //         { title: "Upload Expense Image", path: "/upload-expense-image" },
  //       ],
  //     },
  //     {
  //       _title: "Import Reports",
  //       _path: "/",
  //       _src: import_report,
  //       _option: true,
  //       _optionList: [
  //         {
  //           title: "Import Sales With Item",
  //           path: "/import-reports-sales-with-item",
  //         },
  //         {
  //           title: "Import Sales Without Item",
  //           path: "/import-reports-sales-without-item",
  //         },
  //         {
  //           title: "Import Purchase With Item",
  //           path: "/import-reports-purchase-with-item",
  //         },
  //         {
  //           title: "Import Purchase Without Item",
  //           path: "/import-reports-purchase-without-item",
  //         },
  //         { title: "Import Expense", path: "/import-reports-expense" },
  //         { title: "Import Items", path: "/import-reports-items" },
  //         { title: "Import Customers", path: "/import-reports-customers" },
  //         { title: "Import Suppliers", path: "/import-reports-suppliers" },
  //         { title: "Import Ledger", path: "/import-reports-ledger" },
  //         { title: "Import Journal", path: "/import-reports-journal" },
  //         { title: "Import Receipts", path: "/import-reports-receipts" },
  //         { title: "Import Payments", path: "/import-reports-payments" },
  //       ],
  //     },
  //     {
  //       _title: "Generate Report",
  //       _path: "/",
  //       _src: generate_report,
  //       _option: false,
  //       _optionList: [],
  //     },
  //     {
  //       _title: "Sharing Room",
  //       _path: "/sharing-room",
  //       _src: sharing_room,
  //       _option: false,
  //       _optionList: [],
  //     },
  //   ],
  // },
];

export default _navList;
