/* global localStorage */

import AES from 'crypto-js/aes'
import CryptoJS from 'crypto-js'

export const setLocalStore = (cname, cvalue) => {
  if (cvalue) {
    const encryptedData = AES.encrypt(JSON.stringify(cvalue), '123')
    localStorage.setItem(cname, encryptedData)
  }
}

export const setResponse = (cname, cvalue) => {
  if (cvalue) {
    localStorage.setItem(cname, cvalue)
  }
}

export const getResponse = (cname) => {
  return JSON.parse(localStorage.getItem(cname))
}

export const getLocalStore = (cname) => {
  let decryptedData = null
  const bytes =
    localStorage.getItem(cname) &&
    AES.decrypt(localStorage.getItem(cname), '123')
  decryptedData = bytes && JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  return decryptedData
}

export const clearLocalStore = () => {
  localStorage.clear()
}

export const objectArrayFromField = (list, field, value) => {
  return list?.length
    ? list.map((item) => {
        return {
          label: item[field],
          value: item[value]
        }
      })
    : []
}

export const deleteItem = (cname) => {
  localStorage.removeItem(cname)
}

export const setSearch = (cname, cvalue) => {
  if (cvalue) {
    localStorage.setItem(cname, JSON.stringify(cvalue))
  }
}

// export const getData = (key, storeData, targetStorage) => {
//   const persistedData = getResponse(targetStorage)
//   const formattedData = persistedData || {}
//   if (storeData && storeData[key] && storeData[key].length) {
//     return storeData[key]
//   }
//   if (formattedData && formattedData[key] && formattedData[key].length) {
//     return formattedData[key]
//   }
//   return []
// }
