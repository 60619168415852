import { createSlice } from "@reduxjs/toolkit";

import api, {
  API_SUPPLIER_LIST,
  API_CUSTOMER_ADD,
  API_CUSTOMER_EDIT,
} from "../utils/axios";

import { showLoading, hideLoading } from "./common.slice";

const initialState = {
  loading: false,
  suppliers: [],
};

const suppliers = createSlice({
  name: "suppliers",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setCustomer: (state, action) => {
      state.suppliers = action.payload.suppliers;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setCustomer, setErrorMessage } = suppliers.actions;
export default suppliers.reducer;

export const fetchSupplier = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(API_SUPPLIER_LIST);
    if (response && response.data) {
      dispatch(setCustomer({ suppliers: response.data.data }));
      dispatch(setLoading({ loading: false }));
      // callback && callback()
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const addSupplier =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.post(API_CUSTOMER_ADD, data);
      if (response && response.data) {
        dispatch(hideLoading());
        dispatch(fetchSupplier());
        callback && callback(response.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

export const editSupplier =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.patch(
        API_CUSTOMER_EDIT.replace("{id}", data.id),
        data
      );
      if (response && response.data) {
        dispatch(hideLoading());
        dispatch(fetchSupplier());
        callback && callback(response.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

export const deleteSupplier =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_CUSTOMER_EDIT.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(hideLoading());
        dispatch(fetchSupplier());
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };
