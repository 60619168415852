import { useMutation, useQueryClient } from "react-query";
import api, { API_ITEM_DONEBY_LIST_CREATE } from "utils/axios";

const createDoneBy = (DoneBy) =>
  api.post(API_ITEM_DONEBY_LIST_CREATE, DoneBy).then((res) => res.data);

export function useCreateDoneBy() {
  const queryClient = useQueryClient();
  return useMutation(createDoneBy, {
    onSuccess: () => queryClient.refetchQueries("doneby"),
  });
}
