/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Box } from "@chakra-ui/react";
const IModalOut = ({ children }) => {
  return (
    <Box bg="white" flex={1} p={2} pb={4} w="100%" rounded="sm">
      <Stack spacing={0} overflowY="hidden">
        {children}
      </Stack>
    </Box>
  );
};
export default IModalOut;
