import { useMutation, useQueryClient } from "react-query";
import api, { API_ITEM_CATEGORY_LIST_CREATE } from "utils/axios";

const createItemCategory = (ItemCategory) =>
  api.post(API_ITEM_CATEGORY_LIST_CREATE, ItemCategory).then((res) => res.data);

export function useCreateItemCategory() {
  const queryClient = useQueryClient();
  return useMutation(createItemCategory, {
    onSuccess: () => queryClient.refetchQueries("itemcategory"),
  });
}
