import { useQuery } from "react-query";
import api, { API_USER_SETUP_GET_EDIT } from "utils/axios";

const getUserSetup = async ({ queryKey }) => {
  const [_, id] = queryKey;
  if (id) {
    return api.get(`${API_USER_SETUP_GET_EDIT}${id}/`).then((res) => res.data);
  }
};
export function useUserSetup(id) {
  return useQuery(["usersetup", id], getUserSetup, {
    enabled: !!id,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
