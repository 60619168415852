import { useMutation, useQueryClient } from "react-query";
import api, { API_PRINT_SETTINGS } from "utils/axios";

const updatePrintSettings = (mainSettings) => {
  return api
    .patch(`${API_PRINT_SETTINGS}${mainSettings.id}`, mainSettings)
    .then((res) => res.data);
};

export function useUpdatePrintSettings() {
  const queryClient = useQueryClient();
  return useMutation(updatePrintSettings, {
    onSuccess: (response) => {
      queryClient.setQueriesData(
        ["printSettings", response.data.id.toString()],
        response
      );
      queryClient.refetchQueries("printSettings");
    },
  });
}
