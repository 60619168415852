/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Field } from "formik";
import {
  Box,
  Stack,
  FormControl,
  Select,
  Textarea,
  Button,
  Heading,
  Grid,
  GridItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Image,
  Text,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Tooltip,
  Flex,
  IconButton,
  Spacer,
  Link,
  useToast,
  Switch,
} from "@chakra-ui/react";
import Message from "../Message";
import TextField from "@material-ui/core/TextField";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
import { isMobile } from "react-device-detect";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import {
  countrieslist,
  emiratesList,
  emiratesListIndia,
  taxregistrationtypeList,
} from "../../utils/utils/optionsLists";
import { getLocalStore, setLocalStore } from "../../utils/utils";
import useKeypress from "react-use-keypress";
import userProfile from "assets/user-profile.png";
import { useCostCenters } from "hooks/CostCenter/useCostCenters";
import { useDoneBy } from "hooks/DoneBy/useDoneBy";
import { useDecimalLength } from "hooks/useDecimalLength";
import { BE_DATE_FORMAT } from "utils/utils/constant";
import moment from "moment";
import { Card, CardContent } from "@material-ui/core";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import DatePicker from "components/DatePicker";
import IDatePicker from "components/IDatePicker";
import CostCenterSelectionField from "components/CostCenterSelectionField";
import DoneBySelectionField from "components/DoneBySelectionField";
import { useLedgerGroups } from "hooks/LedgerGroup/useLedgerGroups";
import { saveAndPrintShortKey } from "utils/utils/saveAndPrintShortkey";
import AddPartyAdvanceModal from "pages/Transaction/components/AddPartyAdvanceModal";
import MultiSelectionField from "components/MultiSelectionField";

import {
  ITab,
  IButton,
  IHeadFav,
  IHead,
  ITabPanelIn,
  IModalOut,
  IModalIn,
  IHStack,
  IVStack,
  ISelect,
  IOption,
  ITextarea,
  ITextField,
  IButtonOut,
  ITabPanel,
  IFieldAndLabel,
} from "components/IModal";
const iconStyle = {
  fontSize: "20px",
  cursor: "pointer",
};
const RenderComponent = ({
  onClear,
  handleEdit,
  values,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid,
  isSubmitting,
  touched,
  handleReset,
  cancelAction,
}) => {
  const {
    type,
    isLoading,
    isSubmitted,
    setSelectCountry,
    setEnableCreditLimit1,
    enableCreditLimit1,
    handleFavourite,
    setSuspand,
    isToggledFav,
    suspand,
    isApproval,
    rejectFunc,
    isRejectLoading,
  } = customProps;

  const auth = getLocalStore("AUTH_DETAILS");
  const companyID = auth?.data?.company?.id;
  const toast = useToast();

  const decimalLength = useDecimalLength(2);
  const { data: costCenterData } = useCostCenters();
  const { data: doneByData } = useDoneBy();
  const { data: ledgerGroupData } = useLedgerGroups();

  const [tabIndex, setTabIndex] = useState(0);
  const [formErrors, setFormErrors] = useState(null);
  const [openingBalanceArray, setOpeningBalanceArray] = useState([]);
  const [openingBalance, setopeningBalance] = useState({});
  const [totalOpeningBalance, setTotalOpeningBalance] = useState(0);
  const [shippingAddressArray, setShippingAddressArray] = useState([]);
  const [shippingAddress, setShippingAddress] = useState({});
  const [contactPersonArray, setContactPersonArray] = useState([]);
  const [contactPerson, setContactPerson] = useState({});
  const [sundryCreditorsGroups, setSundryCreditorsGroups] = useState([]);
  const [hasExecuted, setHasExecuted] = useState(false);
  const [showMoreWeb, setShowMoreWeb] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [clickKey, setClickKey] = useState({
    keyA: false,
    keyP: false,
  });

  const [editingAdvanceItem, setEditingAdvanceItem] = useState(null);
  const [advancesArray, setAdvancesArray] = useState([]);
  const [costCenters, setCostCenters] = useState([]);
  const [doneBys, setDoneBys] = useState([]);

  const [fieldInFocus, setFieldInFocus] = useState(1);
  const nameRef = useRef();
  const openingBalanceRef = useRef();
  const addressRef = useRef();
  const countryRef = useRef();
  const emirateRef = useRef();
  const trnRef = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const ledgerGroupRef = useRef();
  const country = getLocalStore("AUTH_DETAILS") || "";
  const taxRegistrationTypeRef = useRef();
  const creditPeriodRef = useRef();
  const maxCreditLimitRef = useRef();
  const bankDetailesRef = useRef();
  const remarksRef = useRef();
  const aliasRef = useRef();
  const distanceRef = useRef();
  const panNumberRef = useRef();
  const webSiteRef = useRef();
  const costCenterRef = useRef();
  const doneByRef = useRef();
  const communicationChannelRef = useRef();
  const noOfDayBeforDueDateRef = useRef();
  const noOfDayAfterDueDateRef = useRef();

  useEffect(() => {
    if (!isMobile) {
      //base info tab
      if (fieldInFocus === 1) nameRef.current?.focus();
      if (fieldInFocus === 2) mobileRef.current?.focus();
      if (fieldInFocus === 3) ledgerGroupRef.current?.focus();
      if (fieldInFocus === 4) creditPeriodRef.current?.focus();
      if (fieldInFocus === 5) maxCreditLimitRef.current?.focus();
      if (fieldInFocus === 6) addressRef.current?.focus();
      if (fieldInFocus === 7) emirateRef.current?.focus();
      if (fieldInFocus === 8) countryRef.current?.focus();
      if (fieldInFocus === 9) emailRef.current?.focus();
      if (fieldInFocus === 10) trnRef.current?.focus();
      if (fieldInFocus === 11) taxRegistrationTypeRef.current?.focus();

      //due date remiander tab
      if (fieldInFocus === 12) communicationChannelRef.current?.focus();
      if (fieldInFocus === 13) noOfDayBeforDueDateRef.current?.focus();
      if (fieldInFocus === 14) noOfDayAfterDueDateRef.current?.focus();

      //other info tab
      if (fieldInFocus === 15) aliasRef.current?.focus();
      if (fieldInFocus === 16) panNumberRef.current?.focus();
      if (fieldInFocus === 17) webSiteRef.current?.focus();
      if (fieldInFocus === 18) distanceRef.current?.focus();
      if (fieldInFocus === 19) bankDetailesRef.current?.focus();
      if (fieldInFocus === 20) remarksRef.current?.focus();
      if (fieldInFocus === 21) costCenterRef.current?.focus();
      if (fieldInFocus === 22) doneByRef.current?.focus();
    }
  }, [fieldInFocus]);

  useKeypress(["Enter", "Tab"], (event) => {
    if (event.key === "Enter" && fieldInFocus <= 22) {
      let next = 1;
      if (fieldInFocus === 4) {
        if (!enableCreditLimit1) {
          if (showMoreWeb) {
            next = 2;
          } else {
            onSubmit();
          }
        }
      } else if (fieldInFocus === 5) {
        if (!showMoreWeb) onSubmit();
      } else if (
        fieldInFocus === 11 ||
        fieldInFocus === 14 ||
        fieldInFocus === 22
      ) {
        onSubmit();
      } else if (
        fieldInFocus === 6 &&
        !["UAE", "INDIA"].includes(values.country)
      ) {
        next = 2;
      }
      setFieldInFocus(fieldInFocus + next);
    }
    event.stopPropagation();
    event.preventDefault();
  });
  useKeypress(
    ["f1", "F1", "f2", "F2", "f3", "F3", "f4", "F4", "f5", "F5", "f6", "F6"],
    (event) => {
      event.preventDefault();
      if (["f1", "F1"].includes(event.key)) {
        setTabIndex(0);
      }
      if (["f2", "F2"].includes(event.key)) {
        setTabIndex(1);
      }
      if (["f3", "F3"].includes(event.key)) {
        setTabIndex(2);
      }
      if (["f4", "F4"].includes(event.key)) {
        setTabIndex(3);
      }
      if (["f5", "F5"].includes(event.key)) {
        setTabIndex(4);
      }
      if (["f6", "F6"].includes(event.key)) {
        setTabIndex(5);
      }
      if (
        ![
          "f1",
          "F1",
          "f2",
          "F2",
          "f3",
          "F3",
          "f4",
          "F4",
          "f5",
          "F5",
          "f6",
          "F6",
        ].includes(event.key)
      ) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
  );

  const handleClick = (fieldId) => {
    if (fieldId <= 22) {
      setFieldInFocus(fieldId);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (tabIndex === 0) {
        handleClick(1);
      } else if (tabIndex === 4) {
        handleClick(12);
      } else if (tabIndex === 5) {
        handleClick(15);
      }
    }, 200);
  }, [tabIndex]);

  const [buttonClick, setButtonClick] = useState(false);
  const onSubmit = () => {
    setButtonClick(!buttonClick);
    if (!isLoading && !isSubmitted) {
      const validateResponse = validateForm();
      validateResponse.then((data) => {
        submitAction(data);
        setHasExecuted(true);
      });
    }
  };

  useEffect(() => {
    if (clickKey?.keyA && !isLoading && !isSubmitted) {
      const validateResponse = validateForm();
      validateResponse.then((data) => {
        submitAction(data);
      });
    }
  }, [clickKey]);
  saveAndPrintShortKey(setClickKey);

  useEffect(() => {
    if (values.country === "UAE" && !values.emirate) {
      handleEdit(auth?.data?.company?.emirate, {
        field: "emirate",
      });
    } else if (values.country === "INDIA") {
      handleEdit(values.emirate, { field: "emirate" });
    }
    // else if (values.country !== "UAE") {
    //   handleEdit(null, { field: "emirate" });
    // }
  }, [values?.country]);

  useEffect(() => {
    if (ledgerGroupData) {
      const getSundryCreditorsIds = (parentId) => {
        const ids = [];
        ledgerGroupData?.data.forEach((item) => {
          if (item.master === parentId) {
            ids.push(item.id);
            ids.push(...getSundryCreditorsIds(item.uuid));
          }
        });
        return ids;
      };

      const sundryCreditors = ledgerGroupData?.data
        .filter((item) => item.name === "Sundry Creditors")
        .map((ids) => {
          return { uuid: ids.uuid, id: ids.id };
        })[0];

      const sundryCreditorsIds = getSundryCreditorsIds(sundryCreditors.uuid);
      sundryCreditorsIds.push(sundryCreditors.id);
      const filteredData = ledgerGroupData?.data.filter((item) =>
        sundryCreditorsIds.includes(item.id)
      );

      setSundryCreditorsGroups(filteredData);

      const ledgerGroupId = filteredData.find(
        (obj) => obj.name === "Sundry Creditors"
      ).id;

      if (!getLocalStore("ledgerGroupIdSupplier")) {
        handleEdit(ledgerGroupId, { field: "ledger_group_id" });
      }
      setLocalStore("ledgerGroupIdSupplier", ledgerGroupId);
    }
  }, [ledgerGroupData]);

  const openingBalanceModal = useDisclosure();
  const shippingAddressModal = useDisclosure();
  const contactPersonModal = useDisclosure();
  const advanceModal = useDisclosure();
  // const [enableCreditLimit, setEnableCreditLimit] = useState(true);

  // opening balance section starts here

  useEffect(() => {
    if (
      values?.primary_invoices.length > 0 &&
      openingBalanceArray.length === 0
    ) {
      let primaryInvoices = values.primary_invoices.map((item) => {
        return {
          ...item,
          total: parseFloat(item.total).toFixed(decimalLength),
        };
      });
      setOpeningBalanceArray(primaryInvoices);
    }
  }, [values?.primary_invoices]);

  const handleOpeningBalance = async () => {
    const dateString = (date) => {
      return date?.format(BE_DATE_FORMAT) ?? null;
    };

    let opening_balance = {
      date: openingBalance.date
        ? dateString(openingBalance.date)
        : getLocalStore("VDATE_MODEL"),
      due_date: openingBalance.due_date
        ? dateString(openingBalance.due_date)
        : getLocalStore("VDATE_MODEL"),
      invoice_number: openingBalance.invoice_number,
      reference_number: openingBalance.reference_number,
      total: openingBalance.total,
      done_by: openingBalance.done_by,
      cost_centre: openingBalance.cost_centre,
      id: openingBalance.id ? openingBalance.id : null,
    };

    if (
      !(
        opening_balance.date &&
        opening_balance.invoice_number &&
        opening_balance.total
      )
    ) {
      return toast({
        title: "Warning",
        description: "Please fill all the required fields",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }

    if (openingBalance.idx !== undefined && openingBalance.idx !== null) {
      setOpeningBalanceArray((prevArray) => {
        const updatedArray = [...prevArray];
        updatedArray[openingBalance.idx] = opening_balance;
        return updatedArray;
      });
    } else {
      setOpeningBalanceArray((prevArray) => [...prevArray, opening_balance]);
    }
    setopeningBalance({});
    openingBalanceModal.onClose();
  };

  const deleteFromOpeningBalance = (idx) => {
    let remainedArray = openingBalanceArray.filter((item, index) => {
      return index != idx;
    });
    if (remainedArray) {
      setOpeningBalanceArray(remainedArray);
    }
  };

  const dateFromString = (dateString) => {
    return dateString ? moment(dateString, BE_DATE_FORMAT) : null;
  };

  const editOpeningBalance = (idx) => {
    let remainedArray = openingBalanceArray
      .filter((item, index) => index === idx)
      .map((item) => {
        return {
          ...item,
          date: dateFromString(item.date),
          due_date: dateFromString(item.due_date),
          idx: idx,
        };
      });

    if (remainedArray) {
      setopeningBalance(...remainedArray);
      openingBalanceModal.onOpen();
    }
  };

  useEffect(() => {
    handleEdit(openingBalanceArray, { field: "primary_invoices" });
  }, [openingBalanceArray]);

  useEffect(() => {
    const totalBalance = openingBalanceArray.reduce(
      (acc, currentValue) => acc + parseFloat(currentValue.total),
      0
    );

    const totalAdvances = advancesArray.reduce(
      (acc, currentValue) => acc + parseFloat(currentValue.amount),
      0
    );

    const total = parseFloat(
      parseFloat(totalBalance) - parseFloat(totalAdvances)
    );

    setTotalOpeningBalance(total);
    handleEdit(total, { field: "opening_balance" });
  }, [openingBalanceArray, advancesArray]);

  // opening balance section ends here

  // advance section starts here

  useEffect(() => {
    if (values?.primary_advances.length > 0 && advancesArray.length === 0) {
      let primaryAdvances = values.primary_advances.map((item, index) => {
        return {
          ...item,
          index: index,
          amount: parseFloat(item.amount).toFixed(decimalLength),
        };
      });
      setAdvancesArray(primaryAdvances);
    }
  }, [values?.primary_advances]);

  const handleEditAdvanceItemSubmit = (item) => {
    let index = item.index !== undefined ? item.index : advancesArray.length;
    let items = [
      { ...item, index: index },
      ...advancesArray.filter((i) => i.index !== item.index),
    ];
    setAdvancesArray(items);
    setEditingAdvanceItem({});
    advanceModal.onClose();
  };

  const editAdvanceItem = (item) => {
    setEditingAdvanceItem({ ...item, invoice_number: item.invoice_number });
    advanceModal.onOpen();
  };

  const deleteFromAdvances = (idx) => {
    let remainedArray = advancesArray.filter((item, index) => {
      return index != idx;
    });
    if (remainedArray) {
      setAdvancesArray(remainedArray);
    }
  };

  useEffect(() => {
    if (advancesArray) {
      let finalAdvanceData = advancesArray.map((item) => {
        return {
          ...item,
          id: item.id ? item.id : null,
          balance: item.amount,
        };
      });
      handleEdit(finalAdvanceData, { field: "primary_advances" });
    }
  }, [advancesArray]);

  // contact person section starts here
  useEffect(() => {
    if (values?.contact_person.length > 0 && contactPersonArray.length === 0) {
      setContactPersonArray(values.contact_person);
    }
  }, [values?.contact_person]);

  const handleContactPerson = async () => {
    let contact_person = {
      salutation: contactPerson.salutation || null,
      name: contactPerson.name || null,
      designation: contactPerson.designation || null,
      department: contactPerson.department || null,
      email: contactPerson.email || null,
      work_phone: contactPerson.work_phone || null,
      mobile_number: contactPerson.mobile_number || null,
      id: contactPerson.id || null,
      owner: companyID,
    };
    if (contactPerson.idx !== undefined && contactPerson.idx !== null) {
      setContactPersonArray((prevArray) => {
        const updatedArray = [...prevArray];
        updatedArray[contactPerson.idx] = contact_person;
        return updatedArray;
      });
    } else {
      setContactPersonArray((prevArray) => [...prevArray, contact_person]);
    }
    setContactPerson({});
    contactPersonModal.onClose();
  };

  const deleteFromContactPerson = (idx) => {
    let remainedArray = contactPersonArray.filter((item, index) => {
      return index != idx;
    });
    if (remainedArray) {
      setContactPersonArray(remainedArray);
    }
  };

  const editContactPerson = (idx) => {
    let remainedArray = contactPersonArray
      .filter((item, index) => index === idx)
      .map((item) => {
        return { ...item, idx: idx };
      });

    if (remainedArray) {
      setContactPerson(...remainedArray);
      contactPersonModal.onOpen();
    }
  };

  useEffect(() => {
    handleEdit(contactPersonArray, { field: "contact_person" });
  }, [contactPersonArray]);

  const handleContactPersonClose = () => {
    contactPersonModal.onClose();
    setFormErrors(null);
    setContactPerson({});
  };
  //contact person section ends here

  //shipping address section starts here
  useEffect(() => {
    if (
      values?.shipping_address.length > 0 &&
      shippingAddressArray.length === 0
    ) {
      setShippingAddressArray(values.shipping_address);
    }
  }, [values?.shipping_address]);

  const handleShippingAddress = async () => {
    let shipping_address = {
      address: shippingAddress.address,
      country: shippingAddress.country,
      emirate: shippingAddress.emirate,
      id: shippingAddress.id ? shippingAddress.id : null,
      owner: companyID,
    };
    if (shippingAddress.idx !== undefined && shippingAddress.idx !== null) {
      setShippingAddressArray((prevArray) => {
        const updatedArray = [...prevArray];
        updatedArray[shippingAddress.idx] = shipping_address;
        return updatedArray;
      });
    } else {
      setShippingAddressArray((prevArray) => [...prevArray, shipping_address]);
    }
    setShippingAddress({});
    shippingAddressModal.onClose();
  };

  const deleteFromShippingAddress = (idx) => {
    let remainedArray = shippingAddressArray.filter((item, index) => {
      return index != idx;
    });
    if (remainedArray) {
      setShippingAddressArray(remainedArray);
    }
  };

  const editShippingAddress = (idx) => {
    let remainedArray = shippingAddressArray
      .filter((item, index) => index === idx)
      .map((item) => {
        return { ...item, idx: idx };
      });

    if (remainedArray) {
      setShippingAddress(...remainedArray);
      shippingAddressModal.onOpen();
    }
  };

  useEffect(() => {
    handleEdit(shippingAddressArray, { field: "shipping_address" });
  }, [shippingAddressArray]);

  const handleShippingAddressClose = () => {
    shippingAddressModal.onClose();
    setShippingAddress({});
  };
  //shipping address section ends here
  useEffect(() => {
    setSelectCountry(countryRef.current?.value);
  }, [countryRef.current?.value]);

  useEffect(() => {
    if (values.trn === "" || values.trn === null) {
      values.tax_registration_type = "UNREGISTERED_BUSINESS";
    } else {
      values.tax_registration_type = "REGISTERED_BUSINESS";
    }
  }, [values.trn]);

  useEffect(() => {
    setHasExecuted(false);
  }, []);

  useEffect(() => {
    setEnableCreditLimit1(values.enable_credit_limit);
  }, [values.enable_credit_limit]);

  const handleOpenAdvanceModal = () => {
    advanceModal.onOpen();
  };

  const closeModal = () => {
    setEditingAdvanceItem({
      invoice_number: "",
      amount: "",
      description: "",
      date: "",
    });
    advanceModal.onClose();
  };

  useEffect(() => {
    if (
      values.cost_centres &&
      values.cost_centres?.length > 0 &&
      costCenters.length === 0 &&
      costCenterData
    ) {
      setCostCenters(
        costCenterData.data?.filter((i) =>
          values.cost_centres?.includes(i.id)
        ) || []
      );
    }
  }, [values.cost_centres, costCenterData]);

  useEffect(() => {
    if (costCenters) {
      const ids = costCenters?.map((i) => i.id);
      handleEdit(ids, {
        field: "cost_centres",
      });
    }
  }, [costCenters]);

  useEffect(() => {
    if (
      values.done_bys &&
      values.done_bys?.length > 0 &&
      doneBys.length === 0 &&
      doneByData
    ) {
      setDoneBys(
        doneByData.data?.filter((i) => values.done_bys?.includes(i.id)) || []
      );
    }
  }, [values.done_bys, doneByData]);

  useEffect(() => {
    if (doneBys) {
      const ids = doneBys?.map((i) => i.id);
      handleEdit(ids, {
        field: "done_bys",
      });
    }
  }, [doneBys]);

  return (
    <>
      {!isMobile ? (
        <IModalOut>
          <IHeadFav
            text={
              isApproval
                ? "Approve"
                : type === "edit"
                ? "Edit"
                : "Add" + " Supplier"
            }
            isFavour={isToggledFav}
            onClick={handleFavourite}
          />
          <Tabs
            isFitted
            onChange={(index) => setTabIndex(index)}
            index={tabIndex}
            colorScheme="blue"
          >
            <TabList mb={5}>
              <ITab tooltipLabel="f1">Basic Info</ITab>
              <ITab tooltipLabel="f2">Opening Balance</ITab>
              <ITab tooltipLabel="f3">Shipping Address</ITab>
              <ITab tooltipLabel="f4">Contact Person</ITab>
              <ITab tooltipLabel="f5">Due date Remainder</ITab>
              <ITab tooltipLabel="f6">Other Info</ITab>
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                <IModalIn>
                  <IHStack>
                    <IVStack>
                      <FormControl>
                        <ITextField
                          disabled={isApproval}
                          label="Supplier Name"
                          variant="outlined"
                          type="text"
                          name="name"
                          onChange={(event) =>
                            handleEdit(event.target.value, { field: "name" })
                          }
                          onFocus={(e) => {
                            if (e) {
                              handleClick(1);
                            }
                          }}
                          inputRef={nameRef}
                          value={values.name || ""}
                          isValid={isNotValid}
                          isRequired={true}
                          errorMessage={errors.name}
                          buttonClick={buttonClick}
                        />
                      </FormControl>
                      <IHStack>
                        <FormControl>
                          <ITextField
                            disabled={isApproval}
                            label="Mobile Number"
                            type="number"
                            name="mobile"
                            inputRef={mobileRef}
                            onChange={(event) =>
                              handleEdit(event.target.value, {
                                field: "mobile",
                              })
                            }
                            onFocus={(e) => {
                              if (e) {
                                handleClick(2);
                              }
                            }}
                            value={values.mobile || ""}
                            // isValid={isNotValid}
                            // errorMessage={errors.mobile}
                          />
                        </FormControl>
                        <FormControl>
                          <ISelect
                            disabled={isApproval}
                            placeholder="Select Ledger Group"
                            value={
                              (values.ledger_group_id ??
                                sundryCreditorsGroups.find(
                                  (obj) => obj.name === "Sundry Creditors"
                                )?.id) ||
                              ""
                            }
                            inputRef={ledgerGroupRef}
                            id="ledger"
                            onChange={(e) =>
                              handleEdit(e.target.value, {
                                field: "ledger_group_id",
                              })
                            }
                            onFocus={(e) => {
                              if (e) {
                                handleClick(3);
                              }
                            }}
                            errorMessage={errors.ledger_group_id}
                            isRequired={true}
                            isValid={errors.ledger_group_id === undefined}
                            buttonClick={buttonClick}
                          >
                            {sundryCreditorsGroups.map((ledger, key) => (
                              <IOption value={ledger.id} key={key}>
                                {ledger.name}
                              </IOption>
                            ))}
                          </ISelect>
                        </FormControl>
                      </IHStack>

                      <IHStack>
                        <ITextField
                          fullWidth={false}
                          disabled={isApproval}
                          label="Credit Period(days)"
                          type="number"
                          name="payment_term"
                          inputRef={creditPeriodRef}
                          onFocus={(e) => {
                            if (e) {
                              handleClick(4);
                            }
                          }}
                          onChange={(event) =>
                            handleEdit(event.target.value, {
                              field: "payment_term",
                            })
                          }
                          value={values.payment_term || ""}
                        />
                        <Box
                          display="flex"
                          justifyContent="start"
                          alignItems="center"
                          height="39px"
                          width="202px"
                        >
                          <Box
                            borderWidth={1}
                            borderColor="#bababa"
                            borderRadius="5px"
                            borderRight="none"
                            height="100%"
                            display="flex"
                            alignItems="center"
                          >
                            <Checkbox
                              disabled={isApproval}
                              isChecked={enableCreditLimit1 || ""}
                              onChange={(e) => {
                                setEnableCreditLimit1(e.target.checked);
                              }}
                              px={4}
                            ></Checkbox>
                          </Box>
                          <ITextField
                            disabled={isApproval || !enableCreditLimit1}
                            label="Max Credit limit"
                            variant="outlined"
                            borderColor="transparent"
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderWidth: 0,
                              },
                            }}
                            border="none"
                            size="small"
                            type="number"
                            name="maximum_credit_limit"
                            inputRef={maxCreditLimitRef}
                            onFocus={(e) => {
                              if (e) {
                                handleClick(5);
                              }
                            }}
                            onChange={(event) =>
                              handleEdit(event.target.value, {
                                field: "maximum_credit_limit",
                              })
                            }
                            value={values.maximum_credit_limit || ""}
                          />
                        </Box>
                      </IHStack>
                    </IVStack>
                    <Box
                      h="140px"
                      w="140px"
                      border="1px solid grey"
                      marginLeft="16%"
                    >
                      <Image
                        height="100%"
                        // width="100%"
                        src={userProfile}
                      ></Image>
                    </Box>
                  </IHStack>

                  <Stack
                    direction="row"
                    marginRight={4}
                    marginBottom={showMoreWeb ? 2 : 0}
                  >
                    <Link
                      sx={{
                        "&:hover": {
                          textDecoration: "none",
                        },
                      }}
                      onClick={() => setShowMoreWeb(!showMoreWeb)}
                    >
                      <Text
                        fontSize="xs"
                        color="brand.bg_blue"
                        sx={{
                          "&:hover": {
                            textColor: "blue.500",
                          },
                        }}
                      >
                        Show {showMoreWeb ? "Less" : "More"}
                        {showMoreWeb ? <ArrowUpIcon /> : <ArrowDownIcon />}
                      </Text>
                    </Link>
                  </Stack>
                  {showMoreWeb ? (
                    <>
                      <IHStack>
                        <FormControl>
                          <ITextarea
                            height="147px"
                            width="325px"
                            disabled={isApproval}
                            placeholder="Address"
                            onChange={(event) =>
                              handleEdit(event.target.value, {
                                field: "address",
                              })
                            }
                            value={values.address || ""}
                            inputRef={addressRef}
                            onFocus={(e) => {
                              if (e) {
                                handleClick(6);
                              }
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "ArrowDown") {
                                handleEdit(values.address + "\n", {
                                  field: "address",
                                });
                              }
                            }}
                          />
                        </FormControl>
                        <IVStack>
                          <FormControl>
                            <ISelect
                              disabled={
                                isApproval
                                  ? true
                                  : values.country === "UAE" ||
                                    values.country === "INDIA"
                                  ? false
                                  : true
                              }
                              placeholder={
                                values.country?.includes("UAE")
                                  ? "Select Emirates"
                                  : "Select State"
                              }
                              name="emirate"
                              value={values.emirate || ""}
                              id="emirate"
                              onChange={(e) =>
                                handleEdit(e.target.value, {
                                  field: "emirate",
                                })
                              }
                              inputRef={emirateRef}
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(7);
                                }
                              }}
                              isValid={errors.emirate === undefined}
                              errorMessage={errors.emirate}
                            >
                              {values.country === "UAE" &&
                                emiratesList.map((emirate, key) => (
                                  <IOption value={emirate.value} key={key}>
                                    {emirate.name}
                                  </IOption>
                                ))}
                              {values.country === "INDIA" &&
                                emiratesListIndia
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .map((emirate, key) => (
                                    <IOption value={emirate.value} key={key}>
                                      {emirate.name}
                                    </IOption>
                                  ))}
                            </ISelect>
                          </FormControl>
                          <FormControl>
                            <ISelect
                              disabled={isApproval}
                              placeholder="Select Country"
                              name="country"
                              value={values.country || ""}
                              inputRef={countryRef}
                              id="country"
                              onChange={(e) =>
                                handleEdit(e.target.value, {
                                  field: "country",
                                })
                              }
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(8);
                                }
                              }}
                              isValid={errors.country === undefined}
                              errorMessage={errors.country}
                            >
                              {countrieslist.map((country, key) => (
                                <IOption value={country.value} key={key}>
                                  {country.name}
                                </IOption>
                              ))}
                            </ISelect>
                          </FormControl>
                          <FormControl>
                            <ITextField
                              disabled={isApproval}
                              label="Email"
                              variant="outlined"
                              size="small"
                              type="text"
                              name="email"
                              inputRef={emailRef}
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(9);
                                }
                              }}
                              onChange={(event) =>
                                handleEdit(event.target.value, {
                                  field: "email",
                                })
                              }
                              value={values.email || ""}
                              isValid={errors.email === undefined}
                              errorMessage={errors.email}
                            />
                          </FormControl>
                        </IVStack>
                      </IHStack>
                      <IHStack>
                        <FormControl>
                          <ITextField
                            disabled={isApproval}
                            // fullWidth
                            label={
                              values.country === "INDIA"
                                ? "GST No"
                                : values.country === "UAE"
                                ? "TRN NO"
                                : values.country === "SAUDI"
                                ? "VAT No"
                                : values.country === "BAHRAIN"
                                ? "VAT No"
                                : values.country === "QATAR"
                                ? "VAT No"
                                : values.country === "OMAN"
                                ? "VAT No"
                                : "TAX No"
                            }
                            variant="outlined"
                            size="small"
                            type="text"
                            name="trn"
                            inputRef={trnRef}
                            onFocus={(e) => {
                              if (e) {
                                handleClick(10);
                              }
                            }}
                            onChange={(event) =>
                              handleEdit(event.target.value, {
                                field: "trn",
                              })
                            }
                            onKeyDown={(event) => {
                              if (values.trn === "" || values.trn === null) {
                                values.tax_registration_type =
                                  "UNREGISTERED_BUSINESS";
                              } else {
                                values.tax_registration_type =
                                  "REGISTERED_BUSINESS";
                              }
                              onSubmit();
                            }}
                            value={values.trn || ""}
                            isValid={errors.trn === undefined}
                            errorMessage={errors.trn}
                          />
                        </FormControl>
                        <FormControl>
                          <ISelect
                            disabled={isApproval}
                            placeholder="Tax Registration type"
                            name="tax_registration_type"
                            value={
                              values.tax_registration_type ||
                              "UNREGISTERED_BUSINESS"
                            }
                            // value={values.trn !=null? "REGISTERED_BUSINESS": "UNREGISTERED_BUSINESS"}
                            inputRef={taxRegistrationTypeRef}
                            id="tax_registration_type"
                            onChange={(e) =>
                              handleEdit(e.target.value, {
                                field: "tax_registration_type",
                              })
                            }
                            onFocus={(e) => {
                              if (e) {
                                handleClick(11);
                              }
                            }}
                            isValid={errors.tax_registration_type === undefined}
                            errorMessage={errors.tax_registration_type}
                          >
                            {taxregistrationtypeList.map((tax, key) => (
                              <IOption value={tax.value} key={key}>
                                {tax.name}
                              </IOption>
                            ))}
                          </ISelect>
                        </FormControl>
                      </IHStack>
                      <Checkbox
                        disabled={isApproval}
                        // mt="10px"
                        // ml="auto"
                        // mr="45px"
                        isChecked={suspand}
                        onChange={(e) => {
                          setSuspand(!suspand);
                        }}
                      >
                        Required Suspend
                      </Checkbox>
                    </>
                  ) : (
                    ""
                  )}
                </IModalIn>
              </TabPanel>
              <TabPanel p={0}>
                <IModalIn>
                  <IHStack>
                    <Button
                      onClick={openingBalanceModal.onOpen}
                      h="30px"
                      colorScheme="blue"
                      variant="ghost"
                      type="submit"
                      size="lg"
                      minH={12}
                      disabled={isApproval}
                    >
                      Add opening balance (+)
                    </Button>
                    <Button
                      onClick={handleOpenAdvanceModal}
                      h="30px"
                      colorScheme="blue"
                      variant="ghost"
                      type="submit"
                      size="lg"
                      minH={12}
                      pl={0}
                      disabled={isApproval}
                    >
                      Add Advance (+)
                    </Button>
                    <Spacer />
                    <Text>Total: {totalOpeningBalance}</Text>
                  </IHStack>

                  <Stack spacing={4}>
                    {openingBalanceArray.length > 0 &&
                      openingBalanceArray.map((item, index) => (
                        <Card>
                          <CardContent
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid
                              templateColumns="repeat(2, 1fr)"
                              gap={2}
                              w="95%"
                            >
                              <GridItem colSpan={1}>
                                <Text>Date : {item.date}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Due Date : {item.due_date}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>
                                  Invoice Number : {item.invoice_number}
                                </Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>
                                  Reference Number : {item.reference_number}
                                </Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text fontWeight="bold">
                                  Invoice Balance : {item.total}
                                </Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>
                                  Cost Center :&nbsp;
                                  {
                                    costCenterData?.data
                                      ?.filter(
                                        (items) => item.cost_centre === items.id
                                      )
                                      .map((item) => item.name)[0]
                                  }
                                </Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>
                                  Done By :&nbsp;
                                  {
                                    doneByData?.data
                                      ?.filter(
                                        (items) => item.done_by === items.id
                                      )
                                      .map((item) => item.name)[0]
                                  }
                                </Text>
                              </GridItem>
                            </Grid>
                            <Box width="min-content">
                              <IconButton
                                disabled={isApproval}
                                m={1}
                                onClick={() => editOpeningBalance(index)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                disabled={isApproval}
                                m={1}
                                onClick={() => deleteFromOpeningBalance(index)}
                              >
                                <DeleteIcon color="red" />
                              </IconButton>
                            </Box>
                          </CardContent>
                        </Card>
                      ))}

                    {advancesArray.length > 0 &&
                      advancesArray.map((item, index) => (
                        <Card>
                          <CardContent
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid
                              templateColumns="repeat(2, 1fr)"
                              gap={2}
                              w="95%"
                            >
                              <GridItem colSpan={1}>
                                <Text>Advance No : {item.invoice_number}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>
                                  Advance Amount :{" "}
                                  {parseFloat(item.amount).toFixed(
                                    decimalLength
                                  )}
                                </Text>
                              </GridItem>

                              <GridItem colSpan={1}>
                                <Text>Date : {item.date}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Description : {item.description}</Text>
                              </GridItem>
                            </Grid>
                            <Box width="min-content">
                              <IconButton
                                disabled={isApproval}
                                m={1}
                                onClick={() => editAdvanceItem(item)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                disabled={isApproval}
                                m={1}
                                onClick={() => deleteFromAdvances(index)}
                              >
                                <DeleteIcon color="red" />
                              </IconButton>
                            </Box>
                          </CardContent>
                        </Card>
                      ))}
                  </Stack>

                  <Modal
                    isOpen={openingBalanceModal.isOpen}
                    onClose={openingBalanceModal.onClose}
                    isCentered
                  >
                    <ModalOverlay />
                    <ModalContent width={"100%"}>
                      <ModalCloseButton />

                      <ModalBody>
                        <IModalOut>
                          <IHead text={"Add opening balance"} />
                          <IModalIn>
                            <IHStack>
                              <FormControl>
                                <IDatePicker
                                  dateLabel="Date *"
                                  date={
                                    openingBalance.date ||
                                    getLocalStore("VDATE_MODEL") ||
                                    null
                                  }
                                  setDate={(date) =>
                                    setopeningBalance({
                                      ...openingBalance,
                                      date: date,
                                    })
                                  }
                                />
                              </FormControl>
                              <FormControl>
                                <TextField
                                  label="Invoice No *"
                                  placeholder="required field"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  InputLabelProps={{ shrink: true }}
                                  value={openingBalance.invoice_number || ""}
                                  onChange={(e) => {
                                    setopeningBalance({
                                      ...openingBalance,
                                      invoice_number: e.target.value,
                                    });
                                  }}
                                />
                              </FormControl>
                            </IHStack>
                            <IHStack>
                              <FormControl>
                                <IDatePicker
                                  dateLabel="Due Date"
                                  date={
                                    openingBalance.due_date ||
                                    getLocalStore("VDATE_MODEL") ||
                                    null
                                  }
                                  setDate={(date) =>
                                    setopeningBalance({
                                      ...openingBalance,
                                      due_date: date,
                                    })
                                  }
                                />
                              </FormControl>
                              <FormControl>
                                <TextField
                                  label="Reference No"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  value={openingBalance.reference_number || ""}
                                  onChange={(e) => {
                                    setopeningBalance({
                                      ...openingBalance,
                                      reference_number: e.target.value,
                                    });
                                  }}
                                />
                              </FormControl>
                            </IHStack>
                            <FormControl>
                              <TextField
                                fullWidth
                                label="Invoice balance *"
                                placeholder="required field"
                                type="number"
                                variant="outlined"
                                size="small"
                                value={openingBalance.total || ""}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (inputValue >= 0 || inputValue === "") {
                                    setopeningBalance({
                                      ...openingBalance,
                                      total: inputValue,
                                    });
                                  }
                                }}
                                // onChange={(e) => {
                                //   setopeningBalance({
                                //     ...openingBalance,
                                //     total: e.target.value,
                                //   });
                                // }}
                              />
                            </FormControl>
                            <CostCenterSelectionField
                              costCenter={openingBalance.cost_centre}
                              setCostCenter={(costCenter) => {
                                setopeningBalance({
                                  ...openingBalance,
                                  cost_centre: costCenter,
                                });
                              }}
                            />
                            <DoneBySelectionField
                              doneBy={openingBalance.done_by}
                              setDoneBy={(doneBy) => {
                                setopeningBalance({
                                  ...openingBalance,
                                  done_by: doneBy,
                                });
                              }}
                            />
                          </IModalIn>
                          <IButtonOut pt={6}>
                            <IButton
                              onClick={handleOpeningBalance}
                              type={"add"}
                            />
                          </IButtonOut>
                        </IModalOut>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </IModalIn>
              </TabPanel>
              <TabPanel p={0}>
                <IModalIn>
                  <Button
                    onClick={shippingAddressModal.onOpen}
                    h="30px"
                    colorScheme="blue"
                    variant="ghost"
                    type="submit"
                    size="lg"
                    minH={12}
                    disabled={isApproval}
                  >
                    Add shipping address (+)
                  </Button>

                  <Stack spacing={4}>
                    {shippingAddressArray.length > 0 &&
                      shippingAddressArray.map((item, index) => (
                        <Card>
                          <CardContent
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid
                              templateColumns="repeat(2, 1fr)"
                              gap={2}
                              w="95%"
                            >
                              <GridItem colSpan={1}>
                                <Text>Address : {item.address}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Country : {item.country}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Emirates : {item.emirate}</Text>
                              </GridItem>
                            </Grid>
                            <Box width="min-content">
                              <IconButton
                                disabled={isApproval}
                                m={1}
                                onClick={() => editShippingAddress(index)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                disabled={isApproval}
                                m={1}
                                onClick={() => deleteFromShippingAddress(index)}
                              >
                                <DeleteIcon color="red" />
                              </IconButton>
                            </Box>
                          </CardContent>
                        </Card>
                      ))}
                  </Stack>

                  <Modal
                    size="xl"
                    isOpen={shippingAddressModal.isOpen}
                    onClose={handleShippingAddressClose}
                    isCentered
                  >
                    <ModalOverlay />
                    <ModalContent>
                      <ModalCloseButton />
                      <ModalBody>
                        <IModalOut>
                          <IHead text={"Add shipping address"} />
                          <IModalIn>
                            <FormControl>
                              <ITextarea
                                style={{ width: "100%" }}
                                variant="outlined"
                                label="Address"
                                multiline
                                minRows={3}
                                onChange={(e) => {
                                  setShippingAddress({
                                    ...shippingAddress,
                                    address: e.target.value,
                                  });
                                }}
                                value={shippingAddress.address || ""}
                              />
                            </FormControl>
                            <IHStack>
                              <FormControl>
                                <ISelect
                                  placeholder="Select Country"
                                  as={Select}
                                  onChange={(e) => {
                                    setShippingAddress({
                                      ...shippingAddress,
                                      country: e.target.value,
                                    });
                                  }}
                                  value={shippingAddress.country || ""}
                                >
                                  {countrieslist.map((country, key) => (
                                    <option value={country.value} key={key}>
                                      {country.name}
                                    </option>
                                  ))}
                                </ISelect>
                              </FormControl>
                              <FormControl>
                                <ISelect
                                  disabled={
                                    shippingAddress.country === "UAE" ||
                                    shippingAddress.country === "INDIA"
                                      ? false
                                      : true
                                  }
                                  placeholder="Select Emirates"
                                  as={Select}
                                  value={shippingAddress.emirate || ""}
                                  onChange={(e) => {
                                    setShippingAddress({
                                      ...shippingAddress,
                                      emirate: e.target.value,
                                    });
                                  }}
                                >
                                  {shippingAddress.country === "UAE " &&
                                    emiratesList.map((emirate, key) => (
                                      <option value={emirate.value} key={key}>
                                        {emirate.name}
                                      </option>
                                    ))}
                                  {shippingAddress.country === "INDIA" &&
                                    emiratesListIndia
                                      .sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                      )
                                      .map((emirate, key) => (
                                        <option value={emirate.value} key={key}>
                                          {emirate.name}
                                        </option>
                                      ))}
                                </ISelect>
                              </FormControl>
                            </IHStack>
                          </IModalIn>

                          <IButtonOut pt={6}>
                            <IButton
                              onClick={handleShippingAddress}
                              type={"add"}
                            />
                          </IButtonOut>
                        </IModalOut>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </IModalIn>
              </TabPanel>
              <TabPanel p={0}>
                <IModalIn>
                  <Button
                    h="30px"
                    colorScheme="blue"
                    variant="ghost"
                    type="submit"
                    size="lg"
                    onClick={contactPersonModal.onOpen}
                    minH={12}
                    disabled={isApproval}
                  >
                    Add contact person (+)
                  </Button>

                  <Stack spacing={4}>
                    {contactPersonArray?.length > 0 &&
                      contactPersonArray.map((item, index) => (
                        <Card>
                          <CardContent
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid
                              templateColumns="repeat(2, 1fr)"
                              gap={2}
                              w="95%"
                            >
                              <GridItem colSpan={1}>
                                <Text>Salutation : {item.salutation}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Name : {item.name}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Destination : {item.designation}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Department : {item.department}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Email : {item.email}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Work Phone : {item.work_phone}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>
                                  Mobile Number : {item.mobile_number}
                                </Text>
                              </GridItem>
                            </Grid>
                            <Box width="min-content">
                              <IconButton
                                disabled={isApproval}
                                m={1}
                                onClick={() => editContactPerson(index)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                disabled={isApproval}
                                m={1}
                                onClick={() => deleteFromContactPerson(index)}
                              >
                                <DeleteIcon color="red" />
                              </IconButton>
                            </Box>
                          </CardContent>
                        </Card>
                      ))}
                  </Stack>

                  <Modal
                    isOpen={contactPersonModal.isOpen}
                    onClose={handleContactPersonClose}
                    isCentered
                  >
                    <ModalOverlay />
                    <ModalContent width={isMobile ? "90%" : "100%"}>
                      <ModalCloseButton />
                      <ModalBody>
                        <IModalOut>
                          <IHead text={"Add contact person"} />
                          <IModalIn>
                            <IHStack>
                              <FormControl>
                                <TextField
                                  fullWidth
                                  label="Salutation"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  onChange={(e) => {
                                    setContactPerson({
                                      ...contactPerson,
                                      salutation: e.target.value,
                                    });
                                  }}
                                  value={contactPerson.salutation || ""}
                                />
                              </FormControl>
                              <FormControl>
                                <TextField
                                  fullWidth
                                  label="Name"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  onChange={(e) => {
                                    setContactPerson({
                                      ...contactPerson,
                                      name: e.target.value,
                                    });
                                  }}
                                  value={contactPerson.name || ""}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                />
                              </FormControl>
                            </IHStack>
                            <IHStack>
                              <FormControl>
                                <TextField
                                  fullWidth
                                  label="Designation"
                                  variant="outlined"
                                  size="small"
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  type="text"
                                  onChange={(e) => {
                                    setContactPerson({
                                      ...contactPerson,
                                      designation: e.target.value,
                                    });
                                  }}
                                  value={contactPerson.designation || ""}
                                />
                              </FormControl>
                              <FormControl>
                                <TextField
                                  fullWidth
                                  label="Department"
                                  variant="outlined"
                                  size="small"
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  type="text"
                                  onChange={(e) => {
                                    setContactPerson({
                                      ...contactPerson,
                                      department: e.target.value,
                                    });
                                  }}
                                  value={contactPerson.department || ""}
                                />
                              </FormControl>
                            </IHStack>
                            <TextField
                              error={formErrors === null ? false : true}
                              fullWidth
                              label="Email"
                              variant="outlined"
                              size="small"
                              type="email"
                              InputProps={{ style: { fontSize: "12px" } }}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                              onChange={(e) => {
                                const testEmail =
                                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                                setContactPerson({
                                  ...contactPerson,
                                  email: e.target.value,
                                });
                                if (!testEmail.test(e.target.value)) {
                                  setFormErrors({
                                    email: "Enter a valid email",
                                  });
                                } else {
                                  setFormErrors(null);
                                }
                              }}
                              value={contactPerson.email || ""}
                            />

                            {formErrors && formErrors.email && (
                              <Message
                                type="error"
                                message={formErrors.email}
                              />
                            )}
                            <IHStack>
                              <FormControl>
                                <TextField
                                  fullWidth
                                  label=" WorkPhone"
                                  variant="outlined"
                                  size="small"
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  type="number"
                                  onChange={(e) => {
                                    setContactPerson({
                                      ...contactPerson,
                                      work_phone: e.target.value,
                                    });
                                  }}
                                  value={contactPerson.work_phone || ""}
                                />
                              </FormControl>
                              <FormControl>
                                <TextField
                                  fullWidth
                                  label="Mobile Number"
                                  variant="outlined"
                                  size="small"
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  type="number"
                                  inputRef={mobileRef}
                                  onChange={(e) => {
                                    setContactPerson({
                                      ...contactPerson,
                                      mobile_number: e.target.value,
                                    });
                                  }}
                                  value={contactPerson.mobile_number || ""}
                                  // onChange={(event) =>
                                  //   handleEdit(event.target.value, {
                                  //     field: "mobile",
                                  //   })
                                  // }
                                  // onFocus={(e) => {
                                  //   if (e) {
                                  //     handleClick(7);
                                  //   }
                                  // }}
                                />
                                {/* {errors.mobile && !isNotValid && (
                              <Message type="error" message={errors.mobile} />
                            )} */}
                              </FormControl>
                            </IHStack>
                          </IModalIn>
                          <IButtonOut pt={6}>
                            <IButton onClick={handleContactPerson} type="add" />
                          </IButtonOut>
                        </IModalOut>
                      </ModalBody>
                    </ModalContent>
                  </Modal>
                </IModalIn>
              </TabPanel>

              <TabPanel p={0}>
                <IModalIn>
                  <ISelect
                    inputRef={communicationChannelRef}
                    onFocus={(e) => {
                      handleClick(12);
                    }}
                    placeholder="Communication Channel"
                  >
                    <IOption>Whats up</IOption>
                    <IOption>Text message</IOption>
                    <IOption>Email</IOption>
                  </ISelect>
                  <IHStack>
                    <Text>Enable remainder on due date</Text>
                    <Spacer />
                    <Switch
                      disabled={false}
                      isChecked={true}
                      onChange={(e) => {
                        // onFieldTransfer(id, !e.target.checked);
                        // setSRSICDNT(e.target.checked);
                      }}
                    />
                  </IHStack>
                  <IHStack>
                    <Text>Specify number of days before due date</Text>
                    <Spacer />

                    <TextField
                      disabled={isApproval}
                      label="0"
                      variant="outlined"
                      size="small"
                      type="number"
                      name="distance"
                      inputRef={noOfDayBeforDueDateRef}
                      onFocus={(e) => {
                        handleClick(13);
                      }}
                    />
                  </IHStack>
                  <IHStack>
                    <Text>Specify number of days after due date</Text>
                    <Spacer />
                    <TextField
                      disabled={isApproval}
                      label="0"
                      variant="outlined"
                      size="small"
                      type="number"
                      name="distance"
                      onChange={(event) => {}}
                      inputRef={noOfDayAfterDueDateRef}
                      onFocus={(e) => {
                        handleClick(14);
                      }}
                    />
                  </IHStack>
                </IModalIn>
              </TabPanel>
              <TabPanel p={0}>
                <IModalIn>
                  <FormControl>
                    <TextField
                      disabled={isApproval}
                      fullWidth
                      label="Alias Name"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="alias_name"
                      inputRef={aliasRef}
                      onChange={(event) =>
                        handleEdit(event.target.value, {
                          field: "alias_name",
                        })
                      }
                      onFocus={(e) => {
                        if (e) {
                          handleClick(15);
                        }
                      }}
                      value={values.alias_name || ""}
                    />
                  </FormControl>

                  <IHStack>
                    <FormControl>
                      <IDatePicker
                        disabled={isApproval}
                        dateLabel="D.O.B"
                        date={values.date_of_birth}
                        setDate={(e) => {
                          handleEdit(e.format("YYYY-MM-DD"), {
                            field: "date_of_birth",
                          });
                        }}
                      ></IDatePicker>
                    </FormControl>
                    <FormControl>
                      <TextField
                        disabled={isApproval}
                        fullWidth
                        label="PAN Number"
                        variant="outlined"
                        size="small"
                        type="number"
                        name="pan_number"
                        inputRef={panNumberRef}
                        onChange={(event) =>
                          handleEdit(event.target.value, {
                            field: "pan_number",
                          })
                        }
                        onFocus={(e) => {
                          if (e) {
                            handleClick(16);
                          }
                        }}
                        value={values.pan_number || ""}
                      />
                    </FormControl>
                  </IHStack>
                  <IHStack>
                    <FormControl>
                      <TextField
                        disabled={isApproval}
                        fullWidth
                        label="Website"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="website"
                        onChange={(event) =>
                          handleEdit(event.target.value, { field: "website" })
                        }
                        onFocus={(e) => {
                          if (e) {
                            handleClick(17);
                          }
                        }}
                        inputRef={webSiteRef}
                        value={values.website || ""}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        disabled={isApproval}
                        fullWidth
                        label="Distance"
                        variant="outlined"
                        size="small"
                        type="number"
                        name="distance"
                        inputRef={distanceRef}
                        onChange={(event) =>
                          handleEdit(event.target.value, {
                            field: "distance",
                          })
                        }
                        onFocus={(e) => {
                          if (e) {
                            handleClick(18);
                          }
                        }}
                        value={values.distance || ""}
                      />
                    </FormControl>
                  </IHStack>
                  <IHStack>
                    <FormControl>
                      <ITextarea
                        disabled={isApproval}
                        placeholder="Bank detailes"
                        onChange={(event) =>
                          handleEdit(event.target.value, {
                            field: "bank_details",
                          })
                        }
                        value={values.bank_details || ""}
                        as={Textarea}
                        name="bank_details"
                        inputRef={bankDetailesRef}
                        onFocus={(e) => {
                          if (e) {
                            handleClick(19);
                          }
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "ArrowDown") {
                            handleEdit(values.bank_details + "\n", {
                              field: "bank_details",
                            });
                          }
                        }}
                      />
                    </FormControl>
                    <FormControl>
                      <ITextarea
                        disabled={isApproval}
                        placeholder="Remarks"
                        onChange={(event) =>
                          handleEdit(event.target.value, {
                            field: "remarks",
                          })
                        }
                        value={values.remarks || ""}
                        as={Textarea}
                        name="remarks"
                        inputRef={remarksRef}
                        onFocus={(e) => {
                          if (e) {
                            handleClick(20);
                          }
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "ArrowDown") {
                            handleEdit(values.remarks + "\n", {
                              field: "remarks",
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </IHStack>
                  <IHStack>
                    <MultiSelectionField
                      placeholder="Cost Centers"
                      datas={costCenterData?.data}
                      options={costCenters}
                      setOptions={setCostCenters}
                      inputRef={costCenterRef}
                      onFocus={(e) => {
                        if (e) {
                          handleClick(21);
                        }
                      }}
                    />
                    <MultiSelectionField
                      placeholder="Done Bys"
                      datas={doneByData?.data}
                      options={doneBys}
                      setOptions={setDoneBys}
                      inputRef={doneByRef}
                      onFocus={(e) => {
                        if (e) {
                          handleClick(22);
                        }
                      }}
                    />
                  </IHStack>
                </IModalIn>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <IButtonOut>
            {isApproval && (
              <Tooltip
                hasArrow
                label="Reject"
                placement="bottom"
                bg="brand.bg_blue"
              >
                <Button
                  w="100%"
                  isLoading={isRejectLoading}
                  minH="40px"
                  type="submit"
                  size="lg"
                  variant="solid"
                  bg="red.600"
                  colorScheme="red"
                  onClick={() => {
                    rejectFunc();
                  }}
                >
                  Reject
                </Button>
              </Tooltip>
            )}
            <IButton
              isLoading={isLoading}
              isApproval={isApproval}
              type={type}
              onClick={(e) => {
                onSubmit();
              }}
            />
          </IButtonOut>
        </IModalOut>
      ) : (
        //mobile view
        <Box bg="white" flex={1} p={2}>
          <Stack spacing={2}></Stack>
          <Box p={2}>
            <Flex alignItems="center" gap="2">
              <Heading size="md" alignItems="center">
                {type === "edit" ? "Edit" : "Add"} Supplier
              </Heading>
              {isToggledFav ? (
                <AiFillStar onClick={handleFavourite} style={iconStyle} />
              ) : (
                <AiOutlineStar onClick={handleFavourite} style={iconStyle} />
              )}
            </Flex>
          </Box>
          <Tabs
            isFitted
            colorScheme="blue"
            // onChange={handleTabsChange}
            // index={index}
          >
            <TabList mb={5}>
              <Tab fontSize="9px">Basic Info</Tab>
              <Tab fontSize="9px">Opening Balance</Tab>
              <Tab fontSize="9px">Shipping Address</Tab>
              <Tab fontSize="9px">Contact Person</Tab>
              <Tab fontSize="9px">Other Info</Tab>
            </TabList>
            <TabPanels>
              <TabPanel pr="0" pl="0" pt="0">
                <Box overflowY="auto">
                  <Box pb={2} pt={2}>
                    <Grid gap={2}>
                      <GridItem colSpan={3}>
                        <FormControl>
                          <TextField
                            fullWidth
                            label="Supplier Name(Required)"
                            variant="outlined"
                            size="small"
                            type="text"
                            name="name"
                            onChange={(event) =>
                              handleEdit(event.target.value, { field: "name" })
                            }
                            onClick={() => handleClick(1)}
                            inputRef={nameRef}
                            value={values.name || ""}
                            InputProps={{
                              style: { fontSize: "12px" },
                            }}
                            InputLabelProps={{
                              style: { fontSize: "12px" },
                            }}
                          />
                          {errors.name && !isNotValid && (
                            <Message type="error" message={errors.name} />
                          )}
                        </FormControl>
                      </GridItem>
                      {/* <GridItem colSpan={1} rowSpan={3}>
                        <Box h="145px" border="1px solid grey">
                          <Image height="100%" src={userProfile}></Image>
                        </Box>
                      </GridItem> */}
                      <GridItem colSpan={3}>
                        <Grid templateColumns="repeat(2, 1fr)" columnGap={2}>
                          <GridItem colSpan={1}>
                            <FormControl>
                              <TextField
                                fullWidth
                                label="Mobile Number"
                                variant="outlined"
                                size="small"
                                type="number"
                                name="mobile"
                                inputRef={mobileRef}
                                onChange={(event) =>
                                  handleEdit(event.target.value, {
                                    field: "mobile",
                                  })
                                }
                                onClick={() => handleClick(2)}
                                value={values.mobile || ""}
                                InputProps={{
                                  style: { fontSize: "12px" },
                                }}
                                InputLabelProps={{
                                  style: { fontSize: "12px" },
                                }}
                              />
                              {errors.mobile && !isNotValid && (
                                <Message type="error" message={errors.mobile} />
                              )}
                            </FormControl>
                          </GridItem>
                          <GridItem colSpan={1}>
                            <FormControl>
                              <Select
                                placeholder="Select Ledger Group"
                                name="country"
                                value={
                                  (values.ledger_group_id ??
                                    sundryCreditorsGroups.find(
                                      (obj) => obj.name === "Sundry Creditors"
                                    )?.id) ||
                                  ""
                                }
                                as={Select}
                                ref={ledgerGroupRef}
                                id="ledger"
                                style={{ fontSize: "12px" }}
                                onChange={(e) =>
                                  handleEdit(e.target.value, {
                                    field: "ledger_group_id",
                                  })
                                }
                                defa
                                onFocus={(e) => {
                                  if (e) {
                                    handleClick(3);
                                  }
                                }}
                              >
                                {sundryCreditorsGroups.map((ledger, key) => (
                                  <option value={ledger.id} key={key}>
                                    {ledger.name}
                                  </option>
                                ))}
                              </Select>
                              {errors.ledger_group_id && !isNotValid && (
                                <Message
                                  type="error"
                                  message={errors.ledger_group_id}
                                />
                              )}
                            </FormControl>
                          </GridItem>
                        </Grid>
                      </GridItem>
                    </Grid>
                  </Box>
                  <Stack
                    direction="row"
                    marginRight={4}
                    marginBottom={showMore ? 2 : 0}
                  >
                    <Spacer />
                    <Link
                      sx={{
                        "&:hover": {
                          textDecoration: "none",
                        },
                      }}
                      onClick={() => setShowMore(!showMore)}
                    >
                      <Text
                        fontSize="xs"
                        color="brand.bg_blue"
                        sx={{
                          "&:hover": {
                            textColor: "blue.500",
                          },
                        }}
                      >
                        Show {showMore ? "Less" : "More"}
                        {showMore ? <ArrowUpIcon /> : <ArrowDownIcon />}
                      </Text>
                    </Link>
                  </Stack>

                  {showMore ? (
                    <div
                      style={{
                        width: "auto",
                      }}
                    >
                      <GridItem colSpan={3}>
                        <FormControl>
                          <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            size="small"
                            type="text"
                            name="email"
                            inputRef={emailRef}
                            onFocus={(e) => {
                              if (e) {
                                handleClick(4);
                              }
                            }}
                            onChange={(event) =>
                              handleEdit(event.target.value, {
                                field: "email",
                              })
                            }
                            value={values.email || ""}
                            InputProps={{
                              style: { fontSize: "12px" },
                            }}
                            InputLabelProps={{
                              style: { fontSize: "12px" },
                            }}
                          />
                          {errors.email && !isNotValid && (
                            <Message type="error" message={errors.email} />
                          )}
                        </FormControl>
                      </GridItem>
                      <Box pb={2} mt={2}>
                        <FormControl>
                          <Textarea
                            placeholder="Address"
                            onChange={(event) =>
                              handleEdit(event.target.value, {
                                field: "address",
                              })
                            }
                            value={values.address || ""}
                            as={Textarea}
                            name="address"
                            ref={addressRef}
                            onClick={() => handleClick(5)}
                            onKeyDown={(event) => {
                              if (event.key === "ArrowDown") {
                                handleEdit(values.address + "\n", {
                                  field: "address",
                                });
                              }
                            }}
                            InputProps={{
                              style: { fontSize: "12px" },
                            }}
                            InputLabelProps={{
                              style: { fontSize: "12px" },
                            }}
                            style={{ fontSize: "12px" }}
                          />
                          {errors.address && !isNotValid && (
                            <Message type="error" message={errors.address} />
                          )}
                        </FormControl>
                      </Box>
                      <Box pb={2}>
                        <Grid
                          autoRows="auto"
                          templateColumns="repeat(2, 1fr)"
                          columnGap={2}
                          rowGap={2}
                        >
                          <GridItem colSpan={1}>
                            <FormControl>
                              <Select
                                placeholder="Select Country"
                                name="country"
                                value={values.country || ""}
                                as={Select}
                                ref={countryRef}
                                id="country"
                                onChange={(e) =>
                                  handleEdit(e.target.value, {
                                    field: "country",
                                  })
                                }
                                onClick={() => handleClick(6)}
                                style={{ fontSize: "12px" }}
                              >
                                {countrieslist.map((country, key) => (
                                  <option value={country.value} key={key}>
                                    {country.name}
                                  </option>
                                ))}
                              </Select>

                              {errors.country && !isNotValid && (
                                <Message
                                  type="error"
                                  message={errors.country}
                                />
                              )}
                            </FormControl>
                          </GridItem>
                          <GridItem colSpan={1}>
                            <FormControl>
                              <Select
                                disabled={
                                  values.country === "UAE" ||
                                  values.country === "INDIA"
                                    ? false
                                    : true
                                }
                                placeholder={
                                  values.country?.includes("UAE")
                                    ? "Select Emirates"
                                    : "Select State"
                                }
                                name="emirate"
                                value={values.emirate || ""}
                                as={Select}
                                style={{ fontSize: "12px" }}
                                id="emirate"
                                onChange={(e) =>
                                  handleEdit(e.target.value, {
                                    field: "emirate",
                                  })
                                }
                                ref={emirateRef}
                                onClick={() => handleClick(7)}
                              >
                                {values.country === "UAE" &&
                                  emiratesList.map((emirate, key) => (
                                    <option value={emirate.value} key={key}>
                                      {emirate.name}
                                    </option>
                                  ))}
                                {values.country === "INDIA" &&
                                  emiratesListIndia
                                    .sort((a, b) =>
                                      a.name.localeCompare(b.name)
                                    )
                                    .map((emirate, key) => (
                                      <option value={emirate.value} key={key}>
                                        {emirate.name}
                                      </option>
                                    ))}
                              </Select>
                              {errors.emirate && !isNotValid && (
                                <Message
                                  type="error"
                                  message={errors.emirate}
                                />
                              )}
                            </FormControl>
                          </GridItem>
                        </Grid>
                      </Box>
                      <Box>
                        <Grid
                          autoRows="auto"
                          templateColumns="repeat(2, 1fr)"
                          columnGap={2}
                          rowGap={2}
                        >
                          <GridItem colSpan={1}>
                            <FormControl height="50px">
                              <TextField
                                fullWidth
                                label={
                                  values.country === "INDIA"
                                    ? "GST No"
                                    : values.country === "UAE"
                                    ? "TRN NO"
                                    : values.country === "SAUDI"
                                    ? "VAT No"
                                    : values.country === "BAHRAIN"
                                    ? "VAT No"
                                    : values.country === "QATAR"
                                    ? "VAT No"
                                    : values.country === "OMAN"
                                    ? "VAT No"
                                    : "TAX No"
                                }
                                variant="outlined"
                                size="small"
                                type="text"
                                name="trn"
                                inputRef={trnRef}
                                onClick={() => handleClick(8)}
                                onChange={(event) =>
                                  handleEdit(event.target.value, {
                                    field: "trn",
                                  })
                                }
                                onKeyDown={(event) => {
                                  if (
                                    values.trn === "" ||
                                    values.trn === null
                                  ) {
                                    values.tax_registration_type =
                                      "UNREGISTERED_BUSINESS";
                                  } else {
                                    values.tax_registration_type =
                                      "REGISTERED_BUSINESS";
                                  }
                                }}
                                value={values.trn || ""}
                                InputProps={{
                                  style: { fontSize: "12px" },
                                }}
                                InputLabelProps={{
                                  style: { fontSize: "12px" },
                                }}
                              />
                              {errors.trn && !isNotValid && (
                                <Message type="error" message={errors.trn} />
                              )}
                            </FormControl>
                          </GridItem>
                          <GridItem colSpan={1}>
                            <FormControl height="50px">
                              <Select
                                placeholder="Tax Registration type"
                                name="tax_registration_type"
                                value={
                                  values.tax_registration_type ||
                                  "UNREGISTERED_BUSINESS"
                                }
                                as={Select}
                                ref={taxRegistrationTypeRef}
                                id="tax_registration_type"
                                style={{ fontSize: "12px" }}
                                onChange={(e) =>
                                  handleEdit(e.target.value, {
                                    field: "tax_registration_type",
                                  })
                                }
                                onClick={() => handleClick(9)}
                                onKeyDown={(event) => {
                                  if (
                                    event.key === "Enter" &&
                                    !hasExecuted &&
                                    !isLoading &&
                                    !isSubmitted
                                  ) {
                                    const validateResponse = validateForm();
                                    validateResponse.then((data) => {
                                      submitAction(data);
                                      setHasExecuted(true);
                                    });
                                  }
                                }}
                              >
                                {taxregistrationtypeList.map((tax, key) => (
                                  <option value={tax.value} key={key}>
                                    {tax.name}
                                  </option>
                                ))}
                              </Select>

                              {errors.tax_registration_type && !isNotValid && (
                                <Message
                                  type="error"
                                  message={errors.tax_registration_type}
                                />
                              )}
                            </FormControl>
                          </GridItem>
                          <Checkbox
                            style={{ whiteSpace: "nowrap" }}
                            // mt="10px"
                            // ml="auto"
                            // mr="45px"
                            isChecked={suspand}
                            onChange={(e) => {
                              setSuspand(!suspand);
                            }}
                          >
                            Required Suspend
                          </Checkbox>
                        </Grid>
                      </Box>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* <Box pb={2}>
                    <FormControl>
                      <Textarea
                        placeholder="Address"
                        onChange={(event) =>
                          handleEdit(event.target.value, { field: "address" })
                        }
                        value={values.address || ""}
                        as={Textarea}
                        name="address"
                        ref={addressRef}
                        onFocus={(e) => {
                          if (e) {
                            handleClick(5);
                          }
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "ArrowDown") {
                            handleEdit(values.address + "\n", {
                              field: "address",
                            });
                          }
                        }}
                      />
                      {errors.address && !isNotValid && (
                        <Message type="error" message={errors.address} />
                      )}
                    </FormControl>
                  </Box>
                  <Box pb={2}>
                    <Grid
                      autoRows="auto"
                      templateColumns="repeat(2, 1fr)"
                      columnGap={2}
                      rowGap={2}
                    >
                      <GridItem colSpan={1}>
                        <FormControl>
                          <Select
                            placeholder="Select Country"
                            name="country"
                            value={values.country || ""}
                            as={Select}
                            ref={countryRef}
                            id="country"
                            onChange={(e) =>
                              handleEdit(e.target.value, { field: "country" })
                            }
                            onFocus={(e) => {
                              if (e) {
                                handleClick(6);
                              }
                            }}
                          >
                            {countrieslist.map((country, key) => (
                              <option value={country.value} key={key}>
                                {country.name}
                              </option>
                            ))}
                          </Select>
                          {errors.country && !isNotValid && (
                            <Message type="error" message={errors.country} />
                          )}
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={1}>
                        <FormControl>
                          <Select
                            disabled={
                              countryRef.current?.value === "UAE" ||
                              countryRef.current?.value === "INDIA"
                                ? false
                                : true
                            }
                            placeholder={
                              countryRef.current?.value?.includes("UAE")
                                ? "Select Emirates"
                                : "Select State"
                            }
                            name="emirate"
                            value={values.emirate || ""}
                            as={Select}
                            id="emirate"
                            onChange={(e) =>
                              handleEdit(e.target.value, { field: "emirate" })
                            }
                            ref={emirateRef}
                            onFocus={(e) => {
                              if (e) {
                                handleClick(7);
                              }
                            }}
                          >
                            {countryRef.current?.value === "UAE" &&
                              emiratesList.map((emirate, key) => (
                                <option value={emirate.value} key={key}>
                                  {emirate.name}
                                </option>
                              ))}
                            {countryRef.current?.value === "INDIA" &&
                              emiratesListIndia
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((emirate, key) => (
                                  <option value={emirate.value} key={key}>
                                    {emirate.name}
                                  </option>
                                ))}
                          </Select>
                          {errors.emirate && !isNotValid && (
                            <Message type="error" message={errors.emirate} />
                          )}
                        </FormControl>
                      </GridItem>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid
                      autoRows="auto"
                      templateColumns="repeat(2, 1fr)"
                      columnGap={2}
                      rowGap={2}
                    >
                      <GridItem colSpan={1}>
                        <FormControl height="50px">
                          <TextField
                            fullWidth
                            label={
                              values.country === "INDIA"
                                ? "GST No"
                                : values.country === "UAE"
                                ? "TRN NO"
                                : values.country === "SAUDI"
                                ? "VAT No"
                                : values.country === "BAHRAIN"
                                ? "VAT No"
                                : values.country === "QATAR"
                                ? "VAT No"
                                : values.country === "OMAN"
                                ? "VAT No"
                                : "TAX No"
                            }
                            variant="outlined"
                            size="small"
                            type="text"
                            name="trn"
                            inputRef={trnRef}
                            onFocus={(e) => {
                              if (e) {
                                handleClick(8);
                              }
                            }}
                            onChange={(event) =>
                              handleEdit(event.target.value, { field: "trn" })
                            }
                            onKeyDown={(event) => {
                              if (values.trn === "" || values.trn === null) {
                                values.tax_registration_type =
                                  "UNREGISTERED_BUSINESS";
                              } else {
                                values.tax_registration_type =
                                  "REGISTERED_BUSINESS";
                              }
                            }}
                            value={values.trn || ""}
                          />
                          {errors.trn && !isNotValid && (
                            <Message type="error" message={errors.trn} />
                          )}
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={1}>
                        <FormControl height="50px">
                          <Select
                            placeholder="Tax Registration type"
                            name="tax_registration_type"
                            value={
                              values.tax_registration_type ||
                              "UNREGISTERED_BUSINESS"
                            }
                            // value={values.trn !=null? "REGISTERED_BUSINESS": "UNREGISTERED_BUSINESS"}
                            as={Select}
                            ref={taxRegistrationTypeRef}
                            id="tax_registration_type"
                            onChange={(e) =>
                              handleEdit(e.target.value, {
                                field: "tax_registration_type",
                              })
                            }
                            onFocus={(e) => {
                              if (e) {
                                handleClick(9);
                              }
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter" && !hasExecuted) {
                                const validateResponse = validateForm();
                                validateResponse.then((data) => {
                                  submitAction(data);
                                  setHasExecuted(true);
                                });
                              }
                            }}
                          >
                            {taxregistrationtypeList.map((tax, key) => (
                              <option value={tax.value} key={key}>
                                {tax.name}
                              </option>
                            ))}
                          </Select>

                          {errors.tax_registration_type && !isNotValid && (
                            <Message
                              type="error"
                              message={errors.tax_registration_type}
                            />
                          )}
                        </FormControl>
                      </GridItem>
                      <Checkbox
                        // mt="10px"
                        // ml="auto"
                        // mr="45px"
                        isChecked={suspand}
                        onChange={(e) => {
                          setSuspand(!suspand);
                        }}
                      >
                        Required Suspend
                      </Checkbox>
                    </Grid>
                  </Box> */}
                </Box>
              </TabPanel>
              <TabPanel pr="0" pl="0" pt="0">
                <Box pb={2} w="100%">
                  <Flex justifyContent="space-between" alignItems="center">
                    <Button
                      onClick={openingBalanceModal.onOpen}
                      h="30px"
                      colorScheme="blue"
                      variant="ghost"
                      type="submit"
                      size="lg"
                      minH={12}
                    >
                      Add opening balance (+)
                    </Button>
                    <Text whiteSpace="nowrap">
                      Total: {totalOpeningBalance}
                    </Text>
                  </Flex>

                  <Stack spacing={4}>
                    {openingBalanceArray.length > 0 &&
                      openingBalanceArray.map((item, index) => (
                        <Card>
                          <CardContent
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid gap={2} w="95%">
                              <GridItem colSpan={1}>
                                <Text>Date : {item.date}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Due Date : {item.due_date}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>
                                  Invoice Number : {item.invoice_number}
                                </Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>
                                  Reference Number : {item.reference_number}
                                </Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text fontWeight="bold">
                                  Invoice Balance : {item.total}{" "}
                                </Text>
                              </GridItem>
                              {/* <GridItem colSpan={1}>
                                <Text>
                                  Cost Center :&nbsp;
                                  {
                                    costCenterData?.data
                                      ?.filter(
                                        (items) => item.cost_centre === items.id
                                      )
                                      .map((item) => item.name)[0]
                                  }
                                </Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>
                                  Done By :&nbsp;
                                  {
                                    doneByData?.data
                                      ?.filter(
                                        (items) => item.done_by === items.id
                                      )
                                      .map((item) => item.name)[0]
                                  }
                                </Text>
                              </GridItem> */}
                            </Grid>
                            <Box width="min-content">
                              <IconButton
                                m={1}
                                onClick={() => editOpeningBalance(index)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                m={1}
                                onClick={() => deleteFromOpeningBalance(index)}
                              >
                                <DeleteIcon color="red" />
                              </IconButton>
                            </Box>
                          </CardContent>
                        </Card>
                      ))}
                  </Stack>

                  <Modal
                    isOpen={openingBalanceModal.isOpen}
                    onClose={openingBalanceModal.onClose}
                    isCentered
                  >
                    <ModalOverlay />
                    <ModalContent width={isMobile ? "90%" : "100%"}>
                      <ModalHeader>
                        <Box p={4}>
                          <Heading size="md" alignItems="center">
                            Add opening balance{" "}
                          </Heading>
                        </Box>
                      </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Box pb={2} pt={2}>
                          <Grid
                            templateColumns="repeat(2, 1fr)"
                            columnGap={2}
                            rowGap={2}
                          >
                            <GridItem colSpan={1}>
                              <FormControl>
                                <DatePicker
                                  dateLabel="Date"
                                  date={
                                    openingBalance.date ||
                                    getLocalStore("VDATE_MODEL") ||
                                    null
                                  }
                                  modal={true}
                                  setDate={(date) =>
                                    setopeningBalance({
                                      ...openingBalance,
                                      date: date,
                                    })
                                  }
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <DatePicker
                                  dateLabel="Due Date"
                                  date={
                                    openingBalance.due_date ||
                                    getLocalStore("VDATE_MODEL") ||
                                    null
                                  }
                                  modal={true}
                                  setDate={(date) =>
                                    setopeningBalance({
                                      ...openingBalance,
                                      due_date: date,
                                    })
                                  }
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <TextField
                                  label="Invoice No *"
                                  placeholder="required field"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  InputLabelProps={{ shrink: true }}
                                  value={openingBalance.invoice_number || ""}
                                  onChange={(e) => {
                                    setopeningBalance({
                                      ...openingBalance,
                                      invoice_number: e.target.value,
                                    });
                                  }}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <TextField
                                  label="Reference No"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  value={openingBalance.reference_number || ""}
                                  onChange={(e) => {
                                    setopeningBalance({
                                      ...openingBalance,
                                      reference_number: e.target.value,
                                    });
                                  }}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <TextField
                                  label="Invoice balance *"
                                  placeholder="required field"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  InputLabelProps={{ shrink: true }}
                                  value={openingBalance.total || ""}
                                  // onChange={(e) => {
                                  //   setopeningBalance({
                                  //     ...openingBalance,
                                  //     total: e.target.value,
                                  //   });
                                  // }}
                                  onChange={(e) => {
                                    const inputValue = e.target.value;
                                    if (inputValue >= 0 || inputValue === "") {
                                      setopeningBalance({
                                        ...openingBalance,
                                        total: inputValue,
                                      });
                                    }
                                  }}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <MultiSelectionField
                                placeholder="Cost Centers"
                                datas={costCenterData?.data}
                                options={costCenters}
                                setOptions={setCostCenters}
                                inputRef={costCenterRef}
                              />
                            </GridItem>
                            <GridItem colSpan={1}>
                              <MultiSelectionField
                                placeholder="Done Bys"
                                datas={doneByData?.data}
                                options={doneBys}
                                setOptions={setDoneBys}
                                inputRef={doneByRef}
                              />
                            </GridItem>
                          </Grid>
                        </Box>
                      </ModalBody>

                      <ModalFooter>
                        <Button
                          w="100%"
                          type="submit"
                          size="lg"
                          variant="solid"
                          bg="brand.bg_blue"
                          colorScheme="brand.bg_blue"
                          minH={12}
                          onClick={handleOpeningBalance}
                        >
                          Add (+)
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Box>
              </TabPanel>
              <TabPanel pr="0" pl="0" pt="0">
                <Box pb={2} w="100%" maxHeight="400px" overflow="scroll">
                  <Button
                    onClick={shippingAddressModal.onOpen}
                    h="30px"
                    colorScheme="blue"
                    variant="ghost"
                    type="submit"
                    size="lg"
                    minH={12}
                  >
                    Add shipping address (+)
                  </Button>

                  <Stack spacing={4}>
                    {shippingAddressArray.length > 0 &&
                      shippingAddressArray.map((item, index) => (
                        <Card>
                          <CardContent
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid
                              templateColumns="repeat(2, 1fr)"
                              gap={2}
                              w="95%"
                            >
                              <GridItem colSpan={1}>
                                <Text>Address : {item.address}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Country : {item.country}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Emirates : {item.emirate}</Text>
                              </GridItem>
                            </Grid>
                            <Box width="min-content">
                              <IconButton
                                m={1}
                                onClick={() => editShippingAddress(index)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                m={1}
                                onClick={() => deleteFromShippingAddress(index)}
                              >
                                <DeleteIcon color="red" />
                              </IconButton>
                            </Box>
                          </CardContent>
                        </Card>
                      ))}
                  </Stack>

                  <Modal
                    size="2xl"
                    isOpen={shippingAddressModal.isOpen}
                    onClose={handleShippingAddressClose}
                    isCentered
                  >
                    <ModalOverlay />
                    <ModalContent width={isMobile ? "90%" : "100%"}>
                      <ModalHeader>
                        <Box p={4}>
                          <Heading size="md" alignItems="center">
                            Add shipping address{" "}
                          </Heading>
                        </Box>
                      </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Box>
                          <Grid
                            autoRows="auto"
                            templateColumns="repeat(2, 1fr)"
                            columnGap={2}
                            rowGap={2}
                          >
                            <GridItem colSpan={2}>
                              <FormControl>
                                <TextField
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                  label="Address"
                                  multiline
                                  minRows={3}
                                  onChange={(e) => {
                                    setShippingAddress({
                                      ...shippingAddress,
                                      address: e.target.value,
                                    });
                                  }}
                                  value={shippingAddress.address || ""}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                />
                              </FormControl>
                            </GridItem>

                            <GridItem colSpan={1}>
                              <FormControl>
                                <Select
                                  placeholder="Select Country"
                                  as={Select}
                                  onChange={(e) => {
                                    setShippingAddress({
                                      ...shippingAddress,
                                      country: e.target.value,
                                    });
                                  }}
                                  value={shippingAddress.country || ""}
                                  style={{ fontSize: "12px" }}
                                >
                                  {countrieslist.map((country, key) => (
                                    <option value={country.value} key={key}>
                                      {country.name}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <Select
                                  disabled={
                                    shippingAddress.country === "UAE" ||
                                    shippingAddress.country === "INDIA"
                                      ? false
                                      : true
                                  }
                                  placeholder="Select Emirates"
                                  as={Select}
                                  style={{ fontSize: "12px" }}
                                  value={shippingAddress.emirate || ""}
                                  onChange={(e) => {
                                    setShippingAddress({
                                      ...shippingAddress,
                                      emirate: e.target.value,
                                    });
                                  }}
                                >
                                  {shippingAddress.country === "UAE " &&
                                    emiratesList.map((emirate, key) => (
                                      <option value={emirate.value} key={key}>
                                        {emirate.name}
                                      </option>
                                    ))}
                                  {shippingAddress.country === "INDIA" &&
                                    emiratesListIndia
                                      .sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                      )
                                      .map((emirate, key) => (
                                        <option value={emirate.value} key={key}>
                                          {emirate.name}
                                        </option>
                                      ))}
                                </Select>
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </Box>
                      </ModalBody>

                      <ModalFooter>
                        <Button
                          w="100%"
                          type="submit"
                          size="lg"
                          variant="solid"
                          bg="brand.bg_blue"
                          colorScheme="brand.bg_blue"
                          minH={12}
                          onClick={handleShippingAddress}
                        >
                          Add (+)
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Box>
              </TabPanel>
              <TabPanel pr="0" pl="0" pt="0">
                <Box pb={2} w="100%" maxHeight="400px" overflow="scroll">
                  <Button
                    h="30px"
                    colorScheme="blue"
                    variant="ghost"
                    type="submit"
                    size="lg"
                    onClick={contactPersonModal.onOpen}
                    minH={12}
                  >
                    Add contact person (+)
                  </Button>

                  <Stack spacing={4}>
                    {contactPersonArray?.length > 0 &&
                      contactPersonArray.map((item, index) => (
                        <Card>
                          <CardContent
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid
                              // templateColumns="repeat(2, 1fr)"
                              gap={2}
                              w="95%"
                            >
                              <GridItem colSpan={1}>
                                <Text>Salutation : {item.salutation}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Name : {item.name}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Destination : {item.designation}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Department : {item.department}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Email : {item.email}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>Work Phone : {item.work_phone}</Text>
                              </GridItem>
                              <GridItem colSpan={1}>
                                <Text>
                                  Mobile Number : {item.mobile_number}
                                </Text>
                              </GridItem>
                            </Grid>
                            <Box width="min-content">
                              <IconButton
                                m={1}
                                onClick={() => editContactPerson(index)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                m={1}
                                onClick={() => deleteFromContactPerson(index)}
                              >
                                <DeleteIcon color="red" />
                              </IconButton>
                            </Box>
                          </CardContent>
                        </Card>
                      ))}
                  </Stack>

                  <Modal
                    isOpen={contactPersonModal.isOpen}
                    onClose={handleContactPersonClose}
                    isCentered
                  >
                    <ModalOverlay />
                    <ModalContent width={isMobile ? "90%" : "100%"}>
                      <ModalHeader>
                        <Box p={2}>
                          <Heading size="md" alignItems="center">
                            Add contact person{" "}
                          </Heading>
                        </Box>
                      </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Box pb={2}>
                          <Grid
                            templateRows="repeat(1, 1fr)"
                            templateColumns="repeat(2, 1fr)"
                            gap={2}
                          >
                            <GridItem colSpan={1}>
                              <FormControl>
                                <TextField
                                  fullWidth
                                  label="Salutation"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  onChange={(e) => {
                                    setContactPerson({
                                      ...contactPerson,
                                      salutation: e.target.value,
                                    });
                                  }}
                                  value={contactPerson.salutation || ""}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <TextField
                                  fullWidth
                                  label="Name"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  onChange={(e) => {
                                    setContactPerson({
                                      ...contactPerson,
                                      name: e.target.value,
                                    });
                                  }}
                                  value={contactPerson.name || ""}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </Box>
                        <Box pb={2}>
                          <Grid
                            templateRows="repeat(1, 1fr)"
                            templateColumns="repeat(2, 1fr)"
                            gap={2}
                          >
                            <GridItem colSpan={1}>
                              <FormControl>
                                <TextField
                                  fullWidth
                                  label="Designation"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  onChange={(e) => {
                                    setContactPerson({
                                      ...contactPerson,
                                      designation: e.target.value,
                                    });
                                  }}
                                  value={contactPerson.designation || ""}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <TextField
                                  fullWidth
                                  label="Department"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  onChange={(e) => {
                                    setContactPerson({
                                      ...contactPerson,
                                      department: e.target.value,
                                    });
                                  }}
                                  value={contactPerson.department || ""}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </Box>
                        <Box pb={2}>
                          <FormControl>
                            <TextField
                              error={formErrors === null ? false : true}
                              fullWidth
                              label="Email"
                              variant="outlined"
                              size="small"
                              type="email"
                              onChange={(e) => {
                                const testEmail =
                                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

                                setContactPerson({
                                  ...contactPerson,
                                  email: e.target.value,
                                });
                                if (!testEmail.test(e.target.value)) {
                                  setFormErrors({
                                    email: "Enter a valid email",
                                  });
                                } else {
                                  setFormErrors(null);
                                }
                              }}
                              value={contactPerson.email || ""}
                              InputProps={{ style: { fontSize: "12px" } }}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                            />

                            {formErrors && formErrors.email && (
                              <Message
                                type="error"
                                message={formErrors.email}
                              />
                            )}
                          </FormControl>
                        </Box>
                        <Box pb={2}>
                          <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <TextField
                                  fullWidth
                                  label=" WorkPhone"
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  onChange={(e) => {
                                    setContactPerson({
                                      ...contactPerson,
                                      work_phone: e.target.value,
                                    });
                                  }}
                                  value={contactPerson.work_phone || ""}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <TextField
                                  fullWidth
                                  label="Mobile Number"
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  inputRef={mobileRef}
                                  onChange={(e) => {
                                    setContactPerson({
                                      ...contactPerson,
                                      mobile_number: e.target.value,
                                    });
                                  }}
                                  value={contactPerson.mobile_number || ""}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  // onChange={(event) =>
                                  //   handleEdit(event.target.value, {
                                  //     field: "mobile",
                                  //   })
                                  // }
                                  // onFocus={(e) => {
                                  //   if (e) {
                                  //     handleClick(7);
                                  //   }
                                  // }}
                                />
                                {/* {errors.mobile && !isNotValid && (
                              <Message type="error" message={errors.mobile} />
                            )} */}
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </Box>
                      </ModalBody>

                      <ModalFooter>
                        <Button
                          w="100%"
                          type="submit"
                          size="lg"
                          variant="solid"
                          bg="brand.bg_blue"
                          colorScheme="brand.bg_blue"
                          minH={12}
                          disabled={formErrors}
                          onClick={handleContactPerson}
                        >
                          Add (+)
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Box>
              </TabPanel>
              <TabPanel pr="0" pl="0" pt="0">
                <Box overflowY="auto">
                  <Box w="100%" textAlign="right">
                    <Checkbox
                      isChecked={enableCreditLimit1 || ""}
                      onChange={(e) => {
                        setEnableCreditLimit1(e.target.checked);
                      }}
                    >
                      Enable credit limit
                    </Checkbox>
                  </Box>
                  <Box>
                    <Grid
                      autoRows="auto"
                      templateColumns="repeat(2, 1fr)"
                      columnGap={2}
                      rowGap={2}
                    >
                      <GridItem colSpan={1}>
                        <FormControl height="50px">
                          <TextField
                            fullWidth
                            label="Credit Period(days)"
                            variant="outlined"
                            size="small"
                            type="number"
                            name="payment_term"
                            inputRef={creditPeriodRef}
                            onFocus={(e) => {
                              if (e) {
                                handleClick(10);
                              }
                            }}
                            onChange={(event) =>
                              handleEdit(event.target.value, {
                                field: "payment_term",
                              })
                            }
                            value={values.payment_term || ""}
                            InputProps={{ style: { fontSize: "12px" } }}
                            InputLabelProps={{
                              style: { fontSize: "12px" },
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={1}>
                        <FormControl height="50px">
                          <TextField
                            disabled={!enableCreditLimit1}
                            fullWidth
                            label="Max Credit limit"
                            variant="outlined"
                            size="small"
                            type="number"
                            name="maximum_credit_limit"
                            inputRef={maxCreditLimitRef}
                            onFocus={(e) => {
                              if (e) {
                                handleClick(11);
                              }
                            }}
                            onChange={(event) =>
                              handleEdit(event.target.value, {
                                field: "maximum_credit_limit",
                              })
                            }
                            value={values.maximum_credit_limit || ""}
                            InputProps={{ style: { fontSize: "12px" } }}
                            InputLabelProps={{
                              style: { fontSize: "12px" },
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </Grid>
                  </Box>
                  <Box pb={2}>
                    <FormControl>
                      <Textarea
                        placeholder="Bank detailes"
                        onChange={(event) =>
                          handleEdit(event.target.value, {
                            field: "bank_details",
                          })
                        }
                        value={values.bank_details || ""}
                        as={Textarea}
                        name="bank_details"
                        ref={bankDetailesRef}
                        InputProps={{
                          style: { fontSize: "12px" },
                        }}
                        InputLabelProps={{
                          style: { fontSize: "12px" },
                        }}
                        style={{ fontSize: "12px" }}
                        onFocus={(e) => {
                          if (e) {
                            handleClick(12);
                          }
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "ArrowDown") {
                            handleEdit(values.bank_details + "\n", {
                              field: "bank_details",
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box pb={2}>
                    <FormControl>
                      <Textarea
                        placeholder="Remarks"
                        onChange={(event) =>
                          handleEdit(event.target.value, { field: "remarks" })
                        }
                        value={values.remarks || ""}
                        as={Textarea}
                        name="remarks"
                        ref={remarksRef}
                        InputProps={{
                          style: { fontSize: "12px" },
                        }}
                        InputLabelProps={{
                          style: { fontSize: "12px" },
                        }}
                        style={{ fontSize: "12px" }}
                        onFocus={(e) => {
                          if (e) {
                            handleClick(13);
                          }
                        }}
                        onKeyDown={(event) => {
                          if (event.key === "ArrowDown") {
                            handleEdit(values.remarks + "\n", {
                              field: "remarks",
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <Grid
                      autoRows="auto"
                      templateColumns="repeat(2, 1fr)"
                      columnGap={2}
                      rowGap={2}
                    >
                      <GridItem colSpan={1}>
                        <FormControl height="50px">
                          <TextField
                            fullWidth
                            label="Distance"
                            variant="outlined"
                            size="small"
                            type="number"
                            name="distance"
                            inputRef={distanceRef}
                            InputProps={{
                              style: { fontSize: "12px" },
                            }}
                            InputLabelProps={{
                              style: { fontSize: "12px" },
                            }}
                            onChange={(event) =>
                              handleEdit(event.target.value, {
                                field: "distance",
                              })
                            }
                            onFocus={(e) => {
                              if (e) {
                                handleClick(14);
                              }
                            }}
                            value={values.distance || ""}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={1}>
                        <FormControl>
                          <TextField
                            fullWidth
                            label="PAN Number"
                            variant="outlined"
                            size="small"
                            type="number"
                            name="pan_number"
                            inputRef={panNumberRef}
                            InputProps={{
                              style: { fontSize: "12px" },
                            }}
                            InputLabelProps={{
                              style: { fontSize: "12px" },
                            }}
                            onChange={(event) =>
                              handleEdit(event.target.value, {
                                field: "pan_number",
                              })
                            }
                            onFocus={(e) => {
                              if (e) {
                                handleClick(15);
                              }
                            }}
                            value={values.pan_number || ""}
                          />
                        </FormControl>
                      </GridItem>
                    </Grid>
                  </Box>
                  <Box>
                    <FormControl height="50px">
                      <TextField
                        fullWidth
                        label="Website"
                        variant="outlined"
                        size="small"
                        type="text"
                        name="website"
                        onChange={(event) =>
                          handleEdit(event.target.value, { field: "website" })
                        }
                        onFocus={(e) => {
                          if (e) {
                            handleClick(16);
                          }
                        }}
                        InputProps={{
                          style: { fontSize: "12px" },
                        }}
                        InputLabelProps={{
                          style: { fontSize: "12px" },
                        }}
                        inputRef={webSiteRef}
                        value={values.website || ""}
                        onKeyDown={(event) => {
                          if (
                            event.key === "Enter" &&
                            !isLoading &&
                            !isSubmitted
                          ) {
                            const validateResponse = validateForm();
                            validateResponse.then((data) => {
                              submitAction(data);
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <Grid
                      autoRows="auto"
                      templateColumns="repeat(2, 1fr)"
                      columnGap={2}
                      rowGap={2}
                    >
                      <GridItem colSpan={1}>
                        <CostCenterSelectionField
                          costCenter={values.cost_centre}
                          setCostCenter={(costCenter) => {
                            handleEdit(costCenter, {
                              field: "cost_centre",
                            });
                          }}
                        />
                      </GridItem>
                      <GridItem colSpan={1}>
                        <DoneBySelectionField
                          doneBy={values.done_by}
                          setDoneBy={(doneBy) => {
                            handleEdit(doneBy, {
                              field: "done_by",
                            });
                          }}
                        />
                      </GridItem>
                    </Grid>
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Box pb={2} w="100%" display="flex" gap={2}>
            {isApproval && (
              <Tooltip
                hasArrow
                label="Reject"
                placement="bottom"
                bg="brand.bg_blue"
              >
                <Button
                  w="100%"
                  isLoading={isRejectLoading}
                  minH="40px"
                  type="submit"
                  size="lg"
                  variant="solid"
                  bg="red.600"
                  colorScheme="red"
                  onClick={() => {
                    rejectFunc();
                  }}
                >
                  Reject
                </Button>
              </Tooltip>
            )}
            <IButton
              isLoading={isLoading}
              onClick={(e) => {
                if (e.detail === 1) {
                  const validateResponse = validateForm();
                  validateResponse.then((data) => {
                    submitAction(data);
                  });
                }
              }}
              isApproval={isApproval}
              type={type}
            />
          </Box>
        </Box>
      )}

      <AddPartyAdvanceModal
        initialValue={editingAdvanceItem}
        onSubmit={handleEditAdvanceItemSubmit}
        isOpen={advanceModal.isOpen}
        closeModal={closeModal}
        onClose={advanceModal.onClose}
      />
    </>
  );
};

export default RenderComponent;
