import React from 'react'

import MessageContainer from './style'

const Message = ({ message, type }) => {

  return (
    <MessageContainer type={type}>
      <p>{message}</p>
    </MessageContainer>
  )
}

export default Message
