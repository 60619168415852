import { useMutation, useQueryClient } from "react-query";
import api, { API_ITEM_CATEGORY_GET_EDIT_DELETE } from "utils/axios";

const updateItemCategory = (ItemCategory) =>
  api
    .patch(
      API_ITEM_CATEGORY_GET_EDIT_DELETE.replace("{id}", ItemCategory.id),
      ItemCategory
    )
    .then((res) => res.data);

export function useUpdateItemCategory() {
  const queryClient = useQueryClient();
  return useMutation(updateItemCategory, {
    onSuccess: () => queryClient.refetchQueries("itemcategory"),
  });
}
