import { createSlice } from '@reduxjs/toolkit'

import api, { API_STOCK_LOSS_ENTRY } from '../utils/axios'
import { useDispatch, useSelector } from 'react-redux'

import { showLoading, hideLoading } from './common.slice';
const initialState = {
  data: {},
}

const stockloss = createSlice({
  name: 'stockloss',
  initialState,
  reducers: {
    // setLoading: (state, action) => {
    //   state.loading = action.payload.loading
    // },
    // setData: (state, action) => {
    //   state.data = action.payload.data
    // },
    // setErrorMessage: (state, action) => {
    //   state.error = action.payload.message
    // }
  }
})

export const {
  setData,
} = stockloss.actions
export default stockloss.reducer

export const addStockLossEntry = ({ data }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    const response = await api.post(API_STOCK_LOSS_ENTRY, data)
    if (response?.data?.status === "success") {
      // dispatch(setData({ data: response.data.data }))
    }
    dispatch(hideLoading())
    return response
  } catch (error) {
    dispatch(hideLoading())
  }
}
