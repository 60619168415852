import { createSlice } from "@reduxjs/toolkit";

import api, {
  // API_RECEIPT_REPORT_LIST,
  // API_RECEIPT_REPORT_DELETE,
  API_PARTY_SET_OFF_CREATE,
  API_SET_OFF_REPORT_LIST,
  // API_RECEIPT_REPORT_EXPORT,
  API_PARTY_SET_OFF_GET_EDIT_DELETE,
} from "../utils/axios";
import { showLoading, hideLoading } from "./common.slice";

const initialState = {
  loading: false,
  next: "",
  previous: "",
  count: 0,
  page: 0,
  total_pages: 0,
  cash_total: 0,
  bank_total: 0,
  amount_total: 0,
  reports: [],
  reportDetails: {},
  setOffData: {},
};

const partysetoffreport = createSlice({
  name: "partysetoffreport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReports: (state, action) => {
      state.reports = action.payload.reports;
      state.count = action.payload.count;
      state.total_pages = action.payload.total_pages;
      state.bank_total = action.payload.bank_total;
      state.cash_total = action.payload.cash_total;
      state.amount_total = action.payload.amount_total;
    },
    setReportDetails: (state, action) => {
      state.reportDetails = action.payload.reportDetails;
    },
    setPartySetOffData: (state, action) => {
      state.setOffData = action.payload.data;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const {
  setLoading,
  setReports,
  setReportDetails,
  setErrorMessage,
  setPartySetOffData,
} = partysetoffreport.actions;

export default partysetoffreport.reducer;

export const fetchSetOffReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url = API_SET_OFF_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate);
      if (data.partyType && data.partyType !== "") {
        url = url + `&party_type=${data.partyType}`;
      }
      if (data.receivedFrom && data.receivedFrom !== "") {
        url = url + `&party=${data.receivedFrom}`;
      }
      if (data.receivedTo && data.receivedTo !== "") {
        url = url + `&ledger=${data.receivedTo}`;
      }
      if (data.costCenter && data.costCenter !== "") {
        if (data.costCenter === "notAvailableId") {
          url += `&not_available_field=cost_centre`;
        } else {
          url += `&cost_centre=${data.costCenter}`;
        }
      }
      if (data.doneBy && data.doneBy !== "") {
        if (data.doneBy === "notAvailableId") {
          url += `&not_available_field=done_by`;
        } else {
          url += `&done_by=${data.doneBy}`;
        }
      }
      if (data.voucherType && data.voucherType !== "") {
        url += `&voucher_group=${data.voucherType}`;
      }
      if (data.searchType && data.searchKey) {
        url += `&${data.searchType}=${data.searchKey}`;
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            amount_total: response.data.total.amount,
            // // total_gross: response.data.data.total_gross,
            // bank_total: response.data.data.bank_total,
            // cash_total: response.data.data.cash_total,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

// export const exportReceiptReports = (data) => async (dispatch) => {
//   try {
//     dispatch(setLoading({ loading: true }));
//     // api call
//     console.log("data----------", data);
//     const response = await api.get(
//       API_RECEIPT_REPORT_EXPORT.replace("{startDate}", data.startDate)
//         .replace("{endDate}", data.endDate)
//         .replace("{status}", data.status)
//         .replace("{fileType}", data.fileType)
//     );
//     if (response?.data?.status === "success") {
//       dispatch(setLoading({ loading: false }));
//       return response?.data?.data?.url;
//     }
//   } catch (error) {
//     dispatch(setLoading({ loading: false }));
//   }
// };

export const deletePartySetOff =
  ({ data, filters }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_PARTY_SET_OFF_GET_EDIT_DELETE.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(
          fetchSetOffReports({
            data: {
              page: filters.currentPage,
              pageSize: 10,
              startDate: filters.startDate,
              endDate: filters.endDate,
              partyType: filters.partyType,
              // voucher_type: filters.voucher_type,
              // reference_type: filters.reference_type,
              // reference_invoice_type: filters.reference_invoice_type,
            },
          })
        );
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const addPartySetOff =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.post(API_PARTY_SET_OFF_CREATE, data);
      if (response?.data?.status === "success") {
        // dispatch(setData({ data: response.data.data }))
      }
      dispatch(hideLoading());
      return response;
    } catch (error) {
      dispatch(hideLoading());
    }
  };

// export const addPartySetOff =
//   ({ data }) =>
//   async (dispatch) => {
//     try {
//       dispatch(showLoading());
//       const response = await api.post(API_PARTY_SET_OFF_CREATE, data);
//       if (response?.data?.status === "success") {
//         // dispatch(setData({ data: response.data.data }))
//         dispatch(setLoading({ loading: false }));
//         dispatch(fetchCustomers());
//         dispatch(clearSalesReports());
//         dispatch(fetchItems());
//       }
//       dispatch(hideLoading());
//       return response;
//     } catch (error) {
//       dispatch(hideLoading());
//     }
//   };

export const getReciept = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await api.get(
      API_PARTY_SET_OFF_GET_EDIT_DELETE.replace("{id}", id)
    );
    if (response?.data?.status === "success") {
      dispatch(setPartySetOffData({ data: response.data.data }));
    }
    dispatch(hideLoading());
    return response;
  } catch (error) {
    dispatch(hideLoading());
  }
};

// export const editOtherReceipt =
//   ({ data, id }) =>
//   async (dispatch) => {
//     try {
//       dispatch(showLoading());
//       const response = await api.patch(
//         API_PARTY_SET_OFF_GET_EDIT_DELETE.replace("{id}", id),
//         data
//       );
//       if (response?.data?.status === "success") {
//         // dispatch(setData({ data: response.data.data }))
//       }
//       dispatch(hideLoading());
//       return response;
//     } catch (error) {
//       dispatch(hideLoading());
//     }
//   };

// export const editReceiptAgainstInvoice =
//   ({ data, id }) =>
//   async (dispatch) => {
//     try {
//       dispatch(showLoading());
//       const response = await api.patch(
//         API_PARTY_SET_OFF_GET_EDIT_DELETE.replace("{id}", id),
//         data
//       );
//       if (response?.data?.status === "success") {
//         // dispatch(setData({ data: response.data.data }))
//         dispatch(setLoading({ loading: false }));
//         dispatch(fetchCustomers());
//         dispatch(clearSalesReports());
//         dispatch(fetchItems());
//       }
//       dispatch(hideLoading());
//       return response;
//     } catch (error) {
//       dispatch(hideLoading());
//     }
//   };

export const clearPartySetOffReports = (data, callback) => async (dispatch) => {
  dispatch(setReports({ ...initialState }));
};
