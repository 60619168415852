import { createSlice } from "@reduxjs/toolkit";
import api, {
  API_PDC_MANAGEMENT_REPORT_LIST,
  API_PDC_GET_EDIT_DELETE,
} from "../utils/axios";

const initialState = {
  loading: false,
  next: "",
  previous: "",
  count: 0,
  page: 0,
  total_pages: 0,
  totalDue: 0,
  totalUnDue: 0,
  total: 0,
  reports: [],
  reportDetails: {},
};

const pdcmanagement = createSlice({
  name: "pdcmanagement",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReports: (state, action) => {
      state.reports = action.payload.reports;
      state.count = action.payload.count;
      state.total_pages = action.payload.total_pages;
      state.total = action.payload.total;
      //   state.totalDue = action.payload.total_due;
      //   state.totalUnDue = action.payload.total_undue;
      //   state.total =
      //     parseFloat(action.payload.total_undue) +
      //     parseFloat(action.payload.total_due);
    },
    setReportDetails: (state, action) => {
      state.reportDetails = action.payload.reportDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setReports, setReportDetails, setErrorMessage } =
  pdcmanagement.actions;

export default pdcmanagement.reducer;

export const fetchPDCManagement =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      //api call
      let url = API_PDC_MANAGEMENT_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{voucherType}", data.voucherType);

      if (data.partyName) {
        url = url + `&party=${data.partyName}`;
      }
      if (data.receivedDateStart) {
        url = url + `&voucher__date__gte=${data.receivedDateStart}`;
      }
      if (data.receivedDateEnd) {
        url = url + `&voucher__date__lte=${data.receivedDateEnd}`;
      }
      if (data.chqDateStart) {
        url = url + `&cheque_date__gte=${data.chqDateStart}`;
      }
      if (data.chqDateEnd) {
        url = url + `&cheque_date__lte=${data.chqDateEnd}`;
      }
      if (data.clearedDateStart) {
        url = url + `&cheque_cleared_date__gte=${data.clearedDateStart}`;
      }
      if (data.clearedDateEnd) {
        url = url + `&cheque_cleared_date__lte=${data.clearedDateEnd}`;
      }
      if (data.depositDateStart) {
        url = url + `&deposit_date__gte=${data.depositDateStart}`;
      }
      if (data.depositDateEnd) {
        url = url + `&deposit_date__lte=${data.depositDateEnd}`;
      }
      if (data.chqAmountGte) {
        url = url + `&voucher__amount__gte=${data.chqAmountGte}`;
      }
      if (data.chqAmountLte) {
        url = url + `&voucher__amount__lte=${data.chqAmountLte}`;
      }
      if (data.clearedBank) {
        url = url + `&cleared_bank=${data.clearedBank}`;
      }
      if (data.isCleared) {
        url = url + `&is_cleared=${data.isCleared}`;
      }
      if (data.chqNumber) {
        url = url + `&cheque_number=${data.chqNumber}`;
      }
      if (data.chqAmount) {
        url = url + `&voucher__amount=${data.chqAmount}`;
      }

      if (data.sort) {
        url = url + `&sorting=${data.sort}`;
      }
      const response = await api.get(url);

      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total: response.data.data.total_amount,
            //   total_due: response.data.data.total_due,
            //   total_undue: response.data.data.total_undue,
            //   total:
            //     parseFloat(response.data.data.total_due) +
            //     parseFloat(response.data.data.total_undue),
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error?.response);
    }
  };

export const updatePDCManagement =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      const response = await api.patch(
        `${API_PDC_GET_EDIT_DELETE}${data.id}`,
        data
      );
      if (response?.data?.status === "success") {
        dispatch(setLoading({ loading: false }));
        callback && callback(response);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      callback && callback(error?.response);
    }
  };

export const clearPDCManagementReports =
  (data, callback) => async (dispatch) => {
    dispatch(setReports({ ...initialState }));
  };
