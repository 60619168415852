/* eslint-disable react-hooks/exhaustive-deps */
import { Tab, Tooltip } from "@chakra-ui/react";
const ITab = ({ children, tooltipLabel = "" }) => {
  return (
    <Tooltip hasArrow label={tooltipLabel} placement="left" bg="#5f5bd4">
      <Tab
        _selected={{ color: "white", bg: "blue.200" }}
        bg="gray.100"
        py={3}
        className="tab-text"
      >
        {children}
      </Tab>
    </Tooltip>
  );
};
export default ITab;
