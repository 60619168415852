import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Box,
  Text,
  IconButton,
  Badge,
  VStack,
  Button,
  useDisclosure,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Modal,
  ModalCloseButton,
  useToast,
} from "@chakra-ui/react";
import { FaDownload } from "react-icons/fa";
import { BsEye } from "react-icons/bs";
import { getLocalStore } from "utils/utils";
import { useDispatch } from "react-redux";
import { useSingleItems } from "hooks/Item/useSingleItem";
import AddItemForm from "components/AddItemForm";
import { Loader } from "components/CustomLoader";
import { useLedgerGroups } from "hooks/LedgerGroup/useLedgerGroups";
import AddLedgerGroup from "components/AddLedgerGroup";
import { useSingleLedgerGroup } from "hooks/LedgerGroup/useSingleLedgerGroup";
import { useSingleLedger } from "hooks/Ledger/useSingleLedger";
import AddLedgerForm from "components/AddLedgerForm";
import { useSingleParty } from "hooks/Parties/useSingleParty";
import AddCustomer from "components/AddCustomer";
import AddSupplier from "components/AddSupplier";
import { useSingleCostCenter } from "hooks/CostCenter/useSingleCostCenter";
import AddCostCentre from "components/AddCostCentre";
import { useSingleDoneBy } from "hooks/DoneBy/useSingleDoneBy";
import AddDoneBy from "components/AddDoneby";
import { useSingleEstimateStatus } from "hooks/EstimateStatus/useSingleEstimateStatus";
import AddEstimateStatus from "components/AddEstimateStatus";
import { useSingleVoucherType } from "hooks/VoucherType/useSingleVoucherType";
import AddVoucherType from "components/AddVoucherType";
import AddProduction from "components/AddProduction";
import { useSingleProduction } from "hooks/Production/useSingleProduction";
import { useDeleteNotification } from "hooks/Notifications/useDeleteNotification";
import { useDeleteItem } from "hooks/Item/useDeleteItem";
import { useDeleteLedgerGroup } from "hooks/LedgerGroup/useDeleteLedgerGroup";
import { useDeleteLedger } from "hooks/Ledger/useDeleteLedger";
import { useDeleteCustomer } from "hooks/Customer/useDeleteCustomer";
import { useDeleteCostCenter } from "hooks/CostCenter/useDeleteCostCenter";
import { useDeleteDoneBy } from "hooks/DoneBy/useDeleteDoneBy";
import { useDeleteEstimateStatus } from "hooks/EstimateStatus/useDeleteEstimateStatus";
import { useDeleteVoucherType } from "hooks/VoucherType/useDeleteVoucherType";
import { useDeleteProduction } from "hooks/Production/useDeleteProduction";
import { useNotifications } from "hooks/Notifications/useNotifications";
import { useDraftById } from "hooks/Drafts/useDraftById";
import AddItemGroup from "components/AddItemGroup";
import { useDraftApproval } from "hooks/Drafts/useDraftApproval";
import AddUnit from "components/Addunit";
import AddItemBrand from "components/AddItemBrand";
import AddItemCategory from "components/AddItemCategory";
import AddStore from "components/AddStore";

export default function NotificationDrawer(props) {
  const { isOpen, onClose } = props;
  const toast = useToast();
  const auth = getLocalStore("AUTH_DETAILS");
  const [rejectButton, setRejectButton] = useState(false);

  const {
    mutate: deleteNotification,
    data: deleteResponse,
    isLoading: isRejectLoading,
  } = useDeleteNotification();

  const {
    data: notificationData,
    refetch,
    isLoading: isLoadingNotifications,
  } = useNotifications();

  const [notificationsData, setNotificationsData] = useState([]);

  useEffect(() => {
    if (notificationData) {
      setNotificationsData(notificationData?.data ?? []);
    }
  }, [notificationData]);

  const openLink = (url) => {
    if (url) {
      let finalUrl = `${process.env.REACT_APP_MEDIA_URL}${url}`;
      window.open(finalUrl, "_blank");
    }
  };

  const dateConverter = (d) => {
    const date = new Date(d);
    const formattedDate = date.toLocaleString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return formattedDate;
  };

  const editItemGroupModal = useDisclosure();
  const editItemUnitModal = useDisclosure();
  const editItemBrandModal = useDisclosure();
  const editItemCategoryModal = useDisclosure();
  const editStoreModal = useDisclosure();

  const editItemModal = useDisclosure();
  const editLedgerGroupModal = useDisclosure();
  const editLedgerModal = useDisclosure();
  const editPartyModal = useDisclosure();
  const editCostCenterModal = useDisclosure();
  const editDoneByModal = useDisclosure();
  const editEstimateStatusModal = useDisclosure();
  const editVoucherGroupModal = useDisclosure();
  const editStdProductionModal = useDisclosure();

  const [notificationId, setNotificationId] = useState(null);
  const [buttonClick, setButtonClick] = useState({ modal: "", clicked: false });
  const [type, setType] = useState("edit");
  const [itemId, setItemId] = useState(null);
  const [ledgerGroupId, setLedgerGroupId] = useState(null);
  const [ledgerId, setLedgerId] = useState(null);
  const [partyId, setPartyId] = useState(null);
  const [costCenterId, setCostCenterId] = useState(null);
  const [doneById, setDoneById] = useState(null);
  const [estimateStatusId, setEstimateStatusId] = useState(null);
  const [voucherGroupId, setVoucherGroupId] = useState(null);
  const [stdProductId, setStdProductId] = useState(null);

  const [previewData, setPreviewData] = useState({});
  const [draftType, setDraftType] = useState("");
  const [draftId, setDraftId] = useState("");
  const [draftMethod, setDraftMethod] = useState("");
  const [originalItemId, setOriginalItemId] = useState(null);

  // const { data: itemData, isLoading: isLoadingItem } = useSingleItems(itemId);
  const { data: SingleLedgerGroupData, isLoading: isLoadingLedgerGroup } =
    useSingleLedgerGroup(ledgerGroupId);
  const { data: ledgerGroupData } = useLedgerGroups();
  const { data: ledgerData, isLoading: isLoadingLedger } =
    useSingleLedger(ledgerId);
  const { data: partyData, isLoading: isLoadingParty } =
    useSingleParty(partyId);
  const { data: costCenterData, isLoading: isLoadingCostCenter } =
    useSingleCostCenter(costCenterId);
  const { data: doneByData, isLoading: isLoadingDoneBy } =
    useSingleDoneBy(doneById);
  const { data: estimateStatusData, isLoading: isLoadingEstimateStatus } =
    useSingleEstimateStatus(estimateStatusId);
  const { data: voucherGroupData, isLoading: isLoadingVoucherGroup } =
    useSingleVoucherType(voucherGroupId);
  const { data: stdProductData, isLoading: isLoadingStdProduct } =
    useSingleProduction(stdProductId);
  const { data: singleDraftData, isLoading: isLoadingDraft } =
    useDraftById(draftId);
  const {
    mutate: draftApproval,
    data: draftApprovalData,
    isSuccess: isDraftApprovalSuccess,
    isLoading: isDraftApprovalLoading,
  } = useDraftApproval();

  const handlePreviewClick = (extras, ID) => {
    setRejectButton(false);
    setNotificationId(ID || null);
    setDraftId(extras.item_id);
    setDraftType(extras.draft_type);
    setDraftMethod(extras.method);
    setOriginalItemId(extras.original_item || null);

    setButtonClick({ modal: extras.model_name, clicked: true });
  };

  useEffect(() => {
    if (singleDraftData && buttonClick.clicked) {
      setPreviewData(singleDraftData.data || {});

      switch (buttonClick.modal) {
        case "ItemGroup":
          editItemGroupModal.onOpen();
          break;
        case "Unit":
          editItemUnitModal.onOpen();
          break;
        case "ItemBrand":
          editItemBrandModal.onOpen();
          break;
        case "ItemCategory":
          editItemCategoryModal.onOpen();
          break;
        case "Store":
          editStoreModal.onOpen();
          break;

        default:
          break;
      }
    }
  }, [singleDraftData, buttonClick]);

  const handleAllModalClose = () => {
    editItemGroupModal.onClose();
    editItemUnitModal.onClose();
    editItemBrandModal.onClose();
    editItemCategoryModal.onClose();
    editStoreModal.onClose();

    resetAll();
  };

  const resetAll = () => {
    setNotificationId(null);
    setPreviewData({});
    setButtonClick({ modal: "", clicked: false });
    setDraftId(null);
    setRejectButton(false);
  };

  const handleIsLoading = (notification_id) => {
    if (isLoadingDraft && notification_id === notificationId) {
      return true;
    } else return false;
  };

  const sortByDate = (a, b) => {
    const dateA = new Date(a.datetime);
    const dateB = new Date(b.datetime);
    return dateB - dateA;
  };

  const handleReject = () => {
    setRejectButton(true);
    if (notificationId) {
      deleteNotification({ id: notificationId });
    }
  };

  useEffect(() => {
    if (isDraftApprovalSuccess) {
      // if (data?.status === "success") {
      toastWithMessage("Approved successfully");
      deleteNotification({ id: notificationId });
      // }
    }
  }, [isDraftApprovalSuccess]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  useEffect(() => {
    if (!deleteResponse) return;
    if (deleteResponse?.status === "success") {
      handleAllModalClose();
    }
  }, [deleteResponse]);

  const handleApprove = () => {
    let payload = {
      draft_id: draftId,
      method: draftMethod,
      update_model_id: draftMethod === "update" ? originalItemId : null,
      draft_type: draftType,
      transaction_type: buttonClick.modal,
    };

    console.log("payload", payload);
    draftApproval(payload);
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Notifications</DrawerHeader>

          <DrawerBody pt={0}>
            <Tabs isFitted variant="enclosed" isLazy>
              <TabList mb="1em" top="0" position="sticky" bg="white" zIndex="1">
                <Tab>
                  Information
                  {notificationsData.length > 0 &&
                    notificationsData.filter(
                      (f) =>
                        f.notification_type.includes("Information") &&
                        f.status === "NEW"
                    ).length > 0 && (
                      <Badge
                        borderRadius="full"
                        bg="blue.500"
                        color="white"
                        ml={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        fontSize="md"
                        p="2px"
                      >
                        {
                          notificationsData.filter(
                            (f) =>
                              f.notification_type.includes("Information") &&
                              f.status === "NEW"
                          ).length
                        }
                      </Badge>
                    )}
                </Tab>
                <Tab>
                  Approvals
                  {notificationsData.length > 0 &&
                    notificationsData.filter(
                      (f) =>
                        f.notification_type === "Approvals" &&
                        f.status === "NEW"
                    ).length > 0 && (
                      <Badge
                        borderRadius="full"
                        bg="blue.500"
                        color="white"
                        ml={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        fontSize="md"
                        p="2px"
                      >
                        {
                          notificationsData.filter(
                            (f) =>
                              f.notification_type === "Approvals" &&
                              f.status === "NEW"
                          ).length
                        }
                      </Badge>
                    )}
                </Tab>
                <Tab>
                  Download
                  {notificationsData.length > 0 &&
                    notificationsData.filter(
                      (f) =>
                        f.notification_type === "Download" && f.status === "NEW"
                    ).length > 0 && (
                      <Badge
                        borderRadius="full"
                        bg="blue.500"
                        color="white"
                        ml={2}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        fontSize="md"
                        p="2px"
                      >
                        {
                          notificationsData.filter(
                            (f) =>
                              f.notification_type === "Download" &&
                              f.status === "NEW"
                          ).length
                        }
                      </Badge>
                    )}
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel p={0}>
                  {isLoadingNotifications ? (
                    <Loader />
                  ) : (
                    <VStack spacing={1}>
                      {notificationsData.length > 0 ? (
                        notificationsData
                          .filter((f) =>
                            f.notification_type.includes("Information")
                          )
                          .sort(sortByDate)
                          .map((i, key) => (
                            <Box
                              key={key}
                              display="flex"
                              flexDir="column"
                              bgColor="gray.200"
                              p={2}
                              py={4}
                              borderRadius="5px"
                              w="100%"
                              _hover={{ bgColor: "gray.300" }}
                            >
                              <Box pb="2" display="flex">
                                <Text fontWeight="bold">{i.title}</Text>
                                <Badge
                                  variant="solid"
                                  colorScheme="green"
                                  ml="2"
                                  display="flex"
                                  alignItems="center"
                                >
                                  {i.status}
                                </Badge>
                              </Box>
                              <Box
                                color="gray.700"
                                display="flex"
                                flexDir="row"
                                justifyContent="space-between"
                              >
                                <Text>{i.content}</Text>
                              </Box>
                              <Box>
                                <Text color="gray.500">
                                  {dateConverter(i.datetime)}
                                </Text>
                              </Box>
                            </Box>
                          ))
                      ) : (
                        <Text
                          textAlign="center"
                          fontStyle="italic"
                          color="gray.600"
                        >
                          No New Notifications
                        </Text>
                      )}
                    </VStack>
                  )}
                </TabPanel>
                <TabPanel p={0}>
                  {isLoadingNotifications ? (
                    <Loader />
                  ) : (
                    <VStack spacing={1}>
                      {notificationsData.length > 0 ? (
                        notificationsData
                          .filter((f) =>
                            f.notification_type.includes("Approvals")
                          )
                          .sort(sortByDate)
                          .map((i, key) => (
                            <Box
                              key={key}
                              display="flex"
                              flexDir="column"
                              bgColor="gray.200"
                              p={2}
                              py={4}
                              borderRadius="5px"
                              w="100%"
                              _hover={{ bgColor: "gray.300" }}
                            >
                              <Box pb="2" display="flex">
                                <Text fontWeight="bold">{i.title}</Text>
                                <Badge
                                  variant="solid"
                                  colorScheme="green"
                                  ml="2"
                                  display="flex"
                                  alignItems="center"
                                  height="fit-content"
                                  py={1}
                                >
                                  {i.status}
                                </Badge>
                              </Box>
                              <Box
                                color="gray.700"
                                display="flex"
                                flexDir="row"
                                justifyContent="space-between"
                              >
                                <Text>{i.content}</Text>
                              </Box>
                              {auth?.data?.user_type === 3 && (
                                <Box py={1}>
                                  <Button
                                    bg="white"
                                    _hover={{ bg: "white", boxShadow: "lg" }}
                                    size="sm"
                                    leftIcon={<BsEye />}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handlePreviewClick(i.extras, i.id);
                                    }}
                                    isLoading={handleIsLoading(i.id)}
                                    loadingText="Loading"
                                  >
                                    Preview
                                  </Button>
                                </Box>
                              )}
                              <Box>
                                <Text color="gray.500">
                                  {dateConverter(i.datetime)}
                                </Text>
                              </Box>
                            </Box>
                          ))
                      ) : (
                        <Text
                          textAlign="center"
                          fontStyle="italic"
                          color="gray.600"
                        >
                          No New Notifications
                        </Text>
                      )}
                    </VStack>
                  )}
                </TabPanel>
                <TabPanel p={0}>
                  {isLoadingNotifications ? (
                    <Loader />
                  ) : (
                    <VStack>
                      {notificationsData.length > 0 ? (
                        notificationsData
                          .filter((f) => f.notification_type === "Download")
                          .sort(sortByDate)
                          .map((i, key) => (
                            <Box
                              key={key}
                              display="flex"
                              flexDir="column"
                              bgColor="gray.200"
                              p={2}
                              py={4}
                              borderRadius="5px"
                              w="100%"
                              _hover={{ bgColor: "gray.300" }}
                            >
                              <Box pb="2" display="flex">
                                <Text fontWeight="bold">{i.title}</Text>
                                <Badge
                                  variant="solid"
                                  colorScheme="green"
                                  ml="2"
                                  display="flex"
                                  alignItems="center"
                                >
                                  {i.status}
                                </Badge>
                              </Box>
                              <Box
                                color="gray.700"
                                display="flex"
                                flexDir="row"
                                justifyContent="space-between"
                              >
                                <Text>{i.content}</Text>
                                <IconButton
                                  icon={<FaDownload color="green" />}
                                  variant="solid"
                                  fontSize="lg"
                                  borderRadius="full"
                                  onClick={() => {
                                    openLink(i.extras?.url);
                                  }}
                                />
                              </Box>
                              <Box>
                                <Text color="gray.500">
                                  {dateConverter(i.datetime)}
                                </Text>
                              </Box>
                            </Box>
                          ))
                      ) : (
                        <Text
                          textAlign="center"
                          fontStyle="italic"
                          color="gray.600"
                        >
                          No New Notifications
                        </Text>
                      )}
                    </VStack>
                  )}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* preview item group modal */}
      {editItemGroupModal.isOpen && (
        <Modal
          isOpen={editItemGroupModal.isOpen}
          onClose={handleAllModalClose}
          size="2xl"
        >
          <ModalOverlay />
          <ModalContent width={"100%"}>
            <ModalCloseButton />
            <ModalBody>
              <AddItemGroup
                type={type}
                editValue={previewData}
                changeType={() => {}}
                onAddSuccess={() => {}}
                handleAddStockItemData={() => {}}
                approveFunc={handleApprove}
                rejectFunc={handleReject}
                isApproval={true}
                isApproveLoading={isDraftApprovalLoading}
                isRejectLoading={isRejectLoading && rejectButton}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* preview item unit modal */}
      {editItemUnitModal.isOpen && (
        <Modal
          isOpen={editItemUnitModal.isOpen}
          onClose={handleAllModalClose}
          size="2xl"
        >
          <ModalOverlay />
          <ModalContent width={"100%"}>
            <ModalCloseButton />
            <ModalBody>
              <AddUnit
                type={type}
                editValue={previewData}
                changeType={() => {}}
                onAddSuccess={() => {}}
                approveFunc={handleApprove}
                rejectFunc={handleReject}
                isApproval={true}
                isApproveLoading={isDraftApprovalLoading}
                isRejectLoading={isRejectLoading && rejectButton}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* preview item brand modal */}
      {editItemBrandModal.isOpen && (
        <Modal
          isOpen={editItemBrandModal.isOpen}
          onClose={handleAllModalClose}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent width={"100%"}>
            <ModalCloseButton />
            <ModalBody>
              <AddItemBrand
                type={type}
                editValue={previewData}
                changeType={() => {}}
                onAddSuccess={() => {}}
                approveFunc={handleApprove}
                rejectFunc={handleReject}
                isApproval={true}
                isApproveLoading={isDraftApprovalLoading}
                isRejectLoading={isRejectLoading && rejectButton}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* preview item category modal */}
      {editItemCategoryModal.isOpen && (
        <Modal
          isOpen={editItemCategoryModal.isOpen}
          onClose={handleAllModalClose}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent width={"100%"}>
            <ModalCloseButton />
            <ModalBody>
              <AddItemCategory
                type={type}
                editValue={previewData}
                changeType={() => {}}
                onAddSuccess={() => {}}
                approveFunc={handleApprove}
                rejectFunc={handleReject}
                isApproval={true}
                isApproveLoading={isDraftApprovalLoading}
                isRejectLoading={isRejectLoading && rejectButton}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* preview item category modal */}
      {editStoreModal.isOpen && (
        <Modal
          isOpen={editStoreModal.isOpen}
          onClose={handleAllModalClose}
          size="xl"
        >
          <ModalOverlay />
          <ModalContent width={"100%"}>
            <ModalCloseButton />
            <ModalBody>
              <AddStore
                type={type}
                editValue={previewData}
                changeType={() => {}}
                onAddSuccess={() => {}}
                approveFunc={handleApprove}
                rejectFunc={handleReject}
                isApproval={true}
                isApproveLoading={isDraftApprovalLoading}
                isRejectLoading={isRejectLoading && rejectButton}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* preview item modal */}
      {editItemModal.isOpen && (
        <Modal
          isOpen={editItemModal.isOpen}
          onClose={handleAllModalClose}
          size="2xl"
        >
          <ModalOverlay />
          <ModalContent width={"100%"}>
            <ModalCloseButton />
            <ModalBody>
              <AddItemForm
                type={type}
                editValue={previewData}
                changeType={() => {}}
                onSuccess={() => {
                  deleteNotification({ id: notificationId });
                }}
                handleAddStockItemData={() => {}}
                isApproval={true}
                rejectFunc={handleReject}
                isRejectLoading={isRejectLoading && rejectButton}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* preview ledger group modal */}
      {editLedgerGroupModal.isOpen && (
        <Modal
          isOpen={editLedgerGroupModal.isOpen}
          onClose={handleAllModalClose}
          scrollBehavior="inside"
          isCentered
        >
          <ModalOverlay />
          <ModalContent width={"100%"}>
            <ModalCloseButton />
            <ModalBody>
              <AddLedgerGroup
                type={type}
                editValue={previewData}
                // listData={ledgerGroupListData}
                onAddSuccess={() => {
                  deleteNotification({ id: notificationId });
                }}
                isApproval={true}
                rejectFunc={handleReject}
                isRejectLoading={isRejectLoading && rejectButton}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* preview ledger modal */}
      {editLedgerModal.isOpen && (
        <Modal
          isOpen={editLedgerModal.isOpen}
          onClose={handleAllModalClose}
          scrollBehavior="inside"
          isCentered
          size="xl"
        >
          <ModalOverlay />
          <ModalContent width={"100%"}>
            <ModalCloseButton />
            <ModalBody>
              <AddLedgerForm
                type={type}
                editValue={previewData}
                onAddSuccess={() => {
                  deleteNotification({ id: notificationId });
                }}
                isApproval={true}
                rejectFunc={handleReject}
                isRejectLoading={isRejectLoading && rejectButton}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* preview party modal */}
      {editPartyModal.isOpen && (
        <Modal
          size="2xl"
          isOpen={editPartyModal.isOpen}
          onClose={handleAllModalClose}
          scrollBehavior="inside"
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              {partyData?.data?.party_type === "CUSTOMER" && (
                <AddCustomer
                  type={type}
                  editValue={previewData}
                  onAddSuccess={() => {
                    deleteNotification({ id: notificationId });
                  }}
                  isApproval={true}
                  rejectFunc={handleReject}
                  isRejectLoading={isRejectLoading && rejectButton}
                />
              )}
              {partyData?.data?.party_type === "SUPPLIER" && (
                <AddSupplier
                  type={type}
                  editValue={previewData}
                  onAddSuccess={() => {
                    deleteNotification({ id: notificationId });
                  }}
                  isApproval={true}
                  rejectFunc={handleReject}
                  isRejectLoading={isRejectLoading && rejectButton}
                />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* preview cost center */}
      {editCostCenterModal.isOpen && (
        <Modal
          isOpen={editCostCenterModal.isOpen}
          onClose={handleAllModalClose}
          scrollBehavior="inside"
          isCentered
        >
          <ModalOverlay />
          <ModalContent width={"100%"}>
            <ModalCloseButton />
            <ModalBody>
              <AddCostCentre
                type={type}
                editValue={previewData}
                onAddSuccess={() => {
                  deleteNotification({ id: notificationId });
                }}
                isApproval={true}
                rejectFunc={handleReject}
                isRejectLoading={isRejectLoading && rejectButton}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* preview doneby */}
      {editDoneByModal.isOpen && (
        <Modal
          isOpen={editDoneByModal.isOpen}
          onClose={handleAllModalClose}
          scrollBehavior="inside"
          isCentered
        >
          <ModalOverlay />
          <ModalContent width={"100%"}>
            <ModalCloseButton />
            <ModalBody>
              <AddDoneBy
                type={type}
                editValue={previewData}
                onAddSuccess={() => {
                  deleteNotification({ id: notificationId });
                }}
                isApproval={true}
                rejectFunc={handleReject}
                isRejectLoading={isRejectLoading && rejectButton}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* preview estimate status */}
      {editEstimateStatusModal.isOpen && (
        <Modal
          isOpen={editEstimateStatusModal.isOpen}
          onClose={handleAllModalClose}
          scrollBehavior="inside"
          isCentered
        >
          <ModalOverlay />
          <ModalContent width={"100%"}>
            <ModalCloseButton />
            <ModalBody>
              <AddEstimateStatus
                type={type}
                editValue={previewData}
                changeType={() => {}}
                clearSearch={() => {}}
                onAddSuccess={() => {
                  deleteNotification({ id: notificationId });
                }}
                isApproval={true}
                rejectFunc={handleReject}
                isRejectLoading={isRejectLoading && rejectButton}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* preview voucher group */}
      {editVoucherGroupModal.isOpen && (
        <Modal
          isOpen={editVoucherGroupModal.isOpen}
          onClose={handleAllModalClose}
          scrollBehavior="inside"
          isCentered
        >
          <ModalOverlay />
          <ModalContent width={"100%"}>
            <ModalCloseButton />
            <ModalBody>
              <AddVoucherType
                type={type}
                editValue={previewData}
                changeType={() => {}}
                clearSearch={() => {}}
                onAddSuccess={() => {
                  deleteNotification({ id: notificationId });
                }}
                isApproval={true}
                rejectFunc={handleReject}
                isRejectLoading={isRejectLoading && rejectButton}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* preview standard production */}
      {editStdProductionModal.isOpen && (
        <Modal
          isOpen={editStdProductionModal.isOpen}
          onClose={handleAllModalClose}
          scrollBehavior="inside"
          isCentered
        >
          <ModalOverlay />
          <ModalContent maxW="80rem">
            <ModalCloseButton />
            <ModalBody>
              <AddProduction
                type={type}
                editValue={previewData}
                changeType={() => {}}
                clearSearch={() => {}}
                onAddSuccess={() => {
                  deleteNotification({ id: notificationId });
                }}
                isApproval={true}
                rejectFunc={handleReject}
                isRejectLoading={isRejectLoading && rejectButton}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
