import { useQuery } from "react-query";
import api, { API_LEDGER_LIST } from "utils/axios";

const getSingleLedger = async ({ queryKey }) => {
  const [_, id] = queryKey;
  if (id) {
    return api.get(`${API_LEDGER_LIST}${id}`).then((res) => res.data);
  }
};

export function useSingleLedger(id) {
  return useQuery(["singleledger", id], getSingleLedger);
}
