import { useQuery } from "react-query";
import api, { API_MULTI_UNIT_PRICING_EDIT } from "utils/axios";

const getSingleMultiUnitPrice = ({ queryKey }) => {
  const [_, id] = queryKey;
  return api
    .get(API_MULTI_UNIT_PRICING_EDIT.replace("{id}", id))
    .then((res) => res.data);
};

export function useSingleMultiUnitPrice(id) {
  return useQuery(["singleMultiUnitPrice", id], getSingleMultiUnitPrice, {
    enabled: !!id,
  });
}
