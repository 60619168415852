export const manualInvoiceNoArray = [
  { text: "Sale", manual: false, value: "sale_manual_numbering_enabled" },
  {
    text: "Sale Return",
    manual: false,
    value: "sales_return_manual_numbering_enabled",
  },
  {
    text: "Purchase",
    manual: false,
    value: "purchase_manual_numbering_enabled",
  },
  {
    text: "Purchase Return",
    manual: false,
    value: "purchase_return_manual_numbering_enabled",
  },
  {
    text: "Expense",
    manual: false,
    value: "expense_manual_numbering_enabled",
  },
  {
    text: "Delivery Order",
    manual: false,
    value: "delivery_order_manual_numbering_enabled",
  },
  {
    text: "Sale Estimate",
    manual: false,
    value: "sale_estimate_manual_numbering_enabled",
  },
  {
    text: "Party Set-Off",
    manual: false,
    value: "party_set_off_manual_numbering_enabled",
  },
  {
    text: "Stock Loss Entry",
    manual: false,
    value: "stock_loss_entry_manual_numbering_enabled",
  },
  {
    text: "Stock Journal",
    manual: false,
    value: "stock_journal_manual_numbering_enabled",
  },
  {
    text: "Journal Entry",
    manual: false,
    value: "journal_entry_manual_numbering_enabled",
  },
  {
    text: "Receipt Against Invoice",
    manual: false,
    value: "receipt_number_sale_manual_numbering_enabled",
  },
  {
    text: "Purchase Payment",
    manual: false,
    value: "payment_number_purchase_manual_numbering_enabled",
  },
  {
    text: "Expense Payment",
    manual: false,
    value: "payment_number_expense_manual_numbering_enabled",
  },
  {
    text: "Other Receipt",
    manual: false,
    value: "receipt_number_manual_numbering_enabled",
  },
  {
    text: "Other Payment",
    manual: false,
    value: "payment_number_manual_numbering_enabled",
  },
  { text: "Sales Estimate", manual: false, value: "SALE_ESTIMATE" },
  { text: "Goods Receipt Note", manual: false, value: "GOODS_RECEIPT_NOTE" },
  { text: "Goods Issue Order", manual: false, value: "GOODS_ISSUE_ORDER" },
  { text: "Proforma Invoice", manual: false, value: "PROFORMA_INVOICE" },
  { text: "Purchase Order", manual: false, value: "PURCHASE_ORDER" },
  {
    text: "Purchase Requesition",
    manual: false,
    value: "PURCHASE_REQUISITION",
  },
  { text: "Retainer Invoice", manual: false, value: "RETAINER_INVOICE" },
  { text: "Sales Order", manual: false, value: "SALES_ORDER" },
  { text: "Consumption Issue", manual: false, value: "CONSUMPTION_ISSUE" },
  { text: "Stock Hold", manual: false, value: "STOCK_HOLD" },
  { text: "Compliment Issue", manual: false, value: "COMPLIMENT_ISSUE" },
];

export const firstInvoiceNoArray = [
  {
    text: "Sales",
    number: null,
    prefix: null,
    suffix: null,
    value: "first_sales_invoice_number",
  },
  {
    text: "Sales Receipt",
    number: null,
    prefix: "Not Available",
    suffix: "Not Available",
    value: "first_sales_receipt_number",
  },
  {
    text: "Purchase Payment",
    number: null,
    prefix: "Not Available",
    suffix: "Not Available",
    value: "first_purchase_payment_number",
  },
  {
    text: "Expenses Payment",
    number: null,
    prefix: "Not Available",
    suffix: "Not Available",
    value: "first_expense_payment_number",
  },
  {
    text: "Other Receipt",
    number: null,
    prefix: "Not Available",
    suffix: "Not Available",
    value: "first_other_receipt_number",
  },
  {
    text: "Other Payment",
    number: null,
    prefix: "Not Available",
    suffix: "Not Available",
    value: "first_other_payment_number",
  },
  {
    text: "D.O",
    number: null,
    prefix: null,
    suffix: null,
    value: "first_delivery_order_number",
  },
  {
    text: "Sales Return",
    number: null,
    prefix: null,
    suffix: null,
    value: "first_sale_return_number",
  },
  {
    text: "Purchase Return",
    number: null,
    prefix: null,
    suffix: null,
    value: "first_purchase_return_number",
  },
  {
    text: "Journal Entry",
    number: null,
    prefix: "Not Available",
    suffix: "Not Available",
    value: "first_journal_entry_number",
  },
  {
    text: "Purchase Voucher",
    number: null,
    prefix: "Not Available",
    suffix: "Not Available",
    value: "first_purchase_voucher_number",
  },
  {
    text: "Expense Voucher",
    number: null,
    prefix: "Not Available",
    suffix: "Not Available",
    value: "first_expense_voucher_number",
  },
  {
    text: "Stock Journal",
    number: null,
    prefix: "Not Available",
    suffix: "Not Available",
    value: "first_stock_journal_voucher_number",
  },
  {
    text: "Customer SetOff",
    number: null,
    prefix: "Not Available",
    suffix: "Not Available",
    value: "first_customer_setoff_invoice_number",
  },
  {
    text: "Supplier SetOff",
    number: null,
    prefix: "Not Available",
    suffix: "Not Available",
    value: "first_supplier_setoff_invoice_number",
  },
  {
    text: "Sales Estimate",
    number: null,
    prefix: null,
    suffix: null,
    value: "SALE_ESTIMATE",
  },
  {
    text: "Goods Receipt Note",
    number: null,
    prefix: null,
    suffix: null,
    value: "GOODS_RECEIPT_NOTE",
  },
  {
    text: "Goods Issue Order",
    number: null,
    prefix: null,
    suffix: null,
    value: "GOODS_ISSUE_ORDER",
  },
  {
    text: "Proforma Invoice",
    number: null,
    prefix: null,
    suffix: null,
    value: "PROFORMA_INVOICE",
  },
  {
    text: "Purchase Order",
    number: null,
    prefix: null,
    suffix: null,
    value: "PURCHASE_ORDER",
  },
  {
    text: "Purchase Requesition",
    number: null,
    prefix: null,
    suffix: null,
    value: "PURCHASE_REQUISITION",
  },
  {
    text: "Retainer Invoice",
    number: null,
    prefix: null,
    suffix: null,
    value: "RETAINER_INVOICE",
  },
  {
    text: "Sales Order",
    number: null,
    prefix: null,
    suffix: null,
    value: "SALES_ORDER",
  },
  {
    text: "Consumption Issue",
    number: null,
    prefix: null,
    suffix: null,
    value: "CONSUMPTION_ISSUE",
  },
  {
    text: "Stock Hold",
    number: null,
    prefix: null,
    suffix: null,
    value: "STOCK_HOLD",
  },
  {
    text: "Compliment Issue",
    number: null,
    prefix: null,
    suffix: null,
    value: "COMPLIMENT_ISSUE",
  },
];

export const groupedPermissionsArray = [
  {
    groupName: "ADD",
    permissions: [
      {
        text: "Stock Item",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      { text: "Customer", create: true, view: true, edit: true, delete: true },
      { text: "Supplier", create: true, view: true, edit: true, delete: true },
      {
        text: "Ledger Group",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      { text: "Ledger", create: true, view: true, edit: true, delete: true },
      {
        text: "Cost Centre",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      { text: "Done By", create: true, view: true, edit: true, delete: true },
      { text: "Item Unit", create: true, view: true, edit: true, delete: true },
      {
        text: "Item Brand Group Category",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Estimate Status",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Voucher Group",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Store",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Mode Of Payment",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Multi Unit Pricing",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
    ],
  },
  {
    groupName: "SALES",
    permissions: [
      { text: "Sale", create: true, view: true, edit: true, delete: true },
      {
        text: "Sale Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Sale Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Sale Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Sale Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Item Wise Sale Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Customer Sale Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Sales Daily Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Sales Monthly Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Cost centre Sales Daily Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Cost centre Sales Monthly Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "PURCHASE",
    permissions: [
      { text: "Purchase", create: true, view: true, edit: true, delete: true },
      {
        text: "Purchase Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Purchase Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Purchase Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Purchase Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Item Wise Purchase Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Supplier Purchase Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Purchases Daily Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Purchases Monthly Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Cost centre Purchases Daily Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Cost centre Purchases Monthly Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "EXPENSES",
    permissions: [
      { text: "Expense", create: true, view: true, edit: true, delete: true },
      {
        text: "Expense Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Expense Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Expense Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Expense Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Supplier Expense Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Expense Daily Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Expense Monthly Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Cost centre Expense Daily Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Cost centre Expense Monthly Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "RECEIPT",
    permissions: [
      {
        text: "Sale Receipt",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Other Reciept",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "PurchaseReturn Receipt",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Contra Receipt",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Doneby Receipt Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Receipt Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Ledger Reciepts Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "PAYMENT",
    permissions: [
      {
        text: "SaleReturn Payment",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Purchase Payment",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Expense Payment",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Doneby Payment Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Payment Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Other Payment",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Ledger Payments Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "OTHERS",
    permissions: [
      {
        text: "Journal Entry",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Customer Setoff",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Supplier Setoff",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
    ],
  },
  {
    groupName: "OTHERS IN REPORTS",
    permissions: [
      {
        text: "PDC Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Pending Advance",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "VAT Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "VAT Party Wise Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "VAT Costcentre Wise Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "VAT HSN Wise Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "VAT Taxtype Wise Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "VAT Place Of Supply Wise Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "VAT VoucherGroup Wise Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Master Group Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Ledger Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "All Transaction Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Customer Balance Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Supplier Balance Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "STOCK TRANSACTIONS",
    permissions: [
      {
        text: "Stock Journal",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Store Transfer",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Product List",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
    ],
  },
  {
    groupName: "SALE RETURN",
    permissions: [
      {
        text: "Sale Return",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Sale Return Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Sale Return Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Sale Return Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Sale Return Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Item Wise Sale Return Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Customer Sale Return Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby SalesReturn Daily Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby SalesReturn Monthly Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Cost centre SalesReturn Daily Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Cost centre SalesReturn Monthly Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "PURCHASE RETURN",
    permissions: [
      {
        text: "Purchase Return",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Purchase Return Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Purchase Return Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Purchase Return Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Purchase Return Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Item Wise Purchase Return Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Supplier Purchase Return Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby PurchaseReturn Daily Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby PurchaseReturn Monthly Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Cost centre PurchaseReturn Daily Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Cost centre PurchaseReturn Monthly Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "DELIVERY ORDER",
    permissions: [
      { text: "DO", create: true, view: true, edit: true, delete: true },
      {
        text: "D O With Amount",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "PartyWise DO Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby DO Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre DO Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Purchase Return Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Purchase Return Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Delivery Order Daily Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Delivery Order Monthly Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Cost centre Delivery Order Daily Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Cost centre Delivery Order Monthly Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "SALE ESTIMATE",
    permissions: [
      {
        text: "Sale Estimate",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Sale Estimate Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Sale Estimate Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "PartyWise Sale Estimates Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Sale Estimates Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Sale Estimates Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Sale Estimate Daily Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Sale Estimate Monthly Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Cost Centre Sale Estimate Daily Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Cost Centre Sale Estimate Monthly Summary",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "COMPLIMENT ISSUE",
    permissions: [
      {
        text: "Compliment Issue",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Compliment Issue Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Compliment Issue Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },

      {
        text: "Costcentre Compliment Issue Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Partywise Compliment Issue Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Compliment Issue Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "STOCK HOLD",
    permissions: [
      {
        text: "Stock Hold",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Stock Hold Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Stock Hold Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Stock Hold Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Partywise Stock Hold Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Stock Hold Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "CONSUMPTION ISSUE",
    permissions: [
      {
        text: "Consumption Issue",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Consumption Issue Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Consumption Issue Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Consumption Issue Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Partywise Consumption Issue Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Consumption Issue Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "GOODS ISSUE ORDER",
    permissions: [
      {
        text: "Goods Issue Order",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Goods Issue Order Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Goods Issue Order Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Goods Issue Order Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Partywise Goods Issue Order Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Goods Issue Order Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "PROFORMA INVOICE",
    permissions: [
      {
        text: "Proforma Invoice",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Proforma Invoice Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Proforma Invoice Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Proforma Invoice Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Partywise Proforma Invoice Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Proforma Invoice Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "RETAINER INVOICE",
    permissions: [
      {
        text: "Retainer Invoice",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Retainer Invoice Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Retainer Invoice Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Retainer Invoice Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Partywise Retainer Invoice Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Retainer Invoice Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "SALES ORDER",
    permissions: [
      {
        text: "Sales Order",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Sales Order Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Sales Order Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Sales Order Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Partywise Sales Order Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Sales Order Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "GOODS RECEIPT NOTE",
    permissions: [
      {
        text: "Goods Receipt Note",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Goods Receipt Note Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Goods Receipt Note Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Goods Receipt Note Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Partywise Goods Receipt Note Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Goods Receipt Note Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "PURCHASE ORDER",
    permissions: [
      {
        text: "Purchase Order",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Purchase Order Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Purchase Order Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Purchase Order Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Partywise Purchase Order Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Purchase Order Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "PURCHASE REQUISITION",
    permissions: [
      {
        text: "Purchase Requisition",
        create: true,
        view: true,
        edit: true,
        delete: true,
      },
      {
        text: "Purchase Requisition Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Purchase Requisition Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Purchase Requisition Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Partywise Purchase Requisition Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Purchase Requisition Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "STOCK REPORTS",
    permissions: [
      {
        text: "Item Wise Daily Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Item Wise Monthly Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Item Wise Profit Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Stock Summery Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Low Stock Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Stock Detailed Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Item Detailed Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Inventory Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Store Stock Summary Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Store Stock Profit Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Store Stock Low Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Store Stock Detailed Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Group Stock Summary Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Group Stock Profit Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Group Stock Low Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Group Stock Detailed Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "AGING REPORTS",
    permissions: [
      {
        text: "Sale Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Purchase Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Expense Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Sales Return Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Purchase Return Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Party Wise Sale Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Party Wise Purchase Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Party Wise SaleReturn Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Party Wise PurchaseReturn Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Party Wise Expense Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },

      {
        text: "Party Wise Monthly Sale Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Party Wise Monthly Purchase Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Party Wise Monthly SaleReturn Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Party Wise Monthly PurchaseReturn Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Party Wise Monthly Expense Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },

      {
        text: "Costcentre Wise Sale Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Wise Purchase Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Wise SaleReturn Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Wise PurchaseReturn Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Wise Expense Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Wise Sale Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Wise Purchase Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Wise SaleReturn Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Wise PurchaseReturn Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Wise Expense Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },

      {
        text: "Costcentre Wise Monthly Sale Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Wise Monthly Purchase Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Wise Monthly SaleReturn Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Wise Monthly PurchaseReturn Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Costcentre Wise Monthly Expense Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Wise Monthly Sale Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Wise Monthly Purchase Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Wise Monthly SaleReturn Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Wise Monthly PurchaseReturn Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Doneby Wise Monthly Expense Aging Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "FINANCIAL REPORTS",
    permissions: [
      {
        text: "Balance Sheet",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Profit and Loss Account",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Trail Balance",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "SUMMARY REPORTS",
    permissions: [
      { text: "Cash Book", create: null, view: true, edit: null, delete: null },
      { text: "Bank Book", create: null, view: true, edit: null, delete: null },
      {
        text: "DashBoard Summery",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
  {
    groupName: "STATISTICS SUMMARY REPORTS",
    permissions: [
      {
        text: "Transaction Summary Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Voucher Summary Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
      {
        text: "Dashboard Summary Report",
        create: null,
        view: true,
        edit: null,
        delete: null,
      },
    ],
  },
];

export const transactionPermissionsArray = [
  { text: "Sale", create: true, view: true, edit: true, delete: true },
  { text: "Purchase", create: true, view: true, edit: true, delete: true },
  { text: "Expense", create: true, view: true, edit: true, delete: true },
  {
    text: "Sale Receipt",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Purchase Payment",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Expense Payment",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Other Reciept",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Other Payment",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Journal Entry",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Sale Return",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Purchase Return",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  { text: "DO", create: true, view: true, edit: true, delete: true },
  {
    text: "Sale Estimate",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Goods Receipt Note",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Goods Issue Order",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Proforma Invoice",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Purchase Order",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Purchase Requisition",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Retainer Invoice",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Sales Order",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Consumption Issue",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Stock Hold",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Compliment Issue",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "D O With Amount",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Stock Item",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  { text: "Customer", create: true, view: true, edit: true, delete: true },
  { text: "Supplier", create: true, view: true, edit: true, delete: true },
  {
    text: "Ledger Group",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  { text: "Ledger", create: true, view: true, edit: true, delete: true },
  {
    text: "Cost Centre",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  { text: "Done By", create: true, view: true, edit: true, delete: true },
  { text: "Item Unit", create: true, view: true, edit: true, delete: true },
  {
    text: "Item Brand Group Category",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Estimate Status",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Voucher Group",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Product List",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Stock Journal",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Customer Setoff",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Supplier Setoff",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Salereturn Payment",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },
  {
    text: "Purchasereturn Receipt",
    create: true,
    view: true,
    edit: true,
    delete: true,
  },

  {
    text: "Balance Sheet",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Profit and Loss Account",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Trail Balance",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Item Wise Profit Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Stock Summery Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Low Stock Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Stock Detailed Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Item Detailed Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  { text: "VAT Report", create: null, view: true, edit: null, delete: null },
  {
    text: "All Transaction Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  { text: "Cash Book", create: null, view: true, edit: null, delete: null },
  { text: "Bank Book", create: null, view: true, edit: null, delete: null },
  {
    text: "DashBoard Summery",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Sale Daily Summery",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Purchase Daily Summery",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Expense Daily Summery",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Sale Estimate Daily Summery",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Item Wise Daily Summery",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Sale Monthly Summery",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Purchase Monthly Summery",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Expense Monthly Summery",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Sale Estimate Monthly Summery",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Item Wise Monthly Summery",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Item Wise Sale Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Item Wise Purchase Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Item Wise Sale Return Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Item Wise Purchase Return Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Sale Aging Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Purchase Aging Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Expense Aging Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Sales Return Aging Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Purchase Return Aging Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Party Wise Sale Aging Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Party Wise Purchase Aging Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Party Wise SaleReturn Aging Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Party Wise PurchaseReturn Aging Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Party Wise Expense Aging Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "PartyWise Sale Estimates Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "PartyWise DO Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  { text: "PDC Report", create: null, view: true, edit: null, delete: null },
  {
    text: "Pending Advance",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Doneby Sale Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Doneby Purchase Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Doneby Expense Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Doneby Sale Return Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Doneby Purchase Return Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Doneby Receipt Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Doneby Payment Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Doneby Sale Estimates Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Doneby DO Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Customer Sale Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Supplier Purchase Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Supplier Expense Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Customer Sale Return Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
  {
    text: "Supplier Purchase Return Report",
    create: null,
    view: true,
    edit: null,
    delete: null,
  },
];

export const nameDuplicationsArray = [
  { id: "1", label: "Add Stock", value: "STOCK" },
  { id: "2", label: "Add Customer", value: "CUSTOMER" },
  { id: "3", label: "Add Supplier", value: "SUPPLIER" },
  { id: "4", label: "Add Ledger", value: "LEDGER" },
];

// export const approvalsArray = [
//   { approve: true, label: "Sale", value: "sale", type: "normal" },
//   { approve: true, label: "Purchase", value: "Purchase", type: "normal" },
//   { approve: true, label: "Expense", value: "expense", type: "normal" },
//   { approve: true, label: "Sale Return", value: "salereturn", type: "normal" },
//   {
//     approve: true,
//     label: "Purchase Return",
//     value: "purchasereturn",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Journal Entry",
//     value: "journalentry",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Customer Setoff",
//     value: "customersetoff",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Supplier Setoff",
//     value: "suppliersetoff",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Stock Journal",
//     value: "stockjournal",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Delivery Order",
//     value: "deliveryorder",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Sale Estimate",
//     value: "saleestimate",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Good Receipt Note",
//     value: "goodreceiptnote",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Goods Issue Order",
//     value: "goodsissueorder",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Proforma Invoice",
//     value: "proformainvoice",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Purchase Order",
//     value: "purchaseorder",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Purchase Requisition",
//     value: "purchaserequisition",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Retainer Invoice",
//     value: "retainerinvoice",
//     type: "normal",
//   },
//   { approve: true, label: "Sale Order", value: "saleorder", type: "normal" },
//   {
//     approve: true,
//     label: "Consumption Issue",
//     value: "consumptionissue",
//     type: "normal",
//   },
//   { approve: true, label: "Stock Hold", value: "stockhold", type: "normal" },
//   {
//     approve: true,
//     label: "Compliment Issue",
//     value: "complimentissue",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Sale Receipt",
//     value: "salereceipt",
//     type: "financial",
//   },
//   {
//     approve: true,
//     label: "Purchase Payment",
//     value: "purchasepayment",
//     type: "financial",
//   },
//   {
//     approve: true,
//     label: "Expense Payment",
//     value: "expensepayment",
//     type: "financial",
//   },
//   {
//     approve: true,
//     label: "Other Receipt",
//     value: "otherreceipt",
//     type: "financial",
//   },
//   {
//     approve: true,
//     label: "Other Payment",
//     value: "otherpayment",
//     type: "financial",
//   },
//   {
//     approve: true,
//     label: "Contra Receipt",
//     value: "contrareceipt",
//     type: "financial",
//   },
//   {
//     approve: true,
//     label: "Sale Return Payment",
//     value: "salereturnpayment",
//     type: "financial",
//   },
//   {
//     approve: true,
//     label: "Purchase Return Receipt",
//     value: "purchasereturnreceipt",
//     type: "financial",
//   },
//   {
//     approve: true,
//     label: "Add Stock Item",
//     value: "stockitem",
//     type: "normal",
//   },
//   { approve: true, label: "Add Customer", value: "customer", type: "normal" },
//   { approve: true, label: "Add Supplier", value: "supplier", type: "normal" },
//   { approve: true, label: "Add Doneby", value: "doneby", type: "normal" },
//   {
//     approve: true,
//     label: "Add Cost Center",
//     value: "costcentre",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Add Sale Estimate Status",
//     value: "saleestimatestatus",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Add Standard Product",
//     value: "standardproduct",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Add Ledger Group",
//     value: "ledgergroup",
//     type: "financial",
//   },
//   { approve: true, label: "Add Ledger", value: "ledger", type: "financial" },
//   {
//     approve: true,
//     label: "Add Voucher Group",
//     value: "vouchergroup",
//     type: "financial",
//   },
//   { approve: true, label: "Add Item Unit", value: "itemunit", type: "normal" },
//   {
//     approve: true,
//     label: "Add Item Group",
//     value: "itemgroup",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Add Item Brand",
//     value: "itembrand",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Add Item Category",
//     value: "itemcategory",
//     type: "normal",
//   },
//   {
//     approve: true,
//     label: "Add Item Store",
//     value: "itemstore",
//     type: "normal",
//   },
// ];


export const approvalsArray = [
  {
    group: "MASTER DATA",
    options: [
      {
        approve: true,
        label: "Add Stock Item",
        value: "stockitem",
        type: "normal",
      },
      {
        approve: true,
        label: "Add Customer",
        value: "customer",
        type: "normal",
      },
      {
        approve: true,
        label: "Add Supplier",
        value: "supplier",
        type: "normal",
      },
      {
        approve: true,
        label: "Add Ledger Group",
        value: "ledgergroup",
        type: "financial",
      },
      {
        approve: true,
        label: "Add Ledger",
        value: "ledger",
        type: "financial",
      },
      { approve: true, label: "Add Doneby", value: "doneby", type: "normal" },
      {
        approve: true,
        label: "Add Cost Center",
        value: "costcentre",
        type: "normal",
      },
      {
        approve: true,
        label: "Add Sale Estimate Status",
        value: "saleestimatestatus",
        type: "normal",
      },
      {
        approve: true,
        label: "Add Voucher Group",
        value: "vouchergroup",
        type: "financial",
      },
      {
        approve: true,
        label: "Add Item Unit",
        value: "itemunit",
        type: "normal",
      },
      {
        approve: true,
        label: "Add Item Group",
        value: "itemgroup",
        type: "normal",
      },
      {
        approve: true,
        label: "Add Item Brand",
        value: "itembrand",
        type: "normal",
      },
      {
        approve: true,
        label: "Add Item Category",
        value: "itemcategory",
        type: "normal",
      },
      {
        approve: true,
        label: "Add Item Store",
        value: "itemstore",
        type: "normal",
      },
    ],
  },
  {
    group: "SALE RELATED TRANSACTIONS",
    options: [
      { approve: true, label: "Sale", value: "sale", type: "normal" },
      {
        approve: true,
        label: "Sale Return",
        value: "salereturn",
        type: "normal",
      },
      {
        approve: true,
        label: "Delivery Order",
        value: "deliveryorder",
        type: "normal",
      },
      {
        approve: true,
        label: "Sale Estimate",
        value: "saleestimate",
        type: "normal",
      },
      {
        approve: true,
        label: "Goods Issue Order",
        value: "goodsissueorder",
        type: "normal",
      },
      {
        approve: true,
        label: "Proforma Invoice",
        value: "proformainvoice",
        type: "normal",
      },
      {
        approve: true,
        label: "Retainer Invoice",
        value: "retainerinvoice",
        type: "normal",
      },
      {
        approve: true,
        label: "Sale Order",
        value: "saleorder",
        type: "normal",
      },
    ],
  },

  {
    group: "PURCHASE & EXPENSE RELATED TRANSACTIONS",
    options: [
      { approve: true, label: "Purchase", value: "Purchase", type: "normal" },
      { approve: true, label: "Expense", value: "expense", type: "normal" },

      {
        approve: true,
        label: "Purchase Return",
        value: "purchasereturn",
        type: "normal",
      },
      {
        approve: true,
        label: "Good Receipt Note",
        value: "goodreceiptnote",
        type: "normal",
      },

      {
        approve: true,
        label: "Purchase Order",
        value: "purchaseorder",
        type: "normal",
      },
      {
        approve: true,
        label: "Purchase Requisition",
        value: "purchaserequisition",
        type: "normal",
      },
    ],
  },
  {
    group: "RECEIPT AND PAYMENT",
    options: [
      {
        approve: true,
        label: "Sale Receipt",
        value: "salereceipt",
        type: "financial",
      },
      {
        approve: true,
        label: "Purchase Payment",
        value: "purchasepayment",
        type: "financial",
      },
      {
        approve: true,
        label: "Expense Payment",
        value: "expensepayment",
        type: "financial",
      },
      {
        approve: true,
        label: "Other Receipt",
        value: "otherreceipt",
        type: "financial",
      },
      {
        approve: true,
        label: "Other Payment",
        value: "otherpayment",
        type: "financial",
      },
      {
        approve: true,
        label: "Contra Receipt",
        value: "contrareceipt",
        type: "financial",
      },
      {
        approve: true,
        label: "Sale Return Payment",
        value: "salereturnpayment",
        type: "financial",
      },
      {
        approve: true,
        label: "Purchase Return Receipt",
        value: "purchasereturnreceipt",
        type: "financial",
      },
    ],
  },
  {
    group: "STOCK TRANSACTIONS",
    options: [
      {
        approve: true,
        label: "Stock Journal",
        value: "stockjournal",
        type: "normal",
      },

      {
        approve: true,
        label: "Consumption Issue",
        value: "consumptionissue",
        type: "normal",
      },
      {
        approve: true,
        label: "Stock Hold",
        value: "stockhold",
        type: "normal",
      },
      {
        approve: true,
        label: "Compliment Issue",
        value: "complimentissue",
        type: "normal",
      },
      {
        approve: true,
        label: "Add Standard Product",
        value: "standardproduct",
        type: "normal",
      },
    ],
  },
  {
    group: "OTHER TRANSACTIONS",
    options: [
      {
        approve: true,
        label: "Journal Entry",
        value: "journalentry",
        type: "normal",
      },
      {
        approve: true,
        label: "Customer Setoff",
        value: "customersetoff",
        type: "normal",
      },
      {
        approve: true,
        label: "Supplier Setoff",
        value: "suppliersetoff",
        type: "normal",
      },
    ],
  },
];