import { createSlice } from "@reduxjs/toolkit";

import api, {
  API_PROFIT_AND_LOSS,
  API_PROFIT_AND_LOSS_EXPORT,
} from "../utils/axios";

import { showLoading, hideLoading } from "./common.slice";

const initialState = {
  loading: false,
  error: null,
  profitAndLossDetails: {},
};

const profitandloss = createSlice({
  name: "profitandloss",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setProfitAndLossDetails: (state, action) => {
      state.profitAndLossDetails = action.payload.profitAndLossDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setProfitAndLossDetails, setErrorMessage } =
  profitandloss.actions;

export default profitandloss.reducer;

export const fetchProfitAndLossDetails =
  (data, callback) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      console.log("data----------", data);
      var response;
      response = await api.get(
        API_PROFIT_AND_LOSS.replace("{startDate}", data.startDate).replace(
          "{endDate}",
          data.endDate
        )
      );

      if (response?.data?.status === "success") {
        dispatch(
          setProfitAndLossDetails({ profitAndLossDetails: response.data.data })
        );
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setProfitAndLossDetails({ profitAndLossDetails: {} }));
    }
  };

export const exportProfitAndLossDetails = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    console.log("data----------", data);
    const response = await api.get(
      API_PROFIT_AND_LOSS_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};
