import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { TextField } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { useDecimalLength } from "hooks/useDecimalLength";
import React, { useEffect, useState } from "react";
import PriceCategoryNameSelectionField from "../PriceCategoryNameSelectionField";
import { useVoucherType } from "hooks/VoucherType/useVoucherType";
import MultiSelectionField from "components/MultiSelectionField";
import {
  IButton,
  IHead,
  IModalOut,
  IModalIn,
  IHStack,
  IButtonOut,
} from "components/IModal";
function PriceCategoryModal(props) {
  const { initialValue, taxPercentage = 0, onSubmit, isOpen, onClose } = props;
  const decimalLength = useDecimalLength(2);
  const { data: voucherTypeData, isLoading: voucherTypeLoading } =
    useVoucherType();
  const [showTax, setShowTax] = useState(false);
  const [disable, setDisable] = useState(true);
  const [item, setItem] = useState({
    id: null,
    category: null,
    voucher_group: [],
    price: null,
    price_with_tax: null,
    sale_discount: null,
    sale_discount_with_tax: null,
  });
  const [withGstNetAmount, setWithGstNetAmount] = useState(false);
  const [withoutGstNetAmount, setWithoutGstNetAmount] = useState(false);

  useEffect(() => {
    setItem({
      ...initialValue,
      id: initialValue?.id || null,
      voucher_group: initialValue?.voucher_group || [],
      price: _parseFloat(initialValue?.price).toFixed(decimalLength),
      price_with_tax: _parseFloat(initialValue?.price_with_tax).toFixed(
        decimalLength
      ),
      sale_discount: _parseFloat(initialValue?.sale_discount).toFixed(
        decimalLength
      ),
      sale_discount_with_tax: _parseFloat(
        initialValue?.sale_discount_with_tax
      ).toFixed(decimalLength),
    });
  }, [isOpen, initialValue]);

  // price without tax
  const setPriceValue = (price) => {
    let taxAmount = (price * taxPercentage) / 100;
    let priceWithTax = _parseFloat(price) + taxAmount;
    setItem({
      ...item,
      price: price,
      price_with_tax: _parseFloat(priceWithTax).toFixed(decimalLength),
    });

    let decimals = price.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setItem({
        ...item,
        price: _parseFloat(price).toFixed(decimalLength),
      });
    }
  };

  // discount without tax
  const setDiscountWithOutTaxValue = (discountWithOutTax) => {
    let discountedPrice = item.price - discountWithOutTax;

    // if (discountedPrice < 0) {
    //   setFormErrors({
    //     discount: "Discount should be less than Price",
    //   });
    // } else {
    //   setFormErrors(null);
    // }

    let tax = (discountedPrice * taxPercentage) / 100;
    let taxAmount = (discountWithOutTax * taxPercentage) / 100;
    let discountWithTax = _parseFloat(discountWithOutTax) + taxAmount;

    setItem({
      ...item,
      sale_discount: _parseFloat(discountWithOutTax),
      sale_discount_with_tax:
        _parseFloat(discountWithTax).toFixed(decimalLength),
    });

    let decimals = discountWithOutTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setItem({
        ...item,
        sale_discount: _parseFloat(discountWithOutTax).toFixed(decimalLength),
      });
    }
  };

  // price with tax
  const setPriceWithTaxValue = (priceWithTax) => {
    let price = _parseFloat(priceWithTax) / (1 + taxPercentage / 100);
    setItem({
      ...item,
      price: _parseFloat(price).toFixed(decimalLength),
      price_with_tax: _parseFloat(priceWithTax),
    });

    let decimals = priceWithTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setItem({
        ...item,
        price_with_tax: _parseFloat(priceWithTax).toFixed(decimalLength),
      });
    }
  };

  //Discount With Tax
  const setDiscountWithTaxValue = (discountWithTax) => {
    let discountWithOutTax =
      _parseFloat(discountWithTax) / (1 + taxPercentage / 100);
    setItem({
      ...item,
      sale_discount: _parseFloat(discountWithOutTax).toFixed(decimalLength),
      sale_discount_with_tax: _parseFloat(discountWithTax),
    });

    let decimals = discountWithTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setItem({
        ...item,
        sale_discount_with_tax:
          _parseFloat(discountWithTax).toFixed(decimalLength),
      });
    }
  };

  useEffect(() => {
    setWithGstNetAmount(item.price_with_tax - item.sale_discount_with_tax);
    setWithoutGstNetAmount(item.price - item.sale_discount);

    if (!item.category) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [item]);

  const onCloseModal = () => {
    setItem({});
    onClose();
  };

  const handleSubmit = () => {
    onSubmit({ ...item });
  };

  return (
    <>
      {!isMobile ? (
        <Modal
          isOpen={isOpen}
          onClose={onCloseModal}
          isCentered
          scrollBehavior="inside"
          size="2xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <IModalOut>
                <IHead text="Price Category" />
                <IModalIn>
                  <IHStack>
                    <Spacer />
                    <Checkbox
                      isChecked={showTax}
                      onChange={(e) => {
                        setShowTax(!showTax);
                      }}
                    >
                      Show With Tax
                    </Checkbox>
                  </IHStack>
                  <PriceCategoryNameSelectionField
                    priceCategoryName={item?.category}
                    setPriceCategoryName={(priceCategoryName) => {
                      setItem({
                        ...item,
                        category: priceCategoryName?.id,
                        categoryName: priceCategoryName?.name,
                      });
                    }}
                  />
                  <MultiSelectionField
                    placeholder="voucher type"
                    datas={voucherTypeData?.data}
                    options={item?.voucher_group}
                    setOptions={(option) => {
                      setItem({ ...item, voucher_group: option });
                    }}
                    isLoading={voucherTypeLoading}
                    disablePortal={true}
                  />
                  <FormLabel>
                    <Text>Price Without Tax</Text>
                  </FormLabel>
                  <Grid
                    autoRows="auto"
                    templateColumns="repeat(3, 1fr)"
                    columnGap={4}
                  >
                    <GridItem colSpan={1}>
                      <FormControl id="price">
                        <TextField
                          label="Price Without Tax"
                          variant="outlined"
                          size="small"
                          type="number"
                          onChange={(event) => {
                            setPriceValue(event.target.value);
                          }}
                          value={item.price || ""}
                          onFocus={(e) => e.target.select()}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <TextField
                          label="Discount Without Tax"
                          variant="outlined"
                          size="small"
                          type="number"
                          onChange={(event) =>
                            setDiscountWithOutTaxValue(event.target.value)
                          }
                          value={item.sale_discount}
                          onFocus={(e) => e.target.select()}
                        ></TextField>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <TextField
                          disabled
                          label="Net Amount"
                          variant="outlined"
                          size="small"
                          type="text"
                          value={_parseFloat(withoutGstNetAmount).toFixed(
                            decimalLength
                          )}
                        ></TextField>
                      </FormControl>
                    </GridItem>
                  </Grid>
                  {showTax ? (
                    <>
                      <FormLabel>
                        <Text>Price With Tax</Text>
                      </FormLabel>
                      <Grid
                        autoRows="auto"
                        templateColumns="repeat(3, 1fr)"
                        columnGap={4}
                      >
                        <GridItem colSpan={1}>
                          <FormControl id="priceWithTax">
                            <TextField
                              label="Price With Tax"
                              variant="outlined"
                              size="small"
                              type="number"
                              onChange={(event) =>
                                setPriceWithTaxValue(event.target.value)
                              }
                              value={item.price_with_tax || ""}
                              onFocus={(e) => e.target.select()}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <FormControl>
                            <TextField
                              label="Discount With Tax"
                              variant="outlined"
                              size="small"
                              type="number"
                              onChange={(event) =>
                                setDiscountWithTaxValue(event.target.value)
                              }
                              value={item.sale_discount_with_tax || ""}
                              onFocus={(e) => e.target.select()}
                            ></TextField>
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <FormControl>
                            <TextField
                              disabled
                              label="Net Amount"
                              variant="outlined"
                              size="small"
                              type="text"
                              value={_parseFloat(withGstNetAmount).toFixed(
                                decimalLength
                              )}
                            ></TextField>
                          </FormControl>
                        </GridItem>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                </IModalIn>
                <IButtonOut>
                  <IButton
                    disabled={disable}
                    onClick={handleSubmit}
                    type="add"
                  />
                </IButtonOut>
              </IModalOut>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        // mobile view
        <Modal
          isOpen={isOpen}
          onClose={onCloseModal}
          isCentered
          scrollBehavior="inside"
          size="2xl"
        >
          <ModalOverlay />
          <ModalContent width="90%">
            <ModalHeader>
              <Box px={2}>
                <Text size="md">Price Category</Text>
              </Box>
            </ModalHeader>
            <ModalCloseButton />
            <Divider />
            <ModalBody>
              <Box p={2} pt={0}>
                <Stack spacing={5} direction="row">
                  <Spacer />
                  <Checkbox
                    isChecked={showTax}
                    onChange={(e) => {
                      setShowTax(!showTax);
                    }}
                  >
                    Show With Tax
                  </Checkbox>
                </Stack>
                <Box py={2}>
                  <PriceCategoryNameSelectionField
                    priceCategoryName={item?.category}
                    modal={true}
                    setPriceCategoryName={(priceCategoryName) => {
                      setItem({
                        ...item,
                        category: priceCategoryName?.id,
                        categoryName: priceCategoryName?.name,
                      });
                    }}
                  />
                </Box>
                <Box pb={2}>
                  <FormLabel>
                    <Text>Price Without Tax</Text>
                  </FormLabel>
                  <Grid
                    autoRows="auto"
                    templateColumns="repeat(3, 1fr)"
                    columnGap={2}
                    rowGap={2}
                  >
                    <GridItem colSpan={1}>
                      <FormControl id="price">
                        <TextField
                          label="Price"
                          variant="outlined"
                          size="small"
                          type="number"
                          onChange={(event) => {
                            setPriceValue(event.target.value);
                          }}
                          value={item.price || ""}
                          onFocus={(e) => e.target.select()}
                          InputProps={{ style: { fontSize: "12px" } }}
                          InputLabelProps={{
                            style: { fontSize: "12px" },
                          }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <TextField
                          label="Discount"
                          variant="outlined"
                          size="small"
                          type="number"
                          onChange={(event) =>
                            setDiscountWithOutTaxValue(event.target.value)
                          }
                          value={item.sale_discount}
                          onFocus={(e) => e.target.select()}
                          InputProps={{ style: { fontSize: "12px" } }}
                          InputLabelProps={{
                            style: { fontSize: "12px" },
                          }}
                        ></TextField>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <TextField
                          disabled
                          label="Net Amount"
                          variant="outlined"
                          size="small"
                          type="text"
                          value={_parseFloat(withoutGstNetAmount).toFixed(
                            decimalLength
                          )}
                        ></TextField>
                      </FormControl>
                    </GridItem>
                  </Grid>
                </Box>
                {showTax ? (
                  <>
                    <FormLabel>
                      <Text>Price With Tax</Text>
                    </FormLabel>
                    <Grid
                      autoRows="auto"
                      templateColumns="repeat(3, 1fr)"
                      columnGap={2}
                      rowGap={2}
                    >
                      <GridItem colSpan={1}>
                        <FormControl id="priceWithTax">
                          <TextField
                            label="Price"
                            variant="outlined"
                            size="small"
                            type="number"
                            onChange={(event) =>
                              setPriceWithTaxValue(event.target.value)
                            }
                            value={item.price_with_tax || ""}
                            onFocus={(e) => e.target.select()}
                            InputProps={{ style: { fontSize: "12px" } }}
                            InputLabelProps={{
                              style: { fontSize: "12px" },
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={1}>
                        <FormControl>
                          <TextField
                            label="Discount"
                            variant="outlined"
                            size="small"
                            type="number"
                            onChange={(event) =>
                              setDiscountWithTaxValue(event.target.value)
                            }
                            value={item.sale_discount_with_tax || ""}
                            onFocus={(e) => e.target.select()}
                            InputProps={{ style: { fontSize: "12px" } }}
                            InputLabelProps={{
                              style: { fontSize: "12px" },
                            }}
                          ></TextField>
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={1}>
                        <FormControl>
                          <TextField
                            disabled
                            label="Net Amount"
                            variant="outlined"
                            size="small"
                            type="text"
                            value={_parseFloat(withGstNetAmount).toFixed(
                              decimalLength
                            )}
                          ></TextField>
                        </FormControl>
                      </GridItem>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Box>
            </ModalBody>
            <ModalFooter>
              <Box p={2} w="100%">
                <Button
                  type="submit"
                  w="100%"
                  minH="40px"
                  size="lg"
                  variant="solid"
                  bg="brand.bg_blue"
                  colorScheme="brand.bg_blue"
                  disabled={disable}
                  onClick={handleSubmit}
                >
                  Add
                  {/* {type === "edit" ? "Update" : "Add"} */}
                </Button>
              </Box>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default PriceCategoryModal;

const _parseFloat = (num) => {
  return parseFloat(num || 0);
};
