import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import { useToast } from "@chakra-ui/react";
import Form from "../Form";
import RenderComponent from "./RenderComponent";
import { useCreateStore } from "hooks/Store/useCreateStore";
import { useUpdateStore } from "hooks/Store/useUpdateStore";
import { useCostCenters } from "hooks/CostCenter/useCostCenters";
import { useDoneBy } from "hooks/DoneBy/useDoneBy";
import { getLocalStore } from "utils/utils";

const AddStore = (props) => {
  const {
    type,
    editValue,
    onAddSuccess,
    approveFunc,
    rejectFunc,
    isApproval = false,
    isRejectLoading = false,
    isApproveLoading = false,
  } = props;
  const toast = useToast();
  const { data: costCenterData } = useCostCenters();
  const { data: doneByData } = useDoneBy();

  const [suspand, setSuspand] = useState(false);

  const {
    mutate: createStore,
    data,
    isSuccess,
    isLoading: isCreateLoading,
  } = useCreateStore();
  const {
    mutate: updateStore,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useUpdateStore();

  const initialValue = {
    name: "",
    is_suspended: suspand,
  };
  const [formValues, setformValues] = useState(initialValue);
  const [costCenter, setCostCenter] = useState([]);
  const [doneBy, setDoneBy] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.status === "success") {
        toastWithMessage("Cost Center created successfully");
        onAddSuccess(data?.data);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Store updated successfully");
        onAddSuccess(updateResponse?.data);
      }
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (type === "add") {
      setformValues(initialValue);
      if (costCenterData && doneByData) {
        setCostCenter(
          costCenterData.data?.filter((i) =>
            [
              getLocalStore("DEFAULT_SETTINGS_DATA")?.default_cost_centre,
            ]?.includes(i.id)
          ) || []
        );
        setDoneBy(
          doneByData.data?.filter((i) =>
            [getLocalStore("DEFAULT_SETTINGS_DATA")?.default_done_by]?.includes(
              i.id
            )
          ) || []
        );
      }
    } else {
      setformValues(editValue);
      setSuspand(editValue.is_suspended);
      if (costCenterData && doneByData) {
        setCostCenter(
          costCenterData.data?.filter((i) =>
            editValue.cost_centre?.includes(i.id)
          ) || []
        );
        setDoneBy(
          doneByData.data?.filter((i) => editValue.done_by?.includes(i.id)) ||
            []
        );
      }
    }
  }, [type, editValue, doneByData, costCenterData]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Form
      initialValues={{ ...formValues }}
      RenderComponent={RenderComponent}
      handleOnSubmit={(fields) => {
        fields.is_suspended = suspand;
        fields.cost_centre = costCenter?.map((i) => i.id) || [];
        fields.done_by = doneBy?.map((i) => i.id) || [];

        if (type === "edit") {
          updateStore(fields);
        } else {
          createStore(fields);
        }
      }}
      customProps={{
        type: type,
        isLoading: isCreateLoading || isUpdateLoading,
        isSubmitted: isSuccess || isUpdateSuccess,
        setSuspand,
        suspand,
        costCenter,
        setCostCenter,
        costCenterData,
        doneBy,
        setDoneBy,
        doneByData,
        isApproval,
        approveFunc,
        rejectFunc,
        isRejectLoading,
        isApproveLoading,
      }}
      validationSchema={object().shape({
        name: string().required("Name Required"),
      })}
    />
  );
};

export default AddStore;
