import TextField from "@material-ui/core/TextField";
import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";

const ITextField = (props) => {
  const {
    onKeyDown,
    onFocus,
    onChange,
    inputRef,
    value,
    name,
    label,
    disabled,
    id,
    isValid = true,
    errorMessage,
    type,
    isRequired = false,
    fullWidth = true,
    buttonClick = false,
  } = props;
  const toast = useToast();

  useEffect(() => {
    if (!isValid && errorMessage) {
      toast({
        title: "",
        description: `${errorMessage}`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [isValid, buttonClick]);

  return (
    <TextField
      InputLabelProps={{
        style: { color: isRequired && value === "" ? "red" : null }, // Set the placeholder color to green
      }}
      error={isRequired && !isValid && value === ""}
      size="small"
      fullWidth={fullWidth}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onChange={onChange}
      inputRef={inputRef}
      variant="outlined"
      value={value}
      name={name}
      label={label}
      disabled={disabled}
      id={id}
      type={type}
    />
  );
};
export default ITextField;
