/* eslint-disable react-hooks/exhaustive-deps */
import { Heading, Flex, Box } from "@chakra-ui/react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

const IHeadFav = ({ text, onClick, isFavour }) => {
  const iconStyle = {
    fontSize: "20px",
    cursor: "pointer",
  };
  // const iconStyle2 = {
  //   fontSize: "20px",
  //   cursor: "pointer",
  //   strokeWidth: 2,
  //   stroke: "black",
  //   fill: "#FFD700",
  // };
  return (
    <Flex
      alignItems="center"
      display="flex"
      justifyContent="start"
      gap="2"
      mt={2}
      mb={5}
    >
      <Heading className="poppins-medium">{text}</Heading>
      {isFavour ? (
        <Box position="relative">
          <AiFillStar
            onClick={onClick}
            style={{ ...iconStyle, color: "#FFD700" }}
          />
        </Box>
      ) : (
        <AiOutlineStar onClick={onClick} style={iconStyle} />
      )}
      {/* <CloseButton /> */}
    </Flex>
  );
};
export default IHeadFav;
