/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  HStack,
  Spacer,
  Switch,
  Box,
  IconButton,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useDisclosure,
} from "@chakra-ui/react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { FieldCard } from "components/FieldDragnDrop/FieldCard";
import { Settings } from "@material-ui/icons";
import { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { Transactions } from "utils/utils/constant";
import { useCreateTransactionSettings } from "hooks/TransactionSettings/useCreateTransactionSettings";
import { useTransactionSettings } from "hooks/TransactionSettings/useTransactionSettings";
import { useUpdateTransactionSettings } from "hooks/TransactionSettings/useUpdateTransactionSettings";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { roundOffOptions } from "utils/utils/optionsLists";
import { useTransactionsSettings } from "hooks/useTransactionsSettings";

const FieldCustomizingPopover = (props) => {
  const {
    customFields,
    setCustomFields,
    transactionName,
    requiredFields,
    setRequiredFields,
    transactionSettingsChoice,
    setRoundOff,
    setHideService,
    setIsRequiredToSaveDebitAndCreditNotTally,
    setIsDescriptionIsRequiredInAdvance,
    setCreditAlways,
  } = props;
  const toast = useToast();
  const cutsomSettings = useDisclosure();
  const { getRequiredFields, getCustomFields } = useTransactionsSettings(
    transactionSettingsChoice
  );

  const { data: transactionSettingsData } = useTransactionSettings();
  const {
    mutate: createTransactionSettings,
    data: createResponse,
    isSuccess: isCreateSuccess,
    isLoading: isCreateLoading,
  } = useCreateTransactionSettings();
  const {
    mutate: updateTransactionSettings,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useUpdateTransactionSettings();
  const [roundOffObject, setRoundOffObject] = useState(null);
  const [mDFARIM, setMDFARIM] = useState(false);
  const [sRSICDNT, setSRSICDNT] = useState(false);
  const [hideServiceLocal, setHideServiceLocal] = useState(false);
  const [creditAlwaysLocal, setCreditAlwaysLocal] = useState(false);
  const isTransactionHaveRoundOff = [
    "SALE",
    "SALE_RETURN",
    "PURCHASE",
    "PURCHASE_RETURN",
    "DELIVERY_ORDER",
    "EXPENSE",
    "SALE_ESTIMATE",
  ].includes(transactionSettingsChoice);
  const isTransactionHaveHideService = [
    "SALE",
    "SALE_RETURN",
    "PURCHASE",
    "PURCHASE_RETURN",
    "SALE_ESTIMATE",
  ].includes(transactionSettingsChoice);

  const isTransactionHaveCreditAndDebitIsNotTallyProperty = [
    "STOCK_JOURNAL",
    "STORE_TRANSFER",
    "STANDARD_PRODUCT",
  ].includes(transactionSettingsChoice);

  const isDescriptionIsRequiredForAdvanceInReceiptProperty = [
    "RECEIPT_AGAINST_INVOICE",
    "PURCHASE_PAYMENT",
    "EXPENSE_PAYMENT",
  ].includes(transactionSettingsChoice);

  const isTransactionHaveCreditAlwaysProperty = [
    "SALE",
    "SALE_RETURN",
    "PURCHASE",
    "PURCHASE_RETURN",
    "EXPENSE",
  ].includes(transactionSettingsChoice);
  useEffect(() => {
    if (isCreateSuccess || isUpdateSuccess) {
      if (
        createResponse?.status === "success" ||
        updateResponse?.status === "success"
      ) {
        toast({
          title: "",
          description: "Settings updated successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
        });

        cutsomSettings.onClose();
      }
    }
  }, [isCreateSuccess, isUpdateSuccess]);
  useEffect(() => {
    if (transactionSettingsData) {
      const apiData = transactionSettingsData.data?.find(
        (item) => item.transaction_type === transactionSettingsChoice
      );

      if (apiData) {
        if (apiData.round_off !== null && isTransactionHaveRoundOff) {
          roundOffOptions.forEach((element) => {
            if (element.key === apiData.round_off) {
              setRoundOffObject(element);
              setRoundOff(element.value);
            }
          });
        }
        if (isTransactionHaveHideService) {
          setHideService(apiData.hide_service);
          setHideServiceLocal(apiData.hide_service);
        }
        if (isTransactionHaveCreditAlwaysProperty) {
          setCreditAlways(apiData.always_active_credit);
          setCreditAlwaysLocal(apiData.always_active_credit);
        }
        if (isTransactionHaveCreditAndDebitIsNotTallyProperty) {
          setSRSICDNT(apiData.stock_journal_save_only_credit_debit_tally);
          setIsRequiredToSaveDebitAndCreditNotTally(
            apiData.stock_journal_save_only_credit_debit_tally
          );
        }
        if (isDescriptionIsRequiredForAdvanceInReceiptProperty) {
          setIsDescriptionIsRequiredInAdvance(
            apiData.make_description_for_advance_receipt_is_mandatory
          );
          setMDFARIM(apiData.make_description_for_advance_receipt_is_mandatory);
        }
        let requiredFieldsData = getRequiredFields();
        if (requiredFieldsData) {
          const updatedRequiredFields = requiredFieldsData.map(
            (defaultValue) => {
              const { name_key } = defaultValue;

              defaultValue.required = apiData[name_key];
              return defaultValue;
            }
          );
          setRequiredFields(updatedRequiredFields);
        }
        let cusotomFieldsData = getCustomFields();

        if (cusotomFieldsData) {
          const updatedCustomFields = cusotomFieldsData
            .map((defaultValue) => {
              const apiSetting = apiData.input_settings.find(
                (setting) => setting.input_field === defaultValue.name_key
              );

              return {
                id: apiSetting?.id ?? defaultValue.id, // Use id from apiData if available, otherwise null
                name: defaultValue.name,
                isInBaseTab: apiSetting
                  ? apiSetting.tab === "BASE"
                  : defaultValue.isInBaseTab,
                order: apiSetting ? apiSetting.order_index : defaultValue.order,
                isHide: apiSetting
                  ? !apiSetting.is_enabled
                  : defaultValue.isHide,
                name_key: defaultValue.name_key,
              };
            })
            .sort((a, b) => {
              const aInApiData = apiData.input_settings.some(
                (setting) => setting.input_field === a.name_key
              );
              const bInApiData = apiData.input_settings.some(
                (setting) => setting.input_field === b.name_key
              );

              return bInApiData - aInApiData;
            });

          const sortedData = updatedCustomFields.sort((a, b) => {
            if (a.order === null) return 1;
            if (b.order === null) return -1;
            return a.order - b.order;
          });
          setCustomFields([...updatedCustomFields]);
        }
      } else {
        //for transaction who dont have have settings data in server........
        let requiredFieldsData = getRequiredFields();
        let cusotomFieldsData = getCustomFields();
        setCustomFields(cusotomFieldsData);
        setRequiredFields(requiredFieldsData);
      }
    }
  }, [transactionSettingsChoice, transactionSettingsData]);

  const reorderArrayByIndex = (fields) => {
    let baseTabOrder = 1;
    let moreTabOrder = 13;

    if (
      [
        Transactions.Receipt,
        Transactions.PurchaseReturnReceipt,
        Transactions.OtherReceipt,
        Transactions.PurchasePayment,
        Transactions.ExpensePayment,
        Transactions.SaleReturnPayment,
        Transactions.OtherPayment,
      ].includes(transactionName)
    ) {
      baseTabOrder = 2;
    } else if (transactionName === Transactions.StockJournal) {
      baseTabOrder = 3;
      moreTabOrder = 12;
    }

    const orderedFields = fields.reduce((acc, obj) => {
      if (obj.isInBaseTab && !obj.isHide) {
        acc.push({ ...obj, order: baseTabOrder++ });
      } else if (!obj.isInBaseTab && !obj.isHide) {
        acc.push({ ...obj, order: moreTabOrder++ });
      } else {
        acc.push({ ...obj, order: null });
      }
      return acc;
    }, []);

    return orderedFields;
  };

  const setOrderNumberOfFields = (fields) => {
    let baseTabOrder = 1;

    if (
      [
        Transactions.Receipt,
        Transactions.PurchaseReturnReceipt,
        Transactions.OtherReceipt,
        Transactions.PurchasePayment,
        Transactions.ExpensePayment,
        Transactions.SaleReturnPayment,
        Transactions.OtherPayment,
      ].includes(transactionName)
    ) {
      baseTabOrder = 2;
    } else if (transactionName === Transactions.StockJournal) {
      baseTabOrder = 3;
    }

    let moreTabOrder = 13;
    if (transactionName === Transactions.StockJournal) {
      moreTabOrder = 12;
    }

    const orderedFields = fields.map((field) => {
      if (field.isInBaseTab && !field.isHide) {
        return { ...field, order: baseTabOrder++ };
      } else if (!field.isInBaseTab && !field.isHide) {
        return { ...field, order: moreTabOrder++ };
      } else {
        return { ...field, order: null };
      }
    });
    return orderedFields;
  };

  const onFieldPostionChange = useCallback((dragIndex, hoverIndex) => {
    setCustomFields((prevCards) => {
      const updatedFields = update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      });

      const orderedFields = reorderArrayByIndex(updatedFields);
      return orderedFields;
    });
  }, []);

  const onFieldTransfer = (id, check) => {
    setCustomFields((prevFields) => {
      const index = prevFields.findIndex((field) => field.id === id);
      if (index === -1) return prevFields;

      const updatedFields = prevFields.map((field, idx) =>
        idx === index ? { ...field, isInBaseTab: check } : field
      );

      const orderedFields = updateCustomFields(updatedFields);

      return orderedFields;
    });
  };

  const onShowHideButtonPress = (id, isHide) => {
    setCustomFields((prevCustomFields) => {
      const updatedFields = prevCustomFields.map((field) =>
        field.id === id ? { ...field, isHide } : field
      );

      return updateCustomFields(updatedFields);
    });
  };

  const updateCustomFields = (fields) => {
    let noOfCustomFieldInBaseTab = fields.filter(
      (field) => field.isInBaseTab === true && field.isHide === false
    ).length;

    if (noOfCustomFieldInBaseTab <= 6) {
      const orderedFields = setOrderNumberOfFields(fields);
      return orderedFields;
    } else {
      showToast();
      return fields;
    }
  };

  const showToast = () => {
    toast({
      title: `Number of Fields exceed in Base info Tab`,
      status: "info",
      isClosable: true,
    });
  };
  const onChangeRequiredFields = (fieldName, required) => {
    const updatedArray = requiredFields.map((field) =>
      field.name === fieldName ? { ...field, required } : field
    );
    setRequiredFields(updatedArray);
  };
  const renderCard = useCallback((card, index) => {
    return (
      <FieldCard
        key={card.id}
        index={index}
        id={card.id}
        text={card.name}
        isInBaseTab={card.isInBaseTab}
        isHide={card.isHide}
        moveCard={onFieldPostionChange}
        onFieldTransfer={onFieldTransfer}
        onShowHideButtonPress={onShowHideButtonPress}
      />
    );
  }, []);

  const onSave = () => {
    let settingsID = null;
    if (transactionSettingsData) {
      settingsID = transactionSettingsData.data?.find(
        (f) => f.transaction_type === transactionSettingsChoice
      )?.id;
    }
    let inputSettings;
    if (customFields) {
      inputSettings = customFields.map((field) => {
        return {
          id: null,
          input_field: field.name_key,
          is_enabled: field.isHide ? false : true,
          tab: field.isInBaseTab ? "BASE" : "OTHER",
          order_index: field.order,
        };
      });
    }
    let requiredFieldsSettings;
    if (requiredFields) {
      requiredFieldsSettings = requiredFields.reduce((acc, field) => {
        acc[field.name_key] = field.required;
        return acc;
      }, {});
    }

    const data = {
      ...requiredFieldsSettings,
      transaction_type: transactionSettingsChoice,
      input_settings: inputSettings,
      hide_service: hideServiceLocal,
      make_description_for_advance_receipt_is_mandatory: mDFARIM,
      stock_journal_save_only_credit_debit_tally: sRSICDNT,
      round_off: roundOffObject ? roundOffObject.key : null,
      always_active_credit: creditAlwaysLocal,
    };

    if (settingsID) {
      data.id = settingsID;
      updateTransactionSettings(data);
    } else {
      createTransactionSettings(data);
    }
  };

  return (
    <Popover
      isOpen={cutsomSettings.isOpen}
      onOpen={cutsomSettings.onOpen}
      onClose={cutsomSettings.onClose}
    >
      <PopoverTrigger>
        <IconButton size="sm" isRound={true} icon={<Settings />} />
      </PopoverTrigger>
      <PopoverContent w="345px">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader p={3}>
          <Text fontWeight="bold" fontSize="18px">
            Custom Settings
          </Text>
        </PopoverHeader>
        <PopoverBody pt={0} pl={0} pr={0} pb={0}>
          <Accordion allowToggle>
            {requiredFields && (
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Text py={1} fontWeight="semibold" fontSize="l">
                        Set Required Fields
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {requiredFields &&
                    requiredFields.map((field, i) => (
                      <HStack
                        borderBottom={i != 0 ? "1px" : null}
                        borderY={i == 0 ? "1px" : null}
                        border
                        borderColor="gray.200"
                        py={3}
                        px={2}
                      >
                        <Text size="sm">{field.name + " Is Compulsory"}</Text>
                        <Spacer />
                        <Switch
                          isChecked={field.required}
                          onChange={(e) => {
                            onChangeRequiredFields(
                              field.name,
                              e.target.checked
                            );
                          }}
                        />
                      </HStack>
                    ))}
                  {isDescriptionIsRequiredForAdvanceInReceiptProperty && (
                    <HStack
                      borderBottom={"1px"}
                      border
                      borderColor="gray.200"
                      py={2}
                      px={2}
                      justifyContent="space-between"
                    >
                      <Text size="sm" fontSize="l">
                        Make Description For Advance Receipt Is Mandatory
                      </Text>
                      <Switch
                        // colorScheme="teal"
                        disabled={false}
                        isChecked={mDFARIM}
                        onChange={(e) => {
                          setMDFARIM(e.target.checked);
                        }}
                      />
                    </HStack>
                  )}
                </AccordionPanel>
              </AccordionItem>
            )}
            {customFields && (
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      <Text py={1} fontWeight="semibold" fontSize="l">
                        Rearrange Fields
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel p={0}>
                  <HStack width="100%" pr={3}>
                    <Box flex="1" display="flex" justifyContent="end">
                      <Box pr={5}>
                        <Text fontSize="12px" fontWeight="semibold">
                          Base info Tab
                        </Text>
                      </Box>
                      <Box pr={0}>
                        <Text fontSize="12px" fontWeight="semibold">
                          More info Tab
                        </Text>
                      </Box>
                    </Box>
                  </HStack>
                  <DndProvider backend={HTML5Backend}>
                    <div width="100%">
                      {customFields &&
                        customFields.map((card, i) => renderCard(card, i))}
                    </div>
                  </DndProvider>
                </AccordionPanel>
              </AccordionItem>
            )}
          </Accordion>

          {isTransactionHaveRoundOff && (
            <HStack
              borderBottom={"1px"}
              border
              borderColor="gray.200"
              px={4}
              py={1}
              justifyContent="space-between"
            >
              <Text fontWeight="semibold" fontSize="l">
                Round off
              </Text>
              <Box w="170px">
                <Autocomplete
                  fullWidth
                  size="small"
                  id="select-roundoff-data"
                  options={roundOffOptions}
                  value={roundOffObject}
                  getOptionLabel={(option) => option.label}
                  renderOption={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Round off"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setRoundOffObject(newValue);
                  }}
                />
              </Box>
            </HStack>
          )}
          {isTransactionHaveCreditAlwaysProperty && (
            <HStack
              borderBottom={"1px"}
              border
              borderColor="gray.200"
              py={3}
              px={4}
              justifyContent="space-between"
            >
              <Text fontWeight="semibold" fontSize="l">
                Credit Always
              </Text>
              <Switch
                disabled={false}
                isChecked={creditAlwaysLocal}
                onChange={(e) => {
                  setCreditAlwaysLocal(e.target.checked);
                }}
              />
            </HStack>
          )}
          {isTransactionHaveHideService && (
            <HStack
              borderBottom={"1px"}
              border
              borderColor="gray.200"
              py={3}
              px={4}
              justifyContent="space-between"
            >
              <Text fontWeight="semibold" fontSize="l">
                Hide Service
              </Text>
              <Switch
                disabled={false}
                isChecked={hideServiceLocal}
                onChange={(e) => {
                  setHideServiceLocal(e.target.checked);
                }}
              />
            </HStack>
          )}
          {isTransactionHaveCreditAndDebitIsNotTallyProperty && (
            <HStack
              borderBottom={"1px"}
              border
              borderColor="gray.200"
              py={1}
              px={4}
              justifyContent="space-between"
            >
              <Text fontWeight="semibold" fontSize="l">
                Stock Journal Required To Save If Total Debit And Credit Is Not
                Tally
              </Text>
              <Switch
                disabled={false}
                isChecked={sRSICDNT}
                onChange={(e) => {
                  setSRSICDNT(e.target.checked);
                }}
              />
            </HStack>
          )}
        </PopoverBody>
        <PopoverFooter display="flex" justifyContent="flex-end">
          <Button
            mr={4}
            w="80px"
            variant="outline"
            onClick={cutsomSettings.onClose}
          >
            Cancel
          </Button>
          <Button
            w="80px"
            colorScheme="blue"
            onClick={onSave}
            isLoading={isCreateLoading || isUpdateLoading}
          >
            Save
          </Button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
export default FieldCustomizingPopover;
