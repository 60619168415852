import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { Box, Flex, Tab, TabList } from "@chakra-ui/react";

import { getLocalStore } from "./utils/utils";
import HeaderTab from "components/HeaderTab";
import Header from "./components/Header";
import LeftNavigation from "./components/LeftNavigation";
import CollapsableLeftNavigation from "./components/CollapsableLeftNavigation";
import { BrowserView, isMobile } from "react-device-detect";

const Private2Route = ({ component: Component, ...rest }) => {
  const auth = getLocalStore("AUTH_DETAILS");
  const bgColor = !isMobile ? "brand.bg_light_blue" : "brand.bg_mobile_white";
  const [collapsed, setCollapsed] = useState(false);

  const toggleSideNav = () => {
    setCollapsed(!collapsed);
  };

  var tokenData = undefined;
  const handleTabBlur = () => {
    tokenData = auth.data?.token;
  };

  const handleTabFocus = () => {
    if (tokenData && tokenData !== getLocalStore("AUTH_DETAILS")?.data?.token) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener("blur", handleTabBlur);
    window.addEventListener("focus", handleTabFocus);
    handleTabFocus();
    return () => {
      window.removeEventListener("blur", handleTabBlur);
      window.removeEventListener("focus", handleTabFocus);
    };
  }, []);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to / page
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          <React.Fragment>
            <Flex bg={bgColor} minHeight="100vh">
              {!isMobile ? (
                <CollapsableLeftNavigation
                  collapsed={collapsed}
                  onToggleSidebar={toggleSideNav}
                  {...auth}
                />
              ) : null}
              <Box flex="1">
                <Flex flexDirection="column" h="100vh" overflow="auto">
                  <Flex
                    h={isMobile ? "8vh" : "11vh"}
                    bg="white"
                    borderBottomColor="#e2e8f0"
                    borderBottomWidth="1px"
                  >
                    {!isMobile ? (
                      <HeaderTab onToggleSidebar={toggleSideNav}></HeaderTab>
                    ) : null}
                  </Flex>
                  <Flex h="89vh" overflow="auto">
                    <Component {...props} />
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </React.Fragment>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default Private2Route;
