import { Flex, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import noInternet from "assets/no-wifi.png";

function NoInternet() {
  return (
    <Flex bg="brand.bg_light_blue" height="100vh" justifyContent="center">
      <VStack width="100%" pt={10}>
        <Image
          shadow="md"
          padding="12px"
          bg="white"
          borderRadius="100"
          w="200px"
          h="200px"
          src={noInternet}
        ></Image>
        <Text fontSize={60} fontWeight="extrabold">
          WHOOPS !
        </Text>
        <Text fontSize={20} textTransform="uppercase" fontWeight="bold">
          Slow or no internet connection
        </Text>
        <Text>Check your internet settings and try again</Text>
      </VStack>
    </Flex>
  );
}

export default NoInternet;
