import { useMutation, useQueryClient } from "react-query";
import api, { API_VOUCHER_PRINT_SETTINGS } from "utils/axios";

const updateVoucherPrintSettings = (data) => {
  return api
    .patch(`${API_VOUCHER_PRINT_SETTINGS}${data.id}`, data)
    .then((res) => res.data);
};

export function useUpdateVoucherPrintSettings() {
  const queryClient = useQueryClient();
  return useMutation(updateVoucherPrintSettings, {
    onSuccess: (response) => {
      queryClient.setQueriesData(
        ["voucherPrintSettings", response.data.id.toString()],
        response
      );
      queryClient.refetchQueries("voucherPrintSettings");
    },
  });
}
