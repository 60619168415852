/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { array, number, object, string } from "yup";
import { useToast } from "@chakra-ui/react";

import Form from "../../components/Form";
import RenderComponent from "./RenderComponent";

import { useCreateCustomer } from "hooks/Customer/useCreateCustomer";
import { useUpdateCustomer } from "hooks/Customer/useUpdateCustomer";
import { getLocalStore } from "utils/utils";

const AddCustomer = (props) => {
  const {
    type,
    editValue,
    onAddSuccess,
    isApproval = false,
    rejectFunc,
    isRejectLoading = false,
  } = props;
  const auth = getLocalStore("AUTH_DETAILS") || "";
  const [selectCountry, setSelectCountry] = useState("");
  const [enableCreditLimit1, setEnableCreditLimit1] = useState(false);
  const [isToggledFav, setIsToggledFav] = useState(false);
  const [suspand, setSuspand] = useState(false);

  const toast = useToast();
  const {
    mutate: createCustomer,
    data,
    isSuccess,
    isLoading: isCreateLoading,
  } = useCreateCustomer();
  const {
    mutate: updateCustomer,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useUpdateCustomer();

  const initialValue = {
    name: "",
    address: "",
    opening_balance: 0,
    mobile: "",
    ledger_group_id: getLocalStore("ledgerGroupIdCustomer") || "",
    email: "",
    trn: "",
    country: JSON.parse(localStorage.getItem("region")).country,
    emirate: JSON.parse(localStorage.getItem("region")).state,
    tax_registration_type: "",
    payment_term: 0,
    maximum_credit_limit: 0,
    bank_details: "",
    remarks: "",
    distance: "0",
    pan_number: "",
    website: "",
    contact_person: [],
    shipping_address: [],
    primary_invoices: [],
    primary_advances: [],
    enable_credit_limit: enableCreditLimit1,
    is_suspended: suspand,
    favourites: isToggledFav,
    cost_centres: getLocalStore("DEFAULT_SETTINGS_DATA")?.default_cost_centre
      ? [getLocalStore("DEFAULT_SETTINGS_DATA")?.default_cost_centre]
      : [],
    done_bys: getLocalStore("DEFAULT_SETTINGS_DATA")?.default_done_by
      ? [getLocalStore("DEFAULT_SETTINGS_DATA")?.default_done_by]
      : [],
  };

  const [formValues, setformValues] = useState(initialValue);
  const handleFavourite = () => {
    setIsToggledFav(!isToggledFav);
  };

  useEffect(() => {
    if (isSuccess) {
      if (data?.status === "success") {
        toastWithMessage("Customer created successfully");
        onAddSuccess(data?.data);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Customer updated successfully");
        onAddSuccess(updateResponse?.data);
      }
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (type === "add") {
      setformValues(initialValue);
    } else {
      setformValues(editValue);
      setIsToggledFav(editValue.favourites);
      setSuspand(editValue.is_suspended);
    }
  }, [type, editValue]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Form
      initialValues={{ ...formValues }}
      RenderComponent={RenderComponent}
      handleOnSubmit={(fields) => {
        if (fields.opening_balance === null) {
          fields.opening_balance = 0;
        }

        // if (fields.country !== "UAE") {
        //   fields.emirate = null;
        // }

        fields.enable_credit_limit = enableCreditLimit1;
        fields.is_suspended = suspand;
        fields.favourites = isToggledFav;

        if (type === "edit") {
          if (isApproval) {
            fields.approved_by = auth?.data?.id;
          } else {
            delete fields.approved_by;
          }
          updateCustomer(fields);
        } else {
          fields.party_type = "CUSTOMER";
          createCustomer(fields);
        }
        if (fields.trn === 0 || fields.trn === "") {
          fields.tax_registration_type = "UNREGISTERED_BUSINESS";
        }
        if (
          (fields.trn !== 0 || fields.trn !== "") &&
          fields.tax_registration_type === ""
        ) {
          fields.tax_registration_type = "REGISTERED_BUSINESS";
        }
      }}
      customProps={{
        type: type,
        isLoading: isCreateLoading || isUpdateLoading,
        isSubmitted: isSuccess || isUpdateSuccess,
        setSelectCountry: (country) => {
          setSelectCountry(country);
        },
        setEnableCreditLimit1: (limit) => {
          setEnableCreditLimit1(limit);
        },
        enableCreditLimit1,
        handleFavourite,
        setSuspand,
        isToggledFav,
        suspand,
        isApproval,
        rejectFunc,
        isRejectLoading,
      }}
      validationSchema={object().shape({
        name: string().required("Customer Name Required"),
        address: string().nullable(),
        mobile: number()
          .optional()
          .nullable()
          .typeError("Enter a valid Mobile number"),
        ledger_group_id: string().required("Select ledger"),
        email: string().optional().nullable().email("Enter a valid mail"),
        trn: string()
          .optional()
          .nullable()
          .test(
            "len",
            "Length cannot exceed 15",
            (val) => val == null || (val && val.toString().length < 16)
          )
          .typeError("Length cannot exceed 15"),
        country: string().required("Country required"),
        emirate: string()
          .nullable()
          .required(
            selectCountry?.includes("UAE")
              ? "Emirate Required"
              : selectCountry?.includes("INDIA")
              ? "State Required"
              : null
          ),
        tax_registration_type: string().optional().nullable(),
        payment_term: number()
          .optional()
          .nullable()
          .typeError("Enter a valid number"),
        maximum_credit_limit: number()
          .optional()
          .nullable()
          .typeError("Enter a valid number"),
        bank_details: string().optional().nullable(),
        remarks: string().optional().nullable(),
        distance: number()
          .optional()
          .nullable()
          .typeError("Enter a valid number"),
        pan_number: string().optional().nullable(),
        website: string().optional().nullable(),
        contact_person: array().nullable(),
        shipping_address: array().nullable(),
        primary_invoices: array().nullable(),
        primary_advances: array().nullable(),
        opening_balance: number().nullable(),
      })}
    />
  );
};
export default AddCustomer;
