import { useMutation, useQueryClient } from "react-query";
import api, { API_NOTIFICATIONS } from "utils/axios";

const deleteNotification = async (item) => {
  if (item.id) {
    return api
      .delete(`${API_NOTIFICATIONS}/${item.id}`)
      .then((res) => res.data);
  }
};

export function useDeleteNotification() {
  const queryClient = useQueryClient();
  return useMutation(deleteNotification, {
    onSuccess: (response) => {
      if (response.status === "success") {
        queryClient.refetchQueries("notifications");
      }
    },
  });
}
