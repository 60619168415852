import { useMutation, useQueryClient } from "react-query";
import api, { API_PRODUCTION_LIST } from "utils/axios";

const updateProduction = (production) =>
  api
    .patch(`${API_PRODUCTION_LIST}${production.id}`, production)
    .then((res) => res.data);

export function useUpdateProduction() {
  const queryClient = useQueryClient();
  return useMutation(updateProduction, {
    onSuccess: () => queryClient.refetchQueries("production"),
  });
}
