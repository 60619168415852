import { createSlice } from "@reduxjs/toolkit";

// import { setLocalStore, setResponse } from '../utils/utils'
import api, {
  API_SALES_REPORT_LIST,
  API_SALES_REPORT_FOR_CUSTOMER_LIST,
  API_SALE_ESTIMATE_REPORT_LIST,
  API_SALES_REPORT_DELETE,
  API_SALES_REPORT_LIST_BY_PARTY,
  API_SALES_REPORT_EXPORT,
  API_SALE_ESTIMATE_REPORT_EXPORT,
  API_SALES_REPORTS_EXPORT,
  API_SALES_RETURN_REPORT_LIST,
  SALE_RETURN_WITH_ITEM,
  API_SALES_RETURN_REPORT_EXPORT,
  API_SALES_RETURN_REPORTS_EXPORT,
  API_SALES_RETURN_REPORT_LIST_BY_PARTY,
  API_SALES_RETURN_REPORT_LIST_BY_IDS,
  API_SALES_REPORT_FOR_CUSTOMER_MINIMAL_LIST,
  API_SALES_RETURN_REPORT_LIST_MINIMAL,
  API_SALES_RETURN_REPORT_LIST_BY_PARTY_MINIMAL,
  API_SALES_RETURN_REPORT_LIST_BY_IDS_MINIMAL,
} from "../utils/axios";
import { hideLoading, showLoading } from "./common.slice";

const initialState = {
  loading: false,
  next: "",
  previous: "",
  count: 0,
  page: 0,
  total_pages: 0,
  totalGross: 0,
  totalBalance: 0,
  totalNet: 0,
  totalTax: 0,
  reports: [],
  reportDetails: {},
};

const salesreturnreport = createSlice({
  name: "salesreturnreport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReports: (state, action) => {
      state.reports = action.payload.reports;
      state.count = action.payload.count;
      state.total_pages = action.payload.total_pages;
      state.totalGross = action.payload.total_gross;
      state.totalBalance = action.payload.total_balance;
      state.totalNet = action.payload.total_net;
      state.totalTax = action.payload.total_tax;
    },
    setReportDetails: (state, action) => {
      state.reportDetails = action.payload.reportDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setReports, setReportDetails, setErrorMessage } =
  salesreturnreport.actions;

export default salesreturnreport.reducer;

export const fetchSalesReturnReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      // console.log("data----------", data);
      let url = API_SALES_RETURN_REPORT_LIST_MINIMAL.replace(
        "{page}",
        data.page
      )
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status);

      if (data.costCenter?.length > 0) {
        if (data.costCenter === "notAvailableId") {
          url = url + `&not_available_field=cost_centre`;
        } else {
          url = url + `&cost_centre__in=${data.costCenter}`;
        }
      }
      if (data.doneBy?.length > 0) {
        if (data.doneBy === "notAvailableId") {
          url = url + `&not_available_field=done_by`;
        } else {
          url = url + `&done_by__in=${data.doneBy}`;
        }
      }
      if (data.taxType?.length > 0) {
        if (data.taxType === "notAvailableId") {
          url = url + `&not_available_field=tax_type`;
        } else {
          url = url + `&tax_type__in=${data.taxType}`;
        }
      }
      if (data.modeOfPayment && data.modeOfPayment !== "") {
        if (data.modeOfPayment === "notAvailableId") {
          url = url + `&not_available_field=mode_or_terms_of_payment`;
        } else {
          url = url + `&mode_or_terms_of_payment=${data.modeOfPayment}`;
        }
      }
      if (data.customer?.length > 0) {
        url = url + `&party__in=${data.customer}`;
      }
      if (data.voucherType?.length > 0) {
        url += `&voucher_group__in=${data.voucherType}`;
      }
      if (data.store && data.store !== "") {
        url = url + `&store=${data.store}`;
      }
      if (data.grossAmount && data.grossAmount !== "") {
        url = url + `&sub_total=${data.grossAmount}`;
      }
      if (data.taxAmount && data.taxAmount !== "") {
        url = url + `&vat=${data.taxAmount}`;
      }
      if (data.totalAmount && data.totalAmount !== "") {
        url = url + `&total=${data.totalAmount}`;
      }

      if (data.sort) {
        url = url + `&sorting=${data.sort}`;
      }
      if (data.tax && data.tax !== "") {
        url += `&tax=${data.tax}`;
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_balance: response.data.data.total_balance,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const fetchSalesReturnLists =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      // console.log("data----------", data);
      let url = API_SALES_RETURN_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status);

      if (data.costCenter && data.costCenter !== "") {
        if (data.costCenter === "notAvailableId") {
          url = url + `&not_available_field=cost_centre`;
        } else {
          url = url + `&cost_centre=${data.costCenter}`;
        }
      }
      if (data.doneBy && data.doneBy !== "") {
        if (data.doneBy === "notAvailableId") {
          url = url + `&not_available_field=done_by`;
        } else {
          url = url + `&done_by=${data.doneBy}`;
        }
      }
      if (data.taxType && data.taxType !== "") {
        if (data.taxType === "notAvailableId") {
          url = url + `&not_available_field=tax_type`;
        } else {
          url = url + `&tax_type=${data.taxType}`;
        }
      }
      if (data.modeOfPayment && data.modeOfPayment !== "") {
        if (data.modeOfPayment === "notAvailableId") {
          url = url + `&not_available_field=mode_or_terms_of_payment`;
        } else {
          url = url + `&mode_or_terms_of_payment=${data.modeOfPayment}`;
        }
      }
      if (data.customer && data.customer !== "") {
        url = url + `&party=${data.customer}`;
      }
      if (data.voucherType && data.voucherType !== "") {
        url += `&voucher_group=${data.voucherType}`;
      }
      if (data.store && data.store !== "") {
        url = url + `&store=${data.store}`;
      }
      if (data.grossAmount && data.grossAmount !== "") {
        url = url + `&sub_total=${data.grossAmount}`;
      }
      if (data.taxAmount && data.taxAmount !== "") {
        url = url + `&vat=${data.taxAmount}`;
      }
      if (data.totalAmount && data.totalAmount !== "") {
        url = url + `&total=${data.totalAmount}`;
      }

      if (data.sort) {
        url = url + `&sorting=${data.sort}`;
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_balance: response.data.data.total_balance,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const exportSingleSalesReport = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    const response = await api.get(
      API_SALES_REPORT_EXPORT.replace("{id}", data.id).replace(
        "{fileType}",
        data.fileType
      )
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const exportSingleSalesReturnReport = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    const response = await api.get(
      API_SALES_RETURN_REPORT_EXPORT.replace("{id}", data.id).replace(
        "{fileType}",
        data.fileType
      )
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const exportSalesReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    console.log("data----------", data);
    const response = await api.get(
      API_SALES_RETURN_REPORTS_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const fetchSalesReportsForParty =
  (data, callback) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      console.log("data----------", data);
      const response = await api.get(
        API_SALES_REPORT_FOR_CUSTOMER_LIST.replace("{page}", data.page)
          .replace("{pageSize}", data.pageSize)
          .replace("{startDate}", data.startDate)
          .replace("{endDate}", data.endDate)
          .replace("{party}", data.party)
          .replace("{status}", data.status)
      );
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
    }
  };

export const fetchSaleEstimateReports =
  (data, callback) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      console.log("data----------", data);
      let url = API_SALE_ESTIMATE_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status);

      if (data.party && data.party != null) {
        url = url + `&party=${data.party}`;
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
    }
  };

export const exportSaleEstimateReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    console.log("data----------", data);
    const response = await api.get(
      API_SALE_ESTIMATE_REPORT_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const deleteSalesReturnItem =
  ({ data, filters }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(`${SALE_RETURN_WITH_ITEM}${data.id}`);
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        if (filters) {
          dispatch(
            fetchSalesReturnReports({
              data: {
                page: filters.currentPage,
                pageSize: 10,
                startDate: filters.startDate,
                endDate: filters.endDate,
                status: filters.status,
              },
            })
          );
        }
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const fetchSalesReturnReportsByParty =
  (data, callback) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      const response = await api.get(
        API_SALES_RETURN_REPORT_LIST_BY_PARTY_MINIMAL.replace(
          "{party}",
          data.party
        ).replace("{status}", data.status)
      );
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
    }
  };

export const fetchSalesReturnReportsByInvoiceIds =
  (data, callback) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      const response = await api.get(
        API_SALES_RETURN_REPORT_LIST_BY_IDS_MINIMAL.replace(
          "{ids}",
          data.invoiceIds.join()
        )
      );
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
    }
  };

export const fetchSalesReturnReportsByInvoiceIdsNotMinimal =
  (data, callback) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      const response = await api.get(
        API_SALES_RETURN_REPORT_LIST_BY_IDS.replace(
          "{ids}",
          data.invoiceIds.join()
        )
      );
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
    }
  };

export const clearSalesReturnReports = (data, callback) => async (dispatch) => {
  dispatch(setReports({ ...initialState }));
};
