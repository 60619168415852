import { useQuery } from "react-query";
import api, { API_ITEMS_LIST } from "utils/axios";

const getItems = async () => api.get(API_ITEMS_LIST).then((res) => res.data);

export function useItems() {
  return useQuery("itemsNonPaginated", getItems, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
