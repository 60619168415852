/* eslint-disable react-hooks/exhaustive-deps */
import { Select } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";

const ISelect = (props) => {
  const {
    children,
    onKeyDown,
    onFocus,
    onChange,
    inputRef,
    value,
    placeholder,
    disabled,
    id,
    width,
    isRequired = false,
    isValid = true,
    errorMessage,
    buttonClick = false,
  } = props;
  const toast = useToast();

  useEffect(() => {
    if (!isValid && errorMessage) {
      toast({
        title: "",
        description: `${errorMessage}`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [isValid, buttonClick]);
  useEffect(() => {
    console.log("value", value);
    console.log("type", typeof value);
  }, [value]);
  return (
    <Select
      _hover={{
        borderColor: !isValid && isRequired && value === "" ? "red" : "black",
      }}
      _focus={{
        borderColor: !isValid && isRequired && value === "" ? "red" : "#2b6cb0",
        borderWidth: 2,
      }}
      height="39px"
      borderWidth={1}
      borderColor={!isValid && isRequired && value === "" ? "red" : "#bababa"}
      style={{ color: isRequired && value === "" ? "red" : null }}
      width={width}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onChange={onChange}
      ref={inputRef}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      id={id}
    >
      {children}
    </Select>
  );
};
export default ISelect;
