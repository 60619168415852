import { useMutation, useQueryClient } from "react-query";
import api, { API_ITEMS_LIST } from "utils/axios";

const createItem = (item) =>
  api.post(API_ITEMS_LIST, item).then((res) => res.data);

export function useCreateItem() {
  const queryClient = useQueryClient();
  return useMutation(createItem, {
    onSuccess: (response) => {
      queryClient.refetchQueries("items");
      queryClient.setQueriesData(["itemsNonPaginated"], (oldData) => {
        if (oldData) {
          return {
            ...oldData,
            data: [...oldData.data, response.data],
          };
        }
      });
    },
  });
}
