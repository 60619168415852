import Form from "components/Form";
import RenderComponent from "./RenderComponent";
import { useDecimalLength } from "hooks/useDecimalLength";
import React, { useEffect, useState } from "react";
import { date, number, object, string } from "yup";
import { getLocalStore } from "utils/utils";

const AddAdvanceAmountComponent = (props) => {
  const { initialValue, onSubmit, decimalLength, isOpen, closeModal } = props;
  const [formValues, setFormValues] = useState(initialValue || {});
  var regex = new RegExp(`^[0-9]*(.[0-9]{0,${decimalLength}})?$`);

  useEffect(() => {
    if (initialValue) {
      setFormValues({ ...initialValue });
    } else {
      setFormValues({ date: getLocalStore("VDATE_MODEL") ?? null });
    }
  }, [initialValue, isOpen]);

  return (
    <Form
      initialValues={{ ...formValues }}
      RenderComponent={RenderComponent}
      handleOnSubmit={(fields) => {
        onSubmit(fields);
        closeModal();
      }}
      validationSchema={object().shape({
        amount: number()
          .moreThan(-1, "Enter a valid number")
          .required("Advance Amount Required")
          .typeError("Enter a valid number"),
        date: date().typeError("please enter a valid date").required(),
        invoice_number: string().required("Invoice Number Required"),
      })}
      customProps={{
        closeModal: closeModal,
        isOpen: isOpen,
      }}
    />
  );
};

export default AddAdvanceAmountComponent;
