import { useQuery } from "react-query";
import api, { API_VOUCHER_TYPE_LIST } from "utils/axios";

const getVoucherType = ({ queryKey }) => {
  const [_, data] = queryKey;
  let url = API_VOUCHER_TYPE_LIST;
  if (data?.isSuspended && data?.isSuspended !== "") {
    url = url + `?suspened=${data.isSuspended}`;
  }
  return api.get(url).then((res) => res.data);
};

export function useVoucherType(data) {
  return useQuery(["vouchertype", data], getVoucherType, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
