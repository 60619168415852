import { getLocalStore } from "../utils/index";
import reduxStore from "../../store";
import { showSnackbar } from "../../slices/snackbar.slice";

// Add a request interceptor
export function onRequest(config) {
  const auth = getLocalStore("AUTH_DETAILS");
  if (auth?.data?.token) {
    config.headers = {
      ...config.headers,
      Authorization: `JWT ${auth?.data?.token}`,
    };
    if (
      auth?.data?.user_type === 1 ||
      (auth?.data?.group_companies && auth?.data?.group_companies !== null)
    ) {
      if (auth?.data?.company?.id) {
        config.params = {
          ...config.params,
          company: `${auth?.data?.company?.id}`,
        };
      }
    }
    // config.url = config.url;
  }

  return config;
}

export function onRequestError(error) {
  // Do something with request error
  return Promise.reject(error);
}

// Add a response interceptor
export function onResponse(response) {
  const { dispatch } = reduxStore;
  const customResponse = response;
  const { status } = response;
  if (status === 500) {
    return Promise.reject(getUnknownError());
  } else if (status === 400) {
    const { errorMessage } = response.data.message || "";
    return Promise.reject(getUnknownError(errorMessage));
  }
  customResponse.data = response.data;
  let customMessage = {};
  // if (response?.config?.url?.includes('signup')) {
  //   customMessage = {
  //     message: 'Registration successful. Please wait for admin approval',
  //     display: true,
  //     type: 'SUCCESS'
  //   }
  // } else {
  customMessage = {
    message: response.data.message,
    status: response.data.status,
  };
  // }

  // To not show the snackbar for next invoice number api
  if (customMessage.message !== "Success") {
    dispatch(showSnackbar(customMessage));
  }
  return customResponse;
}

export function onResponseError(error) {
  const { dispatch } = reduxStore;
  let customError = { ...error };
  const status = Number(
    error.response ? error.response.headers.status || error.response.status : -1
  );
  if (status === 401 && window.location.pathname !== "/") {
    localStorage.clear();
    window.location.reload("/");
  } else if (
    error.response.data.message ===
    "'NoneType' object has no attribute 'account_owner'"
  ) {
    const auth = getLocalStore("AUTH_DETAILS");
    if (auth?.data?.user_type === 1) {
      window.location = "/master-dashboard";
    } else if (
      auth?.data?.group_companies &&
      auth?.data?.group_companies !== null
    ) {
      window.location = `/group-companies/${auth?.data?.group_companies}`;
    }
  } else if (status === 500) {
    customError = getUnknownError();
  } else if (status === 200) {
    if (error.response.data.message === "Expired token") {
      customError = {
        response: {
          message: 1000,
          status: status,
          display: true,
          type: "ERROR",
        },
      };
      localStorage.clear();
    } else {
      customError = {
        response: {
          status: status,
          message: error.response.data.message,
          type: "ERROR",
        },
      };
    }
  } else {
    if (status === 400) {
      if (error.response.data.non_field_errors) {
        customError = {
          response: {
            status: status,
            message: error.response.data.non_field_errors,
            type: "ERROR",
          },
        };
      } else {
        customError = {
          response: {
            status: status,
            message: error.response.data.message,
            type: "ERROR",
          },
        };
      }
    } else if (status === 403) {
      if (
        error.response.data.detail &&
        error.response.data.detail ===
          "You do not have permission to perform this action."
      ) {
        customError = {
          response: {
            status: status,
            message: "User Doesn't Have Access To This Service",
            type: "ERROR",
          },
        };
      } else if (
        error.response.data.detail &&
        error.response.data.detail !==
          "You do not have permission to perform this action."
      ) {
        customError = {
          response: {
            status: status,
            message: error.response.data.detail,
            type: "ERROR",
          },
        };
      } else {
        customError = {
          response: {
            status: status,
            message: "An unknown error has occurred",
            type: "ERROR",
          },
        };
      }
    } else {
      customError = {
        response: {
          status: status,
          message: "An unknown error has occurred",
          type: "ERROR",
        },
      };
    }
  }
  dispatch(showSnackbar(customError.response));

  return Promise.reject(customError);
}

const getUnknownError = (message) => ({
  response: {
    message:
      message && typeof message === typeof ""
        ? message
        : "Service unavailable. Please try after some time.",
    display: true,
    status: 500,
    type: "ERROR",
  },
});
