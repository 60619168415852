import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { Box, Text } from "@chakra-ui/react";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";
import { IconButton } from "@material-ui/core";

const theme = createTheme({
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1400,
    snackbar: 1500,
    tooltip: 1600,
  },
});

export const IDatePicker = (props) => {
  const {
    dateLabel,
    date,
    modal,
    setDate,
    onClick,
    disabled = false,
    onFocus,
    inputRef,
    minDate,
    maxDate,
    ...rest
  } = props;

  return (
    <Box {...rest}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            fullWidth
            size="small"
            variant="inline"
            inputVariant="outlined"
            label={dateLabel}
            format="DD/MM/yyyy"
            value={date}
            onChange={(date) => setDate(date)}
            minDate={minDate}
            maxDate={maxDate}
            KeyboardButtonProps={{
              "aria-label": "change date",
              component: IconButton,
              style: { padding: 0, marginRight: "-6px" },
            }}
            // inputProps={
            //   isMobile
            //     ? { style: { padding: "10px 10px", fontSize: "11px" } }
            //     : {}
            // }
            disabled={disabled}
            onClick={onClick}
            InputLabelProps={isMobile ? { style: { fontSize: "11px" } } : {}}
            onFocus={onFocus}
            inputRef={inputRef}
            TypographyProps={
              isMobile ? { style: { fontSize: "11px", lineHeight: "1.2" } } : {}
            }
            FormHelperTextProps={{
              style: { display: "none" }, // Hide the error message
            }}
            // PopoverProps={{
            //   style: "zIndex: 1400",
            // }}
          />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Box>
  );
};

export default IDatePicker;
