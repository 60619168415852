import { useQuery } from "react-query";
import api, { API_PRICE_CATEGORY_NAME } from "utils/axios";

const getPriceCategoryName = () =>
  api.get(API_PRICE_CATEGORY_NAME).then((res) => res.data);

export function usePriceCategoryName() {
  return useQuery("pricecategoryname", getPriceCategoryName, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
