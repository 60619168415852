import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { TextField } from "@material-ui/core";
import StoreSelectionField from "components/StoreSelectionField";
import { useDecimalLength } from "hooks/useDecimalLength";
import React, { useEffect, useState } from "react";
import {
  IButton,
  IHead,
  IModalOut,
  IModalIn,
  IHStack,
  IButtonOut,
} from "components/IModal";
export default function MinMaxStockModal(props) {
  const { initialValue, onSubmit, isOpen, onClose } = props;
  const decimalLength = useDecimalLength(2);
  const [disable, setDisable] = useState(true);
  const [item, setItem] = useState({
    id: null,
    store: "",
    minimum_stock: null,
    maximum_stock: null,
  });

  useEffect(() => {
    setItem({
      ...initialValue,
      id: initialValue?.id || null,
      store: initialValue?.store || "",
      minimum_stock:
        _parseFloat(initialValue?.minimum_stock).toFixed(decimalLength) || null,
      maximum_stock:
        _parseFloat(initialValue?.maximum_stock).toFixed(decimalLength) || null,
    });
  }, [isOpen, initialValue]);

  useEffect(() => {
    if (!item.store) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [item]);

  const onCloseModal = () => {
    setItem({});
    onClose();
  };

  const handleSubmit = () => {
    onSubmit({ ...item });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      isCentered
      scrollBehavior="inside"
      size="2xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <IModalOut>
            <IHead text="Mix Max Stock" />
            <IModalIn>
              <StoreSelectionField
                store={item?.store}
                setStore={(store) => {
                  setItem({ ...item, store: store });
                }}
              />
              <IHStack>
                <FormControl id="min-stock">
                  <TextField
                    fullWidth
                    label="Minimum Qty"
                    variant="outlined"
                    size="small"
                    type="number"
                    onChange={(event) => {
                      setItem({
                        ...item,
                        minimum_stock: event.target.value,
                      });
                    }}
                    value={item.minimum_stock || ""}
                    onFocus={(e) => e.target.select()}
                  />
                </FormControl>
                <FormControl id="max-stock">
                  <TextField
                    fullWidth
                    label="Maximum Qty"
                    variant="outlined"
                    size="small"
                    type="number"
                    onChange={(event) => {
                      setItem({
                        ...item,
                        maximum_stock: event.target.value,
                      });
                    }}
                    value={item.maximum_stock || ""}
                    onFocus={(e) => e.target.select()}
                  />
                </FormControl>
              </IHStack>
            </IModalIn>
            <IButtonOut>
              <IButton disabled={disable} onClick={handleSubmit} type="add" />
            </IButtonOut>
          </IModalOut>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

const _parseFloat = (num) => {
  return parseFloat(num || 0);
};
