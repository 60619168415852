import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import { useToast } from "@chakra-ui/react";

import Form from "../Form";
import RenderComponent from "./RenderComponent";
import { useCreateItemCategory } from "hooks/ItemCategory/useCreateItemCategory";
import { useUpdateItemCategory } from "hooks/ItemCategory/useUpdateItemCategory";
import { useCostCenters } from "hooks/CostCenter/useCostCenters";
import { useDoneBy } from "hooks/DoneBy/useDoneBy";
import { getLocalStore } from "utils/utils";

const AddItemCategory = (props) => {
  const [isToggledFav, setIsToggledFav] = useState(false);
  const [suspand, setSuspand] = useState(false);
  const {
    type,
    editValue,
    changeType,
    listData,
    clearSearch,
    onAddSuccess,
    searchData,
    approveFunc,
    rejectFunc,
    isApproval = false,
    isRejectLoading = false,
    isApproveLoading = false,
  } = props;
  const toast = useToast();
  const { data: costCenterData } = useCostCenters();
  const { data: doneByData } = useDoneBy();

  const {
    mutate: createItemCategory,
    data,
    isSuccess,
    isLoading: isCreateLoading,
  } = useCreateItemCategory();

  const {
    mutate: updateItemCategory,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useUpdateItemCategory();

  const initialValue = {
    name: editValue?.name !== "" ? editValue?.name : searchData || "",
    opening_balance: 0,
    description: "",
    group: "",
    favourites: isToggledFav,
    is_suspended: suspand,
  };
  const [formValues, setformValues] = useState(initialValue);
  const [clearValues, setClearValues] = useState(false);
  const [costCenter, setCostCenter] = useState([]);
  const [doneBy, setDoneBy] = useState([]);

  const handleFavourite = () => {
    setIsToggledFav(!isToggledFav);
  };

  const handleSuspand = () => {
    setSuspand(!suspand);
  };
  useEffect(() => {
    if (isSuccess) {
      if (data?.status === "success") {
        toastWithMessage("Category created successfully");
        onAddSuccess(data?.data);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Category updated successfully");
        onAddSuccess(updateResponse?.data);
      }
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    const editObj = listData?.filter(
      (item) => item.uuid === editValue?.group
    )[0];
    if (type === "add") {
      setformValues(initialValue);
      if (costCenterData && doneByData) {
        setCostCenter(
          costCenterData.data?.filter((i) =>
            [
              getLocalStore("DEFAULT_SETTINGS_DATA")?.default_cost_centre,
            ]?.includes(i.id)
          ) || []
        );
        setDoneBy(
          doneByData.data?.filter((i) =>
            [getLocalStore("DEFAULT_SETTINGS_DATA")?.default_done_by]?.includes(
              i.id
            )
          ) || []
        );
      }
    } else {
      setformValues(editValue);
      setIsToggledFav(editValue.favourites);
      setSuspand(editValue.is_suspended);
      if (costCenterData && doneByData) {
        setCostCenter(
          costCenterData.data?.filter((i) =>
            editValue.cost_centre?.includes(i.id)
          ) || []
        );
        setDoneBy(
          doneByData.data?.filter((i) => editValue.done_by?.includes(i.id)) ||
            []
        );
      }
      if (editValue.group) {
        setformValues({
          ...editValue,
          group: {
            label: editObj.name,
            value: editObj.id,
          },
        });
      } else {
        setformValues({ ...editValue, group: "" });
      }
    }
  }, [type, editValue, listData, doneByData, costCenterData]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const resetFormValues = () => {
    setformValues(initialValue);
    changeType();
  };
  return (
    <Form
      initialValues={{ ...formValues }}
      RenderComponent={RenderComponent}
      handleOnSubmit={(fields) => {
        fields.favourites = isToggledFav;
        fields.is_suspended = suspand;
        fields.cost_centre = costCenter?.map((i) => i.id) || [];
        fields.done_by = doneBy?.map((i) => i.id) || [];
        if (type === "edit") {
          updateItemCategory(fields);
        } else {
          createItemCategory(fields);
        }
        //clearSearch();
      }}
      customProps={{
        type: type,
        clearInitialValues: () => resetFormValues(),
        handleResetToComponent: clearValues,
        listData: listData,
        isLoading: isCreateLoading || isUpdateLoading,
        isSubmitted: isSuccess || isUpdateSuccess,
        handleFavourite,
        isToggledFav,
        handleSuspand,
        suspand,
        costCenter,
        setCostCenter,
        doneBy,
        setDoneBy,
        doneByData,
        costCenterData,
        isApproval,
        approveFunc,
        rejectFunc,
        isRejectLoading,
        isApproveLoading,
      }}
      validationSchema={object().shape({
        name: string().required("Name Required"),
      })}
    />
  );
};
export default AddItemCategory;
