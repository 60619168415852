import { useMutation, useQueryClient } from "react-query";
import api, { API_LEDGER_GROUPS_LIST } from "utils/axios";

const createLedgerGroups = (DoneBy) =>
  api.post(API_LEDGER_GROUPS_LIST, DoneBy).then((res) => res.data);

export function useCreateLedgerGroups() {
  const queryClient = useQueryClient();
  return useMutation(createLedgerGroups, {
    onSuccess: () => queryClient.refetchQueries("ledgergroups"),
  });
}
