import { useQuery } from "react-query";
import api, { API_LEDGER_GROUPS_LIST } from "utils/axios";

const getLedgerGroups = ({ queryKey }) => {
  const [_, filter] = queryKey;
  let url = API_LEDGER_GROUPS_LIST;
  if (filter && filter.isSuspended !== "") {
    url = url + `?suspened=${filter.isSuspended}`;
    if (filter.favourites) {
      url = url + `&favourites=true`;
    }
  } else if (filter?.favourites) {
    url = url + `?favourites=true`;
  }

  return api.get(url).then((res) => res.data);
};

export function useLedgerGroups(filter) {
  return useQuery(["ledgergroups", filter], getLedgerGroups, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
