import { useMutation, useQueryClient } from "react-query";
import api, { API_PARTIES_GET_EDIT_DELETE } from "utils/axios";

const updateCustomer = (costCenter) =>
  api
    .patch(
      API_PARTIES_GET_EDIT_DELETE.replace("{id}", costCenter.id),
      costCenter
    )
    .then((res) => res.data);

export function useUpdateCustomer() {
  const queryClient = useQueryClient();
  return useMutation(updateCustomer, {
    onSuccess: () => queryClient.refetchQueries("customers"),
  });
}
