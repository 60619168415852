export function saveAndPrintShortKey(
  setClickKey,
  isOpenEditItemModal = false,
  openCusModal = false,
  isOpenServiceModal = false
) {
  //Prevent Ctrl+S (and Ctrl+W for old browsers and Edge)
  document.onkeydown = function (e) {
    e = e || window.event; //Get event

    if (!e.ctrlKey) return;

    var code = e.which || e.keyCode; //Get key code

    // eslint-disable-next-line default-case
    if (!(isOpenEditItemModal || openCusModal || isOpenServiceModal)) {
      switch (code) {
        case 83: //Block Ctrl+S
          e.preventDefault();
          e.stopPropagation();
          setClickKey({
            keyS: true,
          });
          break;
        case 80: //Block Ctrl+p
          e.preventDefault();
          e.stopPropagation();
          setClickKey({
            keyP: true,
          });
          break;
        case 65: //Block Ctrl+A
          e.preventDefault();
          e.stopPropagation();
          setClickKey({
            keyA: true,
          });
          break;
        default:
          setClickKey({
            keyS: false,
            keyP: false,
          });
          break;
      }
    }
  };
}
