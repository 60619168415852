import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { Route } from "react-router-dom";
import { clearLocalStore, getLocalStore } from "./utils/utils";

const ResetRoute = ({ component: Component, restricted, ...rest }) => {
  const auth = getLocalStore("AUTH_DETAILS");
  const queryClient = useQueryClient();

  useEffect(() => {
    if (auth) {
      clearLocalStore();
      queryClient.clear();
    }
  }, []);
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default ResetRoute;
