export const InvoiceDataOptions = [
  { id: "1", value: "COST_CENTER", label: "Cost Center" },
  { id: "2", value: "DUE_DATE", label: "Due Date" },
  { id: "3", value: "D.O_NUMNER", label: "D.O Number" },
  { id: "4", value: "D.O_DATE", label: "D.O Date" },
  { id: "5", value: "DONE_BY", label: "Done by" },
  { id: "6", value: "L.P.O_NUMBER", label: "L.P.O Number" },
  { id: "7", value: "L.P.O_DATE", label: "L.P.O Date" },
  { id: "8", value: "NOTES", label: "Notes" },
  { id: "9", value: "REFERENCE_NUMBER", label: "Reference Number" },
  { id: "10", value: "REMARK_IN_INVOICE", label: "Remarks in Invoice" },
  { id: "11", value: "MODE_OF_PAYMENT", label: "Mode of Payment" },
  { id: "12", value: "TERM_OF_DELIVERY", label: "Term Of Delivery" },

  { id: "13", value: "TAX_TYPE", label: "Tax Type" },
  { id: "14", value: "PLANCE_OF_SUPPLY", label: "Place Of Supply" },
  { id: "15", value: "BUYERS_REFERENCE", label: "Buyers Ref.No" },
  { id: "16", value: "DESPATCH_DOCUMENT_NUMBER", label: "Despatch Doc.NO" },
  { id: "17", value: "DESPATCH_THROUGH", label: "Despatch Through" },
  { id: "18", value: "DESTINATION", label: "Destination" },
  { id: "19", value: "SHIPMENT_DUE_DATE", label: "Shipment Due Date" },
  { id: "20", value: "SHIP_TO_ADDRESS", label: "Ship To Address" },
  { id: "21", value: "MY_COMPANY_ADDRESS", label: "My Company Address" },
  { id: "22", value: "MY_COMPANY_NAME", label: "My Company Name" },
  { id: "23", value: "STORE", label: "Store" },
  { id: "24", value: "VOUCHER_TYPE", label: "Voucher Type" },
  { id: "25", value: "PRICE_CATEGORY", label: "Price Category" },
];
