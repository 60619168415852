import { createSlice } from "@reduxjs/toolkit";

import { showLoading, hideLoading } from "./common.slice";

import api, {
  API_LEDGER_LIST,
  API_LEDGER_ADD,
  API_LEDGER_EDIT,
  API_GROUPS_LIST,
  API_LEDGER_LIST_BY_GROUP_ID,
} from "../utils/axios";

const initialState = {
  loading: false,
  ledgers: [],
  items: [],
  groups: [],
};

const items = createSlice({
  name: "ledger",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setItems: (state, action) => {
      state.items = action.payload.items;
    },
    setLedgers: (state, action) => {
      state.ledgers = action.payload.ledgers;
    },
    setItemDetails: (state, action) => {
      state.itemDetails = action.payload.itemDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
    setGroups: (state, action) => {
      state.groups = action.payload.groups;
    },
  },
});

export const {
  setLoading,
  setItems,
  setLedgers,
  setItemDetails,
  setErrorMessage,
  setGroups,
} = items.actions;

export default items.reducer;

export const fetchLedgers = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(API_LEDGER_LIST);
    if (response && response.data) {
      dispatch(setItems({ items: response.data.data }));
      dispatch(setLedgers({ ledgers: response.data.data }));
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(setItems({ items: [] }));
  }
};

export const fetchLedgerByGroupId = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_LEDGER_LIST_BY_GROUP_ID.replace("{group}", data)
    );
    if (response && response.data) {
      dispatch(setItems({ items: response.data.data }));
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(setItems({ items: [] }));
  }
};

export const addItem =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.post(API_LEDGER_ADD, data);
      if (response && response.data) {
        dispatch(hideLoading());
        dispatch(fetchLedgers());
        callback && callback(response.data);
      }
    } catch (error) {
      dispatch(hideLoading());
      callback && callback(error);
    }
  };

export const editItem =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.patch(
        API_LEDGER_EDIT.replace("{id}", data.id),
        data
      );
      dispatch(hideLoading());
      if (response && response.data) {
        dispatch(fetchLedgers());
        callback && callback(response.data);
      }
    } catch (error) {
      dispatch(hideLoading());
      callback && callback(error);
    }
  };

export const deleteItem =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.delete(
        API_LEDGER_EDIT.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(hideLoading());
        dispatch(fetchLedgers());
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

export const fetchGroups = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(API_GROUPS_LIST);
    if (response && response.data && response.data.data) {
      dispatch(setGroups({ groups: response.data.data }));
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(setGroups({ groups: [] }));
  }
};
