import { createSlice } from "@reduxjs/toolkit";

// import { setLocalStore, setResponse } from '../utils/utils'
import api, {
  API_ITEMS_LIST,
  API_ITEMS_ADD,
  API_ITEMS_EDIT,
} from "../utils/axios";

import { showLoading, hideLoading } from "./common.slice";

const initialState = {
  loading: false,
  items: [],
};

const items = createSlice({
  name: "items",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setItems: (state, action) => {
      state.items = action.payload.items;
    },
    setItemDetails: (state, action) => {
      state.itemDetails = action.payload.itemDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setItems, setItemDetails, setErrorMessage } =
  items.actions;

export default items.reducer;

export const fetchItems = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(API_ITEMS_LIST);
    if (response && response.data) {
      dispatch(setItems({ items: response.data.data }));
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(setItems({ items: [] }));
  }
};

export const addItem =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.post(API_ITEMS_ADD, data);
      if (response && response.data) {
        dispatch(hideLoading());
        dispatch(fetchItems());
        callback && callback(response);
      }
    } catch (error) {
      dispatch(hideLoading());
      callback && callback(error?.response);
    }
  };

export const editItem =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.patch(
        API_ITEMS_EDIT.replace("{id}", data.id),
        data
      );
      if (response && response.data) {
        dispatch(hideLoading());
        dispatch(fetchItems());
        callback && callback(response);
      }
    } catch (error) {
      dispatch(hideLoading());
      callback && callback(error?.response);
    }
  };

export const deleteItem =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_ITEMS_EDIT.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchItems());
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };
