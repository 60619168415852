import { useMutation, useQueryClient } from "react-query";
import api, { API_STORE_LIST_CREATE } from "utils/axios";

const createStore = (store) =>
  api.post(API_STORE_LIST_CREATE, store).then((res) => res.data);

export function useCreateStore() {
  const queryClient = useQueryClient();
  return useMutation(createStore, {
    onSuccess: () => queryClient.refetchQueries("store"),
  });
}
