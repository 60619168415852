import React, { useEffect, useState, useRef } from "react";
import { Field } from "formik";
import {
  Box,
  Stack,
  Text,
  FormControl,
  FormLabel,
  Select,
  Button,
  Grid,
  GridItem,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Textarea,
  Tooltip,
  Checkbox,
  Spacer,
  IconButton,
  Link,
  useDisclosure,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Divider,
  Menu,
  MenuButton,
  Icon,
  MenuItem,
  MenuList,
  Flex,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tfoot,
} from "@chakra-ui/react";
import TextField from "@material-ui/core/TextField";
import Message from "../Message";
import useKeypress from "react-use-keypress";

import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
import { isMobile } from "react-device-detect";

import GroupAutoComplete from "../GroupAutoComplete";
import CategoryAutoComplete from "../CategoryAutoComplete";
import BrandAutoComplete from "../BrandAutoComplete";
import UnitAutoComplete from "components/UnitAutoComplete";
import CostCenterSelectionField from "components/CostCenterSelectionField";
import DoneBySelectionField from "components/DoneBySelectionField";
import { getLocalStore } from "utils/utils";
import { ArrowDownIcon, ArrowUpIcon, SearchIcon } from "@chakra-ui/icons";
import { saveAndPrintShortKey } from "utils/utils/saveAndPrintShortkey";
import PriceCategoryModal from "pages/Transaction/components/PriceCategoryModal";
import { FiEdit3, FiEye, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { useDecimalLength } from "hooks/useDecimalLength";
import { usePriceCategoryName } from "hooks/PriceCategoryName/usePriceCategoryName";
import MultiUnitModal from "pages/Transaction/components/MultiUnitModal";
import { useItemUnit } from "hooks/ItemUnit/useItemUnit";
import MultiSelectionField from "components/MultiSelectionField";
import { useItems } from "hooks/Item/useItems";
import { useCostCenters } from "hooks/CostCenter/useCostCenters";
import { useDoneBy } from "hooks/DoneBy/useDoneBy";
import { useLedgers } from "hooks/Ledger/useLedgers";
import { Autocomplete } from "@material-ui/lab";
import AutocompleteWithAddOption from "FormComponents/AutoComplete/component";
import AddLedgerForm from "components/AddLedgerForm";
import useCheckDecimal from "hooks/useCheckDecimal";
import { useVoucherType } from "hooks/VoucherType/useVoucherType";
import { useLedgerGroups } from "hooks/LedgerGroup/useLedgerGroups";
import MinMaxStockModal from "pages/Transaction/components/MinMaxStockModal";
import { useStore } from "hooks/Store/useStore";

import {
  ITab,
  IButton,
  IHeadFav,
  ISelect,
  IOption,
  ITextField,
  ITabPanelIn,
  IModalOut,
  IModalIn,
  IHStack,
  IButtonOut,
  ITabPanel,
  ITextarea,
  IFieldAndLabel,
} from "components/IModal";

// const filter = createFilterOptions();

const iconStyle = {
  fontSize: "20px",
  cursor: "pointer",
};

const RenderComponent = ({
  onClear,
  handleEdit,
  values,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid,
  isSubmitting,
  touched,
  handleReset,
  cancelAction,
}) => {
  const {
    type,
    priceWithTax,
    setPriceWithTaxValue,
    price,
    setPriceValue,
    showTrack,
    setShowTrack,
    // p_priceWithTax,
    // setP_PriceWithTaxValue,
    discountWithTax,
    setDiscountWithTaxValue,
    discountWithOutTax,
    setDiscountWithOutTaxValue,
    tax,
    setTaxValue,
    isLoading,
    isSubmitted,
    purchasePriceWithTaxValue,
    purchasePriceWithoutTaxValue,
    purchasePriceWithTax,
    purchasePriceWithOutTax,
    purchasePriceDiscountWithTaxValue,
    purchasePriceDiscountWithoutTaxValue,
    purchasePriceDiscountWithoutTax,
    purchasePriceDiscountWithTax,
    setMinSpWithTaxValue,
    setMinSpWithoutTaxValue,
    minSpWithTax,
    minSpWithoutTax,
    setMaxPurchasePriceWithoutTaxValue,
    setMaxPurchasePriceWithTaxValue,
    maxPurchasePriceWithTax,
    maxPurchasePriceWithoutTax,
    editValue,
    handleFavourite,
    setSuspand,
    suspand,
    isService,
    setIsService,
    isToggledFav,
    isApproval,
    rejectFunc,
    isRejectLoading,
  } = customProps;
  const [tabIndex, setTabIndex] = useState(0);
  const [fieldInFocus, setFieldInFocus] = useState(0);
  const [showTax, setShowTax] = useState(false);
  const [showMin, setShowMin] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [clickKey, setClickKey] = useState({
    keyA: false,
    keyP: false,
  });
  const [hasExecuted, setHasExecuted] = useState(false);

  const { data: costCenterData } = useCostCenters();
  const { data: doneByData } = useDoneBy();
  const { data: unitData } = useItemUnit();
  const { data: storeData } = useStore();
  const { data: ledgerData, isLoading: isLoadingLedger } = useLedgers();
  const { data: ledgerGroupsData } = useLedgerGroups();
  const { data: priceCategoryNameData } = usePriceCategoryName();
  const { data: voucherTypeData } = useVoucherType();
  const decimalLength = useDecimalLength(2);
  const [checkDecimal] = useCheckDecimal();
  const [isOpen, setIsOpen] = useState("close");
  const priceCategoryModal = useDisclosure();
  const multiUnitModal = useDisclosure();
  const minMaxStockModal = useDisclosure();
  const [selectedPriceCategory, setSelectedPriceCategory] = useState([]);
  const [selectedMultiUnits, setSelectedMultiUnits] = useState([]);
  const [selectedMinMaxStock, setSelectedMinMaxStock] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null); // currently selected category
  const [editingMultiUnit, setEditingMultiUnit] = useState(null);
  const [editingMinMaxStock, setEditingMinMaxStock] = useState(null);
  const [unitList, setUnitList] = useState([]);
  const [storeList, setStoreList] = useState([]);

  const [costCenters, setCostCenters] = useState([]);
  const [doneBys, setDoneBys] = useState([]);
  const [postingLedger, setPostingLedger] = useState(null);
  const [purchaseLedger, setPurchaseLedger] = useState(null);
  const [postingLedgersList, setPostingLedgersList] = useState([]);

  const nameRef = useRef();
  const aliasRef = useRef();
  const taxRef = useRef();
  const unitRef = useRef();
  const maximumRef = useRef();
  const minimumRef = useRef();
  const descriptionRef = useRef();
  const itemcodRef = useRef();
  const barcodeRef = useRef();
  const hsnCodeRef = useRef();
  const itemgroupRef = useRef();
  const categoryRef = useRef();
  const openingQtyRef = useRef();
  const costPriceRef = useRef();
  // next tab
  const spWithTaxRef = useRef();
  const spWithoutTaxRef = useRef();
  const spDiscountWithTaxRef = useRef();
  const spDiscountWithoutTaxRef = useRef();
  const ppDiscountWithTaxRef = useRef();
  const ppDiscountWithoutTaxRef = useRef();

  const ppWithTaxRef = useRef();
  const ppWithoutTaxRef = useRef();
  const mrpRef = useRef();
  const miniSpWithoutTaxRef = useRef();
  const miniSpWithTaxRef = useRef();
  const maxPurchasePriceWithTaxRef = useRef();
  const maxPurchasePriceWithoutTaxRef = useRef();

  const salePostingLedgerRef = useRef();
  const purchasePostingLedgerRef = useRef();
  const costCenterRef = useRef();
  const doneByRef = useRef();
  const brandRef = useRef();

  useEffect(() => {
    if (!isMobile) {
      if (tabIndex === 0) {
        //base tab
        if (fieldInFocus === 0) nameRef.current?.focus();
        if (fieldInFocus === 1) aliasRef.current?.focus();
        if (fieldInFocus === 2) taxRef.current?.focus();
        if (fieldInFocus === 3) unitRef.current?.focus();
        if (fieldInFocus === 4) itemgroupRef.current?.focus();
        if (fieldInFocus === 5) categoryRef.current?.focus();
        if (fieldInFocus === 6) descriptionRef.current?.focus();
        if (fieldInFocus === 7) itemcodRef.current?.focus();
        if (fieldInFocus === 8) barcodeRef.current?.focus();
        if (fieldInFocus === 9) hsnCodeRef.current?.focus();
        if (fieldInFocus === 10) openingQtyRef.current?.focus();
        if (fieldInFocus === 11) costPriceRef.current?.focus();
      } else if (tabIndex === 1) {
        if (fieldInFocus === 0) spWithoutTaxRef.current?.focus();
        if (fieldInFocus === 1) spDiscountWithoutTaxRef.current?.focus();
        if (fieldInFocus === 2) spWithTaxRef.current?.focus();
        if (fieldInFocus === 3) spDiscountWithTaxRef.current?.focus();
        if (fieldInFocus === 4) ppWithoutTaxRef.current?.focus();
        if (fieldInFocus === 5) ppDiscountWithoutTaxRef.current?.focus();
        if (fieldInFocus === 6) ppWithTaxRef.current?.focus();
        if (fieldInFocus === 7) ppDiscountWithTaxRef.current?.focus();
        if (fieldInFocus === 8) mrpRef.current?.focus();
        if (fieldInFocus === 9) miniSpWithoutTaxRef.current?.focus();
        if (fieldInFocus === 10) miniSpWithTaxRef.current?.focus();
        if (fieldInFocus === 11) maxPurchasePriceWithTaxRef.current?.focus();
        if (fieldInFocus === 12) maxPurchasePriceWithoutTaxRef.current?.focus();
        // }
      } else if (tabIndex === 5) {
        if (fieldInFocus === 0) salePostingLedgerRef.current?.focus();
        if (fieldInFocus === 1) purchasePostingLedgerRef.current?.focus();
        if (fieldInFocus === 2) costCenterRef.current?.focus();
        if (fieldInFocus === 3) doneByRef.current?.focus();
        if (fieldInFocus === 4) brandRef.current?.focus();
      }
    }
  });

  const [buttonClick, setButtonClick] = useState(false);
  const onSubmit = () => {
    setButtonClick(!buttonClick);
    const validateResponse = validateForm();
    validateResponse.then((data) => {
      submitAction(data);
    });
  };
  useKeypress(["Enter", "Tab"], (event) => {
    if (event.key === "Enter") {
      let next = 1;
      if (tabIndex == 0) {
        if (fieldInFocus === 6 && !showMore) {
          onSubmit();
        } else if (fieldInFocus === 11) {
          onSubmit();
        }
      } else if (tabIndex == 1) {
        if (fieldInFocus === 1 && !showTax) {
          next = 3;
        } else if (fieldInFocus === 5 && !showTax) {
          if (!showMin) {
            onSubmit();
          } else {
            next = 3;
          }
        }
      } else if (tabIndex === 5) {
        if (fieldInFocus === 4) {
          onSubmit();
        }
      }
      setFieldInFocus(fieldInFocus + next);
    }

    // if (
    //   event.key === "Enter" && tabIndex === 0
    //     ? fieldInFocus < 15 && isOpen === "close"
    //     : fieldInFocus < 13 && isOpen === "close"
    // ) {
    //   let next = 1;
    //   setFieldInFocus(fieldInFocus + next);
    //   if (fieldInFocus === 1 && !showTax && !showMin) {
    //     let next = 3;
    //     setFieldInFocus(fieldInFocus + next);
    //   } else if (!showTax && showMin) {
    //     let next = 3;
    //     setFieldInFocus(fieldInFocus + next);
    //   }
    // }
    event.stopPropagation();
    event.preventDefault();
  });
  useEffect(() => {
    setTimeout(() => {
      if (tabIndex === 0) {
        nameRef.current?.focus();
      } else if (tabIndex === 1) {
        spWithoutTaxRef.current?.focus();
      } else if (tabIndex === 5) {
        salePostingLedgerRef.current?.focus();
      }
    }, 200);
  }, [tabIndex]);

  useKeypress(
    ["f1", "F1", "f2", "F2", "f3", "F3", "f4", "F4", "f5", "F5", "f6", "F6"],
    (event) => {
      event.preventDefault();
      if (["f1", "F1"].includes(event.key)) {
        setTabIndex(0);
      }
      if (["f2", "F2"].includes(event.key)) {
        setTabIndex(1);
      }
      if (["f3", "F3"].includes(event.key)) {
        setTabIndex(2);
      }
      if (["f4", "F4"].includes(event.key)) {
        setTabIndex(3);
      }
      if (["f5", "F5"].includes(event.key)) {
        setTabIndex(4);
      }
      if (["f6", "F6"].includes(event.key)) {
        setTabIndex(5);
      }
      if (
        ![
          "f1",
          "F1",
          "f2",
          "F2",
          "f3",
          "F3",
          "f4",
          "F4",
          "f5",
          "F5",
          "f6",
          "F6",
        ].includes(event.key)
      ) {
        event.stopPropagation();
        event.preventDefault();
      }
    }
  );

  useEffect(() => {
    if (ledgerData?.data && ledgerGroupsData?.data) {
      const getChildrenIds = (parentId) => {
        const uuids = [];
        ledgerGroupsData?.data.forEach((item) => {
          if (item.master === parentId) {
            uuids.push(item.uuid);
            uuids.push(...getChildrenIds(item.uuid));
          }
        });
        return uuids;
      };

      const sundryDebtors = ledgerGroupsData?.data
        .filter((item) => item.name === "Sundry Debtors")
        .map((ids) => {
          return { uuid: ids.uuid, id: ids.id };
        })[0];
      const sundryCreditors = ledgerGroupsData?.data
        .filter((item) => item.name === "Sundry Creditors")
        .map((ids) => {
          return { uuid: ids.uuid, id: ids.id };
        })[0];
      const bankAccounts = ledgerGroupsData?.data
        .filter((item) => item.name === "Bank Accounts")
        .map((ids) => {
          return { uuid: ids.uuid, id: ids.id };
        })[0];
      const cashInHand = ledgerGroupsData?.data
        .filter((item) => item.name === "Cash-in-Hand")
        .map((ids) => {
          return { uuid: ids.uuid, id: ids.id };
        })[0];

      const sundryDebtorsIds = getChildrenIds(sundryDebtors.uuid);
      sundryDebtorsIds.push(sundryDebtors.uuid);

      const sundryCreditorsIds = getChildrenIds(sundryCreditors.uuid);
      sundryCreditorsIds.push(sundryCreditors.uuid);

      const bankAccountsIds = getChildrenIds(bankAccounts.uuid);
      bankAccountsIds.push(bankAccounts.uuid);

      const cashInHandIds = getChildrenIds(cashInHand.uuid);
      cashInHandIds.push(cashInHand.uuid);

      const filteredLedgers = ledgerData.data?.filter(
        (item) =>
          ![
            ...sundryDebtorsIds,
            ...sundryCreditorsIds,
            ...bankAccountsIds,
            ...cashInHandIds,
          ].includes(item.group)
      );

      let ledgerGroups = ledgerGroupsData?.data ?? [];
      let ledgersWithGroupData = filteredLedgers
        ?.map((ledger) => {
          let group = ledgerGroups?.find((item) => item.uuid === ledger.group);
          let master;
          if (group) {
            master = ledgerGroups?.find((item) => item.uuid === group.master);
          }
          return { ...ledger, group: group?.name, master: master?.name };
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      setPostingLedgersList(filteredLedgers || []);
    }
  }, [ledgerData, ledgerGroupsData]);

  useEffect(() => {
    if (postingLedger) {
      handleEdit(postingLedger?.id, {
        field: "ledger",
      });
    } else {
      handleEdit(null, {
        field: "ledger",
      });
    }
  }, [postingLedger]);

  useEffect(() => {
    if (values.ledger && postingLedger === null && ledgerData) {
      setPostingLedger(
        ledgerData.data?.find((i) => i.id === values.ledger) || null
      );
    }
  }, [values.ledger, ledgerData]);

  useEffect(() => {
    if (purchaseLedger) {
      handleEdit(purchaseLedger?.id, {
        field: "purchase_ledger",
      });
    } else {
      handleEdit(null, {
        field: "purchase_ledger",
      });
    }
  }, [purchaseLedger]);

  useEffect(() => {
    if (values.purchase_ledger && purchaseLedger === null && ledgerData) {
      setPurchaseLedger(
        ledgerData.data?.find((i) => i.id === values.purchase_ledger) || null
      );
    }
  }, [values.purchase_ledger, ledgerData]);

  useEffect(() => {
    if (
      values.cost_centres &&
      values.cost_centres?.length > 0 &&
      costCenters.length === 0 &&
      costCenterData
    ) {
      setCostCenters(
        costCenterData.data?.filter((i) =>
          values.cost_centres?.includes(i.id)
        ) || []
      );
    }
  }, [values.cost_centres, costCenterData]);

  useEffect(() => {
    if (costCenters) {
      const ids = costCenters?.map((i) => i.id);
      handleEdit(ids, {
        field: "cost_centres",
      });
    }
  }, [costCenters]);

  useEffect(() => {
    if (
      values.done_bys &&
      values.done_bys?.length > 0 &&
      doneBys.length === 0 &&
      doneByData
    ) {
      setDoneBys(
        doneByData.data?.filter((i) => values.done_bys?.includes(i.id)) || []
      );
    }
  }, [values.done_bys, doneByData]);

  useEffect(() => {
    if (doneBys) {
      const ids = doneBys?.map((i) => i.id);
      handleEdit(ids, {
        field: "done_bys",
      });
    }
  }, [doneBys]);

  useEffect(() => {
    setUnitList(unitData?.data);
  }, [unitData]);

  useEffect(() => {
    setStoreList(storeData?.data.results);
  }, [storeData]);

  useEffect(() => {
    if (clickKey?.keyA && !isLoading && !isSubmitted) {
      const validateResponse = validateForm();
      validateResponse.then((data) => {
        submitAction(data);
      });
    }
  }, [clickKey]);
  // short key

  saveAndPrintShortKey(setClickKey);

  const country = getLocalStore("AUTH_DETAILS") || "";
  const handleClick = (fieldId) => {
    if (fieldId <= 15) {
      setFieldInFocus(fieldId);
    }
  };

  useEffect(() => {
    // if (tabIndex === 4) {
    //   setTimeout(() => {
    //     costCenterRef.current?.focus();
    //   }, 200);
    // } else if (tabIndex === 1) {
    //   setTimeout(() => {
    //     spWithoutTaxRef.current?.focus();
    //   }, 200);
    // } else if (tabIndex === 0) {
    //   setTimeout(() => {
    //     nameRef.current?.focus();
    //   }, 200);
    // }
  }, [tabIndex]);

  //Tax Options added
  let taxOptions;

  switch (country.data.company.country) {
    case "INDIA":
      taxOptions = [
        { value: "0", label: "0" },
        { value: "0.25", label: "0.25%" },
        { value: "3", label: "3%" },
        { value: "5", label: "5%" },
        { value: "12", label: "12%" },
        { value: "18", label: "18%" },
        { value: "28", label: "28%" },
      ];
      break;
    case "SAUDI":
      taxOptions = [
        { value: "0", label: "0" },
        { value: "15", label: "15%" },
      ];
      break;
    case "OMAN":
      taxOptions = [
        { value: "0", label: "0" },
        { value: "5", label: "5%" },
      ];
      break;
    case "UAE":
      taxOptions = [
        { value: "0", label: "0" },
        { value: "5", label: "5%" },
      ];
      break;
    case "BAHRAIN":
      taxOptions = [
        { value: "0", label: "0" },
        { value: "5", label: "5%" },
        { value: "10", label: "10%" },
      ];
      break;
    default:
      taxOptions = [
        { value: "0", label: "0" },
        { value: "0.25", label: "0.25%" },
        { value: "3", label: "3%" },
        { value: "5", label: "5%" },
        { value: "7", label: "7%" },
        { value: "12", label: "12%" },
        { value: "15", label: "15%" },
        { value: "18", label: "18%" },
        { value: "28", label: "28%" },
        { value: "-1", label: "Exempted" },
      ];
      break;
  }

  useEffect(() => {
    if (
      values?.price_categories &&
      values?.price_categories.length > 0 &&
      selectedPriceCategory.length === 0 &&
      priceCategoryNameData &&
      voucherTypeData
    ) {
      let categories = values.price_categories.map((item, index) => {
        const actualValue = priceCategoryNameData?.data?.find(
          (i) => i.id === item.category
        );
        return {
          ...item,
          categoryName: actualValue.name,
          voucher_group: voucherTypeData?.data?.filter((f) =>
            item.voucher_group?.includes(f.id)
          ),
          index,
        };
      });
      setSelectedPriceCategory(categories);
    }
  }, [values?.price_categories, priceCategoryNameData, voucherTypeData]);

  const handleEditCategorySubmit = (category) => {
    let updatedCategory = {
      ...category,
    };

    let index =
      updatedCategory.index !== undefined
        ? updatedCategory.index
        : selectedPriceCategory.length;

    let categories = [
      ...selectedPriceCategory.filter((i) => i.index !== updatedCategory.index),
      { ...updatedCategory, index: index },
    ];
    let sorted = categories.sort((a, b) => (a.index > b.index ? 1 : -1));

    setSelectedPriceCategory(sorted);
    setEditingCategory(null);
    priceCategoryModal.onClose();
  };

  const handleEditCategory = (item) => {
    setEditingCategory(item);
    priceCategoryModal.onOpen();
  };

  const handleDeleteCategory = (item) => {
    let categories = selectedPriceCategory
      .filter((i) => i.index !== item.index)
      .map((item, index) => ({ ...item, index: index }));
    setSelectedPriceCategory(categories);
  };

  useEffect(() => {
    if (selectedPriceCategory) {
      const categoryWithoutIndex = selectedPriceCategory?.map(
        ({ index, ...rest }) => ({
          ...rest,
          voucher_group: rest.voucher_group?.map((item) => item.id),
        })
      );
      handleEdit(categoryWithoutIndex ? categoryWithoutIndex : [], {
        field: "price_categories",
      });
    }
  }, [selectedPriceCategory]);

  const handlePriceCategoryOnClose = () => {
    setEditingCategory(null);
    priceCategoryModal.onClose();
  };

  useEffect(() => {
    setHasExecuted(false);
  }, []);

  useEffect(() => {
    if (
      values?.secondary_units &&
      values?.secondary_units.length > 0 &&
      selectedMultiUnits.length === 0
    ) {
      let multiUnits = values.secondary_units.map((item, index) => {
        return {
          ...item,
          index,
        };
      });

      setSelectedMultiUnits(multiUnits);
    }
  }, [values?.secondary_units, priceCategoryNameData]);

  const handleEditMultiUnitSubmit = (multiUnit) => {
    let updatedUnit = {
      ...multiUnit,
    };
    let index =
      updatedUnit.index !== undefined
        ? updatedUnit.index
        : selectedMultiUnits.length;

    let multiUnits = [
      ...selectedMultiUnits.filter((i) => i.index !== updatedUnit.index),
      { ...updatedUnit, index: index },
    ];
    let sorted = multiUnits.sort((a, b) => (a.index > b.index ? 1 : -1));

    setSelectedMultiUnits(sorted);
    setEditingMultiUnit(null);
    multiUnitModal.onClose();
  };

  const handleEditMultiUnits = (item) => {
    setEditingMultiUnit(item);
    multiUnitModal.onOpen();
  };

  const handleDeleteMultiUnits = (item) => {
    let categories = selectedMultiUnits
      .filter((i) => i.index !== item.index)
      .map((item, index) => ({ ...item, index: index }));
    setSelectedMultiUnits(categories);
  };

  useEffect(() => {
    if (selectedMultiUnits && selectedMultiUnits.length > 0) {
      const multiUnitsWithoutIndex = selectedMultiUnits.map(
        ({ index, ...rest }) => ({
          ...rest,
        })
      );
      handleEdit(multiUnitsWithoutIndex, {
        field: "secondary_units",
      });
    }
  }, [selectedMultiUnits]);

  //min max stock --start

  useEffect(() => {
    if (
      values?.min_max_stock &&
      values?.min_max_stock.length > 0 &&
      selectedMinMaxStock.length === 0
    ) {
      let categories = values.min_max_stock?.map((item, index) => {
        return {
          ...item,
          index,
        };
      });
      setSelectedMinMaxStock(categories || []);
    }
  }, [values?.min_max_stock]);

  const handleEditMinMaxStockSubmit = (category) => {
    let updatedMinMaxStock = {
      ...category,
    };

    let index =
      updatedMinMaxStock.index !== undefined
        ? updatedMinMaxStock.index
        : selectedMinMaxStock.length;

    let categories = [
      ...selectedMinMaxStock.filter(
        (i) => i.index !== updatedMinMaxStock.index
      ),
      { ...updatedMinMaxStock, index: index },
    ];
    let sorted = categories.sort((a, b) => (a.index > b.index ? 1 : -1));

    setSelectedMinMaxStock(sorted);
    setEditingMinMaxStock(null);
    minMaxStockModal.onClose();
  };

  const handleEditMinMaxStock = (item) => {
    setEditingMinMaxStock(item);
    minMaxStockModal.onOpen();
  };

  const handleDeleteMinMaxStock = (item) => {
    let categories = selectedMinMaxStock
      .filter((i) => i.index !== item.index)
      .map((item, index) => ({ ...item, index: index }));
    setSelectedMinMaxStock(categories);
  };

  useEffect(() => {
    if (selectedMinMaxStock) {
      const minMaxStockWithoutIndex = selectedMinMaxStock?.map(
        ({ index, ...rest }) => ({
          ...rest,
        })
      );
      handleEdit(minMaxStockWithoutIndex ? minMaxStockWithoutIndex : [], {
        field: "min_max_stock",
      });

      const maxStockAmount = minMaxStockWithoutIndex
        ? minMaxStockWithoutIndex.reduce(
            (total, obj) => _parseFloat(obj.maximum_stock) + total,
            0
          )
        : 0;

      const minStockAmount = minMaxStockWithoutIndex
        ? minMaxStockWithoutIndex.reduce(
            (total, obj) => _parseFloat(obj.minimum_stock) + total,
            0
          )
        : 0;

      handleEdit(maxStockAmount, {
        field: "maximum_stock",
      });
      handleEdit(minStockAmount, {
        field: "minimum_stock",
      });
    }
  }, [selectedMinMaxStock]);

  const handleMinMaxStockOnClose = () => {
    setEditingMinMaxStock(null);
    minMaxStockModal.onClose();
  };

  const getUnitName = (unitId) => {
    if (unitId) {
      const unit = unitList?.find((item) => Number(item.id) === Number(unitId));
      return unit?.unit_symbol;
    }
  };

  const getStoreName = (storeId) => {
    if (storeId) {
      const store = storeList?.find(
        (item) => Number(item.id) === Number(storeId)
      );
      return store?.name;
    }
  };

  const onCloseMultiUnitModal = () => {
    setEditingMultiUnit(null);
    multiUnitModal.onClose();
  };

  const openLedger = (ledger) => {
    if (ledger) {
      const newWindow = window.open(`/ledger-report`, "_blank");
      newWindow.ledger = ledger;
    }
  };

  const [ledgerEditValue, setLedgerEditValue] = useState(null);
  const ledgerEditModal = useDisclosure();

  const renderMoreOptions = (value) => {
    return (
      <Box display="flex" gap={2}>
        <IconButton
          aria-label="view"
          icon={<Icon as={FiEye} />}
          size="sm"
          color="blue.600"
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            openLedger(value);
          }}
        />
        <IconButton
          aria-label="edit"
          icon={<Icon as={FiEdit3} />}
          size="sm"
          color="orange.600"
          variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            setLedgerEditValue(value);
            ledgerEditModal.onOpen();
          }}
        />
      </Box>
    );
  };

  return (
    <>
      {!isMobile ? (
        <IModalOut>
          <IHeadFav
            text={
              isApproval
                ? "Approve Stock Item - " + values.name
                : type === "edit"
                ? "Edit Stock Item - " + values.name
                : "Add Stock Item"
            }
            onClick={handleFavourite}
            isFavour={isToggledFav}
          />
          <Tabs
            isFitted
            onChange={(index) => setTabIndex(index)}
            colorScheme="blue"
            index={tabIndex}
          >
            <TabList mb={3}>
              <ITab tooltipLabel="f1">Basic Info</ITab>
              <ITab tooltipLabel="f2">Rates & Quantity’s</ITab>
              <ITab tooltipLabel="f3">Price Categories</ITab>
              <ITab tooltipLabel="f4">Multi Unit</ITab>
              <ITab tooltipLabel="f5">MinMax Stock</ITab>
              <ITab tooltipLabel="f6">Other Info</ITab>
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                <IModalIn>
                  <FormControl id="name">
                    <ITextField
                      disabled={isApproval}
                      label="Item Name"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      onChange={(event) =>
                        handleEdit(event.target.value, { field: "name" })
                      }
                      onFocus={(e) => {
                        if (e) {
                          handleClick(0);
                        }
                      }}
                      inputRef={nameRef}
                      value={values.name || ""}
                      isRequired={true}
                      isValid={isNotValid}
                      errorMessage={errors.name}
                      buttonClick={buttonClick}
                    />
                  </FormControl>
                  <IHStack>
                    <TextField
                      disabled={isApproval}
                      label="Alias Name"
                      variant="outlined"
                      size="small"
                      type="text"
                      fullWidth
                      value={values.name_alias || ""}
                      onFocus={(e) => {
                        if (e) {
                          handleClick(1);
                        }
                      }}
                      inputRef={aliasRef}
                      onChange={(event) =>
                        handleEdit(event.target.value, {
                          field: "name_alias",
                        })
                      }
                    >
                      {errors.name_alias && !isNotValid && (
                        <Message type="error" message={errors.name_alias} />
                      )}
                    </TextField>
                    <ISelect
                      width="100px"
                      disabled={isApproval}
                      placeholder="Tax %"
                      onChange={(event) => {
                        setTaxValue(event.target.value);
                        handleEdit(event.target.value, { field: "tax" });
                      }}
                      onFocus={(e) => {
                        if (e) {
                          handleClick(2);
                        }
                      }}
                      name="tax"
                      value={tax || ""}
                      as={Select}
                      inputRef={taxRef}
                      id="tax"
                      errorMessage={errors.tax}
                      isRequired={true}
                      isValid={errors.tax === undefined}
                      buttonClick={buttonClick}
                    >
                      {taxOptions.map((taxOption, key) => (
                        <IOption value={taxOption.value} key={key}>
                          {taxOption.label}
                        </IOption>
                      ))}
                    </ISelect>
                  </IHStack>
                  <Grid
                    autoRows="auto"
                    templateColumns="repeat(3, 1fr)"
                    columnGap={4}
                  >
                    <GridItem colSpan={1}>
                      <UnitAutoComplete
                        selectedUnit={values.unit}
                        setSelectedUnit={(newValue) => {
                          handleEdit(newValue, { field: "unit" });
                        }}
                        inputRef={unitRef}
                        onFocus={(e) => {
                          if (e) {
                            handleClick(3);
                          }
                        }}
                        disabled={isApproval}
                        setIsOpen={setIsOpen}
                        textFieldClick={(e) => {
                          if (e) {
                            handleClick(3);
                          }
                        }}
                      ></UnitAutoComplete>
                      {errors.unit && !isNotValid && (
                        <Message type="error" message={errors.unit} />
                      )}
                    </GridItem>

                    <GridItem colSpan={1}>
                      <GroupAutoComplete
                        selectedGroup={values.group}
                        setSelectedGroup={(newValue) => {
                          handleEdit(newValue, { field: "group" });
                        }}
                        inputRef={itemgroupRef}
                        setIsOpen={setIsOpen}
                        textFieldClick={(e) => {
                          if (e) {
                            handleClick(4);
                          }
                        }}
                        disabled={isApproval}
                      ></GroupAutoComplete>
                      {errors.group && !isNotValid && (
                        <Message type="error" message={errors.group} />
                      )}
                    </GridItem>
                    <GridItem colSpan={1}>
                      <CategoryAutoComplete
                        selectedCategory={values.category}
                        setSelectedCategory={(newValue) => {
                          handleEdit(newValue, { field: "category" });
                        }}
                        inputRef={categoryRef}
                        setIsOpen={setIsOpen}
                        textFieldClick={(e) => {
                          if (e) {
                            handleClick(5);
                          }
                        }}
                        disabled={isApproval}
                      ></CategoryAutoComplete>
                      {errors.category && !isNotValid && (
                        <Message type="error" message={errors.category} />
                      )}
                    </GridItem>
                  </Grid>
                  <FormControl>
                    <ITextarea
                      disabled={isApproval}
                      placeholder="Item Description"
                      onFocus={(e) => {
                        if (e) {
                          handleClick(6);
                        }
                      }}
                      // inputRef={descriptionRef}
                      inputRef={descriptionRef}
                      onChange={(event) =>
                        handleEdit(event.target.value, {
                          field: "description",
                        })
                      }
                      value={values.description || ""}
                      onKeyDown={(event) => {
                        if (event.key === "ArrowDown") {
                          handleEdit(values.description + "\n", {
                            field: "description",
                          });
                        }
                        if (
                          event.key === "Enter" &&
                          !showMore &&
                          !hasExecuted
                        ) {
                          const validateResponse = validateForm();
                          validateResponse.then((data) => {
                            submitAction(data);
                            setHasExecuted(true);
                          });
                        }
                      }}
                    ></ITextarea>
                  </FormControl>
                  <Stack direction="row" marginRight={4}>
                    <Spacer />
                    <Link
                      sx={{
                        "&:hover": {
                          textDecoration: "none",
                        },
                      }}
                      onClick={() => setShowMore(!showMore)}
                    >
                      <Text
                        fontSize="xs"
                        color="brand.bg_blue"
                        sx={{
                          "&:hover": {
                            textColor: "blue.500",
                          },
                        }}
                      >
                        Show {showMore ? "Less" : "More"}
                        {showMore ? <ArrowUpIcon /> : <ArrowDownIcon />}
                      </Text>
                    </Link>
                  </Stack>
                  {showMore ? (
                    <>
                      <Grid
                        autoRows="auto"
                        templateColumns="repeat(3, 1fr)"
                        columnGap={4}
                      >
                        <GridItem colSpan={1}>
                          <FormControl id="item_code">
                            <TextField
                              label="Item Code"
                              variant="outlined"
                              size="small"
                              type="text"
                              onChange={(event) =>
                                handleEdit(event.target.value, {
                                  field: "item_code",
                                })
                              }
                              value={values.item_code || ""}
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(7);
                                }
                              }}
                              inputRef={itemcodRef}
                              disabled={isApproval}
                            />
                            {errors.item_code && !isNotValid && (
                              <Message
                                type="error"
                                message={errors.item_code}
                              />
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <TextField
                            label="Bar Code"
                            variant="outlined"
                            size="small"
                            type="text"
                            onFocus={(e) => {
                              if (e) {
                                handleClick(8);
                              }
                            }}
                            inputRef={barcodeRef}
                            onChange={(event) =>
                              handleEdit(event.target.value, {
                                field: "bar_code",
                              })
                            }
                            value={values.bar_code || ""}
                            disabled={isApproval}
                          >
                            {errors.bar_code && !isNotValid && (
                              <Message type="error" message={errors.bar_code} />
                            )}
                          </TextField>
                        </GridItem>
                        {/* HSN Code */}
                        <GridItem colSpan={1}>
                          <TextField
                            label="HSN Code"
                            variant="outlined"
                            size="small"
                            type="text"
                            onFocus={(e) => {
                              if (e) {
                                handleClick(9);
                              }
                            }}
                            inputRef={hsnCodeRef}
                            onChange={(event) =>
                              handleEdit(event.target.value, {
                                field: "hsn_number",
                              })
                            }
                            value={values.hsn_number || ""}
                            disabled={isApproval}
                          >
                            {errors.hsn_number && !isNotValid && (
                              <Message
                                type="error"
                                message={errors.hsn_number}
                              />
                            )}
                          </TextField>
                        </GridItem>
                        <GridItem colSpan={1}></GridItem>
                      </Grid>
                      <FormLabel>
                        <Text>Opening Stock Details</Text>
                      </FormLabel>
                      <Grid
                        autoRows="auto"
                        templateColumns="repeat(3, 1fr)"
                        columnGap={4}
                      >
                        <GridItem colSpan={1}>
                          <FormControl id="opening_stock">
                            <TextField
                              label="Opening Qty"
                              variant="outlined"
                              size="small"
                              type="number"
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(10);
                                }
                              }}
                              disabled={isApproval}
                              inputRef={openingQtyRef}
                              onChange={(event) => {
                                let openingQtyValue = event.target.value
                                  ? event.target.value
                                  : "0";
                                if (
                                  openingQtyValue?.length > 1 &&
                                  openingQtyValue[0] === "0"
                                )
                                  openingQtyValue = openingQtyValue.slice(1);
                                handleEdit(checkDecimal(openingQtyValue), {
                                  field: "opening_stock",
                                });
                                let opening_stock_value = checkDecimal(
                                  values.cost_price * event.target.value
                                );
                                handleEdit(
                                  opening_stock_value
                                    ? opening_stock_value
                                    : "0",
                                  {
                                    field: "opening_stock_value",
                                  }
                                );
                              }}
                              value={values.opening_stock || ""}
                            />
                            {errors.opening_stock && !isNotValid && (
                              <Message
                                type="error"
                                message={errors.opening_stock}
                              />
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <FormControl id="cost_price">
                            <TextField
                              label="Cost Price"
                              variant="outlined"
                              size="small"
                              type="number"
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(11);
                                }
                              }}
                              disabled={isApproval}
                              inputRef={costPriceRef}
                              onChange={(event) => {
                                let costPriceValue = event.target.value
                                  ? event.target.value
                                  : "0";
                                if (
                                  costPriceValue?.length > 1 &&
                                  costPriceValue[0] === "0"
                                )
                                  costPriceValue = costPriceValue.slice(1);
                                handleEdit(checkDecimal(costPriceValue), {
                                  field: "cost_price",
                                });
                                let opening_stock_value = checkDecimal(
                                  values.opening_stock * event.target.value
                                );
                                handleEdit(
                                  opening_stock_value
                                    ? opening_stock_value
                                    : "0",
                                  {
                                    field: "opening_stock_value",
                                  }
                                );
                              }}
                              value={values.cost_price || ""}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  const validateResponse = validateForm();
                                  validateResponse.then((data) => {
                                    submitAction(data);
                                  });
                                }
                              }}
                            />
                            {errors.cost_price && !isNotValid && (
                              <Message
                                type="error"
                                message={errors.cost_price}
                              />
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <FormControl id="opening_stock_value">
                            <TextField
                              label="Stock Value"
                              variant="outlined"
                              size="small"
                              type="number"
                              disabled
                              value={values.opening_stock_value || ""}
                            />
                            {errors.opening_stock_value && !isNotValid && (
                              <Message
                                type="error"
                                message={errors.opening_stock_value}
                              />
                            )}
                          </FormControl>
                        </GridItem>
                      </Grid>
                      <Checkbox
                        disabled={isApproval}
                        mt="10px"
                        ml="auto"
                        mr="45px"
                        isChecked={suspand}
                        onChange={(e) => {
                          setSuspand(!suspand);
                        }}
                      >
                        Required Suspend
                      </Checkbox>
                    </>
                  ) : (
                    ""
                  )}
                </IModalIn>
              </TabPanel>
              <TabPanel p={0}>
                <IModalIn>
                  <IHStack>
                    <Spacer />
                    <Checkbox
                      isChecked={showTax}
                      onChange={(e) => {
                        setShowTax(!showTax);
                      }}
                    >
                      Show With Tax
                    </Checkbox>
                    <Checkbox
                      isChecked={showMin}
                      onChange={(e) => {
                        setShowMin(!showMin);
                      }}
                    >
                      Show Min And Max
                    </Checkbox>
                  </IHStack>
                  <FormLabel>
                    <Text>Selling Price Without Tax</Text>
                  </FormLabel>
                  <Grid
                    autoRows="auto"
                    templateColumns="repeat(3, 1fr)"
                    columnGap={4}
                  >
                    <GridItem colSpan={1}>
                      <FormControl id="price">
                        <TextField
                          label="S P Without Tax"
                          variant="outlined"
                          size="small"
                          type="number"
                          onFocus={(e) => {
                            if (e) {
                              handleClick(0);
                              // spWithoutTaxRef.current.select();
                            }
                          }}
                          disabled={isApproval}
                          inputRef={spWithoutTaxRef}
                          onChange={(event) => {
                            setPriceValue(event.target.value);
                            handleEdit(event.target.value, {
                              field: "price",
                            });
                          }}
                          value={price || ""}
                        />
                        {errors.price && !isNotValid && (
                          <Message type="error" message={errors.price} />
                        )}
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <TextField
                          // label="Sale Discount"
                          label="Discount Without Tax"
                          variant="outlined"
                          size="small"
                          type="text"
                          onFocus={(e) => {
                            if (e) {
                              handleClick(1);
                              // spDiscountWithoutTaxRef.current.select();
                            }
                          }}
                          disabled={isApproval}
                          inputRef={spDiscountWithoutTaxRef}
                          onChange={(event) =>
                            setDiscountWithOutTaxValue(event.target.value)
                          }
                          value={
                            discountWithOutTax
                              ? discountWithOutTax
                              : editValue?.sale_discount_with_tax === 0
                              ? editValue?.sale_discount_with_tax
                              : "" || ""
                          }
                        >
                          {errors.sale_discount && !isNotValid && (
                            <Message
                              type="error"
                              message={errors.sale_discount}
                            />
                          )}
                        </TextField>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <TextField
                          disabled
                          label="Net Amount"
                          variant="outlined"
                          size="small"
                          type="text"
                          value={
                            _parseFloat(
                              price -
                                (editValue?.sale_discount
                                  ? editValue?.sale_discount
                                  : discountWithOutTax)
                            ).toFixed(2) || ""
                          }
                        >
                          {errors.sale_discount && !isNotValid && (
                            <Message
                              type="error"
                              message={errors.sale_discount}
                            />
                          )}
                        </TextField>
                      </FormControl>
                    </GridItem>
                  </Grid>
                  {showTax ? (
                    <>
                      <FormLabel>
                        <Text>Selling Price With Tax</Text>
                      </FormLabel>
                      <Grid
                        autoRows="auto"
                        templateColumns="repeat(3, 1fr)"
                        columnGap={2}
                        rowGap={2}
                      >
                        <GridItem colSpan={1}>
                          <FormControl id="priceWithTax">
                            <TextField
                              label="S P With Tax"
                              variant="outlined"
                              size="small"
                              type="number"
                              onChange={(event) =>
                                setPriceWithTaxValue(event.target.value)
                              }
                              disabled={isApproval}
                              value={priceWithTax || ""}
                              inputRef={spWithTaxRef}
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(2);
                                  // spWithTaxRef.current.select();
                                }
                              }}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <FormControl>
                            <TextField
                              label="Discount With Tax"
                              variant="outlined"
                              size="small"
                              type="text"
                              inputRef={spDiscountWithTaxRef}
                              onChange={(event) =>
                                setDiscountWithTaxValue(event.target.value)
                              }
                              disabled={isApproval}
                              value={
                                discountWithTax
                                  ? discountWithTax
                                  : editValue?.sale_discount_with_tax === 0
                                  ? editValue?.sale_discount_with_tax
                                  : "" || ""
                              }
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(3);
                                  // spDiscountWithTaxRef.current.select();
                                }
                              }}
                            >
                              {errors.sale_discount && !isNotValid && (
                                <Message
                                  type="error"
                                  message={errors.sale_discount}
                                />
                              )}
                            </TextField>
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <FormControl>
                            <TextField
                              disabled
                              label="Net Amount"
                              variant="outlined"
                              size="small"
                              type="text"
                              value={
                                _parseFloat(
                                  priceWithTax - discountWithTax
                                ).toFixed(2) || ""
                              }
                            >
                              {errors.sale_discount && !isNotValid && (
                                <Message
                                  type="error"
                                  message={errors.sale_discount}
                                />
                              )}
                            </TextField>
                          </FormControl>
                        </GridItem>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                  <FormLabel>
                    <Text>Purchase Price Without Tax</Text>
                  </FormLabel>
                  <Grid
                    autoRows="auto"
                    templateColumns="repeat(3, 1fr)"
                    columnGap={4}
                  >
                    <GridItem colSpan={1}>
                      <FormControl id=")p_price">
                        <TextField
                          fullWidth
                          label="P P Without Tax"
                          variant="outlined"
                          size="small"
                          type="number"
                          onChange={(event) => {
                            handleEdit(event.target.value, {
                              field: "purchase_price_without_tax",
                            });
                            purchasePriceWithoutTaxValue(event.target.value);
                          }}
                          value={
                            purchasePriceWithOutTax
                              ? purchasePriceWithOutTax
                              : editValue?.purchase_price_without_tax === "0"
                              ? editValue?.purchase_price_without_tax
                              : "" || ""
                          }
                          disabled={isApproval}
                          inputRef={ppWithoutTaxRef}
                          onFocus={(e) => {
                            if (e) {
                              handleClick(4);
                            }
                          }}
                        />
                        {errors.price && !isNotValid && (
                          <Message type="error" message={errors.price} />
                        )}
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <TextField
                          // label="Sale Discount"
                          label="Discount Without Tax"
                          variant="outlined"
                          size="small"
                          type="text"
                          onFocus={(e) => {
                            if (e) {
                              handleClick(5);
                            }
                          }}
                          disabled={isApproval}
                          onChange={(event) => {
                            handleEdit(event.target.value, {
                              field: "sale_discount",
                            });
                            purchasePriceDiscountWithoutTaxValue(
                              event.target.value
                            );
                          }}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              if (!showMin && !showTax) {
                                const validateResponse = validateForm();
                                validateResponse.then((data) => {
                                  submitAction(data);
                                });
                              }
                            }
                          }}
                          value={
                            purchasePriceDiscountWithoutTax
                              ? purchasePriceDiscountWithoutTax
                              : editValue?.purchase_discount === 0
                              ? editValue?.purchase_discount
                              : "" || ""
                          }
                          inputRef={ppDiscountWithoutTaxRef}
                        >
                          {errors.sale_discount && !isNotValid && (
                            <Message
                              type="error"
                              message={errors.sale_discount}
                            />
                          )}
                        </TextField>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <TextField
                          disabled
                          label="Net Amount"
                          variant="outlined"
                          size="small"
                          type="text"
                          value={
                            _parseFloat(
                              (editValue?.purchase_price_without_tax
                                ? editValue?.purchase_price_without_tax
                                : purchasePriceWithOutTax) -
                                (editValue?.purchase_discount
                                  ? editValue?.purchase_discount
                                  : purchasePriceDiscountWithoutTax)
                            ).toFixed(2) || ""
                          }
                        >
                          {errors.sale_discount && !isNotValid && (
                            <Message
                              type="error"
                              message={errors.sale_discount}
                            />
                          )}
                        </TextField>
                      </FormControl>
                    </GridItem>
                  </Grid>
                  {showTax ? (
                    <>
                      <FormLabel>
                        <Text>Purchase Price With Tax</Text>
                      </FormLabel>
                      <Grid
                        autoRows="auto"
                        templateColumns="repeat(3, 1fr)"
                        columnGap={2}
                        rowGap={2}
                      >
                        <GridItem colSpan={1}>
                          <FormControl id="purchase_price_with_tax">
                            <TextField
                              fullWidth
                              label="P P With Tax"
                              variant="outlined"
                              size="small"
                              type="number"
                              onChange={(event) => {
                                handleEdit(event.target.value, {
                                  field: "purchase_price_with_tax",
                                });
                                purchasePriceWithTaxValue(event.target.value);
                              }}
                              disabled={isApproval}
                              value={purchasePriceWithTax || ""}
                              inputRef={ppWithTaxRef}
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(6);
                                  ppWithTaxRef.current.select();
                                }
                              }}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <TextField
                              // label="Sale Discount"
                              label="Discount With Tax"
                              variant="outlined"
                              size="small"
                              type="text"
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(7);
                                  ppDiscountWithTaxRef.current.select();
                                }
                              }}
                              disabled={isApproval}
                              inputRef={ppDiscountWithTaxRef}
                              onChange={(event) => {
                                handleEdit(event.target.value, {
                                  field: "sale_discount",
                                });
                                purchasePriceDiscountWithTaxValue(
                                  event.target.value
                                );
                              }}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  if (!showMin && showTax) {
                                    const validateResponse = validateForm();
                                    validateResponse.then((data) => {
                                      submitAction(data);
                                    });
                                  }
                                }
                              }}
                              value={purchasePriceDiscountWithTax || ""}
                              // let taxAmount = (price * taxPercentage) / 100;
                            >
                              {errors.sale_discount && !isNotValid && (
                                <Message
                                  type="error"
                                  message={errors.sale_discount}
                                />
                              )}
                            </TextField>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <TextField
                              disabled
                              label="Net Amount"
                              variant="outlined"
                              size="small"
                              type="text"
                              value={
                                _parseFloat(
                                  purchasePriceWithTax -
                                    purchasePriceDiscountWithTax
                                ).toFixed(2) || ""
                              }
                            >
                              {errors.sale_discount && !isNotValid && (
                                <Message
                                  type="error"
                                  message={errors.sale_discount}
                                />
                              )}
                            </TextField>
                          </FormControl>
                        </GridItem>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                  {/* <Box p={2} pt={0} pr={4}>
                  <FormLabel>
                    <Text>Purchase Price Without Tax</Text>
                  </FormLabel>
                  <Grid
                    autoRows="auto"
                    templateColumns="repeat(3, 1fr)"
                    columnGap={2}
                    rowGap={2}
                  >
                    <GridItem colSpan={1}>
                      <FormControl id=")p_price">
                        <TextField
                          fullWidth
                          label="P P Without Tax"
                          variant="outlined"
                          size="small"
                          type="number"
                          onChange={(event) => {
                            handleEdit(event.target.value, {
                              field: "purchase_price_without_tax",
                            });
                            purchasePriceWithoutTaxValue(event.target.value);
                          }}
                          value={
                            purchasePriceWithOutTax
                              ? purchasePriceWithOutTax
                              : editValue?.purchase_price_without_tax === "0"
                              ? editValue?.purchase_price_without_tax
                              : "" || ""
                          }
                          inputRef={ppWithoutTaxRef}
                          onFocus={(e) => {
                            if (e) {
                              handleClick(6);
                            }
                          }}
                        />
                        {errors.price && !isNotValid && (
                          <Message type="error" message={errors.price} />
                        )}
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <TextField
                          // label="Sale Discount"
                          label="Discount Without Tax"
                          variant="outlined"
                          size="small"
                          type="text"
                          onFocus={(e) => {
                            if (e) {
                              handleClick(7);
                            }
                          }}
                          onChange={(event) => {
                            handleEdit(event.target.value, {
                              field: "sale_discount",
                            });
                            purchasePriceDiscountWithoutTaxValue(
                              event.target.value
                            );
                          }}
                          value={
                            purchasePriceDiscountWithoutTax
                              ? purchasePriceDiscountWithoutTax
                              : editValue?.purchase_discount === 0
                              ? editValue?.purchase_discount
                              : "" || ""
                          }
                          inputRef={ppDiscountWithoutTaxRef}
                        >
                          {errors.sale_discount && !isNotValid && (
                            <Message
                              type="error"
                              message={errors.sale_discount}
                            />
                          )}
                        </TextField>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <TextField
                          disabled
                          label="Net Amount"
                          variant="outlined"
                          size="small"
                          type="text"
                          value={
                            _parseFloat(
                              purchasePriceWithOutTax -
                                purchasePriceDiscountWithoutTax
                            ).toFixed(2) || ""
                          }
                        >
                          {errors.sale_discount && !isNotValid && (
                            <Message
                              type="error"
                              message={errors.sale_discount}
                            />
                          )}
                        </TextField>
                      </FormControl>
                    </GridItem>
                  </Grid>
                </Box> */}
                  {showMin ? (
                    <>
                      <Box p={2} pt={0} pr={4}>
                        <FormLabel>
                          <Text>Min And Max Price</Text>
                        </FormLabel>
                        <Grid
                          autoRows="auto"
                          templateColumns="repeat(3, 1fr)"
                          columnGap={2}
                          rowGap={2}
                        >
                          <GridItem colSpan={1}>
                            <FormControl id="maximum_retail_price">
                              <TextField
                                fullWidth
                                label="MRP"
                                variant="outlined"
                                size="small"
                                type="number"
                                onChange={(event) => {
                                  // setPriceValue(event.target.value);
                                  handleEdit(event.target.value, {
                                    field: "maximum_retail_price",
                                  });
                                }}
                                disabled={isApproval}
                                value={values.maximum_retail_price || ""}
                                inputRef={mrpRef}
                                onFocus={(e) => {
                                  if (e) {
                                    handleClick(8);
                                  }
                                }}
                              />
                              {errors.price && !isNotValid && (
                                <Message type="error" message={errors.price} />
                              )}
                            </FormControl>
                          </GridItem>
                          <GridItem colSpan={1}>
                            <FormControl>
                              <TextField
                                // label="Sale Discount"
                                label="Mini S P Without Tax"
                                variant="outlined"
                                size="small"
                                type="text"
                                onChange={(e) => {
                                  setMinSpWithoutTaxValue(e.target.value);
                                }}
                                value={
                                  minSpWithoutTax
                                    ? minSpWithoutTax
                                    : editValue?.minimum_selling_price_without_tax ===
                                      "0"
                                    ? editValue?.minimum_selling_price_without_tax
                                    : "" || ""
                                }
                                disabled={isApproval}
                                inputRef={miniSpWithoutTaxRef}
                                onFocus={(e) => {
                                  if (e) {
                                    handleClick(9);
                                  }
                                }}
                              >
                                {errors.sale_discount && !isNotValid && (
                                  <Message
                                    type="error"
                                    message={errors.sale_discount}
                                  />
                                )}
                              </TextField>
                            </FormControl>
                          </GridItem>
                          <GridItem colSpan={1}>
                            <FormControl>
                              <TextField
                                label="Mini S P With Tax"
                                variant="outlined"
                                size="small"
                                type="text"
                                onChange={(e) => {
                                  setMinSpWithTaxValue(e.target.value);
                                }}
                                disabled={isApproval}
                                value={minSpWithTax || ""}
                                inputRef={miniSpWithTaxRef}
                                onFocus={(e) => {
                                  if (e) {
                                    handleClick(10);
                                  }
                                }}
                              >
                                {errors.sale_discount && !isNotValid && (
                                  <Message
                                    type="error"
                                    message={errors.sale_discount}
                                  />
                                )}
                              </TextField>
                            </FormControl>
                          </GridItem>
                        </Grid>
                      </Box>
                      <Box p={2} pt={0} pr={4}>
                        <Grid
                          autoRows="auto"
                          templateColumns="repeat(2, 1fr)"
                          columnGap={2}
                          rowGap={2}
                        >
                          <GridItem colSpan={1}>
                            <FormControl>
                              <TextField
                                fullWidth
                                label="Max Purchase Price With Tax"
                                variant="outlined"
                                size="small"
                                type="text"
                                onChange={(e) => {
                                  setMaxPurchasePriceWithTaxValue(
                                    e.target.value
                                  );
                                }}
                                disabled={isApproval}
                                value={maxPurchasePriceWithTax || ""}
                                inputRef={maxPurchasePriceWithTaxRef}
                                onFocus={(e) => {
                                  if (e) {
                                    handleClick(11);
                                  }
                                }}
                              >
                                {errors.sale_discount && !isNotValid && (
                                  <Message
                                    type="error"
                                    message={errors.sale_discount}
                                  />
                                )}
                              </TextField>
                            </FormControl>
                          </GridItem>
                          <GridItem colSpan={1}>
                            <FormControl id="price">
                              <TextField
                                fullWidth
                                label="Max Purchase Price Without Tax"
                                variant="outlined"
                                size="small"
                                type="number"
                                onFocus={(e) => {
                                  if (e) {
                                    handleClick(12);
                                  }
                                }}
                                disabled={isApproval}
                                inputRef={maxPurchasePriceWithoutTaxRef}
                                onChange={(e) => {
                                  setMaxPurchasePriceWithoutTaxValue(
                                    e.target.value
                                  );
                                }}
                                value={
                                  maxPurchasePriceWithoutTax
                                    ? maxPurchasePriceWithoutTax
                                    : editValue?.maximum_purchase_price_without_tax ===
                                      "0"
                                    ? editValue?.maximum_purchase_price_without_tax
                                    : "" || ""
                                }
                                onKeyDown={(event) => {
                                  if (event.key === "Enter") {
                                    const validateResponse = validateForm();
                                    validateResponse.then((data) => {
                                      submitAction(data);
                                    });
                                  }
                                }}
                              />
                              {errors.price && !isNotValid && (
                                <Message type="error" message={errors.price} />
                              )}
                            </FormControl>
                          </GridItem>
                        </Grid>
                      </Box>
                    </>
                  ) : (
                    ""
                  )}
                </IModalIn>
              </TabPanel>
              <TabPanel p={0}>
                <IModalIn>
                  <Box w="100%" maxHeight="400px" overflowY="auto">
                    <Button
                      onClick={priceCategoryModal.onOpen}
                      h="30px"
                      colorScheme="blue"
                      variant="ghost"
                      type="submit"
                      size="lg"
                      minH={12}
                      disabled={isApproval}
                    >
                      Add Price Category (+)
                    </Button>

                    <Stack spacing={4} pt={2}>
                      {selectedPriceCategory &&
                        selectedPriceCategory.length > 0 && (
                          <TableContainer>
                            <Table
                              variant="simple"
                              size="sm"
                              width="100%"
                              style={{ tableLayout: "fixed" }}
                              // colorScheme="telegram"
                            >
                              <Thead>
                                <Tr>
                                  <Th
                                    position="sticky"
                                    top="0"
                                    zIndex="1"
                                    bg="blue.50"
                                    textColor="green.500"
                                    minWidth="120px"
                                  >
                                    Category
                                  </Th>
                                  <Th
                                    position="sticky"
                                    top="0"
                                    zIndex="1"
                                    bg="blue.50"
                                    textColor="green.500"
                                    w="120px"
                                  >
                                    Voucher Type
                                  </Th>
                                  <Th
                                    position="sticky"
                                    top="0"
                                    zIndex="1"
                                    bg="blue.50"
                                    isNumeric
                                    w="120px"
                                    textColor="green.500"
                                  >
                                    price
                                    <br />
                                    <Divider borderColor="gray.500" />
                                    price W.T
                                  </Th>
                                  <Th
                                    position="sticky"
                                    top="0"
                                    zIndex="1"
                                    bg="blue.50"
                                    isNumeric
                                    w="130px"
                                    textColor="green.500"
                                  >
                                    Discount
                                    <br />
                                    <Divider borderColor="gray.500" />
                                    discount W.T
                                  </Th>
                                  <Th
                                    position="sticky"
                                    top="0"
                                    zIndex="1"
                                    bg="blue.50"
                                    w="40px"
                                  ></Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {selectedPriceCategory.map((item, key) => (
                                  <Tr
                                    bg="white"
                                    key={key}
                                    _hover={{ bg: "gray.100" }}
                                  >
                                    <Td>{item.categoryName}</Td>
                                    <Td whiteSpace="normal">
                                      <Text>
                                        {item.voucher_group
                                          .map((v) => v.name)
                                          .join(", ")}
                                      </Text>
                                    </Td>
                                    <Td isNumeric>
                                      {_parseFloat(item.price).toFixed(
                                        decimalLength
                                      )}
                                      <br />
                                      <Divider borderColor="gray.500" />
                                      {_parseFloat(item.price_with_tax).toFixed(
                                        decimalLength
                                      )}
                                    </Td>
                                    <Td isNumeric>
                                      {_parseFloat(item.sale_discount).toFixed(
                                        decimalLength
                                      )}
                                      <br />
                                      <Divider borderColor="gray.500" />
                                      {_parseFloat(
                                        item.sale_discount_with_tax
                                      ).toFixed(decimalLength)}
                                    </Td>
                                    <Td>
                                      <Menu>
                                        <MenuButton
                                          as={IconButton}
                                          aria-label="Options"
                                          icon={<Icon as={FiMoreVertical} />}
                                          size="xs"
                                          variant="solid"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                          disabled={isApproval}
                                        />
                                        <MenuList>
                                          <MenuItem
                                            icon={<Icon as={FiEdit3}></Icon>}
                                            onClick={() =>
                                              handleEditCategory(item)
                                            }
                                          >
                                            Edit
                                          </MenuItem>
                                          <MenuItem
                                            color="red.500"
                                            icon={<Icon as={FiTrash2}></Icon>}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleDeleteCategory(item);
                                            }}
                                          >
                                            Delete
                                          </MenuItem>
                                        </MenuList>
                                      </Menu>
                                    </Td>
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          </TableContainer>
                        )}
                    </Stack>
                  </Box>
                </IModalIn>
              </TabPanel>
              <TabPanel pr="0" pl="0" pt="0">
                <Box pb={2} w="100%" maxHeight="400px" overflowY="auto">
                  <Button
                    onClick={multiUnitModal.onOpen}
                    h="30px"
                    colorScheme="blue"
                    variant="ghost"
                    type="submit"
                    size="lg"
                    minH={12}
                    disabled={isApproval}
                  >
                    Add Multi Unit (+)
                  </Button>

                  <Stack spacing={4} pt={2}>
                    {selectedMultiUnits && selectedMultiUnits.length > 0 && (
                      <TableContainer>
                        <Table
                          variant="simple"
                          size="sm"
                          width="100%"
                          style={{ tableLayout: "fixed" }}
                        >
                          <Thead>
                            <Tr>
                              <Th
                                position="sticky"
                                top="0"
                                zIndex="1"
                                bg="blue.50"
                                textColor="green.500"
                                w="50px"
                              >
                                Sl No
                              </Th>
                              <Th
                                position="sticky"
                                top="0"
                                zIndex="1"
                                bg="blue.50"
                                textColor="green.500"
                                w="100%"
                              >
                                Unit Symbol
                              </Th>
                              <Th
                                position="sticky"
                                top="0"
                                zIndex="1"
                                bg="blue.50"
                                textColor="green.500"
                                isNumeric
                                w="140px"
                              >
                                Conversion Rate
                              </Th>
                              <Th
                                position="sticky"
                                top="0"
                                zIndex="1"
                                bg="blue.50"
                                w="40px"
                              ></Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {selectedMultiUnits.map((item, key) => (
                              <Tr
                                bg="white"
                                key={key}
                                _hover={{ bg: "gray.100" }}
                              >
                                <Td>{item.index + 1}</Td>
                                <Td>{getUnitName(item.unit)}</Td>
                                <Td isNumeric>
                                  {parseFloat(item.rate).toFixed(decimalLength)}
                                </Td>
                                <Td>
                                  <Menu>
                                    <MenuButton
                                      as={IconButton}
                                      aria-label="Options"
                                      icon={<Icon as={FiMoreVertical} />}
                                      size="xs"
                                      variant="solid"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                      disabled={isApproval}
                                    />
                                    <MenuList>
                                      <MenuItem
                                        icon={<Icon as={FiEdit3}></Icon>}
                                        onClick={() =>
                                          handleEditMultiUnits(item)
                                        }
                                      >
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        color="red.500"
                                        icon={<Icon as={FiTrash2}></Icon>}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleDeleteMultiUnits(item);
                                        }}
                                      >
                                        Delete
                                      </MenuItem>
                                    </MenuList>
                                  </Menu>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    )}
                  </Stack>
                </Box>
              </TabPanel>

              <TabPanel p={0}>
                <IModalIn>
                  <Box w="100%" maxHeight="400px" overflowY="auto">
                    <Button
                      onClick={minMaxStockModal.onOpen}
                      h="30px"
                      colorScheme="blue"
                      variant="ghost"
                      type="submit"
                      size="lg"
                      minH={12}
                      disabled={isApproval}
                    >
                      Add Min Max Stock (+)
                    </Button>

                    <Stack spacing={4} pt={2}>
                      {selectedMinMaxStock?.length > 0 && (
                        <TableContainer>
                          <Table
                            variant="simple"
                            size="sm"
                            width="100%"
                            style={{ tableLayout: "fixed" }}
                          >
                            <Thead>
                              <Tr>
                                <Th
                                  position="sticky"
                                  top="0"
                                  zIndex="1"
                                  bg="blue.50"
                                  textColor="green.500"
                                  minWidth="120px"
                                >
                                  Store
                                </Th>
                                <Th
                                  position="sticky"
                                  top="0"
                                  zIndex="1"
                                  bg="blue.50"
                                  textColor="green.500"
                                  w="120px"
                                  isNumeric
                                >
                                  Min Stock
                                </Th>
                                <Th
                                  position="sticky"
                                  top="0"
                                  zIndex="1"
                                  bg="blue.50"
                                  textColor="green.500"
                                  w="120px"
                                  isNumeric
                                >
                                  Max Stock
                                </Th>
                                <Th
                                  position="sticky"
                                  top="0"
                                  zIndex="1"
                                  bg="blue.50"
                                  w="40px"
                                ></Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {selectedMinMaxStock?.map((item, index) => (
                                <Tr
                                  bg="white"
                                  key={index}
                                  _hover={{ bg: "gray.100" }}
                                >
                                  <Td>{getStoreName(item?.store)}</Td>
                                  <Td isNumeric>
                                    {_parseFloat(item?.minimum_stock).toFixed(
                                      decimalLength
                                    )}
                                  </Td>
                                  <Td isNumeric>
                                    {_parseFloat(item?.maximum_stock).toFixed(
                                      decimalLength
                                    )}
                                  </Td>
                                  <Td>
                                    <Menu>
                                      <MenuButton
                                        as={IconButton}
                                        aria-label="Options"
                                        icon={<Icon as={FiMoreVertical} />}
                                        size="xs"
                                        variant="solid"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        disabled={isApproval}
                                      />
                                      <MenuList>
                                        <MenuItem
                                          icon={<Icon as={FiEdit3}></Icon>}
                                          onClick={() =>
                                            handleEditMinMaxStock(item)
                                          }
                                        >
                                          Edit
                                        </MenuItem>
                                        <MenuItem
                                          color="red.500"
                                          icon={<Icon as={FiTrash2}></Icon>}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteMinMaxStock(item);
                                          }}
                                        >
                                          Delete
                                        </MenuItem>
                                      </MenuList>
                                    </Menu>
                                  </Td>
                                </Tr>
                              ))}
                            </Tbody>
                            <Tfoot>
                              {selectedMinMaxStock?.length > 0 && (
                                <Tr fontWeight="bold">
                                  <Td>Total</Td>
                                  <Td isNumeric>
                                    {parseFloat(
                                      selectedMinMaxStock
                                        ? selectedMinMaxStock.reduce(
                                            (total, obj) =>
                                              _parseFloat(obj.minimum_stock) +
                                              total,
                                            0
                                          )
                                        : 0
                                    ).toFixed(decimalLength)}
                                  </Td>
                                  <Td isNumeric>
                                    {parseFloat(
                                      selectedMinMaxStock
                                        ? selectedMinMaxStock.reduce(
                                            (total, obj) =>
                                              _parseFloat(obj.maximum_stock) +
                                              total,
                                            0
                                          )
                                        : 0
                                    ).toFixed(decimalLength)}
                                  </Td>
                                  <Td></Td>
                                </Tr>
                              )}
                            </Tfoot>
                          </Table>
                        </TableContainer>
                      )}
                    </Stack>
                  </Box>
                </IModalIn>
              </TabPanel>
              <TabPanel p={0}>
                <IModalIn>
                  <Grid
                    autoRows="auto"
                    templateColumns="repeat(2, 1fr)"
                    columnGap={4}
                    rowGap={4}
                  >
                    <GridItem colSpan={1}>
                      <AutocompleteWithAddOption
                        value={postingLedger}
                        options={postingLedgersList}
                        onChange={(newValue) => {
                          setPostingLedger(newValue || "");
                        }}
                        optionLabel="name"
                        inputLabel="Sale Posting Ledger"
                        freeSolo
                        clearOnBlur
                        disabled={isApproval}
                        disablePortal={true}
                        setIsOpen={() => {}}
                        setSearchData={() => {}}
                        voucherType={"addStockItem"}
                        renderMoreOptions={renderMoreOptions}
                        isLoading={isLoadingLedger}
                        inputRef={salePostingLedgerRef}
                        onFocuse={() => {
                          handleClick(0);
                        }}
                      >
                        <AddLedgerForm type={"add"} onAddSuccess={() => {}} />
                      </AutocompleteWithAddOption>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <AutocompleteWithAddOption
                        value={purchaseLedger}
                        options={postingLedgersList}
                        onChange={(newValue) => {
                          setPurchaseLedger(newValue || "");
                        }}
                        optionLabel="name"
                        inputLabel="Purchase Posting Ledger"
                        freeSolo
                        clearOnBlur
                        disabled={isApproval}
                        disablePortal={true}
                        setIsOpen={() => {}}
                        setSearchData={() => {}}
                        voucherType={"addStockItem"}
                        renderMoreOptions={renderMoreOptions}
                        isLoading={isLoadingLedger}
                        inputRef={purchasePostingLedgerRef}
                        onFocuse={() => {
                          handleClick(1);
                        }}
                      >
                        <AddLedgerForm type={"add"} onAddSuccess={() => {}} />
                      </AutocompleteWithAddOption>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <MultiSelectionField
                        placeholder="Cost Centers"
                        datas={costCenterData?.data}
                        options={costCenters}
                        setOptions={setCostCenters}
                        inputRef={costCenterRef}
                        onFocuse={() => {
                          handleClick(2);
                        }}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <MultiSelectionField
                        placeholder="Done Bys"
                        datas={doneByData?.data}
                        options={doneBys}
                        setOptions={setDoneBys}
                        inputRef={doneByRef}
                        onFocuse={() => {
                          handleClick(3);
                        }}
                      />
                    </GridItem>
                  </Grid>
                  <BrandAutoComplete
                    selectedBrand={values.brand}
                    setSelectedBrand={(newValue) => {
                      handleEdit(newValue, { field: "brand" });
                    }}
                    inputRef={brandRef}
                    setIsOpen={setIsOpen}
                    textFieldClick={(e) => {
                      if (e) {
                        handleClick(4);
                      }
                    }}
                    disabled={isApproval}
                  ></BrandAutoComplete>
                  {errors.brand && !isNotValid && (
                    <Message type="error" message={errors.brand} />
                  )}
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    templateRows="repeat(2, 1fr)"
                    columnGap={1}
                    rowGap={0}
                  >
                    <GridItem>
                      <Checkbox
                        disabled={isApproval}
                        ml="auto"
                        mt="10px"
                        mr="45px"
                        isChecked={false}
                        onChange={(e) => {
                          // setShowTrack(!showTrack);
                        }}
                      >
                        Restrict to change Sale price
                      </Checkbox>
                    </GridItem>
                    <GridItem>
                      <Checkbox
                        disabled={isApproval}
                        mt="10px"
                        ml="auto"
                        mr="45px"
                        isChecked={false}
                        onChange={(e) => {
                          // setIsService(!isService);
                        }}
                      >
                        Restrict to change Sale Discount
                      </Checkbox>
                    </GridItem>
                    <GridItem>
                      <Checkbox
                        disabled={isApproval}
                        ml="auto"
                        mt="10px"
                        mr="45px"
                        isChecked={showTrack}
                        onChange={(e) => {
                          setShowTrack(!showTrack);
                        }}
                      >
                        Not Required Track Closing Stock
                      </Checkbox>
                    </GridItem>
                    <GridItem>
                      <Checkbox
                        disabled={isApproval}
                        mt="10px"
                        ml="auto"
                        mr="45px"
                        isChecked={isService}
                        onChange={(e) => {
                          setIsService(!isService);
                        }}
                      >
                        Service Item
                      </Checkbox>
                    </GridItem>
                  </Grid>
                </IModalIn>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <IButtonOut>
            {isApproval && (
              <Tooltip
                hasArrow
                label="Reject"
                placement="bottom"
                bg="brand.bg_blue"
              >
                <Button
                  w="100%"
                  isLoading={isRejectLoading}
                  minH="40px"
                  type="submit"
                  size="lg"
                  variant="solid"
                  bg="red.600"
                  colorScheme="red"
                  onClick={() => {
                    rejectFunc();
                  }}
                >
                  Reject
                </Button>
              </Tooltip>
            )}
            <IButton
              onClick={() => {
                onSubmit();
              }}
              isLoading={isLoading}
              isApproval={isApproval}
              type={type}
            />
          </IButtonOut>

          {/* <PriceCategoryModal
            taxPercentage={tax}
            initialValue={editingCategory}
            isOpen={priceCategoryModal.isOpen}
            onClose={priceCategoryModal.onClose}
            onSubmit={handleEditCategorySubmit}
          />

          <MultiUnitModal
            isOpen={multiUnitModal.isOpen}
            onClose={multiUnitModal.onClose}
            initialValue={editingMultiUnit}
            onSubmit={handleEditMultiUnitSubmit}
          /> */}
        </IModalOut>
      ) : (
        //mobile view
        <Box bg="white" flex={1} p={2} py={4} w="100%" rounded="sm">
          <Stack spacing={0} overflowY="hidden">
            <Flex alignItems="center" gap="2">
              <Heading size="md" alignItems="center">
                {type === "edit"
                  ? "Edit Stock Item - " + values.name
                  : "Add Stock Item"}
              </Heading>

              {isToggledFav ? (
                <AiFillStar onClick={handleFavourite} style={iconStyle} />
              ) : (
                <AiOutlineStar onClick={handleFavourite} style={iconStyle} />
              )}
            </Flex>
            <Box overflowY="auto">
              <Tabs
                isFitted
                onChange={(index) => setTabIndex(index)}
                colorScheme="blue"
                // index={paymentIndex}
              >
                <TabList mb={3}>
                  <Tab fontSize="9px">Basic Info</Tab>
                  <Tab fontSize="9px">Rates & Quantity’s</Tab>
                  <Tab fontSize="9px">Price Categories</Tab>
                  <Tab fontSize="9px">Multi Unit</Tab>
                  <Tab fontSize="9px">Other Info</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel pr="0" pl="0" pt="0">
                    <Box p={2}>
                      <FormControl id="name">
                        <TextField
                          label="Item Name"
                          variant="outlined"
                          size="small"
                          type="text"
                          fullWidth
                          onChange={(event) =>
                            handleEdit(event.target.value, { field: "name" })
                          }
                          onFocus={(e) => {
                            if (e) {
                              handleClick(0);
                            }
                          }}
                          inputRef={nameRef}
                          value={values.name || ""}
                          InputProps={{
                            style: { fontSize: "12px" },
                          }}
                          InputLabelProps={{
                            style: { fontSize: "12px" },
                          }}
                        />
                        {errors.name && !isNotValid && (
                          <Message type="error" message={errors.name} />
                        )}
                      </FormControl>
                    </Box>
                    <Box p={2} pt={0}>
                      <Grid
                        autoRows="auto"
                        templateColumns="repeat(4, 1fr)"
                        columnGap={2}
                        rowGap={2}
                      >
                        <GridItem colSpan={3}>
                          <FormControl>
                            <TextField
                              label="Alias Name"
                              variant="outlined"
                              size="small"
                              type="text"
                              fullWidth
                              value={values.name_alias || ""}
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(1);
                                }
                              }}
                              inputRef={aliasRef}
                              onChange={(event) =>
                                handleEdit(event.target.value, {
                                  field: "name_alias",
                                })
                              }
                              InputProps={{
                                style: { fontSize: "12px" },
                              }}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                            >
                              {errors.name_alias && !isNotValid && (
                                <Message
                                  type="error"
                                  message={errors.name_alias}
                                />
                              )}
                            </TextField>
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <Select
                            placeholder="Tax %"
                            onChange={(event) => {
                              setTaxValue(event.target.value);
                              handleEdit(event.target.value, { field: "tax" });
                            }}
                            onFocus={(e) => {
                              if (e) {
                                handleClick(2);
                              }
                            }}
                            name="tax"
                            value={tax}
                            as={Select}
                            ref={taxRef}
                            id="tax"
                            style={{ fontSize: "12px" }}
                          >
                            {taxOptions.map((taxOption, key) => (
                              <option value={taxOption.value} key={key}>
                                {taxOption.label}
                              </option>
                            ))}
                          </Select>
                          {errors.tax && !isNotValid && (
                            <Message type="error" message={errors.tax} />
                          )}
                        </GridItem>
                      </Grid>
                    </Box>
                    <Box p={2} pt={0} height="55px">
                      <Grid
                        autoRows="auto"
                        templateColumns="repeat(3, 1fr)"
                        columnGap={2}
                        rowGap={1}
                      >
                        <GridItem colSpan={1}>
                          <UnitAutoComplete
                            selectedUnit={values.unit}
                            modal={true}
                            setSelectedUnit={(newValue) => {
                              handleEdit(newValue, { field: "unit" });
                            }}
                            inputRef={unitRef}
                            onFocus={(e) => {
                              if (e) {
                                handleClick(3);
                              }
                            }}
                            setIsOpen={setIsOpen}
                            textFieldClick={(e) => {
                              if (e) {
                                handleClick(3);
                              }
                            }}
                            InputProps={{
                              style: { fontSize: "12px" },
                            }}
                            InputLabelProps={{
                              style: { fontSize: "12px" },
                            }}
                          ></UnitAutoComplete>
                          {errors.unit && !isNotValid && (
                            <Message type="error" message={errors.unit} />
                          )}
                        </GridItem>
                        {/* Maximum Qty */}
                        <GridItem colSpan={1}>
                          <FormControl id="maximum_stock">
                            <TextField
                              label="Max.Qty"
                              variant="outlined"
                              size="small"
                              type="text"
                              inputRef={maximumRef}
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(4);
                                }
                              }}
                              onChange={(event) =>
                                handleEdit(event.target.value, {
                                  field: "maximum_stock",
                                })
                              }
                              value={values.maximum_stock || ""}
                              InputProps={{
                                style: { fontSize: "12px" },
                              }}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                            />
                            {errors.item_code && !isNotValid && (
                              <Message
                                type="error"
                                message={errors.item_code}
                              />
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <FormControl id="minimum_stock">
                            <TextField
                              label="Min.Qty"
                              variant="outlined"
                              size="small"
                              type="number"
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(5);
                                }
                              }}
                              inputRef={minimumRef}
                              onChange={(event) =>
                                handleEdit(event.target.value, {
                                  field: "minimum_stock",
                                })
                              }
                              value={values.minimum_stock || ""}
                              InputProps={{
                                style: { fontSize: "12px" },
                              }}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                            />
                            {errors.minimum_stock && !isNotValid && (
                              <Message
                                type="error"
                                message={errors.minimum_stock}
                              />
                            )}
                          </FormControl>
                          {/* <TextField label="sdsd" inputRef={minimumRef}/> */}
                        </GridItem>
                      </Grid>
                    </Box>
                    <Box p={2} pt={0}>
                      <FormControl>
                        <Textarea
                          placeholder="Item Description"
                          onFocus={(e) => {
                            if (e) {
                              handleClick(6);
                            }
                          }}
                          // inputRef={descriptionRef}
                          ref={descriptionRef}
                          onChange={(event) =>
                            handleEdit(event.target.value, {
                              field: "description",
                            })
                          }
                          value={values.description || ""}
                          InputProps={{
                            style: { fontSize: "12px" },
                          }}
                          InputLabelProps={{
                            style: { fontSize: "12px" },
                          }}
                          style={{ fontSize: "12px" }}
                          onKeyDown={(event) => {
                            if (event.key === "ArrowDown") {
                              handleEdit(values.description + "\n", {
                                field: "description",
                              });
                            }
                            if (
                              event.key === "Enter" &&
                              !showMore &&
                              !hasExecuted
                            ) {
                              const validateResponse = validateForm();
                              validateResponse.then((data) => {
                                submitAction(data);
                                setHasExecuted(true);
                              });
                            }
                          }}
                        ></Textarea>
                      </FormControl>
                    </Box>
                    <Stack direction="row" marginRight={4}>
                      <Spacer />
                      <Link
                        sx={{
                          "&:hover": {
                            textDecoration: "none",
                          },
                        }}
                        onClick={() => setShowMore(!showMore)}
                      >
                        <Text
                          fontSize="xs"
                          color="brand.bg_blue"
                          sx={{
                            "&:hover": {
                              textColor: "blue.500",
                            },
                          }}
                        >
                          Show {showMore ? "Less" : "More"}
                          {showMore ? <ArrowUpIcon /> : <ArrowDownIcon />}
                        </Text>
                      </Link>
                    </Stack>
                    {showMore ? (
                      <>
                        <Box p={2} pt={1}>
                          <Grid
                            autoRows="auto"
                            templateColumns="repeat(2, 1fr)"
                            columnGap={2}
                            rowGap={3}
                          >
                            <GridItem colSpan={1}>
                              <FormControl id="item_code">
                                <TextField
                                  label="Item Code"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  onChange={(event) =>
                                    handleEdit(event.target.value, {
                                      field: "item_code",
                                    })
                                  }
                                  value={values.item_code || ""}
                                  onFocus={(e) => {
                                    if (e) {
                                      handleClick(7);
                                    }
                                  }}
                                  inputRef={itemcodRef}
                                  InputProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                />
                                {errors.item_code && !isNotValid && (
                                  <Message
                                    type="error"
                                    message={errors.item_code}
                                  />
                                )}
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <TextField
                                label="Bar Code"
                                variant="outlined"
                                size="small"
                                type="text"
                                onFocus={(e) => {
                                  if (e) {
                                    handleClick(8);
                                  }
                                }}
                                inputRef={barcodeRef}
                                onChange={(event) =>
                                  handleEdit(event.target.value, {
                                    field: "bar_code",
                                  })
                                }
                                value={values.bar_code || ""}
                                InputProps={{
                                  style: { fontSize: "12px" },
                                }}
                                InputLabelProps={{
                                  style: { fontSize: "12px" },
                                }}
                              >
                                {errors.bar_code && !isNotValid && (
                                  <Message
                                    type="error"
                                    message={errors.bar_code}
                                  />
                                )}
                              </TextField>
                            </GridItem>
                            {/* HSN Code */}
                            <GridItem colSpan={1}>
                              <TextField
                                label="HSN Code"
                                variant="outlined"
                                size="small"
                                type="text"
                                onFocus={(e) => {
                                  if (e) {
                                    handleClick(9);
                                  }
                                }}
                                inputRef={hsnCodeRef}
                                onChange={(event) =>
                                  handleEdit(event.target.value, {
                                    field: "hsn_number",
                                  })
                                }
                                value={values.hsn_number || ""}
                                InputProps={{
                                  style: { fontSize: "12px" },
                                }}
                                InputLabelProps={{
                                  style: { fontSize: "12px" },
                                }}
                              >
                                {errors.hsn_number && !isNotValid && (
                                  <Message
                                    type="error"
                                    message={errors.hsn_number}
                                  />
                                )}
                              </TextField>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <GroupAutoComplete
                                selectedGroup={values.group}
                                modal={true}
                                setSelectedGroup={(newValue) => {
                                  handleEdit(newValue, { field: "group" });
                                }}
                                inputRef={itemgroupRef}
                                setIsOpen={setIsOpen}
                                textFieldClick={(e) => {
                                  if (e) {
                                    handleClick(10);
                                  }
                                }}
                              ></GroupAutoComplete>
                              {errors.group && !isNotValid && (
                                <Message type="error" message={errors.group} />
                              )}
                            </GridItem>
                            <GridItem colSpan={1}>
                              <CategoryAutoComplete
                                selectedCategory={values.category}
                                modal={true}
                                setSelectedCategory={(newValue) => {
                                  handleEdit(newValue, { field: "category" });
                                }}
                                inputRef={categoryRef}
                                setIsOpen={setIsOpen}
                                textFieldClick={(e) => {
                                  if (e) {
                                    handleClick(11);
                                  }
                                }}
                              ></CategoryAutoComplete>
                              {errors.category && !isNotValid && (
                                <Message
                                  type="error"
                                  message={errors.category}
                                />
                              )}
                            </GridItem>
                            <GridItem colSpan={1}>
                              <BrandAutoComplete
                                selectedBrand={values.brand}
                                modal={true}
                                setSelectedBrand={(newValue) => {
                                  handleEdit(newValue, { field: "brand" });
                                }}
                                inputRef={brandRef}
                                setIsOpen={setIsOpen}
                                textFieldClick={(e) => {
                                  if (e) {
                                    handleClick(12);
                                  }
                                }}
                              ></BrandAutoComplete>
                              {errors.brand && !isNotValid && (
                                <Message type="error" message={errors.brand} />
                              )}
                            </GridItem>
                          </Grid>
                        </Box>
                        <Box p={2} pt={0}>
                          <FormLabel>
                            <Text style={{ fontSize: "12px" }}>
                              Opening Stock Details
                            </Text>
                          </FormLabel>
                          <Grid
                            autoRows="auto"
                            templateColumns="repeat(3, 1fr)"
                            columnGap={3}
                            rowGap={3}
                          >
                            <GridItem colSpan={1}>
                              <FormControl id="opening_stock">
                                <TextField
                                  label="Op.Qty"
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  onFocus={(e) => {
                                    if (e) {
                                      handleClick(13);
                                    }
                                  }}
                                  inputRef={openingQtyRef}
                                  InputProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  onChange={(event) => {
                                    let openingQtyValue = event.target.value
                                      ? event.target.value
                                      : "0";
                                    if (
                                      openingQtyValue?.length > 1 &&
                                      openingQtyValue[0] === "0"
                                    )
                                      openingQtyValue =
                                        openingQtyValue.slice(1);
                                    handleEdit(checkDecimal(openingQtyValue), {
                                      field: "opening_stock",
                                    });
                                    let opening_stock_value = checkDecimal(
                                      values.cost_price * event.target.value
                                    );
                                    handleEdit(
                                      opening_stock_value
                                        ? opening_stock_value
                                        : "0",
                                      {
                                        field: "opening_stock_value",
                                      }
                                    );
                                  }}
                                  value={values.opening_stock || ""}
                                />
                                {errors.opening_stock && !isNotValid && (
                                  <Message
                                    type="error"
                                    message={errors.opening_stock}
                                  />
                                )}
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl id="cost_price">
                                <TextField
                                  label="Cost Price"
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  InputProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  onFocus={(e) => {
                                    if (e) {
                                      handleClick(14);
                                    }
                                  }}
                                  inputRef={costPriceRef}
                                  onChange={(event) => {
                                    let costPriceValue = event.target.value
                                      ? event.target.value
                                      : "0";
                                    if (
                                      costPriceValue?.length > 1 &&
                                      costPriceValue[0] === "0"
                                    )
                                      costPriceValue = costPriceValue.slice(1);
                                    handleEdit(checkDecimal(costPriceValue), {
                                      field: "cost_price",
                                    });
                                    let opening_stock_value = checkDecimal(
                                      values.opening_stock * event.target.value
                                    );
                                    handleEdit(
                                      opening_stock_value
                                        ? opening_stock_value
                                        : "0",
                                      {
                                        field: "opening_stock_value",
                                      }
                                    );
                                  }}
                                  value={values.cost_price || ""}
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      const validateResponse = validateForm();
                                      validateResponse.then((data) => {
                                        submitAction(data);
                                      });
                                    }
                                  }}
                                />
                                {errors.cost_price && !isNotValid && (
                                  <Message
                                    type="error"
                                    message={errors.cost_price}
                                  />
                                )}
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl id="opening_stock_value">
                                <TextField
                                  label="Stock Value"
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  disabled
                                  value={values.opening_stock_value || ""}
                                  InputProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                />
                                {errors.opening_stock_value && !isNotValid && (
                                  <Message
                                    type="error"
                                    message={errors.opening_stock_value}
                                  />
                                )}
                              </FormControl>
                            </GridItem>
                          </Grid>
                          <Checkbox
                            ml="auto"
                            mt="10px"
                            mr="45px"
                            isChecked={showTrack}
                            onChange={(e) => {
                              setShowTrack(!showTrack);
                            }}
                          >
                            <Text style={{ fontSize: "12px" }}>
                              Not Required Track Closing Stock
                            </Text>
                          </Checkbox>
                          <Checkbox
                            mt="10px"
                            ml="auto"
                            mr="45px"
                            isChecked={suspand}
                            onChange={(e) => {
                              setSuspand(!suspand);
                            }}
                          >
                            <Text style={{ fontSize: "12px" }}>
                              Required Suspend
                            </Text>
                          </Checkbox>
                          <Checkbox
                            disabled={isApproval}
                            mt="10px"
                            ml="auto"
                            mr="45px"
                            isChecked={isService}
                            onChange={(e) => {
                              setIsService(!isService);
                            }}
                          >
                            Service Item
                          </Checkbox>
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </TabPanel>
                  <TabPanel pr="0" pl="0" pt="0">
                    <Box p={2} pt={0} pr={4}>
                      <Stack direction="row">
                        {/* <Spacer /> */}
                        <Checkbox
                          isChecked={showTax}
                          onChange={(e) => {
                            setShowTax(!showTax);
                          }}
                          whiteSpace="nowrap"
                        >
                          Show With Tax
                        </Checkbox>
                        <Checkbox
                          isChecked={showMin}
                          onChange={(e) => {
                            setShowMin(!showMin);
                          }}
                          whiteSpace="nowrap"
                        >
                          Show Min And Max
                        </Checkbox>
                      </Stack>
                      <Box>
                        <FormLabel>
                          <Text>Selling Price Without Tax</Text>
                        </FormLabel>
                        <Grid
                          autoRows="auto"
                          templateColumns="repeat(3, 1fr)"
                          columnGap={2}
                          rowGap={2}
                        >
                          <GridItem colSpan={1}>
                            <FormControl id="price">
                              <TextField
                                label="S P"
                                variant="outlined"
                                size="small"
                                type="number"
                                onFocus={(e) => {
                                  if (e) {
                                    handleClick(0);
                                  }
                                }}
                                inputRef={spWithoutTaxRef}
                                onChange={(event) => {
                                  setPriceValue(event.target.value);
                                  handleEdit(event.target.value, {
                                    field: "price",
                                  });
                                }}
                                value={price || ""}
                                InputProps={{ style: { fontSize: "12px" } }}
                                InputLabelProps={{
                                  style: { fontSize: "12px" },
                                }}
                              />
                              {errors.price && !isNotValid && (
                                <Message type="error" message={errors.price} />
                              )}
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <TextField
                                // label="Sale Discount"
                                label="Discount"
                                variant="outlined"
                                size="small"
                                type="text"
                                onFocus={(e) => {
                                  if (e) {
                                    handleClick(1);
                                  }
                                }}
                                inputRef={spDiscountWithoutTaxRef}
                                onChange={(event) =>
                                  setDiscountWithOutTaxValue(event.target.value)
                                }
                                value={
                                  discountWithOutTax
                                    ? discountWithOutTax
                                    : editValue?.sale_discount_with_tax === 0
                                    ? editValue?.sale_discount_with_tax
                                    : "" || ""
                                }
                                InputProps={{ style: { fontSize: "12px" } }}
                                InputLabelProps={{
                                  style: { fontSize: "12px" },
                                }}
                              >
                                {errors.sale_discount && !isNotValid && (
                                  <Message
                                    type="error"
                                    message={errors.sale_discount}
                                  />
                                )}
                              </TextField>
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <TextField
                                disabled
                                label="Net Amount"
                                variant="outlined"
                                size="small"
                                type="text"
                                value={
                                  _parseFloat(
                                    price -
                                      (editValue?.sale_discount
                                        ? editValue?.sale_discount
                                        : discountWithOutTax)
                                  ).toFixed(2) || ""
                                }
                                InputProps={{ style: { fontSize: "12px" } }}
                                InputLabelProps={{
                                  style: { fontSize: "12px" },
                                }}
                              >
                                {errors.sale_discount && !isNotValid && (
                                  <Message
                                    type="error"
                                    message={errors.sale_discount}
                                  />
                                )}
                              </TextField>
                            </FormControl>
                          </GridItem>
                        </Grid>
                      </Box>
                      {showTax ? (
                        <>
                          <FormLabel>
                            <Text>Selling Price With Tax</Text>
                          </FormLabel>
                          <Grid
                            autoRows="auto"
                            templateColumns="repeat(3, 1fr)"
                            columnGap={2}
                            rowGap={2}
                          >
                            <GridItem colSpan={1}>
                              <FormControl id="priceWithTax">
                                <TextField
                                  label="S P"
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  onChange={(event) =>
                                    setPriceWithTaxValue(event.target.value)
                                  }
                                  value={priceWithTax || ""}
                                  inputRef={spWithTaxRef}
                                  onFocus={(e) => {
                                    if (e) {
                                      handleClick(2);
                                    }
                                  }}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <TextField
                                  label="Discount"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  inputRef={spDiscountWithTaxRef}
                                  onChange={(event) =>
                                    setDiscountWithTaxValue(event.target.value)
                                  }
                                  value={
                                    discountWithTax
                                      ? discountWithTax
                                      : editValue?.sale_discount_with_tax === 0
                                      ? editValue?.sale_discount_with_tax
                                      : "" || ""
                                  }
                                  onFocus={(e) => {
                                    if (e) {
                                      handleClick(3);
                                    }
                                  }}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                >
                                  {errors.sale_discount && !isNotValid && (
                                    <Message
                                      type="error"
                                      message={errors.sale_discount}
                                    />
                                  )}
                                </TextField>
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <TextField
                                  disabled
                                  label="Net Amount"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  value={
                                    _parseFloat(
                                      priceWithTax - discountWithTax
                                    ).toFixed(2) || ""
                                  }
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                >
                                  {errors.sale_discount && !isNotValid && (
                                    <Message
                                      type="error"
                                      message={errors.sale_discount}
                                    />
                                  )}
                                </TextField>
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                    <Box p={2} pt={0} pr={4}>
                      <FormLabel>
                        <Text>Purchase Price Without Tax</Text>
                      </FormLabel>
                      <Grid
                        autoRows="auto"
                        templateColumns="repeat(3, 1fr)"
                        columnGap={2}
                        rowGap={2}
                      >
                        <GridItem colSpan={1}>
                          <FormControl id=")p_price">
                            <TextField
                              fullWidth
                              label="P P"
                              variant="outlined"
                              size="small"
                              type="number"
                              onChange={(event) => {
                                handleEdit(event.target.value, {
                                  field: "purchase_price_without_tax",
                                });
                                purchasePriceWithoutTaxValue(
                                  event.target.value
                                );
                              }}
                              InputProps={{ style: { fontSize: "12px" } }}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                              value={
                                purchasePriceWithOutTax
                                  ? purchasePriceWithOutTax
                                  : editValue?.purchase_price_without_tax ===
                                    "0"
                                  ? editValue?.purchase_price_without_tax
                                  : "" || ""
                              }
                              inputRef={ppWithoutTaxRef}
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(4);
                                }
                              }}
                            />
                            {errors.price && !isNotValid && (
                              <Message type="error" message={errors.price} />
                            )}
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <TextField
                              // label="Sale Discount"
                              label="Discount"
                              variant="outlined"
                              size="small"
                              type="text"
                              onFocus={(e) => {
                                if (e) {
                                  handleClick(5);
                                }
                              }}
                              onChange={(event) => {
                                handleEdit(event.target.value, {
                                  field: "sale_discount",
                                });
                                purchasePriceDiscountWithoutTaxValue(
                                  event.target.value
                                );
                              }}
                              InputProps={{ style: { fontSize: "12px" } }}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                              onKeyDown={(event) => {
                                if (event.key === "Enter") {
                                  if (!showMin && !showTax) {
                                    const validateResponse = validateForm();
                                    validateResponse.then((data) => {
                                      submitAction(data);
                                    });
                                  }
                                }
                              }}
                              value={
                                purchasePriceDiscountWithoutTax
                                  ? purchasePriceDiscountWithoutTax
                                  : editValue?.purchase_discount === 0
                                  ? editValue?.purchase_discount
                                  : "" || ""
                              }
                              inputRef={ppDiscountWithoutTaxRef}
                            >
                              {errors.sale_discount && !isNotValid && (
                                <Message
                                  type="error"
                                  message={errors.sale_discount}
                                />
                              )}
                            </TextField>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <TextField
                              disabled
                              label="Net Amount"
                              variant="outlined"
                              size="small"
                              type="text"
                              value={
                                _parseFloat(
                                  (editValue?.purchase_price_without_tax
                                    ? editValue?.purchase_price_without_tax
                                    : purchasePriceWithOutTax) -
                                    (editValue?.purchase_discount
                                      ? editValue?.purchase_discount
                                      : purchasePriceDiscountWithoutTax)
                                ).toFixed(2) || ""
                              }
                              InputProps={{ style: { fontSize: "12px" } }}
                              InputLabelProps={{
                                style: { fontSize: "12px" },
                              }}
                            >
                              {errors.sale_discount && !isNotValid && (
                                <Message
                                  type="error"
                                  message={errors.sale_discount}
                                />
                              )}
                            </TextField>
                          </FormControl>
                        </GridItem>
                      </Grid>
                    </Box>
                    <Box p={2} pt={0} pr={4}>
                      {showTax ? (
                        <>
                          <FormLabel>
                            <Text>Purchase Price With Tax</Text>
                          </FormLabel>
                          <Grid
                            autoRows="auto"
                            templateColumns="repeat(3, 1fr)"
                            columnGap={2}
                            rowGap={2}
                          >
                            <GridItem colSpan={1}>
                              <FormControl id="purchase_price_with_tax">
                                <TextField
                                  fullWidth
                                  label="P P"
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  onChange={(event) => {
                                    handleEdit(event.target.value, {
                                      field: "purchase_price_with_tax",
                                    });
                                    purchasePriceWithTaxValue(
                                      event.target.value
                                    );
                                  }}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={purchasePriceWithTax || ""}
                                  inputRef={ppWithTaxRef}
                                  onFocus={(e) => {
                                    if (e) {
                                      handleClick(6);
                                    }
                                  }}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <TextField
                                  // label="Sale Discount"
                                  label="Discount"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  onFocus={(e) => {
                                    if (e) {
                                      handleClick(7);
                                      ppDiscountWithTaxRef.current.select();
                                    }
                                  }}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  inputRef={ppDiscountWithTaxRef}
                                  onChange={(event) => {
                                    handleEdit(event.target.value, {
                                      field: "sale_discount",
                                    });
                                    purchasePriceDiscountWithTaxValue(
                                      event.target.value
                                    );
                                  }}
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      if (!showMin && showTax) {
                                        const validateResponse = validateForm();
                                        validateResponse.then((data) => {
                                          submitAction(data);
                                        });
                                      }
                                    }
                                  }}
                                  value={purchasePriceDiscountWithTax || ""}
                                  // let taxAmount = (price * taxPercentage) / 100;
                                >
                                  {errors.sale_discount && !isNotValid && (
                                    <Message
                                      type="error"
                                      message={errors.sale_discount}
                                    />
                                  )}
                                </TextField>
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <TextField
                                  disabled
                                  label="Net Amount"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={
                                    _parseFloat(
                                      purchasePriceWithTax -
                                        purchasePriceDiscountWithTax
                                    ).toFixed(2) || ""
                                  }
                                >
                                  {errors.sale_discount && !isNotValid && (
                                    <Message
                                      type="error"
                                      message={errors.sale_discount}
                                    />
                                  )}
                                </TextField>
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                    {/* <Box p={2} pt={0} pr={4}>
                  <FormLabel>
                    <Text>Purchase Price Without Tax</Text>
                  </FormLabel>
                  <Grid
                    autoRows="auto"
                    templateColumns="repeat(3, 1fr)"
                    columnGap={2}
                    rowGap={2}
                  >
                    <GridItem colSpan={1}>
                      <FormControl id=")p_price">
                        <TextField
                          fullWidth
                          label="P P Without Tax"
                          variant="outlined"
                          size="small"
                          type="number"
                          onChange={(event) => {
                            handleEdit(event.target.value, {
                              field: "purchase_price_without_tax",
                            });
                            purchasePriceWithoutTaxValue(event.target.value);
                          }}
                          value={
                            purchasePriceWithOutTax
                              ? purchasePriceWithOutTax
                              : editValue?.purchase_price_without_tax === "0"
                              ? editValue?.purchase_price_without_tax
                              : "" || ""
                          }
                          inputRef={ppWithoutTaxRef}
                          onFocus={(e) => {
                            if (e) {
                              handleClick(6);
                            }
                          }}
                        />
                        {errors.price && !isNotValid && (
                          <Message type="error" message={errors.price} />
                        )}
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <TextField
                          // label="Sale Discount"
                          label="Discount Without Tax"
                          variant="outlined"
                          size="small"
                          type="text"
                          onFocus={(e) => {
                            if (e) {
                              handleClick(7);
                            }
                          }}
                          onChange={(event) => {
                            handleEdit(event.target.value, {
                              field: "sale_discount",
                            });
                            purchasePriceDiscountWithoutTaxValue(
                              event.target.value
                            );
                          }}
                          value={
                            purchasePriceDiscountWithoutTax
                              ? purchasePriceDiscountWithoutTax
                              : editValue?.purchase_discount === 0
                              ? editValue?.purchase_discount
                              : "" || ""
                          }
                          inputRef={ppDiscountWithoutTaxRef}
                        >
                          {errors.sale_discount && !isNotValid && (
                            <Message
                              type="error"
                              message={errors.sale_discount}
                            />
                          )}
                        </TextField>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl>
                        <TextField
                          disabled
                          label="Net Amount"
                          variant="outlined"
                          size="small"
                          type="text"
                          value={
                            _parseFloat(
                              purchasePriceWithOutTax -
                                purchasePriceDiscountWithoutTax
                            ).toFixed(2) || ""
                          }
                        >
                          {errors.sale_discount && !isNotValid && (
                            <Message
                              type="error"
                              message={errors.sale_discount}
                            />
                          )}
                        </TextField>
                      </FormControl>
                    </GridItem>
                  </Grid>
                </Box> */}
                    {showMin ? (
                      <>
                        <Box p={2} pt={0} pr={4}>
                          <FormLabel>
                            <Text>Min And Max Price</Text>
                          </FormLabel>
                          <Grid
                            autoRows="auto"
                            templateColumns="repeat(3, 1fr)"
                            columnGap={2}
                            rowGap={2}
                          >
                            <GridItem colSpan={1}>
                              <FormControl id="maximum_retail_price">
                                <TextField
                                  fullWidth
                                  label="MRP"
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  onChange={(event) => {
                                    // setPriceValue(event.target.value);
                                    handleEdit(event.target.value, {
                                      field: "maximum_retail_price",
                                    });
                                  }}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={values.maximum_retail_price || ""}
                                  inputRef={mrpRef}
                                  onFocus={(e) => {
                                    if (e) {
                                      handleClick(8);
                                      mrpRef.current.select();
                                    }
                                  }}
                                />
                                {errors.price && !isNotValid && (
                                  <Message
                                    type="error"
                                    message={errors.price}
                                  />
                                )}
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <TextField
                                  // label="Sale Discount"
                                  label="Mini S P"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  onChange={(e) => {
                                    setMinSpWithoutTaxValue(e.target.value);
                                  }}
                                  value={
                                    minSpWithoutTax
                                      ? minSpWithoutTax
                                      : editValue?.minimum_selling_price_without_tax ===
                                        "0"
                                      ? editValue?.minimum_selling_price_without_tax
                                      : "" || ""
                                  }
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  inputRef={miniSpWithoutTaxRef}
                                  onFocus={(e) => {
                                    if (e) {
                                      handleClick(9);
                                      miniSpWithoutTaxRef.current.select();
                                    }
                                  }}
                                >
                                  {errors.sale_discount && !isNotValid && (
                                    <Message
                                      type="error"
                                      message={errors.sale_discount}
                                    />
                                  )}
                                </TextField>
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl>
                                <TextField
                                  label="Mini S P"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  onChange={(e) => {
                                    setMinSpWithTaxValue(e.target.value);
                                  }}
                                  value={minSpWithTax || ""}
                                  inputRef={miniSpWithTaxRef}
                                  onFocus={(e) => {
                                    if (e) {
                                      handleClick(10);
                                    }
                                  }}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                >
                                  {errors.sale_discount && !isNotValid && (
                                    <Message
                                      type="error"
                                      message={errors.sale_discount}
                                    />
                                  )}
                                </TextField>
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </Box>
                        <Box p={2} pt={0} pr={4}>
                          <Grid
                            autoRows="auto"
                            templateColumns="repeat(2, 1fr)"
                            columnGap={2}
                            rowGap={2}
                          >
                            <GridItem colSpan={1}>
                              <FormControl>
                                <TextField
                                  fullWidth
                                  label="Max Purch. Price"
                                  variant="outlined"
                                  size="small"
                                  type="text"
                                  onChange={(e) => {
                                    setMaxPurchasePriceWithTaxValue(
                                      e.target.value
                                    );
                                  }}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  value={maxPurchasePriceWithTax || ""}
                                  inputRef={maxPurchasePriceWithTaxRef}
                                  onFocus={(e) => {
                                    if (e) {
                                      handleClick(11);
                                    }
                                  }}
                                >
                                  {errors.sale_discount && !isNotValid && (
                                    <Message
                                      type="error"
                                      message={errors.sale_discount}
                                    />
                                  )}
                                </TextField>
                              </FormControl>
                            </GridItem>
                            <GridItem colSpan={1}>
                              <FormControl id="price">
                                <TextField
                                  fullWidth
                                  label="Max Purch. Price"
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  onFocus={(e) => {
                                    if (e) {
                                      handleClick(12);
                                      maxPurchasePriceWithoutTaxRef.current.select();
                                    }
                                  }}
                                  InputProps={{ style: { fontSize: "12px" } }}
                                  InputLabelProps={{
                                    style: { fontSize: "12px" },
                                  }}
                                  inputRef={maxPurchasePriceWithoutTaxRef}
                                  onChange={(e) => {
                                    setMaxPurchasePriceWithoutTaxValue(
                                      e.target.value
                                    );
                                  }}
                                  value={
                                    maxPurchasePriceWithoutTax
                                      ? maxPurchasePriceWithoutTax
                                      : editValue?.maximum_purchase_price_without_tax ===
                                        "0"
                                      ? editValue?.maximum_purchase_price_without_tax
                                      : "" || ""
                                  }
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      const validateResponse = validateForm();
                                      validateResponse.then((data) => {
                                        submitAction(data);
                                      });
                                    }
                                  }}
                                />
                                {errors.price && !isNotValid && (
                                  <Message
                                    type="error"
                                    message={errors.price}
                                  />
                                )}
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </Box>
                      </>
                    ) : (
                      ""
                    )}
                  </TabPanel>
                  <TabPanel pr="0" pl="0" pt="0">
                    <Box pb={2} w="100%" maxHeight="400px" overflow="scroll">
                      <Button
                        onClick={priceCategoryModal.onOpen}
                        h="30px"
                        colorScheme="blue"
                        variant="ghost"
                        type="submit"
                        size="lg"
                        minH={12}
                      >
                        Add Price Category (+)
                      </Button>

                      <Stack spacing={4} pt={2}>
                        {selectedPriceCategory &&
                          selectedPriceCategory.length > 0 && (
                            <TableContainer>
                              <Table
                                variant="simple"
                                size="sm"
                                width="100%"
                                style={{ tableLayout: "fixed" }}
                                // colorScheme="telegram"
                              >
                                <Thead>
                                  <Tr>
                                    <Th
                                      position="sticky"
                                      top="0"
                                      zIndex="1"
                                      bg="blue.50"
                                      textColor="green.500"
                                    >
                                      Category
                                    </Th>
                                    <Th
                                      position="sticky"
                                      top="0"
                                      zIndex="1"
                                      bg="blue.50"
                                      isNumeric
                                      w="160px"
                                      textColor="green.500"
                                    >
                                      price
                                      <br />
                                      <Divider borderColor="gray.500" />
                                      price with tax
                                    </Th>
                                    <Th
                                      position="sticky"
                                      top="0"
                                      zIndex="1"
                                      bg="blue.50"
                                      isNumeric
                                      w="180px"
                                      textColor="green.500"
                                    >
                                      Discount
                                      <br />
                                      <Divider borderColor="gray.500" />
                                      discount with tax
                                    </Th>
                                    <Th
                                      position="sticky"
                                      top="0"
                                      zIndex="1"
                                      bg="blue.50"
                                      w="40px"
                                    ></Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {selectedPriceCategory.map((item, key) => (
                                    <Tr
                                      bg="white"
                                      key={key}
                                      _hover={{ bg: "gray.100" }}
                                    >
                                      <Td>{item.categoryName}</Td>
                                      <Td isNumeric>
                                        {_parseFloat(item.price).toFixed(
                                          decimalLength
                                        )}
                                        <br />
                                        <Divider borderColor="gray.500" />
                                        {_parseFloat(
                                          item.price_with_tax
                                        ).toFixed(decimalLength)}
                                      </Td>
                                      <Td isNumeric>
                                        {_parseFloat(
                                          item.sale_discount
                                        ).toFixed(decimalLength)}
                                        <br />
                                        <Divider borderColor="gray.500" />
                                        {_parseFloat(
                                          item.sale_discount_with_tax
                                        ).toFixed(decimalLength)}
                                      </Td>
                                      <Td>
                                        <Menu>
                                          <MenuButton
                                            as={IconButton}
                                            aria-label="Options"
                                            icon={<Icon as={FiMoreVertical} />}
                                            size="xs"
                                            variant="solid"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          />
                                          <MenuList>
                                            <MenuItem
                                              icon={<Icon as={FiEdit3}></Icon>}
                                              onClick={() =>
                                                handleEditCategory(item)
                                              }
                                            >
                                              Edit
                                            </MenuItem>
                                            <MenuItem
                                              color="red.500"
                                              icon={<Icon as={FiTrash2}></Icon>}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteCategory(item);
                                              }}
                                            >
                                              Delete
                                            </MenuItem>
                                          </MenuList>
                                        </Menu>
                                      </Td>
                                    </Tr>
                                  ))}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          )}
                      </Stack>
                    </Box>
                  </TabPanel>
                  <TabPanel pr="0" pl="0" pt="0">
                    <Box pb={2} w="100%" maxHeight="400px" overflow="scroll">
                      <Button
                        onClick={multiUnitModal.onOpen}
                        h="30px"
                        colorScheme="blue"
                        variant="ghost"
                        type="submit"
                        size="lg"
                        minH={12}
                      >
                        Add Multi Unit (+)
                      </Button>

                      <Stack spacing={4} pt={2}>
                        {selectedMultiUnits &&
                          selectedMultiUnits.length > 0 && (
                            <TableContainer>
                              <Table
                                variant="simple"
                                size="sm"
                                width="100%"
                                style={{ tableLayout: "fixed" }}
                              >
                                <Thead>
                                  <Tr>
                                    <Th
                                      position="sticky"
                                      top="0"
                                      zIndex="1"
                                      bg="blue.50"
                                      textColor="green.500"
                                      w="50px"
                                    >
                                      Sl No
                                    </Th>
                                    <Th
                                      position="sticky"
                                      top="0"
                                      zIndex="1"
                                      bg="blue.50"
                                      textColor="green.500"
                                      w="100%"
                                    >
                                      Unit Symbol
                                    </Th>
                                    <Th
                                      position="sticky"
                                      top="0"
                                      zIndex="1"
                                      bg="blue.50"
                                      textColor="green.500"
                                      isNumeric
                                      w="140px"
                                    >
                                      Conversion Rate
                                    </Th>
                                    <Th
                                      position="sticky"
                                      top="0"
                                      zIndex="1"
                                      bg="blue.50"
                                      w="40px"
                                    ></Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {selectedMultiUnits.map((item, key) => (
                                    <Tr
                                      bg="white"
                                      key={key}
                                      _hover={{ bg: "gray.100" }}
                                    >
                                      <Td>{item.index + 1}</Td>
                                      <Td>{getUnitName(item.unit)}</Td>
                                      <Td isNumeric>
                                        {parseFloat(item.rate).toFixed(
                                          decimalLength
                                        )}
                                      </Td>
                                      <Td>
                                        <Menu>
                                          <MenuButton
                                            as={IconButton}
                                            aria-label="Options"
                                            icon={<Icon as={FiMoreVertical} />}
                                            size="xs"
                                            variant="solid"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                          />
                                          <MenuList>
                                            <MenuItem
                                              icon={<Icon as={FiEdit3}></Icon>}
                                              onClick={() =>
                                                handleEditMultiUnits(item)
                                              }
                                            >
                                              Edit
                                            </MenuItem>
                                            <MenuItem
                                              color="red.500"
                                              icon={<Icon as={FiTrash2}></Icon>}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteMultiUnits(item);
                                              }}
                                            >
                                              Delete
                                            </MenuItem>
                                          </MenuList>
                                        </Menu>
                                      </Td>
                                    </Tr>
                                  ))}
                                </Tbody>
                              </Table>
                            </TableContainer>
                          )}
                      </Stack>
                    </Box>
                  </TabPanel>
                  <TabPanel pr="0" pl="0" pt="0">
                    <Box p={2} pt={0} pr={4}>
                      <Grid
                        autoRows="auto"
                        templateColumns="repeat(2, 1fr)"
                        columnGap={2}
                        rowGap={2}
                      >
                        <GridItem colSpan={1}>
                          <MultiSelectionField
                            placeholder="Cost Centers"
                            datas={costCenterData?.data}
                            options={costCenters}
                            setOptions={setCostCenters}
                            inputRef={costCenterRef}
                          />
                        </GridItem>
                        <GridItem colSpan={1}>
                          <MultiSelectionField
                            placeholder="Done Bys"
                            datas={doneByData?.data}
                            options={doneBys}
                            setOptions={setDoneBys}
                            inputRef={doneByRef}
                          />
                        </GridItem>
                      </Grid>
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
              <Box p={2} w="100%" display="flex" gap={2}>
                {isApproval && (
                  <Tooltip
                    hasArrow
                    label="Reject"
                    placement="bottom"
                    bg="brand.bg_blue"
                  >
                    <Button
                      w="100%"
                      isLoading={isRejectLoading}
                      minH="40px"
                      type="submit"
                      size="lg"
                      variant="solid"
                      bg="red.600"
                      colorScheme="red"
                      onClick={() => {
                        rejectFunc();
                      }}
                    >
                      Reject
                    </Button>
                  </Tooltip>
                )}
                <IButton
                  isLoading={isLoading}
                  onClick={() => {
                    const validateResponse = validateForm();
                    validateResponse.then((data) => {
                      submitAction(data);
                    });
                  }}
                  isApproval={isApproval}
                  type={type}
                />
              </Box>
            </Box>
          </Stack>

          {/* <PriceCategoryModal
            taxPercentage={tax}
            initialValue={editingCategory}
            isOpen={priceCategoryModal.isOpen}
            onClose={priceCategoryModal.onClose}
            onSubmit={handleEditCategorySubmit}
          />

          <MultiUnitModal
            isOpen={multiUnitModal.isOpen}
            onClose={multiUnitModal.onClose}
            initialValue={editingMultiUnit}
            onSubmit={handleEditMultiUnitSubmit}
          /> */}
        </Box>
      )}

      <PriceCategoryModal
        taxPercentage={tax}
        initialValue={editingCategory}
        isOpen={priceCategoryModal.isOpen}
        onClose={handlePriceCategoryOnClose}
        onSubmit={handleEditCategorySubmit}
      />

      <MultiUnitModal
        baseUnit={values.unit}
        isOpen={multiUnitModal.isOpen}
        onClose={onCloseMultiUnitModal}
        initialValue={editingMultiUnit}
        onSubmit={handleEditMultiUnitSubmit}
      />

      <MinMaxStockModal
        initialValue={editingMinMaxStock}
        isOpen={minMaxStockModal.isOpen}
        onClose={handleMinMaxStockOnClose}
        onSubmit={handleEditMinMaxStockSubmit}
      />

      <Modal
        isOpen={ledgerEditModal.isOpen}
        onClose={ledgerEditModal.onClose}
        scrollBehavior="inside"
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent width={"100%"}>
          <ModalCloseButton />
          <ModalBody>
            <AddLedgerForm
              type={"edit"}
              editValue={editValue}
              onAddSuccess={(ledger) => {
                ledgerEditModal.onClose();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
const _parseFloat = (num) => {
  return parseFloat(num || 0);
};

export default RenderComponent;
