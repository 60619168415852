import AutocompleteWithAddOption from "FormComponents/AutoComplete/component";
import AddPriceCategoryName from "components/AddPriceCategoryName";
import { usePriceCategoryName } from "hooks/PriceCategoryName/usePriceCategoryName";
import { useEffect, useState } from "react";

const PriceCategoryNameSelectionField = (props) => {
  const { priceCategoryName, setPriceCategoryName, inputRef, onFocus } = props;
  const { data } = usePriceCategoryName();

  const [searchData, setSearchData] = useState("");
  const [priceCategoryNameObj, setPriceCategoryNameObj] = useState();
  const [priceCategory, setPriceCategory] = useState([]);

  useEffect(() => {
    if (data) {
      setPriceCategory(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (data && priceCategoryName) {
      setPriceCategoryNameObj(
        data.data.find((i) => i.id === priceCategoryName)
      );
    } else {
      setPriceCategoryNameObj(null);
    }
  }, [priceCategoryName]);

  const setSelectedPriceCategoryName = (selectPriceCategoryName) => {
    setPriceCategoryName(selectPriceCategoryName);
  };

  return (
    <AutocompleteWithAddOption
      value={priceCategoryNameObj}
      options={priceCategory}
      modal={props.modal}
      onChange={setSelectedPriceCategoryName}
      inputRef={inputRef}
      optionLabel="name"
      inputLabel="Price Category"
      setIsOpen={() => {}}
      setSearchData={setSearchData}
      onFocus={onFocus}
    >
      <AddPriceCategoryName
        type="add"
        searchData={searchData}
        onAddSuccess={(name) => {
          setSelectedPriceCategoryName(name);
        }}
      />
    </AutocompleteWithAddOption>
  );
};
export default PriceCategoryNameSelectionField;
