import React, { useEffect, useState } from "react";
import {
  Stack,
  Box,
  Accordion,
  AccordionItem,
  Icon,
  Tooltip,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
  HStack,
  Flex,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Spinner,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import AddItemForm from "../AddItemForm";
import AddCustomer from "../AddCustomer";
import AddSupplier from "../AddSupplier";
import AddUnit from "../Addunit";
import AddItemGroup from "../AddItemGroup";
import AddItemCategory from "../AddItemCategory";
import AddItemBrand from "../AddItemBrand";
import AddStore from "../AddStore";
import AddCostCentre from "../AddCostCentre";
import AddDoneby from "../AddDoneby";
import AddProduction from "../AddProduction";
import AddModeOfPayment from "../AddModeOfPayment";
import AddVoucherType from "../AddVoucherType";
import AddEstimateStatus from "../AddEstimateStatus";
import AddLedgerGroup from "../AddLedgerGroup";
import AddLedgerForm from "../AddLedgerForm";
import {
  BiGridAlt,
  BiTransfer,
  BiLayer,
  BiFile,
  BiDuplicate,
  BiChevronRight,
  BiWrench,
} from "react-icons/bi";
import {
  BiSolidDashboard,
  BiSolidDirections,
  BiSolidAddToQueue,
  BiSolidReport,
  BiSolidWrench,
  BiSolidLayer,
} from "react-icons/bi";
import { BiSolidPlusCircle } from "react-icons/bi";

import "./style.css";

import _navList from "../../_nav";
import { useSelector } from "react-redux";

const MainList = (props) => {
  const { collapsed, option, ...rest } = props;
  const [hoveredItems, setHoveredItems] = useState(Array(10).fill(false));
  const [isHighlighted, setHighlighted] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addModalComponent, setAddModalComponent] = useState("item");
  const [isTitleClicked, setIsTitleClicked] = useState(false);
  let openAddModal = (componentName) => {
    setAddModalComponent(componentName);
    onOpen();
  };
  const handleMouseEnter = (index) => {
    setHoveredItems((prev) => {
      const updatedHoveredItems = [...prev];
      updatedHoveredItems[index] = true;
      return updatedHoveredItems;
    });
  };

  const handleMouseLeave = (index) => {
    setHoveredItems((prev) => {
      const updatedHoveredItems = [...prev];
      updatedHoveredItems[index] = false;
      return updatedHoveredItems;
    });
  };
  const handleTitleClick = (option) => {
    if (!option) {
      setIsTitleClicked(true);
      // onTitleClick(isTitleClicked);
    }
  };
  const gotoAddPath = (value) => {
    if (!value?.isModal) {
      openLink(value?.addPath);
    } else {
      openAddModal(value?.addPath);
    }
  };
  let history = useHistory();
  let openLink = (path) => {
    history.push(path);
  };
  const ledgerData = useSelector((state) => state?.ledger);
  const [listDataGroup, setlistDataGroup] = useState(null);
  useEffect(() => {
    setlistDataGroup(ledgerData?.groups);
  }, [ledgerData]);
  return (
    <>
      <AccordionItem
        border={0}
        overflow="visible"
        position="relative"
        role="group"
        {...rest}
      >
        {/* <Tooltip
      label={collapsed ? props.title : null}
      placement="top"
      width="100%"
    > */}
        <AccordionButton
          p="10px"
          display="flex"
          flex="1"
          _hover={{
            bg: {
              lg: "black",
              md: "none",
            },
          }}
        >
          <HStack
            w="100%"
            spacing="0"
            onClick={() => {
              handleTitleClick(props?.option);
            }}
          >
            <NavIcon iconName={props.title} />
            <Box pl={2} textAlign="left" display={collapsed ? "none" : "block"}>
              {props.title}
            </Box>
            <Spacer display={collapsed ? "none" : "block"} />
            {props?.option && (
              <AccordionIcon
                position="relative"
                left="1px"
                display={collapsed ? "none" : "block"}
              />
            )}
          </HStack>
        </AccordionButton>
        {/* </Tooltip> */}

        <Box
          w="250px"
          _groupHover={collapsed ? { display: "block" } : { display: "none" }}
          display="none"
          position={collapsed ? "absolute" : "static"}
          left="100%"
          top="0px"
          zIndex="100"
          pl="10px"
          maxH="50vh"
          onClick={() => {
            handleTitleClick(props?.option);
          }}
        >
          <Stack bg="#2b3a4a" rounded="4px">
            <Accordion allowToggle={props._option}>
              {props &&
                props.children &&
                props.children.map((child, key) => (
                  <React.Fragment key={key}>
                    <AccordionItem
                      border={0}
                      className="menu"
                      position="relative"
                    >
                      <AccordionButton
                        _hover={{
                          bg: {
                            lg: "black",
                            md: "none",
                          },
                        }}
                        onClick={
                          !child._option
                            ? () => {
                                localStorage.setItem(
                                  "transactionTitle",
                                  JSON.stringify(child._title)
                                );
                                openLink(child._path);
                              }
                            : null
                        }
                        onMouseEnter={() => handleMouseEnter(child?.index)}
                        onMouseLeave={() => handleMouseLeave(child?.index)}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <Box>{child._title}</Box>
                          {child?.add &&
                            !child._option &&
                            hoveredItems[child?.index] && (
                              <BiSolidPlusCircle
                                onMouseEnter={() => setHighlighted(true)}
                                onMouseLeave={() => setHighlighted(false)}
                                // colorScheme={isHighlighted ? "teal" : undefined}
                                className={
                                  hoveredItems[child?.index] ? "highlight" : ""
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  gotoAddPath(child);
                                }}
                              />
                            )}
                        </div>
                        {child._option && <BiChevronRight />}
                      </AccordionButton>

                      {child._option && (
                        <>
                          <Box
                            w="250px"
                            display="none"
                            className="submenu"
                            position="absolute"
                            left="100%"
                            top="0px"
                            zIndex="100"
                            pl="3px"
                            maxH="50vh"
                          >
                            <Stack
                              bg="#2b3a4a"
                              rounded="4px"
                              maxH="300px"
                              // _hover={{ bg: "black" }}
                              overflowY="auto"
                              css={{
                                "&::-webkit-scrollbar": {
                                  width: "6px",
                                },

                                "&::-webkit-scrollbar-thumb": {
                                  backgroundColor: "#46505c",
                                  borderRadius: "5px",
                                },

                                "&::-webkit-scrollbar-thumb:hover": {
                                  backgroundColor: "#5b6875",
                                },

                                "&::-webkit-scrollbar-track": {
                                  backgroundColor: "#f1f1f1",
                                },

                                "&::-webkit-scrollbar-track:hover": {
                                  backgroundColor: "#ddd",
                                },
                              }}
                            >
                              {child._optionList &&
                                child._optionList.map((optionVal, opt_key) => (
                                  <Accordion key={opt_key} allowToggle>
                                    <AccordionItem border={0}>
                                      <AccordionButton
                                        _hover={{
                                          bg: {
                                            lg: "black",
                                            md: "none",
                                          },
                                        }}
                                        onClick={() => {
                                          openLink(optionVal && optionVal.path);
                                        }}
                                        onMouseEnter={() =>
                                          handleMouseEnter(optionVal?.index)
                                        }
                                        onMouseLeave={() =>
                                          handleMouseLeave(optionVal?.index)
                                        }
                                      >
                                        {/* <Box flex="1" textAlign="left">
                                      {optionVal && optionVal.title}
                                    </Box> */}
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Box>
                                            {optionVal && optionVal.title}
                                          </Box>
                                          {optionVal?.add &&
                                            props?._title !== "Stock Item" &&
                                            hoveredItems[optionVal.index] && (
                                              <BiSolidPlusCircle
                                                onMouseEnter={() =>
                                                  setHighlighted(true)
                                                }
                                                onMouseLeave={() =>
                                                  setHighlighted(false)
                                                }
                                                // colorScheme={isHighlighted ? "teal" : undefined}
                                                className={
                                                  hoveredItems[optionVal.index]
                                                    ? "highlight"
                                                    : ""
                                                }
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  gotoAddPath(optionVal);
                                                }}
                                              />
                                            )}
                                        </div>
                                      </AccordionButton>
                                    </AccordionItem>
                                  </Accordion>
                                ))}
                            </Stack>
                          </Box>
                        </>
                      )}
                    </AccordionItem>
                  </React.Fragment>
                ))}
            </Accordion>
          </Stack>
        </Box>

        <AccordionPanel display={collapsed ? "none" : "block"} p="0">
          <Stack>
            {props &&
              props.children &&
              props.children.map((child, chi_key) => (
                <InternalList key={chi_key} {...child} />
              ))}
          </Stack>
        </AccordionPanel>
      </AccordionItem>
      <AddModalComponent
        isOpen={isOpen}
        onClose={onClose}
        listDataGroup={listDataGroup}
        componentType={addModalComponent}
        onSuccess={onClose}
      />
    </>
  );
};

const InternalList = (props) => {
  const { collapsed } = props;
  const [isHighlighted, setHighlighted] = useState(false);
  const ledgerData = useSelector((state) => state?.ledger);
  const [listDataGroup, setlistDataGroup] = useState(null);
  useEffect(() => {
    setlistDataGroup(ledgerData?.groups);
  }, [ledgerData]);

  const [hoveredItems, setHoveredItems] = useState(Array(10).fill(false));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [addModalComponent, setAddModalComponent] = useState("item");
  let openAddModal = (componentName) => {
    setAddModalComponent(componentName);
    onOpen();
  };
  const handleMouseEnter = (index) => {
    setHoveredItems((prev) => {
      const updatedHoveredItems = [...prev];
      updatedHoveredItems[index] = true;
      return updatedHoveredItems;
    });
  };

  const handleMouseLeave = (index) => {
    setHoveredItems((prev) => {
      const updatedHoveredItems = [...prev];
      updatedHoveredItems[index] = false;
      return updatedHoveredItems;
    });
  };

  const gotoAddPath = (value) => {
    if (!value?.isModal) {
      openLink(value?.addPath);
    } else {
      openAddModal(value?.addPath);
    }
  };

  let history = useHistory();
  let openLink = (path) => {
    history.push(path);
  };
  return (
    <>
      <Accordion allowToggle={props._option}>
        <AccordionItem border={0} role="group">
          <AccordionButton
            _hover={{
              bg: {
                lg: "black",
                md: "none",
              },
            }}
            onClick={
              !props._option
                ? () => {
                    openLink(props._path);
                  }
                : null
            }
            onMouseEnter={() => handleMouseEnter(props?.index)}
            onMouseLeave={() => handleMouseLeave(props?.index)}
          >
            {/* <Box flex="1" textAlign="left">
              {props._title}
            </Box> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Box>{props._title}</Box>
              {props?.add && hoveredItems[props?.index] && (
                <BiSolidPlusCircle
                  onMouseEnter={() => setHighlighted(true)}
                  onMouseLeave={() => setHighlighted(false)}
                  // colorScheme={isHighlighted ? "teal" : undefined}
                  className={hoveredItems[props?.index] ? "highlight" : ""}
                  onClick={(e) => {
                    e.stopPropagation();
                    gotoAddPath(props);
                  }}
                />
              )}
            </div>
            {props._option && <AccordionIcon />}
          </AccordionButton>
          {props._option && (
            <>
              <Box
                w="250px"
                _groupHover={
                  collapsed ? { display: "block" } : { display: "none" }
                }
                display="none"
                position={collapsed ? "absolute" : "static"}
                left="100%"
                top="0px"
                zIndex="100"
                pl="10px"
                overflowY="auto"
                maxH="50vh"
              >
                <Stack bg="#151F56">
                  {props &&
                    props._optionList &&
                    props._optionList.map((optionVal, opt_key) => (
                      <Accordion key={opt_key} allowToggle>
                        <AccordionItem border={0}>
                          <AccordionButton
                            _hover={{
                              bg: {
                                lg: "black",
                                md: "none",
                              },
                            }}
                            onClick={() => {
                              openLink(optionVal && optionVal.path);
                            }}
                          >
                            <Box flex="1" textAlign="left">
                              {optionVal && optionVal.title}
                            </Box>
                          </AccordionButton>
                        </AccordionItem>
                      </Accordion>
                    ))}
                </Stack>
              </Box>
              <AccordionPanel p="0" display={collapsed ? "none" : "block"}>
                <Stack>
                  {props &&
                    props._optionList &&
                    props._optionList.map((optionVal, opt_key) => (
                      <Accordion key={opt_key} allowToggle>
                        <AccordionItem border={0}>
                          <AccordionButton
                            _hover={{
                              bg: {
                                lg: "black",
                                md: "none",
                              },
                            }}
                            onClick={() => {
                              openLink(optionVal && optionVal.path);
                            }}
                            pl={5}
                            pr={5}
                            onMouseEnter={() =>
                              handleMouseEnter(optionVal?.index)
                            }
                            onMouseLeave={() =>
                              handleMouseLeave(optionVal?.index)
                            }
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              <Box>{optionVal && optionVal.title}</Box>
                              {optionVal?.add &&
                                props?._title !== "Stock Item" &&
                                hoveredItems[optionVal.index] && (
                                  <BiSolidPlusCircle
                                    onMouseEnter={() => setHighlighted(true)}
                                    onMouseLeave={() => setHighlighted(false)}
                                    // colorScheme={isHighlighted ? "teal" : undefined}
                                    className={
                                      hoveredItems[optionVal.index]
                                        ? "highlight"
                                        : ""
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      gotoAddPath(optionVal);
                                    }}
                                  />
                                )}
                            </div>
                          </AccordionButton>
                        </AccordionItem>
                      </Accordion>
                    ))}
                </Stack>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
      <AddModalComponent
        isOpen={isOpen}
        onClose={onClose}
        listDataGroup={listDataGroup}
        componentType={addModalComponent}
        onSuccess={onClose}
      />
    </>
  );
};

function NavIcon({ iconName }) {
  return (
    <Icon
      w="25px"
      h="25px"
      as={
        {
          Dashboard: BiSolidDashboard,
          Transaction: BiSolidDirections,
          Reports: BiSolidReport,
          Add: BiSolidAddToQueue,
          Other: BiSolidLayer,
          Settings: BiSolidWrench,
        }[iconName]
      }
    />
  );
}

const CollapsableSideNavbar = (props) => {
  const { collapsed, ...rest } = props;
  const { settingsLoading } = useSelector((state) => state.common);

  let history = useHistory();
  return (
    <Box
      minWidth={collapsed ? "40px" : "100%"}
      mx="auto"
      {...rest}
      overflow="visible"
    >
      <Accordion allowToggle>
        <AccordionItem border={0}>
          {/* <Tooltip label="Dashboard" placement="right"> */}
          <AccordionButton
            p="10px"
            _hover={{
              bg: {
                lg: "black",
                md: "none",
              },
              width: "100%",
            }}
            onClick={() => {
              history.push("/dashboard");
            }}
          >
            <NavIcon iconName="Dashboard" />
            <Box ml={2} textAlign="left" display={collapsed ? "none" : "block"}>
              Dashboard
            </Box>
          </AccordionButton>
          {/* </Tooltip> */}
        </AccordionItem>

        {_navList.map((value, key) => (
          <MainList key={key} collapsed={collapsed} {...value} />
        ))}
        <AccordionItem border={0} isDisabled={settingsLoading}>
          {/* <Tooltip label="Settings" placement="right"> */}
          <AccordionButton
            p="10px"
            _hover={{
              bg: {
                lg: "black",
                md: "none",
              },
            }}
            onClick={() => {
              history.push("/settings");
            }}
          >
            {settingsLoading ? <Spinner /> : <NavIcon iconName="Settings" />}
            <Box ml={2} textAlign="left" display={collapsed ? "none" : "block"}>
              Settings
            </Box>
          </AccordionButton>
          {/* </Tooltip> */}
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
const addModalComponentList = {
  item: AddItemForm,
  customer: AddCustomer,
  supplier: AddSupplier,
  addunit: AddUnit,
  additemgroup: AddItemGroup,
  addcategory: AddItemCategory,
  addbrand: AddItemBrand,
  addstore: AddStore,
  costcentre: AddCostCentre,
  doneby: AddDoneby,
  addproduction: AddProduction,
  addmode: AddModeOfPayment,
  addvoucher: AddVoucherType,
  addesitmate: AddEstimateStatus,
  group: AddLedgerGroup,
  ledger: AddLedgerForm,
};
//add modal component
function AddModalComponent({ isOpen, onClose, listDataGroup, componentType }) {
  var AddModalComponent = addModalComponentList[componentType];
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={
        componentType === "item" ||
        componentType === "customer" ||
        componentType === "supplier"
          ? "2xl"
          : componentType === "addproduction"
          ? "6xl"
          : "md"
      }
      // size="2xl"
      // // size="md"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <AddModalComponent
            type="add"
            listData={listDataGroup}
            onSuccess={onClose}
            onAddSuccess={onClose}
            handleAddStockItemData={() => {}}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CollapsableSideNavbar;
