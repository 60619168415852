import { useMutation, useQueryClient } from "react-query";
import api, { API_MODE_OF_PAYMENT_LIST_CREATE } from "utils/axios";

const createModeOfPayment = (payment) =>
  api.post(API_MODE_OF_PAYMENT_LIST_CREATE, payment).then((res) => res.data);

export function useCreateModeOfPayment() {
  const queryClient = useQueryClient();
  return useMutation(createModeOfPayment, {
    onSuccess: () => queryClient.refetchQueries("modeofpayment"),
  });
}
