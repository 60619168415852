import { useMutation, useQueryClient } from "react-query";
import api, { API_TRANSACTION_SETTINGS } from "utils/axios";

const updateTransactionSettings = (data) =>
  api
    .patch(
      `${API_TRANSACTION_SETTINGS}${data.id}?transaction_type=${data.transaction_type}`,
      data
    )
    .then((res) => res.data);

export function useUpdateTransactionSettings() {
  const queryClient = useQueryClient();
  return useMutation(updateTransactionSettings, {
    onSuccess: () => queryClient.refetchQueries("transactionSettings"),
  });
}
