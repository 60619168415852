import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  settingsLoading: false,
};

const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setSettingsLoading: (state, action) => {
      state.settingsLoading = action.payload.settingsLoading;
    },
    // setErrorMessage: (state, action) => {
    //   state.error = action.payload.message
    // }
  },
});

export const { setLoading, setSettingsLoading } = common.actions;

export default common.reducer;

export const showLoading = () => async (dispatch) => {
  dispatch(setLoading({ loading: true }));
};

export const hideLoading = () => async (dispatch) => {
  dispatch(setLoading({ loading: false }));
};

export const showSettingsLoading = () => async (dispatch) => {
  dispatch(setSettingsLoading({ settingsLoading: true }));
};

export const hideSettingsLoading = () => async (dispatch) => {
  dispatch(setSettingsLoading({ settingsLoading: false }));
};
