import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import { useToast } from "@chakra-ui/react";
import Form from "../Form";
import RenderComponent from "./RenderComponent";
import { useCreateEstimateStatus } from "hooks/EstimateStatus/useCreateEstimateStatus";
import { useUpdateEstimateStatus } from "hooks/EstimateStatus/useUpdateEstimateStatus";
import { getLocalStore } from "utils/utils";
import { estimateStatusUnderOptions } from "utils/utils/optionsLists";
import { useCostCenters } from "hooks/CostCenter/useCostCenters";
import { useDoneBy } from "hooks/DoneBy/useDoneBy";

const AddEstimateStatus = (props) => {
  const {
    type,
    editValue,
    clearSearch,
    onClose,
    onAddSuccess,
    isApproval = false,
    rejectFunc,
    isRejectLoading = false,
  } = props;
  const [suspand, setSuspand] = useState(false);
  const auth = getLocalStore("AUTH_DETAILS");

  const toast = useToast();
  const { data: costCenterData } = useCostCenters();
  const { data: doneByData } = useDoneBy();

  const {
    mutate: createEstimateStatus,
    data,
    isSuccess: isCreateSuccess,
    isLoading: isCreateLoading,
  } = useCreateEstimateStatus();

  const {
    mutate: updateEstimateStatus,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useUpdateEstimateStatus();

  const initialValue = {
    status: "",
    is_suspended: suspand,
  };
  const [formValues, setformValues] = useState(initialValue);
  const [clearValues, setClearValues] = useState(false);
  const [under, setUnder] = useState([]);
  const [costCenter, setCostCenter] = useState([]);
  const [doneBy, setDoneBy] = useState([]);

  useEffect(() => {
    if (isCreateSuccess) {
      if (data?.status === "success") {
        toastWithMessage("Estimate status changed successfully");
        onAddSuccess(data?.data);
      }
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Estimate status updated successfully");
        onAddSuccess(updateResponse?.data);
      }
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (type === "add") {
      setformValues(initialValue);
      if (costCenterData && doneByData) {
        setCostCenter(
          costCenterData.data?.filter((i) =>
            [
              getLocalStore("DEFAULT_SETTINGS_DATA")?.default_cost_centre,
            ]?.includes(i.id)
          ) || []
        );
        setDoneBy(
          doneByData.data?.filter((i) =>
            [getLocalStore("DEFAULT_SETTINGS_DATA")?.default_done_by]?.includes(
              i.id
            )
          ) || []
        );
      }
    } else {
      setUnder(
        estimateStatusUnderOptions.filter((f) =>
          editValue?.under?.includes(f.value)
        ) || []
      );
      setSuspand(editValue?.is_suspended);
      setformValues(editValue);
      if (costCenterData && doneByData) {
        setCostCenter(
          costCenterData.data?.filter((i) =>
            editValue.cost_centre?.includes(i.id)
          ) || []
        );
        setDoneBy(
          doneByData.data?.filter((i) => editValue.done_by?.includes(i.id)) ||
            []
        );
      }
    }
  }, [type, editValue, costCenterData, doneByData]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Form
      initialValues={{ ...formValues }}
      RenderComponent={RenderComponent}
      handleOnSubmit={async (fields) => {
        fields.is_suspended = suspand;
        fields.under = under?.map((i) => i.value) || [];
        fields.cost_centre = costCenter?.map((i) => i.id) || [];
        fields.done_by = doneBy?.map((i) => i.id) || [];
        if (type === "edit") {
          if (isApproval) {
            fields.approved_by = auth?.data?.id;
          } else {
            delete fields.approved_by;
          }
          updateEstimateStatus(fields);
        } else {
          createEstimateStatus(fields);
          setClearValues(true);
        }
        clearSearch && clearSearch();
        onClose && onClose();
      }}
      customProps={{
        type: type,
        handleResetToComponent: clearValues,
        isLoading: isCreateLoading || isUpdateLoading,
        isSubmitted: isCreateSuccess || isUpdateSuccess,
        setSuspand,
        suspand,
        isApproval,
        rejectFunc,
        isRejectLoading,
        under,
        setUnder,
        costCenter,
        setCostCenter,
        doneBy,
        setDoneBy,
        doneByData,
        costCenterData,
      }}
      validationSchema={object().shape({
        status: string().required("Status Required"),
      })}
    />
  );
};

export default AddEstimateStatus;
