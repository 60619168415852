import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { Box, Flex } from "@chakra-ui/react";

import { getLocalStore } from "./utils/utils";
import Header from "./components/Header";
import LeftNavigation from "./components/LeftNavigation";
import CollapsableLeftNavigation from "./components/CollapsableLeftNavigation";
import { isMobile } from "react-device-detect";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = getLocalStore("AUTH_DETAILS");
  const bgColor = !isMobile ? "brand.bg_light_blue" : "brand.bg_mobile_white";

  var tokenData = undefined;
  const handleTabBlur = () => {
    tokenData = auth.data?.token;
  };

  const handleTabFocus = () => {
    if (tokenData && tokenData !== getLocalStore("AUTH_DETAILS")?.data?.token) {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener("blur", handleTabBlur);
    window.addEventListener("focus", handleTabFocus);
    handleTabFocus();
    return () => {
      window.removeEventListener("blur", handleTabBlur);
      window.removeEventListener("focus", handleTabFocus);
    };
  }, [auth]);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to / page
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          <React.Fragment>
            <Flex bg={bgColor} minHeight="100vh">
              {!isMobile ? <CollapsableLeftNavigation {...auth} /> : null}
              <Box flex="1">
                <Flex flexDirection="column">
                  {/* <Header {...auth}/> */}
                  <Flex h="100vh" overflow="auto">
                    <Component {...props} />
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </React.Fragment>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;
