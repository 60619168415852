import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutocompleteWithAddOption from "FormComponents/AutoComplete/component";

import AddItemGroup from "../AddItemGroup";
import { useItemGroup } from "hooks/ItemGroup/useItemGroup";

export default function GroupAutoComplete(props) {
  const {
    selectedGroup,
    setSelectedGroup,
    modal,
    isReportField = false,
    disabled = false,
  } = props;
  const { data, isLoading } = useItemGroup();
  const [editValue, setEditValue] = useState({ name: "" });
  const [searchData, setSearchData] = useState("");

  const [listData, setlistData] = useState([]);
  const [group, setGroup] = useState(null);

  useEffect(() => {
    let group = listData?.find((item) => item.id === selectedGroup);
    setGroup(group);
  }, [selectedGroup, listData]);

  useEffect(() => {
    if (data) {
      let apiData = data?.data;
      if (isReportField) {
        apiData = [
          { name: "-Not Available-", id: "notAvailableId" },
          ...data.data,
        ];
      }

      let items = apiData
        ?.slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item, i) => item);
      setlistData(items ?? []);
    }
  }, [data]);

  return (
    <>
      <AutocompleteWithAddOption
        value={group}
        options={listData}
        modal={props.modal}
        onChange={(newValue) => {
          setSelectedGroup(newValue?.id);
          setGroup(newValue);
        }}
        inputRef={props.inputRef}
        optionLabel="name"
        setIsOpen={props.setIsOpen}
        inputLabel="Item Group"
        textFieldClick={props.textFieldClick}
        setSearchData={setSearchData}
        disabled={disabled}
      >
        <AddItemGroup
          type="add"
          editValue={editValue}
          listData={listData}
          searchData={searchData}
        />
      </AutocompleteWithAddOption>
    </>
  );
}
