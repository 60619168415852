import { useToast } from "@chakra-ui/react";
import Form from "components/Form";
import { useCreateVoucherType } from "hooks/VoucherType/useCreateVoucherType";
import { useUpdateVoucherType } from "hooks/VoucherType/useUpdateVoucherType";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { object, string } from "yup";
import RenderComponent from "./RenderComponent";
import { getLocalStore } from "utils/utils";
import { useCostCenters } from "hooks/CostCenter/useCostCenters";
import { useDoneBy } from "hooks/DoneBy/useDoneBy";
import { useCreateVoucherPrintSettings } from "hooks/PrintSettings/useCreateVoucherPrintSettings";
import { useUpdateVoucherPrintSettings } from "hooks/PrintSettings/useUpdateVoucherPrintSettings";
import { useVoucherPrintSettings } from "hooks/PrintSettings/useVoucherPrintSettings";
import { InvoiceDataOptions } from "utils/utils/invoiceDataOptionsModel";

const AddVoucherType = (props) => {
  const {
    type,
    editValue,
    clearSearch,
    onClose,
    onAddSuccess,
    searchData,
    isApproval = false,
    rejectFunc,
    isRejectLoading = false,
  } = props;
  const auth = getLocalStore("AUTH_DETAILS");
  const [suspand, setSuspand] = useState(false);
  const toast = useToast();
  const history = useHistory();
  const { data: costCenterData } = useCostCenters();
  const { data: doneByData } = useDoneBy();
  const { data: voucherPrintSettingsData } = useVoucherPrintSettings();

  const {
    mutate: createVoucherType,
    data,
    isSuccess,
    isError,
    isLoading: isCreateLoading,
  } = useCreateVoucherType();

  const {
    mutate: updateVoucherType,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    isLoading: isUpdateLoading,
  } = useUpdateVoucherType();

  const {
    mutate: createPrintSettings,
    isSuccess: isPrintSettingSuccess,
    isError: isPrintSettingError,
    isLoading: isPrintSettingLoading,
  } = useCreateVoucherPrintSettings();

  const {
    mutate: updatePrintSettings,
    isSuccess: isPrintUpdateSettingSuccess,
    isError: isPrintUpdateSettingError,
    isLoading: isPrintUpdateSettingLoading,
  } = useUpdateVoucherPrintSettings();

  const initialValue = {
    name: searchData,
    under: "",
    is_suspended: suspand,
    seperate_print_settings_required: false,
  };

  const [formValues, setformValues] = useState(initialValue);
  const [costCenter, setCostCenter] = useState([]);
  const [doneBy, setDoneBy] = useState([]);

  //settings related
  const [printSettings, setPrintSettings] = useState({
    id: null,
    voucher_group: null,
    printing_model: "ENGLISH_A4",
    invoice_fields_in_print: [],
    terms_and_condition_to_print_invoice: null,
    terms_and_condition_to_pos: null,
    company_name_in_print: "",
    company_address_in_print: "",
    print_previous_balance_also: false,
  });

  const handlePrintSettings = (voucherId) => {
    const payload = {
      ...printSettings,
      voucher_group: voucherId,
      invoice_fields_in_print:
        printSettings.invoice_fields_in_print?.map((i) => i.value) || [],
    };

    if (payload.id) {
      updatePrintSettings(payload);
    } else {
      createPrintSettings(payload);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      if (data?.status === "success") {
        toastWithMessage("success", "Voucher type created successfully");
        if (data?.data?.seperate_print_settings_required) {
          handlePrintSettings(data?.data?.id);
        } else {
          onAddSuccess(data?.data);
        }
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isPrintSettingSuccess) {
      toastWithMessage(
        "success",
        `Voucher print settings created successfully`
      );
      onAddSuccess(data?.data);
    }
  }, [isPrintSettingSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("success", "Voucher type updated successfully");
        if (updateResponse?.data?.seperate_print_settings_required) {
          handlePrintSettings(updateResponse?.data?.id);
        } else {
          onAddSuccess(updateResponse?.data);
        }
      }
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isPrintUpdateSettingSuccess) {
      toastWithMessage(
        "success",
        `Voucher print settings updated successfully`
      );
      onAddSuccess(updateResponse?.data);
    }
  }, [isPrintUpdateSettingSuccess]);

  useEffect(() => {
    if (isError || isUpdateError) {
      toastWithMessage("error", "Something went wrong");
    }
  }, [isError, isUpdateError]);

  useEffect(() => {
    if (type === "add") {
      setformValues(initialValue);
      if (costCenterData && doneByData) {
        setCostCenter(
          costCenterData.data?.filter((i) =>
            [
              getLocalStore("DEFAULT_SETTINGS_DATA")?.default_cost_centre,
            ]?.includes(i.id)
          ) || []
        );
        setDoneBy(
          doneByData.data?.filter((i) =>
            [getLocalStore("DEFAULT_SETTINGS_DATA")?.default_done_by]?.includes(
              i.id
            )
          ) || []
        );
      }
    } else {
      setformValues(editValue);
      setSuspand(editValue.is_suspended);
      if (costCenterData && doneByData) {
        setCostCenter(
          costCenterData.data?.filter((i) =>
            editValue.extras?.cost_centres?.includes(i.id)
          ) || []
        );
        setDoneBy(
          doneByData.data?.filter((i) =>
            editValue.extras?.done_bys?.includes(i.id)
          ) || []
        );
      }
      if (voucherPrintSettingsData) {
        const voucherRelatedSettings = voucherPrintSettingsData.data?.find(
          (f) => f.voucher_group === Number(editValue?.id)
        );

        if (voucherRelatedSettings) {
          let invoiceModel = voucherRelatedSettings?.invoice_fields_in_print;
          const filteredInvoiceModel = InvoiceDataOptions.filter((item) =>
            invoiceModel?.includes(item.value)
          );

          setPrintSettings({
            id: voucherRelatedSettings?.id,
            voucher_group: voucherRelatedSettings?.voucher_group,
            printing_model: voucherRelatedSettings?.printing_model,
            invoice_fields_in_print: filteredInvoiceModel || [],
            terms_and_condition_to_print_invoice:
              voucherRelatedSettings?.terms_and_condition_to_print_invoice,
            terms_and_condition_to_pos:
              voucherRelatedSettings?.terms_and_condition_to_pos,
            company_name_in_print:
              voucherRelatedSettings?.company_name_in_print,
            company_address_in_print:
              voucherRelatedSettings?.company_address_in_print,
            print_previous_balance_also:
              voucherRelatedSettings?.print_previous_balance_also,
          });
        }
      }
    }
  }, [type, editValue, doneByData, costCenterData, voucherPrintSettingsData]);

  const toastWithMessage = (status, message) => {
    toast({
      title: "",
      description: message,
      status: status,
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Form
      initialValues={{ ...formValues }}
      RenderComponent={RenderComponent}
      handleOnSubmit={(fields) => {
        if (
          fields.seperate_print_settings_required &&
          (!printSettings.company_name_in_print ||
            !printSettings.company_address_in_print)
        ) {
          return toast({
            title: "",
            description:
              "Please add company name and company address in print settings",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
        fields.is_suspended = suspand;
        const extras = {
          cost_centres: costCenter?.map((i) => i.id) || [],
          done_bys: doneBy?.map((i) => i.id) || [],
        };
        fields.extras = extras;

        if (type === "edit") {
          if (isApproval) {
            fields.approved_by = auth?.data?.id;
          } else {
            delete fields.approved_by;
          }
          updateVoucherType(fields);
        } else {
          createVoucherType(fields);
        }
        clearSearch && clearSearch();
        onClose && onClose();
      }}
      customProps={{
        type: type,
        isLoading:
          isCreateLoading ||
          isUpdateLoading ||
          isPrintSettingLoading ||
          isPrintUpdateSettingLoading,
        isSubmitted:
          isSuccess ||
          isUpdateSuccess ||
          isPrintSettingSuccess ||
          isPrintUpdateSettingSuccess,
        setSuspand,
        suspand,
        isApproval,
        rejectFunc,
        isRejectLoading,
        costCenter,
        setCostCenter,
        doneBy,
        setDoneBy,
        doneByData,
        costCenterData,
        printSettings,
        setPrintSettings,
      }}
      validationSchema={object().shape({
        name: string().required("Name Required").typeError("Name Required"),
        under: string().required("Select Under Required"),
      })}
    />
  );
};

export default AddVoucherType;
