import { useMutation, useQueryClient } from "react-query";
import api, { API_MODE_OF_PAYMENT_GET_EDIT_DELETE } from "utils/axios";

const updateModeOfPayment = (payment) =>
  api
    .patch(
      API_MODE_OF_PAYMENT_GET_EDIT_DELETE.replace("{id}", payment.id),
      payment
    )
    .then((res) => res.data);

export function useUpdateModeOfPayment() {
  const queryClient = useQueryClient();
  return useMutation(updateModeOfPayment, {
    onSuccess: () => queryClient.refetchQueries("modeofpayment"),
  });
}
