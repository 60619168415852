import { useMutation, useQueryClient } from "react-query";
import api, { API_ESTIMATE_STATUS_LIST_GET_EDIT_DELETE } from "utils/axios";

const updateEstimateStatus = (EstimateStatus) =>
  api
    .patch(
      API_ESTIMATE_STATUS_LIST_GET_EDIT_DELETE.replace(
        "{id}",
        EstimateStatus.id
      ),
      EstimateStatus
    )
    .then((res) => res.data);

export function useUpdateEstimateStatus() {
  const queryClient = useQueryClient();
  return useMutation(updateEstimateStatus, {
    onSuccess: () => queryClient.refetchQueries("estimatestatus"),
  });
}
