import { createSlice } from '@reduxjs/toolkit'

import api, { API_EXPENSE } from '../utils/axios'
import { useDispatch, useSelector } from 'react-redux'

import { showLoading, hideLoading } from './common.slice';
const initialState = {
  data: {},
}

const expense = createSlice({
  name: 'expense',
  initialState,
  reducers: {
    // setLoading: (state, action) => {
    //   state.loading = action.payload.loading
    // },
    setData: (state, action) => {
      state.data = action.payload.data
    },
    // setErrorMessage: (state, action) => {
    //   state.error = action.payload.message
    // }
  }
})

export const {
  setData,
} = expense.actions
export default expense.reducer

export const getExpense = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    const response = await api.get(`${API_EXPENSE}${id}`)
    if (response?.data?.status === "success") {
      dispatch(setData({ data: response.data.data }))
    }
    dispatch(hideLoading())
    return response
  } catch (error) {
    dispatch(hideLoading())
  }
}

export const addExpense = ({ data }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    const response = await api.post(API_EXPENSE, data)
    if (response?.data?.status === "success") {
      // dispatch(setData({ data: response.data.data }))
    }
    dispatch(hideLoading())
    return response
  } catch (error) {
    dispatch(hideLoading())
  }
}

export const editExpense = ({ data, id }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    const response = await api.patch(`${API_EXPENSE}${id}`, data)
    if (response?.data?.status === "success") {
      // dispatch(setData({ data: response.data.data }))
    }
    dispatch(hideLoading())
    return response
  } catch (error) {
    dispatch(hideLoading())
  }
}