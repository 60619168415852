import { useQuery } from "react-query";
import api, { API_ITEMS_LIST } from "utils/axios";

const getSingleItems = async ({ queryKey }) => {
  const [_, id] = queryKey;
  if (id) {
    return api.get(`${API_ITEMS_LIST}${id}`).then((res) => res.data);
  }
};

export function useSingleItems(id) {
  return useQuery(["singleitem", id], getSingleItems);
}
