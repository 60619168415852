import { createSlice } from "@reduxjs/toolkit";

import api, {
  API_CUSTOMER_LIST,
  API_CUSTOMER_ADD,
  API_CUSTOMER_EDIT,
} from "../utils/axios";

import { showLoading, hideLoading } from "./common.slice";

const initialState = {
  loading: false,
  customers: [],
};

const customers = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setCustomer: (state, action) => {
      state.customers = action.payload.customers;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setCustomer, setErrorMessage } = customers.actions;
export default customers.reducer;

export const fetchCustomers = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(API_CUSTOMER_LIST);
    if (response && response.data) {
      dispatch(setCustomer({ customers: response.data.data }));
      dispatch(setLoading({ loading: false }));
      // callback && callback()
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const addCustomer =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.post(API_CUSTOMER_ADD, data);
      if (response && response.data) {
        dispatch(hideLoading());
        if (response.data.status === "success") {
          dispatch(fetchCustomers());
        }
        callback && callback(response.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

export const editCustomer =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.patch(
        API_CUSTOMER_EDIT.replace("{id}", data.id),
        data
      );
      if (response && response.data) {
        dispatch(hideLoading());
        dispatch(fetchCustomers());
        callback && callback(response.data);
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

export const deleteCustomer =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_CUSTOMER_EDIT.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchCustomers());
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };
