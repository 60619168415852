import { createSlice } from "@reduxjs/toolkit";

import api, { API_ITEMS_LIST_REPORT_EXPORT, API_CUSTOMERS_LIST_REPORT_EXPORT, API_SUPPLIERS_LIST_REPORT_EXPORT, API_LEDGERS_LIST_REPORT_EXPORT, API_LEDGER_GROUPS_LIST_REPORT_EXPORT } from '../utils/axios';

const initialState = {
    loading: false,
    next: '',
    previous: '',
    count: 0,
    page: 0,
    total_pages: 0,
    totalGross: 0,
    totalNet: 0,
    totalTax: 0,
    reports: [],
    reportDetails: {}
};

const itemreport = createSlice({
    name: "itemreport",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload.loading;
        },
        setReports: (state, action) => {
            state.reports = action.payload.reports;
            state.count = action.payload.count;
            state.total_pages = action.payload.total_pages;
            state.totalGross = action.payload.total_gross;
            state.totalNet = action.payload.total_net;
            state.totalTax = action.payload.total_tax;
        },
        setReportDetails: (state, action) => {
            state.reportDetails = action.payload.reportDetails;
        },
        setErrorMessage: (state, action) => {
            state.error = action.payload.message;
        },
    },
});

export const { setLoading, setReports, setReportDetails, setErrorMessage } =
    itemreport.actions;

export default itemreport.reducer;

export const exportItemsListReport = (data) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.get(
        API_ITEMS_LIST_REPORT_EXPORT
      );
      if (response?.data?.status === "success") {
        dispatch(setLoading({ loading: false }));
        return response?.data?.data?.url;
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

  export const exportCustomersListReport = (data) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.get(
        API_CUSTOMERS_LIST_REPORT_EXPORT
      );
      if (response?.data?.status === "success") {
        dispatch(setLoading({ loading: false }));
        return response?.data?.data?.url;
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

  export const exportSuppliersListReport = (data) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.get(
        API_SUPPLIERS_LIST_REPORT_EXPORT
      );
      if (response?.data?.status === "success") {
        dispatch(setLoading({ loading: false }));
        return response?.data?.data?.url;
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

  export const exportLedgersListReport = (data) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.get(
        API_LEDGERS_LIST_REPORT_EXPORT
      );
      if (response?.data?.status === "success") {
        dispatch(setLoading({ loading: false }));
        return response?.data?.data?.url;
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

  export const exportLedgerGroupsListReport = (data) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.get(
        API_LEDGER_GROUPS_LIST_REPORT_EXPORT
      );
      if (response?.data?.status === "success") {
        dispatch(setLoading({ loading: false }));
        return response?.data?.data?.url;
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };