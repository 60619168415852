/* eslint-disable react-hooks/exhaustive-deps */
import { HStack } from "@chakra-ui/react";
const IHStack = ({ children, width = "100%" }) => {
  return (
    <HStack width={width} spacing={4}>
      {children}
    </HStack>
  );
};
export default IHStack;
