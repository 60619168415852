import { Box } from "@chakra-ui/react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";

export default function MultiSelectionField(props) {
  const {
    placeholder,
    datas,
    options,
    setOptions,
    inputRef,
    onFocus,
    onKeyDown,
    disabled = false,
    optionLabel = undefined,
    disablePortal = true,
    isLoading = false,
    valueKey = "id",
    incNotAvailable = false,
  } = props;
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [value, setValue] = useState([]);

  const filterMultiSelectOptions = () => {
    const tmpOptions = [];
    let tempData = datas;
    if (incNotAvailable) {
      tempData = [
        { name: "-Not Available-", [valueKey]: "notAvailableId" },
        ...datas,
      ];
    }
    const newValuesIds = options?.map((item) => item[valueKey] ?? item);
    tempData?.forEach((element) => {
      if (!newValuesIds?.includes(element[valueKey])) {
        tmpOptions.push(element);
      }
    });
    if (tmpOptions) setFilteredOptions(tmpOptions);
  };

  useEffect(() => {
    if (datas) {
      filterMultiSelectOptions();
      const selectedValueIds = options?.map((item) => item[valueKey] ?? item);
      let tempData = datas;
      if (incNotAvailable) {
        tempData = [
          { name: "-Not Available-", [valueKey]: "notAvailableId" },
          ...datas,
        ];
      }

      const mappedOptions = tempData?.filter((item) =>
        selectedValueIds?.includes(item[valueKey])
      );
      setValue(mappedOptions || []);
    }
  }, [options, datas]);

  return (
    <Box w="100%">
      <Autocomplete
        size="small"
        multiple
        autoHighlight
        freeSolo
        disablePortal={disablePortal}
        value={value}
        rows={1}
        maxRows={1}
        disabled={disabled}
        options={filteredOptions}
        getOptionLabel={(option) =>
          optionLabel ? option[optionLabel] : option.name
        }
        renderInput={(params) => {
          const { InputProps, ...restParams } = params;
          const { startAdornment, ...restInputProps } = InputProps;
          return (
            <TextField
              {...params}
              variant="outlined"
              placeholder={isLoading ? "Loading. . ." : placeholder}
              isLoading={isLoading}
              disabled={isLoading}
              inputRef={inputRef}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              InputProps={{
                ...restInputProps,
                startAdornment: (
                  <div
                    style={{
                      maxHeight: "100px",
                      overflowY: "auto",
                    }}
                  >
                    {startAdornment}
                  </div>
                ),
              }}
            />
          );
        }}
        onChange={(event, newValue) => setOptions(newValue)}
      />
    </Box>
  );
}
