import { useMutation, useQueryClient } from "react-query";
import api, { API_VOUCHER_TYPE_LIST } from "utils/axios";

const deleteVoucherType = (vouchertype) =>
  api
    .delete(`${API_VOUCHER_TYPE_LIST}${vouchertype.id}`)
    .then((res) => res.data);

export function useDeleteVoucherType() {
  const queryClient = useQueryClient();
  return useMutation(deleteVoucherType, {
    onSuccess: () => queryClient.refetchQueries("vouchertype"),
  });
}
