import { useMutation, useQueryClient } from "react-query";
import api, { API_LEDGER_LIST } from "utils/axios";

const createLedger = (ledger) =>
  api.post(API_LEDGER_LIST, ledger).then((res) => res.data);

export function useCreateLedger() {
  const queryClient = useQueryClient();
  return useMutation(createLedger, {
    onSuccess: () => queryClient.refetchQueries("ledgers"),
  });
}
