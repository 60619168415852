import { useMutation, useQueryClient } from "react-query";
import api, { API_ITEMS_EDIT } from "utils/axios";

const updateItem = (item) =>
  api
    .patch(API_ITEMS_EDIT.replace("{id}", item.id), item)
    .then((res) => res.data);

export function useUpdateItem() {
  const queryClient = useQueryClient();
  return useMutation(updateItem, {
    onSuccess: (data, variables) => {
      queryClient.refetchQueries("items");
      queryClient.setQueryData(["itemsNonPaginated"], (oldData) => {
        if (oldData && variables) {
          return {
            ...oldData,
            data: oldData.data?.map((item) =>
              item.id === variables.id ? variables : item
            ),
          };
        }
      });
    },
  });
}
