/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@chakra-ui/react";
const IButtonOut = ({ children, py, pt = 6, pb }) => {
  return (
    <Box w="100%" py={py} pt={pt} pb={pb} display="flex" gap={2} px={2}>
      {children}
    </Box>
  );
};
export default IButtonOut;
