export const PRINTING_MODELS = [
  { name: "English A4", value: "ENGLISH_A4" },
  { name: "English A5", value: "ENGLISH_A5" },
  // { name: "POS Print", value: "ENGLISH_+_ARABIC_A4" },
  { name: "English Arabic A4", value: "ENGLISH_+_ARABIC_A4" },
  { name: "English + Arabic A5", value: "ENGLISH_+_ARABIC_A5" },
  { name: "English A4 letter head", value: "ENGLISH_A4_LETTER_HEAD" },
  { name: "English A5 letter head", value: "ENGLISH_A5_LETTER_HEAD" },
  {
    name: "English + Arabic A4 letter head",
    value: "ENGLISH_+_ARABIC_A4_LETTER_HEAD",
  },
  {
    name: "English + Arabic A5 letter head",
    value: "ENGLISH_+_ARABIC_A5_LETTER_HEAD",
  },
  { name: "POS Print English", value: "POS_Print_English" },
  { name: "POS Print English + Arabic", value: "POS_Print_English_+_Arabic" },
];
