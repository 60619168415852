import TextField from "@material-ui/core/TextField";
import {
  Box,
  Stack,
  FormControl,
  Button,
  Heading,
  Tooltip,
  Checkbox,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import Message from "../Message";
import useKeypress from "react-use-keypress";
import { useEffect, useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import { saveAndPrintShortKey } from "utils/utils/saveAndPrintShortkey";
import { Autocomplete } from "@material-ui/lab";
import { estimateStatusUnderOptions } from "utils/utils/optionsLists";
import MultiSelectionField from "components/MultiSelectionField";
import {
  ITab,
  IButton,
  IHeadFav,
  ITabPanelIn,
  IModalIn,
  IModalOut,
  IHStack,
  IButtonOut,
  ITabPanel,
  IFieldAndLabel,
  IHead,
  ITextField,
} from "components/IModal";
const RenderComponent = ({
  onClear,
  handleEdit,
  values,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid,
  isSubmitting,
  touched,
  handleReset,
  cancelAction,
}) => {
  const {
    type,
    handleResetToComponent,
    isLoading,
    isSubmitted,
    setSuspand,
    suspand,
    isApproval,
    rejectFunc,
    isRejectLoading,
    under,
    setUnder,
    costCenter,
    setCostCenter,
    doneBy,
    setDoneBy,
    doneByData,
    costCenterData,
  } = customProps;
  const [clickKey, setClickKey] = useState({
    keyA: false,
    // keyP: false,
  });

  const [fieldInFocus, setFieldInFocus] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);

  const nameRef = useRef();
  const statusRef = useRef();
  const costCenterRef = useRef();
  const doneByRef = useRef();
  useEffect(() => {
    if (!isMobile) {
      if (tabIndex === 0) {
        if (fieldInFocus === 1) nameRef.current?.focus();
        if (fieldInFocus === 2) statusRef.current?.focus();
      } else {
        if (fieldInFocus === 1) costCenterRef.current?.focus();
        if (fieldInFocus === 2) doneByRef.current?.focus();
      }
    }
  }, [fieldInFocus]);
  useKeypress(["Enter", "Tab"], (event) => {
    if (event.key === "Enter" && fieldInFocus <= 2) {
      let next = 1;
      if (fieldInFocus === 2 && tabIndex === 0) {
        onSubmit();
      } else if (fieldInFocus === 2 && tabIndex === 1) {
        onSubmit();
      }
      setFieldInFocus(fieldInFocus + next);
    }
    event.stopPropagation();
    event.preventDefault();
  });

  const handleClick = (fieldId) => {
    if (fieldId <= 2) {
      setFieldInFocus(fieldId);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (tabIndex === 0) {
        nameRef.current?.focus();
      } else if (tabIndex === 1) {
        costCenterRef.current?.focus();
      }
    }, 200);
  }, [tabIndex]);
  const [buttonClick, setButtonClick] = useState(false);
  const onSubmit = () => {
    setButtonClick(!buttonClick);
    const validateResponse = validateForm();
    validateResponse.then((data) => {
      submitAction(data);
    });
  };
  useEffect(() => {
    if (handleResetToComponent) handleReset();
  }, [handleResetToComponent]);
  useKeypress(["f1", "F1", "f2", "F2"], (event) => {
    event.preventDefault();
    if (["f1", "F1"].includes(event.key)) {
      setTabIndex(0);
    }
    if (["f2", "F2"].includes(event.key)) {
      setTabIndex(1);
    }
    if (!["f1", "F1", "f2", "F2"].includes(event.key)) {
      event.stopPropagation();
      event.preventDefault();
    }
  });
  useEffect(() => {
    if (clickKey?.keyA && !isLoading && !isSubmitted) {
      onSubmit();
    }
  }, [clickKey]);
  saveAndPrintShortKey(setClickKey);

  return (
    <IModalOut>
      <IHead
        text={
          isApproval
            ? "Approve Estimate Status - " + values.status
            : type === "edit"
            ? "Edit Estimate Status - " + values.status
            : "Add Estimate Status"
        }
      />
      <Box overflowY="auto">
        <Tabs
          isFitted
          onChange={(index) => setTabIndex(index)}
          index={tabIndex}
          colorScheme="blue"
        >
          <TabList mb={3}>
            <ITab tooltipLabel="f1">Basic Info </ITab>
            <ITab tooltipLabel="f2">Other Info</ITab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <IModalIn>
                <FormControl>
                  <ITextField
                    disabled={isApproval}
                    fullWidth
                    label="Estimate Status"
                    variant="outlined"
                    size="small"
                    type="text"
                    name="status"
                    onChange={(event) =>
                      handleEdit(event.target.value, { field: "status" })
                    }
                    inputRef={nameRef}
                    onFocus={(e) => {
                      if (e) {
                        handleClick();
                      }
                    }}
                    value={values.status || ""}
                    isValid={isNotValid}
                    errorMessage={errors.status}
                    isRequired={true}
                    buttonClick={buttonClick}
                  />
                </FormControl>
                <MultiSelectionField
                  placement="Under"
                  datas={estimateStatusUnderOptions}
                  options={under}
                  setOptions={setUnder}
                  optionLabel="label"
                  inputRef={statusRef}
                  onFocus={(e) => {
                    if (e) {
                      handleClick(2);
                    }
                  }}
                />
                <Checkbox
                  disabled={isApproval}
                  isChecked={suspand}
                  onChange={(e) => {
                    setSuspand(!suspand);
                  }}
                >
                  Required Suspend
                </Checkbox>
              </IModalIn>
            </TabPanel>
            <TabPanel p={0}>
              <IModalIn>
                <MultiSelectionField
                  placeholder="Cost Centers"
                  datas={costCenterData?.data}
                  options={costCenter}
                  setOptions={setCostCenter}
                  inputRef={costCenterRef}
                  onFocus={(e) => {
                    if (e) {
                      handleClick(1);
                    }
                  }}
                />
                <MultiSelectionField
                  placeholder="Done Bys"
                  datas={doneByData?.data}
                  options={doneBy}
                  setOptions={setDoneBy}
                  inputRef={doneByRef}
                  onFocus={(e) => {
                    if (e) {
                      handleClick(2);
                    }
                  }}
                />
              </IModalIn>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <IButtonOut>
          {isApproval && (
            <Tooltip
              hasArrow
              label="Reject"
              placement="bottom"
              bg="brand.bg_blue"
            >
              <Button
                w="100%"
                isLoading={isRejectLoading}
                minH="40px"
                type="submit"
                size="lg"
                variant="solid"
                bg="red.600"
                colorScheme="red"
                onClick={() => {
                  rejectFunc();
                }}
              >
                Reject
              </Button>
            </Tooltip>
          )}
          <IButton
            isLoading={isLoading}
            onClick={() => {
              onSubmit();
            }}
            isApproval={isApproval}
            type={type}
          />
        </IButtonOut>
      </Box>
    </IModalOut>
  );
};

export default RenderComponent;
