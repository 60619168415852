/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Tooltip } from "@chakra-ui/react";
const IButton = (props) => {
  const {
    onClick,
    isApproval = false,
    type,
    isLoading = false,
    py,
    pt,
    pb,
    style2 = false,
    tooltip = true,
    disabled = false,
  } = props;
  return (
    <Tooltip
      hasArrow
      label={tooltip ? "ctrl+A" : ""}
      placement="bottom"
      bg={"brand.bg_blue"}
    >
      <Button
        // boxShadow="rgba(149, 157, 165, 0.2) 0px 8px 24px"
        py={py}
        pt={pt}
        pb={pb}
        isLoading={isLoading}
        loadingText="Saving"
        type="submit"
        w="100%"
        minH="40px"
        size="lg"
        variant="solid"
        bg={style2 ? "gray.200" : "brand.bg_blue"}
        colorScheme="brand.bg_blue"
        onClick={onClick}
        className="poppins-regular-m"
        color={style2 ? "black" : "white"}
        disabled={disabled}
      >
        {isApproval
          ? "Approve"
          : type === "edit"
          ? "update"
          : type === "save"
          ? "save"
          : type === "close"
          ? "close"
          : type === "submit"
          ? "submit"
          : "add"}
      </Button>
    </Tooltip>
  );
};
export default IButton;
