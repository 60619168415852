import { useMutation, useQueryClient } from "react-query";
import api, { API_PARTIES_GET_EDIT_DELETE } from "utils/axios";

const updateSupplier = (costCenter) =>
  api
    .patch(
      API_PARTIES_GET_EDIT_DELETE.replace("{id}", costCenter.id),
      costCenter
    )
    .then((res) => res.data);

export function useUpdateSupplier() {
  const queryClient = useQueryClient();
  return useMutation(updateSupplier, {
    onSuccess: () => queryClient.refetchQueries("suppliers"),
  });
}
