import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { setLocalStore } from "utils/utils";
import { getLocalStore } from "utils/utils";
import { useUpdateMainSettings } from "./MainSettings/useUpdateMainSettings";

export function useVoucherDateModel() {
  const toast = useToast();
  const {
    mutate: updateMainSettings,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateMainSettings();

  const auth = getLocalStore("AUTH_DETAILS");
  let companyID = auth?.data?.company.id || "";
  let newDate = new Date();

  const [dateModel, setDateModel] = useState(getLocalStore("VDATE_MODEL"));
  function changeDateModel(vdateModel) {
    let formattedDate;
    if (!vdateModel) {
      const date = new Date(newDate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      formattedDate = `${year}-${month}-${day}`;
      setLocalStore("VDATE_MODEL", formattedDate);
      setDateModel(formattedDate);
    } else {
      setLocalStore("VDATE_MODEL", vdateModel);
      setDateModel(vdateModel);
    }
    // updateDateModel(vdateModel);
  }

  function updateDateModel(vdateModel, close = false) {
    let formattedDate;
    if (!vdateModel && close) {
      const date = new Date(newDate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      formattedDate = `${year}-${month}-${day}`;
      toast({
        title: "",
        description: `Date can't update without select`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      setLocalStore("VDATE_MODEL", vdateModel);
      setDateModel(vdateModel);
      const data = {
        id: companyID,
        settings: {
          invoice_fields_in_print: [],
          item_fields_in_print: {},
          qr_code_in_invoice: null,
          keyword_settings: [],
          name_duplication_alllowed_in: [],
          default_voucher_date: vdateModel,
        },
      };
      updateMainSettings(data);
    }
  }

  const clearVoucherDate = () => {
    setDateModel(null);
    updateDateModel(null, false);
    setLocalStore("VDATE_MODEL", newDate);
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Date Updated Successfully");
      }
    }
  }, [isUpdateSuccess]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  useEffect(() => {
    if (isUpdateError) {
      toast({
        title: "",
        description: `Settings update failed`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [isUpdateError]);

  return [dateModel, changeDateModel, updateDateModel, clearVoucherDate];
}
