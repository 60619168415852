import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { FiEdit3, FiEye, FiMoreVertical, FiTrash2 } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import "./style.css";
function AutoCompleteOptions(props) {
  const history = useHistory();
  const [type, setType] = useState("add");
  const [editValue, setEditValue] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteValue, setDeleteValue] = useState(null);

  const openLedger = (ledger) => {
    history.push({
      // pathname: `/ledger-report`,
      pathname: `/add-ledger`,
      state: { ledger: ledger },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <div className="option-left">
        <span>{props.option.name}</span>
        <span>{props.option.group}</span>
      </div>
      <div style={{ display: "flex", gap: 10 }}>
        <span style={{ fontSize: 16 }}>{props.option.current_balance}</span>
      </div>
    </div>
  );
}

export default AutoCompleteOptions;
