import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { isMobile } from "react-device-detect";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import AutoCompleteOptions from "components/AutoCompleteOptions";
import { useLedgerGroups } from "hooks/LedgerGroup/useLedgerGroups";
import { CloseIcon } from "@chakra-ui/icons";
import { CircularProgress, InputAdornment } from "@material-ui/core";

const filter = createFilterOptions();

const AutocompleteWithAddOption = (props) => {
  const {
    value,
    options,
    onChange,
    onClick,
    optionLabel,
    inputLabel,
    disablePortal,
    disabled,
    inputRef,
    required,
    size,
    onFocus,
    onKeyDown,
    modal,
    setIsOpenModal,
    voucherType,
    renderMoreOptions,
    isLoading = false,
    searchFilter,
    ...rest
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: ledgerGroupsData } = useLedgerGroups();
  const [listData, setlistData] = useState(null);
  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onAddSuccess: (value) => {
          onChange(value);
          props.setIsOpen?.("close");
          setIsOpenModal?.(false);
          onClose();
        },
      });
    }
    return child;
  });

  useEffect(() => {
    let ledgerGroups = ledgerGroupsData?.data ?? [];
    let ledgers = options
      ?.map((ledger) => {
        let group = ledgerGroups?.find((item) => item.uuid === ledger.group);
        let master;
        if (group) {
          master = ledgerGroups?.find((item) => item.uuid === group.master);
        }
        return { ...ledger, group: group?.name, master: master?.name };
      })
      .sort((a, b) => a.name?.localeCompare(b.name));

    setlistData(ledgers);
  }, [options]);

  return (
    <>
      <Autocomplete
        isLoading={isLoading}
        size="small"
        autoHighlight
        disablePortal={disablePortal}
        value={value || ""}
        options={listData}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            onOpen();
          } else if (newValue && newValue.inputValue) {
            onOpen();
            props?.setIsOpen?.("open");
            setIsOpenModal?.(true);
          } else {
            onChange(newValue);
          }
        }}
        getOptionLabel={(option) => option[optionLabel] || ""}
        filterOptions={(options, params) => {
          let filtered = filter(options, params);

          // Limit the display to 20 options when no input
          if (params.inputValue === "") {
            filtered = filtered.slice(0, 20);
          }

          if (searchFilter) {
            let newFiltered = options.filter((f) =>
              f[searchFilter]
                ?.toUpperCase()
                ?.includes(params.inputValue.toUpperCase())
            );
            if (newFiltered && newFiltered.length > 0) {
              return newFiltered.slice(0, 20);
            } else {
              let addItemOption = {
                inputValue: params.inputValue,
              };
              addItemOption[optionLabel] = `Add "${params.inputValue}"`;
              filtered.push(addItemOption);
            }
          } else if (
            params.inputValue !== "" &&
            filtered.every((opt) => opt[optionLabel] !== params.inputValue)
          ) {
            let addItemOption = {
              inputValue: params.inputValue,
            };
            addItemOption[optionLabel] = `Add "${params.inputValue}"`;
            filtered.push(addItemOption);
          }
          return filtered;
        }}
        renderOption={(option) => {
          if (
            [
              "otherReceipt",
              "otherPayment",
              "journalEntry",
              "saleReceipt",
              "purchasePayment",
              "expensePayment",
              "salesreturn-payment",
              "purchasereturn-receipt",
              "customer-set-off",
              "supplier-set-off",
              "addStockItem",
              "contra",
              "customType",
            ].includes(voucherType)
          ) {
            return (
              <Box
                component="li"
                {...props}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Text>{option[optionLabel]}</Text>
                  {option?.group && (
                    <Text fontSize="12px" color="gray.600" as="i">
                      {option.group}
                    </Text>
                  )}
                  {searchFilter && searchFilter !== "name" && (
                    <Text fontSize="12px" color="gray.600" as="i">
                      {option[searchFilter]}
                    </Text>
                  )}
                </Box>
                {!!renderMoreOptions && renderMoreOptions(option)}
              </Box>
            );
          } else {
            return (
              <Box
                component="li"
                {...props}
                style={{ display: "flex", flexDirection: "row", width: "100%" }}
              >
                {option[optionLabel]}
              </Box>
            );
          }
        }}
        renderInput={(params) => (
          <TextField
            onClick={props.textFieldClick}
            {...params}
            onChange={(e) => {
              props.setSearchData?.(e.target.value);
            }}
            required={required}
            label={isLoading ? "Loading. . ." : inputLabel}
            inputRef={inputRef}
            variant="outlined"
            onFocus={onFocus}
            onKeyDown={onKeyDown}
            inputProps={{
              ...params.inputProps,
              style: { fontSize: "12px" },
              ...(isMobile && props.modal
                ? { style: { fontSize: "12px" } }
                : {}),
            }}
            InputLabelProps={
              isMobile && props.modal ? { style: { fontSize: "12px" } } : {}
            }
          />
        )}
        freeSolo
        clearOnBlur
        disabled={disabled || isLoading}
        {...rest}
      />

      <Modal
        isOpen={isOpen}
        onClose={() => {
          props.setIsOpen?.("close");
          setIsOpenModal?.(false);
          onClose();
        }}
        scrollBehavior="inside"
        isCentered
        size={size}
      >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalCloseButton /> */}
          <CloseIcon
            style={{
              margin: 16,
              position: "absolute",
              right: 30,
              cursor: "pointer",
            }}
            onClick={() => {
              onClose();
              props.setIsOpen?.("close");
              setIsOpenModal?.(false);
            }}
          />
          <ModalBody>{childrenWithProps}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

AutocompleteWithAddOption.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  optionLabel: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  disablePortal: PropTypes.bool,
};

AutocompleteWithAddOption.defaultProps = {
  disablePortal: true,
};

export default AutocompleteWithAddOption;
