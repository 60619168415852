import React, { useEffect, useState } from "react";
import { useItemCategory } from "hooks/ItemCategory/useItemCategory";
import AutocompleteWithAddOption from "FormComponents/AutoComplete/component";

import AddItemCategory from "../AddItemCategory";

export default function CategoryAutoComplete(props) {
  const {
    selectedCategory,
    setSelectedCategory,
    onFocus,
    setIsOpen,
    modaldata,
    isReportField = false,
    disabled = false,
  } = props;
  const { data, isLoading } = useItemCategory();

  const [editValue, setEditValue] = useState({ name: "" });
  const [searchData, setSearchData] = useState("");

  const [listData, setlistData] = useState([]);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    let category = listData.find((item) => item.id === selectedCategory);
    setCategory(category);
  }, [selectedCategory, listData]);

  useEffect(() => {
    if (data) {
      let apiData = data?.data;
      if (isReportField) {
        apiData = [
          { name: "-Not Available-", id: "notAvailableId" },
          ...data.data,
        ];
      }
      let categories = apiData
        ?.slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item, i) => item);
      setlistData(categories ?? []);
    }
  }, [data]);

  return (
    <>
      <AutocompleteWithAddOption
        value={category}
        options={listData}
        modal={props.modal}
        onChange={(newValue) => {
          setSelectedCategory(newValue?.id);
          setCategory(newValue);
        }}
        inputRef={props.inputRef}
        optionLabel="name"
        inputLabel="Item Category"
        setIsOpen={setIsOpen}
        textFieldClick={props.textFieldClick}
        setSearchData={setSearchData}
        onFocus={onFocus}
        disabled={disabled}
      >
        <AddItemCategory
          type="add"
          editValue={editValue}
          listData={listData}
          searchData={searchData}
        />
      </AutocompleteWithAddOption>
    </>
  );
}
