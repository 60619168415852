import axios from "axios";

import {
  onRequest,
  onRequestError,
  onResponse,
  onResponseError,
} from "./interceptors";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Request interceptor
api.interceptors.request.use(onRequest, onRequestError);

// Response interceptor
api.interceptors.response.use(onResponse, onResponseError);

export const API_LOGIN = "authentication/login/";
export const API_LOGOUT = "authentication/logout/";
export const API_MULTI_LOGIN_CHECK = "authentication/switch-login/";
export const API_FORGOT_USERNAME = "/authentication/forgot-username-password/";
export const API_RESET_PASSWORD = "/authentication/reset-password/";
export const DASHBOARD_DATA = "master/dashboard";

export const API_STAFFS = "authentication/users/";
export const API_STAFFS_EDIT = "authentication/users/{id}";
export const API_MASTER_COMPANIES = "master/companies/";
export const API_MASTER_LOGIN_COMPANY_GET_EDIT_DELETE = "master/companies/{id}";
export const API_MASTER_COMPANY_GET_EDIT_DELETE =
  "master/companies/settings/{id}";
export const API_MASTER_GROUP_COMPANIES = "master/companygroup/";

export const API_USER_SETUP_GET_EDIT = "master/user-setup/";

export const API_USER_PERMISSIONS = "/master/userpermissions/";
export const API_NOTIFICATIONS = "/notificatoion/notification";

export const API_PERMISSIONS_CHECK = "/master/userpermissionscheck/";
export const API_REPORT_FIELD_PERMISSIONS = "master/reportfieldspermissions/";

export const API_DRAFTS = "inventory/itemutils/draft/";
export const API_APPROVALS = "inventory/approval/";
export const API_ITEMS_MINIMAL_LIST = "inventory/items/minimal/";
export const API_ITEMS_LIST = "inventory/items/";
export const API_ITEMS_ADD = "inventory/items/";
export const API_ITEMS_EDIT = "/inventory/items/{id}";
export const API_ITEM_DATA_CHECK = "/inventory/itemdatacheck/?item={id}";
export const API_CUSTOMER_LIST = "/inventory/parties/?party_type=CUSTOMER";
export const API_CUSTOMER_EDIT = "/inventory/parties/{id}";
export const API_CUSTOMER_ADD = "/inventory/parties/";
export const API_SUPPLIER_LIST = "/inventory/parties/?party_type=SUPPLIER";
export const API_ADVANCES_LIST = "/inventory/advances/?party={party}";
export const API_GROUPS_LIST = "/financial/ledgers/groups/";
export const API_GROUPS_ADD = "/financial/ledgers/groups/";
export const API_GROUPS_EDIT = "/financial/ledgers/groups/{id}";
// export const API_LEDGER_LIST = "/financial/ledgers/";
export const API_LEDGER_ADD = "/financial/ledgers/";
// export const API_LEDGER_EDIT = "/financial/ledgers/{id}";
export const API_SHARING_ROOM_LIST = "inventory/sharingroom/";
export const API_SHARING_ROOM_DELETE = "inventory/sharingroom/{id}";
export const API_UPLOADED_IMAGE_LIST =
  "inventory/images/gallery/?created_at__gte={startDate}&created_at__lte={endDate}&images__image_type={imageType}";
export const API_UPLOADED_IMAGE_DELETE = "inventory/images/gallery/{id}";
export const API_UPLOAD_IMAGE = "inventory/images/gallery/";
export const API_IMPORT_REPORT =
  "imports/{reportType}/?with_item={withItem}&type={type}&file_type={fileType}";

export const API_SALES_REPORTS_EXPORT =
  "report/sales/?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_SALES_REPORT_EXPORT =
  "report/sales/{id}?&file_type={fileType}";
export const API_SALES_RETURN_REPORTS_EXPORT =
  "report/sales_return/?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_SALES_RETURN_REPORT_EXPORT =
  "report/sales_return/{id}?&file_type={fileType}";
export const API_SALE_ESTIMATE_REPORTS_EXPORT =
  "report/estimate/sale/?date__gte={startDate}&date__lte={endDate}&file_type={fileType}";
export const API_SALE_ESTIMATE_REPORT_EXPORT =
  "report/sale_estimate/{id}?&file_type={fileType}";
export const API_PURCHASE_REPORTS_EXPORT =
  "report/purchases/?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_PURCHASE_REPORT_EXPORT =
  "report/purchases/{id}?&file_type={fileType}";
export const API_PURCHASE_RETURN_REPORTS_EXPORT =
  "report/purchases_return/?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_PURCHASE_RETURN_REPORT_EXPORT =
  "report/purchases_return/{id}?&file_type={fileType}";
export const API_EXPENSE_REPORTS_EXPORT =
  "report/expenses/?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_EXPENSE_REPORT_EXPORT =
  "report/expenses/{id}?&file_type={fileType}";
export const API_RECEIPT_REPORT_EXPORT =
  "report/vouchers/{id}?&file_type={fileType}";
export const API_RECEIPT_REPORTS_EXPORT =
  "report/vouchers?date__gte={startDate}&date__lte={endDate}&voucher_category={voucherCategory}&file_type={fileType}&voucher_type={voucherType}&reference_invoice_type={referenceInvoiceType}";
export const API_LEDGER_REPORT_EXPORT =
  "report/ledgers/{id}/book?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_VAT_REPORT_EXPORT =
  "inventory/reports/combined/sheet/?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_JOURNAL_ENTRY_REPORT_EXPORT =
  "report/journals/{id}?&file_type={fileType}";
export const API_JOURNAL_ENTRIES_REPORT_EXPORT =
  "report/journalentry?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_TRIAL_BALANCE_DETAILS_EXPORT =
  "report/trail_balance?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_BALANCE_SHEET_DETAILS_EXPORT =
  "report/bs/?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_DELIVERY_ORDER_REPORTS_EXPORT =
  "report/deliveryorder/?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_DELIVERY_ORDER_REPORT_EXPORT =
  "report/delivery_order/{id}?&file_type={fileType}";
export const API_PROFIT_AND_LOSS_EXPORT =
  "report/p-and-l/?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_STOCK_SUMMARY_REPORT_EXPORT =
  "report/stock/summary?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_LOW_STOCK_REPORT_EXPORT =
  "report/stock/low?start_date={startDate}&end_date={endDate}&status={status}&file_type={fileType}";
export const API_STOCK_LOSS_REPORT_EXPORT =
  "report/stock/loss/?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_STOCK_DETAILED_REPORT_EXPORT =
  "report/stock/detailed?start_date={startDate}&end_date={endDate}&status={status}&file_type={fileType}";
export const API_ITEM_DETAILED_REPORT_EXPORT =
  "report/stock/item/detailed/{id}?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_ITEM_WISE_PROFIT_REPORT_EXPORT =
  "report/stock/item/profit?start_date={startDate}&end_date={endDate}&status={status}&file_type={fileType}";
export const API_ALL_TRANSACTIONS_REPORT_EXPORT =
  "report/reports/all-transactions/?date__gte={startDate}&date__lte={endDate}&status={status}&file_type={fileType}";
export const API_ITEMS_LIST_REPORT_EXPORT = "report/items";
export const API_AGING_REPORT_EXPORT =
  "inventory/reports/{exportReportType}/?as_on={startDate}";
// export const API_CUSTOMERS_LIST_REPORT_EXPORT =
//   "report/customers";
// export const API_SUPPLIERS_LIST_REPORT_EXPORT =
//   "report/suppliers";
export const API_LEDGERS_LIST_REPORT_EXPORT = "report/ledgers";
export const API_ADVANCE_REPORT = "report/advancereport/";
export const API_DONE_BY_CUSTOMER_REPORT =
  "inventory/reports/{basedOn}/?start_date={startDate}&end_date={endDate}&transaction_type={reportType}";
export const API_LEDGER_REPORT_MONTHLY_SUMMARY =
  "financial/ledgers/summary/?date__gte={startDate}&date__lte={endDate}&ledger={ledgerId}";

export const API_SALES_REPORT_LIST =
  "inventory/sales/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&status={status}&with_item={withItem}";
export const API_SALES_RETURN_REPORT_LIST =
  "inventory/salesreturn/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&status={status}";
export const API_SALES_REPORT_FOR_CUSTOMER_LIST =
  "inventory/sales/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&party={party}&status={status}&with_item={withItem}";
export const API_SALE_ESTIMATE_REPORT_LIST =
  "inventory/estimate/sales/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&status={status}";
export const API_PURCHASE_REPORT_LIST =
  "inventory/purchases/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&status={status}&with_item={withItem}";
export const API_PURCHASE_RETURN_REPORT_LIST =
  "inventory/purchasereturn/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&status={status}";
export const API_PURCHASE_REPORT_FOR_CUSTOMER_LIST =
  "inventory/purchases/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&party={party}&status={status}&with_item={withItem}";
export const API_EXPENSE_REPORT_LIST =
  "inventory/expenses/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&status={status}";
export const API_RECEIPT_REPORT_LIST =
  "financial/vouchers/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&voucher_type={voucher_type}&reference_type={reference_type}&reference_invoice_type={reference_invoice_type}";
export const API_LEDGER_REPORT_LIST =
  "financial/ledgers/book/?date__gte={startDate}&date__lte={endDate}&ledger={ledger}";
export const API_CASHBOOK_DETAILS =
  "inventory/reports/finance/cash-bank-book/cash?date__gte={startDate}&date__lte={endDate}&ledger={ledger}";
export const API_BANKBOOK_DETAILS =
  "inventory/reports/finance/cash-bank-book/bank?date__gte={startDate}&date__lte={endDate}&ledger={ledger}";
export const API_VAT_REPORT_LIST =
  "inventory/reports/vat/201/?date__gte={startDate}&date__lte={endDate}";
export const API_JOURNAL_ENTRIES_REPORT_LIST =
  "financial/journal/?date__gte={startDate}&date__lte={endDate}";
export const API_TRIAL_BALANCE_DETAILS =
  "financial/tb/?date__gte={startDate}&date__lte={endDate}";
export const API_BALANCE_SHEET_DETAILS = "financial/bs/?date__lte={endDate}";
export const API_DELIVERY_ORDER_REPORT_LIST =
  "inventory/deliveryorder/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}";
export const API_PROFIT_AND_LOSS =
  "financial/p-and-l/?date__gte={startDate}&date__lte={endDate}";
export const API_STOCK_SUMMARY_REPORT_LIST =
  "inventory/stock/summary/?page={page}&page_size={pageSize}&end_date={endDate}&in_stock={in_stock}&negative_stock={negative_stock}&zero_stock={zero_stock}&track_stock={track_stock}";
export const API_LOW_STOCK_REPORT_LIST =
  "inventory/stock/low/?page={page}&page_size={pageSize}&end_date={endDate}&in_stock={in_stock}&negative_stock={negative_stock}&zero_stock={zero_stock}&track_stock={track_stock}";
export const API_STOCK_LOSS_REPORT_LIST =
  "inventory/stock/loss/?page={page}&page_size={pageSize}&end_date={endDate}";
export const API_STOCK_DETAILED_REPORT_LIST =
  "inventory/stock/detailed/?page={page}&page_size={pageSize}&start_date={startDate}&end_date={endDate}&in_stock={in_stock}&negative_stock={negative_stock}&zero_stock={zero_stock}&track_stock={track_stock}";
export const API_ITEM_WISE_PROFIT_REPORT_LIST =
  "inventory/stock/item/profit/?page={page}&page_size={pageSize}&start_date={startDate}&end_date={endDate}&in_stock={in_stock}&negative_stock={negative_stock}&zero_stock={zero_stock}&track_stock={track_stock}";
export const API_ITEM_DETAILED_REPORT_LIST =
  "inventory/stock/item/detailed/{id}?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}";
export const API_ALL_TRANSACTIONS_REPORT_LIST =
  "inventory/reports/all-transactions/?page={page}&page_size={pageSize}";
export const API_PDC_MANAGEMENT_REPORT_LIST =
  "financial/pdc/?page={page}&page_size={pageSize}&voucher__voucher_type={voucherType}";
export const API_ITEM_WISE_TRANSACTION_REPORT_LIST =
  "inventory/itemwisetransaction/?start_date={startDate}&end_date={endDate}&transaction_type={transactionType}";
export const API_LEDGERWISE_RECEIPT_PAYMENT_SUMMARY =
  "financial/report/voucher/summary/";
export const API_SUMMARY_REPORT = "inventory/voucher/summary/";
export const API_TRANSACTION_SUMMARY = "inventory/transaction/summary/";
export const API_DASHBOARD_SUMMARY = "inventory/dashboard/summary/";

export const API_SALES_REPORT_DELETE = "inventory/sales/{id}";
export const API_PURCHASE_REPORT_DELETE = "inventory/purchases/{id}";
export const API_PURCHASE_RETURN_REPORT_DELETE =
  "inventory/purchasereturn/{id}";
export const API_EXPENSE_REPORT_DELETE = "inventory/expenses/{id}";
export const API_RECEIPT_REPORT_DELETE = "financial/vouchers/{id}";
export const API_JOURNAL_ENTRY_REPORT_DELETE = "financial/journal/{id}";
export const API_DELIVERY_ORDER_REPORT_DELETE = "inventory/deliveryorder/{id}";
export const API_STOCK_LOSS_REPORT_DELETE = "inventory/stock/loss/{id}";

export const API_PURCHASE_REPORT_LIST_BY_PARTY =
  "inventory/purchases/?party={party}&status={status}";
export const API_PURCHASE_REPORT_LIST_BY_IDS = "inventory/purchases/?ids={ids}";
export const API_PURCHASE_RETURN_REPORT_LIST_BY_IDS =
  "inventory/purchasereturn/?ids={ids}";
export const API_PURCHASE_RETURN_REPORT_LIST_BY_PARTY =
  "inventory/purchasereturn/?party={party}&status={status}";
export const API_EXPENSE_REPORT_LIST_BY_PARTY =
  "inventory/expenses/?party={party}&status={status}";
export const API_EXPENSE_REPORT_LIST_BY_IDS = "inventory/expenses/?ids={ids}";
export const API_SALES_REPORT_LIST_BY_PARTY =
  "inventory/sales/?party={party}&status={status}";
export const API_SALES_REPORT_LIST_BY_IDS = "inventory/sales/?ids={ids}";
export const API_SALES_RETURN_REPORT_LIST_BY_PARTY =
  "inventory/salesreturn/?party={party}&status={status}";
export const API_SALES_RETURN_REPORT_LIST_BY_IDS =
  "inventory/salesreturn/?ids={ids}";
export const API_LEDGER_LIST_BY_GROUP_ID =
  "/financial/ledgers/?group__in={group}";
// export const API_LEDGER_LIST_BY_GROUP_ID = '/financial/ledgers/'

export const API_ITEM_GROUP_LIST_CREATE = "/inventory/general/groups/";
export const API_ITEM_GROUP_GET_EDIT_DELETE = "/inventory/general/groups/{id}";
export const API_ITEM_CATEGORY_LIST_CREATE = "/inventory/general/categories/";
export const API_ITEM_CATEGORY_GET_EDIT_DELETE =
  "/inventory/general/categories/{id}";
export const API_ITEM_BRAND_LIST_CREATE = "/inventory/general/brands/";
export const API_ITEM_BRAND_GET_EDIT_DELETE = "/inventory/general/brands/{id}";
export const API_PRODUCTION_LIST = "/inventory/product_list/";
//Add

// voucher type api
export const API_VOUCHER_TYPE_LIST = "/financial/vouchergroup/";
// mode of payment api

export const API_MODE_OF_PAYMENT_LIST_CREATE =
  "/inventory/general/modeofpayment/";
export const API_MODE_OF_PAYMENT_GET_EDIT_DELETE =
  "/inventory/general/modeofpayment/{id}";

// cost centre api
export const API_ITEM_COSTCENTRE_LIST_CREATE =
  "/inventory/general/costcentres/";
export const API_ITEM_COSTCENTRE_GET_EDIT_DELETE =
  "/inventory/general/costcentres/{id}";

// store api
export const API_STORE_LIST_CREATE = "/inventory/store/";

// done by api
export const API_ITEM_DONEBY_LIST_CREATE = "/inventory/general/doneby/";
export const API_ITEM_DONEBY_GET_EDIT_DELETE = "/inventory/general/doneby/{id}";

// parties api

export const API_PARTIES_LIST = "/inventory/parties/?party_type={party_type}";
export const API_PARTIES_CREATE = "/inventory/parties/";
export const API_PARTIES_GET_EDIT_DELETE = "/inventory/parties/{id}";
export const API_CUSTOMERS_LIST_REPORT_EXPORT = "report/customers";
export const API_SUPPLIERS_LIST_REPORT_EXPORT = "report/suppliers";

export const API_ITEM_UNIT_LIST_CREATE = "/inventory/units/";
export const API_ITEM_UNIT_GET_EDIT_DELETE = "/inventory/units/{id}";

// ledgers api
export const API_LEDGER_LIST = "/financial/ledgers/";
export const API_LEDGER_EDIT = "/financial/ledgers/{id}";

// ledger groups api
export const API_LEDGER_GROUPS_LIST = "/financial/ledgers/groups/";
export const API_LEDGER_GROUPS_EDIT_DELETE = "/financial/ledgers/groups/{id}";
export const API_LEDGER_GROUPS_LIST_REPORT_EXPORT = "report/groups";

// master ledger group api
export const API_MASTER_LEDGER_GROUPS = "/financial/ledgers/master_groups/";

export const SALE_WITH_ITEM = "/inventory/sales/";
export const SALE_WITHOUT_ITEM = "/inventory/sales/";

export const PURCHASE_WITHOUT_ITEM = "/inventory/purchases/";
export const PURCHASE_WITH_ITEM = "/inventory/purchases/";

export const PURCHASE_RETURN_WITH_ITEM = "/inventory/purchasereturn/";
// TODO: export const SALE_WITH_ITEM = '/inventory/sales/'

export const API_ESTIMATE_STATUS_LIST = "/inventory/estimate/sales/status/";
export const API_ESTIMATE_STATUS_LIST_GET_EDIT_DELETE =
  "/inventory/estimate/sales/status/{id}";
export const SALE_ESTIMATE_WITH_ITEM = "/inventory/estimate/sales/";
export const API_SALE_ESTIMATE_DELETE = "/inventory/estimate/sales/{id}";
export const SALE_RETURN_WITH_ITEM = "/inventory/salesreturn/";

export const API_DELIVERY_ORDER = "/inventory/deliveryorder/";
export const API_EXPENSE = "/inventory/expenses/";
export const API_RECEIPT_CREATE = "financial/vouchers/";
export const API_RECIEPT_GET_EDIT_DELETE = "financial/vouchers/{id}";
export const API_STOCK_LOSS_ENTRY = "inventory/stock/loss/";
export const API_JOURNAL_ENTRY = "financial/journal/";
export const API_STOCK_JOURNAL = "inventory/stock_journal/";
export const API_STOCK_JOURNAL_MINIMAL = "inventory/stock_journal/minimal";
export const API_PAYMENT_AGAINST_PURCHASE_CREATE = "financial/vouchers/";
export const API_PAYMENT_AGAINST_PURCHASE_GET_EDIT_DELETE =
  "financial/vouchers/{id}";
export const API_PAYMENT_AGAINST_EXPENSE_CREATE = "financial/vouchers/";
export const API_PAYMENT_AGAINST_EXPENSE_GET_EDIT_DELETE =
  "financial/vouchers/{id}";
export const API_SETTINGS = "/financial/settings/";

export const API_AGING_REPORT_LIST =
  "inventory/agingreport/{reportType}/?page={page}&page_size={pageSize}&as_on={startDate}&sorting={sortList}";
// "inventory/agingreport/{reportType}/?as_on={startDate}&date__gte={invoiceStartDate}&date__lte={invoiceEndDate}&due_date__gte={dueStartDate}&due_date__lte={dueEndDate}";

export const API_AGING_REPORT_PARTYWISE_DAILY_LIST =
  "report/agingreport/partywise/?as_on={startDate}&transaction_type={transactionType}&date_format={dateFormat}";
export const API_AGING_REPORT_COSTCENTER_WISE_DAILY_LIST =
  "report/agingreport/costcentrewise/?as_on={startDate}&transaction_type={transactionType}&date_format={dateFormat}";
export const API_AGING_REPORT_DONEBY_WISE_DAILY_LIST =
  "report/agingreport/donebywise/?as_on={startDate}&transaction_type={transactionType}&date_format={dateFormat}";
export const API_AGING_REPORT_PARTYWISE_MONTHLY_LIST =
  "inventory/reports/partywise/monthly/?as_on={startDate}&transaction_type={transactionType}&date_format={dateFormat}";
export const API_AGING_REPORT_COSTCENTER_WISE_MONTHLY_LIST =
  "inventory/reports/costcentrewise/monthly/?as_on={startDate}&transaction_type={transactionType}&date_format={dateFormat}";
export const API_AGING_REPORT_DONEBY_WISE_MONTHLY_LIST =
  "inventory/reports/donebywise/monthly/?as_on={startDate}&transaction_type={transactionType}&date_format={dateFormat}";
export const API_AGING_REPORT_UPDATE_LIST =
  "inventory/agingreport/{reportType}/{id}";
export const API_STOCK_AGING_REPORT = "inventory/stock/aging/";
export const API_STOCK_AGING_REPORTS_EXPORT = "report/stock/aging/";
// follow update history
export const API_FOLLOW_UPDATE_HISTORY = "/inventory/followupdatehistory/";
export const API_PDC_DEPOSIT_HISTORY = "financial/pdc/deposit_history/";
// sale estimate status udpate history
export const API_ESTIMATE_STATUS_HISTORY = "/inventory/estimate/sales/history/";

export const API_SET_OFF_REPORT_LIST =
  "/inventory/partysetoff/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}";
export const API_PARTY_SET_OFF_CREATE = "/inventory/partysetoff/";
export const API_PARTY_SET_OFF_GET_EDIT_DELETE = "/inventory/partysetoff/{id}";
export const API_PDC_GET_EDIT_DELETE = "/financial/pdc/";
export const API_NEXT_INVOICE_NUMBER_GET =
  "/inventory/invoice/number/{invoice_type}/next/";

export const API_PRICE_CATEGORY_NAME = "/inventory/general/pricecategoryname/";

//monthly and daily summary report
export const API_MONTHLY_SUMMARY_REPORT =
  "inventory/{reportType}/?date__gte={startDate}&date__lte={endDate}";
export const API_MONTHLY_SUMMARY_ITEMWISE_REPORT =
  "inventory/stock/item/summary/?start_date={startDate}&end_date={endDate}&track_stock={track_stock}";
export const API_DAILY_SUMMARY_REPORT =
  "inventory/{reportType}/?month={month}&year={year}";
export const API_DAILY_SUMMARY_ITEMWISE_REPORT =
  "inventory/stock/item/summary/daily?start_date={startDate}&end_date={endDate}&track_stock={track_stock}";
export const API_STORE_GROUP_WISE_SUMMARY_REPORT =
  "inventory/stock/{basedOn}/{reportType}/?page={page}&page_size={pageSize}";
export const API_VAT_PLACE_OF_SUPPLY_REPORT =
  "inventory/reports/vat/placeofsupply/?start_date={startDate}&end_date={endDate}";
export const API_VAT_HSN_WISE_REPORT =
  "inventory/reports/vat/hsn/?start_date={startDate}&end_date={endDate}";
export const API_VAT_PARTY_WISE_REPORT =
  "inventory/reports/vat/partywise/?start_date={startDate}&end_date={endDate}";
export const API_VAT_TAX_TYPE_REPORT =
  "inventory/reports/vat/tax_type/?start_date={startDate}&end_date={endDate}";
export const API_VAT_COST_CENTER_REPORT =
  "inventory/reports/vat/cost_centre/?start_date={startDate}&end_date={endDate}";
export const API_VAT_VOUCHER_TYPE_REPORT =
  "inventory/reports/vat/voucher_group/?start_date={startDate}&end_date={endDate}";
export const API_MULTI_UNIT_PRICING = "inventory/items/unit/pricing/";
export const API_MULTI_UNIT_PRICING_EDIT = "inventory/items/{id}/unit/pricing/";

//minimal api for reports
export const API_SALES_REPORT_MINIMAL_LIST =
  "inventory/sales/minimal/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&status={status}&with_item={withItem}";
export const API_SALES_REPORT_FOR_CUSTOMER_MINIMAL_LIST =
  "inventory/sales/minimal/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&party={party}&status={status}&with_item={withItem}";
export const API_SALES_REPORT_LIST_BY_PARTY_MINIMAL =
  "inventory/sales/minimal/?party={party}&status={status}";
export const API_SALES_REPORT_LIST_BY_IDS_MINIMAL =
  "inventory/sales/minimal/?ids={ids}";

export const API_SALES_RETURN_REPORT_LIST_MINIMAL =
  "inventory/salereturns/minimal/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&status={status}";
export const API_SALES_RETURN_REPORT_LIST_BY_PARTY_MINIMAL =
  "inventory/salereturns/minimal/?party={party}&status={status}";
export const API_SALES_RETURN_REPORT_LIST_BY_IDS_MINIMAL =
  "inventory/salereturns/minimal/?ids={ids}";

export const API_PURCHASE_REPORT_LIST_MINIMAL =
  "inventory/purchases/minimal/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&status={status}&with_item={withItem}";
export const API_PURCHASE_REPORT_FOR_CUSTOMER_LIST_MINIMAL =
  "inventory/purchases/minimal/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&party={party}&status={status}&with_item={withItem}";
export const API_PURCHASE_REPORT_LIST_BY_PARTY_MINIMAL =
  "inventory/purchases/minimal/?party={party}&status={status}";
export const API_PURCHASE_REPORT_LIST_BY_IDS_MINIMAL =
  "inventory/purchases/minimal/?ids={ids}";

export const API_PURCHASE_RETURN_REPORT_LIST_MINIMAL =
  "inventory/purchasereturns/minimal/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&status={status}";
export const API_PURCHASE_RETURN_REPORT_LIST_BY_IDS_MINIMAL =
  "inventory/purchasereturns/minimal/?ids={ids}";
export const API_PURCHASE_RETURN_REPORT_LIST_BY_PARTY_MINIMAL =
  "inventory/purchasereturns/minimal/?party={party}&status={status}";

export const API_EXPENSE_REPORT_LIST_MINIMAL =
  "inventory/expenses/minimal/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&status={status}";
export const API_EXPENSE_REPORT_LIST_BY_PARTY_MINIMAL =
  "inventory/expenses/minimal/?party={party}&status={status}";
export const API_EXPENSE_REPORT_LIST_BY_IDS_MINIMAL =
  "inventory/expenses/minimal/?ids={ids}";

export const API_DELIVERY_ORDER_REPORT_LIST_MINIMAL =
  "inventory/deliveryorders/minimal/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}";

export const API_SALE_ESTIMATE_REPORT_LIST_MINIMAL =
  "inventory/estimates/sale/minimal/?page={page}&page_size={pageSize}&date__gte={startDate}&date__lte={endDate}&status={status}";

//Subscription plan settings
export const SUBSCRIPTION_PLAN_CREATION = "master/feature/settings/";

//print settings
export const API_PRINT_SETTINGS = "master/printsettings/";

// cost center and doneby monthly & daily summary report
export const API_COST_CENTER_SUMMARY_REPORT =
  "inventory/reports/{basedOn}/{reportType}/";
export const API_DONEBY_SUMMARY_REPORT =
  "inventory/reports/doneby/{reportType}/?start_date={startDate}&end_date={endDate}";

// party balance summary report
export const API_PARTY_BALANCE_SUMMARY_REPORT =
  "report/party/balance/summary/{party_type}/?as_on={asOn}";

//individual transaction settings
export const API_TRANSACTION_SETTINGS = "master/transaction/settings/";

// draft create, update, delete
export const API_TRANSACTION_DRAFT = "inventory/trasactions/draft/";

// voucher type based print settings
export const API_VOUCHER_PRINT_SETTINGS =
  "master/voucher-group/print-settings/";

export default api;
