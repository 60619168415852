import { useMutation, useQueryClient } from "react-query";
import api, { API_PRICE_CATEGORY_NAME } from "utils/axios";

const createPriceCategoryName = (pricecategoryname) =>
  api.post(API_PRICE_CATEGORY_NAME, pricecategoryname).then((res) => res.data);

export function useCreatePriceCategoryName() {
  const queryClient = useQueryClient();
  return useMutation(createPriceCategoryName, {
    onSuccess: () => queryClient.refetchQueries("pricecategoryname"),
  });
}
