import { useQuery } from "react-query";
import api, { API_PRINT_SETTINGS } from "utils/axios";

const getPrintSettings = () =>
  api.get(API_PRINT_SETTINGS).then((res) => res.data);

export function usePrintSettings() {
  return useQuery("printSettings", getPrintSettings, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
