import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import api, { API_LEDGER_LIST_BY_GROUP_ID } from "utils/axios";

const getLedgersInGroups = async ({ queryKey }) => {
  const [_, groups] = queryKey;
  return api
    .get(API_LEDGER_LIST_BY_GROUP_ID.replace("{group}", groups))
    .then((res) => res.data);
};

export function useLedgersInGroups() {
  const queryClient = useQueryClient();
  const [groups, setGroups] = useState();

  const refetch = () => {
    queryClient.refetchQueries(["ledgers-in-groups", groups]);
  };

  return {
    ...useQuery(["ledgers-in-groups", groups], getLedgersInGroups, {
      // The query will not execute until the groups exists
      enabled: !!groups,
    }),
    setGroups,
    refetch,
  };
}
