import { useEffect, useState } from "react";

import AutocompleteWithAddOption from "FormComponents/AutoComplete/component";
import { useCostCenters } from "hooks/CostCenter/useCostCenters";
import AddCostCentre from "components/AddCostCentre";
import TextField from "@material-ui/core/TextField";

const CostCenterSelectionField = (props) => {
  const {
    costCenter,
    disabled,
    setCostCenter,
    required,
    inputRef,
    onFocus,
    modal,
    setIsOpenCostcenterModal,
    isReportField = false,
  } = props;

  const { data, isLoading } = useCostCenters();

  const [costCenterObject, setCostCenterObject] = useState();
  const [costCenters, setCostCenters] = useState([]);

  useEffect(() => {
    if (data) {
      let apiData = data.data;
      if (isReportField) {
        apiData = [
          { id: "notAvailableId", name: "-Not Available-" },
          ...data.data,
        ];
      }
      setCostCenters(apiData);
    }
  }, [data]);

  useEffect(() => {
    if (costCenters && costCenter) {
      setCostCenterObject(costCenters.find((i) => i.id === costCenter));
    } else {
      setCostCenterObject(null);
    }
  }, [costCenter, costCenters]);

  const setSelectedCostCenter = (selectedCostCenter) => {
    setCostCenter(selectedCostCenter?.id);
  };

  return (
    <AutocompleteWithAddOption
      value={costCenterObject}
      options={costCenters}
      modal={props.modal}
      onChange={setSelectedCostCenter}
      optionLabel="name"
      inputLabel="Cost Center"
      disabled={disabled}
      required={required}
      inputRef={inputRef}
      onFocus={onFocus}
      setIsOpenModal={setIsOpenCostcenterModal}
      isLoading={isLoading}
    >
      <AddCostCentre
        type="add"
        listData={costCenters}
        onAddSuccess={(costCenter) => {
          setSelectedCostCenter(costCenter);
        }}
      />
    </AutocompleteWithAddOption>
  );
};

export default CostCenterSelectionField;
