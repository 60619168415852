import { useQuery } from "react-query";
import api, { API_ITEM_DONEBY_LIST_CREATE } from "utils/axios";

const getSingleDoneBy = ({ queryKey }) => {
  const [_, id] = queryKey;

  if (id) {
    return api
      .get(`${API_ITEM_DONEBY_LIST_CREATE}${id}`)
      .then((res) => res.data);
  }
};

export function useSingleDoneBy(id) {
  return useQuery(["singledoneby", id], getSingleDoneBy);
}
