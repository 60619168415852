import { useQuery } from "react-query";
import api, { API_ESTIMATE_STATUS_LIST } from "utils/axios";

const getSingleEstimateStatus = ({ queryKey }) => {
  const [_, id] = queryKey;

  if (id) {
    return api.get(`${API_ESTIMATE_STATUS_LIST}${id}`).then((res) => res.data);
  }
};

export function useSingleEstimateStatus(id) {
  return useQuery(["singleestimatestatus", id], getSingleEstimateStatus);
}
