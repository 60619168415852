import { createSlice } from "@reduxjs/toolkit";

import api, {
  API_TRIAL_BALANCE_DETAILS,
  API_TRIAL_BALANCE_DETAILS_EXPORT,
} from "../utils/axios";

import { showLoading, hideLoading } from "./common.slice";

const initialState = {
  loading: false,
  error: null,
  trialBalanceDetails: {},
};

const trialbalance = createSlice({
  name: "trialbalance",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setTrialBalanceDetails: (state, action) => {
      state.trialBalanceDetails = action.payload.trialBalanceDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setTrialBalanceDetails, setErrorMessage } =
  trialbalance.actions;

export default trialbalance.reducer;

export const fetchTrialBalance = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    var response = await api.get(
      API_TRIAL_BALANCE_DETAILS.replace("{startDate}", data.startDate).replace(
        "{endDate}",
        data.endDate
      )
    );
    if (response?.data?.status === "success") {
      dispatch(
        setTrialBalanceDetails({ trialBalanceDetails: response.data.data })
      );
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(setTrialBalanceDetails({ trialBalanceDetails: {} }));
  }
};

export const exportTrialBalance = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    console.log("data----------", data);
    const response = await api.get(
      API_TRIAL_BALANCE_DETAILS_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};
