import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  VStack,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Divider,
  Badge,
  IconButton,
  Button,
  FormControl,
  Checkbox,
  Tfoot,
  useToast,
} from "@chakra-ui/react";
import { TextField } from "@material-ui/core";
import Message from "components/Message";
import { useDigitStandardModel } from "hooks/useDigitStandardModel";
import EditProductionItemModal from "pages/Transaction/components/EditProductionItemModal";
import ItemSelectionModal from "pages/Transaction/components/ItemSelectionModal";
import ServiceSelectionModal from "pages/Transaction/components/ServiceSelectionModal";
import React, { useEffect, useState, useRef } from "react";
import { getStandardFormat } from "utils/utils/digitStandards";
import MultiSelectionField from "components/MultiSelectionField";
import { useCostCenters } from "hooks/CostCenter/useCostCenters";
import { useDoneBy } from "hooks/DoneBy/useDoneBy";
import FieldCustomizingPopover from "components/FieldCustomizingPopover";
const RenderComponent = ({
  onClear,
  handleEdit,
  values,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid,
  isSubmitting,
  touched,
  handleReset,
  cancelAction,
}) => {
  const {
    type,
    isLoading,
    decimalLength,
    stockInItems,
    stockOutItems,
    editingItem,
    setEditingItem,
    editItemModal,
    setIsOpenItemSelectionModal,
    setIsOpenServiceModal,
    handleEditStockInItemSubmit,
    handleEditStockOutItemSubmit,
    handleEditStockOutItem,
    handleEditStockInItem,
    handleDeleteStockInItem,
    handleDeleteStockOutItem,
    stockInItemOpen,
    stockOutItemOpen,
    setStockInItemOpen,
    setStockOutItemOpen,
    setSuspand,
    suspand,
    isApproval,
    rejectFunc,
    isRejectLoading,
    costCenters,
    doneBys,
    setCostCenters,
    setDoneBys,
  } = customProps;
  const toast = useToast();

  const { data: costCenterData } = useCostCenters();
  const { data: doneByData } = useDoneBy();
  const costCenterRef = useRef();
  const doneByRef = useRef();

  const [digitStandardModel] = useDigitStandardModel();
  const [isAddService, setIsAddService] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);

  const handleStockInOnOpen = () => {
    setStockInItemOpen(true);
    editItemModal.onOpen();
  };

  const handleStockOutOnOpen = () => {
    setStockOutItemOpen(true);
    editItemModal.onOpen();
  };

  const handleOnClose = () => {
    setStockInItemOpen(false);
    setStockOutItemOpen(false);
    editItemModal.onClose();
  };

  useEffect(() => {
    if (
      values.cost_centre &&
      values.cost_centre?.length > 0 &&
      costCenters.length === 0 &&
      costCenterData
    ) {
      setCostCenters(
        costCenterData.data?.filter((i) =>
          values.cost_centre?.includes(i.id)
        ) || []
      );
    }
  }, [values.cost_centre, costCenterData]);

  useEffect(() => {
    if (
      values.done_by &&
      values.done_by?.length > 0 &&
      doneBys.length === 0 &&
      doneByData
    ) {
      setDoneBys(
        doneByData.data?.filter((i) => values.done_by?.includes(i.id)) || []
      );
    }
  }, [values.done_by, doneByData]);

  useEffect(() => {
    let stockIn = stockInItems?.map((item) => {
      return {
        id: item.rawId || null,
        quantity: item.quantity,
        rate: parseFloat(item.rate).toFixed(decimalLength),
        amount: parseFloat(item.amount).toFixed(decimalLength),
        description: item.description,
        item: item.item,
        ledger: null,
        store: item.store || null,
        // secondary_units: item.secondary_units?.filter((f) => f.id !== null),
        unit: item.unit || null,
      };
    });
    handleEdit(stockIn, { field: "raw_items" });
  }, [stockInItems]);

  useEffect(() => {
    let stockOut = stockOutItems.map((item) => {
      return {
        id: item.productId || null,
        quantity: item.quantity,
        rate: parseFloat(item.rate).toFixed(decimalLength),
        amount: parseFloat(item.amount).toFixed(decimalLength),
        description: item.description,
        item: item.item,
        ledger: null,
        store: item.store || null,
        // secondary_units: item.secondary_units?.filter((f) => f.id !== null),
        unit: item.unit || null,
      };
    });
    handleEdit(stockOut, { field: "products" });
  }, [stockOutItems]);
  const [
    isRequiredToSaveDebitAndCreditNotTally,
    setIsRequiredToSaveDebitAndCreditNotTally,
  ] = useState(false);

  const onAddProductionButtonClick = () => {
    if (!isRequiredToSaveDebitAndCreditNotTally) {
      let stockInItmesTotalAmount = stockInItems.reduce(
        (acc, item) => acc + _parseFloat(item.quantity),
        0
      );
      let stockOutItmesTotalAmount = stockOutItems.reduce(
        (acc, item) => acc + _parseFloat(item.quantity),
        0
      );
      if (stockInItmesTotalAmount !== stockOutItmesTotalAmount) {
        toast({
          title: "",
          description: "Stock In Amount and Stock Out Amount should be equal",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    }

    const validateResponse = validateForm();

    validateResponse.then((data) => {
      submitAction(data);
    });
  };

  const handleSetEditItem = (editingItem) => {
    setEditingItem({
      ...editingItem,
      price: _parseFloat(editingItem.purchase_price_without_tax).toFixed(
        decimalLength
      ),
      price_with_tax: _parseFloat(editingItem.purchase_price_with_tax).toFixed(
        decimalLength
      ),
    });
  };

  return (
    <Box bg="white" p={2} pb={4} w="100%">
      <Stack pb={4}>
        <Box p={2}>
          <HStack>
            <Heading size="lg">
              {isApproval
                ? "Approve Production - " + values?.name
                : type === "edit"
                ? "Edit Production - " + values?.name
                : "Add Production"}
            </Heading>
            <FieldCustomizingPopover
              transactionSettingsChoice={"STANDARD_PRODUCT"}
              setIsRequiredToSaveDebitAndCreditNotTally={
                setIsRequiredToSaveDebitAndCreditNotTally
              }
              customFields={customFields}
              setCustomFields={setCustomFields}
              requiredFields={requiredFields}
              setRequiredFields={setRequiredFields}
            />
          </HStack>
        </Box>
      </Stack>
      <Box display="flex" gap="2">
        <FormControl>
          <TextField
            disabled={isApproval}
            fullWidth
            label="Production Name"
            variant="outlined"
            size="small"
            type="text"
            name="name"
            autoFocus
            onChange={(event) =>
              handleEdit(event.target.value, { field: "name" })
            }
            value={values.name || ""}
          />
          {errors.name && !isNotValid && (
            <Message type="error" message={errors.name} />
          )}
        </FormControl>
        <Box width="187px">
          <MultiSelectionField
            placeholder="Cost Centers"
            datas={costCenterData?.data}
            options={costCenters}
            setOptions={setCostCenters}
            inputRef={costCenterRef}
          />
        </Box>
        <Box width="187px">
          <MultiSelectionField
            placeholder="Done Bys"
            datas={doneByData?.data}
            options={doneBys}
            setOptions={setDoneBys}
            inputRef={doneByRef}
          />
        </Box>
      </Box>
      <Box>
        <HStack w="full" h="full" alignItems="top" py={4}>
          {/*------------ STOCK IN -------------*/}
          <VStack w="50%" h="full">
            <Box w="100%">
              <Heading
                size="md"
                py={2}
                mb={2}
                textAlign="center"
                bgColor="gray.300"
              >
                STOCK IN
              </Heading>

              <Table
                variant="simple"
                size="sm"
                w="100%"
                style={{ tableLayout: "fixed" }}
              >
                <Thead>
                  <Tr>
                    <Th
                      position="sticky"
                      bg="blue.50"
                      top="0"
                      zIndex="1"
                      textColor="green.500"
                      w="40px"
                      pr="0"
                    >
                      #
                    </Th>
                    <Th
                      position="sticky"
                      bg="blue.50"
                      top="0"
                      zIndex="1"
                      textColor="green.500"
                      w="100%"
                      pr="0"
                    >
                      Item Name
                    </Th>
                    <Th
                      position="sticky"
                      bg="blue.50"
                      top="0"
                      zIndex="1"
                      pr="5px"
                      textColor="green.500"
                      w="60px"
                      isNumeric
                    >
                      unit
                    </Th>
                    <Th
                      position="sticky"
                      bg="blue.50"
                      top="0"
                      zIndex="1"
                      pr="5px"
                      textColor="green.500"
                      w="60px"
                      isNumeric
                    >
                      Qty
                    </Th>
                    <Th
                      position="sticky"
                      bg="blue.50"
                      top="0"
                      zIndex="1"
                      pr="5px"
                      textColor="green.500"
                      w="110px"
                      isNumeric
                    >
                      Rate
                    </Th>
                    <Th
                      position="sticky"
                      bg="blue.50"
                      top="0"
                      zIndex="1"
                      pr="5px"
                      textColor="green.500"
                      w="110px"
                      isNumeric
                    >
                      Amount
                    </Th>
                    <Th
                      position="sticky"
                      bg="blue.50"
                      top="0"
                      zIndex="1"
                      pl="5px"
                      pr="5px"
                      textColor="green.500"
                      w="60px"
                      p="0"
                    ></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {console.log("stockInItems", stockInItems)}
                  {stockInItems && stockInItems.length ? (
                    stockInItems.map((item, index) => (
                      <Tr
                        bg="white"
                        cursor="pointer"
                        _hover={{ bg: "gray.100" }}
                      >
                        <Td pr="0">
                          <Badge variant="solid" colorScheme="green">
                            {item.index + 1}
                          </Badge>
                        </Td>
                        <Td>
                          <div>
                            <Text fontWeight="bold">{item.name}</Text>
                            <Text>{item.description}</Text>
                          </div>
                        </Td>
                        <Td isNumeric pl="5px" pr="5px">
                          {item.unit_data?.unit_symbol}
                        </Td>
                        <Td textAlign="right" pr="5px">
                          {item.quantity}
                        </Td>
                        <Td isNumeric pl="5px" pr="5px">
                          {getStandardFormat(
                            parseFloat(item.rate),
                            digitStandardModel,
                            decimalLength
                          )}
                        </Td>
                        <Td isNumeric pl="5px" pr="5px">
                          {getStandardFormat(
                            parseFloat(item.amount),
                            digitStandardModel,
                            decimalLength
                          )}
                        </Td>
                        <Td p="0">
                          <HStack spacing="0">
                            <IconButton
                              disabled={isApproval}
                              size="sm"
                              variant="none"
                              aria-label="Edit"
                              icon={<EditIcon />}
                              onClick={() => handleEditStockInItem(item)}
                            />
                            <IconButton
                              disabled={isApproval}
                              size="sm"
                              variant="none"
                              aria-label="Delete"
                              icon={<DeleteIcon color="red.500" />}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteStockInItem(item);
                              }}
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr bg="white">
                      <Td
                        colSpan="6"
                        textAlign="center"
                        fontStyle="italic"
                        textColor="gray.500"
                        fontSize="smaller"
                      >
                        No Data Available
                      </Td>
                    </Tr>
                  )}
                  {stockInItems && stockInItems.length > 0 && (
                    <Tr bg="white" fontWeight="bold">
                      <Td fontSize="smaller" colSpan={3}>
                        Total
                      </Td>
                      <Td isNumeric pl="5px" pr="5px">
                        {getStandardFormat(
                          parseFloat(
                            stockInItems.reduce(
                              (acc, item) => acc + _parseFloat(item.quantity),
                              0
                            )
                          ),
                          digitStandardModel,
                          decimalLength
                        )}
                      </Td>
                      <Td isNumeric pl="5px" pr="5px"></Td>
                      <Td isNumeric pl="5px" pr="5px">
                        {getStandardFormat(
                          parseFloat(
                            stockInItems.reduce(
                              (acc, item) => acc + _parseFloat(item.amount),
                              0
                            )
                          ),
                          digitStandardModel,
                          decimalLength
                        )}
                      </Td>
                      <Td p="0"></Td>
                    </Tr>
                  )}
                  <Tr bg="white">
                    <Td colSpan="6">
                      <ItemSelectionModal
                        disabled={isApproval}
                        setEditingItem={handleSetEditItem}
                        onOpenEditItemModal={handleStockInOnOpen}
                        setIsOpenItemSelectionModal={
                          setIsOpenItemSelectionModal
                        }
                        handleAddStockItemData={setEditingItem}
                      />
                      <ServiceSelectionModal
                        disabled={isApproval}
                        setEditingItem={setEditingItem}
                        onOpenEditItemModal={editItemModal.onOpen}
                        setIsOpenServiceModal={setIsOpenServiceModal}
                        setIsAddService={setIsAddService}
                      />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </VStack>
          <Divider orientation="vertical" />
          {/*------------ STOCK OUT -------------*/}
          <VStack w="50%" h="full">
            <Box w="100%">
              <Heading size="md" py={2} textAlign="center" bgColor="gray.300">
                STOCK OUT
              </Heading>
            </Box>
            <Table
              variant="simple"
              size="sm"
              w="100%"
              style={{ tableLayout: "fixed" }}
            >
              <Thead>
                <Tr>
                  <Th
                    position="sticky"
                    bg="blue.50"
                    top="0"
                    zIndex="1"
                    textColor="green.500"
                    w="40px"
                    pr="0"
                  >
                    #
                  </Th>
                  <Th
                    position="sticky"
                    bg="blue.50"
                    top="0"
                    zIndex="1"
                    textColor="green.500"
                    w="100%"
                    pr="0"
                  >
                    Item Name
                  </Th>
                  <Th
                    position="sticky"
                    bg="blue.50"
                    top="0"
                    zIndex="1"
                    pr="5px"
                    textColor="green.500"
                    w="60px"
                    isNumeric
                  >
                    unit
                  </Th>
                  <Th
                    position="sticky"
                    bg="blue.50"
                    top="0"
                    zIndex="1"
                    pr="5px"
                    textColor="green.500"
                    w="60px"
                    isNumeric
                  >
                    Qty
                  </Th>
                  <Th
                    position="sticky"
                    bg="blue.50"
                    top="0"
                    zIndex="1"
                    pr="5px"
                    textColor="green.500"
                    w="110px"
                    isNumeric
                  >
                    Rate
                  </Th>
                  <Th
                    position="sticky"
                    bg="blue.50"
                    top="0"
                    zIndex="1"
                    pr="5px"
                    textColor="green.500"
                    w="110px"
                    isNumeric
                  >
                    Amount
                  </Th>
                  <Th
                    position="sticky"
                    bg="blue.50"
                    top="0"
                    zIndex="1"
                    pl="5px"
                    pr="5px"
                    textColor="green.500"
                    w="60px"
                    p="0"
                  ></Th>
                </Tr>
              </Thead>
              <Tbody>
                {stockOutItems && stockOutItems.length ? (
                  stockOutItems.map((item, index) => (
                    <Tr bg="white" cursor="pointer" _hover={{ bg: "gray.100" }}>
                      <Td pr="0">
                        <Badge variant="solid" colorScheme="green">
                          {item.index + 1}
                        </Badge>
                      </Td>
                      <Td>
                        <div>
                          <Text fontWeight="bold">{item.name}</Text>
                          <Text>{item.description}</Text>
                        </div>
                      </Td>
                      <Td isNumeric pl="5px" pr="5px">
                        {item.unit_data?.unit_symbol}
                      </Td>
                      <Td textAlign="right" pr="5px">
                        {item.quantity}
                      </Td>
                      <Td isNumeric pl="5px" pr="5px">
                        {getStandardFormat(
                          parseFloat(item.rate),
                          digitStandardModel,
                          decimalLength
                        )}
                      </Td>
                      <Td isNumeric pl="5px" pr="5px">
                        {getStandardFormat(
                          parseFloat(item.amount),
                          digitStandardModel,
                          decimalLength
                        )}
                      </Td>
                      <Td p="0">
                        <HStack spacing="0">
                          <IconButton
                            disabled={isApproval}
                            size="sm"
                            variant="none"
                            aria-label="Edit"
                            icon={<EditIcon />}
                            onClick={() => handleEditStockOutItem(item)}
                          />
                          <IconButton
                            disabled={isApproval}
                            size="sm"
                            variant="none"
                            aria-label="Delete"
                            icon={<DeleteIcon color="red.500" />}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteStockOutItem(item);
                            }}
                          />
                        </HStack>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr bg="white">
                    <Td
                      colSpan="6"
                      textAlign="center"
                      fontStyle="italic"
                      textColor="gray.500"
                      fontSize="smaller"
                    >
                      No Data Available
                    </Td>
                  </Tr>
                )}
                {stockOutItems && stockOutItems.length > 0 && (
                  <Tr bg="white" fontWeight="bold">
                    <Td fontSize="smaller" colSpan={3}>
                      Total
                    </Td>
                    <Td isNumeric pl="5px" pr="5px">
                      {getStandardFormat(
                        parseFloat(
                          stockOutItems.reduce(
                            (acc, item) => acc + _parseFloat(item.quantity),
                            0
                          )
                        ),
                        digitStandardModel,
                        decimalLength
                      )}
                    </Td>
                    <Td isNumeric pl="5px" pr="5px"></Td>
                    <Td isNumeric pl="5px" pr="5px">
                      {getStandardFormat(
                        parseFloat(
                          stockOutItems.reduce(
                            (acc, item) => acc + _parseFloat(item.amount),
                            0
                          )
                        ),
                        digitStandardModel,
                        decimalLength
                      )}
                    </Td>
                    <Td p="0"></Td>
                  </Tr>
                )}
                <Tr bg="white">
                  <Td colSpan="6">
                    <ItemSelectionModal
                      disabled={isApproval}
                      setEditingItem={handleSetEditItem}
                      onOpenEditItemModal={handleStockOutOnOpen}
                      setIsOpenItemSelectionModal={setIsOpenItemSelectionModal}
                      handleAddStockItemData={setEditingItem}
                    />
                    <ServiceSelectionModal
                      disabled={isApproval}
                      setEditingItem={setEditingItem}
                      onOpenEditItemModal={editItemModal.onOpen}
                      setIsOpenServiceModal={setIsOpenServiceModal}
                      setIsAddService={setIsAddService}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </VStack>
        </HStack>
        <Checkbox
          disabled={isApproval}
          p={2}
          isChecked={suspand}
          onChange={(e) => {
            setSuspand(!suspand);
          }}
        >
          Required Suspend
        </Checkbox>
        <Box display="flex" justifyContent="center" gap={4}>
          {isApproval && (
            <Button
              w="300px"
              isLoading={isRejectLoading}
              minH="40px"
              type="submit"
              size="lg"
              variant="solid"
              bg="red.600"
              colorScheme="red"
              onClick={() => {
                rejectFunc();
              }}
            >
              Reject
            </Button>
          )}
          <Button
            isLoading={isLoading}
            loadingText="Saving"
            w="300px"
            type="submit"
            size="lg"
            variant="solid"
            bg="brand.bg_blue"
            colorScheme="brand.bg_blue"
            onClick={(e) => {
              if (e.detail === 1) {
                onAddProductionButtonClick();
              }
            }}
          >
            {isApproval
              ? "Approve"
              : type === "edit"
              ? "Update"
              : "Add Production"}
          </Button>
        </Box>
      </Box>

      <EditProductionItemModal
        initialValue={editingItem}
        onSubmit={(item) => {
          if (stockInItemOpen) {
            handleEditStockInItemSubmit(item);
          } else if (stockOutItemOpen) {
            handleEditStockOutItemSubmit(item);
          }
        }}
        isOpen={editItemModal.isOpen}
        onClose={handleOnClose}
        isAddService={isAddService}
        setIsAddService={setIsAddService}
        transactionType="production"
        // finalFocusRef={addItemRef}
        // setFieldInFocus={setFieldInFocus}
      />
    </Box>
  );
};

export default RenderComponent;

const _parseFloat = (num) => {
  return parseFloat(num || 0);
};
