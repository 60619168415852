import React, { useEffect, useState } from "react";
import {
  Text,
  HStack,
  Stack,
  Flex,
  Image,
  Spacer,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { useQueryClient } from "react-query";

import logo from "../../assets/logo.svg";
import IpasLogo from "../../assets/ipas-book-logo_cropped.png";
import CollapsableSideNavbar from "../CollapsableSideNavbar/component";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";
import { FiLogOut } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { clearLocalStore, getLocalStore } from "../../utils/utils";

import "./style.css";
import { useLogout } from "hooks/Login/useLogout";

const CollapsableLeftNavigation = ({}) => {
  let history = useHistory();
  const { mutate: logOut, isLoading, isError } = useLogout();

  function tytPreGetBool(pre) {
    return localStorage.getItem(pre) === "true";
  }

  //let collapsedValue = tytPreGetBool("sidenav");

  // const [collapsed, setCollapsed] = useState(false);

  // const toggleSideNav = () => {
  //   //localStorage.setItem("sidenav", !collapsed);
  //   setCollapsed(!collapsed);
  // };

  const auth = getLocalStore("AUTH_DETAILS");
  const CompanyLogo = auth?.data?.company?.logo;
  const [collapsed, setCollapsed] = useState(false);

  const onToggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Flex
      bg="#2b3a4a"
      height="100vh"
      className={!collapsed ? "sidenav collapsed" : "sidenav open"}
      color="white"
      flexShrink="0"
      overflowY={!collapsed ? null : "auto"}
      css={{
        "&::-webkit-scrollbar": {
          width: "6px",
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#46505c",
          borderRadius: "5px",
        },

        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#5b6875",
        },

        "&::-webkit-scrollbar-track": {
          backgroundColor: "#f1f1f1",
        },

        "&::-webkit-scrollbar-track:hover": {
          backgroundColor: "#ddd",
        },
      }}
    >
      <Stack align="stretch" spacing="1" w="100%">
        <Stack align="stretch">
          <HStack minWidth="100%" spacing={3}>
            {/* <Image
              borderRadius="0px 0px 8px 8px"
              boxSize="60px"
              backgroundColor="white"
              src={CompanyLogo ? CompanyLogo : IpasLogo}
              alt=""
            /> */}
            {/* <Text fontWeight="600" d={!collapsed ? "none" : "block"}>
              IPAS
            </Text> */}
          </HStack>
          {/* <Button
            mx='auto'
            borderRadius="md"
            bg="green.500"
            color="white"
            width="100%"
            h="10"
            leftIcon={<LinkIcon w={3} h={3} />}
            disabled
          >
            <Text d={collapsed ? 'none' : 'block'}>Sync Data</Text>
          </Button> */}
          <IconButton
            aria-label="Toggle Sidebar"
            icon={<HamburgerIcon />}
            onClick={onToggleSidebar}
            color="white"
            size="md"
            background="none"
            marginRight="15px"
            _hover={{ bg: "black" }}
          />
        </Stack>
        <Stack align="stretch" pt="2" pb="2" height="100%">
          <CollapsableSideNavbar collapsed={!collapsed} />
          <Spacer />
          {/* <Box>
          <Stack alignItems='flex-start' marginBottom='5'>
            <Box
              as="button"
              color="white"
              width="100%"
              h="10"
              _hover={{ bg: "brand.bg_blue" }}
              d="flex"
              alignItems="center"
              px="5"
              m="0"
              onClick={() => {
                history.push('privacy-policy')
              }}
            >
              Privacy Policy
            </Box>
            <Box
              as="button"
              color="white"
              width="100%"
              h="10"
              _hover={{ bg: "brand.bg_blue" }}
              d="flex"
              alignItems="center"
              px="5"
              m="0"
              onClick={() => {
                history.push('about-us')
              }}
            >
              About Us
            </Box>
            <Box px="4" w="100%">
              <Button w="100%" colorScheme="red" onClick={() => { clearLocalStore(); history.push('/'); }}>Logout</Button>
            </Box>
          </Stack>
        </Box> */}

          {/* <IconButton
          className="collapse_button"
          colorScheme="red"
          aria-label="Search database"
          // variant="ghost"
          onClick={() => {
            clearLocalStore();
            history.push("/");
          }}
          icon={collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        >
          Logout
        </IconButton> */}
          <Stack pl="2">
            <IconButton
              leftIcon={<FiLogOut />}
              colorScheme="red"
              variant="solid"
              onClick={logOut}
            ></IconButton>
          </Stack>

          {/* <IconButton
            className="collapse_button"
            colorScheme="blue"
            aria-label="Search database"
            variant="ghost"
            onClick={() => toggleSideNav()}
            icon={!collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          /> */}
        </Stack>
      </Stack>
    </Flex>
  );
};

export default CollapsableLeftNavigation;
