import React, { useEffect, useRef, useState } from "react";
import { Field } from "formik";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import useKeypress from "react-use-keypress";
import {
  Box,
  Grid,
  GridItem,
  FormControl,
  Input,
  Textarea,
  Button,
  Heading,
  useToast,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  Stack,
  Checkbox,
  Spacer,
} from "@chakra-ui/react";

import { getLocalStore } from "utils/utils";
import DatePicker from "components/DatePicker";
import Message from "components/Message";
import moment from "moment";
import {
  ITab,
  IButton,
  IHeadFav,
  IHead,
  IModalIn,
  IModalOut,
  IHStack,
  ITextarea,
  IVStack,
  IButtonOut,
  ITabPanel,
  IFieldAndLabel,
  ISelect,
} from "components/IModal";
const RenderComponent = ({
  onClear,
  handleEdit,
  values,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid,

  isSubmitting,
  touched,
  handleReset,
  cancelAction,
}) => {
  const { isOpen, closeModal } = customProps;
  const toast = useToast();

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <IModalOut>
            <IHead text={"add advance"} />
            <IModalIn>
              <IHStack>
                <FormControl>
                  <TextField
                    label="Advance No"
                    variant="outlined"
                    size="small"
                    type="text"
                    onChange={(event) =>
                      handleEdit(event.target.value, {
                        field: "invoice_number",
                      })
                    }
                    value={
                      values.invoice_number !== null &&
                      values.invoice_number !== undefined
                        ? values.invoice_number
                        : ""
                    }
                  />
                  {errors.invoice_number && !isNotValid && (
                    <Message type="error" message={errors.invoice_number} />
                  )}
                </FormControl>
                <FormControl>
                  <DatePicker
                    dateLabel="Date"
                    date={values.date || null}
                    setDate={(date) =>
                      handleEdit(moment(date).format("YYYY-MM-DD"), {
                        field: "date",
                      })
                    }
                  />
                  {errors.date && !isNotValid && (
                    <Message type="error" message={errors.date} />
                  )}
                </FormControl>
              </IHStack>
              <FormControl>
                <TextField
                  fullWidth
                  label="Advance Amount"
                  variant="outlined"
                  size="small"
                  type="number"
                  onChange={(event) =>
                    handleEdit(event.target.value, {
                      field: "amount",
                    })
                  }
                  value={
                    values.amount !== null && values.amount !== undefined
                      ? values.amount
                      : ""
                  }
                />
                {errors.amount && !isNotValid && (
                  <Message type="error" message={errors.amount} />
                )}
              </FormControl>
              <FormControl>
                <ITextarea
                  placeholder="Description"
                  type="text"
                  onChange={(event) =>
                    handleEdit(event.target.value, {
                      field: "description",
                    })
                  }
                  value={
                    values.description !== null &&
                    values.description !== undefined
                      ? values.description
                      : ""
                  }
                  onKeyDown={(event) => {
                    if (event.key === "ArrowDown") {
                      handleEdit(values.description + "\n", {
                        field: "description",
                      });
                    }
                  }}
                />
                {errors.description && !isNotValid && (
                  <Message type="error" message={errors.description} />
                )}
              </FormControl>
            </IModalIn>
            <Box pt={6}>
              <IButtonOut>
                <IButton
                  onClick={() => {
                    const validateResponse = validateForm();
                    validateResponse.then((data) => {
                      submitAction(data);
                    });
                  }}
                  type="add"
                />
              </IButtonOut>
            </Box>
          </IModalOut>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RenderComponent;
