import { createSlice } from '@reduxjs/toolkit'

// import { setLocalStore, setResponse } from '../utils/utils'
import api, { API_GROUPS_LIST,API_GROUPS_ADD, API_GROUPS_EDIT } from '../utils/axios'

const initialState = {
  loading: false,
  items: [],
}

const items = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setItems: (state, action) => {
      state.items = action.payload.items
    },
    setItemDetails: (state, action) => {
      state.itemDetails = action.payload.itemDetails
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message
    }
  }
})

export const { setLoading, setItems, setItemDetails, setErrorMessage } = items.actions

export default items.reducer

export const fetchItems = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    // api call
    const response = await api.get(API_GROUPS_LIST)
    if (response && response.data) {
      dispatch(setItems({ items: response.data.data }))
      dispatch(setLoading({ loading: false }))
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
    dispatch(setItems({ items: [] }))
  }
}

export const addItem = ({ data, callback }) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.post(API_GROUPS_ADD, data)
    if (response && response.data) {
      dispatch(setLoading({ loading: false }))
      dispatch(fetchItems())
      callback && callback(response)
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}

export const editItem = ({ data, callback }) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.patch(API_GROUPS_EDIT.replace('{id}', data.id), data)
    if (response && response.data) {
      dispatch(setLoading({ loading: false }))
      dispatch(fetchItems())
      callback && callback(response)
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}

export const deleteItem = ({ data }) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.delete(API_GROUPS_EDIT.replace('{id}', data.id))
    if (response && response.data) {
      dispatch(setLoading({ loading: false }))
      dispatch(fetchItems())
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}