import { createSlice } from "@reduxjs/toolkit";
import api, {
  API_MONTHLY_SUMMARY_REPORT,
  API_MONTHLY_SUMMARY_ITEMWISE_REPORT,
  API_DAILY_SUMMARY_REPORT,
  API_DAILY_SUMMARY_ITEMWISE_REPORT,
} from "../utils/axios";

const initialState = {
  loading: false,
  next: "",
  previous: "",
  totalGross: 0,
  totalTax: 0,
  totalNet: 0,
  totalBalance: 0,
  reports: [],
  reportDetails: {},
};

const summaryreport = createSlice({
  name: "summaryreport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReports: (state, action) => {
      state.reports = action.payload.reports;
      state.totalGross = action.payload.totalGross;
      state.totalTax = action.payload.totalTax;
      state.totalNet = action.payload.totalNet;
      state.totalBalance = action.payload.totalBalance;
    },
    setReportDetails: (state, action) => {
      state.reportDetails = action.payload.reportDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setReports, setReportDetails, setErrorMessage } =
  summaryreport.actions;

export default summaryreport.reducer;

export const fetchMonthlySummaryReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url;
      if (data.reportType === "itemWiseProfit") {
        url = API_MONTHLY_SUMMARY_ITEMWISE_REPORT.replace(
          "{startDate}",
          data.startDate
        )
          .replace("{endDate}", data.endDate)
          .replace("{track_stock}", data.nonTrackingStock);
      } else {
        url = API_MONTHLY_SUMMARY_REPORT.replace(
          "{reportType}",
          data.reportType
        )
          .replace("{startDate}", data.startDate)
          .replace("{endDate}", data.endDate);
        if (data.sort && data.sort !== "") {
          url = url + `&sort=${data.sort}`;
        }
        if (data.documentType && data.documentType !== "") {
          url += `&document_type=${data.documentType}`;
        }
      }
      if (data.costCenter && data.costCenter !== "") {
        if (data.costCenter === "notAvailableId") {
          url += `&not_available_field=cost_centre`;
        } else {
          url += `&cost_centre=${data.costCenter}`;
        }
      }
      if (data.doneBy && data.doneBy !== "") {
        if (data.doneBy === "notAvailableId") {
          url += `&not_available_field=done_by`;
        } else {
          url += `&done_by=${data.doneBy}`;
        }
      }
      if (data.party && data.party !== "") {
        url += `&party=${data.party}`;
      }
      if (data.taxType && data.taxType !== "") {
        if (data.taxType === "notAvailableId") {
          url += `&not_available_field=tax_type`;
        } else {
          url += `&tax_type=${data.taxType}`;
        }
      }
      if (data.voucherType && data.voucherType !== "") {
        url += `&voucher_group=${data.voucherType}`;
      }
      if (data.modeOfPayment && data.modeOfPayment !== "") {
        if (data.modeOfPayment === "notAvailableId") {
          url += `&not_available_field=mode_or_terms_of_payment`;
        } else {
          url = url + `&mode_or_terms_of_payment=${data.modeOfPayment}`;
        }
      }
      if (data.store && data.store !== "") {
        url += `&store=${data.store}`;
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data,
            totalGross: response.data.data.total_gross,
            totalTax: response.data.data.total_tax,
            totalNet: response.data.data.total_net,
            totalBalance: response.data.data.total_balance,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const fetchDailySummaryReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url;
      if (data.reportType === "itemWiseProfit") {
        url = API_DAILY_SUMMARY_ITEMWISE_REPORT.replace(
          "{startDate}",
          data.startDate
        )
          .replace("{endDate}", data.endDate)
          .replace("{track_stock}", data.nonTrackingStock);
      } else {
        url = API_DAILY_SUMMARY_REPORT.replace("{reportType}", data.reportType)
          .replace("{month}", data.month)
          .replace("{year}", data.year);
        if (data.sort && data.sort !== "") {
          url += `&sort=${data.sort}`;
        }
        if (data.documentType && data.documentType !== "") {
          url += `&document_type=${data.documentType}`;
        }
      }
      if (data.costCenter && data.costCenter !== "") {
        if (data.costCenter === "notAvailableId") {
          url += `&not_available_field=cost_centre`;
        } else {
          url += `&cost_centre=${data.costCenter}`;
        }
      }
      if (data.doneBy && data.doneBy !== "") {
        if (data.doneBy === "notAvailableId") {
          url += `&not_available_field=done_by`;
        } else {
          url += `&done_by=${data.doneBy}`;
        }
      }
      if (data.party && data.party !== "") {
        url += `&party=${data.party}`;
      }
      if (data.taxType && data.taxType !== "") {
        if (data.taxType === "notAvailableId") {
          url += `&not_available_field=tax_type`;
        } else {
          url += `&tax_type=${data.taxType}`;
        }
      }
      if (data.voucherType && data.voucherType !== "") {
        url += `&voucher_group=${data.voucherType}`;
      }
      if (data.modeOfPayment && data.modeOfPayment !== "") {
        if (data.modeOfPayment === "notAvailableId") {
          url += `&not_available_field=mode_or_terms_of_payment`;
        } else {
          url = url + `&mode_or_terms_of_payment=${data.modeOfPayment}`;
        }
      }
      if (data.store && data.store !== "") {
        url += `&store=${data.store}`;
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data,
            totalGross: response.data.data.total_gross,
            totalTax: response.data.data.total_tax,
            totalNet: response.data.data.total_net,
            totalBalance: response.data.data.total_balance,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const clearSummaryReports = (data, callback) => async (dispatch) => {
  dispatch(setReports({ ...initialState }));
};
