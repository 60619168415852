import { useMutation, useQueryClient } from "react-query";
import api, { API_PARTIES_CREATE } from "utils/axios";

const createSupplier = (supplier) =>
  api.post(API_PARTIES_CREATE, supplier).then((res) => res.data);

export function useCreateSupplier() {
  const queryClient = useQueryClient();
  return useMutation(createSupplier, {
    onSuccess: () => queryClient.refetchQueries("suppliers"),
  });
}
