/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from "@chakra-ui/react";
const IModalIn = ({ children }) => {
  return (
    <Stack mt={6} mb={1} gap={2} px={2}>
      {children}
    </Stack>
  );
};
export default IModalIn;
