import { combineReducers } from "@reduxjs/toolkit";

import snackbarReducer from "../slices/snackbar.slice";
import sessionReducer from "../slices/session.slice";
import itemsReducer from "../slices/items.slice";
import customersReducer from "../slices/customers.slice";
import supplierReducer from "../slices/supplier.slice";
import groupsReducer from "../slices/groups.slice";
import ledgerReducer from "../slices/ledger.slice";
import salesreportReducer from "../slices/salesreport.slice";
import dashboardReducer from "../slices/dashboard.slice";
import purchasereportReducer from "../slices/purchasereport.slice";
import expensereportReducer from "../slices/expensereport.slice";
import receiptreportReducer from "../slices/receiptreport.slice";
import ledgerreportReducer from "../slices/ledgerreport.slice";
import vatreportReducer from "../slices/vatreport.slice";
import journalentriesreportReducer from "../slices/journalentriesreport.slice";
import salewithoutitemReducer from "../slices/salewithoutitem.slice";
import purchasewithoutitemReducer from "../slices/purchasewithoutitem.slice";
import salewithitemReducer from "../slices/salewithitem.slice";
import estimatewithitemReducer from "../slices/estimatewithitem.slice";
import purchasewithitemReducer from "../slices/purchasewithitem.slice";
import salesreturnwithitemReducer from "../slices/salesreturnwithitem.slice";
import salesreturnwithoutitemReducer from "../slices/salesreturnwithoutitem.slice";
import purchasereturnwithitemReducer from "../slices/purchasereturnwithitem.slice";
import purchasereturnwithoutitemReducer from "../slices/purchasereturnwithoutitem.slice";

import sharingroomReducer from "../slices/sharingroom.slice";
import commonReducer from "../slices/common.slice";
import uploadimageReducer from "../slices/uploadimage.slice";
import generalReducer from "../slices/general.slice";
import expenseReducer from "../slices/expense.slice";
import stocklossReducer from "../slices/stockloss.slice";
import cashbookReducer from "../slices/cashbook.slice";
import trialbalanceReducer from "../slices/trialbalance.slice";
import balancesheetReducer from "../slices/balancesheet.slice";
import deliveryorderreportReducer from "../slices/deliveryorderreport.slice";
import advanceReducer from "../slices/advance.slice";
import profitandlosseReducer from "../slices/profitandloss.slice";
import stockreportReducer from "../slices/stockreport.slice";
import alltransactionsreportReducer from "../slices/alltransactionsreport.slice";
import salesreturnreportReducer from "../slices/salesreturnreport.slice";
import purchasereturnreportReducer from "../slices/purchasereturnreport.slice";
import agingreportReducer from "../slices/agingreport.slice";
import pdcmanagementReducer from "../slices/pdcmanagement.slice";
import settingsReducer from "../slices/settings.slice";
import partysetoffreportReducer from "../slices/partysetoffreport.slice";
import importreportsReducer from "slices/importreports.slice";
import summaryreportsReducer from "slices/summaryreport.slice";

const reducerList = {
  snackbar: snackbarReducer,
  session: sessionReducer,
  items: itemsReducer,
  customers: customersReducer,
  suppliers: supplierReducer,
  groups: groupsReducer,
  ledger: ledgerReducer,
  sharingroom: sharingroomReducer,
  uploadimage: uploadimageReducer,
  salesreport: salesreportReducer,
  purchasereport: purchasereportReducer,
  expensereport: expensereportReducer,
  receiptreport: receiptreportReducer,
  ledgerreport: ledgerreportReducer,
  vatreport: vatreportReducer,
  journalentriesreport: journalentriesreportReducer,
  dashboard: dashboardReducer,
  salewithoutitem: salewithoutitemReducer,
  salewithitem: salewithitemReducer,
  estimatewithitem: estimatewithitemReducer,
  purchasewithitem: purchasewithitemReducer,
  purchasewithoutitem: purchasewithoutitemReducer,
  salesreturnwithitem: salesreturnwithitemReducer,
  salesreturnwithoutitem: salesreturnwithoutitemReducer,
  purchasereturnwithitem: purchasereturnwithitemReducer,
  purchasereturnwithoutitem: purchasereturnwithoutitemReducer,

  common: commonReducer,
  general: generalReducer,
  expense: expenseReducer,
  stockloss: stocklossReducer,
  cashbook: cashbookReducer,
  trialbalance: trialbalanceReducer,
  balancesheet: balancesheetReducer,
  deliveryorder: deliveryorderreportReducer,
  advance: advanceReducer,
  profitandloss: profitandlosseReducer,
  stockreport: stockreportReducer,
  alltransactionsreport: alltransactionsreportReducer,
  salesreturnreport: salesreturnreportReducer,
  purchasereturnreport: purchasereturnreportReducer,
  agingreport: agingreportReducer,
  pdcmanagement: pdcmanagementReducer,
  settings: settingsReducer,
  partysetoffreport: partysetoffreportReducer,
  importreports: importreportsReducer,
  summaryreport: summaryreportsReducer,
};

export default combineReducers(reducerList);
