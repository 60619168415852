import { useMutation, useQueryClient } from "react-query";
import api, { API_ITEM_DONEBY_GET_EDIT_DELETE } from "utils/axios";

const updateDoneBy = (costCenter) =>
  api
    .patch(
      API_ITEM_DONEBY_GET_EDIT_DELETE.replace("{id}", costCenter.id),
      costCenter
    )
    .then((res) => res.data);

export function useUpdateDoneBy() {
  const queryClient = useQueryClient();
  return useMutation(updateDoneBy, {
    onSuccess: () => queryClient.refetchQueries("doneby"),
  });
}
