import { useQuery } from "react-query";
import api, { API_VOUCHER_TYPE_LIST } from "utils/axios";

const getSingleVoucherType = ({ queryKey }) => {
  const [_, id] = queryKey;

  if (id) {
    return api.get(`${API_VOUCHER_TYPE_LIST}${id}`).then((res) => res.data);
  }
};

export function useSingleVoucherType(id) {
  return useQuery(["singlevouchertype", id], getSingleVoucherType);
}
