import { useMutation, useQueryClient } from "react-query";
import api, { API_VOUCHER_TYPE_LIST } from "utils/axios";

const updateVoucherType = (vouchertype) =>
  api
    .patch(`${API_VOUCHER_TYPE_LIST}${vouchertype.id}`, vouchertype)
    .then((res) => res.data);

export function useUpdateVoucherType() {
  const queryClient = useQueryClient();
  return useMutation(updateVoucherType, {
    onSuccess: () => queryClient.refetchQueries("vouchertype"),
  });
}
