import React, { useEffect, useState } from "react";
import { number, object, string } from "yup";

import { useToast } from "@chakra-ui/react";

import Form from "../../components/Form";

import RenderComponent from "./RenderComponent";
import { useDecimalLength } from "hooks/useDecimalLength";
import { useCreateItem } from "hooks/Item/useCreateItem";
import { useUpdateItem } from "hooks/Item/useUpdateItem";
import { useTaxModel } from "hooks/useTaxModel";
import { getLocalStore } from "utils/utils";
import { useItems } from "hooks/Item/useItems";

const AddItem = (props) => {
  const {
    type,
    editValue,
    onSuccess,
    handleAddStockItemData,
    isApproval = false,
    rejectFunc,
    isRejectLoading = false,
  } = props;
  const auth = getLocalStore("AUTH_DETAILS");
  const [decimalLength] = useDecimalLength();
  const [taxModel] = useTaxModel();
  const { data: itemData, refetch } = useItems();
  const {
    mutate: createItem,
    data: createResponse,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
    isLoading: isCreateLoading,
  } = useCreateItem();

  const {
    mutate: updateItem,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    isLoading: isUpdateLoading,
  } = useUpdateItem();

  const toast = useToast();

  const initialValue = {
    name: "",
    item_code: "",
    unit: getLocalStore("UNIT_MODEL")?.id,
    price: "",
    tax: parseFloat(getLocalStore("TAX_MODEL")),
    opening_stock: 0,
    cost_price: 0,
    opening_stock_value: 0,
    minimum_stock: 0,
    maximum_stock: 0,
    brand: null,
    category: null,
    group: null,
    is_suspended: false,
    favourites: false,
    price_categories: [],
    secondary_units: [],
    min_max_stock: [],
    cost_centres: getLocalStore("DEFAULT_SETTINGS_DATA")?.default_cost_centre
      ? [getLocalStore("DEFAULT_SETTINGS_DATA")?.default_cost_centre]
      : [],
    done_bys: getLocalStore("DEFAULT_SETTINGS_DATA")?.default_done_by
      ? [getLocalStore("DEFAULT_SETTINGS_DATA")?.default_done_by]
      : [],
  };

  const [formValues, setformValues] = useState(initialValue);
  const [tax, setTax] = useState("");
  const [price, setPrice] = useState("");
  const [priceWithTax, setPriceWithTax] = useState("");
  const [showTrack, setShowTrack] = useState(false);

  const [isToggledFav, setIsToggledFav] = useState(false);
  const [suspand, setSuspand] = useState(false);
  const [isService, setIsService] = useState(false);

  const [p_priceWithTax, setP_PriceWithTax] = useState("");
  const [p_priceWithOutTax, setP_PriceWithOutTax] = useState("");
  const [discountWithOutTax, setDiscountWithOutTax] = useState("");
  const [discountWithTax, setDiscountWithTax] = useState("");
  const [purchasePriceWithOutTax, setPurchasePriceWithOutTax] = useState("");
  const [purchasePriceWithTax, setPurchasePriceWithTax] = useState("");
  const [purchasePriceDiscountWithoutTax, setPurchasePriceDiscountWithOutTax] =
    useState("");
  const [purchasePriceDiscountWithTax, setPurchasePriceDiscountWithTax] =
    useState("");
  const [minSpWithTax, setminSpWithTax] = useState("");
  const [minSpWithoutTax, setminSpWithoutTax] = useState("");
  const [maxPurchasePriceWithoutTax, setMaxPurchasePriceWithOutTax] =
    useState("");
  const [maxPurchasePriceWithTax, setMaxPurchasePriceWithTax] = useState("");

  const handleFavourite = () => {
    setIsToggledFav(!isToggledFav);
  };

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };
  useEffect(() => {
    if (isCreateSuccess) {
      if (createResponse?.status === "success") {
        toastWithMessage("Item created successfully");
        onSuccess(createResponse?.data);
        handleAddStockItemData(updateResponse?.data);
      }
    }
  }, [isCreateSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Item updated");
        onSuccess(updateResponse?.data);
        handleAddStockItemData(updateResponse?.data);
      }
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (type === "add") {
      setformValues(initialValue);
      setTax(initialValue.tax);
    } else {
      setformValues({
        ...editValue,
        closing_cost_price: _parseFloat(editValue.closing_cost_price).toFixed(
          decimalLength
        ),
        cost_price: _parseFloat(editValue.cost_price).toFixed(decimalLength),
        current_stock: _parseFloat(editValue.current_stock).toFixed(
          decimalLength
        ),
        minimum_stock: _parseFloat(editValue.minimum_stock).toFixed(
          decimalLength
        ),
        maximum_stock: _parseFloat(editValue.maximum_stock).toFixed(
          decimalLength
        ),
        opening_stock: _parseFloat(editValue.opening_stock).toFixed(
          decimalLength
        ),
        opening_stock_value: _parseFloat(editValue.opening_stock_value).toFixed(
          decimalLength
        ),
        purchase_price_with_tax: _parseFloat(
          editValue.purchase_price_with_tax
        ).toFixed(decimalLength),
        purchase_price_without_tax: _parseFloat(
          editValue.purchase_price_without_tax
        ).toFixed(decimalLength),
        maximum_retail_price: _parseFloat(
          editValue.maximum_retail_price
        ).toFixed(decimalLength),
        price: _parseFloat(editValue.price).toFixed(decimalLength),
        price_with_tax: _parseFloat(editValue.price_with_tax).toFixed(
          decimalLength
        ),
        sale_discount: _parseFloat(editValue.sale_discount).toFixed(
          decimalLength
        ),
        // unit: editValue.unit_data,
      });
      setPrice(_parseFloat(editValue.price).toFixed(decimalLength));
      setTax(editValue.tax);

      let taxPercentage = editValue.tax === "-1" ? 0 : parseInt(editValue.tax);
      let taxAmount = (editValue.price * taxPercentage) / 100;
      let purchaseTaxAmount =
        (editValue.purchase_price_without_tax * taxPercentage) / 100;
      let purchacePriceWithTax =
        _parseFloat(editValue.purchase_price_without_tax) +
        _parseFloat(purchaseTaxAmount);
      // let priceWithTax = _parseFloat(editValue.price) + taxAmount;
      // setPriceWithTax(_parseFloat(priceWithTax).toFixed(decimalLength));

      setPrice(_parseFloat(editValue.price).toFixed(decimalLength));
      setPriceWithTax(
        _parseFloat(editValue.price_with_tax).toFixed(decimalLength)
      );

      setDiscountWithOutTax(
        _parseFloat(editValue.sale_discount).toFixed(decimalLength)
      );

      setDiscountWithTax(
        _parseFloat(editValue.sale_discount_with_tax).toFixed(decimalLength)
      );

      setPurchasePriceWithOutTax(
        _parseFloat(editValue.purchase_price_without_tax).toFixed(decimalLength)
      );

      setPurchasePriceWithTax(
        _parseFloat(purchacePriceWithTax).toFixed(decimalLength)
      );

      setPurchasePriceDiscountWithOutTax(
        _parseFloat(editValue.purchase_discount).toFixed(decimalLength)
      );

      let purchaceDiscountWithTax =
        _parseFloat(editValue.purchase_discount) +
        (editValue.purchase_discount * purchaseTaxAmount) / 100;
      setPurchasePriceDiscountWithTax(
        _parseFloat(purchaceDiscountWithTax).toFixed(decimalLength)
      );

      setminSpWithoutTax(
        _parseFloat(editValue.minimum_selling_price_without_tax).toFixed(
          decimalLength
        )
      );
      let minSpWithTax2 =
        _parseFloat(editValue.minimum_selling_price_without_tax) + taxAmount;

      setminSpWithTax(_parseFloat(minSpWithTax2).toFixed(decimalLength));

      setMaxPurchasePriceWithOutTax(
        _parseFloat(editValue.maximum_purchase_price_without_tax).toFixed(
          decimalLength
        )
      );
      let purchacePriceWithTax2 =
        _parseFloat(editValue.maximum_purchase_price_without_tax) + taxAmount;
      setMaxPurchasePriceWithTax(
        _parseFloat(purchacePriceWithTax2).toFixed(decimalLength)
      );
      // setPurchasePriceDiscountWithOutTax(
      //   _parseFloat(purchacePriceWithoutTax).toFixed(decimalLength)
      // );

      // min selling price with tax
      const minSpTaxAmount =
        (editValue?.minimum_selling_price_without_tax * taxPercentage) / 100;
      let minSpWithTax =
        _parseFloat(editValue?.minimum_selling_price_without_tax) +
        minSpTaxAmount;
      setminSpWithTax(_parseFloat(minSpWithTax).toFixed(decimalLength));

      // max purchase price with tax
      let maxPpTaxAmount =
        (editValue?.maximum_purchase_price_without_tax * taxPercentage) / 100;
      let maxPpWithTax =
        _parseFloat(editValue?.maximum_purchase_price_without_tax) +
        maxPpTaxAmount;
      setMaxPurchasePriceWithTax(
        _parseFloat(maxPpWithTax).toFixed(decimalLength)
      );
      setShowTrack(!editValue.tracking_required);
      setIsToggledFav(editValue.favourites);
      setSuspand(editValue.is_suspended);
      setIsService(editValue.is_service_item);
    }
  }, [type, decimalLength]);

  const setPriceValue = (price) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let taxAmount = (price * taxPercentage) / 100;
    let priceWithTax = _parseFloat(price) + taxAmount;
    setPriceWithTax(_parseFloat(priceWithTax).toFixed(decimalLength));
    setPrice(price);

    let decimals = price.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setPrice(_parseFloat(price).toFixed(decimalLength));
    }
  };

  const setPriceWithTaxValue = (priceWithTax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let price = _parseFloat(priceWithTax) / (1 + taxPercentage / 100);
    setPrice(_parseFloat(price).toFixed(decimalLength));
    setPriceWithTax(_parseFloat(priceWithTax));

    let decimals = priceWithTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setPriceWithTax(_parseFloat(priceWithTax).toFixed(decimalLength));
    }
  };

  //Discount With Tax
  const setDiscountWithTaxValue = (discountWithTax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let discountWithOutTax =
      _parseFloat(discountWithTax) / (1 + taxPercentage / 100);
    setDiscountWithOutTax(
      _parseFloat(discountWithOutTax).toFixed(decimalLength)
    );
    setDiscountWithTax(_parseFloat(discountWithTax));

    let decimals = discountWithTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setDiscountWithTax(_parseFloat(discountWithTax).toFixed(decimalLength));
    }
  };

  //Discount With Out Tax
  const setDiscountWithOutTaxValue = (discountWithOutTax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let taxAmount = (discountWithOutTax * taxPercentage) / 100;
    let discountWithTax = _parseFloat(discountWithOutTax) + taxAmount;
    setDiscountWithTax(_parseFloat(discountWithTax).toFixed(decimalLength));
    setDiscountWithOutTax(_parseFloat(discountWithOutTax));

    let decimals = discountWithOutTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setDiscountWithOutTax(
        _parseFloat(discountWithOutTax).toFixed(decimalLength)
      );
    }
  };

  // Purchase price with tax
  const purchasePriceWithTaxValue = (purchasePriceWithTax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let purchacePriceWithoutTax =
      _parseFloat(purchasePriceWithTax) / (1 + taxPercentage / 100);
    setPurchasePriceWithOutTax(
      _parseFloat(purchacePriceWithoutTax).toFixed(decimalLength)
    );
    setPurchasePriceWithTax(_parseFloat(purchasePriceWithTax));

    let decimals = purchasePriceWithTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setPurchasePriceWithTax(
        _parseFloat(purchasePriceWithTax).toFixed(decimalLength)
      );
    }
  };

  //Purchase price With Out Tax
  const purchasePriceWithoutTaxValue = (purchasePriceWithOutTax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let taxAmount = (purchasePriceWithOutTax * taxPercentage) / 100;
    let purchacePriceWithTax = _parseFloat(purchasePriceWithOutTax) + taxAmount;
    setPurchasePriceWithTax(
      _parseFloat(purchacePriceWithTax).toFixed(decimalLength)
    );
    setPurchasePriceWithOutTax(_parseFloat(purchasePriceWithOutTax));

    let decimals = purchasePriceWithOutTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setPurchasePriceWithOutTax(
        _parseFloat(purchasePriceWithOutTax).toFixed(decimalLength)
      );
    }
  };

  // purchase price Discount with tax
  const purchasePriceDiscountWithTaxValue = (purchasePriceDiscountWithTax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let purchacePriceWithoutTax =
      _parseFloat(purchasePriceDiscountWithTax) / (1 + taxPercentage / 100);
    setPurchasePriceDiscountWithOutTax(
      _parseFloat(purchacePriceWithoutTax).toFixed(decimalLength)
    );
    setPurchasePriceDiscountWithTax(_parseFloat(purchasePriceDiscountWithTax));

    let decimals = purchasePriceDiscountWithTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setPurchasePriceDiscountWithTax(
        _parseFloat(purchasePriceDiscountWithTax).toFixed(decimalLength)
      );
    }
  };

  // Purchase price Discount without tax
  const purchasePriceDiscountWithoutTaxValue = (
    purchasePriceDiscountWithOutTax
  ) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let taxAmount = (purchasePriceDiscountWithOutTax * taxPercentage) / 100;
    let purchacePriceWithTax =
      _parseFloat(purchasePriceDiscountWithOutTax) + taxAmount;
    setPurchasePriceDiscountWithTax(
      _parseFloat(purchacePriceWithTax).toFixed(decimalLength)
    );
    setPurchasePriceDiscountWithOutTax(
      _parseFloat(purchasePriceDiscountWithOutTax)
    );

    let decimals = purchasePriceDiscountWithOutTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setPurchasePriceDiscountWithOutTax(
        _parseFloat(purchasePriceDiscountWithOutTax).toFixed(decimalLength)
      );
    }
  };

  // Min sp with tax

  const setMinSpWithTaxValue = (minSpWithTax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let minSpWithoutTax = _parseFloat(minSpWithTax) / (1 + taxPercentage / 100);
    setminSpWithoutTax(_parseFloat(minSpWithoutTax).toFixed(decimalLength));
    setminSpWithTax(_parseFloat(minSpWithTax));

    let decimals = minSpWithTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setminSpWithTax(_parseFloat(minSpWithTax).toFixed(decimalLength));
    }
  };

  // min sp without tax

  const setMinSpWithoutTaxValue = (minSpWithoutTax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let taxAmount = (minSpWithoutTax * taxPercentage) / 100;
    let minSpWithTax = _parseFloat(minSpWithoutTax) + taxAmount;
    setminSpWithTax(_parseFloat(minSpWithTax).toFixed(decimalLength));
    setminSpWithoutTax(_parseFloat(minSpWithoutTax));

    let decimals = minSpWithoutTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setminSpWithoutTax(_parseFloat(minSpWithoutTax).toFixed(decimalLength));
    }
  };

  // Max purchase price with tax
  const setMaxPurchasePriceWithTaxValue = (maxPurchasePriceWithTax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let purchacePriceWithoutTax =
      _parseFloat(maxPurchasePriceWithTax) / (1 + taxPercentage / 100);
    setMaxPurchasePriceWithOutTax(
      _parseFloat(purchacePriceWithoutTax).toFixed(decimalLength)
    );
    setMaxPurchasePriceWithTax(_parseFloat(maxPurchasePriceWithTax));

    let decimals = maxPurchasePriceWithTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setMaxPurchasePriceWithTax(
        _parseFloat(maxPurchasePriceWithTax).toFixed(decimalLength)
      );
    }
  };

  // Max purchase price without tax
  const setMaxPurchasePriceWithoutTaxValue = (maxPurchasePriceWithoutTax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let taxAmount = (maxPurchasePriceWithoutTax * taxPercentage) / 100;
    let purchacePriceWithTax =
      _parseFloat(maxPurchasePriceWithoutTax) + taxAmount;
    setMaxPurchasePriceWithTax(
      _parseFloat(purchacePriceWithTax).toFixed(decimalLength)
    );
    setMaxPurchasePriceWithOutTax(_parseFloat(maxPurchasePriceWithoutTax));

    let decimals = maxPurchasePriceWithoutTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setMaxPurchasePriceWithTax(
        _parseFloat(maxPurchasePriceWithoutTax).toFixed(decimalLength)
      );
    }
  };

  const setTaxValue = (tax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let taxAmount = (price * taxPercentage) / 100;
    let purchaseTaxAmount = (purchasePriceWithOutTax * taxPercentage) / 100;
    let priceWithTax = _parseFloat(price) + taxAmount;
    let discountWithTax =
      _parseFloat(discountWithOutTax) + (discountWithOutTax * taxAmount) / 100;
    setPriceWithTax(_parseFloat(priceWithTax).toFixed(decimalLength));
    setDiscountWithTax(_parseFloat(discountWithTax).toFixed(decimalLength));
    let purchasePriceWithTax =
      _parseFloat(purchasePriceWithOutTax) + purchaseTaxAmount;
    setPurchasePriceWithTax(
      _parseFloat(purchasePriceWithTax).toFixed(decimalLength)
    );
    let purchasePriceDiscountWithTax =
      _parseFloat(purchasePriceDiscountWithoutTax) +
      (purchasePriceDiscountWithoutTax * taxAmount) / 100;
    setPurchasePriceDiscountWithTax(
      _parseFloat(purchasePriceDiscountWithTax).toFixed(decimalLength)
    );
    let minSpWithTax = _parseFloat(minSpWithoutTax) + taxAmount;
    setminSpWithTax(_parseFloat(minSpWithTax).toFixed(decimalLength));
    let maxPurchasePriceWithTax =
      _parseFloat(maxPurchasePriceWithoutTax) + taxAmount;
    setMaxPurchasePriceWithTax(
      _parseFloat(maxPurchasePriceWithTax).toFixed(decimalLength)
    );
    setTax(tax);
  };
  const onSubmit = (fields) => {
    delete fields.price_categories[0]?.categoryName;
    let data = {
      ...fields,
      tax: tax,
      price: price ? price : 0.0,
      price_with_tax: priceWithTax ? priceWithTax : 0.0,
      sale_discount_with_tax: discountWithTax ? discountWithTax : 0,
      sale_discount: discountWithOutTax ? discountWithOutTax : 0,
      purchase_price_without_tax: purchasePriceWithOutTax
        ? purchasePriceWithOutTax
        : 0,
      purchase_price_with_tax: purchasePriceWithTax ? purchasePriceWithTax : 0,
      purchase_discount: purchasePriceDiscountWithoutTax
        ? purchasePriceDiscountWithoutTax
        : 0,
      minimum_selling_price_without_tax: minSpWithoutTax ? minSpWithoutTax : 0,
      maximum_purchase_price_without_tax: maxPurchasePriceWithoutTax
        ? maxPurchasePriceWithoutTax
        : 0,
      tracking_required: !showTrack,
      maximum_stock: _parseFloat(fields.maximum_stock).toFixed(decimalLength),
      minimum_stock: _parseFloat(fields.minimum_stock).toFixed(decimalLength),
      is_suspended: suspand,
      is_service_item: isService,
      favourites: isToggledFav,
    };

    if (type === "edit") {
      if (isApproval) {
        data.approved_by = auth?.data?.id;
      } else {
        delete data.approved_by;
      }
      updateItem(data);
    } else {
      createItem(data);
    }
  };
  return (
    <>
      <Form
        initialValues={{ ...formValues }}
        RenderComponent={RenderComponent}
        handleOnSubmit={onSubmit}
        customProps={{
          type: type,
          priceWithTax,
          showTrack,
          setShowTrack,
          setPriceWithTaxValue,
          price,
          setPriceValue,
          tax,
          setTaxValue,
          discountWithTax,
          setDiscountWithTaxValue,
          discountWithOutTax,
          setDiscountWithOutTaxValue,
          purchasePriceWithTaxValue,
          purchasePriceWithoutTaxValue,
          purchasePriceDiscountWithTaxValue,
          purchasePriceDiscountWithoutTaxValue,
          purchasePriceDiscountWithoutTax,
          purchasePriceDiscountWithTax,
          purchasePriceWithTax,
          purchasePriceWithOutTax,
          setMinSpWithTaxValue,
          setMinSpWithoutTaxValue,
          minSpWithTax,
          minSpWithoutTax,
          setMaxPurchasePriceWithoutTaxValue,
          setMaxPurchasePriceWithTaxValue,
          maxPurchasePriceWithTax,
          maxPurchasePriceWithoutTax,
          editValue,
          isLoading: isCreateLoading || isUpdateLoading,
          isSubmitted: isCreateSuccess || isUpdateSuccess,
          handleFavourite,
          isToggledFav,
          setSuspand,
          suspand,
          isService,
          setIsService,
          isApproval,
          rejectFunc,
          isRejectLoading,
        }}
        validationSchema={object().shape({
          name: string().nullable().required("Name Required"),
          unit: number().nullable().required("Unit Required"),
          price: number()
            .moreThan(-1, "Enter a valid number")
            .typeError("Enter a valid number"),
          tax: string().nullable().required("Tax Required"),
        })}
      />
    </>
  );
};

export default AddItem;

const _parseFloat = (num) => {
  return parseFloat(num || 0);
};
