import { useMutation, useQueryClient } from "react-query";
import api, { API_LEDGER_GROUPS_EDIT_DELETE } from "utils/axios";

const deleteLedgerGroup = (costCenter) =>
  api
    .delete(API_LEDGER_GROUPS_EDIT_DELETE.replace("{id}", costCenter.id))
    .then((res) => res.data);

export function useDeleteLedgerGroup() {
  const queryClient = useQueryClient();
  return useMutation(deleteLedgerGroup, {
    onSuccess: () => queryClient.refetchQueries("ledgergroups"),
  });
}
