import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { setLocalStore } from "utils/utils";
import { getLocalStore } from "utils/utils";
import { useUpdateMainSettings } from "./MainSettings/useUpdateMainSettings";
import moment from "moment";

export function useReportPeriodDateModel() {
  const toast = useToast();
  const {
    mutate: updateMainSettings,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateMainSettings();

  const auth = getLocalStore("AUTH_DETAILS");
  let companyID = auth?.data?.company.id || "";
  let newDate = new Date();

  const [periodDateModel, setPeriodDateModel] = useState({
    reportPeriodFrom: getLocalStore("REPORT_PERIOD_FROM"),
    reportPeriodTo: getLocalStore("REPORT_PERIOD_TO"),
  });

  function updatePeriodDateModel(dates) {
    const { reportPeriodFrom, reportPeriodTo } = dates;
    setLocalStore("REPORT_PERIOD_FROM", reportPeriodFrom);
    setLocalStore("REPORT_PERIOD_TO", reportPeriodTo);
    const data = {
      id: companyID,
      settings: {
        invoice_fields_in_print: [],
        item_fields_in_print: {},
        qr_code_in_invoice: null,
        keyword_settings: [],
        name_duplication_alllowed_in: [],
        default_report_period_from: reportPeriodFrom,
        default_report_period_to: reportPeriodTo,
      },
    };
    updateMainSettings(data);
  }

  const addPeriodDate = (from, to, fromDashboard = false) => {
    // console.log(from);
    // console.log('222', periodDateModel.reportPeriodFrom)
    // setLocalStore(
    //   "REPORT_PERIOD_FROM",
    //   periodDateModel.reportPeriodFrom ?? from
    // );
    // setLocalStore("REPORT_PERIOD_TO", periodDateModel.reportPeriodTo ?? to);
    if (from && to) {
      setLocalStore("REPORT_PERIOD_FROM", from);
      setLocalStore("REPORT_PERIOD_TO", to);
      setPeriodDateModel({
        reportPeriodFrom: from,
        reportPeriodTo: to,
      });
      if (!fromDashboard) {
        updatePeriodDateModel({
          reportPeriodFrom: from,
          reportPeriodTo: to,
        });
      }
    }

    if (!(from && to)) {
      var currentDate = new Date();
      var startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      var formattedCurrentDate = moment(currentDate).format("YYYY-MM-DD");
      var formattedStartDate = moment(startDate).format("YYYY-MM-DD");

      setLocalStore("REPORT_PERIOD_FROM", formattedStartDate);
      setLocalStore("REPORT_PERIOD_TO", formattedCurrentDate);
      setPeriodDateModel({
        reportPeriodFrom: formattedStartDate,
        reportPeriodTo: formattedCurrentDate,
      });
    }
  };

  const clearPeriodDate = () => {
    setPeriodDateModel({ reportPeriodFrom: null, reportPeriodTo: null });
    updatePeriodDateModel({
      reportPeriodFrom: null,
      reportPeriodTo: null,
    });
    setLocalStore("REPORT_PERIOD_FROM", newDate);
    setLocalStore("REPORT_PERIOD_TO", newDate);
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Date Updated Successfully");
      }
    }
  }, [isUpdateSuccess]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  useEffect(() => {
    if (isUpdateError) {
      toast({
        title: "",
        description: `Settings update failed`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [isUpdateError]);
  return [periodDateModel, clearPeriodDate, addPeriodDate, setPeriodDateModel];
}
