import { useMutation, useQueryClient } from "react-query";
import api, { API_ITEM_COSTCENTRE_GET_EDIT_DELETE } from "utils/axios";

const updateCostCenter = (costCenter) =>
  api
    .patch(
      API_ITEM_COSTCENTRE_GET_EDIT_DELETE.replace("{id}", costCenter.id),
      costCenter
    )
    .then((res) => res.data);

export function useUpdateCostCenter() {
  const queryClient = useQueryClient();
  return useMutation(updateCostCenter, {
    onSuccess: () => queryClient.refetchQueries("costcenters"),
  });
}
