import TextField from "@material-ui/core/TextField";
import {
  Box,
  Stack,
  FormControl,
  Button,
  Heading,
  Tooltip,
} from "@chakra-ui/react";
import Message from "../Message";
import useKeypress from "react-use-keypress";
import { useEffect, useState } from "react";
import { saveAndPrintShortKey } from "utils/utils/saveAndPrintShortkey";

const RenderComponent = ({
  onClear,
  handleEdit,
  values,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid,
  isSubmitting,
  touched,
  handleReset,
  cancelAction,
}) => {
  const { type, handleResetToComponent, isLoading } = customProps;
  const [clickKey, setClickKey] = useState({
    keyS: false,
    keyP: false,
  });

  useEffect(() => {
    if (handleResetToComponent) handleReset();
  }, [handleResetToComponent]);

  useEffect(() => {
    if (clickKey?.keyS) {
      const validateResponse = validateForm();
      validateResponse.then((data) => {
        submitAction(data);
      });
    }
  }, [clickKey]);
  saveAndPrintShortKey(setClickKey);

  return (
    <Box bg="white" flex={1} p={2} pb={4}>
      <Stack spacing={4}>
        <Box p={2}>
          <Heading size="md">
            {type === "edit"
              ? "Edit Price Category Name -" + values.name
              : "Add Price Category Name"}
          </Heading>
        </Box>
        <Box>
          <Box p={2}>
            <FormControl>
              <TextField
                fullWidth
                label="Price Category Name"
                variant="outlined"
                size="small"
                type="text"
                name="status"
                autoFocus
                onChange={(event) =>
                  handleEdit(event.target.value, { field: "name" })
                }
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    const validateResponse = validateForm();
                    validateResponse.then((data) => {
                      submitAction(data);
                    });
                  }
                }}
                value={values.name || ""}
              />
              {errors.name && !isNotValid && (
                <Message type="error" message={errors.name} />
              )}
            </FormControl>
          </Box>
        </Box>
        <Tooltip hasArrow label="ctrl+A" placement="bottom" bg="brand.bg_blue">
          <Button
            isLoading={isLoading}
            loadingText="Saving"
            type="submit"
            size="lg"
            variant="solid"
            bg="brand.bg_blue"
            colorScheme="brand.bg_blue"
            onClick={() => {
              const validateResponse = validateForm();
              validateResponse.then((data) => {
                submitAction(data);
              });
            }}
          >
            {type === "edit" ? "Update" : "Add Price Category Name"}
          </Button>
        </Tooltip>
      </Stack>
    </Box>
  );
};

export default RenderComponent;
