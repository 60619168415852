/* eslint-disable react-hooks/exhaustive-deps */
const IOption = (props) => {
  const { value, key, children } = props;
  return (
    <option style={{ color: "black" }} value={value} key={key}>
      {children}
    </option>
  );
};
export default IOption;
