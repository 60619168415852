import { createSlice } from "@reduxjs/toolkit";

import api, {
  API_CASHBOOK_DETAILS,
  API_BANKBOOK_DETAILS,
} from "../utils/axios";

import { showLoading, hideLoading } from "./common.slice";

const initialState = {
  loading: false,
  error: null,
  cashBookDetails: {},
};

const cashbook = createSlice({
  name: "cashbook",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setCashBookDetails: (state, action) => {
      state.cashBookDetails = action.payload.cashBookDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setCashBookDetails, setErrorMessage } =
  cashbook.actions;

export default cashbook.reducer;

export const fetchCashBookDetails = (data, callback) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    console.log("data----------", data);
    var response;
    if (data.ledger == null) {
      response = await api.get(
        API_CASHBOOK_DETAILS.replace("{startDate}", data.startDate)
          .replace("{endDate}", data.endDate)
          .replace("&ledger={ledger}", "")
      );
    } else {
      response = await api.get(
        API_CASHBOOK_DETAILS.replace("{startDate}", data.startDate)
          .replace("{endDate}", data.endDate)
          .replace("{ledger}", data.ledger)
      );
    }

    if (response?.data?.status === "success") {
      dispatch(setCashBookDetails({ cashBookDetails: response.data.data }));
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(setCashBookDetails({ reports: {} }));
  }
};

export const fetchBankhBookDetails = (data, callback) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    console.log("data----------", data);
    var response;
    if (data.ledger == null) {
      response = await api.get(
        API_BANKBOOK_DETAILS.replace("{startDate}", data.startDate)
          .replace("{endDate}", data.endDate)
          .replace("&ledger={ledger}", "")
      );
    } else {
      response = await api.get(
        API_BANKBOOK_DETAILS.replace("{startDate}", data.startDate)
          .replace("{endDate}", data.endDate)
          .replace("{ledger}", data.ledger)
      );
    }

    if (response?.data?.status === "success") {
      dispatch(setCashBookDetails({ cashBookDetails: response.data.data }));
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(setCashBookDetails({ reports: {} }));
  }
};
