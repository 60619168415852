import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  InputGroup,
  InputLeftElement,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  useDisclosure,
  InputRightElement,
  Heading,
  HStack,
  Spacer,
  Flex,
  Text,
  VStack,
  Divider,
  Menu,
  MenuButton,
  Icon,
  MenuList,
  MenuItem,
  IconButton,
  Select,
  Skeleton,
} from "@chakra-ui/react";
import { FiMoreVertical, FiEdit3, FiTrash2, FiEye } from "react-icons/fi";
import { Search2Icon, CloseIcon, AddIcon } from "@chakra-ui/icons";
import AddLedgerForm from "../AddLedgerForm";
import { useLedgerGroups } from "hooks/LedgerGroup/useLedgerGroups";
import { useLedgersInGroups } from "hooks/Ledger/useLedgersInGroups";
import { useHistory } from "react-router-dom";
import useKeypress from "react-use-keypress";
import { useLedgers } from "hooks/Ledger/useLedgers";
import { getLocalStore } from "utils/utils";
import { getStandardFormat } from "utils/utils/digitStandards";
import { useDecimalLength } from "hooks/useDecimalLength";
import { useDigitStandardModel } from "hooks/useDigitStandardModel";
import { isMobile } from "react-device-detect";
import { useDebounce } from "hooks/useDebounce";

export default function LedgerSelectionList(props) {
  const { onEdit, onDelete, onClick, ...rest } = props;

  const history = useHistory();
  const inputRef = useRef(null);
  const {
    data: ledgersInGroupsData,
    setGroups,
    refetch: refetchLedgersInGroups,
  } = useLedgersInGroups();
  const { data: ledgerGroupsData } = useLedgerGroups();
  const { data: ledgers, isLoading } = useLedgers();

  const [listData, setlistData] = useState(null);
  const [sortedListData, setSortedListData] = useState(null);
  const [searchKey, setSearchKey] = useState("");

  const [deleteValue, setDeleteValue] = useState(null);
  const [type, setType] = useState("add");
  const [editValue, setEditValue] = useState(null);
  const [searchType, setSearchType] = useState("name");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [decimalLength] = useDecimalLength(2);
  const [digitStandardModel] = useDigitStandardModel();

  const [altPressed, setAltPressed] = useState(false);
  const [datasInPopup, setDatasInPopup] = useState(null);

  const ledgerSearchModel = getLocalStore("LEDGER_SEARCH_DEFAULT_MODEL");
  const datasInLedgerPopupModel = getLocalStore("DATAS_IN_LEDGER_POPUP_MODEL");

  useEffect(() => {
    if (ledgerSearchModel) {
      switch (ledgerSearchModel) {
        case "NAME":
          setSearchType("name");
          break;
        case "CODE":
          setSearchType("ledger_code");
          break;
        case "OPENING_BALANCE":
          setSearchType("opening_balance");
          break;
        case "GROUP":
          setSearchType("group");
          break;
        case "DESCRIPTION":
          setSearchType("description");
          break;
        default:
          setSearchType("name");
          break;
      }
    }
  }, [ledgerSearchModel]);

  useEffect(() => {
    setDatasInPopup(datasInLedgerPopupModel || null);
  }, [datasInLedgerPopupModel]);

  useEffect(() => {
    if (!isMobile) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (ledgers?.data && ledgerGroupsData?.data) {
      const getChildrenIds = (parentId) => {
        const uuids = [];
        ledgerGroupsData?.data.forEach((item) => {
          if (item.master === parentId) {
            uuids.push(item.uuid);
            uuids.push(...getChildrenIds(item.uuid));
          }
        });
        return uuids;
      };

      const sundryDebtors = ledgerGroupsData?.data
        .filter((item) => item.name === "Sundry Debtors")
        .map((ids) => {
          return { uuid: ids.uuid, id: ids.id };
        })[0];
      const sundryCreditors = ledgerGroupsData?.data
        .filter((item) => item.name === "Sundry Creditors")
        .map((ids) => {
          return { uuid: ids.uuid, id: ids.id };
        })[0];
      const bankAccounts = ledgerGroupsData?.data
        .filter((item) => item.name === "Bank Accounts")
        .map((ids) => {
          return { uuid: ids.uuid, id: ids.id };
        })[0];
      const cashInHand = ledgerGroupsData?.data
        .filter((item) => item.name === "Cash-in-Hand")
        .map((ids) => {
          return { uuid: ids.uuid, id: ids.id };
        })[0];

      const sundryDebtorsIds = getChildrenIds(sundryDebtors.uuid);
      sundryDebtorsIds.push(sundryDebtors.uuid);

      const sundryCreditorsIds = getChildrenIds(sundryCreditors.uuid);
      sundryCreditorsIds.push(sundryCreditors.uuid);

      const bankAccountsIds = getChildrenIds(bankAccounts.uuid);
      bankAccountsIds.push(bankAccounts.uuid);

      const cashInHandIds = getChildrenIds(cashInHand.uuid);
      cashInHandIds.push(cashInHand.uuid);

      const filteredLedgers = ledgers.data?.filter(
        (item) =>
          ![
            ...sundryDebtorsIds,
            ...sundryCreditorsIds,
            ...bankAccountsIds,
            ...cashInHandIds,
          ].includes(item.group)
      );

      let ledgerGroups = ledgerGroupsData?.data ?? [];
      let ledgersWithGroupData = filteredLedgers
        ?.map((ledger) => {
          let group = ledgerGroups?.find((item) => item.uuid === ledger.group);
          let master;
          if (group) {
            master = ledgerGroups?.find((item) => item.uuid === group.master);
          }
          return { ...ledger, group: group?.name, master: master?.name };
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      setlistData(ledgersWithGroupData);
      setSortedListData(ledgersWithGroupData);
    }
  }, [ledgers, ledgerGroupsData]);

  const handleChange = (event) => {
    let value = event.target.value;
    setSearchKey(value);
    handleDebouncedSearch(value);
  };

  const debouncedSearch = (value) => {
    const temp = [...sortedListData];
    if (value.length > 0) {
      if (searchType === "name") {
        const a = temp.filter((name) =>
          name.name.toUpperCase().includes(value.toUpperCase())
        );
        setlistData(a);
      } else if (searchType === "opening_balance") {
        const filteredData = temp.filter(
          (item) =>
            item.opening_balance &&
            item.opening_balance.toUpperCase().includes(value.toUpperCase())
        );
        setlistData(filteredData);
      } else if (searchType === "ledger_code") {
        const filteredData = temp.filter(
          (item) =>
            item.ledger_code &&
            item.ledger_code.toUpperCase().includes(value.toUpperCase())
        );
        setlistData(filteredData);
      }
    } else {
      setlistData(sortedListData);
    }
  };

  const handleDebouncedSearch = useDebounce(debouncedSearch, 500);

  const openLedger = (ledger, value) => {
    // history.push({
    //   // pathname: `/ledger-report`,
    //   pathname: `/add-ledger`,
    //   state: { ledger: ledger },
    // });
    const newWindow = window.open(`/ledger-report`, "_blank");
    newWindow.ledgerId = value.id;
  };

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const selectedItemRef = useRef(null);
  useKeypress(["Enter", "ArrowUp", "ArrowDown"], (event) => {
    const searchList = listData.slice(0, 10);
    if (!isOpen) {
      if (event.key === "Enter") {
        onClick(searchList[selectedItemIndex]);
      } else if (event.key === "ArrowUp") {
        if (selectedItemIndex - 1 >= 0) {
          setSelectedItemIndex(selectedItemIndex - 1);
        } else {
          setSelectedItemIndex(searchList.length - 1);
        }
      } else if (event.key === "ArrowDown") {
        if (selectedItemIndex + 1 < searchList.length) {
          setSelectedItemIndex(selectedItemIndex + 1);
        } else {
          setSelectedItemIndex(0);
        }
      }
      selectedItemRef.current?.scrollIntoView();
      event.stopPropagation();
      event.preventDefault();
    }
  });
  //add ledger modal
  useKeypress(["Alt", "c", "C"], (event) => {
    if (event.key === "Alt") {
      setAltPressed(true);
      setTimeout(() => setAltPressed(false), 1000);
    }
    if (event.key === "c" || event.key === "C") {
      if (altPressed) {
        onOpen();
      }
    }
    if (event.key !== "c" && event.key !== "C" && event.key !== "Alt") {
      event.stopPropagation();
      event.preventDefault();
    }
  });

  return (
    <>
      <Stack bg="white" width="100%" {...rest}>
        <HStack pb="1">
          <Heading size="lg">Ledgers</Heading>
          <Spacer />
          {!isMobile ? (
            <Button colorScheme="blue" leftIcon={<AddIcon />} onClick={onOpen}>
              Create Ledger
            </Button>
          ) : (
            <Button
              colorScheme="blue"
              leftIcon={<AddIcon />}
              onClick={onOpen}
            ></Button>
          )}
        </HStack>
        <Box pb="2">
          <InputGroup>
            {/* <InputLeftElement
              pointerEvents="none"
              children={<Search2Icon color="gray.300" />}
            /> */}
            <InputLeftElement
              color="gray.700"
              width={isMobile ? "6rem" : "8rem"}
              children={
                <Select
                  bg="grey.300"
                  placeholder="select"
                  value={searchType}
                  onChange={(e) => setSearchType(e.target.value)}
                >
                  <option value="name">Item name</option>
                  <option value="ledger_code">Ledger code</option>
                  <option value="opening_balance">Opening balance</option>
                  {/* <option value="description">Description</option> */}
                </Select>
              }
            />
            <Input
              // type="tel"
              pl={isMobile ? "6.5rem" : "9rem"}
              pr="2rem"
              placeholder="Search"
              ref={inputRef}
              value={searchKey}
              onChange={handleChange}
            />
            {searchKey && (
              <InputRightElement
                cursor="pointer"
                children={<CloseIcon color="gray.300" />}
                onClick={() => {
                  setSearchKey("");
                  setlistData(sortedListData);
                }}
              />
            )}
          </InputGroup>
        </Box>
        <Box overflowY="auto">
          <Skeleton isLoaded={!isLoading}>
            {listData &&
              listData.length > 0 &&
              listData.map((value, key) => (
                <VStack
                  key={key}
                  spacing="0"
                  cursor="pointer"
                  _hover={{ bg: "gray.100" }}
                  background={key === selectedItemIndex ? "gray.100" : ""}
                  ref={key === selectedItemIndex ? selectedItemRef : null}
                >
                  <HStack w="100%">
                    <Flex
                      w="100%"
                      margin={2}
                      p={1}
                      as="button"
                      onClick={() => {
                        onClick && onClick({ ...value });
                      }}
                      justifyContent="space-between"
                      // background={key === selectedItemIndex ? "gray.100" : ""}
                      // ref={key === selectedItemIndex ? selectedItemRef : null}
                    >
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Text
                          w="100%"
                          size="md"
                          noOfLines={1}
                          align="left"
                          fontWeight="bold"
                        >
                          {value.name}
                        </Text>
                        <Text
                          fontSize="sm"
                          color="gray.600"
                          noOfLines={1}
                          align="left"
                        >
                          {value.group}
                        </Text>
                      </div>
                      {value.extras !== null && (
                        <Box>
                          {datasInPopup === "PRICE_WITH_TAX" && (
                            <Text fontSize="sm">
                              {getStandardFormat(
                                parseFloat(value.extras.price_with_tax || 0),
                                digitStandardModel,
                                decimalLength
                              )}
                            </Text>
                          )}
                          {datasInPopup === "PRICE_WITHOUT_TAX" && (
                            <Text fontSize="sm">
                              {getStandardFormat(
                                parseFloat(value.extras.price || 0),
                                digitStandardModel,
                                decimalLength
                              )}
                            </Text>
                          )}
                          {datasInPopup === "BOTH_PRICE" && (
                            <Text fontSize="sm">
                              {getStandardFormat(
                                parseFloat(value.extras.price_with_tax || 0),
                                digitStandardModel,
                                decimalLength
                              )}
                              <Text fontSize="10px">
                                {getStandardFormat(
                                  parseFloat(value.extras.price || 0),
                                  digitStandardModel,
                                  decimalLength
                                )}
                              </Text>
                            </Text>
                          )}
                          {datasInPopup === "QUANTITY" && (
                            <Text fontSize="sm"> </Text>
                          )}
                          {datasInPopup === "EMPTY" && (
                            <Text fontSize="sm"> </Text>
                          )}
                        </Box>
                      )}
                    </Flex>
                    {/* 3 dot code */}
                    <VStack maxW="30px">
                      <Menu spacing="2">
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<Icon as={FiMoreVertical} />}
                          size="xs"
                          variant="solid"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                        <MenuList>
                          <MenuItem
                            icon={<Icon as={FiEye}></Icon>}
                            onClick={(event) => {
                              //event.stopPropagation();
                              openLedger(true, value, "view");
                            }}
                          >
                            View
                          </MenuItem>
                          <MenuItem
                            icon={<Icon as={FiEdit3}></Icon>}
                            onClick={(event) => {
                              event.stopPropagation();
                              setType("edit");
                              setEditValue(value);
                              onOpen();
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            color="red.500"
                            icon={<Icon as={FiTrash2}></Icon>}
                            onClick={(event) => {
                              event.stopPropagation();
                              setDeleteValue(value);
                              // onOpenDeleteModal();
                            }}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </VStack>
                  </HStack>
                  <Divider />
                </VStack>
              ))}
          </Skeleton>
          {/* <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th
                  position="sticky"
                  bg="blue.50"
                  top="0"
                  zIndex="1"
                  textColor="green.500"
                  minW="150px"
                >
                  Ledger Name
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {listData &&
                listData.length > 0 &&
                listData.map((value, key) => (
                  <Tr key={key} cursor="pointer" _hover={{ bg: "gray.100" }}>
                    <Td
                      onClick={() => {
                        onClick && onClick({ ...value });
                      }}
                    >
                      {value.name}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table> */}
        </Box>
      </Stack>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <AddLedgerForm
              type={type}
              editValue={editValue}
              onAddSuccess={() => {
                setSearchKey("");
                setlistData(sortedListData);
                onClose();
                // refetch ledgers in groups
                refetchLedgersInGroups();
              }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
