import { createSlice } from "@reduxjs/toolkit";

import api, {
  API_BALANCE_SHEET_DETAILS,
  API_BALANCE_SHEET_DETAILS_EXPORT,
} from "../utils/axios";

import { showLoading, hideLoading } from "./common.slice";

const initialState = {
  loading: false,
  error: null,
  balanceSheetDetails: {},
  balanceSheetDetailsWithID: {},
};

const balancesheet = createSlice({
  name: "balancesheet",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setBalanceSheetDetails: (state, action) => {
      state.balanceSheetDetails = action.payload.balanceSheetDetails;
    },
    setBalanceSheetDetailsWithID: (state, action) => {
      state.balanceSheetDetailsWithID =
        action.payload.balanceSheetDetailsWithID;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const {
  setLoading,
  setBalanceSheetDetails,
  setBalanceSheetDetailsWithID,
  setErrorMessage,
} = balancesheet.actions;

export default balancesheet.reducer;

export const fetchBalanceSheetDetails = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    var response = await api.get(
      API_BALANCE_SHEET_DETAILS.replace("{endDate}", data.endDate)
    );
    if (response?.data?.status === "success") {
      dispatch(
        setBalanceSheetDetails({ balanceSheetDetails: response.data.data })
      );
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(setBalanceSheetDetails({ balanceSheetDetails: {} }));
  }
};

export const fetchBalanceSheetWithID =
  ({ data, callBack }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      let url = API_BALANCE_SHEET_DETAILS.replace("{endDate}", data.endDate);
      url = url + `&group_id=${data.groupId}`;
      var response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(setLoading({ loading: false }));
        dispatch(
          setBalanceSheetDetailsWithID({
            balanceSheetDetailsWithID: response.data.data,
          })
        );
        callBack && callBack(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setBalanceSheetDetailsWithID({ balanceSheetDetailsWithID: {} }));
      callBack && callBack(error);
    }
  };

export const exportBalanceSheetDetails = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    console.log("data----------", data);
    const response = await api.get(
      API_BALANCE_SHEET_DETAILS_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};
