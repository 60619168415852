import { createSlice } from "@reduxjs/toolkit";

import { setLocalStore, getLocalStore } from "../utils/utils";
import api, { API_SETTINGS } from "../utils/axios";
import { showLoading, hideLoading } from "./common.slice";

let settingsData = getLocalStore("SETTINGS");

const initialState = {
  settings: {
    decimal_place: settingsData?.decimal_place ?? 2,
  },
};

const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload.settings;
    },
  },
});

export const { setSettings } = settings.actions;

export default settings.reducer;

export const getSettings =
  ({ callback }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.get(API_SETTINGS);
      if (response && response.data) {
        dispatch(hideLoading());
        dispatch(
          setSettings({
            settings: response.data.data,
          })
        );
        callback && callback(response.data);
      }
    } catch (error) {
      dispatch(hideLoading());
      callback && callback(error);
    }
  };

export const updateSettings =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.patch(API_SETTINGS + "/1", data);
      if (response && response.data) {
        dispatch(hideLoading());
        setLocalStore("SETTINGS", response.data.data);
        dispatch(
          setSettings({
            settings: response.data.data,
          })
        );
        callback && callback(response.data);
      }
    } catch (error) {
      dispatch(hideLoading());
      callback && callback(error);
    }
  };
