import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { setLocalStore } from "utils/utils";
import { getLocalStore } from "utils/utils";
import { useUpdatePrintSettings } from "./PrintSettings/useUpdatePrintSettings";
import { usePrintSettings } from "./PrintSettings/usePrintSettings";
// import { PRINTING_MODELS } from "utils/utils/printingModels";

export function useInvoiceOptionModel() {
  const toast = useToast();
  const { data: printSettingsData } = usePrintSettings();

  const {
    mutate: updatePrintSettings,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdatePrintSettings();

  const [invoiceModel, setInvoiceModel] = useState(
    getLocalStore("INVOICEOPTION_MODEL") ?? []
  );

  function changeInvoiceModel(invoiceModel) {
    setLocalStore("INVOICEOPTION_MODEL", invoiceModel);
    setInvoiceModel(invoiceModel);
  }

  function updateInvoiceModel(invoiceModel) {
    setLocalStore("INVOICEOPTION_MODEL", invoiceModel);
    setInvoiceModel(invoiceModel);

    let printSettings = printSettingsData
      ? { ...printSettingsData.data[0] }
      : {};
    delete printSettings.footer_of_invoice_print;
    delete printSettings.header_of_invoice_print;

    if (printSettings) {
      updatePrintSettings({
        ...printSettings,
        invoice_fields_in_print: [...invoiceModel.map((item) => item.value)],
      });
    }
  }

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Settings Updated Successfully");
      }
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isUpdateError) {
      toast({
        title: "",
        description: `Settings update failed`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [isUpdateError]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return [invoiceModel, changeInvoiceModel, updateInvoiceModel];
}
