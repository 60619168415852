import React, { useEffect, useState, useRef } from "react";
import {
  Text,
  HStack,
  Stack,
  Flex,
  Image,
  Spacer,
  IconButton,
  Button,
  Link,
  Box,
  FormControl,
  GridItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuOptionGroup,
  Badge,
  MenuItemOption,
  Tooltip,
  useDisclosure,
  AvatarGroup,
  Avatar,
  Divider,
} from "@chakra-ui/react";
import { useQueryClient } from "react-query";
import { getLocalStore } from "../../utils/utils";
import { BE_DATE_FORMAT } from "utils/utils/constant";
import DatePicker from "components/DatePicker";
import { useVoucherDateModel } from "hooks/useVoucherDateModel";
import logo from "../../assets/logo.svg";
import CollapsableSideNavbar from "../CollapsableSideNavbar/component";
import { ArrowDownIcon, ArrowUpIcon, BellIcon } from "@chakra-ui/icons";
import { FaSignOutAlt } from "react-icons/fa";
import featureButton from "../../assets/feature_button.jpg";
import { MdFullscreen, MdFullscreenExit } from "react-icons/md";
import {
  ArrowBackIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  HamburgerIcon,
  SettingsIcon,
  AddIcon,
  MinusIcon,
} from "@chakra-ui/icons";
import { FiLogOut } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { clearLocalStore } from "../../utils/utils";
import "./style.css";
import { useReportPeriodDateModel } from "hooks/useReportPeriodDateModel";
import { Notifications } from "@material-ui/icons";
import NotificationDrawer from "components/Notifications";
import IpasLogo from "../../assets/ipas-book-logo_cropped.png";
import { useSelector } from "react-redux";
import { useNotifications } from "hooks/Notifications/useNotifications";
import moment from "moment";
import { useUserSetup } from "hooks/UserSetup/useUserSetup";
import UserProfilePopup from "components/UserProfilePopup";

const HeaderTab = ({ collapsed, onToggleSidebar }) => {
  const auth = getLocalStore("AUTH_DETAILS");
  const userID = auth?.data?.id;
  const { data: defaultSettingsData } = useUserSetup(userID);
  const [showMoreAllocation, setShowMoreAllocation] = useState(false);
  const [showMoreDate, setShowMoreDate] = useState(false);
  const [showMorePrice, setShowMorePrice] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const boxRef = useRef(null);
  const [isClicked, setIsClicked] = useState(false);

  const voucherDate = getLocalStore("VDATE_MODEL");
  const reportPeriodFrom = getLocalStore("REPORT_PERIOD_FROM");
  const reportPeriodTo = getLocalStore("REPORT_PERIOD_TO");
  const companyName = auth?.data.company.name || "";
  const [dateModel, changeDateModel, updateDateModel, clearVoucherDate] =
    useVoucherDateModel();

  const [periodDateModel, clearPeriodDate, addPeriodDate, setPeriodDateModel] =
    useReportPeriodDateModel();
  const closeBox = () => {
    setIsBoxVisible(false);
  };

  var currentDate = new Date();
  var startDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  var formattedCurrentDate = moment(currentDate).format("YYYY-MM-DD");
  var formattedStartDate = moment(startDate).format("YYYY-MM-DD");

  const [periodFrom, setPeriodFrom] = useState(
    reportPeriodFrom || formattedStartDate
  );
  const [periodTo, setPeriodTo] = useState(
    reportPeriodTo || formattedCurrentDate
  );

  const [dVoucherDate, setDVoucherDate] = useState(
    voucherDate || formattedCurrentDate
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isUserOpen, setIsUserOpen] = useState(false);
  // const [periodDate, setPeriodDate] = useState({});
  let history = useHistory();
  let openLink = (path) => {
    history.push(path);
  };
  const queryClient = useQueryClient();

  const dateString = (date) => {
    return date?.format(BE_DATE_FORMAT) ?? null;
  };

  const notificationDrawer = useDisclosure();
  const [notifications, setNotifications] = useState([]);
  const [btnDisable, setBtnDisable] = useState(true);
  const [isBoxVisible, setIsBoxVisible] = useState(false);

  const data = useSelector((state) => state.dashboard);

  useEffect(() => {
    if (voucherDate) {
      setDVoucherDate(voucherDate);
    }
  }, [voucherDate]);

  useEffect(() => {
    if (reportPeriodFrom) {
      setPeriodFrom(reportPeriodFrom);
    }
  }, [reportPeriodFrom]);

  useEffect(() => {
    if (reportPeriodTo) {
      setPeriodTo(reportPeriodTo);
    }
  }, [reportPeriodTo]);

  useEffect(() => {
    if (periodFrom) {
      setPeriodDateModel({
        ...periodDateModel,
        from: periodFrom,
      });
    }
    if (periodTo) {
      setPeriodDateModel({
        ...periodDateModel,
        to: periodTo,
      });
    }
  }, [periodFrom, periodTo]);
  const toggleBoxVisibility = () => {
    setIsBoxVisible(!isBoxVisible);
  };
  const toggleFullscreen = () => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen().then(() => {
        setIsFullscreen(true);
      });
    } else {
      document.exitFullscreen().then(() => {
        setIsFullscreen(false);
      });
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsUserOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const CompanyLogo = auth?.data?.company?.logo;

  return (
    <>
      <HStack
        w="100%"
        border="1px"
        borderBottomColor="#e2e8f0"
        borderLeftColor="white"
        borderRightColor="white"
        borderTopColor="white"
        boxShadow="0 -3px 31px 0 rgba(0,0,0,.05), 0 6px 20px 0 rgba(0,0,0,.02)"
        rounded="sm"
        p="2"
        // px="3"
        // _hover={{ boxShadow: "lg", bg: "gray.50", borderColor: "gray.300" }}
      >
        <Box ml="3" flexDirection="row">
          {/* <Stack spacing="0"> */}
          {/* <IconButton
            aria-label="Toggle Sidebar"
            icon={<HamburgerIcon />}
            onClick={onToggleSidebar}
            color="black"
            size="md"
            background="none"
            marginRight="15px"
            _hover={{ bg: "white" }}
          /> */}
          {/* <Image
            borderRadius="0px 0px 8px 8px"
            boxSize="60px"
            backgroundColor="white"
            src={CompanyLogo ? CompanyLogo : IpasLogo}
            alt=""
          /> */}
          {auth?.data?.user_type === 1 && (
            <Button
              marginRight="15px"
              onClick={() => openLink("/master-dashboard")}
            >
              <ArrowBackIcon />
            </Button>
          )}
          {auth?.data?.group_companies &&
            auth?.data?.group_companies.length > 0 && (
              <Button
                marginRight="15px"
                onClick={() =>
                  openLink(`/group-companies/${auth?.data?.group_companies}`)
                }
              >
                <ArrowBackIcon />
              </Button>
            )}

          {/* <Button
            marginRight="15px"
            bg="#f8f9fa"
            onClick={() => openLink("/dashboard")}
          >
            {" "}
            Dashboard
          </Button> */}
          {/* <Button marginRight="15px">Dashboard</Button>
        <Button>summery</Button> */}
          {/* <Button
            isLoading={data?.loading}
            loadingText="Summary"
            onClick={() => openLink("/summery")}
            bg="#f8f9fa"
          >
            Summary
          </Button> */}
          <Menu isOpen={isOpen}>
            <MenuButton
              px={4}
              py={2}
              transition="all 0.2s"
              borderRadius="md"
              borderWidth="1px"
              _hover={{ bg: "gray.400" }}
              _expanded={{ bg: "blue.400", color: "white" }}
              _focus={{ boxShadow: "outline" }}
              onClick={() => setIsOpen(!isOpen)}
            >
              Select Date <ChevronDownIcon />
            </MenuButton>
            <MenuList>
              <MenuOptionGroup
                defaultValue="asc"
                title="Default Voucher Date"
                type="radio"
              >
                <Box display="flex" flexDir="row" alignItems="center">
                  <FormControl id="date" style={{ padding: "0 20px" }}>
                    <DatePicker
                      w="170px"
                      dateLabel={"Select Date"}
                      date={dVoucherDate}
                      setDate={(date) => {
                        setDVoucherDate(dateString(date));
                      }}
                    ></DatePicker>
                  </FormControl>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      variant="ghost"
                      icon={<CheckIcon />}
                      colorScheme="blue"
                      minWidth="50px"
                      onClick={() => {
                        updateDateModel(dVoucherDate, true);
                      }}
                    >
                      Save
                    </IconButton>
                    <IconButton
                      variant="ghost"
                      icon={<CloseIcon />}
                      colorScheme="red"
                      minWidth="50px"
                      onClick={() => {
                        clearVoucherDate();
                        setDVoucherDate(null);
                      }}
                    >
                      Cancel
                    </IconButton>
                  </div>
                </Box>
              </MenuOptionGroup>
              <MenuDivider />
              <MenuOptionGroup
                title="Default Report Period Date"
                type="checkbox"
              >
                <Box display="flex" flexDir="row" alignItems="center">
                  <Box>
                    <FormControl id="date" style={{ padding: "0px 20px" }}>
                      <DatePicker
                        w="170px"
                        dateLabel={"Start Date"}
                        date={periodFrom}
                        setDate={(date) => {
                          setPeriodFrom(dateString(date));
                          // setPeriodDateModel({
                          //   ...periodDateModel,
                          //   reportPeriodFrom: dateString(date),
                          // });
                        }}
                      ></DatePicker>
                    </FormControl>
                    <FormControl
                      id="date"
                      style={{ padding: "0 20px", marginTop: 16 }}
                    >
                      <DatePicker
                        w="170px"
                        dateLabel={"End Date"}
                        date={periodTo}
                        setDate={
                          (date) => setPeriodTo(dateString(date))
                          // setPeriodDateModel({
                          //   ...periodDateModel,
                          //   reportPeriodTo: dateString(date),
                          // })
                        }
                      ></DatePicker>
                    </FormControl>
                  </Box>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      variant="ghost"
                      icon={<CheckIcon />}
                      colorScheme="blue"
                      minWidth="50px"
                      onClick={() => {
                        addPeriodDate(periodFrom, periodTo);
                      }}
                    >
                      Save
                    </IconButton>
                    <IconButton
                      variant="ghost"
                      icon={<CloseIcon />}
                      colorScheme="red"
                      minWidth="50px"
                      onClick={() => {
                        clearPeriodDate();
                      }}
                    >
                      Cancel
                    </IconButton>
                  </div>
                </Box>
              </MenuOptionGroup>
              <MenuDivider />
              <Box display="flex" justifyContent="end" padding={1} pr={4}>
                <Button colorScheme="blue" onClick={() => setIsOpen(false)}>
                  Close
                </Button>
              </Box>
            </MenuList>
          </Menu>
          {/* </Stack> */}
        </Box>
        {/* <Box>{props.title}</Box> */}
        <Box fontSize="20px" fontWeight="2000">
          {" "}
          Welcome back! {companyName}
        </Box>
        <Spacer />
        {auth?.data?.user_type === 3 && (
          <Image
            boxSize="35px"
            backgroundColor="white"
            src={featureButton}
            alt=""
            onClick={(e) => {
              openLink("subscription-plan");
            }}
            cursor="pointer"
          />
        )}
        <Box width={1}></Box>
        {/* <Button
          bgGradient="linear(to-r, blue.400, blue.700)"
          textColor="white"
          borderRadius="md"
          onClick={(e) => {
            openLink("subscription-plan");
          }}
        >
          Unlock Premium Features!
        </Button> */}
        {/* <Tooltip label="Notifications"> */}
        {/* <Button
          isLoading={btnDisable}
          disabled={btnDisable}
          onClick={(e) => {
            e.preventDefault();
            notificationDrawer.onOpen();
          }}
          bg="white"
          _hover={{ bg: "white" }}
        >
          <Notifications />
        </Button> */}
        {/* <Badge
          position="absolute"
          top="0"
          right="0"
          fontSize="sm"
          colorScheme="red"
        >
          12
        </Badge> */}
        {/* </Tooltip> */}
        <Box position="relative">
          <IconButton
            // isLoading={isLoadingNotifications}
            // disabled={isLoadingNotifications}
            bg={isClicked ? "transparent" : "white"}
            _hover={{ bg: "white" }}
            icon={<BellIcon style={{ width: "25px", height: "auto" }} />}
            onClick={(e) => {
              e.preventDefault();
              setIsClicked(true);
              notificationDrawer.onOpen();
            }}
            variant="ghost"
            size="lg"
          />
          {/* <Badge
            position="absolute"
            top="0"
            right="0"
            fontSize="sm"
            bg="red"
            borderRadius="10"
            color="white"
          >
            {notifications?.length}
          </Badge> */}
        </Box>
        <Button
          bg="white"
          _hover={{ bg: "white" }}
          onClick={(e) => {
            e.preventDefault();
            toggleFullscreen();
          }}
        >
          {isFullscreen ? (
            <MdFullscreenExit style={{ width: "30px", height: "auto" }} />
          ) : (
            <MdFullscreen style={{ width: "30px", height: "auto" }} />
          )}
        </Button>
        <UserProfilePopup></UserProfilePopup>

        <IconButton
          icon={<SettingsIcon style={{ width: "20px", height: "auto" }} />}
          onClick={() => {
            history.push("/settings");
          }}
          // __css={{
          //   "> svg": {
          //     animation: "spin 0.5s linear infinite", // Apply the animation to the inner svg element
          //   },
          // }}
          _hover={{
            bg: "white",
          }}
          color="black"
          size="md"
          background="none"
        />
      </HStack>
      {notificationDrawer.isOpen && (
        <NotificationDrawer
          isOpen={notificationDrawer.isOpen}
          onClose={notificationDrawer.onClose}
        />
      )}
    </>
  );
};

export default HeaderTab;
