export const DIGIT_STANDARDS = [
  { digit: "1,00,00,000", value: "indian" },
  { digit: "10,000,000", value: "international" },
];

export function getStandardFormat(digit, standard, decimalLength) {
  switch (standard) {
    case "international":
      return digit
        .toFixed(decimalLength)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    case "indian":
      return digit
        .toFixed(decimalLength)
        .replace(/(\d)(?=(\d{2})+\d\.)/g, "$1,");

    default:
      break;
  }
}
