import { useMutation, useQueryClient } from "react-query";
import api, { API_ITEM_BRAND_GET_EDIT_DELETE } from "utils/axios";

const updateItemBrand = (brand) =>
  api
    .patch(API_ITEM_BRAND_GET_EDIT_DELETE.replace("{id}", brand.id), brand)
    .then((res) => res.data);

export function useUpdateItemBrand() {
  const queryClient = useQueryClient();
  return useMutation(updateItemBrand, {
    onSuccess: () => queryClient.refetchQueries("itembrand"),
  });
}
