import React, { useEffect, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import AutocompleteWithAddOption from "FormComponents/AutoComplete/component";
import AddLedgerGroup from "../AddLedgerGroup";
import { useLedgerGroups } from "hooks/LedgerGroup/useLedgerGroups";

export default function LedgerGroupAutoComplete(props) {
  const {
    selectedGroup,
    setSelectedGroup,
    onGroupClick,
    listData,
    textFieldClick,
    hasExecuted,
    onKeyDown,
    disabled = false,
  } = props;

  const { data: ledgerGroupData } = useLedgerGroups();

  const {
    // eslint-disable-next-line no-unused-vars
    isOpen: isOpenAddModal,
    // eslint-disable-next-line no-unused-vars
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure();

  const [groupListData, setGroupListData] = useState([]);
  const [ledgerGroup, setLedgerGroup] = useState([]);

  // const [hasExecuted, setHasExecuted] = useState(false);

  useEffect(() => {
    setGroupListData(listData);
  }, [listData]);

  useEffect(() => {
    let group = groupListData?.find((item) => item.id === selectedGroup);
    setLedgerGroup(group);
  }, [selectedGroup, groupListData]);

  useEffect(() => {
    let sundryDebters = ledgerGroupData?.data?.find(
      (group) => group.name === "Sundry Debtors"
    );
    let sundryCreditors = ledgerGroupData?.data?.find(
      (group) => group.name === "Sundry Creditors"
    );
    let filteredGroups = ledgerGroupData?.data?.filter(
      (group) =>
        ![sundryCreditors.uuid, sundryDebters.uuid].includes(group.uuid) &&
        ![sundryCreditors.uuid, sundryDebters.uuid].includes(group.master)
    );
    setGroupListData(filteredGroups);
  }, [ledgerGroupData]);

  return (
    <>
      <AutocompleteWithAddOption
        value={ledgerGroup}
        options={groupListData}
        onChange={(group) => {
          setSelectedGroup(group?.id);
        }}
        onKeyDown={onKeyDown}
        setIsOpen={() => {}}
        inputRef={props.inputRef}
        optionLabel="name"
        inputLabel="Select Ledger Group"
        textFieldClick={textFieldClick}
        disabled={disabled}
      >
        <AddLedgerGroup
          type="add"
          listData={groupListData}
          changeType={() => {}}
          onSuccess={onCloseAddModal}
          // onAddSuccess={(doneBy) => {
          //   setSelectedDoneBy(doneBy);
          // }}
        />
      </AutocompleteWithAddOption>
    </>
  );
}
