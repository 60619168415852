import { createSlice } from "@reduxjs/toolkit";

// import { setLocalStore, setResponse } from '../utils/utils'
import api, {
  API_ITEM_GROUP_LIST_CREATE,
  API_ITEM_GROUP_GET_EDIT_DELETE,
  API_ITEM_CATEGORY_LIST_CREATE,
  API_ITEM_CATEGORY_GET_EDIT_DELETE,
  API_ITEM_BRAND_LIST_CREATE,
  API_ITEM_BRAND_GET_EDIT_DELETE,
  API_ITEM_DONEBY_GET_EDIT_DELETE,
  API_ITEM_DONEBY_LIST_CREATE,
  API_ITEM_COSTCENTRE_LIST_CREATE,
  API_ITEM_COSTCENTRE_GET_EDIT_DELETE,
  API_ITEM_UNIT_LIST_CREATE,
  API_ITEM_UNIT_GET_EDIT_DELETE,
} from "../utils/axios";

const initialState = {
  loading: false,
  items: [],
  categories: [],
  costcenters: [],
  doneByList: [],
  groups: [],
  brands: [],
};

const general = createSlice({
  name: "general",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setItems: (state, action) => {
      state.items = action.payload.items;
    },
    setCategories: (state, action) => {
      state.categories = action.payload.categories;
    },
    setCostCenters: (state, action) => {
      state.costcenters = action.payload.costcenters;
    },
    setUnits: (state, action) => {
      state.units = action.payload.units;
    },
    setBrands: (state, action) => {
      state.brands = action.payload.brands;
    },
    setDoneByList: (state, action) => {
      state.doneByList = action.payload.doneByList;
    },
    setItemDetails: (state, action) => {
      state.itemDetails = action.payload.itemDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
    setGroups: (state, action) => {
      state.groups = action.payload.groups;
    },
  },
});

export const {
  setLoading,
  setItems,
  setCategories,
  setCostCenters,
  setDoneByList,
  setItemDetails,
  setErrorMessage,
  setGroups,
  setUnits,
  setBrands,
} = general.actions;

export default general.reducer;

export const fetchItemGroups = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(API_ITEM_GROUP_LIST_CREATE);
    if (response && response.data) {
      dispatch(setItems({ items: response.data.data }));
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(setItems({ items: [] }));
  }
};

export const addItemGroup =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.post(API_ITEM_GROUP_LIST_CREATE, data);
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchItemGroups());
        callback && callback(response);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const editItemGroup =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.patch(
        API_ITEM_GROUP_GET_EDIT_DELETE.replace("{id}", data.id),
        data
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchItemGroups());
        callback && callback(response);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const deleteItemGroup =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_ITEM_GROUP_GET_EDIT_DELETE.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchItemGroups());
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const fetchItemCategories = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(API_ITEM_CATEGORY_LIST_CREATE);
    if (response && response.data) {
      dispatch(setCategories({ categories: response.data.data }));
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const addItemCategory =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.post(API_ITEM_CATEGORY_LIST_CREATE, data);
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchItemCategories());
        callback && callback(response);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const editItemCategory =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.patch(
        API_ITEM_CATEGORY_GET_EDIT_DELETE.replace("{id}", data.id),
        data
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchItemCategories());
        callback && callback(response);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const deleteItemCategory =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_ITEM_CATEGORY_GET_EDIT_DELETE.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchItemCategories());
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const fetchItemBrands = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(API_ITEM_BRAND_LIST_CREATE);
    if (response && response.data) {
      dispatch(setBrands({ brands: response.data.data }));
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const addItemBrand =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.post(API_ITEM_BRAND_LIST_CREATE, data);
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchItemBrands());
        callback && callback(response);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const editItemBrand =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.patch(
        API_ITEM_BRAND_GET_EDIT_DELETE.replace("{id}", data.id),
        data
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchItemBrands());
        callback && callback(response);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const deleteItemBrand =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_ITEM_BRAND_GET_EDIT_DELETE.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchItemBrands());
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const fetchDoneby = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(API_ITEM_DONEBY_LIST_CREATE);
    if (response && response.data) {
      dispatch(setDoneByList({ doneByList: response.data.data }));
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(setItems({ items: [] }));
  }
};

export const addDoneby =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.post(API_ITEM_DONEBY_LIST_CREATE, data);
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchDoneby());
        callback && callback(response.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const editDoneby =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.patch(
        API_ITEM_DONEBY_GET_EDIT_DELETE.replace("{id}", data.id),
        data
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchDoneby());
        callback && callback(response);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const deleteDoneby =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_ITEM_DONEBY_GET_EDIT_DELETE.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchDoneby());
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const fetchCostCentre = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(API_ITEM_COSTCENTRE_LIST_CREATE);
    if (response && response.data) {
      dispatch(setCostCenters({ costcenters: response.data.data }));
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(setItems({ items: [] }));
  }
};

export const addCostCentre =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.post(API_ITEM_COSTCENTRE_LIST_CREATE, data);
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchCostCentre());
        callback && callback(response.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const editCostCentre =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.patch(
        API_ITEM_COSTCENTRE_GET_EDIT_DELETE.replace("{id}", data.id),
        data
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchCostCentre());
        callback && callback(response);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const deleteCostCentre =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_ITEM_COSTCENTRE_GET_EDIT_DELETE.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchCostCentre());
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const fetchUnits = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(API_ITEM_UNIT_LIST_CREATE);
    if (response && response.data) {
      dispatch(setUnits({ units: response.data.data }));
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(setUnits({ units: [] }));
  }
};

export const addUnit =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.post(API_ITEM_UNIT_LIST_CREATE, data);
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchUnits());
        callback && callback(response);
      }
      return response;
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const editUnit =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.patch(
        API_ITEM_UNIT_GET_EDIT_DELETE.replace("{id}", data.id),
        data
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchUnits());
        callback && callback(response);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const deleteUnit =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_ITEM_UNIT_GET_EDIT_DELETE.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(fetchUnits());
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };
