import { useMutation, useQueryClient } from "react-query";
import api, { API_APPROVALS } from "utils/axios";

const draftApproval = ({ transaction_type, ...data }) =>
  api
    .post(`${API_APPROVALS}?transaction_type=${transaction_type}`, data)
    .then((res) => res.data);

export function useDraftApproval() {
  const queryClient = useQueryClient();
  return useMutation(draftApproval, {
    onSuccess: () => {},
  });
}
