import React, { useEffect, useRef, useState } from "react";
import { Field } from "formik";
import TextField from "@material-ui/core/TextField";

import {
  Box,
  Stack,
  Text,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  NumberInput,
  NumberInputField,
  Button,
  Heading,
  Tooltip,
  Checkbox,
} from "@chakra-ui/react";
import Message from "../Message";
import useKeypress from "react-use-keypress";
import { saveAndPrintShortKey } from "utils/utils/saveAndPrintShortkey";
import { isMobile } from "react-device-detect";

import {
  ITab,
  IButton,
  IHead,
  IHeadFav,
  ITabPanelIn,
  IModalIn,
  IModalOut,
  IHStack,
  ITextField,
  IButtonOut,
  ITabPanel,
  IFieldAndLabel,
} from "components/IModal";
const RenderComponent = ({
  onClear,
  handleEdit,
  values,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid,
  isSubmitting,
  touched,
  handleReset,
  cancelAction,
}) => {
  const {
    type,
    clearInitialValues,
    handleResetToComponent,
    listData,
    isLoading,
    isSubmitted,
    setSuspand,
    suspand,
    isApproval,
    approveFunc,
    rejectFunc,
    isRejectLoading,
    isApproveLoading,
  } = customProps;

  const [clickKey, setClickKey] = useState({
    keyA: false,
  });

  useEffect(() => {
    if (handleResetToComponent) handleReset();
  }, [handleResetToComponent]);

  const unitRef = useRef(0);
  const formalRef = useRef();
  const decimalRef = useRef();

  useEffect(() => {
    if (clickKey?.keyA && !isLoading && !isSubmitted) {
      onSubmit();
    }
  }, [clickKey]);
  useEffect(() => {
    unitRef.current?.focus();
  }, []);
  saveAndPrintShortKey(setClickKey);
  const [buttonClick, setButtonClick] = useState(false);
  const onSubmit = () => {
    setButtonClick(!buttonClick);
    const validateResponse = validateForm();
    validateResponse.then((data) => {
      submitAction(data);
    });
  };
  return (
    <IModalOut>
      <IHead
        text={
          isApproval
            ? "Approve Unit -" + values.unit_symbol
            : type === "edit"
            ? "Edit Unit -" + values.unit_symbol
            : "Add Unit"
        }
      />
      <IModalIn>
        <FormControl>
          <ITextField
            autoFocus={!isMobile}
            fullWidth
            label="Unit Symbol"
            variant="outlined"
            size="small"
            type="text"
            name="unit_symbol"
            onChange={(event) =>
              handleEdit(event.target.value, { field: "unit_symbol" })
            }
            inputRef={unitRef}
            value={values.unit_symbol || ""}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                formalRef.current?.focus();
              }
            }}
            disabled={isApproval}
            isValid={isNotValid}
            isRequired={true}
            errorMessage={errors.unit_symbol}
            buttonClick={buttonClick}
          />
        </FormControl>
        <FormControl>
          <ITextField
            fullWidth
            label="Formal Name"
            variant="outlined"
            size="small"
            type="text"
            name="unit"
            inputRef={formalRef}
            onChange={(event) =>
              handleEdit(event.target.value, { field: "unit" })
            }
            value={values.unit || ""}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                decimalRef.current?.focus();
              }
            }}
            disabled={isApproval}
            isValid={errors.unit === undefined}
            isRequired={true}
            errorMessage={errors.unit}
          />
        </FormControl>
        <FormControl>
          <TextField
            fullWidth
            label="Number of decimal places"
            variant="outlined"
            size="small"
            type="number"
            name="no_of_decimal"
            onChange={(event) =>
              handleEdit(event.target.value, { field: "no_of_decimal" })
            }
            inputRef={decimalRef}
            value={values.no_of_decimal || ""}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                onSubmit();
              }
            }}
            disabled={isApproval}
          />
          {errors.no_of_decimal && !isNotValid && (
            <Message type="error" message={errors.no_of_decimal} />
          )}
        </FormControl>
        <Checkbox
          isChecked={suspand}
          onChange={(e) => {
            setSuspand(!suspand);
          }}
          disabled={isApproval}
        >
          Required Suspend
        </Checkbox>
      </IModalIn>
      <IButtonOut>
        {isApproval && (
          <Tooltip
            hasArrow
            label="Reject"
            placement="bottom"
            bg="brand.bg_blue"
          >
            <Button
              w="100%"
              isLoading={isRejectLoading}
              minH="40px"
              type="submit"
              size="lg"
              variant="solid"
              bg="red.600"
              colorScheme="red"
              onClick={() => {
                rejectFunc();
              }}
            >
              Reject
            </Button>
          </Tooltip>
        )}
        <IButton
          isLoading={isLoading || isApproveLoading}
          onClick={(e) => {
            if (e.detail === 1) {
              if (isApproval) {
                approveFunc();
              } else {
                onSubmit();
              }
            }
          }}
          type={type}
          isApproval={isApproval}
        />
      </IButtonOut>
    </IModalOut>
  );
};

export default RenderComponent;
