import React, { useEffect, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import ErrorBoundary from "./components/ErrorBoundary";
// import NotFound from './pages/NotFound'
import PrivateRoute from "./PrivateRoute";
import Private2Route from "Private2Route";
import PublicRoute from "./PublicRoute";
import MasterRoute from "./MasterRoute";
import ResetRoute from "ResetRoute";
import AddProduction from "components/AddProduction";

const Login = lazy(() => import("./pages/Login"));
const reset = lazy(() => import("./pages/ResetPassword"));
const Summery = lazy(() => import("./pages/Summery"));
const Dashboard = lazy(() => import("./pages/DashboardV2"));
const MasterDashboard = lazy(() => import("./pages/DashboardMaster"));
const DefaultUserSettings = lazy(() => import("./pages/DefaultUserSettings"));
const GroupCompanies = lazy(() => import("./pages/GroupCompanies"));
const Settings = lazy(() => import("./pages/Settings/Settings"));
const SubscriptionPlan = lazy(() => import("./pages/SubscriptionPlan"));
const CashBook = lazy(() => import("./pages/Reports/CashBook"));
const BankBook = lazy(() => import("./pages/Reports/BankBook"));

const WithORWithoutSale = lazy(() =>
  import("./pages/Transaction/Sales/WithORWithoutSale")
);
const WithORWithoutSaleReturn = lazy(() =>
  import("./pages/Transaction/SalesReturn/WithORWithoutSaleReturn")
);
const WithORWithoutPurchase = lazy(() =>
  import("./pages/Transaction/Purchase/WithORWithoutPurchase")
);
const WithORWithoutPurchaseReturn = lazy(() =>
  import("./pages/Transaction/PurchaseReturn/WithORWithoutPurchaseReturn")
);
const SalesWithItem = lazy(() =>
  import("./pages/Transaction/Sales/SalesWithItem/SalesWithItemPage")
);
// const SalesWithOutItem = lazy(() =>
//   import("./pages/Transaction/SalesWithOutItem")
// );

const SalesWithOutItem = lazy(() =>
  import("./pages/Transaction/Sales/SalesWithoutItem/SalesWithOutItemPage")
);
const SalesPOS = lazy(() =>
  import("./pages/Transaction/SalesPOS/SalesPOSPage")
);

const PurchaseWithItem = lazy(() =>
  import("./pages/Transaction/Purchase/PurchaseWithItem/PurchaseWithItemPage")
);

const PurchaseWithOutItem = lazy(() =>
  import(
    "./pages/Transaction/Purchase/PurchaseWithoutItem/PurchaseWithOutItemPage"
  )
);
const Expense = lazy(() =>
  import("./pages/Transaction/Expense/ExpenseItemsMainPage")
);
const Reciept = lazy(() =>
  import("./pages/Transaction/Reciept/ReceiptMainPage")
);
const OtherReciept = lazy(() =>
  import("./pages/Transaction/OtherReciept/OtherRecieptMainPage")
);
const OtherPayment = lazy(() =>
  import("./pages/Transaction/OtherPayment/OtherPaymentMainPage")
);
const PaymentAgainstPurchase = lazy(() =>
  import(
    "./pages/Transaction/PaymentAgainstPurchase/PaymentAgainstPurchaseMainpage"
  )
);
const PaymentAgainstExpense = lazy(() =>
  import(
    "./pages/Transaction/PaymentAgainstExpense/PaymentAgainstExpenseMainPage"
  )
);
const ReceiptAgainstPurchaseReturn = lazy(() =>
  import(
    "./pages/Transaction/ReceiptAgainstPurchaseReturn/PurchaseReturnReceiptMainPage"
  )
);
const PaymentAgainstSalesReturn = lazy(() =>
  import(
    "./pages/Transaction/PaymentAgainstSalesReturn/SalesReturnPaymentMainPage"
  )
);
const Journal = lazy(() => import("./pages/Transaction/Journal"));
const StockJournal = lazy(() =>
  import("./pages/Transaction/StockJournal/StockJournalMainPage")
);
const StockLossEntry = lazy(() => import("./pages/Transaction/StockLossEntry"));
const JournalEntry = lazy(() =>
  import("./pages/Transaction/JournalEntry/JournalEntryMainPage")
);

const CustomerSetOff = lazy(() =>
  import("./pages/Transaction/CustomerSetOff/CustomerSetOffMainPage")
);
const SupplierSetOff = lazy(() =>
  import("./pages/Transaction/SupplierSetOff/SupplierSetOffMainPage")
);
const EstimateWithItem = lazy(() =>
  import("./pages/Transaction/EstimateWithItem/EstimateWithItemPage")
);
const DeliveryOrder = lazy(() =>
  import("./pages/Transaction/DeliveryOrder/DeliveryOrderMainPage")
);
const SalesReturnWithItem = lazy(() =>
  import(
    "./pages/Transaction/SalesReturn/SalesReturnWithItem/SalesReturnWithItemPage"
  )
);
const SalesReturnWithOutItem = lazy(() =>
  import(
    "./pages/Transaction/SalesReturn/SalesReturnWithOutItem/SalesReturnWithOutItemPage"
  )
);
const PurchaseReturnWithItem = lazy(() =>
  import(
    "./pages/Transaction/PurchaseReturn/PurchaseReturnWithItem/PurchaseReturnWithItemPage"
  )
);
const PurchaseReturnWithOutItem = lazy(() =>
  import(
    "./pages/Transaction/PurchaseReturn/PurchaseReturnWithOutItem/PurchaseReturnWithOutItemPage"
  )
);

const CustomerAndSupplierCommunication = lazy(() =>
  import("./pages/CustomerAndSupplierCommunication/component")
);

const Events = lazy(() =>
  import("./pages/CustomerAndSupplierCommunication/EventsPage/component")
);

const EventDetail = lazy(() =>
  import("./pages/CustomerAndSupplierCommunication/EventDetailPage/component")
);

const AddItem = lazy(() => import("./pages/Add/AddItem"));
const Contra = lazy(() => import("./pages/Transaction/Contra/contraMainPage"));
const SalesReport = lazy(() => import("./pages/Reports/SalesReports"));
const SalesReturnReport = lazy(() =>
  import("./pages/Reports/SalesReturnReports")
);
const PurchaseReturnReport = lazy(() =>
  import("./pages/Reports/PurchaseReturnReports")
);

const SaleEstimateReport = lazy(() =>
  import("./pages/Reports/SaleEstimateReports")
);
const AgingReports = lazy(() => import("./pages/Reports/AgingReports"));
const AgingReportsPartyWise = lazy(() =>
  import("./pages/Reports/AgingReportsPartyWise")
);
const PaymentReports = lazy(() => import("./pages/Reports/PaymentReports"));
const ExpenseReports = lazy(() => import("./pages/Reports/ExpenseReports"));
const PurchaseReports = lazy(() => import("./pages/Reports/PurchaseReports"));
const RecieptReports = lazy(() => import("./pages/Reports/RecieptReports"));
const ContraReports = lazy(() => import("./pages/Reports/ContraReports"));
const LedgerReports = lazy(() => import("./pages/Reports/LedgerReports"));
const MasterGroupReports = lazy(() =>
  import("./pages/Reports/MasterGroupReports")
);
const PartyBalanceSummaryReports = lazy(() =>
  import("./pages/Reports/PartyBalanceSummaryReports")
);
const VATReports = lazy(() => import("./pages/Reports/VATReports"));
const VATReportsPlaceOfSupply = lazy(() =>
  import("./pages/Reports/VATReportsPlaceOfSupply")
);
const VATReportsHSNWise = lazy(() =>
  import("./pages/Reports/VATReportsHSNWise")
);
const VATReportsPartyWise = lazy(() =>
  import("./pages/Reports/VATReportsPartyWise")
);
const VATReportsTaxType = lazy(() =>
  import("./pages/Reports/VATReportsTaxType")
);
const VATReportsCostCenter = lazy(() =>
  import("./pages/Reports/VATReportsCostCenter")
);
const VATReportsVoucherType = lazy(() =>
  import("./pages/Reports/VATReportsVoucherType")
);
const CustomerSetOffReports = lazy(() =>
  import("./pages/Reports/CustomerSetOffReports")
);
const SupplierSetOffReports = lazy(() =>
  import("./pages/Reports/SupplierSetOffReports")
);
const JournalEntriesReports = lazy(() =>
  import("./pages/Reports/JournalEntriesReports")
);
const TrialBalance = lazy(() => import("./pages/Reports/TrialBalance"));
const BalanceSheet = lazy(() => import("./pages/Reports/BalanceSheet"));
const DeliveryOrderReports = lazy(() =>
  import("./pages/Reports/DeliveryOrderReports")
);
const ProfitAndLoss = lazy(() => import("./pages/Reports/ProfitAndLoss"));
const StockSummaryReports = lazy(() =>
  import("./pages/Reports/StockSummaryReports")
);
const LowStockReports = lazy(() => import("./pages/Reports/LowStockReports"));
const StockLossReports = lazy(() => import("./pages/Reports/StockLossReports"));
const StockDetailedReports = lazy(() =>
  import("./pages/Reports/StockDetailedReports")
);
const ItemDetailedReports = lazy(() =>
  import("./pages/Reports/ItemDetailedReports")
);
const ItemWiseProfitReports = lazy(() =>
  import("./pages/Reports/ItemWiseProfitReports")
);
const AllTransactionsReports = lazy(() =>
  import("./pages/Reports/AllTransactionsReports")
);
const PDCManagementReport = lazy(() =>
  import("./pages/Reports/PDCManagementReport")
);
const MonthlySummaryReport = lazy(() =>
  import("./pages/Reports/MonthlySummaryReport")
);
const DailySummaryReport = lazy(() =>
  import("./pages/Reports/DailySummaryReport")
);
const StockJournalReports = lazy(() =>
  import("./pages/Reports/StockJournalReports")
);
const AdvanceReports = lazy(() => import("./pages/Reports/AdvanceReports"));
const ItemWiseTransactionReports = lazy(() =>
  import("./pages/Reports/ItemWiseTransactionReports")
);
const InventoryAgingReport = lazy(() =>
  import("./pages/Reports/InventoryAgingReport")
);
const LedgerWiseReceiptPaymentSummary = lazy(() =>
  import("./pages/Reports/LedgerWiseReceiptPaymentSummary")
);
const SummaryReports = lazy(() => import("./pages/Reports/SummaryReports"));
const DoneByBasedSummaryReport = lazy(() =>
  import("./pages/Reports/DoneByBasedSummaryReport")
);
const CostCenterBasedSummaryReport = lazy(() =>
  import("./pages/Reports/CostCenterBasedSummaryReport")
);
const CostCenterMonthlySummaryReport = lazy(() =>
  import("./pages/Reports/CostCenterMonthlySummaryReport")
);
const CostCenterDailySummaryReport = lazy(() =>
  import("./pages/Reports/CostCenterDailySummaryReport")
);
const DoneByDailySummaryReport = lazy(() =>
  import("./pages/Reports/DoneByDailySummaryReport")
);
const DoneByMonthlySummaryReport = lazy(() =>
  import("./pages/Reports/DoneByMonthlySummaryReport")
);
const PartyWiseSummaryReport = lazy(() =>
  import("./pages/Reports/PartyWiseSummaryReport")
);
const StoreWiseSummaryReport = lazy(() =>
  import("./pages/Reports/StoreWiseSummaryReport")
);
const GroupWiseSummaryReport = lazy(() =>
  import("./pages/Reports/GroupWiseSummaryReport")
);
const LedgerReportMonthlySummary = lazy(() =>
  import("./pages/Reports/LedgerReportMonthlySummary")
);

const MultiUnitPricing = lazy(() => import("./pages/Add/MultiUnitPricing"));
const CustomerList = lazy(() => import("./pages/Add/CustomerList"));
const SupplierList = lazy(() => import("./pages/Add/SupplierList"));
const LedgerGroupList = lazy(() => import("./pages/Add/LedgerGroupList"));
const AddLedger = lazy(() => import("./pages/Add/AddLedger"));
const ItemGroupList = lazy(() => import("./pages/Add/ItemGroupList"));
const ItemCategoryList = lazy(() => import("./pages/Add/ItemCategoryList"));
const ItemBrandList = lazy(() => import("./pages/Add/ItemBrandList"));
const DonebyList = lazy(() => import("./pages/Add/DonebyList"));
const CostCentreList = lazy(() => import("./pages/Add/CostCentreList"));
const UnitList = lazy(() => import("./pages/Add/UnitList"));
const VoucherTypeList = lazy(() => import("./pages/Add/VoucherTypeList"));
const EstimateStatusList = lazy(() => import("./pages/Add/EstimateStatusList"));
const ProductionList = lazy(() => import("./pages/Add/ProductionList"));
const StoreList = lazy(() => import("./pages/Add/StoreList"));
const ModeOfPaymentList = lazy(() => import("./pages/Add/ModeOfPaymentList"));
const SharingRoom = lazy(() => import("./pages/Others/SharingRoom"));
const TransactionTab = lazy(() => import("./pages/DashboardV2/TransactionTab"));
const ReportTab = lazy(() => import("./pages/DashboardV2/ReportTab"));
const AddItemTab = lazy(() => import("./pages/DashboardV2/AddItemTab"));
const NotificationPage = lazy(() =>
  import("./pages/DashboardV2/Notification-page")
);
const UploadImage = lazy(() => import("./pages/Others/UploadImage"));
const ImportReports = lazy(() => import("./pages/Others/ImportReports"));
const ImportReportsNew = lazy(() =>
  import("./pages/Transaction/components/ImportReportModal")
);
const TransactionDraftReports = lazy(() =>
  import("./pages/Reports/TransactionDraftReports")
);

const Routes = () => {
  const toast = useToast();
  const data = useSelector((state) => state.snackbar);
  useEffect(() => {
    if (data?.message) {
      toast({
        title: "",
        description: data?.message,
        status: data?.type === "ERROR" ? "error" : "success",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [data, toast]);
  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          <PublicRoute component={Login} path="/" exact />
          <MasterRoute
            component={MasterDashboard}
            path="/master-dashboard"
            exact
          />
          <MasterRoute
            component={DefaultUserSettings}
            path="/default-user-settings"
            exact
          />
          <MasterRoute
            component={DefaultUserSettings}
            path="/default-user-settings/:id"
            exact
          />
          <MasterRoute
            component={GroupCompanies}
            path="/group-companies/:groupId"
            exact
          />
          <ResetRoute component={reset} path="/resetpassword" exact />
          {/* <PrivateRoute component={Summery} path="/summery" exact /> */}
          {/* <PrivateRoute component={Dashboard} path="/dashboard" exact /> */}
          <Private2Route component={Summery} path="/summery" exact />
          <Private2Route component={Dashboard} path="/dashboard" exact />
          <PrivateRoute component={Settings} path="/settings" exact />
          <PrivateRoute
            component={SubscriptionPlan}
            path="/subscription-plan"
            exact
          />
          <PrivateRoute component={CashBook} path="/cash-book" exact />
          <PrivateRoute component={BankBook} path="/bank-book" exact />
          {/* <PrivateRoute component={Sales} path='/sale-without-item' exact /> */}
          <PrivateRoute
            component={WithORWithoutSale}
            path="/sales/:id/:mode"
            exact
          />
          <PrivateRoute
            component={WithORWithoutSaleReturn}
            path="/salesreturn/:id/:mode"
            exact
          />
          <PrivateRoute
            component={WithORWithoutPurchase}
            path="/purchases/:id/:mode"
            exact
          />
          <PrivateRoute
            component={WithORWithoutPurchaseReturn}
            path="/purchasesreturn/:id/:mode"
            exact
          />
          <PrivateRoute
            component={SalesWithItem}
            path="/sale-with-item"
            exact
          />
          <PrivateRoute
            component={SalesWithItem}
            path="/sale-with-item/:id/:mode"
            exact
          />
          <PrivateRoute
            component={SalesWithItem}
            path="/sale-with-item/:id/:mode/:transactionMode"
            exact
          />
          <PrivateRoute
            component={SalesWithOutItem}
            path="/sale-without-item"
            exact
          />
          <PrivateRoute
            component={SalesWithOutItem}
            path="/sale-without-item/:id/:mode"
            exact
          />
          <PrivateRoute component={SalesPOS} path="/sales-pos" exact />
          <PrivateRoute
            component={SalesPOS}
            path="/sales-pos/:id/:mode"
            exact
          />
          <PrivateRoute
            component={EstimateWithItem}
            path="/estimate-with-item/:type"
            exact
          />
          <PrivateRoute
            component={EstimateWithItem}
            path="/estimate-with-item/:type/:id/:mode"
            exact
          />
          <PrivateRoute
            component={PurchaseWithOutItem}
            path="/purchase-without-item"
            exact
          />
          <PrivateRoute
            component={PurchaseWithOutItem}
            path="/purchase-without-item/:id/:mode"
            exact
          />
          <PrivateRoute
            component={PurchaseWithItem}
            path="/purchase-with-item"
            exact
          />
          <PrivateRoute
            component={PurchaseWithItem}
            path="/purchase-with-item/:id/:mode"
            exact
          />
          <PrivateRoute component={Expense} path="/expense" exact />
          <PrivateRoute component={Expense} path="/expense/:id/:mode" exact />
          <PrivateRoute
            component={Reciept}
            path="/reciept-against-invoice"
            exact
          />
          <PrivateRoute
            component={Reciept}
            path="/reciept-against-invoice/:id/:mode"
            exact
          />
          <PrivateRoute component={OtherReciept} path="/other-reciept" exact />
          <PrivateRoute
            component={OtherReciept}
            path="/other-reciept/:id/:mode"
            exact
          />
          <PrivateRoute component={OtherPayment} path="/other-payment" exact />
          <PrivateRoute
            component={OtherPayment}
            path="/other-payment/:id/:mode"
            exact
          />
          <PrivateRoute
            component={PaymentAgainstPurchase}
            path="/payment-against-purchase"
            exact
          />
          <PrivateRoute
            component={PaymentAgainstPurchase}
            path="/payment-against-purchase/:id/:mode"
            exact
          />
          <PrivateRoute
            component={PaymentAgainstExpense}
            path="/payment-against-expense"
            exact
          />
          <PrivateRoute
            component={PaymentAgainstExpense}
            path="/payment-against-expense/:id/:mode"
            exact
          />
          <PrivateRoute
            component={ReceiptAgainstPurchaseReturn}
            path="/receipt-against-purchase-return"
            exact
          />
          <PrivateRoute
            component={ReceiptAgainstPurchaseReturn}
            path="/receipt-against-purchase-return/:id/:mode"
            exact
          />
          <PrivateRoute
            component={PaymentAgainstSalesReturn}
            path="/payment-against-sales-return"
            exact
          />
          <PrivateRoute
            component={PaymentAgainstSalesReturn}
            path="/payment-against-sales-return/:id/:mode"
            exact
          />
          <PrivateRoute component={Journal} path="/journal-entries" exact />
          <PrivateRoute
            component={StockJournal}
            path="/stock-journal/:type"
            exact
          />
          <PrivateRoute
            component={StockJournal}
            path="/stock-journal/:type/:id/:mode"
            exact
          />
          <PrivateRoute
            component={StockLossEntry}
            path="/stock-loss-entry"
            exact
          />
          <PrivateRoute component={JournalEntry} path="/journal-entry" exact />
          <PrivateRoute
            component={JournalEntry}
            path="/journal-entry/:id/:mode"
            exact
          />
          <PrivateRoute
            component={CustomerSetOff}
            path="/customer-set-off"
            exact
          />
          <PrivateRoute
            component={CustomerSetOff}
            path="/customer-set-off/:id/:mode"
            exact
          />
          <PrivateRoute
            component={SupplierSetOff}
            path="/supplier-set-off"
            exact
          />
          <PrivateRoute
            component={SupplierSetOff}
            path="/supplier-set-off/:id/:mode"
            exact
          />
          <PrivateRoute
            component={DeliveryOrder}
            path="/delivery-order"
            exact
          />
          <PrivateRoute
            component={DeliveryOrder}
            path="/delivery-order/:id/:mode"
            exact
          />
          <PrivateRoute
            component={SalesReturnWithItem}
            path="/sales-return-with-item"
            exact
          />
          <PrivateRoute
            component={SalesReturnWithItem}
            path="/sales-return-with-item/:id/:mode"
            exact
          />
          <PrivateRoute
            component={SalesReturnWithOutItem}
            path="/sales-return-without-item"
            exact
          />
          <PrivateRoute
            component={SalesReturnWithOutItem}
            path="/sales-return-without-item/:id/:mode"
            exact
          />
          <PrivateRoute
            component={PurchaseReturnWithItem}
            path="/purchase-return-with-item"
            exact
          />
          <PrivateRoute
            component={PurchaseReturnWithItem}
            path="/purchase-return-with-item/:id/:mode"
            exact
          />
          <PrivateRoute
            component={PurchaseReturnWithOutItem}
            path="/purchase-return-without-item"
            exact
          />
          <PrivateRoute
            component={PurchaseReturnWithOutItem}
            path="/purchase-return-without-item/:id/:mode"
            exact
          />

          <PrivateRoute
            component={CustomerAndSupplierCommunication}
            path="/customer-and-supplier-communication"
            exact
          />
          <PrivateRoute
            component={Events}
            path="/customer-and-supplier-communication/events"
            exact
          />
          <PrivateRoute
            component={EventDetail}
            path="/customer-and-supplier-communication/events/detail/:id"
            exact
          />
          <PrivateRoute component={Contra} path="/contra" exact />
          <PrivateRoute component={Contra} path="/contra/:id/:mode" exact />
          <PrivateRoute component={SalesReport} path="/sales-report" exact />
          <PrivateRoute
            component={SalesReport}
            path="/sales-report/:date/:from"
            exact
          />
          <PrivateRoute
            component={SalesReport}
            path="/sales-report/:params"
            exact
          />
          <PrivateRoute
            component={SalesReturnReport}
            path="/sales-return-report"
            exact
          />
          <PrivateRoute
            component={SalesReturnReport}
            path="/sales-return-report/:params"
            exact
          />
          <PrivateRoute
            component={SalesReturnReport}
            path="/sales-return-report/:date/:from"
            exact
          />
          <PrivateRoute
            component={PurchaseReturnReport}
            path="/purchase-return-report"
            exact
          />
          <PrivateRoute
            component={PurchaseReturnReport}
            path="/purchase-return-report/:params"
            exact
          />
          <PrivateRoute
            component={PurchaseReturnReport}
            path="/purchase-return-report/:date/:from"
            exact
          />
          <PrivateRoute
            component={SaleEstimateReport}
            path="/sale-estimate-report/:type"
            exact
          />
          <PrivateRoute
            component={SaleEstimateReport}
            path="/sale-estimate-report/:type/:date/:from"
            exact
          />
          <PrivateRoute
            component={SaleEstimateReport}
            path="/sale-estimate-report/:type/:params"
            exact
          />
          <PrivateRoute
            component={PurchaseReports}
            path="/purchase-report"
            exact
          />
          <PrivateRoute
            component={PurchaseReports}
            path="/purchase-report/:date/:from"
            exact
          />
          <PrivateRoute
            component={PurchaseReports}
            path="/purchase-report/:params"
            exact
          />
          <PrivateRoute
            component={ExpenseReports}
            path="/expense-report"
            exact
          />
          <PrivateRoute
            component={ExpenseReports}
            path="/expense-report/:date/:from"
            exact
          />
          <PrivateRoute
            component={ExpenseReports}
            path="/expense-report/:params"
            exact
          />
          <PrivateRoute
            component={RecieptReports}
            path="/reciept-report"
            exact
          />
          <PrivateRoute
            component={RecieptReports}
            path="/reciept-report/:params"
            exact
          />
          <PrivateRoute component={ContraReports} path="/contra-report" exact />
          <PrivateRoute
            component={ContraReports}
            path="/contra-report/:params"
            exact
          />
          <PrivateRoute component={AgingReports} path="/aging-report" exact />
          <PrivateRoute
            component={AgingReports}
            path="/aging-report/:partyId"
            exact
          />
          <PrivateRoute
            component={AgingReportsPartyWise}
            path="/aging-reports/:type/:params"
            exact
          />
          <PrivateRoute
            component={CustomerSetOffReports}
            path="/customer-set-off-report"
            exact
          />
          <PrivateRoute
            component={SupplierSetOffReports}
            path="/supplier-set-off-report"
            exact
          />
          <PrivateRoute
            component={PaymentReports}
            path="/payment-report"
            exact
          />
          <PrivateRoute
            component={PaymentReports}
            path="/payment-report/:params"
            exact
          />
          <PrivateRoute component={LedgerReports} path="/ledger-report" exact />
          <PrivateRoute
            component={LedgerReports}
            path="/ledger-report/:paramId"
            exact
          />
          <PrivateRoute
            component={LedgerReports}
            path="/ledger-report/:paramId/:date/:from"
            exact
          />
          <PrivateRoute
            component={MasterGroupReports}
            path="/master-group"
            exact
          />
          <PrivateRoute
            component={MasterGroupReports}
            path="/master-group/:id"
            exact
          />
          <PrivateRoute
            component={PartyBalanceSummaryReports}
            path="/party-balance-summary/:type"
            exact
          />
          <PrivateRoute component={VATReports} path="/vat-report" exact />
          <PrivateRoute
            component={VATReportsPlaceOfSupply}
            path="/vat-report-place-of-supply"
            exact
          />
          <PrivateRoute
            component={VATReportsHSNWise}
            path="/vat-report-hsn-wise"
            exact
          />
          <PrivateRoute
            component={VATReportsPartyWise}
            path="/vat-report-party-wise"
            exact
          />
          <PrivateRoute
            component={VATReportsTaxType}
            path="/vat-report-tax-type"
            exact
          />
          <PrivateRoute
            component={VATReportsCostCenter}
            path="/vat-report-cost-center"
            exact
          />
          <PrivateRoute
            component={VATReportsVoucherType}
            path="/vat-report-voucher-type"
            exact
          />
          <PrivateRoute
            component={JournalEntriesReports}
            path="/journal-entries-report"
            exact
          />
          <PrivateRoute component={TrialBalance} path="/trail-balance" exact />
          <PrivateRoute component={BalanceSheet} path="/balance-sheet" exact />
          <PrivateRoute
            component={DeliveryOrderReports}
            path="/delivery-order-report"
            exact
          />
          <PrivateRoute
            component={DeliveryOrderReports}
            path="/delivery-order-report/:params"
            exact
          />
          <PrivateRoute
            component={ProfitAndLoss}
            path="/profit-and-loss"
            exact
          />
          <PrivateRoute
            component={StockSummaryReports}
            path="/stock-summary-report"
            exact
          />
          <PrivateRoute
            component={StockSummaryReports}
            path="/stock-summary-report/:params"
            exact
          />
          <PrivateRoute
            component={LowStockReports}
            path="/low-max-stock-report"
            exact
          />
          <PrivateRoute
            component={LowStockReports}
            path="/low-max-stock-report/:type/"
            exact
          />
          <PrivateRoute
            component={LowStockReports}
            path="/low-max-stock-report/:type/:params"
            exact
          />
          <PrivateRoute
            component={StockLossReports}
            path="/stock-loss-report"
            exact
          />
          <PrivateRoute
            component={StockDetailedReports}
            path="/stock-detailed-report"
            exact
          />
          <PrivateRoute
            component={StockDetailedReports}
            path="/stock-detailed-report/:params"
            exact
          />
          <PrivateRoute
            component={ItemDetailedReports}
            path="/item-detailed-report"
            exact
          />
          <PrivateRoute
            component={ItemDetailedReports}
            path="/item-detailed-report/:id/"
            exact
          />
          <PrivateRoute
            component={ItemWiseProfitReports}
            path="/item-wise-profit-report"
            exact
          />
          <PrivateRoute
            component={ItemWiseProfitReports}
            path="/item-wise-profit-report/:params"
            exact
          />
          <PrivateRoute
            component={ItemWiseProfitReports}
            path="/item-wise-profit-report/:date/:from"
            exact
          />
          <PrivateRoute
            component={AllTransactionsReports}
            path="/all-transactions-report"
            exact
          />
          <PrivateRoute
            component={PDCManagementReport}
            path="/pdc-management-report"
            exact
          />
          <PrivateRoute
            component={MonthlySummaryReport}
            path="/monthly-summary-report"
            exact
          />
          <PrivateRoute
            component={MonthlySummaryReport}
            path="/monthly-summary-report/:type"
            exact
          />
          <PrivateRoute
            component={DailySummaryReport}
            path="/daily-summary-report"
            exact
          />
          <PrivateRoute
            component={DailySummaryReport}
            path="/daily-summary-report/:type"
            exact
          />
          <PrivateRoute
            component={StockJournalReports}
            path="/stock-journal-report/:type"
            exact
          />
          <PrivateRoute
            component={AdvanceReports}
            path="/advance-report"
            exact
          />
          <PrivateRoute
            component={ItemWiseTransactionReports}
            path="/item-wise-transaction-report"
            exact
          />
          <PrivateRoute
            component={ItemWiseTransactionReports}
            path="/item-wise-transaction-report/:type"
            exact
          />
          <PrivateRoute
            component={InventoryAgingReport}
            path="/inventory-aging-report"
            exact
          />
          <PrivateRoute
            component={LedgerWiseReceiptPaymentSummary}
            path="/ledger-wise-summary-report"
            exact
          />
          <PrivateRoute
            component={LedgerWiseReceiptPaymentSummary}
            path="/ledger-wise-summary-report/:type"
            exact
          />
          <PrivateRoute
            component={SummaryReports}
            path="/summary-reports"
            exact
          />
          <PrivateRoute
            component={DoneByBasedSummaryReport}
            path="/doneby-based-summary-report"
            exact
          />
          <PrivateRoute
            component={DoneByBasedSummaryReport}
            path="/doneby-based-summary-report/:type"
            exact
          />
          <PrivateRoute
            component={CostCenterBasedSummaryReport}
            path="/cost-center-based-summary-report"
            exact
          />
          <PrivateRoute
            component={CostCenterBasedSummaryReport}
            path="/cost-center-based-summary-report/:type"
            exact
          />
          <PrivateRoute
            component={CostCenterDailySummaryReport}
            path="/cost-center-daily-summary-report"
            exact
          />
          <PrivateRoute
            component={CostCenterMonthlySummaryReport}
            path="/cost-center-monthly-summary-report"
            exact
          />
          <PrivateRoute
            component={DoneByDailySummaryReport}
            path="/doneby-based-daily-summary-report"
            exact
          />
          <PrivateRoute
            component={DoneByMonthlySummaryReport}
            path="/doneby-based-monthly-summary-report"
            exact
          />
          <PrivateRoute
            component={PartyWiseSummaryReport}
            path="/partywise-summary-report"
            exact
          />
          <PrivateRoute
            component={PartyWiseSummaryReport}
            path="/partywise-summary-report/:type"
            exact
          />
          <PrivateRoute
            component={StoreWiseSummaryReport}
            path="/store-wise-summary-report"
            exact
          />
          <PrivateRoute
            component={GroupWiseSummaryReport}
            path="/group-wise-summary-report"
            exact
          />
          <PrivateRoute
            component={LedgerReportMonthlySummary}
            path="/ledger-report-monthly-summary"
            exact
          />
          <PrivateRoute
            component={LedgerReportMonthlySummary}
            path="/ledger-report-monthly-summary/?:params"
            exact
          />
          <PrivateRoute component={AddItem} path="/add-item" exact />
          <PrivateRoute component={CustomerList} path="/add-customer" exact />
          <PrivateRoute component={SupplierList} path="/add-supplier" exact />
          <PrivateRoute
            component={LedgerGroupList}
            path="/ledger-group"
            exact
          />
          <PrivateRoute component={AddLedger} path="/add-ledger" exact />
          <PrivateRoute
            component={AddLedger}
            path="/add-ledger/:groupId"
            exact
          />
          <PrivateRoute
            component={ItemGroupList}
            path="/add-item-group"
            exact
          />
          <PrivateRoute
            component={ItemCategoryList}
            path="/add-category"
            exact
          />
          <PrivateRoute component={ItemBrandList} path="/add-brand" exact />
          <PrivateRoute component={DonebyList} path="/add-done-by" exact />
          <PrivateRoute
            component={CostCentreList}
            path="/add-cost-centre"
            exact
          />
          <PrivateRoute component={UnitList} path="/add-unit" exact />
          <PrivateRoute
            component={VoucherTypeList}
            path="/add-voucher-type"
            exact
          />
          <PrivateRoute
            component={EstimateStatusList}
            path="/add-estimate-status"
            exact
          />
          <PrivateRoute
            component={ProductionList}
            path="/add-production-list"
            exact
          />
          <PrivateRoute component={StoreList} path="/add-store" exact />
          <PrivateRoute
            component={ModeOfPaymentList}
            path="/add-mode-of-payment"
            exact
          />
          <PrivateRoute component={SharingRoom} path="/sharing-room" exact />
          <PrivateRoute
            component={UploadImage}
            path="/upload-sales-image"
            exact
          />
          <PrivateRoute
            component={UploadImage}
            path="/upload-purchase-image"
            exact
          />
          <PrivateRoute
            component={UploadImage}
            path="/upload-expense-image"
            exact
          />
          <PrivateRoute component={TransactionTab} path="/transactions" exact />
          <PrivateRoute component={ReportTab} path="/reports" exact />
          <PrivateRoute component={AddItemTab} path="/add-items" exact />
          <PrivateRoute
            component={NotificationPage}
            path="/notification"
            exact
          />
          <PrivateRoute
            component={AddProduction}
            path="/add-production"
            exact
          />
          <PrivateRoute
            component={AddProduction}
            path="/add-production/:id/:mode"
            exact
          />
          <PrivateRoute
            component={MultiUnitPricing}
            path="/add-multi-unit-pricing"
            exact
          />
          <PrivateRoute
            component={TransactionDraftReports}
            path="/transaction-draft-report"
            exact
          />
          {/* <PrivateRoute
            component={ImportReportsNew}
            path="/import-reports"
            exact
          />

          <PrivateRoute
            component={ImportReports}
            path="/import-reports-sales-with-item"
            exact
          />
          <PrivateRoute
            component={ImportReports}
            path="/import-reports-sales-without-item"
            exact
          />
          <PrivateRoute
            component={ImportReports}
            path="/import-reports-purchase-with-item"
            exact
          />
          <PrivateRoute
            component={ImportReports}
            path="/import-reports-purchase-without-item"
            exact
          />
          <PrivateRoute
            component={ImportReports}
            path="/import-reports-expense"
            exact
          />
          <PrivateRoute
            component={ImportReports}
            path="/import-reports-items"
            exact
          />
          <PrivateRoute
            component={ImportReports}
            path="/import-reports-customers"
            exact
          />
          <PrivateRoute
            component={ImportReports}
            path="/import-reports-suppliers"
            exact
          />
          <PrivateRoute
            component={ImportReports}
            path="/import-reports-ledger"
            exact
          />
          <PrivateRoute
            component={ImportReports}
            path="/import-reports-journal"
            exact
          />
          <PrivateRoute
            component={ImportReports}
            path="/import-reports-receipts"
            exact
          />
          <PrivateRoute
            component={ImportReports}
            path="/import-reports-payments"
            exact
          /> */}
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </ErrorBoundary>
  );
};

export default Routes;
