import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import { useToast } from "@chakra-ui/react";

import Form from "../Form";
import RenderComponent from "./RenderComponent";
import { useCreateCostCenter } from "hooks/CostCenter/useCreateCostCenter";
import { useUpdateCostCenter } from "hooks/CostCenter/useUpdateCostCenter";
import { getLocalStore } from "utils/utils";
import { useDoneBy } from "hooks/DoneBy/useDoneBy";

const AddCostCentre = (props) => {
  const {
    type,
    editValue,
    onAddSuccess,
    isApproval = false,
    rejectFunc,
    isRejectLoading = false,
  } = props;
  const [suspand, setSuspand] = useState(false);
  const auth = getLocalStore("AUTH_DETAILS");
  const toast = useToast();
  const { data: doneByData } = useDoneBy();

  const {
    mutate: createCostCenter,
    data,
    isSuccess,
    isLoading: isCreateLoading,
  } = useCreateCostCenter();
  const {
    mutate: updateCostCenter,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useUpdateCostCenter();

  const initialValue = {
    name: "",
    is_suspended: suspand,
  };
  const [formValues, setformValues] = useState(initialValue);
  const [doneBy, setDoneBy] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.status === "success") {
        toastWithMessage("Cost Center created successfully");
        onAddSuccess(data?.data);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Cost Center updated successfully");
        onAddSuccess(updateResponse?.data);
      }
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (type === "add") {
      setformValues(initialValue);
      console.log("initialValue", initialValue);
      if (doneByData) {
        setDoneBy(
          doneByData.data?.filter((i) =>
            [getLocalStore("DEFAULT_SETTINGS_DATA")?.default_done_by]?.includes(
              i.id
            )
          ) || []
        );
      }
    } else {
      console.log("editValue", editValue);
      setformValues(editValue);
      setSuspand(editValue.is_suspended);
      if (doneByData) {
        setDoneBy(
          doneByData.data?.filter((i) => editValue.done_by?.includes(i.id)) ||
            []
        );
      }
    }
  }, [type, editValue, doneByData]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Form
      initialValues={{ ...formValues }}
      RenderComponent={RenderComponent}
      handleOnSubmit={(fields) => {
        fields.done_by = doneBy?.map((i) => i.id) || [];
        fields.is_suspended = suspand;

        if (type === "edit") {
          if (isApproval) {
            fields.approved_by = auth?.data?.id;
          } else {
            delete fields.approved_by;
          }
          updateCostCenter(fields);
        } else {
          createCostCenter(fields);
        }
      }}
      InputLabelProps={{ style: { fontSize: "12px" } }}
      customProps={{
        type: type,
        isLoading: isCreateLoading || isUpdateLoading,
        isSubmitted: isSuccess || isUpdateSuccess,
        setSuspand,
        suspand,
        isApproval,
        rejectFunc,
        isRejectLoading,
        doneByData,
        doneBy,
        setDoneBy,
      }}
      validationSchema={object().shape({
        name: string().required("Name Required"),
      })}
    />
  );
};

export default AddCostCentre;
