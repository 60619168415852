import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spacer,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { TextField } from "@material-ui/core";
import UnitAutoComplete from "components/UnitAutoComplete";
import { useItemUnit } from "hooks/ItemUnit/useItemUnit";
import { useDecimalLength } from "hooks/useDecimalLength";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  IButton,
  IHead,
  IModalOut,
  IModalIn,
  IButtonOut,
} from "components/IModal";
export default function MultiUnitModal(props) {
  const { initialValue, isOpen, onClose, onSubmit, baseUnit } = props;
  const decimalLength = useDecimalLength(2);
  const unitRef = useRef();
  const toast = useToast();
  const { data: unitData } = useItemUnit();
  const [isOpenModal, setIsOpen] = useState("");
  const [disable, setDisable] = useState(true);
  const [item, setItem] = useState({
    id: null,
    unit: null,
    rate: null,
  });

  useEffect(() => {
    if (item.unit && item.rate) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [item]);

  useEffect(() => {
    setItem({ ...initialValue, id: initialValue?.id || null });
  }, [isOpen, initialValue]);

  const handleSubmit = () => {
    onSubmit({ ...item });
    handleClose();
  };

  const handleClose = () => {
    setItem({
      id: null,
      unit: null,
      rate: null,
    });
    onClose();
  };

  const getUnitName = (id) => {
    const unitName = unitData?.data?.find((f) => f.id === id);
    return unitName?.unit_symbol;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      scrollBehavior="inside"
      //   size="xl"
    >
      <ModalOverlay />
      <ModalContent width={isMobile ? "90%" : "100%"}>
        <ModalCloseButton />
        <ModalBody>
          <IModalOut>
            <IHead text="Multi Unit" />
            <IModalIn>
              <HStack w="100%" spacing={2}>
                <Text as="kbd">1</Text>
                <UnitAutoComplete
                  selectedUnit={item.unit}
                  modal={true}
                  setSelectedUnit={(newValue) => {
                    if (newValue === baseUnit) {
                      return toast({
                        title: "Multi unit can't be same as base unit",
                        status: "warning",
                        duration: 3000,
                        isClosable: true,
                      });
                    } else {
                      setItem({
                        ...item,
                        unit: newValue,
                      });
                    }
                  }}
                  inputRef={unitRef}
                  setIsOpen={setIsOpen}
                />
                <Text as="kbd">x</Text>
                <NumberInput
                  min={0}
                  allowMouseWheel
                  value={item.rate || ""}
                  onChange={(value) => setItem({ ...item, rate: value })}
                  fontSize={isMobile ? "12px" : ""}
                >
                  <NumberInputField
                    placeholder=" Conversion Rate"
                    fontSize={isMobile ? "12px" : ""}
                  />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Text as="kbd">=</Text>
                <Text as="kbd">1&nbsp;{getUnitName(baseUnit)}</Text>
              </HStack>
            </IModalIn>
            <IButtonOut>
              <IButton disabled={disable} onClick={handleSubmit} type="add" />
            </IButtonOut>
          </IModalOut>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
