import { createSlice } from '@reduxjs/toolkit'

// import { setLocalStore, setResponse } from '../utils/utils'
import api, { API_ADVANCES_LIST } from '../utils/axios'

const initialState = {
  loading: false,
  advances: [],
}

const advance = createSlice({
  name: 'advance',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setAdvances: (state, action) => {
      state.advances = action.payload.advances
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message
    }
  }
})

export const { setLoading, setAdvances, setErrorMessage } = advance.actions

export default advance.reducer

export const fetchAdvances = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.get(API_ADVANCES_LIST.replace('{party}', data.party))
    if (response && response.data) {
      dispatch(setAdvances({ advances: response.data.data }))
      dispatch(setLoading({ loading: false }))
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
    dispatch(setAdvances({ advances: [] }))
  }
}
