import { useEffect, useState } from "react";

import AutocompleteWithAddOption from "FormComponents/AutoComplete/component";
import { useDoneBy } from "hooks/DoneBy/useDoneBy";
import AddDoneBy from "components/AddDoneby";

const DoneBySelectionField = (props) => {
  const {
    doneBy,
    disabled,
    setDoneBy,
    required,
    inputRef,
    onFocus,
    modal,
    setIsOpenDoneByModal,
    onKeyDown,
    isReportField = false,
  } = props;

  const { data, isLoading } = useDoneBy();
  const [doneByObject, setDoneByObject] = useState();
  const [doneByList, setDoneByList] = useState([]);

  useEffect(() => {
    if (data) {
      let apiData = data.data;
      if (isReportField) {
        apiData = [
          { id: "notAvailableId", name: "-Not Available-" },
          ...data.data,
        ];
      }
      setDoneByList(apiData);
    }
  }, [data]);

  useEffect(() => {
    if (doneByList && doneBy) {
      setDoneByObject(doneByList.find((i) => i.id === doneBy));
    } else {
      setDoneByObject(null);
    }
  }, [doneBy, doneByList]);

  const setSelectedDoneBy = (selectedDoneBy) => {
    setDoneBy(selectedDoneBy?.id);
  };

  return (
    <AutocompleteWithAddOption
      value={doneByObject}
      options={doneByList}
      onChange={setSelectedDoneBy}
      onKeyDown={onKeyDown}
      optionLabel="name"
      inputLabel="Done By"
      disabled={disabled}
      modal={props.modal}
      required={required}
      inputRef={inputRef}
      onFocus={onFocus}
      setIsOpenModal={setIsOpenDoneByModal}
      isLoading={isLoading}
    >
      <AddDoneBy
        type="add"
        listData={doneByList}
        onAddSuccess={(doneBy) => {
          setSelectedDoneBy(doneBy);
        }}
      />
    </AutocompleteWithAddOption>
  );
};

export default DoneBySelectionField;
