import { createSlice } from "@reduxjs/toolkit";

import api, {
  API_VAT_REPORT_LIST,
  API_VAT_REPORT_EXPORT,
} from "../utils/axios";

const initialState = {
  loading: false,
  sale_data: [],
  purchase_data: [],
  expense_data: [],
};

const vatreport = createSlice({
  name: "vatreport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReports: (state, action) => {
      state.sale_data = action.payload.sale_data;
      state.purchase_data = action.payload.purchase_data;
      state.expense_data = action.payload.expense_data;
    },
    setReportDetails: (state, action) => {
      state.reportDetails = action.payload.reportDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setReports, setReportDetails, setErrorMessage } =
  vatreport.actions;

export default vatreport.reducer;

export const fetchVATReports = (data, callback) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_VAT_REPORT_LIST.replace("{startDate}", data.startDate).replace(
        "{endDate}",
        data.endDate
      )
    );
    if (response?.data?.status === "success") {
      dispatch(
        setReports({
          sale_data: response.data.data.sale_data,
          purchase_data: response.data.data.purchase_data,
          expense_data: response.data.data.expense_data,
        })
      );
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(
      setReports({
        sale_data: [],
        purchase_data: [],
        expense_data: [],
      })
    );
  }
};

export const exportVATReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_VAT_REPORT_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};
