import React, { useEffect, useState } from "react";
import AutocompleteWithAddOption from "FormComponents/AutoComplete/component";

import AddItemBrand from "../AddItemBrand";
import { useItemBrand } from "hooks/ItemBrand/useItemBrand";

export default function BrandAutoComplete(props) {
  const {
    selectedBrand,
    setSelectedBrand,
    onFocus,
    modal,
    isReportField = false,
    disabled = false,
  } = props;
  const { data, isLoading } = useItemBrand();
  const [searchData, setSearchData] = useState("");

  const [listData, setlistData] = useState([]);
  const [brand, setBrand] = useState(null);

  useEffect(() => {
    let category = listData?.find((item) => item.id === selectedBrand);
    setBrand(category);
  }, [selectedBrand, listData]);

  useEffect(() => {
    if (data) {
      let apiData = data?.data;
      if (isReportField) {
        apiData = [
          { name: "-Not Available-", id: "notAvailableId" },
          ...data.data,
        ];
      }

      let brands = apiData
        ?.slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item, i) => item);
      setlistData(brands ?? []);
    }
  }, [data]);

  return (
    <>
      <AutocompleteWithAddOption
        value={brand}
        options={listData}
        modal={props.modal}
        onChange={(newValue) => {
          setSelectedBrand(newValue?.id);
          setBrand(newValue);
        }}
        inputRef={props.inputRef}
        optionLabel="name"
        inputLabel="Item Brand"
        setIsOpen={props.setIsOpen}
        textFieldClick={props.textFieldClick}
        setSearchData={setSearchData}
        onFocus={onFocus}
        disabled={disabled}
      >
        <AddItemBrand
          type="add"
          listData={listData}
          onSuccess={() => {}}
          searchData={searchData}
        />
      </AutocompleteWithAddOption>
    </>
  );
}
