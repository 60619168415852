import React from "react";
import { useDecimalLength } from "./useDecimalLength";

export default function useCheckDecimal() {
  const decimalLength = useDecimalLength();
  const checkDecimal = (value) => {
    let decimals = value?.toString()?.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      let decimalValue = _parseFloat(value).toFixed(decimalLength);
      return decimalValue;
    } else {
      return value;
    }
  };
  return [checkDecimal];
}

const _parseFloat = (num) => {
  return parseFloat(num || 0);
};
