import { useMutation, useQueryClient } from "react-query";
import api, { API_ESTIMATE_STATUS_LIST_GET_EDIT_DELETE } from "utils/axios";

const deleteEstimateStatus = (EstimateStatus) =>
  api
    .delete(
      API_ESTIMATE_STATUS_LIST_GET_EDIT_DELETE.replace(
        "{id}",
        EstimateStatus.id
      )
    )
    .then((res) => res.data);

export function useDeleteEstimateStatus() {
  const queryClient = useQueryClient();
  return useMutation(deleteEstimateStatus, {
    onSuccess: () => queryClient.refetchQueries("estimatestatus"),
  });
}
