import { useQuery } from "react-query";
import api, { API_NOTIFICATIONS } from "utils/axios";

const getNotifications = async () =>
  api.get(API_NOTIFICATIONS).then((res) => res.data);

export function useNotifications() {
  return useQuery("notifications", getNotifications, {
    cacheTime: 0,
    staleTime: 0,
  });
}
