import { getLocalStore } from "utils/utils";

const country = getLocalStore("AUTH_DETAILS") || "";

export let TAX_MODELS = [];

switch (country?.data?.company?.country) {
  case "INDIA":
    TAX_MODELS = [
      { value: "0", label: "0" },
      { value: "0.25", label: "0.25%" },
      { value: "3", label: "3%" },
      { value: "5", label: "5%" },
      { value: "12", label: "12%" },
      { value: "18", label: "18%" },
      { value: "28", label: "28%" },
    ];
    break;
  case "SAUDI":
    TAX_MODELS = [
      { value: "0", label: "0" },
      { value: "15", label: "15%" },
    ];
    break;
  case "OMAN":
    TAX_MODELS = [
      { value: "0", label: "0" },
      { value: "5", label: "5%" },
    ];
    break;
  case "UAE":
    TAX_MODELS = [
      { value: "0", label: "0" },
      { value: "5", label: "5%" },
    ];
    break;
  default:
    TAX_MODELS = [
      { value: "0", label: "0" },
      { value: "0.25", label: "0.25%" },
      { value: "3", label: "3%" },
      { value: "5", label: "5%" },
      { value: "7", label: "7%" },
      { value: "12", label: "12%" },
      { value: "15", label: "15%" },
      { value: "18", label: "18%" },
      { value: "28", label: "28%" },
      { value: "-1", label: "Exempted" },
    ];
    break;
}
