import { useQuery } from "react-query";
import api, { API_LEDGER_LIST } from "utils/axios";

const getLedgers = () => api.get(API_LEDGER_LIST).then((res) => res.data);

export function useLedgers() {
  return useQuery("ledgers", getLedgers, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
