import { useMutation, useQueryClient } from "react-query";
import api, { API_ESTIMATE_STATUS_LIST } from "utils/axios";

const createEstimateStatus = (estimatestatus) =>
  api.post(API_ESTIMATE_STATUS_LIST, estimatestatus).then((res) => res.data);

export function useCreateEstimateStatus() {
  const queryClient = useQueryClient();
  return useMutation(createEstimateStatus, {
    onSuccess: () => queryClient.refetchQueries("estimatestatus"),
  });
}
