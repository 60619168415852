import { useMutation, useQueryClient } from "react-query";
import api, { API_PRICE_CATEGORY_NAME } from "utils/axios";

const updatePriceCategoryName = (PriceCategoryName) =>
  api
    .patch(
      `${API_PRICE_CATEGORY_NAME}${PriceCategoryName.id}`,
      PriceCategoryName
    )
    .then((res) => res.data);

export function useUpdatePriceCategoryName() {
  const queryClient = useQueryClient();
  return useMutation(updatePriceCategoryName, {
    onSuccess: () => queryClient.refetchQueries("pricecategoryname"),
  });
}
