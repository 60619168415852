import { useMutation, useQueryClient } from "react-query";
import api, { API_LEDGER_EDIT } from "utils/axios";

const deleteLedger = (ledger) =>
  api
    .delete(API_LEDGER_EDIT.replace("{id}", ledger.id))
    .then((res) => res.data);

export function useDeleteLedger() {
  const queryClient = useQueryClient();
  return useMutation(deleteLedger, {
    onSuccess: () => queryClient.refetchQueries("ledgers"),
  });
}
