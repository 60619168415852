import { createSlice } from "@reduxjs/toolkit";

// import { setLocalStore, setResponse } from '../utils/utils'
import api, {
  API_AGING_REPORT_EXPORT,
  API_AGING_REPORT_LIST,
} from "../utils/axios";

const initialState = {
  loading: false,
  next: "",
  previous: "",
  count: 0,
  page: 0,
  total_pages: 0,
  totalDue: 0,
  totalUnDue: 0,
  total: 0,
  reports: [],
  reportDetails: {},
};

const agingreport = createSlice({
  name: "agingreport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReports: (state, action) => {
      state.reports = action.payload.reports;
      state.count = action.payload.count;
      state.total_pages = action.payload.total_pages;
      state.totalDue = action.payload.total_due;
      state.totalUnDue = action.payload.total_undue;
      state.totalDueToday = action.payload.total_due_today;
      state.total =
        parseFloat(action.payload.total_undue) +
        parseFloat(action.payload.total_due) +
        parseFloat(action.payload.total_due_today);
    },
    setReportDetails: (state, action) => {
      state.reportDetails = action.payload.reportDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setReports, setReportDetails, setErrorMessage } =
  agingreport.actions;

export default agingreport.reducer;

export const fetchAgingReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url = API_AGING_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{reportType}", data.reportType)
        .replace("{startDate}", data.startDate)
        .replace("{sortList}", data.sortList)
        .concat(
          data.invoiceStartDate ? `&date__gte=${data.invoiceStartDate}` : ""
        )
        .concat(data.invoiceEndDate ? `&date__lte=${data.invoiceEndDate}` : "")
        .concat(data.dueStartDate ? `&due_date__gte=${data.dueStartDate}` : "")
        .concat(data.dueEndDate ? `&due_date__lte=${data.dueEndDate}` : "")
        .concat(
          data.followupStartDate
            ? `&followup_date__gte=${data.followupStartDate}`
            : ""
        )
        .concat(
          data.followupEndDate
            ? `&followup_date__lte=${data.followupEndDate}`
            : ""
        );

      // Search params
      if (data.referenceNumber && data.referenceNumber !== "") {
        url = url + `&reference_number__icontains=${data.referenceNumber}`;
      }
      if (data.reportType === "expense") {
        if (data.invoiceNumber && data.invoiceNumber !== "") {
          url = url + `&supplier_invoice__icontains=${data.invoiceNumber}`;
        }
      } else {
        if (data.invoiceNumber && data.invoiceNumber !== "") {
          url = url + `&invoice_number__icontains=${data.invoiceNumber}`;
        }
      }
      if (data.notes && data.notes !== "") {
        url = url + `&notes__icontains=${data.notes}`;
      }
      if (data.invoiceBalance && data.invoiceBalance !== "") {
        url = url + `&invoice_balance=${data.invoiceBalance}`;
      }
      if (data.receivedAmount && data.receivedAmount !== "") {
        url = url + `&received_amount=${data.receivedAmount}`;
      }
      if (data.balanceAmount && data.balanceAmount !== "") {
        url = url + `&total=${data.balanceAmount}`;
      }
      if (data.mobileNumber && data.mobileNumber !== "") {
        url = url + `&party__mobile__icontains=${data.mobileNumber}`;
      }

      // Filter params

      if (data.costCenter?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.costCenter.filter(
          (item) => item !== notAvailableId
        );
        if (filtered?.length > 0) {
          url = url + `&cost_centre__in=${filtered}`;
        }
        if (data.costCenter.includes(notAvailableId)) {
          url = url + `&not_available_field=cost_centre`;
        }
      }

      if (data.doneBy?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.doneBy.filter((item) => item !== notAvailableId);
        if (filtered?.length > 0) {
          url = url + `&done_by__in=${filtered}`;
        }
        if (data.doneBy.includes(notAvailableId)) {
          url = url + `&not_available_field=done_by`;
        }
      }
      if (data.dueToday && data.dueToday !== "") {
        url = url + `&due_status=${data.dueToday}`;
      }
      if (data.dueWithin !== "0" || data.dueUpto !== "0") {
        if (data.dueWithin && data.dueWithin !== "") {
          url = url + `&due_status_gte=${data.dueWithin}`;
        }
        if (data.dueUpto && data.dueUpto !== "") {
          url = url + `&due_status_lte=${data.dueUpto}`;
        }
      }
      if (data.followupToday && data.followupToday !== "") {
        url = url + `&followup_date_status=${data.followupToday}`;
      }
      if (data.followupDueWithin !== "0" || data.followupDueUpto !== "0") {
        if (data.followupDueWithin && data.followupDueWithin !== "") {
          url = url + `&followup_status_gte=${data.followupDueWithin}`;
        }
        if (data.followupDueUpto && data.dufollowupDueUptoeUpto !== "") {
          url = url + `&followup_status_lte=${data.followupDueUpto}`;
        }
      }

      const response = await api.get(url);

      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_due: response.data.data.total_due,
            total_undue: response.data.data.total_undue,
            total_due_today: response.data.data.total_due_today,
            total:
              parseFloat(response.data.data.total_due) +
              parseFloat(response.data.data.total_undue) +
              parseFloat(response.data.data.total_due_today),
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const exportAgingReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    //api call
    let url = API_AGING_REPORT_EXPORT.replace(
      "{exportReportType}",
      data.exportReportType
    ).replace("{startDate}", data.startDate);

    const response = await api.get(url);
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.report_url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};
export const clearAgingReports = (data, callback) => async (dispatch) => {
  dispatch(setReports({ ...initialState }));
};
