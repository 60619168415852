import { createSlice } from "@reduxjs/toolkit";

import { clearLocalStore, setLocalStore } from "../utils/utils";
import api, {
  API_FORGOT_USERNAME,
  API_RESET_PASSWORD,
  API_MULTI_LOGIN_CHECK,
  API_LOGOUT,
} from "../utils/axios";
import { toast } from "@chakra-ui/react";

const initialState = {
  loading: false,
  user: {},
};

const session = createSlice({
  name: "session",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setUser, setErrorMessage } = session.actions;
export default session.reducer;

export const login =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      const response = await api.post(API_MULTI_LOGIN_CHECK, data);
      localStorage.setItem(
        "region",
        JSON.stringify({
          country: response.data.data.company.country,
          state: response.data.data.company.emirate,
        })
      );
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        dispatch(setUser({ user: response.data }));
        dispatch(setErrorMessage({ message: "" }));
        setLocalStore("AUTH_DETAILS", response.data);
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      } else {
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(
        setErrorMessage({ message: "Sorry, unrecognized username or password" })
      );
    }
  };

export const forgotUsername =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      const response = await api.post(API_FORGOT_USERNAME, data);
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        dispatch(setLoading({ loading: false }));
        callback && callback();
      } else {
        dispatch(setLoading({ loading: false }));
      }
    } catch {
      dispatch(setLoading({ loading: false }));
    }
  };

export const resetPassword =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      //api call
      const response = await api.post(API_RESET_PASSWORD, data);
      if (
        response &&
        response.data &&
        response.data.status &&
        response.data.status === "success"
      ) {
        dispatch(setLoading({ loading: false }));
        callback && callback();
      } else {
        dispatch(setLoading({ loading: false }));
      }
    } catch {
      dispatch(setLoading({ loading: false }));
    }
  };

export const logout =
  ({ callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      const response = await api.delete(API_LOGOUT);
      if (response?.data?.status === "success") {
        toast({
          title: "Success",
          description: response?.data?.data,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      callback && callback(response?.data);
      dispatch(setLoading({ loading: false }));
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      callback && callback(error);
    }
  };
