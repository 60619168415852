import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getLocalStore } from "./utils/utils";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const auth = getLocalStore("AUTH_DETAILS");
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          auth.data.user_type === 1 ? (
            <Redirect to="/master-dashboard" />
          ) : (
            <Redirect to="/dashboard" />
            // <Redirect to="/default-user-settings" />
          )
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
