import { createSlice } from '@reduxjs/toolkit'

import api, { API_SHARING_ROOM_LIST, API_SHARING_ROOM_DELETE } from '../utils/axios'

const initialState = {
  loading: false,
  next: '',
  previous: '',
  count: 0,
  page: 0,
  total_pages: 0,
  files: [],
}

const sharingroom = createSlice({
  name: 'sharingroom',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setItems: (state, action) => {
      state.files = action.payload.files
      state.count = action.payload.count;
      state.total_pages = action.payload.total_pages;
      state.totalGross = action.payload.total_gross;
    },
    setItemDetails: (state, action) => {
      state.itemDetails = action.payload.itemDetails
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message
    }
  }
})

export const { setLoading, setItems, setItemDetails, setErrorMessage } = sharingroom.actions

export default sharingroom.reducer

export const fetchFiles = () => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    // api call
    const response = await api.get(API_SHARING_ROOM_LIST)
    if (response && response.data) {
      dispatch(setItems({ 
        files: response.data.data.results,
        count: response.data.data.count, 
        total_pages: response.data.data.total_pages
      }))
      dispatch(setLoading({ loading: false }))
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
    dispatch(setItems({ items: [] }))
  }
}

export const deleteFile = ({ data }) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const response = await api.delete(API_SHARING_ROOM_DELETE.replace('{id}', data.id))
    if (response && response.data) {
      dispatch(setLoading({ loading: false }))
      dispatch(fetchFiles())
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}