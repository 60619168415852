import React, { useEffect, useState } from "react";
import { number, object, string } from "yup";
import { useHistory } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

import Form from "../Form";
import RenderComponent from "./RenderComponent";
import { useCreateItemBrand } from "hooks/ItemBrand/useCreateItemBrand";
import { useUpdateItemBrand } from "hooks/ItemBrand/useUpdateItemBrand";
import { useCostCenters } from "hooks/CostCenter/useCostCenters";
import { useDoneBy } from "hooks/DoneBy/useDoneBy";
import { getLocalStore } from "utils/utils";

const AddItemBrand = (props) => {
  const {
    type,
    editValue,
    changeType,
    listData,
    onAddSuccess,
    searchData,
    approveFunc,
    rejectFunc,
    isApproval = false,
    isRejectLoading = false,
    isApproveLoading = false,
  } = props;
  const toast = useToast();
  const history = useHistory();
  const [isToggledFav, setIsToggledFav] = useState(false);
  const [suspand, setSuspand] = useState(false);
  const {
    mutate: createItemBrand,
    data,
    isSuccess,
    isLoading: isCreateLoading,
  } = useCreateItemBrand();
  const { data: costCenterData } = useCostCenters();
  const { data: doneByData } = useDoneBy();
  const {
    mutate: updateItemBrand,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useUpdateItemBrand();

  const initialValue = {
    name: searchData,
    opening_balance: 0,
    description: "",
    group: "",
    favourites: isToggledFav,
    is_suspended: suspand,
  };

  const [formValues, setformValues] = useState(initialValue);
  const [clearValues, setClearValues] = useState(false);
  const [costCenter, setCostCenter] = useState([]);
  const [doneBy, setDoneBy] = useState([]);

  const handleFavourite = () => {
    setIsToggledFav(!isToggledFav);
  };
  useEffect(() => {
    if (isSuccess) {
      if (data?.status === "success") {
        toastWithMessage("ItemBrand created successfully");
        onAddSuccess(data?.data);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("ItemBrand updated successfully");
        onAddSuccess(updateResponse?.data);
      }
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (type === "add") {
      setformValues(initialValue);
      if (costCenterData && doneByData) {
        setCostCenter(
          costCenterData.data?.filter((i) =>
            [
              getLocalStore("DEFAULT_SETTINGS_DATA")?.default_cost_centre,
            ]?.includes(i.id)
          ) || []
        );
        setDoneBy(
          doneByData.data?.filter((i) =>
            [getLocalStore("DEFAULT_SETTINGS_DATA")?.default_done_by]?.includes(
              i.id
            )
          ) || []
        );
      }
    } else {
      setIsToggledFav(editValue.favourites);
      setformValues(editValue);
      setSuspand(editValue.is_suspended);
      if (costCenterData && doneByData) {
        setCostCenter(
          costCenterData.data?.filter((i) =>
            editValue.cost_centre?.includes(i.id)
          ) || []
        );
        setDoneBy(
          doneByData.data?.filter((i) => editValue.done_by?.includes(i.id)) ||
            []
        );
      }
    }
  }, [type, editValue, doneByData, costCenterData]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const resetFormValues = () => {
    setformValues(initialValue);
    changeType();
  };
  return (
    <Form
      initialValues={{ ...formValues }}
      RenderComponent={RenderComponent}
      handleOnSubmit={(fields) => {
        fields.favourites = isToggledFav;
        fields.is_suspended = suspand;
        fields.cost_centre = costCenter?.map((i) => i.id) || [];
        fields.done_by = doneBy?.map((i) => i.id) || [];

        if (type === "edit") {
          updateItemBrand(fields);
        } else {
          createItemBrand(fields);
        }
      }}
      customProps={{
        type: type,
        clearInitialValues: () => resetFormValues(),
        handleResetToComponent: clearValues,
        listData: listData,
        isLoading: isCreateLoading || isUpdateLoading,
        isSubmitted: isSuccess || isUpdateSuccess,
        handleFavourite,
        isToggledFav,
        setSuspand,
        suspand,
        costCenter,
        setCostCenter,
        doneBy,
        setDoneBy,
        doneByData,
        costCenterData,
        isApproval,
        approveFunc,
        rejectFunc,
        isRejectLoading,
        isApproveLoading,
      }}
      validationSchema={object().shape({
        name: string().required("Name Required"),
      })}
    />
  );
};
export default AddItemBrand;
