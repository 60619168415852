import { createSlice } from "@reduxjs/toolkit";

// import { setLocalStore, setResponse } from '../utils/utils'
import api, {
  API_SALES_REPORT_LIST,
  API_SALES_REPORT_FOR_CUSTOMER_LIST,
  API_SALE_ESTIMATE_REPORT_LIST,
  API_SALES_REPORT_DELETE,
  API_SALES_REPORT_LIST_BY_PARTY,
  API_SALES_REPORT_EXPORT,
  API_SALE_ESTIMATE_REPORT_EXPORT,
  API_SALES_REPORTS_EXPORT,
  API_SALES_REPORT_LIST_BY_IDS,
  API_SALE_ESTIMATE_DELETE,
  API_SALE_ESTIMATE_REPORTS_EXPORT,
  API_SALES_REPORT_MINIMAL_LIST,
  API_SALES_REPORT_FOR_CUSTOMER_MINIMAL_LIST,
  API_SALES_REPORT_LIST_BY_PARTY_MINIMAL,
  API_SALES_REPORT_LIST_BY_IDS_MINIMAL,
  API_SALE_ESTIMATE_REPORT_LIST_MINIMAL,
} from "../utils/axios";

const initialState = {
  loading: false,
  next: "",
  previous: "",
  count: 0,
  page: 0,
  total_pages: 0,
  totalGross: 0,
  totalBalance: 0,
  totalNet: 0,
  totalTax: 0,
  reports: [],
  reportDetails: {},
};

const salesreport = createSlice({
  name: "salesreport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReports: (state, action) => {
      state.reports = action.payload.reports;
      state.count = action.payload.count;
      state.total_pages = action.payload.total_pages;
      state.totalGross = action.payload.total_gross;
      state.totalBalance = action.payload.total_balance;
      state.totalNet = action.payload.total_net;
      state.totalTax = action.payload.total_tax;
    },
    setReportDetails: (state, action) => {
      state.reportDetails = action.payload.reportDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setReports, setReportDetails, setErrorMessage } =
  salesreport.actions;

export default salesreport.reducer;

export const fetchSalesReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url = API_SALES_REPORT_MINIMAL_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{withItem}", data.withItem);
      if (data.isPOSEntry) {
        url = url + `&is_pos_entry=${data.isPOSEntry}`;
      }
      if (data.is_primary === false) {
        url = url + `&is_primary=${data.is_primary}`;
      }
      if (data.sortList && data.sortList !== "") {
        url = url + `&sorting=${data.sortList}`;
      }
      if (data.costCenter?.length > 0) {
        if (data.costCenter === "notAvailableId") {
          url = url + `&not_available_field=cost_centre`;
        } else {
          url = url + `&cost_centre__in=${data.costCenter}`;
        }
      }
      if (data.doneBy?.length > 0) {
        if (data.doneBy === "notAvailableId") {
          url = url + `&not_available_field=done_by`;
        } else {
          url = url + `&done_by__in=${data.doneBy}`;
        }
      }
      if (data.taxType?.length > 0) {
        if (data.taxType === "notAvailableId") {
          url = url + `&not_available_field=tax_type`;
        } else {
          url = url + `&tax_type__in=${data.taxType}`;
        }
      }
      if (data.placeOfSupply?.length > 0) {
        if (data.placeOfSupply === "notAvailableId") {
          url = url + `&not_available_field=place_of_supply`;
        } else {
          url = url + `&place_of_supply__in=${data.placeOfSupply}`;
        }
      }
      if (data.customer?.length > 0) {
        url = url + `&party__in=${data.customer}`;
      }
      if (data.invoiceNumber && data.invoiceNumber !== "") {
        url = url + `&invoice_number=${data.invoiceNumber}`;
      }
      if (data.referenceNumber && data.referenceNumber !== "") {
        url = url + `&reference_number__icontains=${data.referenceNumber}`;
      }
      if (data.mobileNumber && data.mobileNumber !== "") {
        url = url + `&party__mobile=${data.mobileNumber}`;
      }
      if (data.notes && data.notes !== "") {
        url = url + `&notes=${data.notes}`;
      }
      if (data.remarkInInvoice && data.remarkInInvoice !== "") {
        url = url + `&remarks_in_invoice=${data.remarkInInvoice}`;
      }
      if (data.termsOfDelivery && data.termsOfDelivery !== "") {
        url = url + `&terms_of_delivery=${data.termsOfDelivery}`;
      }
      if (data.lpoNumber && data.lpoNumber !== "") {
        url = url + `&lpo_number=${data.lpoNumber}`;
      }
      if (data.dispatchDocumentNumber && data.dispatchDocumentNumber !== "") {
        url = url + `&dispatch_document_number=${data.dispatchDocumentNumber}`;
      }
      if (data.dispatchedThrough && data.dispatchedThrough !== "") {
        url = url + `&dispatched_through=${data.dispatchedThrough}`;
      }
      if (data.destination && data.destination !== "") {
        url = url + `&destination=${data.destination}`;
      }
      if (data.buyerReference && data.buyerReference !== "") {
        url = url + `&buyer_reference=${data.buyerReference}`;
      }
      if (data.doNumber && data.doNumber !== "") {
        url = url + `&d_o_number__in=${data.doNumber}`;
      }
      if (data.saleEstimateNumber && data.saleEstimateNumber !== "") {
        url = url + `&sale_estimate_number__in=${data.saleEstimateNumber}`;
      }
      if (data.grossAmount && data.grossAmount !== "") {
        url = url + `&sub_total=${data.grossAmount}`;
      }
      if (data.taxAmount && data.taxAmount !== "") {
        url = url + `&vat=${data.taxAmount}`;
      }
      if (data.totalAmount && data.totalAmount !== "") {
        url = url + `&total=${data.totalAmount}`;
      }
      if (data.modeOfPayment && data.modeOfPayment !== "") {
        if (data.modeOfPayment === "notAvailableId") {
          url = url + `&not_available_field=mode_or_terms_of_payment`;
        } else {
          url = url + `&mode_or_terms_of_payment=${data.modeOfPayment}`;
        }
      }
      if (data.voucherType?.length > 0) {
        url += `&voucher_group__in=${data.voucherType}`;
      }
      if (data.store && data.store !== "") {
        url += `&store=${data.store}`;
      }
      if (data.tax && data.tax !== "") {
        url += `&tax=${data.tax}`;
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_balance: response.data.data.total_balance,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const fetchSalesLists =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url = API_SALES_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{withItem}", data.withItem);
      if (data.isPOSEntry) {
        url = url + `&is_pos_entry=${data.isPOSEntry}`;
      }
      if (data.is_primary === false) {
        url = url + `&is_primary=${data.is_primary}`;
      }
      if (data.sortList && data.sortList !== "") {
        url = url + `&sorting=${data.sortList}`;
      }
      if (data.costCenter && data.costCenter !== "") {
        if (data.costCenter === "notAvailableId") {
          url = url + `&not_available_field=cost_centre`;
        } else {
          url = url + `&cost_centre=${data.costCenter}`;
        }
      }
      if (data.doneBy && data.doneBy !== "") {
        if (data.doneBy === "notAvailableId") {
          url = url + `&not_available_field=done_by`;
        } else {
          url = url + `&done_by=${data.doneBy}`;
        }
      }
      if (data.taxType && data.taxType !== "") {
        if (data.taxType === "notAvailableId") {
          url = url + `&not_available_field=tax_type`;
        } else {
          url = url + `&tax_type=${data.taxType}`;
        }
      }
      if (data.placeOfSupply && data.placeOfSupply !== "") {
        if (data.placeOfSupply === "notAvailableId") {
          url = url + `&not_available_field=place_of_supply`;
        } else {
          url = url + `&place_of_supply=${data.placeOfSupply}`;
        }
      }
      if (data.customer && data.customer !== "") {
        url = url + `&party=${data.customer}`;
      }
      if (data.invoiceNumber && data.invoiceNumber !== "") {
        url = url + `&invoice_number=${data.invoiceNumber}`;
      }
      if (data.referenceNumber && data.referenceNumber !== "") {
        url = url + `&reference_number__icontains=${data.referenceNumber}`;
      }
      if (data.mobileNumber && data.mobileNumber !== "") {
        url = url + `&party__mobile=${data.mobileNumber}`;
      }
      if (data.notes && data.notes !== "") {
        url = url + `&notes=${data.notes}`;
      }
      if (data.remarkInInvoice && data.remarkInInvoice !== "") {
        url = url + `&remarks_in_invoice=${data.remarkInInvoice}`;
      }
      if (data.termsOfDelivery && data.termsOfDelivery !== "") {
        url = url + `&terms_of_delivery=${data.termsOfDelivery}`;
      }
      if (data.lpoNumber && data.lpoNumber !== "") {
        url = url + `&lpo_number=${data.lpoNumber}`;
      }
      if (data.dispatchDocumentNumber && data.dispatchDocumentNumber !== "") {
        url = url + `&dispatch_document_number=${data.dispatchDocumentNumber}`;
      }
      if (data.dispatchedThrough && data.dispatchedThrough !== "") {
        url = url + `&dispatched_through=${data.dispatchedThrough}`;
      }
      if (data.destination && data.destination !== "") {
        url = url + `&destination=${data.destination}`;
      }
      if (data.buyerReference && data.buyerReference !== "") {
        url = url + `&buyer_reference=${data.buyerReference}`;
      }
      if (data.doNumber && data.doNumber !== "") {
        url = url + `&d_o_number__in=${data.doNumber}`;
      }
      if (data.saleEstimateNumber && data.saleEstimateNumber !== "") {
        url = url + `&sale_estimate_number__in=${data.saleEstimateNumber}`;
      }
      if (data.modeOfPayment && data.modeOfPayment !== "") {
        if (data.modeOfPayment === "notAvailableId") {
          url = url + `&not_available_field=mode_or_terms_of_payment`;
        } else {
          url = url + `&mode_or_terms_of_payment=${data.modeOfPayment}`;
        }
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_balance: response.data.data.total_balance,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const exportSingleSalesReport = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    const response = await api.get(
      API_SALES_REPORT_EXPORT.replace("{id}", data.id).replace(
        "{fileType}",
        data.fileType
      )
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const exportSalesReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_SALES_REPORTS_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const fetchSalesReportsForParty =
  (data, callback) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      const response = await api.get(
        API_SALES_REPORT_FOR_CUSTOMER_MINIMAL_LIST.replace("{page}", data.page)
          .replace("{pageSize}", data.pageSize)
          .replace("{startDate}", data.startDate)
          .replace("{endDate}", data.endDate)
          .replace("{party}", data.party)
          .replace("{status}", data.status)
          .replace("{withItem}", data.withItem)
      );
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
    }
  };

export const deleteSaleEstimateReport =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_SALE_ESTIMATE_DELETE.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      callback && callback(error);
    }
  };

export const fetchSaleEstimateReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url = API_SALE_ESTIMATE_REPORT_LIST_MINIMAL.replace(
        "{page}",
        data.page
      )
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .concat(data.dueStartDate ? `&due_date__gte=${data.dueStartDate}` : "")
        .concat(data.dueEndDate ? `&due_date__lte=${data.dueEndDate}` : "")
        .concat(
          data.followupStartDate
            ? `&followup_date__gte=${data.followupStartDate}`
            : ""
        )
        .concat(
          data.followupEndDate
            ? `&followup_date__lte=${data.followupEndDate}`
            : ""
        );
      if (data.saleStartDate && data.saleStartDate !== "") {
        url = url + `&start_date=${data.saleStartDate}`;
      }
      if (data.saleEndDate && data.saleEndDate !== "") {
        url = url + `&end_date=${data.saleEndDate}`;
      }
      if (data.party?.length > 0) {
        url = url + `&party__in=${data.party}`;
      }
      if (data.sortList && data.sortList !== "") {
        url = url + `&sorting=${data.sortList}`;
      }
      if (data.costCenter?.length > 0) {
        url = url + `&cost_centre__in=${data.costCenter}`;
      }
      if (data.doneBy?.length > 0) {
        url = url + `&done_by__in=${data.doneBy}`;
      }
      if (data.taxType?.length > 0) {
        url = url + `&tax_typ__ine=${data.taxType}`;
      }
      if (data.placeOfSupply?.length > 0) {
        url = url + `&place_of_supply__in=${data.placeOfSupply}`;
      }
      if (data.customer?.length) {
        url = url + `&party__in=${data.customer}`;
      }
      if (data.modeOfPayment && data.modeOfPayment !== "") {
        url = url + `&mode_or_terms_of_payment=${data.modeOfPayment}`;
      }
      if (data.documentType && data.documentType !== "") {
        url = url + `&document_type=${data.documentType}`;
      }
      if (data.statusFilter?.length > 0) {
        url = url + `&estimate_status__in=${data.statusFilter}`;
      }
      if (data.invoiceNumber && data.invoiceNumber !== "") {
        url = url + `&invoice_number__icontains=${data.invoiceNumber}`;
      }
      if (data.saleInvoiceNo && data.saleInvoiceNo !== "") {
        url = url + `&sale_invoice_number=${data.saleInvoiceNo}`;
      }
      if (data.referenceNumber && data.referenceNumber !== "") {
        url = url + `&reference_number__icontains=${data.referenceNumber}`;
      }
      if (data.mobileNumber && data.mobileNumber !== "") {
        url = url + `&party__mobile__icontains=${data.mobileNumber}`;
      }
      if (data.notes && data.notes !== "") {
        url = url + `&notes=${data.notes}`;
      }
      if (data.remarkInInvoice && data.remarkInInvoice !== "") {
        url = url + `&remarks_in_invoice=${data.remarkInInvoice}`;
      }
      if (data.termsOfDelivery && data.termsOfDelivery !== "") {
        url = url + `&terms_of_delivery=${data.termsOfDelivery}`;
      }
      if (data.lpoNumber && data.lpoNumber !== "") {
        url = url + `&lpo_number=${data.lpoNumber}`;
      }
      if (data.dispatchedThrough && data.dispatchedThrough !== "") {
        url = url + `&dispatched_through=${data.dispatchedThrough}`;
      }
      if (data.destination && data.destination !== "") {
        url = url + `&destination=${data.destination}`;
      }
      if (data.buyerReference && data.buyerReference !== "") {
        url = url + `&buyer_reference=${data.buyerReference}`;
      }
      if (data.creditTerms && data.creditTerms !== "") {
        url = url + `&credit_terms=${data.creditTerms}`;
      }
      if (data.invoiceStatus && data.invoiceStatus !== "") {
        url = url + `&invoice_status=${data.invoiceStatus}`;
      }
      if (data.purchaseInvoiceStatus && data.purchaseInvoiceStatus !== "") {
        url = url + `&purchase_invoice_status=${data.purchaseInvoiceStatus}`;
      }
      if (data.grossAmount && data.grossAmount !== "") {
        url = url + `&sub_total=${data.grossAmount}`;
      }
      if (data.taxAmount && data.taxAmount !== "") {
        url = url + `&vat=${data.taxAmount}`;
      }
      if (data.totalAmount && data.totalAmount !== "") {
        url = url + `&total=${data.totalAmount}`;
      }
      if (data.voucherType?.length > 0) {
        url += `&voucher_group__in=${data.voucherType}`;
      }
      if (data.store && data.store !== "") {
        url += `&store=${data.store}`;
      }
      if (data.convertedToSale && data.convertedToSale !== "") {
        url = url + `&converted_to_sale=${data.convertedToSale}`;
      }
      if (data.convertedToPurchase && data.convertedToPurchase !== "") {
        url = url + `&converted_to_purchase=${data.convertedToPurchase}`;
      }
      if (data.convertedToDo && data.convertedToDo !== "") {
        url = url + `&converted_to_delivery_order=${data.convertedToDo}`;
      }
      if (data.convertedToSaleEstimate && data.convertedToSaleEstimate !== "") {
        url =
          url + `&converted_to_sale_estimate=${data.convertedToSaleEstimate}`;
      }
      if (data.tax && data.tax !== "") {
        url += `&tax=${data.tax}`;
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const fetchSaleEstimateLists =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url = API_SALE_ESTIMATE_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .concat(data.dueStartDate ? `&due_date__gte=${data.dueStartDate}` : "")
        .concat(data.dueEndDate ? `&due_date__lte=${data.dueEndDate}` : "")
        .concat(
          data.followupStartDate
            ? `&followup_date__gte=${data.followupStartDate}`
            : ""
        )
        .concat(
          data.followupEndDate
            ? `&followup_date__lte=${data.followupEndDate}`
            : ""
        );
      if (data.saleStartDate && data.saleStartDate !== "") {
        url = url + `&start_date=${data.saleStartDate}`;
      }
      if (data.saleEndDate && data.saleEndDate !== "") {
        url = url + `&end_date=${data.saleEndDate}`;
      }
      if (data.party && data.party != null) {
        url = url + `&party=${data.party}`;
      }
      if (data.sortList && data.sortList !== "") {
        url = url + `&sorting=${data.sortList}`;
      }
      if (data.costCenter && data.costCenter !== "") {
        url = url + `&cost_centre=${data.costCenter}`;
      }
      if (data.doneBy && data.doneBy !== "") {
        url = url + `&done_by=${data.doneBy}`;
      }
      if (data.taxType && data.taxType !== "") {
        url = url + `&tax_type=${data.taxType}`;
      }
      if (data.placeOfSupply && data.placeOfSupply !== "") {
        url = url + `&place_of_supply=${data.placeOfSupply}`;
      }
      if (data.customer && data.customer !== "") {
        url = url + `&party=${data.customer}`;
      }
      if (data.modeOfPayment && data.modeOfPayment !== "") {
        url = url + `&mode_or_terms_of_payment=${data.modeOfPayment}`;
      }
      if (data.documentType && data.documentType !== "") {
        url = url + `&document_type=${data.documentType}`;
      }
      if (data.statusFilter && data.statusFilter !== "") {
        url = url + `&estimate_status=${data.statusFilter}`;
      }
      if (data.invoiceNumber && data.invoiceNumber !== "") {
        url = url + `&invoice_number__icontains=${data.invoiceNumber}`;
      }
      if (data.saleInvoiceNo && data.saleInvoiceNo !== "") {
        url = url + `&sale_invoice_number=${data.saleInvoiceNo}`;
      }
      if (data.referenceNumber && data.referenceNumber !== "") {
        url = url + `&reference_number__icontains=${data.referenceNumber}`;
      }
      if (data.mobileNumber && data.mobileNumber !== "") {
        url = url + `&party__mobile__icontains=${data.mobileNumber}`;
      }
      if (data.notes && data.notes !== "") {
        url = url + `&notes=${data.notes}`;
      }
      if (data.remarkInInvoice && data.remarkInInvoice !== "") {
        url = url + `&remarks_in_invoice=${data.remarkInInvoice}`;
      }
      if (data.termsOfDelivery && data.termsOfDelivery !== "") {
        url = url + `&terms_of_delivery=${data.termsOfDelivery}`;
      }
      if (data.lpoNumber && data.lpoNumber !== "") {
        url = url + `&lpo_number=${data.lpoNumber}`;
      }
      if (data.dispatchedThrough && data.dispatchedThrough !== "") {
        url = url + `&dispatched_through=${data.dispatchedThrough}`;
      }
      if (data.destination && data.destination !== "") {
        url = url + `&destination=${data.destination}`;
      }
      if (data.buyerReference && data.buyerReference !== "") {
        url = url + `&buyer_reference=${data.buyerReference}`;
      }
      if (data.creditTerms && data.creditTerms !== "") {
        url = url + `&credit_terms=${data.creditTerms}`;
      }
      if (data.invoiceStatus && data.invoiceStatus !== "") {
        url = url + `&invoice_status=${data.invoiceStatus}`;
      }
      if (data.convertedToSale && data.convertedToSale !== "") {
        if (data.convertedToSale === "convertedToSale") {
          url = url + `&converted_to_sale=true`;
        } else if (data.convertedToSale === "notConvertedToSale") {
          url = url + `&converted_to_sale=false`;
        }
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };
export const exportSingleSaleEstimateReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    //api call
    const response = await api.get(
      API_SALE_ESTIMATE_REPORT_EXPORT.replace("{id}", data.id).replace(
        "{fileType}",
        data.fileType
      )
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};
export const exportSaleEstimateReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    let url = API_SALE_ESTIMATE_REPORTS_EXPORT.replace(
      "{startDate}",
      data.startDate
    )
      .replace("{endDate}", data.endDate)
      .replace("{status}", data.status)
      .replace("{fileType}", data.fileType);

    if (data.saleStartDate && data.saleStartDate !== "") {
      url = url + `&start_date=${data.saleStartDate}`;
    }
    if (data.saleEndDate && data.saleEndDate !== "") {
      url = url + `&end_date=${data.saleEndDate}`;
    }
    if (data.party && data.party != null) {
      url = url + `&party=${data.party}`;
    }
    if (data.sortList && data.sortList !== "") {
      url = url + `&sorting=${data.sortList}`;
    }
    if (data.costCenter && data.costCenter !== "") {
      url = url + `&cost_centre=${data.costCenter}`;
    }
    if (data.doneBy && data.doneBy !== "") {
      url = url + `&done_by=${data.doneBy}`;
    }
    if (data.taxType && data.taxType !== "") {
      url = url + `&tax_type=${data.taxType}`;
    }
    if (data.placeOfSupply && data.placeOfSupply !== "") {
      url = url + `&place_of_supply=${data.placeOfSupply}`;
    }
    if (data.customer && data.customer !== "") {
      url = url + `&party=${data.customer}`;
    }
    if (data.modeOfPayment && data.modeOfPayment !== "") {
      url = url + `&mode_or_terms_of_payment=${data.modeOfPayment}`;
    }
    if (data.documentType && data.documentType !== "") {
      url = url + `&document_type=${data.documentType}`;
    }
    if (data.statusFilter && data.statusFilter !== "") {
      url = url + `&estimate_status=${data.statusFilter}`;
    }
    if (data.invoiceNumber && data.invoiceNumber !== "") {
      url = url + `&invoice_number__icontains=${data.invoiceNumber}`;
    }
    if (data.saleInvoiceNo && data.saleInvoiceNo !== "") {
      url = url + `&sale_invoice_number=${data.saleInvoiceNo}`;
    }
    if (data.referenceNumber && data.referenceNumber !== "") {
      url = url + `&reference_number__icontains=${data.referenceNumber}`;
    }
    if (data.mobileNumber && data.mobileNumber !== "") {
      url = url + `&party__mobile__icontains=${data.mobileNumber}`;
    }
    if (data.notes && data.notes !== "") {
      url = url + `&notes=${data.notes}`;
    }
    if (data.remarkInInvoice && data.remarkInInvoice !== "") {
      url = url + `&remarks_in_invoice=${data.remarkInInvoice}`;
    }
    if (data.termsOfDelivery && data.termsOfDelivery !== "") {
      url = url + `&terms_of_delivery=${data.termsOfDelivery}`;
    }
    if (data.lpoNumber && data.lpoNumber !== "") {
      url = url + `&lpo_number=${data.lpoNumber}`;
    }
    if (data.dispatchedThrough && data.dispatchedThrough !== "") {
      url = url + `&dispatched_through=${data.dispatchedThrough}`;
    }
    if (data.destination && data.destination !== "") {
      url = url + `&destination=${data.destination}`;
    }
    if (data.buyerReference && data.buyerReference !== "") {
      url = url + `&buyer_reference=${data.buyerReference}`;
    }
    if (data.creditTerms && data.creditTerms !== "") {
      url = url + `&credit_terms=${data.creditTerms}`;
    }
    if (data.invoiceStatus && data.invoiceStatus !== "") {
      url = url + `&invoice_status=${data.invoiceStatus}`;
    }
    if (data.convertedToSale && data.convertedToSale !== "") {
      if (data.convertedToSale === "convertedToSale") {
        url = url + `&converted_to_sale=true`;
      } else if (data.convertedToSale === "notConvertedToSale") {
        url = url + `&converted_to_sale=false`;
      }
    }

    const response = await api.get(url);
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const deleteSales =
  ({ data, filters }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_SALES_REPORT_DELETE.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        if (filters) {
          dispatch(
            fetchSalesReports({
              data: {
                page: filters.currentPage,
                pageSize: 10,
                startDate: filters.startDate,
                endDate: filters.endDate,
                status: filters.status,
                is_primary: false,
              },
            })
          );
        }
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const fetchSalesReportsByParty =
  (data, callback) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      const response = await api.get(
        API_SALES_REPORT_LIST_BY_PARTY_MINIMAL.replace(
          "{party}",
          data.party
        ).replace("{status}", data.status)
      );
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
    }
  };

export const fetchSalesReportsByInvoiceIds =
  (data, callback) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      const response = await api.get(
        API_SALES_REPORT_LIST_BY_IDS_MINIMAL.replace(
          "{ids}",
          data.invoiceIds.join()
        )
      );
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
    }
  };

export const fetchSalesReportsByInvoiceIdsNotMinimal =
  (data, callback) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      const response = await api.get(
        API_SALES_REPORT_LIST_BY_IDS.replace("{ids}", data.invoiceIds.join())
      );
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
    }
  };

export const clearSalesReports = (data, callback) => async (dispatch) => {
  dispatch(setReports({ ...initialState }));
};
