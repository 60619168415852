import { useMutation, useQueryClient } from "react-query";
import api, { API_ITEM_GROUP_GET_EDIT_DELETE } from "utils/axios";

const updateItemGroup = (ItemGroup) =>
  api
    .patch(
      API_ITEM_GROUP_GET_EDIT_DELETE.replace("{id}", ItemGroup.id),
      ItemGroup
    )
    .then((res) => res.data);

export function useUpdateItemGroup() {
  const queryClient = useQueryClient();
  return useMutation(updateItemGroup, {
    onSuccess: () => queryClient.refetchQueries("itemgroup"),
  });
}
