import { HStack, Text, Box, Checkbox, Switch } from "@chakra-ui/react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
const style = {
  border: "1px",
  boxShadow: "4px 4px 10px -3px #e7dfe7",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "move",
};
export const FieldCard = ({
  id,
  text,
  index,
  isInBaseTab,
  isHide,
  moveCard,
  onFieldTransfer,
  onShowHideButtonPress,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index, isInBaseTab, isHide };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      <HStack pl={1} pr={6}>
        <Checkbox
          mr={3}
          isChecked={!isHide}
          onChange={(e) => {
            onShowHideButtonPress(id, !e.target.checked);
          }}
        />
        <Box w="130px">
          <Text>{text}</Text>
        </Box>
        {/* <Spacer /> */}
        <Box flex="1" display="flex" justifyContent="space-between">
          <Switch
            // colorScheme="red"
            disabled={isHide}
            isChecked={isInBaseTab}
            onChange={(e) => {
              onFieldTransfer(id, e.target.checked);
            }}
          />
          <Switch
            // colorScheme="teal"
            disabled={isHide}
            isChecked={!isInBaseTab}
            onChange={(e) => {
              onFieldTransfer(id, !e.target.checked);
            }}
          />
        </Box>
      </HStack>
    </div>
  );
};
