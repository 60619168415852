import { useMutation, useQueryClient } from "react-query";
import api, { API_LEDGER_GROUPS_EDIT_DELETE } from "utils/axios";

const updateLedgerGroups = (costCenter) =>
  api
    .patch(
      API_LEDGER_GROUPS_EDIT_DELETE.replace("{id}", costCenter.id),
      costCenter
    )
    .then((res) => res.data);

export function useUpdateLedgerGroups() {
  const queryClient = useQueryClient();
  return useMutation(updateLedgerGroups, {
    onSuccess: () => queryClient.refetchQueries("ledgergroups"),
  });
}
