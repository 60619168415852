import { createSlice } from "@reduxjs/toolkit";

import api, {
  API_DELIVERY_ORDER_REPORT_LIST,
  API_DELIVERY_ORDER_REPORT_DELETE,
  API_DELIVERY_ORDER_REPORT_EXPORT,
  API_DELIVERY_ORDER_REPORTS_EXPORT,
  API_DELIVERY_ORDER_REPORT_LIST_MINIMAL,
} from "../utils/axios";

const initialState = {
  loading: false,
  next: "",
  previous: "",
  count: 0,
  page: 0,
  total_pages: 0,
  totalGross: 0,
  totalNet: 0,
  totalTax: 0,
  reports: [],
  reportDetails: {},
};

const deliveryorderreport = createSlice({
  name: "deliveryorderreport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReports: (state, action) => {
      state.reports = action.payload.reports;
      state.count = action.payload.count;
      state.total_pages = action.payload.total_pages;
      state.totalGross = action.payload.total_gross;
      state.totalNet = action.payload.total_net;
      state.totalTax = action.payload.total_tax;
    },
    setReportDetails: (state, action) => {
      state.reportDetails = action.payload.reportDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setReports, setReportDetails, setErrorMessage } =
  deliveryorderreport.actions;

export default deliveryorderreport.reducer;

export const fetchDeliveryOrderReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      let url = API_DELIVERY_ORDER_REPORT_LIST_MINIMAL.replace(
        "{page}",
        data.page
      )
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate);

      if (data.saleStartDate && data.saleStartDate !== "") {
        url = url + `&start_date=${data.saleStartDate}`;
      }
      if (data.saleEndDate && data.saleEndDate !== "") {
        url = url + `&end_date=${data.saleEndDate}`;
      }
      if (data.party && data.party != null) {
        url = url + `&party=${data.party}`;
      }
      if (data.sortList && data.sortList !== "") {
        url = url + `&sorting=${data.sortList}`;
      }
      if (data.costCenter?.length > 0) {
        url = url + `&cost_centre__in=${data.costCenter}`;
      }
      if (data.doneBy?.length > 0) {
        url = url + `&done_by__in=${data.doneBy}`;
      }
      if (data.taxType?.length > 0) {
        url = url + `&tax_type__in=${data.taxType}`;
      }
      if (data.placeOfSupply?.length > 0) {
        url = url + `&place_of_supply__in=${data.placeOfSupply}`;
      }
      if (data.customer?.length > 0) {
        url = url + `&party__in=${data.customer}`;
      }
      if (data.modeOfPayment && data.modeOfPayment !== "") {
        url = url + `&mode_or_terms_of_payment=${data.modeOfPayment}`;
      }
      if (data.DONumber && data.DONumber !== "") {
        url = url + `&delivery_order_number__icontains=${data.DONumber}`;
      }
      if (data.saleInvoiceNo && data.saleInvoiceNo !== "") {
        url = url + `&sale_invoice_number=${data.saleInvoiceNo}`;
      }
      if (data.referenceNumber && data.referenceNumber !== "") {
        url = url + `&buyer_reference__icontains=${data.referenceNumber}`;
      }
      if (data.mobileNumber && data.mobileNumber !== "") {
        url = url + `&party__mobile__icontains=${data.mobileNumber}`;
      }
      if (data.notes && data.notes !== "") {
        url = url + `&notes=${data.notes}`;
      }
      if (data.remarkInInvoice && data.remarkInInvoice !== "") {
        url = url + `&remarks_in_invoice=${data.remarkInInvoice}`;
      }
      if (data.termsOfDelivery && data.termsOfDelivery !== "") {
        url = url + `&terms_of_delivery=${data.termsOfDelivery}`;
      }
      if (data.dispatchDocumentNumber && data.dispatchDocumentNumber !== "") {
        url = url + `&dispatch_document_number=${data.dispatchDocumentNumber}`;
      }
      if (data.dispatchedThrough && data.dispatchedThrough !== "") {
        url = url + `&dispatched_through=${data.dispatchedThrough}`;
      }
      if (data.destination && data.destination !== "") {
        url = url + `&destination=${data.destination}`;
      }
      if (data.creditTerms && data.creditTerms !== "") {
        url = url + `&credit_terms=${data.creditTerms}`;
      }
      if (data.invoiceStatus && data.invoiceStatus !== "") {
        url = url + `&invoice_status=${data.invoiceStatus}`;
      }
      if (data.purchaseInvoiceStatus && data.purchaseInvoiceStatus !== "") {
        url = url + `&purchase_invoice_status=${data.purchaseInvoiceStatus}`;
      }
      if (data.grossAmount && data.grossAmount !== "") {
        url = url + `&sub_total=${data.grossAmount}`;
      }
      if (data.taxAmount && data.taxAmount !== "") {
        url = url + `&vat=${data.taxAmount}`;
      }
      if (data.totalAmount && data.totalAmount !== "") {
        url = url + `&total=${data.totalAmount}`;
      }
      if (data.voucherType?.length > 0) {
        url += `&voucher_group__in=${data.voucherType}`;
      }
      if (data.tax && data.tax !== "") {
        url += `&tax=${data.tax}`;
      }
      if (data.convertedToSale && data.convertedToSale !== "") {
        url = url + `&converted_to_sale=${data.convertedToSale}`;
      }
      if (data.convertedToPurchase && data.convertedToPurchase !== "") {
        url = url + `&converted_to_purchase=${data.convertedToPurchase}`;
      }
      if (data.convertedToDo && data.convertedToDo !== "") {
        url = url + `&converted_to_delivery_order=${data.convertedToDo}`;
      }
      if (data.convertedToSaleEstimate && data.convertedToSaleEstimate !== "") {
        url =
          url + `&converted_to_sale_estimate=${data.convertedToSaleEstimate}`;
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.total.gross,
            total_net: response.data.total.total,
            total_tax: response.data.total.tax,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const fetchDeliveryOrderLists =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      let url = API_DELIVERY_ORDER_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate);

      if (data.saleStartDate && data.saleStartDate !== "") {
        url = url + `&start_date=${data.saleStartDate}`;
      }
      if (data.saleEndDate && data.saleEndDate !== "") {
        url = url + `&end_date=${data.saleEndDate}`;
      }
      if (data.party && data.party != null) {
        url = url + `&party=${data.party}`;
      }
      if (data.sortList && data.sortList !== "") {
        url = url + `&sorting=${data.sortList}`;
      }
      if (data.costCenter && data.costCenter !== "") {
        url = url + `&cost_centre=${data.costCenter}`;
      }
      if (data.doneBy && data.doneBy !== "") {
        url = url + `&done_by=${data.doneBy}`;
      }
      if (data.taxType && data.taxType !== "") {
        url = url + `&tax_type=${data.taxType}`;
      }
      if (data.placeOfSupply && data.placeOfSupply !== "") {
        url = url + `&place_of_supply=${data.placeOfSupply}`;
      }
      if (data.customer && data.customer !== "") {
        url = url + `&party=${data.customer}`;
      }
      if (data.modeOfPayment && data.modeOfPayment !== "") {
        url = url + `&mode_or_terms_of_payment=${data.modeOfPayment}`;
      }
      if (data.DONumber && data.DONumber !== "") {
        url = url + `&delivery_order_number__icontains=${data.DONumber}`;
      }
      if (data.saleInvoiceNo && data.saleInvoiceNo !== "") {
        url = url + `&sale_invoice_number=${data.saleInvoiceNo}`;
      }
      if (data.referenceNumber && data.referenceNumber !== "") {
        url = url + `&buyer_reference__icontains=${data.referenceNumber}`;
      }
      if (data.mobileNumber && data.mobileNumber !== "") {
        url = url + `&party__mobile__icontains=${data.mobileNumber}`;
      }
      if (data.notes && data.notes !== "") {
        url = url + `&notes=${data.notes}`;
      }
      if (data.remarkInInvoice && data.remarkInInvoice !== "") {
        url = url + `&remarks_in_invoice=${data.remarkInInvoice}`;
      }
      if (data.termsOfDelivery && data.termsOfDelivery !== "") {
        url = url + `&terms_of_delivery=${data.termsOfDelivery}`;
      }
      if (data.dispatchDocumentNumber && data.dispatchDocumentNumber !== "") {
        url = url + `&dispatch_document_number=${data.dispatchDocumentNumber}`;
      }
      if (data.dispatchedThrough && data.dispatchedThrough !== "") {
        url = url + `&dispatched_through=${data.dispatchedThrough}`;
      }
      if (data.destination && data.destination !== "") {
        url = url + `&destination=${data.destination}`;
      }
      if (data.creditTerms && data.creditTerms !== "") {
        url = url + `&credit_terms=${data.creditTerms}`;
      }
      if (data.invoiceStatus && data.invoiceStatus !== "") {
        url = url + `&invoice_status=${data.invoiceStatus}`;
      }
      if (data.convertedToSale && data.convertedToSale !== "") {
        if (data.convertedToSale === "convertedToSale") {
          url = url + `&converted_to_sale=true`;
        } else if (data.convertedToSale === "notConvertedToSale") {
          url = url + `&converted_to_sale=false`;
        }
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const exportDeliveryOrderReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_DELIVERY_ORDER_REPORTS_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};
export const exportSingleDeliveryOrderReport = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    const response = await api.get(
      API_DELIVERY_ORDER_REPORT_EXPORT.replace("{id}", data.id).replace(
        "{fileType}",
        data.fileType
      )
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const deleteDeliveryOrder =
  ({ data, filters, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_DELIVERY_ORDER_REPORT_DELETE.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(
          fetchDeliveryOrderReports({
            data: {
              page: filters.currentPage,
              pageSize: 10,
              startDate: filters.startDate,
              endDate: filters.endDate,
              status: filters.status,
            },
          })
        );
        callback && callback(response.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };
