import { useMutation, useQueryClient } from "react-query";
import api, { API_ITEMS_EDIT } from "utils/axios";

const deleteItem = async (item) =>
  api.delete(API_ITEMS_EDIT.replace("{id}", item.id)).then((res) => res.data);

export function useDeleteItem() {
  const queryClient = useQueryClient();
  return useMutation(deleteItem, {
    onSuccess: (response, deletedData) => {
      if (response.status === "success") {
        queryClient.refetchQueries("items");
        queryClient.setQueriesData(["itemsNonPaginated"], (oldData) => {
          if (oldData) {
            return {
              ...oldData,
              data: oldData.data.filter((item) => item.id !== deletedData?.id),
            };
          }
        });
      }
    },
  });
}
