import { useQuery } from "react-query";
import api, { API_TRANSACTION_SETTINGS } from "utils/axios";

const getTransactionSettings = () =>
  api.get(API_TRANSACTION_SETTINGS).then((res) => res.data);

export function useTransactionSettings() {
  return useQuery("transactionSettings", getTransactionSettings, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
