import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getLocalStore } from "utils/utils";

const MasterRoute = ({ component: Component, ...rest }) => {
  const auth = getLocalStore("AUTH_DETAILS");
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          <React.Fragment>
            <Flex bg="brand.bg_light_blue" minHeight="100vh">
              <Box flex="1">
                <Flex flexDirection="column">
                  <Flex h="100vh" overflow="auto">
                    <Component {...props} />
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          </React.Fragment>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

export default MasterRoute;
