import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import { useToast } from "@chakra-ui/react";
import Form from "../Form";
import RenderComponent from "./RenderComponent";
import { useCreatePriceCategoryName } from "hooks/PriceCategoryName/useCreatePriceCategoryName";
import { useUpdatePriceCategoryName } from "hooks/PriceCategoryName/useUpdatePriceCategoryName";

const AddPriceCategoryName = (props) => {
  const { type, editValue, clearSearch, onClose, onAddSuccess, searchData } =
    props;
  const toast = useToast();
  const {
    mutate: createPriceCategoryName,
    data,
    isSuccess,
    isLoading: isCreateLoading,
  } = useCreatePriceCategoryName();

  const {
    mutate: updatePriceCategoryName,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useUpdatePriceCategoryName();

  const initialValue = {
    name: searchData,
  };
  const [formValues, setformValues] = useState(initialValue);
  const [clearValues, setClearValues] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      if (data?.status === "success") {
        toastWithMessage("Price category name changed successfully");
        onAddSuccess(data?.data);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Price catgeory name updated successfully");
        onAddSuccess(updateResponse?.data);
      }
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (type === "add") {
      setformValues(initialValue);
    } else {
      setformValues(editValue);
    }
  }, [type, editValue]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Form
      initialValues={{ ...formValues }}
      RenderComponent={RenderComponent}
      handleOnSubmit={async (fields) => {
        if (type === "edit") {
          updatePriceCategoryName(fields);
        } else {
          createPriceCategoryName(fields);
          setClearValues(true);
        }
        clearSearch && clearSearch();
        onClose && onClose();
      }}
      customProps={{
        type: type,
        handleResetToComponent: clearValues,
        isLoading: isCreateLoading || isUpdateLoading,
      }}
      validationSchema={object().shape({
        name: string().required("Name Required").nullable(),
      })}
    />
  );
};

export default AddPriceCategoryName;
