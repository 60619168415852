import { createSlice } from '@reduxjs/toolkit'

import api, { SALE_WITH_ITEM, PURCHASE_RETURN_WITH_ITEM, SALE_ESTIMATE_WITH_ITEM, PURCHASE_WITHOUT_ITEM, SALE_RETURN_WITH_ITEM, API_DELIVERY_ORDER } from '../utils/axios'
import { useDispatch, useSelector } from 'react-redux'

import { showLoading, hideLoading } from './common.slice';
const initialState = {
  data: {},
  deliveryOrderData: {},
}

const salewithitem = createSlice({
  name: 'salewithitem',
  initialState,
  reducers: {
    // setLoading: (state, action) => {
    //   state.loading = action.payload.loading
    // },
    setData: (state, action) => {
      state.data = action.payload.data
    },
    setDeliveryOrderData: (state, action) => {
      state.deliveryOrderData = action.payload.data
    },
    // setErrorMessage: (state, action) => {
    //   state.error = action.payload.message
    // }
  }
})

export const {
  setData,
  setDeliveryOrderData
} = salewithitem.actions
export default salewithitem.reducer

export const getSaleWithItem = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    const response = await api.get(`${SALE_WITH_ITEM}${id}`)
    if (response?.data?.status === "success") {
      dispatch(setData({ data: response.data.data }))
    }
    dispatch(hideLoading())
    return response
  } catch (error) {
    dispatch(hideLoading())
  }
}

export const addSaleWithItem = ({ data }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    const response = await api.post(SALE_WITH_ITEM, data)
    if (response?.data?.status === "success") {
      // dispatch(setData({ data: response.data.data }))
    }
    dispatch(hideLoading())
    return response
  } catch (error) {
    dispatch(hideLoading())
  }
}

export const editSaleWithItem = ({ data, id }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    const response = await api.patch(`${SALE_WITH_ITEM}${id}`, data)
    if (response?.data?.status === "success") {
      // dispatch(setData({ data: response.data.data }))
    }
    dispatch(hideLoading())
    return response
  } catch (error) {
    dispatch(hideLoading())
  }
}

export const addDeliveryOrder = ({ data }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    const response = await api.post(API_DELIVERY_ORDER, data)
    if (response?.data?.status === "success") {
      // dispatch(setData({ data: response.data.data }))
    }
    dispatch(hideLoading())
    return response
  } catch (error) {
    dispatch(hideLoading())
  }
}

export const editDeliveryOrder = ({ data, id }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    const response = await api.patch(`${API_DELIVERY_ORDER}${id}`, data)
    if (response?.data?.status === "success") {
      // dispatch(setData({ data: response.data.data }))
    }
    dispatch(hideLoading())
    return response
  } catch (error) {
    dispatch(hideLoading())
  }

}

export const getDeliveryOrder = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    const response = await api.get(`${API_DELIVERY_ORDER}${id}`)
    if (response?.data?.status === "success") {
      dispatch(setDeliveryOrderData({ data: response.data.data }))
    }
    dispatch(hideLoading())
    return response
  } catch (error) {
    dispatch(hideLoading())
  }
}