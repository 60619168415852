import React, { useEffect, useState, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Box,
  Stack,
  FormControl,
  Button,
  Heading,
  Tooltip,
  Checkbox,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import useKeypress from "react-use-keypress";
import { isMobile } from "react-device-detect";

import Message from "../Message";
import { saveAndPrintShortKey } from "utils/utils/saveAndPrintShortkey";
import MultiSelectionField from "components/MultiSelectionField";
import {
  ITab,
  IButton,
  IHeadFav,
  IHead,
  ITabPanelIn,
  IModalOut,
  IModalIn,
  IHStack,
  IButtonOut,
  ITabPanel,
  IFieldAndLabel,
  ITextField,
} from "components/IModal";
const RenderComponent = ({
  onClear,
  handleEdit,
  values,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid,
  isSubmitting,
  touched,
  handleReset,
  cancelAction,
}) => {
  const {
    type,
    isLoading,
    isSubmitted,
    setSuspand,
    suspand,
    isApproval,
    rejectFunc,
    isRejectLoading,
    costCenter,
    setCostCenter,
    costCenterData,
  } = customProps;
  const [clickKey, setClickKey] = useState({
    keyA: false,
    // keyP: false,
  });
  const [fieldInFocus, setFieldInFocus] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);

  const nameRef = useRef();
  const costCenterRef = useRef();
  useEffect(() => {
    if (!isMobile) {
      if (tabIndex === 0) {
        if (fieldInFocus === 1) nameRef.current?.focus();
      } else {
        if (fieldInFocus === 1) costCenterRef.current?.focus();
      }
    }
  }, [fieldInFocus]);
  useKeypress(["Enter", "Tab"], (event) => {
    if (event.key === "Enter" && fieldInFocus <= 2) {
      let next = 1;
      if (fieldInFocus === 1 && tabIndex === 0) {
        onSubmit();
      } else if (fieldInFocus === 1 && tabIndex === 1) {
        onSubmit();
      }
      setFieldInFocus(fieldInFocus + next);
    }
    event.stopPropagation();
    event.preventDefault();
  });

  const handleClick = (fieldId) => {
    if (fieldId <= 2) {
      setFieldInFocus(fieldId);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (tabIndex === 0) {
        nameRef.current?.focus();
      } else if (tabIndex === 1) {
        costCenterRef.current?.focus();
      }
    }, 200);
  }, [tabIndex]);
  const [buttonClick, setButtonClick] = useState(false);
  const onSubmit = () => {
    setButtonClick(!buttonClick);
    const validateResponse = validateForm();
    validateResponse.then((data) => {
      submitAction(data);
    });
  };
  useKeypress(["f1", "F1", "f2", "F2"], (event) => {
    event.preventDefault();
    if (["f1", "F1"].includes(event.key)) {
      setTabIndex(0);
    }
    if (["f2", "F2"].includes(event.key)) {
      setTabIndex(1);
    }
    if (!["f1", "F1", "f2", "F2"].includes(event.key)) {
      event.stopPropagation();
      event.preventDefault();
    }
  });
  useEffect(() => {
    if (clickKey?.keyA && !isLoading && !isSubmitted) {
      onSubmit();
    }
  }, [clickKey]);
  saveAndPrintShortKey(setClickKey);

  return (
    <IModalOut>
      <IHead
        text={
          isApproval
            ? "Approve Doneby - " + values.name
            : type === "edit"
            ? "Edit Doneby -" + values.name
            : "Add Doneby"
        }
      />
      <Tabs
        isFitted
        onChange={(index) => setTabIndex(index)}
        index={tabIndex}
        colorScheme="blue"
      >
        <TabList mb={3}>
          <ITab tooltipLabel="f1">Basic Info </ITab>
          <ITab tooltipLabel="f2">Other Info</ITab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <IModalIn>
              <FormControl>
                <ITextField
                  disabled={isApproval}
                  fullWidth="100%"
                  label="Doneby Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="name"
                  onChange={(event) =>
                    handleEdit(event.target.value, { field: "name" })
                  }
                  inputRef={nameRef}
                  onFocus={(e) => {
                    if (e) {
                      handleClick(1);
                    }
                  }}
                  value={values.name || ""}
                  isValid={isNotValid}
                  isRequired={true}
                  errorMessage={errors.name}
                  buttonClick={buttonClick}
                />
              </FormControl>

              <Checkbox
                disabled={isApproval}
                isChecked={suspand}
                onChange={(e) => {
                  setSuspand(!suspand);
                }}
              >
                Required Suspend
              </Checkbox>
            </IModalIn>
          </TabPanel>
          <TabPanel p={0}>
            <IModalIn>
              <MultiSelectionField
                placeholder="Cost Centers"
                datas={costCenterData?.data}
                options={costCenter}
                setOptions={setCostCenter}
                inputRef={costCenterRef}
                onFocus={(e) => {
                  if (e) {
                    handleClick(1);
                  }
                }}
              />
            </IModalIn>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <IButtonOut>
        {isApproval && (
          <Tooltip
            hasArrow
            label="Reject"
            placement="bottom"
            bg="brand.bg_blue"
          >
            <Button
              w="100%"
              isLoading={isRejectLoading}
              minH="40px"
              type="submit"
              size="lg"
              variant="solid"
              bg="red.600"
              colorScheme="red"
              onClick={() => {
                rejectFunc();
              }}
            >
              Reject
            </Button>
          </Tooltip>
        )}
        <IButton
          isLoading={isLoading}
          onClick={(e) => {
            if (e.detail === 1) {
              onSubmit();
            }
          }}
          isApproval={isApproval}
          type={type}
        />
      </IButtonOut>
    </IModalOut>
  );
};

export default RenderComponent;
