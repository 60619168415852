import { createSlice } from "@reduxjs/toolkit";

import api, {
  API_RECEIPT_REPORT_LIST,
  API_RECEIPT_REPORT_DELETE,
  API_RECEIPT_CREATE,
  API_RECEIPT_REPORTS_EXPORT,
  API_RECIEPT_GET_EDIT_DELETE,
  API_RECEIPT_REPORT_EXPORT,
} from "../utils/axios";
import { fetchCustomers } from "./customers.slice";
import { fetchItems } from "./groups.slice";
import { clearSalesReports } from "./salesreport.slice";
import { showLoading, hideLoading } from "./common.slice";

const initialState = {
  loading: false,
  next: "",
  previous: "",
  count: 0,
  page: 0,
  total_pages: 0,
  cash_total: 0,
  bank_total: 0,
  reports: [],
  reportDetails: {},
  recieptData: {},
};

const receiptreport = createSlice({
  name: "receiptreport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReports: (state, action) => {
      state.reports = action.payload.reports;
      state.count = action.payload.count;
      state.total_pages = action.payload.total_pages;
      state.bank_total = action.payload.bank_total;
      state.cash_total = action.payload.cash_total;
    },
    setReportDetails: (state, action) => {
      state.reportDetails = action.payload.reportDetails;
    },
    setRecieptData: (state, action) => {
      state.recieptData = action.payload.data;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const {
  setLoading,
  setReports,
  setReportDetails,
  setErrorMessage,
  setRecieptData,
} = receiptreport.actions;

export default receiptreport.reducer;

export const fetchReceiptReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url = API_RECEIPT_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{voucher_type}", data.voucher_type)
        .replace("{reference_type}", data.reference_type)
        .replace("{reference_invoice_type}", data.reference_invoice_type);

      if (data.sortList && data.sortList !== "") {
        url = url + `&sorting=${data.sortList}`;
      }
      if (data.ledgerFrom && data.ledgerFrom !== "") {
        url = url + `&ledger_from=${data.ledgerFrom}`;
      }
      if (data.ledgerTo && data.ledgerTo !== "") {
        url = url + `&ledger_to=${data.ledgerTo}`;
      }
      if (data.amount && data.amount !== "") {
        url = url + `&amount=${data.amount}`;
      }
      if (data.ref_invoice_no && data.ref_invoice_no !== "") {
        url = url + `&reference_invoice_no=${data.ref_invoice_no}`;
      }
      if (data.voucher_no && data.voucher_no !== "") {
        url = url + `&voucher_no__icontains=${data.voucher_no}`;
      }
      console.log("mobileNumber", data.mobileNumber);
      if (data.mobileNumber && data.mobileNumber !== "") {
        url = url + `&ledger_from=${data.mobileNumber}`;
      }
      if (data.costCenter && data.costCenter !== "") {
        if (data.costCenter === "notAvailableId") {
          url += `&not_available_field=cost_centre`;
        } else {
          url += `&cost_centre=${data.costCenter}`;
        }
      }
      if (data.doneBy && data.doneBy !== "") {
        if (data.doneBy === "notAvailableId") {
          url += `&not_available_field=done_by`;
        } else {
          url += `&done_by=${data.doneBy}`;
        }
      }
      if (data.voucherType?.length && data.voucherType !== "") {
        url += `&voucher_group__in=${data.voucherType}`;
      }
      if (data.contra && data.contra !== "") {
        url += `&contra=${data.contra}`;
      }

      const response = await api.get(url);

      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            // total_gross: response.data.data.total_gross,
            bank_total: response.data.data.bank_total,
            cash_total: response.data.data.cash_total,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const exportSingleReceiptReport = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    const response = await api.get(
      API_RECEIPT_REPORT_EXPORT.replace("{id}", data.id).replace(
        "{fileType}",
        data.fileType
      )
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const exportReceiptReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_RECEIPT_REPORTS_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{voucherCategory}", data.voucherCategory)
        .replace("{fileType}", data.fileType)
        .replace("{voucherType}", data.voucher_type)
        .replace("{referenceInvoiceType}", data.reference_invoice_type)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const deleteReceipt =
  ({ data, filters }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_RECEIPT_REPORT_DELETE.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        if (filters) {
          dispatch(
            fetchReceiptReports({
              data: {
                page: filters.currentPage,
                pageSize: 10,
                startDate: filters.startDate,
                endDate: filters.endDate,
                voucher_type: filters.voucher_type,
                reference_type: filters.reference_type,
                reference_invoice_type: filters.reference_invoice_type,
              },
            })
          );
        }
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const addOtherReceipt =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.post(API_RECEIPT_CREATE, data);
      if (response?.data?.status === "success") {
        // dispatch(setData({ data: response.data.data }))
      }
      dispatch(hideLoading());
      return response;
    } catch (error) {
      dispatch(hideLoading());
    }
  };

export const addReceiptAgainstInvoice =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.post(API_RECEIPT_CREATE, data);
      if (response?.data?.status === "success") {
        // dispatch(setData({ data: response.data.data }))
        dispatch(setLoading({ loading: false }));
        dispatch(fetchCustomers());
        dispatch(clearSalesReports());
        dispatch(fetchItems());
      }
      dispatch(hideLoading());
      return response;
    } catch (error) {
      dispatch(hideLoading());
    }
  };

export const getReciept = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await api.get(
      API_RECIEPT_GET_EDIT_DELETE.replace("{id}", id)
    );
    if (response?.data?.status === "success") {
      dispatch(setRecieptData({ data: response.data.data }));
    }
    dispatch(hideLoading());
    return response;
  } catch (error) {
    dispatch(hideLoading());
  }
};

export const editOtherReceipt =
  ({ data, id }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.patch(
        API_RECIEPT_GET_EDIT_DELETE.replace("{id}", id),
        data
      );
      if (response?.data?.status === "success") {
        // dispatch(setData({ data: response.data.data }))
      }
      dispatch(hideLoading());
      return response;
    } catch (error) {
      dispatch(hideLoading());
    }
  };

export const editReceiptAgainstInvoice =
  ({ data, id }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.patch(
        API_RECIEPT_GET_EDIT_DELETE.replace("{id}", id),
        data
      );
      if (response?.data?.status === "success") {
        // dispatch(setData({ data: response.data.data }))
        dispatch(setLoading({ loading: false }));
        dispatch(fetchCustomers());
        dispatch(clearSalesReports());
        dispatch(fetchItems());
      }
      dispatch(hideLoading());
      return response;
    } catch (error) {
      dispatch(hideLoading());
    }
  };

export const clearReceiptReports = (data, callback) => async (dispatch) => {
  dispatch(setReports({ ...initialState }));
};
