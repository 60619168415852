import {
  Text,
  Stack,
  Link,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  AvatarGroup,
  Avatar,
  Divider,
} from "@chakra-ui/react";
import { SettingsIcon } from "@chakra-ui/icons";
import { isMobile } from "react-device-detect";
import { ArrowDownIcon, ArrowUpIcon, BellIcon } from "@chakra-ui/icons";
import { getLocalStore } from "utils/utils";
import { useUserSetup } from "hooks/UserSetup/useUserSetup";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { FaSignOutAlt } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useLogout } from "hooks/Login/useLogout";

export default function UserProfilePopup(props) {
  const auth = getLocalStore("AUTH_DETAILS");
  const userID = auth?.data?.id;
  const { data: defaultSettingsData } = useUserSetup(userID);
  const [showMoreAllocation, setShowMoreAllocation] = useState(false);
  const [showMoreDate, setShowMoreDate] = useState(false);
  const [showMorePrice, setShowMorePrice] = useState(false);
  let history = useHistory();
  const companyName = getLocalStore("AUTH_DETAILS")?.data.company.name || "";
  const userName = companyName.charAt(0);

  const { mutate: logOut, isLoading, isError } = useLogout();

  return (
    <Menu>
      <MenuButton>
        {isMobile ? (
          <Box
            w="25px"
            h="25px"
            borderRadius="50%"
            bg="blue"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize="16px"
            color="white"
            margin="auto"
            textTransform="uppercase"
          >
            {userName.toUpperCase()}
          </Box>
        ) : (
          <MenuButton>
            <AvatarGroup cursor="pointer" spacing="1rem" size="sm">
              <Avatar bg="teal.500" />
            </AvatarGroup>
          </MenuButton>
        )}
      </MenuButton>
      <MenuList height="auto">
        <MenuOptionGroup>
          <Box
            // width={200}
            height="auto"
            bg="white"
            // ref={boxRef}
            // position="absolute"
            // top="11%"
            // right="5%"
            // zIndex={1}
            padding={4}
            borderRadius={4}
          >
            <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
              <Avatar w={4} h={4} bg="black" />
              <Text fontWeight="bold">
                {defaultSettingsData?.data?.full_name}
              </Text>
            </Box>
            <Divider mt={2} mb={2}></Divider>
            <Box display="flex" flexDirection="column" mt={2}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Text fontWeight="medium" fontStyle="italic">
                  Default Allocations
                </Text>
                <Stack>
                  <Link
                    sx={{
                      "&:hover": {
                        textDecoration: "none",
                      },
                    }}
                    onClick={() => setShowMoreAllocation(!showMoreAllocation)}
                  >
                    <Text
                      fontSize="xs"
                      color="brand.bg_blue"
                      sx={{
                        "&:hover": {
                          textColor: "blue.500",
                        },
                      }}
                    >
                      {showMoreAllocation ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </Text>
                  </Link>
                </Stack>
              </Box>
              {showMoreAllocation ? (
                <Box display="flex" flexDirection="column" gap={2}>
                  <Text>
                    default_bank_ledger:
                    {defaultSettingsData?.data?.default_bank_ledger}
                  </Text>
                  <Text>
                    default_cash_ledger:
                    {defaultSettingsData?.data?.default_cash_ledger}
                  </Text>
                  <Text>
                    default_cost_centre:
                    {defaultSettingsData?.data?.default_cost_centre}
                  </Text>
                  <Text>
                    default_done_by :
                    {defaultSettingsData?.data?.default_done_by}
                  </Text>
                  <Text>
                    default_store :{defaultSettingsData?.data?.default_store}
                  </Text>
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Box display="flex" flexDirection="column" mt={2}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Text fontWeight="medium" fontStyle="italic">
                  Date Restrictions
                </Text>
                <Stack>
                  <Link
                    sx={{
                      "&:hover": {
                        textDecoration: "none",
                      },
                    }}
                    onClick={() => setShowMoreDate(!showMoreDate)}
                  >
                    <Text
                      fontSize="xs"
                      color="brand.bg_blue"
                      sx={{
                        "&:hover": {
                          textColor: "blue.500",
                        },
                      }}
                    >
                      {showMoreDate ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </Text>
                  </Link>
                </Stack>
              </Box>
              {showMoreDate ? (
                <Box display="flex" flexDirection="column" gap={2}>
                  <Text>
                    maximum_allowed_days_for_backdated_entries :
                    {
                      defaultSettingsData?.data
                        ?.maximum_allowed_days_for_backdated_entries
                    }
                  </Text>
                  <Text>
                    maximum_allowed_days_for_forward_entries:
                    {
                      defaultSettingsData?.data
                        ?.maximum_allowed_days_for_forward_entries
                    }
                  </Text>
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Box display="flex" flexDirection="column" mt={2}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Text fontWeight="medium" fontStyle="italic">
                  Price Change Restrictions
                </Text>
                <Stack>
                  <Link
                    sx={{
                      "&:hover": {
                        textDecoration: "none",
                      },
                    }}
                    onClick={() => setShowMorePrice(!showMorePrice)}
                  >
                    <Text
                      fontSize="xs"
                      color="brand.bg_blue"
                      sx={{
                        "&:hover": {
                          textColor: "blue.500",
                        },
                      }}
                    >
                      {showMorePrice ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </Text>
                  </Link>
                </Stack>
              </Box>
              {showMorePrice ? (
                <Box display="flex" flexDirection="column" gap={2}>
                  <Text>
                    maximum_allowed_days_for_backdated_entries :
                    {
                      defaultSettingsData?.data
                        ?.maximum_allowed_days_for_backdated_entries
                    }
                  </Text>
                  <Text>
                    maximum_allowed_days_for_forward_entries:
                    {
                      defaultSettingsData?.data
                        ?.maximum_allowed_days_for_forward_entries
                    }
                  </Text>
                </Box>
              ) : (
                ""
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={2}
              mt={2}
              cursor="pointer"
              onClick={() => {
                history.push("/settings");
              }}
            >
              <SettingsIcon />
              <Text margin="auto" marginLeft="0" fontWeight="bold">
                Settings
              </Text>
            </Box>
            <Divider mt={2} mb={2}></Divider>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={2}
              cursor="pointer"
              isLoading={isLoading}
              onClick={logOut}
            >
              <FaSignOutAlt />
              <Text margin="auto" marginLeft="0" color="red" fontWeight="bold">
                Logout
              </Text>
            </Box>
          </Box>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
