import { useMutation, useQueryClient } from "react-query";
import api, { API_PARTIES_CREATE } from "utils/axios";

const createCustomer = (customer) =>
  api.post(API_PARTIES_CREATE, customer).then((res) => res.data);

export function useCreateCustomer() {
  const queryClient = useQueryClient();
  return useMutation(createCustomer, {
    onSuccess: () => queryClient.refetchQueries("customers"),
  });
}
