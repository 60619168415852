import { useQuery } from "react-query";
import api, { API_LEDGER_GROUPS_LIST } from "utils/axios";

const getSingleLedgerGroup = async ({ queryKey }) => {
  const [_, id] = queryKey;
  if (id) {
    return api.get(`${API_LEDGER_GROUPS_LIST}${id}`).then((res) => res.data);
  }
};

export function useSingleLedgerGroup(id) {
  return useQuery(["singleledgergroup", id], getSingleLedgerGroup);
}
