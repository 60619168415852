import { useQuery } from "react-query";
import api, { API_ITEM_UNIT_LIST_CREATE } from "utils/axios";

const getItemUnit = async ({ queryKey }) => {
  const [_, data] = queryKey;

  let url = API_ITEM_UNIT_LIST_CREATE;
  if (data?.isSuspended && data?.isSuspended !== "") {
    url = url + `?suspened=${data.isSuspended}`;
  }
  return api.get(url).then((res) => res.data);
};

export function useItemUnit(data) {
  return useQuery(["itemunit", data], getItemUnit, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
