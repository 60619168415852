import { useQuery } from "react-query";
import api, { API_ITEM_DONEBY_LIST_CREATE } from "utils/axios";

const getDoneBy = ({ queryKey }) => {
  const [_, data] = queryKey;

  let url = API_ITEM_DONEBY_LIST_CREATE;
  if (data?.isSuspended && data?.isSuspended !== "") {
    url = url + `?suspened=${data.isSuspended}`;
  }
  return api.get(url).then((res) => res.data);
};

export function useDoneBy(data) {
  return useQuery(["doneby", data], getDoneBy, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
