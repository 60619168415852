import { createSlice } from "@reduxjs/toolkit";

import api, {
  API_ALL_TRANSACTIONS_REPORT_LIST,
  API_ALL_TRANSACTIONS_REPORT_EXPORT,
} from "../utils/axios";

const initialState = {
  loading: false,
  next: "",
  previous: "",
  count: 0,
  page: 0,
  total_pages: 0,
  reports: [],
};

const alltransactionsreport = createSlice({
  name: "alltransactionsreport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReports: (state, action) => {
      state.reports = action.payload.reports;
      state.count = action.payload.count;
      state.total_pages = action.payload.total_pages;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setReports, setErrorMessage } =
  alltransactionsreport.actions;

export default alltransactionsreport.reducer;

export const fetchAllTransactionReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      let url = API_ALL_TRANSACTIONS_REPORT_LIST.replace(
        "{page}",
        data.page
      ).replace("{pageSize}", data.pageSize);

      if (data.startDate && data.endDate) {
        url = url + `&date__gte=${data.startDate}&date__lte=${data.endDate}`;
      }
      if (data.invoiceNo && data.invoiceNo !== "") {
        url = url + `&invoice_number=${data.invoiceNo}`;
      }
      if (data.notes && data.notes !== "") {
        url = url + `&notes=${data.notes}`;
      }
      if (data.referenceNo && data.referenceNo !== "") {
        url = url + `&reference_no=${data.referenceNo}`;
      }
      if (data.invoiceType && data.invoiceType !== "") {
        url = url + `&invoice_type=${data.invoiceType}`;
      }
      if (data.sort && data.sort !== "") {
        url = url + `&sorting=${data.sort}`;
      }
      if (data.party?.length > 0) {
        url = url + `&ledger__in=${data.party}`;
      }

      if (data.costCenter?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.costCenter.filter(
          (item) => item !== notAvailableId
        );
        if (filtered?.length > 0) {
          url = url + `&cost_centre__in=${filtered}`;
        }
        if (data.costCenter.includes(notAvailableId)) {
          url = url + `&not_available_field=cost_centre`;
        }
      }

      if (data.doneBy?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.doneBy.filter((item) => item !== notAvailableId);
        if (filtered?.length > 0) {
          url = url + `&done_by__in=${filtered}`;
        }
        if (data.doneBy.includes(notAvailableId)) {
          url = url + `&not_available_field=done_by`;
        }
      }

      if (data.voucherType && data.voucherType !== "") {
        url += `&voucher_group=${data.voucherType}`;
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const exportAllTransactionReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_ALL_TRANSACTIONS_REPORT_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};
