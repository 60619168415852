import { useMutation, useQueryClient } from "react-query";
import api, { API_PRODUCTION_LIST } from "utils/axios";

const createProduction = (production) =>
  api.post(API_PRODUCTION_LIST, production).then((res) => res.data);

export function useCreateProduction() {
  const queryClient = useQueryClient();
  return useMutation(createProduction, {
    onSuccess: () => queryClient.refetchQueries("production"),
  });
}
