import { createSlice } from "@reduxjs/toolkit";

import api, {
  API_JOURNAL_ENTRIES_REPORT_LIST,
  API_JOURNAL_ENTRY_REPORT_DELETE,
  API_JOURNAL_ENTRIES_REPORT_EXPORT,
  API_JOURNAL_ENTRY_REPORT_EXPORT,
} from "../utils/axios";

const initialState = {
  loading: false,
  count: 0,
  page: 0,
  total_pages: 0,
  reports: [],
  reportDetails: {},
};

const journalentriesreport = createSlice({
  name: "journalentriesreport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReports: (state, action) => {
      state.reports = action.payload.reports;
      state.count = action.payload.count;
      state.total_pages = action.payload.total_pages;
    },
    setReportDetails: (state, action) => {
      state.reportDetails = action.payload.reportDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setReports, setReportDetails, setErrorMessage } =
  journalentriesreport.actions;

export default journalentriesreport.reducer;

export const fetchJournalEntriesReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      console.log("data----------", data);
      let url = API_JOURNAL_ENTRIES_REPORT_LIST.replace(
        "{startDate}",
        data.startDate
      ).replace("{endDate}", data.endDate);
      if (data.page && data.pageSize) {
        url = url + `&page=${data.page}&page_size=${data.pageSize}`;
      }
      if (data.costCenter && data.costCenter !== "") {
        if (data.costCenter === "notAvailableId") {
          url += `&not_available_field=cost_centre`;
        } else {
          url += `&cost_centre=${data.costCenter}`;
        }
      }
      if (data.doneBy && data.doneBy !== "") {
        if (data.doneBy === "notAvailableId") {
          url += `&not_available_field=done_by`;
        } else {
          url += `&done_by=${data.doneBy}`;
        }
      }
      if (data.voucherType?.length && data.voucherType !== "") {
        url += `&voucher_group__in=${data.voucherType}`;
      }
      if (data.drLedger && data.drLedger !== "") {
        url += `&debit_ledger=${data.drLedger}`;
      }
      if (data.crLedger && data.crLedger !== "") {
        url += `&credit_ledger=${data.crLedger}`;
      }
      if (data.searchType && data.searchKey) {
        url += `&${data.searchType}=${data.searchKey}`;
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const exportJournalEntryReport = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    const response = await api.get(
      API_JOURNAL_ENTRY_REPORT_EXPORT.replace("{id}", data.id).replace(
        "{fileType}",
        data.fileType
      )
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const exportJournalEntriesReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    console.log("data----------", data);
    const response = await api.get(
      API_JOURNAL_ENTRIES_REPORT_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const deleteJournalEntry =
  ({ data, filters }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_JOURNAL_ENTRY_REPORT_DELETE.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(
          fetchJournalEntriesReports({
            data: {
              page: filters.currentPage,
              pageSize: 10,
              startDate: filters.startDate,
              endDate: filters.endDate,
            },
          })
        );
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };
