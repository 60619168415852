/* eslint-disable react-hooks/exhaustive-deps */
import { Textarea, useToast } from "@chakra-ui/react";
import { useEffect } from "react";

const ITextarea = (props) => {
  const {
    onKeyDown,
    onFocus,
    onChange,
    inputRef,
    value,
    placeholder,
    disabled,
    id,
    style,
    height,
    width,
    errorMessage,
    isValid = true,
    isRequired = false,
  } = props;
  const toast = useToast();

  useEffect(() => {
    if (!isValid && errorMessage) {
      toast({
        title: "",
        description: `${errorMessage}`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [isValid]);
  return (
    <Textarea
      _hover={{ borderColor: isValid ? "black" : "red" }}
      _focus={{ borderColor: isValid ? "#2b6cb0" : "red", borderWidth: 2 }}
      borderColor={isValid ? "#bababa" : "red"}
      ref={inputRef}
      borderWidth={1}
      height={height}
      width={width}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onChange={onChange}
      value={value}
      style={style}
      placeholder={placeholder}
      disabled={disabled}
      id={id}
    />
  );
};
export default ITextarea;
