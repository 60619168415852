import React, { useEffect, useState } from "react";
import AutocompleteWithAddOption from "FormComponents/AutoComplete/component";

import Addunit from "../Addunit";
import { useItemUnit } from "hooks/ItemUnit/useItemUnit";

export default function UnitAutoComplete(props) {
  const {
    selectedUnit,
    setSelectedUnit,
    modal,
    disabled = false,
    inputLabel = "Unit",
  } = props;
  const { data, isLoading } = useItemUnit();

  const [listData, setlistData] = useState([]);
  const [unit, setUnit] = useState(null);
  const [searchData, setSearchData] = useState("");
  useEffect(() => {
    let unit = listData?.find((item) => item.id === selectedUnit);
    setUnit(unit);
  }, [selectedUnit, listData]);

  useEffect(() => {
    let units = data?.data
      ?.slice()
      .sort((a, b) => a.unit.localeCompare(b.unit))
      .map((item, i) => item);
    setlistData(units ?? []);
  }, [data]);
  return (
    <>
      <AutocompleteWithAddOption
        value={unit}
        options={listData}
        modal={props.modal}
        onChange={(newValue) => {
          setSelectedUnit(newValue?.id);
          setUnit(newValue);
        }}
        inputRef={props.inputRef}
        optionLabel="unit_symbol"
        inputLabel={inputLabel}
        setIsOpen={props.setIsOpen}
        textFieldClick={props.textFieldClick}
        setSearchData={setSearchData}
        disabled={disabled}
      >
        <Addunit type="add" listData={listData} searchData={searchData} />
      </AutocompleteWithAddOption>
    </>
  );
}
