import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { setLocalStore } from "utils/utils";
import { getLocalStore } from "utils/utils";
import { TAX_MODELS } from "utils/utils/taxModels";
import { useUpdateMainSettings } from "./MainSettings/useUpdateMainSettings";

export function useTaxModel() {
  const toast = useToast();
  const {
    mutate: updateMainSettings,
    data: updateResponse,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateMainSettings();

  const auth = getLocalStore("AUTH_DETAILS");
  let companyID = auth?.data?.company.id || "";

  const [taxModel, setTaxModel] = useState(
    getLocalStore("TAX_MODEL") ?? TAX_MODELS[0].value
  );

  function changeTaxModel(taxModel) {
    setLocalStore("TAX_MODEL", taxModel);
    setTaxModel(taxModel);
  }

  function updateTaxModel(taxModel) {
    setLocalStore("TAX_MODEL", taxModel);
    setTaxModel(taxModel);

    const data = {
      id: companyID,
      settings: {
        invoice_fields_in_print: [],
        item_fields_in_print: {},
        qr_code_in_invoice: null,
        keyword_settings: [],
        name_duplication_alllowed_in: [],
        default_tax_percentage: taxModel,
      },
    };

    updateMainSettings(data);
  }

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateResponse?.status === "success") {
        toastWithMessage("Settings Updated Successfully");
      }
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (isUpdateError) {
      toast({
        title: "",
        description: `Settings update failed`,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [isUpdateError]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return [taxModel, changeTaxModel, updateTaxModel];
}
