import { useState, useEffect } from "react";
import { useSettings } from "hooks/Settings/useSettings";

export function useDecimalLength() {
  const [decimalLength, setDecimalLength] = useState();
  const { data: settingsData } = useSettings();

  useEffect(() => {
    if (settingsData?.data?.decimal_place !== undefined)
      setDecimalLength(parseInt(settingsData.data.decimal_place));
  }, [settingsData?.data?.decimal_place]);

  return [decimalLength];
}
