import { useMutation, useQueryClient } from "react-query";
import api, { API_ITEM_BRAND_LIST_CREATE } from "utils/axios";

const createItemBrand = (brand) =>
  api.post(API_ITEM_BRAND_LIST_CREATE, brand).then((res) => res.data);

export function useCreateItemBrand() {
  const queryClient = useQueryClient();
  return useMutation(createItemBrand, {
    onSuccess: () => queryClient.refetchQueries("itembrand"),
  });
}
