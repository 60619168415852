import React, { useEffect, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import {
  Box,
  Stack,
  Button,
  Heading,
  FormControl,
  Grid,
  GridItem,
  Textarea,
  Tooltip,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Checkbox,
  Spacer,
  FormLabel,
  Text,
  Select,
  Flex,
  HStack,
} from "@chakra-ui/react";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Message from "../Message";
import LedgerGroupAutoComplete from "components/LedgerGroupAutoComplete/component";
import CostCenterSelectionField from "components/CostCenterSelectionField";
import DoneBySelectionField from "components/DoneBySelectionField";
import useKeypress from "react-use-keypress";
import { saveAndPrintShortKey } from "utils/utils/saveAndPrintShortkey";
import { getLocalStore } from "utils/utils";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
import { isMobile } from "react-device-detect";
import MultiSelectionField from "components/MultiSelectionField";
import {
  ITab,
  IButton,
  IHeadFav,
  IModalIn,
  IModalOut,
  ITextField,
  IHStack,
  IButtonOut,
  ITabPanel,
  IFieldAndLabel,
} from "components/IModal";
const filter = createFilterOptions();
const iconStyle = {
  fontSize: "20px",
  cursor: "pointer",
};
const RenderComponent = ({
  onClear,
  handleEdit,
  values,
  validateForm,
  customProps,
  submitAction,
  errors,
  isNotValid,
  isSubmitting,
  touched,
  handleReset,
  cancelAction,
}) => {
  const {
    type,
    listData,
    isLoading,
    isSubmitted,
    priceWithTax,
    setPriceWithTaxValue,
    price,
    setPriceValue,
    discountWithTax,
    setDiscountWithTaxValue,
    discountWithOutTax,
    setDiscountWithOutTaxValue,
    tax,
    setTaxValue,
    costCenter,
    setCostCenter,
    doneBy,
    setDoneBy,
    hsnCode,
    setHsnCode,
    editValue,
    handleFavourite,
    setSuspand,
    isToggledFav,
    suspand,
    isApproval,
    rejectFunc,
    isRejectLoading,
    doneByData,
    costCenterData,
  } = customProps;

  const country = getLocalStore("AUTH_DETAILS") || "";
  const [fieldInFocus, setFieldInFocus] = useState(1);
  const ledgerRef = useRef();
  const selectledgRef = useRef();
  const openingRef = useRef();
  const ledgcodeRef = useRef();
  const descRef = useRef();
  const priceWithoutRef = useRef();
  const discWithoutRef = useRef();
  const priceWithRef = useRef();
  const discWithRef = useRef();
  const costCenterRef = useRef();
  const doneByRef = useRef();

  const [hasExecuted, setHasExecuted] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);
  const [showTax, setShowTax] = useState(false);
  const [clickKey, setClickKey] = useState({
    keyA: false,
    keyP: false,
  });
  useEffect(() => {
    if (!isMobile) {
      if (tabIndex === 0) {
        if (fieldInFocus === 1) ledgerRef.current?.focus();
        if (fieldInFocus === 2) selectledgRef.current?.focus();
        if (fieldInFocus === 3) openingRef.current?.focus();
        if (fieldInFocus === 4) ledgcodeRef.current?.focus();
        if (fieldInFocus === 5) descRef.current?.focus();
      } else if (tabIndex === 1) {
        if (fieldInFocus === 1) priceWithoutRef.current?.focus();
        if (fieldInFocus === 2) discWithoutRef.current?.focus();
        if (fieldInFocus === 3) priceWithRef.current?.focus();
        if (fieldInFocus === 4) discWithRef.current?.focus();
      } else if (tabIndex === 2) {
        if (fieldInFocus === 1) costCenterRef.current?.focus();
        if (fieldInFocus === 2) doneByRef.current?.focus();
      }
    }
  }, [fieldInFocus]);

  // short key
  const [ctrlPressed, setCtrlPressed] = useState(false);
  useKeypress(["Enter", "Tab"], (event) => {
    if (event.key === "Enter" && fieldInFocus < 5) {
      let next = 1;
      setFieldInFocus(fieldInFocus + next);
    }

    event.stopPropagation();
    event.preventDefault();
  });

  const handleClick = (fieldId) => {
    if (fieldId < 6) {
      setFieldInFocus(fieldId);
    }
  };

  useEffect(() => {
    if (tabIndex === 2) {
      setTimeout(() => {
        costCenterRef.current?.focus();
      }, 200);
    } else if (tabIndex === 1) {
      setTimeout(() => {
        priceWithoutRef.current?.focus();
      }, 200);
    } else if (tabIndex === 0) {
      setTimeout(() => {
        ledgerRef.current?.focus();
      }, 200);
    }
  }, [tabIndex]);

  useEffect(() => {
    if (clickKey?.keyA && !isLoading && !isSubmitted) {
      onSubmit();
    }
  }, [clickKey]);
  useKeypress(["f1", "F1", "f2", "F2", "f3", "F3"], (event) => {
    event.preventDefault();
    if (["f1", "F1"].includes(event.key)) {
      setTabIndex(0);
    }
    if (["f2", "F2"].includes(event.key)) {
      setTabIndex(1);
    }
    if (["f3", "F3"].includes(event.key)) {
      setTabIndex(2);
    }
    if (!["f1", "F1", "f2", "F2", "f3", "F3"].includes(event.key)) {
      event.stopPropagation();
      event.preventDefault();
    }
  });
  saveAndPrintShortKey(setClickKey);
  const [buttonClick, setButtonClick] = useState(false);
  const onSubmit = () => {
    setButtonClick(!buttonClick);
    const validateResponse = validateForm();
    validateResponse.then((data) => {
      submitAction(data);
    });
  };
  //Tax Options added
  let taxOptions;

  switch (country.data.company.country) {
    case "INDIA":
      taxOptions = [
        { value: "0", label: "0" },
        { value: "0.25", label: "0.25%" },
        { value: "3", label: "3%" },
        { value: "5", label: "5%" },
        { value: "12", label: "12%" },
        { value: "18", label: "18%" },
        { value: "28", label: "28%" },
      ];
      break;
    case "SAUDI":
      taxOptions = [
        { value: "0", label: "0" },
        { value: "15", label: "15%" },
      ];
      break;
    case "OMAN":
      taxOptions = [
        { value: "0", label: "0" },
        { value: "5", label: "5%" },
      ];
      break;
    case "UAE":
      taxOptions = [
        { value: "0", label: "0" },
        { value: "5", label: "5%" },
      ];
      break;
    default:
      taxOptions = [
        { value: "0", label: "0" },
        { value: "0.25", label: "0.25%" },
        { value: "3", label: "3%" },
        { value: "5", label: "5%" },
        { value: "7", label: "7%" },
        { value: "12", label: "12%" },
        { value: "15", label: "15%" },
        { value: "18", label: "18%" },
        { value: "28", label: "28%" },
        { value: "-1", label: "Exempted" },
      ];
      break;
  }

  useEffect(() => {
    setHasExecuted(false);
  }, []);

  return (
    <IModalOut>
      <IHeadFav
        text={
          isApproval
            ? "Approve Ledger - " + values.name
            : type === "edit"
            ? "Edit Ledger - " + values.name
            : "Add Ledger"
        }
        isFavour={isToggledFav}
        onClick={handleFavourite}
      />
      <Box overflowY="auto">
        <Tabs
          isFitted
          onChange={(index) => setTabIndex(index)}
          index={tabIndex}
        >
          <TabList>
            <ITab tooltipLabel="f1">Basic Info</ITab>
            <ITab tooltipLabel="f2">Rate & Tax</ITab>
            <ITab tooltipLabel="f3">Other Info</ITab>
          </TabList>
          <TabPanels>
            <TabPanel p={0}>
              <IModalIn>
                <FormControl>
                  <ITextField
                    disabled={isApproval}
                    fullWidth
                    label="Ledger Name"
                    variant="outlined"
                    size="small"
                    type="text"
                    name="name"
                    inputRef={ledgerRef}
                    onFocus={(e) => {
                      if (e) {
                        handleClick(1);
                      }
                    }}
                    onChange={(event) =>
                      handleEdit(event.target.value, { field: "name" })
                    }
                    value={values.name || ""}
                    isValid={isNotValid}
                    isRequired={true}
                    errorMessage={errors.name}
                    buttonClick={buttonClick}
                  />
                </FormControl>
                <LedgerGroupAutoComplete
                  disabled={isApproval}
                  selectedGroup={values.group}
                  setSelectedGroup={(newValue) => {
                    handleEdit(newValue, { field: "group" });
                  }}
                  inputRef={selectledgRef}
                  listData={listData}
                  textFieldClick={() => handleClick(2)}
                />
                {errors.group && !isNotValid && (
                  <Message type="error" message={errors.group} />
                )}
                <IHStack>
                  <FormControl>
                    <TextField
                      disabled={isApproval}
                      fullWidth
                      label={isMobile ? "Op.Balance" : "Opening Balance"}
                      variant="outlined"
                      size="small"
                      type="number"
                      id="opening_balance"
                      inputRef={openingRef}
                      onChange={(event) => {
                        let targetValue = event.target.value;
                        if (targetValue === "") targetValue = "0";
                        if (targetValue.length > 1 && targetValue[0] === "0")
                          targetValue = targetValue.slice(1);
                        handleEdit(targetValue, {
                          field: "opening_balance",
                        });
                      }}
                      onClick={() => handleClick(3)}
                      value={values.opening_balance || ""}
                    />
                    {errors.opening_balance && !isNotValid && (
                      <Message type="error" message={errors.opening_balance} />
                    )}
                  </FormControl>
                  <FormControl>
                    <TextField
                      disabled={isApproval}
                      fullWidth
                      label="Ledger Code"
                      variant="outlined"
                      size="small"
                      type="text"
                      id="ledger_code"
                      inputRef={ledgcodeRef}
                      onChange={(event) => {
                        let targetValue = event.target.value;
                        if (targetValue === "") targetValue = "0";
                        if (targetValue.length > 1 && targetValue[0] === "0")
                          targetValue = targetValue.slice(1);
                        handleEdit(targetValue, {
                          field: "ledger_code",
                        });
                      }}
                      onClick={() => handleClick(4)}
                      value={values.ledger_code || ""}
                    />
                    {errors.ledger_code && !isNotValid && (
                      <Message type="error" message={errors.ledger_code} />
                    )}
                  </FormControl>
                </IHStack>
                <FormControl>
                  <Textarea
                    disabled={isApproval}
                    placeholder="Description"
                    type="text"
                    name="description"
                    ref={descRef}
                    onChange={(event) =>
                      handleEdit(event.target.value, { field: "description" })
                    }
                    onClick={() => handleClick(5)}
                    value={values.description || ""}
                    onKeyDown={(event) => {
                      if (event.key === "ArrowDown") {
                        handleEdit(values.description + "\n", {
                          field: "description",
                        });
                      }
                      if (event.key === "Enter" && !hasExecuted) {
                        onSubmit();
                      }
                    }}
                  />
                  {/* {errors.description && !isNotValid && (
                <Message type="error" message={errors.description} />
              )} */}
                </FormControl>
                <Checkbox
                  disabled={isApproval}
                  isChecked={suspand}
                  onChange={(e) => {
                    setSuspand(!suspand);
                  }}
                >
                  Required Suspend
                </Checkbox>
              </IModalIn>
            </TabPanel>
            <TabPanel p={0}>
              <IModalIn>
                <IHStack>
                  <Spacer />
                  <Checkbox
                    isChecked={showTax}
                    onChange={(e) => {
                      setShowTax(!showTax);
                    }}
                  >
                    Show With Tax
                  </Checkbox>
                  <Select
                    disabled={isApproval}
                    placeholder="Tax %"
                    onChange={(event) => {
                      setTaxValue(event.target.value);
                    }}
                    width="25%"
                    name="tax"
                    value={tax}
                    as={Select}
                    id="tax"
                  >
                    {taxOptions.map((taxOption, key) => (
                      <option value={taxOption.value} key={key}>
                        {taxOption.label}
                      </option>
                    ))}
                  </Select>
                </IHStack>
                <IFieldAndLabel>
                  <FormLabel>
                    <Text>Price Without Tax</Text>
                  </FormLabel>
                  <IHStack>
                    <FormControl id="price">
                      <TextField
                        disabled={isApproval}
                        label="Price Without Tax"
                        variant="outlined"
                        size="small"
                        type="number"
                        inputRef={priceWithoutRef}
                        onChange={(event) => {
                          setPriceValue(event.target.value);
                        }}
                        onFocus={(e) => {
                          if (e) {
                            handleClick(1);
                          }
                        }}
                        value={price || ""}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        disabled={isApproval}
                        label="Disc. Without Tax"
                        inputRef={discWithoutRef}
                        variant="outlined"
                        size="small"
                        type="number"
                        onChange={(event) =>
                          setDiscountWithOutTaxValue(event.target.value)
                        }
                        value={
                          discountWithOutTax
                            ? discountWithOutTax
                            : editValue?.discount_with_tax === 0
                            ? editValue?.discount_with_tax
                            : "" || ""
                        }
                      ></TextField>
                    </FormControl>
                    {!isMobile && (
                      <FormControl>
                        <TextField
                          disabled
                          label="Net Amount"
                          variant="outlined"
                          size="small"
                          type="text"
                          value={
                            _parseFloat(
                              price -
                                (editValue?.discount_without_tax
                                  ? editValue?.discount_without_tax
                                  : discountWithOutTax)
                            ).toFixed(2) || ""
                          }
                        ></TextField>
                      </FormControl>
                    )}
                  </IHStack>
                </IFieldAndLabel>

                {showTax ? (
                  <>
                    <IFieldAndLabel>
                      <FormLabel>
                        <Text>Price With Tax</Text>
                      </FormLabel>
                      <IHStack>
                        <FormControl id="priceWithTax">
                          <TextField
                            disabled={isApproval}
                            label="Price With Tax"
                            variant="outlined"
                            size="small"
                            type="number"
                            inputRef={priceWithRef}
                            onChange={(event) =>
                              setPriceWithTaxValue(event.target.value)
                            }
                            value={priceWithTax || ""}
                          />
                        </FormControl>
                        <FormControl>
                          <TextField
                            disabled={isApproval}
                            label="Disc. With Tax"
                            variant="outlined"
                            inputRef={discWithRef}
                            size="small"
                            type="number"
                            onChange={(event) =>
                              setDiscountWithTaxValue(event.target.value)
                            }
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                // console.log("Testst")
                                onSubmit();
                              }
                            }}
                            value={
                              discountWithTax
                                ? discountWithTax
                                : editValue?.discount_with_tax === 0
                                ? editValue?.discount_with_tax
                                : "" || ""
                            }
                          ></TextField>
                        </FormControl>
                        {!isMobile && (
                          <FormControl>
                            <TextField
                              disabled
                              label="Net Amount"
                              variant="outlined"
                              size="small"
                              type="text"
                              value={
                                _parseFloat(
                                  priceWithTax - discountWithTax
                                ).toFixed(2) || ""
                              }
                            ></TextField>
                          </FormControl>
                        )}
                      </IHStack>
                    </IFieldAndLabel>
                  </>
                ) : (
                  ""
                )}
              </IModalIn>
            </TabPanel>
            <TabPanel p={0}>
              <IModalIn>
                <MultiSelectionField
                  placeholder="Cost Centers"
                  datas={costCenterData?.data}
                  options={costCenter}
                  setOptions={setCostCenter}
                  inputRef={costCenterRef}
                  onFocus={(e) => {
                    if (e) {
                      handleClick(1);
                    }
                  }}
                />
                <MultiSelectionField
                  placeholder="Done Bys"
                  datas={doneByData?.data}
                  options={doneBy}
                  setOptions={setDoneBy}
                  inputRef={doneByRef}
                />
                <TextField
                  fullWidth
                  placeholder="HSN Code"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="name"
                  onChange={(event) => setHsnCode(event.target.value)}
                  value={hsnCode || ""}
                />
              </IModalIn>
            </TabPanel>
          </TabPanels>
        </Tabs>
        <IButtonOut>
          {isApproval && (
            <Tooltip
              hasArrow
              label="Reject"
              placement="bottom"
              bg="brand.bg_blue"
            >
              <Button
                w="100%"
                isLoading={isRejectLoading}
                minH="40px"
                type="submit"
                size="lg"
                variant="solid"
                bg="red.600"
                colorScheme="red"
                onClick={() => {
                  rejectFunc();
                }}
              >
                Reject
              </Button>
            </Tooltip>
          )}
          <IButton
            isLoading={isLoading}
            onClick={(e) => {
              onSubmit();
            }}
            isApproval={isApproval}
            type={type}
          />
        </IButtonOut>
      </Box>
    </IModalOut>
  );
};

export default RenderComponent;

const _parseFloat = (num) => {
  return parseFloat(num || 0);
};
