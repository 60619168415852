import { createSlice } from "@reduxjs/toolkit";

import api, {
  API_LEDGER_REPORT_LIST,
  API_LEDGER_REPORT_EXPORT,
} from "../utils/axios";

const initialState = {
  loading: false,
  next: "",
  previous: "",
  group_category: "",
  count: 0,
  page: 0,
  total_pages: 0,
  closing_balance: 0,
  opening_balance: 0,
  debit_total: 0,
  credit_total: 0,
  reports: [],
  reportDetails: {},
};

const ledgerreport = createSlice({
  name: "ledgerreport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReports: (state, action) => {
      state.reports = action.payload.reports;
      state.count = action.payload.count;
      state.total_pages = action.payload.total_pages;
      state.closing_balance = action.payload.closing_balance;
      state.opening_balance = action.payload.opening_balance;
      state.debit_total = action.payload.debit_total;
      state.group_category = action.payload.group_category;
      state.credit_total = action.payload.credit_total;
    },
    setReportDetails: (state, action) => {
      state.reportDetails = action.payload.reportDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setReports, setReportDetails, setErrorMessage } =
  ledgerreport.actions;

export default ledgerreport.reducer;

export const fetchLedgerReports = (data, callback) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    let url = API_LEDGER_REPORT_LIST.replace("{startDate}", data.startDate)
      .replace("{endDate}", data.endDate)
      .replace("{ledger}", data.ledger);

    if (data.page && data.page !== "") {
      url = url + `&page=${data.page}`;
    }
    if (data.pageSize && data.pageSize !== "") {
      url = url + `&page_size=${data.pageSize}`;
    }
    if (data.voucherNo && data.voucherNo !== "") {
      url = url + `&voucher_no=${data.voucherNo}`;
    }
    if (data.transactionType && data.transactionType !== "") {
      url = url + `&transaction_type=${data.transactionType}`;
    }
    if (data.voucherType && data.voucherType !== "") {
      url = url + `&voucher_group=${data.voucherType}`;
    }
    if (data.notes && data.notes !== "") {
      url = url + `&notes=${data.notes}`;
    }
    if (data.referenceNo && data.referenceNo !== "") {
      url = url + `&reference_no=${data.referenceNo}`;
    }
    if (data.amount && data.amount !== "") {
      url = url + `&amount=${data.amount}`;
    }
    if (data.costCenter && data.costCenter !== "") {
      if (data.costCenter === "notAvailableId") {
        url += `&not_available_field=cost_centre`;
      } else {
        url += `&cost_centre=${data.costCenter}`;
      }
    }
    if (data.doneBy && data.doneBy !== "") {
      if (data.doneBy === "notAvailableId") {
        url += `&not_available_field=done_by`;
      } else {
        url += `&done_by=${data.doneBy}`;
      }
    }
    if (data.particularLedger && data.particularLedger !== "") {
      url += `&particulars=${data.particularLedger}`;
    }

    const response = await api.get(url);
    if (response?.data?.status === "success") {
      dispatch(
        setReports({
          reports: response.data.data,
          count: response.data.data.count,
          total_pages: response.data.data.total_pages,
          closing_balance: response.data.data.closing_balance,
          opening_balance: response.data.data.opening_balance,
          debit_total: response.data.data.debit_total,
          group_category: response.data.data.group_category,
          credit_total: response.data.data.credit_total,
        })
      );
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(setReports({ reports: [] }));
  }
};

export const exportLedgerReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_LEDGER_REPORT_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{id}", data.id)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const clearLedgerReports = () => async (dispatch) => {
  dispatch(setReports({ ...initialState }));
};
