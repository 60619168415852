import { createSlice } from "@reduxjs/toolkit";
import { fetchSupplier } from "./supplier.slice";
import { fetchItems } from "./groups.slice";
import { showLoading, hideLoading } from "./common.slice";

// import { setLocalStore, setResponse } from '../utils/utils'
import api, {
  API_PURCHASE_REPORT_LIST,
  API_PURCHASE_REPORT_FOR_CUSTOMER_LIST,
  API_PURCHASE_REPORT_DELETE,
  API_PURCHASE_REPORT_LIST_BY_PARTY,
  API_PURCHASE_REPORT_LIST_BY_IDS,
  API_PAYMENT_AGAINST_PURCHASE_CREATE,
  API_PURCHASE_REPORT_EXPORT,
  API_PURCHASE_REPORTS_EXPORT,
  API_PAYMENT_AGAINST_PURCHASE_GET_EDIT_DELETE,
  API_PURCHASE_REPORT_LIST_MINIMAL,
  API_PURCHASE_REPORT_LIST_BY_PARTY_MINIMAL,
  API_PURCHASE_REPORT_LIST_BY_IDS_MINIMAL,
} from "../utils/axios";
import { setLocalStore } from "utils/utils";

const initialState = {
  loading: false,
  next: "",
  previous: "",
  count: 0,
  page: 0,
  total_pages: 0,
  totalBalance: 0,
  totalGross: 0,
  totalNet: 0,
  totalTax: 0,
  reports: [],
  reportDetails: {},
};

const purchasereport = createSlice({
  name: "purchasereport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReports: (state, action) => {
      state.reports = action.payload.reports;
      state.count = action.payload.count;
      state.total_pages = action.payload.total_pages;
      state.totalBalance = action.payload.total_balance;
      state.totalGross = action.payload.total_gross;
      state.totalNet = action.payload.total_net;
      state.totalTax = action.payload.total_tax;
    },
    setReportDetails: (state, action) => {
      state.reportDetails = action.payload.reportDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setReports, setReportDetails, setErrorMessage } =
  purchasereport.actions;

export default purchasereport.reducer;

export const fetchPurchaseReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url = API_PURCHASE_REPORT_LIST_MINIMAL.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{withItem}", data.withItem);

      if (data.is_primary === false) {
        url = url + `&is_primary=${data.is_primary}`;
      }
      if (data.sortList && data.sortList !== "") {
        url = url + `&sorting=${data.sortList}`;
      }

      if (data.costCenter?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.costCenter.filter(
          (item) => item !== notAvailableId
        );
        if (filtered?.length > 0) {
          url = url + `&cost_centre__in=${filtered}`;
        }
        if (data.costCenter.includes(notAvailableId)) {
          url = url + `&not_available_field=cost_centre`;
        }
      }

      if (data.doneBy?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.doneBy.filter((item) => item !== notAvailableId);
        if (filtered?.length > 0) {
          url = url + `&done_by__in=${filtered}`;
        }
        if (data.doneBy.includes(notAvailableId)) {
          url = url + `&not_available_field=done_by`;
        }
      }
      if (data.taxType?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.taxType.filter((item) => item !== notAvailableId);
        if (filtered?.length > 0) {
          url = url + `&tax_type__in=${filtered}`;
        }
        if (data.taxType === "notAvailableId") {
          url = url + `&not_available_field=tax_type`;
        }
      }
      if (data.placeOfSupply?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.placeOfSupply.filter(
          (item) => item !== notAvailableId
        );
        if (filtered?.length > 0) {
          url = url + `&place_of_supply__in=${filtered}`;
        }
        if (data.placeOfSupply === "notAvailableId") {
          url = url + `&not_available_field=place_of_supply`;
        }
      }

      if (data.supplier?.length > 0) {
        url = url + `&party__in=${data.supplier}`;
      }
      if (data.modeOfPayment && data.modeOfPayment !== "") {
        if (data.modeOfPayment === "notAvailableId") {
          url = url + `&not_available_field=mode_or_terms_of_payment`;
        } else {
          url = url + `&mode_or_terms_of_payment=${data.modeOfPayment}`;
        }
      }
      if (data.invoiceNumber && data.invoiceNumber !== "") {
        url = url + `&invoice_number__icontains=${data.invoiceNumber}`;
      }
      if (data.referenceNumber && data.referenceNumber !== "") {
        url = url + `&reference_number__icontains=${data.referenceNumber}`;
      }
      if (data.notes && data.notes !== "") {
        url = url + `&notes=${data.notes}`;
      }
      if (data.remarksInInvoice && data.remarksInInvoice !== "") {
        url = url + `&remarks_in_invoice=${data.remarksInInvoice}`;
      }
      if (data.termsOfDelivery && data.termsOfDelivery !== "") {
        url = url + `&terms_of_delivery=${data.termsOfDelivery}`;
      }
      if (data.dispatchDocumentNumber && data.dispatchDocumentNumber !== "") {
        url = url + `&dispatch_document_number=${data.dispatchDocumentNumber}`;
      }
      if (data.dispatchedThrough && data.dispatchedThrough !== "") {
        url = url + `&dispatched_through=${data.dispatchedThrough}`;
      }

      if (data.destination && data.destination !== "") {
        url = url + `&destination=${data.destination}`;
      }

      if (data.supplierReference && data.supplierReference !== "") {
        url = url + `&supplier_reference=${data.supplierReference}`;
      }
      if (data.grossAmount && data.grossAmount !== "") {
        url = url + `&sub_total=${data.grossAmount}`;
      }
      if (data.taxAmount && data.taxAmount !== "") {
        url = url + `&vat=${data.taxAmount}`;
      }
      if (data.totalAmount && data.totalAmount !== "") {
        url = url + `&total=${data.totalAmount}`;
      }
      if (data.voucherType?.length > 0) {
        url += `&voucher_group__in=${data.voucherType}`;
      }
      if (data.store && data.store !== "") {
        url += `&store=${data.store}`;
      }
      if (data.tax && data.tax !== "") {
        url += `&tax=${data.tax}`;
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_balance: response.data.data.total_balance,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const fetchPurchaseLists =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url = API_PURCHASE_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{withItem}", data.withItem);

      if (data.is_primary === false) {
        url = url + `&is_primary=${data.is_primary}`;
      }
      if (data.sortList && data.sortList !== "") {
        url = url + `&sorting=${data.sortList}`;
      }
      if (data.costCenter && data.costCenter !== "") {
        if (data.costCenter === "notAvailableId") {
          url = url + `&not_available_field=cost_centre`;
        } else {
          url = url + `&cost_centre=${data.costCenter}`;
        }
      }
      if (data.doneBy && data.doneBy !== "") {
        if (data.doneBy === "notAvailableId") {
          url = url + `&not_available_field=done_by`;
        } else {
          url = url + `&done_by=${data.doneBy}`;
        }
      }
      if (data.taxType && data.taxType !== "") {
        if (data.taxType === "notAvailableId") {
          url = url + `&not_available_field=tax_type`;
        } else {
          url = url + `&tax_type=${data.taxType}`;
        }
      }
      if (data.placeOfSupply && data.placeOfSupply !== "") {
        if (data.placeOfSupply === "notAvailableId") {
          url = url + `&not_available_field=place_of_supply`;
        } else {
          url = url + `&place_of_supply=${data.placeOfSupply}`;
        }
      }
      if (data.supplier && data.supplier !== "") {
        url = url + `&party=${data.supplier}`;
      }
      if (data.modeOfPayment && data.modeOfPayment !== "") {
        if (data.modeOfPayment === "notAvailableId") {
          url = url + `&not_available_field=mode_or_terms_of_payment`;
        } else {
          url = url + `&mode_or_terms_of_payment=${data.modeOfPayment}`;
        }
      }
      if (data.invoiceNumber && data.invoiceNumber !== "") {
        url = url + `&invoice_number__icontains=${data.invoiceNumber}`;
      }
      if (data.referenceNumber && data.referenceNumber !== "") {
        url = url + `&reference_number__icontains=${data.referenceNumber}`;
      }
      if (data.notes && data.notes !== "") {
        url = url + `&notes=${data.notes}`;
      }
      if (data.remarksInInvoice && data.remarksInInvoice !== "") {
        url = url + `&remarks_in_invoice=${data.remarksInInvoice}`;
      }
      if (data.termsOfDelivery && data.termsOfDelivery !== "") {
        url = url + `&terms_of_delivery=${data.termsOfDelivery}`;
      }
      if (data.dispatchDocumentNumber && data.dispatchDocumentNumber !== "") {
        url = url + `&dispatch_document_number=${data.dispatchDocumentNumber}`;
      }
      if (data.dispatchedThrough && data.dispatchedThrough !== "") {
        url = url + `&dispatched_through=${data.dispatchedThrough}`;
      }

      if (data.destination && data.destination !== "") {
        url = url + `&destination=${data.destination}`;
      }

      if (data.supplierReference && data.supplierReference !== "") {
        url = url + `&supplier_reference=${data.supplierReference}`;
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_balance: response.data.data.total_balance,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };
export const exportSinglePurchaseReport = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    const response = await api.get(
      API_PURCHASE_REPORT_EXPORT.replace("{id}", data.id).replace(
        "{fileType}",
        data.fileType
      )
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};
export const exportPurchaseReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_PURCHASE_REPORTS_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const fetchPurchaseReportsForParty =
  (data, callback) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      const response = await api.get(
        API_PURCHASE_REPORT_FOR_CUSTOMER_LIST.replace("{page}", data.page)
          .replace("{pageSize}", data.pageSize)
          .replace("{startDate}", data.startDate)
          .replace("{endDate}", data.endDate)
          .replace("{party}", data.party)
          .replace("{status}", data.status)
          .replace("{withItem}", data.withItem)
      );
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
    }
  };

export const deletePurchase =
  ({ data, filters }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_PURCHASE_REPORT_DELETE.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        if (filters) {
          dispatch(
            fetchPurchaseReports({
              data: {
                page: filters.currentPage,
                pageSize: 10,
                startDate: filters.startDate,
                endDate: filters.endDate,
                status: filters.status,
                is_primary: false,
              },
            })
          );
        }
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const fetchPurchaseReportsByParty =
  (data, callback) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      const response = await api.get(
        API_PURCHASE_REPORT_LIST_BY_PARTY_MINIMAL.replace(
          "{party}",
          data.party
        ).replace("{status}", data.status)
      );
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
    }
  };

export const fetchPurchaseReportsByInvoiceIds =
  (data, callback) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      //api call
      const response = await api.get(
        API_PURCHASE_REPORT_LIST_BY_IDS_MINIMAL.replace(
          "{ids}",
          data.invoiceIds.join()
        )
      );
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
    }
  };

export const fetchPurchaseReportsByInvoiceIdsNotMinimal =
  (data, callback) => async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      //api call
      const response = await api.get(
        API_PURCHASE_REPORT_LIST_BY_IDS.replace("{ids}", data.invoiceIds.join())
      );
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_gross: response.data.data.total_gross,
            total_net: response.data.data.total_net,
            total_tax: response.data.data.total_tax,
          })
        );
        dispatch(setLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
    }
  };

export const addPaymentAgainstPurchase =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.post(
        API_PAYMENT_AGAINST_PURCHASE_CREATE,
        data
      );
      if (response?.data?.status === "success") {
        // dispatch(setData({ data: response.data.data }))
        dispatch(setLoading({ loading: false }));
        dispatch(fetchSupplier());
        dispatch(clearPurchaseReports());
        dispatch(fetchItems());
      }
      dispatch(hideLoading());
      return response;
    } catch (error) {
      dispatch(hideLoading());
    }
  };

export const getPaymentAgainstPurchase = (id) => async (dispatch) => {
  try {
    dispatch(showLoading());
    const response = await api.get(
      API_PAYMENT_AGAINST_PURCHASE_GET_EDIT_DELETE.replace("{id}", id)
    );
    if (response?.data?.status === "success") {
      dispatch(setReportDetails({ data: response.data.data }));
    }
    dispatch(hideLoading());
    return response;
  } catch (error) {
    dispatch(hideLoading());
  }
};

export const editPaymentAgainstPurchase =
  ({ data, id }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading());
      const response = await api.patch(
        API_PAYMENT_AGAINST_PURCHASE_GET_EDIT_DELETE.replace("{id}", id),
        data
      );
      if (response?.data?.status === "success") {
        // dispatch(setData({ data: response.data.data }))
      }
      dispatch(hideLoading());
      return response;
    } catch (error) {
      dispatch(hideLoading());
    }
  };

// export const addItem = ({ data, callback }) => async (dispatch) => {
//   try {
//     dispatch(setLoading({ loading: true }))
//     const response = await api.post(API_ITEMS_ADD, data)
//     if (response && response.data) {
//       dispatch(setLoading({ loading: false }))
//       dispatch(fetchItems())
//       callback && callback()
//     }
//   } catch (error) {
//     dispatch(setLoading({ loading: false }))
//   }
// }

// export const editItem = ({ data, callback }) => async (dispatch) => {
//   try {
//     dispatch(setLoading({ loading: true }))
//     const response = await api.patch(API_ITEMS_EDIT.replace('{id}', data.id), data)
//     if (response && response.data) {
//       dispatch(setLoading({ loading: false }))
//       dispatch(fetchItems())
//       callback && callback()
//     }
//   } catch (error) {
//     dispatch(setLoading({ loading: false }))
//   }
// }

// export const deleteItem = ({ data }) => async (dispatch) => {
//   try {
//     dispatch(setLoading({ loading: true }))
//     const response = await api.delete(API_ITEMS_EDIT.replace('{id}', data.id))
//     if (response && response.data) {
//       dispatch(setLoading({ loading: false }))
//       dispatch(fetchItems())
//     }
//   } catch (error) {
//     dispatch(setLoading({ loading: false }))
//   }
// }

export const clearPurchaseReports = (data, callback) => async (dispatch) => {
  dispatch(setReports({ ...initialState }));
};
