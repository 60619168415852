import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { number, object, string } from "yup";

import { useToast } from "@chakra-ui/react";

import Form from "../Form";
import RenderComponent from "./RenderComponent";
import { useLedgerGroups } from "hooks/LedgerGroup/useLedgerGroups";
import { useCreateLedger } from "hooks/Ledger/useCreateLedger";
import { useUpdateLedger } from "hooks/Ledger/useUpdateLedger";
import { useDecimalLength } from "hooks/useDecimalLength";
import { getLocalStore } from "utils/utils";
import { useCostCenters } from "hooks/CostCenter/useCostCenters";
import { useDoneBy } from "hooks/DoneBy/useDoneBy";

const AddLedgerForm = (props) => {
  const {
    type = "add",
    editValue,
    onAddSuccess,
    isApproval = false,
    rejectFunc,
    isRejectLoading = false,
  } = props;
  const [isToggledFav, setIsToggledFav] = useState(false);
  const [suspand, setSuspand] = useState(false);
  const auth = getLocalStore("AUTH_DETAILS");

  const toast = useToast();
  const decimalLength = useDecimalLength(2);
  const { data: ledgerGroupData } = useLedgerGroups();
  const { data: costCenterData } = useCostCenters();
  const { data: doneByData } = useDoneBy();
  const {
    mutate: createLedger,
    data,
    isSuccess,
    isLoading: isCreateLoading,
  } = useCreateLedger();

  const {
    mutate: updateLedger,
    data: updateLedgerResponse,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useUpdateLedger();

  const initialValue = {
    name: "",
    opening_balance: 0,
    ledger_code: "",
    description: "",
    group: "",
    extras: {},
    is_suspended: suspand,
    favourites: isToggledFav,
  };

  const [formValues, setformValues] = useState(initialValue);
  const [groups, setGroups] = useState([]);
  const [tax, setTax] = useState("0");
  const [price, setPrice] = useState("");
  const [priceWithTax, setPriceWithTax] = useState("");
  const [discountWithOutTax, setDiscountWithOutTax] = useState("");
  const [discountWithTax, setDiscountWithTax] = useState("");
  const [costCenter, setCostCenter] = useState([]);
  const [doneBy, setDoneBy] = useState([]);
  const [hsnCode, setHsnCode] = useState("");

  const handleFavourite = () => {
    setIsToggledFav(!isToggledFav);
  };

  useEffect(() => {
    if (type === "add") {
      setformValues(initialValue);
      if (costCenterData && doneByData) {
        setCostCenter(
          costCenterData.data?.filter((i) =>
            [
              getLocalStore("DEFAULT_SETTINGS_DATA")?.default_cost_centre,
            ]?.includes(i.id)
          ) || []
        );
        setDoneBy(
          doneByData.data?.filter((i) =>
            [getLocalStore("DEFAULT_SETTINGS_DATA")?.default_done_by]?.includes(
              i.id
            )
          ) || []
        );
      }
    } else {
      const groupSelected = editValue?.group
        ? ledgerGroupData?.data?.filter(
            (item) => item.name === editValue?.group
          )[0]
        : undefined;

      if (editValue) {
        setformValues({
          ...editValue,
          group: groupSelected ? groupSelected?.id : "",
        });

        setPrice(_parseFloat(editValue.extras?.price));
        setTax(_parseFloat(editValue?.extras?.tax));

        setPrice(_parseFloat(editValue.extras?.price));
        setPriceWithTax(_parseFloat(editValue.extras?.price_with_tax));
        setDiscountWithOutTax(
          _parseFloat(editValue.extras?.discount_without_tax)
        );
        setDiscountWithTax(_parseFloat(editValue.extras?.discount_with_tax));
        setIsToggledFav(editValue.favourites);
        setSuspand(editValue.is_suspended);
        setHsnCode(editValue.extras?.hsn_number || "");
        if (costCenterData && doneByData) {
          setCostCenter(
            costCenterData.data?.filter((i) =>
              editValue.extras?.cost_centres?.includes(i.id)
            ) || []
          );
          setDoneBy(
            doneByData.data?.filter((i) =>
              editValue.extras?.done_bys?.includes(i.id)
            ) || []
          );
        }
      }
    }
  }, [editValue, ledgerGroupData, doneByData, costCenterData]);

  useEffect(() => {
    let ledgerGroups = ledgerGroupData?.data;
    let sundryDebters = ledgerGroups?.find(
      (group) => group.name === "Sundry Debtors"
    );
    let sundryCreditors = ledgerGroups?.find(
      (group) => group.name === "Sundry Creditors"
    );
    let filteredGroups = ledgerGroups?.filter(
      (group) =>
        ![sundryCreditors.uuid, sundryDebters.uuid].includes(group.uuid) &&
        ![sundryCreditors.uuid, sundryDebters.uuid].includes(group.master)
    );
    setGroups(filteredGroups);
  }, [ledgerGroupData]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.status === "success") {
        toastWithMessage("Ledger created successfully");
        onAddSuccess(data?.data);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      if (updateLedgerResponse?.status === "success") {
        toastWithMessage("Ledger updated successfully");
        onAddSuccess(updateLedgerResponse?.data);
      }
    }
  }, [isUpdateSuccess]);

  const toastWithMessage = (message) => {
    toast({
      title: "",
      description: message,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  const setPriceValue = (price) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let taxAmount = (price * taxPercentage) / 100;
    let priceWithTax = _parseFloat(price) + taxAmount;
    setPriceWithTax(_parseFloat(priceWithTax).toFixed(decimalLength));
    setPrice(price);

    let decimals = price.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setPrice(_parseFloat(price).toFixed(decimalLength));
    }
  };

  const setPriceWithTaxValue = (priceWithTax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let price = _parseFloat(priceWithTax) / (1 + taxPercentage / 100);
    setPrice(_parseFloat(price).toFixed(decimalLength));
    setPriceWithTax(_parseFloat(priceWithTax));

    let decimals = priceWithTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setPriceWithTax(_parseFloat(priceWithTax).toFixed(decimalLength));
    }
  };

  //Discount With Tax
  const setDiscountWithTaxValue = (discountWithTax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let discountWithOutTax =
      _parseFloat(discountWithTax) / (1 + taxPercentage / 100);
    setDiscountWithOutTax(
      _parseFloat(discountWithOutTax).toFixed(decimalLength)
    );
    setDiscountWithTax(_parseFloat(discountWithTax));

    let decimals = discountWithTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setDiscountWithTax(_parseFloat(discountWithTax).toFixed(decimalLength));
    }
  };

  //Discount With Out Tax
  const setDiscountWithOutTaxValue = (discountWithOutTax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let taxAmount = (discountWithOutTax * taxPercentage) / 100;
    let discountWithTax = _parseFloat(discountWithOutTax) + taxAmount;
    setDiscountWithTax(_parseFloat(discountWithTax).toFixed(decimalLength));
    setDiscountWithOutTax(_parseFloat(discountWithOutTax));

    let decimals = discountWithOutTax.split(".")[1];
    if (decimals && decimals.length > decimalLength) {
      setDiscountWithOutTax(
        _parseFloat(discountWithOutTax).toFixed(decimalLength)
      );
    }
  };

  const setTaxValue = (tax) => {
    let taxPercentage = tax === "-1" ? 0 : parseInt(tax);
    let taxAmount = (price * taxPercentage) / 100;
    let priceWithTax = _parseFloat(price) + taxAmount;
    let discountWithTax =
      _parseFloat(discountWithOutTax) + (discountWithOutTax * taxAmount) / 100;
    setPriceWithTax(_parseFloat(priceWithTax).toFixed(decimalLength));
    setDiscountWithTax(_parseFloat(discountWithTax));
    setTax(tax);
  };

  const onSubmit = (fields) => {
    fields.is_suspended = suspand;
    fields.favourites = isToggledFav;
    const extraData = {
      tax: _parseFloat(tax).toFixed(decimalLength),
      price: _parseFloat(price).toFixed(decimalLength),
      price_with_tax: _parseFloat(priceWithTax).toFixed(decimalLength),
      discount_without_tax:
        _parseFloat(discountWithOutTax).toFixed(decimalLength),
      discount_with_tax: _parseFloat(discountWithTax).toFixed(decimalLength),
      cost_centres: costCenter?.map((i) => i.id),
      done_bys: doneBy?.map((i) => i.id),
      hsn_number: hsnCode,
      // is_suspended: suspand,
      // favourites: isToggledFav,
    };

    fields.extras = extraData;

    if (type === "edit") {
      if (isApproval) {
        fields.approved_by = auth?.data?.id;
      } else {
        delete fields.approved_by;
      }
      updateLedger(fields);
    } else {
      createLedger(fields);
    }
  };

  return (
    <Form
      initialValues={{ ...formValues }}
      RenderComponent={RenderComponent}
      handleOnSubmit={onSubmit}
      customProps={{
        type: type,
        listData: groups,
        priceWithTax,
        setPriceWithTaxValue,
        price,
        setPriceValue,
        tax,
        setTaxValue,
        discountWithTax,
        setDiscountWithTaxValue,
        discountWithOutTax,
        setDiscountWithOutTaxValue,
        costCenter,
        setCostCenter,
        doneBy,
        setDoneBy,
        hsnCode,
        setHsnCode,
        editValue,
        isLoading: isCreateLoading || isUpdateLoading,
        isSubmitted: isSuccess || isUpdateSuccess,
        handleFavourite,
        setSuspand,
        isToggledFav,
        suspand,
        isApproval,
        rejectFunc,
        isRejectLoading,
        doneByData,
        costCenterData,
      }}
      validationSchema={object().shape({
        name: string().required("Name Required"),
        opening_balance: number()
          // .moreThan(-1, "Enter a valid number")
          .required("Opening balance Required")
          .typeError("Enter a valid number"),
        group: string().required("Group Required"),
        extras: object().nullable(),
      })}
    />
  );
};

export default AddLedgerForm;

const _parseFloat = (num) => {
  return parseFloat(num || 0);
};
