import { useMutation, useQueryClient } from "react-query";
import api, { API_LEDGER_EDIT } from "utils/axios";

const updateLedger = (ledger) =>
  api
    .patch(API_LEDGER_EDIT.replace("{id}", ledger.id), ledger)
    .then((res) => res.data);

export function useUpdateLedger() {
  const queryClient = useQueryClient();
  return useMutation(updateLedger, {
    onSuccess: () => queryClient.refetchQueries("ledgers"),
  });
}
