import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "focus-visible/dist/focus-visible";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { isMobile } from "react-device-detect";

const colors = {
  brand: {
    bg_blue: "#060F42",
    bg_green: "#27AE60",
    bg_light_blue: "#E4E9F3",
    bg_off_white: "#F9FAFB",
    bg_mobile_white: "#FFFFFF",
  },
};

const theme = extendTheme({ colors });
const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 10 * 1000, // 10 seconds
      staleTime: 5 * 1000, // 5 second
      retry: (failureCount, error) => {
        if (
          error?.response !== undefined &&
          [404, 503, 504].includes(error?.response?.status)
        ) {
          return false;
        }
        return failureCount < 2;
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={client}>
        <App />
        {!isMobile ? <ReactQueryDevtools initialIsOpen={false} /> : null}
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
    window.location.reload();
  },
});

reportWebVitals();
