import { createSlice } from "@reduxjs/toolkit";

import api, { API_IMPORT_REPORT } from "../utils/axios";

const initialState = {
  loading: false,
  next: "",
  previous: "",
  count: 0,
  total_pages: 0,
  results: [],
};

const importReports = createSlice({
  name: "importReports",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setItems: (state, action) => {
      state.results = action.payload.results;
      state.count = action.payload.count;
      state.total_pages = action.payload.total_pages;
    },
    setItemDetails: (state, action) => {
      state.itemDetails = action.payload.itemDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setItems, setItemDetails, setErrorMessage } =
  importReports.actions;

export default importReports.reducer;

const form = new FormData();

export const importReport =
  ({
    data,
    reportType,
    fileType,
    partyType,
    manageDuplicates,
    withItem,
    type,
  }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      form.append("file", data);
      var importUrl = API_IMPORT_REPORT.replace("{reportType}", reportType)
        .replace("{withItem}", withItem)
        .replace("{type}", type)
        .replace("{fileType}", fileType);

      // if (importUrl.includes("undefined")) {
      //   importUrl = importUrl.replace("?with_item=undefined", "");
      // }
      if (partyType && partyType !== "") {
        importUrl = importUrl + `&party_type=${partyType}`;
      }
      if (manageDuplicates && manageDuplicates !== "") {
        importUrl = importUrl + `&manage_duplicates=${manageDuplicates}`;
      }

      const response = await api.post(importUrl, form, {
        "Content-Type": "multipart/form-data",
      });
      if (response?.data?.status === "success") {
        // dispatch(setData({ data: response.data.data }))
      }
      dispatch(setLoading({ loading: false }));
      return response;
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };
