import { createSlice } from "@reduxjs/toolkit";

import api, {
  API_STOCK_SUMMARY_REPORT_LIST,
  API_STOCK_SUMMARY_REPORT_EXPORT,
  API_LOW_STOCK_REPORT_LIST,
  API_LOW_STOCK_REPORT_EXPORT,
  API_STOCK_LOSS_REPORT_LIST,
  API_STOCK_LOSS_REPORT_EXPORT,
  API_STOCK_LOSS_REPORT_DELETE,
  API_STOCK_DETAILED_REPORT_LIST,
  API_STOCK_DETAILED_REPORT_EXPORT,
  API_ITEM_WISE_PROFIT_REPORT_LIST,
  API_ITEM_WISE_PROFIT_REPORT_EXPORT,
  API_ITEM_DETAILED_REPORT_LIST,
  API_ITEM_DETAILED_REPORT_EXPORT,
} from "../utils/axios";

const initialState = {
  loading: false,
  next: "",
  previous: "",
  count: 0,
  page: 0,
  total_pages: 0,
  totalQtyIn: 0,
  totalQtyOut: 0,
  totalAmtIn: 0,
  totalAmtOut: 0,
  total_values: {},
  all_details: {},
  reports: [],
};

const stockreport = createSlice({
  name: "stockreport",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading;
    },
    setReports: (state, action) => {
      state.reports = action.payload.reports;
      state.count = action.payload.count;
      state.total_pages = action.payload.total_pages;
      state.totalQtyIn = action.payload.total_stock_in_qty;
      state.totalQtyOut = action.payload.total_stock_out_qty;
      state.totalAmtIn = action.payload.total_stock_in_amount;
      state.totalAmtOut = action.payload.total_stock_out_amount;
      state.total_values = action.payload.total_values;
      state.all_details = action.payload.all_details;
    },
    setReportDetails: (state, action) => {
      state.reportDetails = action.payload.reportDetails;
    },
    setErrorMessage: (state, action) => {
      state.error = action.payload.message;
    },
  },
});

export const { setLoading, setReports, setErrorMessage } = stockreport.actions;

export default stockreport.reducer;

export const fetchStockSummaryReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url = API_STOCK_SUMMARY_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{endDate}", data.endDate)
        .replace("{in_stock}", data.instock)
        .replace("{negative_stock}", data.negativestock)
        .replace("{zero_stock}", data.zerostock)
        .replace("{track_stock}", data.nonTrackingStock);

      if (data.category?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.category.filter(
          (category) => category !== notAvailableId
        );

        if (filtered?.length > 0) {
          url += `&category__in=${filtered}`;
        }
        if (data.category.includes(notAvailableId)) {
          url += `&not_available_field=category`;
        }
      }

      if (data.brand?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.brand.filter(
          (category) => category !== notAvailableId
        );

        if (filtered?.length > 0) {
          url = url + `&brand__in=${filtered}`;
        }
        if (data.brand.includes(notAvailableId)) {
          url = url + `&not_available_field=brand`;
        }
      }

      if (data.group?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.group.filter(
          (category) => category !== notAvailableId
        );

        if (filtered?.length > 0) {
          url = url + `&group__in=${filtered}`;
        }

        if (data.group.includes(notAvailableId)) {
          url = url + `&not_available_field=group`;
        }
      }

      if (data.unit?.length > 0) {
        url = url + `&unit__in=${data.unit}`;
      }
      if (data.itemName && data.itemName !== "") {
        url = url + `&name=${data.itemName}`;
      }
      if (data.store && data.store !== "") {
        if (data.store === "notAvailableId") {
          url = url + `&not_available_field=store`;
        } else {
          url = url + `&store=${data.store}`;
        }
      }
      if (data.excludeZeroQty && data.excludeZeroQty !== "") {
        url = url + `&exclude_zero_quantity=${data.excludeZeroQty}`;
      }
      if (data.sort && data.sort !== "") {
        if (data.sort === "name" || data.sort === "-name") {
          url = url + `&sorting=${data.sort}`;
        } else {
          url = url + `&sort=${data.sort}`;
        }
      }
      if (
        data.order &&
        data.order !== "" &&
        !(data.sort === "name" || data.sort === "-name")
      ) {
        url = url + `&order=${data.order}`;
      }
      if (data.nameSearch && data.nameSearch !== "") {
        url = url + `&name__icontains=${data.nameSearch}`;
      }

      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_stock_in_qty: response.data.data.total_stock_in_qty,
            total_stock_out_qty: response.data.data.total_stock_out_qty,
            total_stock_in_amount: response.data.total_stock_in_amount,
            total_stock_out_amount: response.data.total_stock_out_amount,
            total_pages: response.data.data.total_pages,
            total_values: response.data.data.total_values,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const exportStockSummaryReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_STOCK_SUMMARY_REPORT_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const fetchLowStockReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url = API_LOW_STOCK_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{endDate}", data.endDate)
        .replace("{in_stock}", data.instock)
        .replace("{negative_stock}", data.negativestock)
        .replace("{zero_stock}", data.zerostock)
        .replace("{track_stock}", data.nonTrackingStock);

      if (data.category?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.category.filter(
          (category) => category !== notAvailableId
        );

        if (filtered?.length > 0) {
          url += `&category__in=${filtered}`;
        }
        if (data.category.includes(notAvailableId)) {
          url += `&not_available_field=category`;
        }
      }

      if (data.brand?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.brand.filter(
          (category) => category !== notAvailableId
        );

        if (filtered?.length > 0) {
          url = url + `&brand__in=${filtered}`;
        }
        if (data.brand.includes(notAvailableId)) {
          url = url + `&not_available_field=brand`;
        }
      }

      if (data.group?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.group.filter(
          (category) => category !== notAvailableId
        );

        if (filtered?.length > 0) {
          url = url + `&group__in=${filtered}`;
        }

        if (data.group.includes(notAvailableId)) {
          url = url + `&not_available_field=group`;
        }
      }

      if (data.unit?.length > 0) {
        url = url + `&unit__in=${data.unit}`;
      }
      if (data.itemName && data.itemName !== "") {
        url = url + `&name=${data.itemName}`;
      }
      if (data.store && data.store !== "") {
        if (data.store === "notAvailableId") {
          url = url + `&not_available_field=store`;
        } else {
          url = url + `&store=${data.store}`;
        }
      }
      if (data.sort && data.sort !== "") {
        if (data.sort === "name" || data.sort === "-name") {
          url = url + `&sorting=${data.sort}`;
        } else {
          url = url + `&sort=${data.sort}`;
        }
      }
      if (
        data.order &&
        data.order !== "" &&
        !(data.sort === "name" || data.sort === "-name")
      ) {
        url = url + `&order=${data.order}`;
      }
      if (data.nameSearch && data.nameSearch !== "") {
        url = url + `&name__icontains=${data.nameSearch}`;
      }

      if (data.removeZero) {
        url = url + `&${data.removeZero}=true`;
      }

      const response = await api.get(url);

      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_values: response.data.data.total_values,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const exportLowStockReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_LOW_STOCK_REPORT_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const fetchStockDetailedReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url = API_STOCK_DETAILED_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{in_stock}", data.instock)
        .replace("{negative_stock}", data.negativestock)
        .replace("{zero_stock}", data.zerostock)
        .replace("{track_stock}", data.nonTrackingStock);

      if (data.category?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.category.filter(
          (category) => category !== notAvailableId
        );

        if (filtered?.length > 0) {
          url += `&category__in=${filtered}`;
        }
        if (data.category.includes(notAvailableId)) {
          url += `&not_available_field=category`;
        }
      }

      if (data.brand?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.brand.filter(
          (category) => category !== notAvailableId
        );

        if (filtered?.length > 0) {
          url = url + `&brand__in=${filtered}`;
        }
        if (data.brand.includes(notAvailableId)) {
          url = url + `&not_available_field=brand`;
        }
      }

      if (data.group?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.group.filter(
          (category) => category !== notAvailableId
        );

        if (filtered?.length > 0) {
          url = url + `&group__in=${filtered}`;
        }

        if (data.group.includes(notAvailableId)) {
          url = url + `&not_available_field=group`;
        }
      }

      if (data.unit?.length > 0) {
        url = url + `&unit__in=${data.unit}`;
      }
      if (data.itemName && data.itemName !== "") {
        url = url + `&name=${data.itemName}`;
      }
      if (data.store && data.store !== "") {
        if (data.store === "notAvailableId") {
          url = url + `&not_available_field=store`;
        } else {
          url = url + `&store=${data.store}`;
        }
      }
      if (data.transactionType) {
        url = url + `&transaction_type=${data.transactionType}`;
      }

      if (data.excludeZeroQty && data.excludeZeroQty !== "") {
        url = url + `&exclude_zero_quantity=${data.excludeZeroQty}`;
      }
      if (data.sort && data.sort !== "") {
        if (data.sort === "name" || data.sort === "-name") {
          url = url + `&sorting=${data.sort}`;
        } else {
          url = url + `&sort=${data.sort}`;
        }
      }
      if (
        data.order &&
        data.order !== "" &&
        !(data.sort === "name" || data.sort === "-name")
      ) {
        url = url + `&order=${data.order}`;
      }
      if (data.zeroQtyOpening) {
        url = url + `&zero_stock_opening=true`;
      }
      if (data.zeroQtyIn) {
        url = url + `&zero_stock_in=true`;
      }
      if (data.zeroQtyOut) {
        url = url + `&zero_stock_out=true`;
      }
      if (data.nameSearch && data.nameSearch !== "") {
        url = url + `&name__icontains=${data.nameSearch}`;
      }

      const response = await api.get(url);

      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_values: response.data.data.total_values,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const exportStockDetailedReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_STOCK_DETAILED_REPORT_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const fetchItemWiseProfitReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url = API_ITEM_WISE_PROFIT_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{in_stock}", data.instock)
        .replace("{negative_stock}", data.negativestock)
        .replace("{zero_stock}", data.zerostock)
        .replace("{track_stock}", data.nonTrackingStock);

      if (data.category?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.category.filter(
          (category) => category !== notAvailableId
        );

        if (filtered?.length > 0) {
          url += `&category__in=${filtered}`;
        }
        if (data.category.includes(notAvailableId)) {
          url += `&not_available_field=category`;
        }
      }

      if (data.brand?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.brand.filter(
          (category) => category !== notAvailableId
        );

        if (filtered?.length > 0) {
          url = url + `&brand__in=${filtered}`;
        }
        if (data.brand.includes(notAvailableId)) {
          url = url + `&not_available_field=brand`;
        }
      }

      if (data.group?.length > 0) {
        const notAvailableId = "notAvailableId";
        const filtered = data.group.filter(
          (category) => category !== notAvailableId
        );

        if (filtered?.length > 0) {
          url = url + `&group__in=${filtered}`;
        }

        if (data.group.includes(notAvailableId)) {
          url = url + `&not_available_field=group`;
        }
      }

      if (data.unit?.length > 0) {
        url = url + `&unit__in=${data.unit}`;
      }
      if (data.itemName && data.itemName !== "") {
        url = url + `&name=${data.itemName}`;
      }
      if (data.store && data.store !== "") {
        if (data.store === "notAvailableId") {
          url = url + `&not_available_field=store`;
        } else {
          url = url + `&store=${data.store}`;
        }
      }
      if (data.sort && data.sort !== "") {
        if (data.sort === "name" || data.sort === "-name") {
          url = url + `&sorting=${data.sort}`;
        } else {
          url = url + `&sort=${data.sort}`;
        }
      }
      if (
        data.order &&
        data.order !== "" &&
        !(data.sort === "name" || data.sort === "-name")
      ) {
        url = url + `&order=${data.order}`;
      }
      if (data.nameSearch && data.nameSearch !== "") {
        url = url + `&name__icontains=${data.nameSearch}`;
      }
      const response = await api.get(url);
      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_pages: response.data.data.total_pages,
            total_values: response.data.data.total_values,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const exportItemWiseProfitReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_ITEM_WISE_PROFIT_REPORT_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const fetchItemDetailedReports =
  ({ data, callback }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      // api call
      let url = API_ITEM_DETAILED_REPORT_LIST.replace("{id}", data.id)
        .replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate);

      if (data.invoiceNumber && data.invoiceNumber !== "") {
        url = url + `&invoice_number=${data.invoiceNumber}`;
      }
      if (data.stockInQty && data.stockInQty !== "") {
        url = url + `&stock_in_qty=${data.stockInQty}`;
      }
      if (data.stockInRate && data.stockInRate !== "") {
        url = url + `&stock_in_rate=${data.stockInRate}`;
      }
      if (data.stockInValue && data.stockInValue !== "") {
        url = url + `&stock_in_value=${data.stockInValue}`;
      }
      if (data.stockOutQty && data.stockOutQty !== "") {
        url = url + `&stock_out_qty=${data.stockOutQty}`;
      }
      if (data.stockOutRate && data.stockOutRate !== "") {
        url = url + `&stock_out_rate=${data.stockOutRate}`;
      }
      if (data.stockOutValue && data.stockOutValue !== "") {
        url = url + `&stock_out_value=${data.stockOutValue}`;
      }
      if (data.partyName && data.partyName !== "") {
        url = url + `&party_name=${data.partyName}`;
      }
      if (data.invoiceType?.length > 0) {
        url = url + `&entry_type__in=${data.invoiceType}`;
      }
      if (data.sort && data.sort !== "") {
        if (data.sort === "party_name" || data.sort === "-party_name") {
          url = url + `&sorting=${data.sort}`;
        } else {
          url = url + `&sort=${data.sort}`;
        }
      }
      if (
        data.order &&
        data.order !== "" &&
        !(data.sort === "party_name" || data.sort === "-party_name")
      ) {
        url = url + `&order=${data.order}`;
      }
      if (data.store && data.store !== "") {
        url = url + `&store=${data.store}`;
      }

      const response = await api.get(url);

      if (response?.data?.status === "success") {
        dispatch(
          setReports({
            reports: response.data.data.results,
            count: response.data.data.count,
            total_stock_in_qty: response.data.data.total_stock_in_qty,
            total_stock_out_qty: response.data.data.total_stock_out_qty,
            total_stock_in_amount: response.data.data.total_stock_in_amount,
            total_stock_out_amount: response.data.data.total_stock_out_amount,
            total_pages: response.data.data.total_pages,
            all_details: response.data.data,
          })
        );
        dispatch(setLoading({ loading: false }));
        callback && callback(response?.data);
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
      dispatch(setReports({ reports: [] }));
      callback && callback(error);
    }
  };

export const exportItemDetailedReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_ITEM_DETAILED_REPORT_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const fetchStockLossReports = (data, callback) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_STOCK_LOSS_REPORT_LIST.replace("{page}", data.page)
        .replace("{pageSize}", data.pageSize)
        .replace("{endDate}", data.endDate)
    );
    if (response?.data?.status === "success") {
      dispatch(
        setReports({
          reports: response.data.data.results,
          count: response.data.data.count,
          total_pages: response.data.data.total_pages,
          total_values: response.data.data.total_values,
        })
      );
      dispatch(setLoading({ loading: false }));
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
    dispatch(setReports({ reports: [] }));
  }
};

export const exportStockLossReports = (data) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }));
    // api call
    const response = await api.get(
      API_STOCK_LOSS_REPORT_EXPORT.replace("{startDate}", data.startDate)
        .replace("{endDate}", data.endDate)
        .replace("{status}", data.status)
        .replace("{fileType}", data.fileType)
    );
    if (response?.data?.status === "success") {
      dispatch(setLoading({ loading: false }));
      return response?.data?.data?.url;
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }));
  }
};

export const deleteStockLoss =
  ({ data, filters }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading({ loading: true }));
      const response = await api.delete(
        API_STOCK_LOSS_REPORT_DELETE.replace("{id}", data.id)
      );
      if (response && response.data) {
        dispatch(setLoading({ loading: false }));
        dispatch(
          fetchStockLossReports({
            page: filters.currentPage,
            pageSize: 10,
            startDate: filters.startDate.toISOString().substring(0, 10),
            endDate: filters.endDate.toISOString().substring(0, 10),
            status: filters.status,
          })
        );
      }
    } catch (error) {
      dispatch(setLoading({ loading: false }));
    }
  };

export const clearStockReports = (data, callback) => async (dispatch) => {
  dispatch(setReports({ ...initialState }));
};
