// export const countrieslist = [
//   "UAE",
//   "BAHRAIN",
//   "SAUDI",
//   "INDIA",
//   "OMAN",
//   "QATAR",
// ];

export const countrieslist = [
  { name: "AFGHANISTAN", value: "AFGHANISTAN" },
  { name: "ALBANIA", value: "ALBANIA" },
  { name: "ALGERIA", value: "ALGERIA" },
  { name: "ANDORRA", value: "ANDORRA" },
  { name: "ANGOLA", value: "ANGOLA" },
  { name: "ANTIGUA AND BARBUDA", value: "ANTIGUA_AND_BARBUDA" },
  { name: "ARGENTINA", value: "ARGENTINA" },
  { name: "ARMENIA", value: "ARMENIA" },
  { name: "AUSTRIA", value: "AUSTRIA" },
  { name: "AZERBAIJAN", value: "AZERBAIJAN" },
  { name: "BAHRAIN", value: "BAHRAIN" },
  { name: "BANGLADESH", value: "BANGLADESH" },
  { name: "BARBADOS", value: "BARBADOS" },
  { name: "BELARUS", value: "BELARUS" },
  { name: "BELGIUM", value: "BELGIUM" },
  { name: "BELIZE", value: "BELIZE" },
  { name: "BENIN", value: "BENIN" },
  { name: "BHUTAN", value: "BHUTAN" },
  { name: "BOLIVIA", value: "BOLIVIA" },
  { name: "BOSNIA AND HERZEGOVINA", value: "BOSNIA_AND_HERZEGOVINA" },
  { name: "BOTSWANA", value: "BOTSWANA" },
  { name: "BRAZIL", value: "BRAZIL" },
  { name: "BRUNEI", value: "BRUNEI" },
  { name: "BULGARIA", value: "BULGARIA" },
  { name: "BURKINA FASO", value: "BURKINA_FASO" },
  { name: "BURUNDI", value: "BURUNDI" },
  { name: "CABO VERDE", value: "CABO_VERDE" },
  { name: "CAMBODIA", value: "CAMBODIA" },
  { name: "CAMEROON", value: "CAMEROON" },
  { name: "CANADA", value: "CANADA" },
  { name: "CENTRAL AFRICAN REPUBLIC", value: "CENTRAL_AFRICAN_REPUBLIC" },
  { name: "CHAD", value: "CHAD" },
  { name: "CHANNEL ISLANDS", value: "CHANNEL_ISLANDS" },
  { name: "CHILE", value: "CHILE" },
  { name: "CHINA", value: "CHINA" },
  { name: "COLOMBIA", value: "COLOMBIA" },
  { name: "COMOROS", value: "COMOROS" },
  { name: "CONGO", value: "CONGO" },
  { name: "COSTA RICA", value: "COSTA_RICA" },
  { name: "CÔTE D IVOIRE", value: "CÔTE_D_IVOIRE" },
  { name: "CROATIA", value: "CROATIA" },
  { name: "CUBA", value: "CUBA" },
  { name: "CYPRUS", value: "CYPRUS" },
  { name: "CZECH REPUBLIC", value: "CZECH_REPUBLIC" },
  { name: "DENMARK", value: "DENMARK" },
  { name: "DJIBOUTI", value: "DJIBOUTI" },
  { name: "DOMINICA", value: "DOMINICA" },
  { name: "DOMINICAN REPUBLIC", value: "DOMINICAN_REPUBLIC" },
  { name: "DR CONGO", value: "DR_CONGO" },
  { name: "ECUADOR", value: "ECUADOR" },
  { name: "EGYPT", value: "EGYPT" },
  { name: "EL SALVADOR", value: "EL_SALVADOR" },
  { name: "EQUATORIAL GUINEA", value: "EQUATORIAL_GUINEA" },
  { name: "ERITREA", value: "ERITREA" },
  { name: "ESTONIA", value: "ESTONIA" },
  { name: "ESWATINI", value: "ESWATINI" },
  { name: "ETHIOPIA", value: "ETHIOPIA" },
  { name: "FAEROE ISLANDS", value: "FAEROE_ISLANDS" },
  { name: "FINLAND", value: "FINLAND" },
  { name: "FRANCE", value: "FRANCE" },
  { name: "FRENCH GUIANA", value: "FRENCH_GUIANA" },
  { name: "GABON", value: "GABON" },
  { name: "GAMBIA", value: "GAMBIA" },
  { name: "GEORGIA", value: "GEORGIA" },
  { name: "GERMANY", value: "GERMANY" },
  { name: "GHANA", value: "GHANA" },
  { name: "GIBRALTAR", value: "GIBRALTAR" },
  { name: "GREECE", value: "GREECE" },
  { name: "GRENADA", value: "GRENADA" },
  { name: "GUATEMALA", value: "GUATEMALA" },
  { name: "GUINEA", value: "GUINEA" },
  { name: "GUINEA BISSAU", value: "GUINEA_BISSAU" },
  { name: "GUYANA", value: "GUYANA" },
  { name: "HAITI", value: "HAITI" },
  { name: "HOLY SEE", value: "HOLY_SEE" },
  { name: "HONDURAS", value: "HONDURAS" },
  { name: "HONG KONG", value: "HONG_KONG" },
  { name: "HUNGARY", value: "HUNGARY" },
  { name: "ICELAND", value: "ICELAND" },
  { name: "INDIA", value: "INDIA" },
  { name: "INDONESIA", value: "INDONESIA" },
  { name: "IRAN", value: "IRAN" },
  { name: "IRAQ", value: "IRAQ" },
  { name: "IRELAND", value: "IRELAND" },
  { name: "ISLE OF MAN", value: "ISLE_OF_MAN" },
  { name: "ISRAEL", value: "ISRAEL" },
  { name: "ITALY", value: "ITALY" },
  { name: "JAMAICA", value: "JAMAICA" },
  { name: "JAPAN", value: "JAPAN" },
  { name: "JORDAN", value: "JORDAN" },
  { name: "KAZAKHSTAN", value: "KAZAKHSTAN" },
  { name: "KENYA", value: "KENYA" },
  { name: "KUWAIT", value: "KUWAIT" },
  { name: "KYRGYZSTAN", value: "KYRGYZSTAN" },
  { name: "LAOS", value: "LAOS" },
  { name: "LATVIA", value: "LATVIA" },
  { name: "LEBANON", value: "LEBANON" },
  { name: "LESOTHO", value: "LESOTHO" },
  { name: "LIBERIA", value: "LIBERIA" },
  { name: "LIBYA", value: "LIBYA" },
  { name: "LIECHTENSTEIN", value: "LIECHTENSTEIN" },
  { name: "LITHUANIA", value: "LITHUANIA" },
  { name: "LUXEMBOURG", value: "LUXEMBOURG" },
  { name: "MACAO", value: "MACAO" },
  { name: "MADAGASCAR", value: "MADAGASCAR" },
  { name: "MALAWI", value: "MALAWI" },
  { name: "MALAYSIA", value: "MALAYSIA" },
  { name: "MALDIVES", value: "MALDIVES" },
  { name: "MALI", value: "MALI" },
  { name: "MALTA", value: "MALTA" },
  { name: "MAURITANIA", value: "MAURITANIA" },
  { name: "MAURITIUS", value: "MAURITIUS" },
  { name: "MAYOTTE", value: "MAYOTTE" },
  { name: "MEXICO", value: "MEXICO" },
  { name: "MOLDOVA", value: "MOLDOVA" },
  { name: "MONACO", value: "MONACO" },
  { name: "MONGOLIA", value: "MONGOLIA" },
  { name: "MONTENEGRO", value: "MONTENEGRO" },
  { name: "MOROCCO", value: "MOROCCO" },
  { name: "MOZAMBIQUE", value: "MOZAMBIQUE" },
  { name: "MYANMAR", value: "MYANMAR" },
  { name: "NAMIBIA", value: "NAMIBIA" },
  { name: "NEPAL", value: "NEPAL" },
  { name: "NETHERLANDS", value: "NETHERLANDS" },
  { name: "NICARAGUA", value: "NICARAGUA" },
  { name: "NIGER", value: "NIGER" },
  { name: "NIGERIA", value: "NIGERIA" },
  { name: "NORTH KOREA", value: "NORTH_KOREA" },
  { name: "NORTH MACEDONIA", value: "NORTH_MACEDONIA" },
  { name: "NORWAY", value: "NORWAY" },
  { name: "OMAN", value: "OMAN" },
  { name: "PAKISTAN", value: "PAKISTAN" },
  { name: "PANAMA", value: "PANAMA" },
  { name: "PARAGUAY", value: "PARAGUAY" },
  { name: "PERU", value: "PERU" },
  { name: "PHILIPPINES", value: "PHILIPPINES" },
  { name: "POLAND", value: "POLAND" },
  { name: "PORTUGAL", value: "PORTUGAL" },
  { name: "QATAR", value: "QATAR" },
  { name: "REUNION", value: "REUNION" },
  { name: "ROMANIA", value: "ROMANIA" },
  { name: "RUSSIA", value: "RUSSIA" },
  { name: "RWANDA", value: "RWANDA" },
  { name: "SAINT HELENA", value: "SAINT_HELENA" },
  { name: "SAINT KITTS AND NEVIS", value: "SAINT_KITTS_AND_NEVIS" },
  { name: "SAINT LUCIA", value: "SAINT_LUCIA" },
  {
    name: "SAINT VINCENT AND THE GRENADINES",
    value: "SAINT_VINCENT_AND_THE_GRENADINES",
  },
  { name: "SAN MARINO", value: "SAN_MARINO" },
  { name: "SAO TOME AND PRINCIPE", value: "SAO_TOME_AND_PRINCIPE" },
  { name: "SAUDI ARABIA", value: "SAUDI" },
  { name: "SENEGAL", value: "SENEGAL" },
  { name: "SERBIA", value: "SERBIA" },
  { name: "SEYCHELLES", value: "SEYCHELLES" },
  { name: "SIERRA LEONE", value: "SIERRA_LEONE" },
  { name: "SINGAPORE", value: "SINGAPORE" },
  { name: "SLOVAKIA", value: "SLOVAKIA" },
  { name: "SLOVENIA", value: "SLOVENIA" },
  { name: "SOMALIA", value: "SOMALIA" },
  { name: "SOUTH AFRICA", value: "SOUTH_AFRICA" },
  { name: "SOUTH KOREA", value: "SOUTH_KOREA" },
  { name: "SOUTH SUDAN", value: "SOUTH_SUDAN" },
  { name: "SPAIN", value: "SPAIN" },
  { name: "SRI LANKA", value: "SRI_LANKA" },
  { name: "STATE OF PALESTINE", value: "STATE_OF_PALESTINE" },
  { name: "SUDAN", value: "SUDAN" },
  { name: "SURINAME", value: "SURINAME" },
  { name: "SWEDEN", value: "SWEDEN" },
  { name: "SWITZERLAND", value: "SWITZERLAND" },
  { name: "SYRIA", value: "SYRIA" },
  { name: "TAIWAN", value: "TAIWAN" },
  { name: "TAJIKISTAN", value: "TAJIKISTAN" },
  { name: "TANZANIA", value: "TANZANIA" },
  { name: "THAILAND", value: "THAILAND" },
  { name: "THE BAHAMAS", value: "THE_BAHAMAS" },
  { name: "TIMOR LESTE", value: "TIMOR_LESTE" },
  { name: "TOGO", value: "TOGO" },
  { name: "TRINIDAD AND TOBAGO", value: "TRINIDAD_AND_TOBAGO" },
  { name: "TUNISIA", value: "TUNISIA" },
  { name: "TURKEY", value: "TURKEY" },
  { name: "TURKMENISTAN", value: "TURKMENISTAN" },
  { name: "UGANDA", value: "UGANDA" },
  { name: "UKRAINE", value: "UKRAINE" },
  { name: "UNITED ARAB EMIRATES", value: "UAE" },
  { name: "UNITED KINGDOM", value: "UNITED_KINGDOM" },
  { name: "UNITED STATES", value: "UNITED_STATES" },
  { name: "URUGUAY", value: "URUGUAY" },
  { name: "UZBEKISTAN", value: "UZBEKISTAN" },
  { name: "VENEZUELA", value: "VENEZUELA" },
  { name: "VIETNAM", value: "VIETNAM" },
  { name: "WESTERN SAHARA", value: "WESTERN_SAHARA" },
  { name: "YEMEN", value: "YEMEN" },
  { name: "ZAMBIA", value: "ZAMBIA" },
  { name: "ZIMBABWE", value: "ZIMBABWE" },
];

export const emiratesList = [
  { value: "ABU_DHABI", name: "ABU DHABI" },
  { value: "DUBAI", name: "DUBAI" },
  { value: "AJMAN", name: "AJMAN" },
  { value: "SHARJA", name: "SHARJAH" },
  { value: "RAS_AL_KHAIMAH", name: "RAS AL KHAIMAH" },
  { value: "FUJAIRAH", name: "FUJAIRAH" },
  { value: "UMM_AL_QUWAIN", name: "UMM AL QUWAIN" },
];

export const emiratesListIndia = [
  // { name: "NOT APPLICABLE", value: "NOT_APPLICABLE" },
  { name: "ANDHRA PRADESH", value: "ANDHRA_PRADESH" },
  { name: "ARUNACHAL PRADESH", value: "ARUNACHAL_PRADESH" },
  { name: "ASSAM", value: "ASSAM" },
  { name: "BIHAR", value: "BIHAR" },
  { name: "CHHATTISGARH", value: "CHHATTISGARH" },
  { name: "GOA", value: "GOA" },
  { name: "GUJARAT", value: "GUJARAT" },
  { name: "HARYANA", value: "HARYANA" },
  { name: "HIMACHAL PRADESH", value: "HIMACHAL_PRADESH" },
  { name: "JHARKHAND", value: "JHARKHAND" },
  { name: "KARNATAKA", value: "KARNATAKA" },
  { name: "KERALA", value: "KERALA" },
  { name: "MADHYA PRADESH", value: "MADHYA_PRADESH" },
  { name: "MAHARASHTRA", value: "MAHARASHTRA" },
  { name: "MANIPUR", value: "MANIPUR" },
  { name: "MEGHALAYA", value: "MEGHALAYA" },
  { name: "MIZORAM", value: "MIZORAM" },
  { name: "NAGALAND", value: "NAGALAND" },
  { name: "ODISHA", value: "ODISHA" },
  { name: "PUNJAB", value: "PUNJAB" },
  { name: "RAJASTHAN", value: "RAJASTHAN" },
  { name: "SIKKIM", value: "SIKKIM" },
  { name: "TAMIL NADU", value: "TAMIL_NADU" },
  { name: "TELANGANA", value: "TELANGANA" },
  { name: "TRIPURA", value: "TRIPURA" },
  { name: "UTTARAKHAND", value: "UTTARAKHAND" },
  { name: "UTTAR PRADESH", value: "UTTAR_PRADESH" },
  { name: "WEST BENGAL", value: "WEST_BENGAL" },
  { name: "ANDAMAN AND NICOBAR ISLANDS", value: "ANDAMAN_AND_NICOBAR_ISLANDS" },
  { name: "CHANDIGARH", value: "CHANDIGARH" },
  {
    name: "DADRA AND NAGAR HAVELI AND DAMAN AND DIU",
    value: "DADRA_AND_NAGAR_HAVELI_AND_DAMAN_AND_DIU",
  },
  { name: "DELHI", value: "DELHI" },
  { name: "JAMMU AND KASHMIR", value: "JAMMU_AND_KASHMIR" },
  { name: "LADAKH", value: "LADAKH" },
  { name: "LAKSHADWEEP", value: "LAKSHADWEEP" },
  { name: "PUDUCHERRY", value: "PUDUCHERRY" },
];

export const taxregistrationtypeList = [
  { name: "REGISTERED BUSINESS", value: "REGISTERED_BUSINESS" },
  {
    name: "REGISTERED BUSINESS COMPOSITION",
    value: "REGISTERED_BUSINESS_COMPOSITION",
  },
  { name: "UNREGISTERED BUSINESS", value: "UNREGISTERED_BUSINESS" },
  { name: "CONSUMER", value: "CONSUMER" },
  { name: "OVERSEAS", value: "OVERSEAS" },
  { name: "SPECIAL ECONOMIC ZONE", value: "SPECAIL_ECONOMIC_ZONE" },
  { name: "DEEMED EXPORT", value: "DEEMED_EXPORT" },
  { name: "FREE ZONE", value: "FREE_ZONE" },
];

export const industryOptionList = [
  { value: "SUPERMARKETS", name: "Supermarkets" },
  { value: "RETAIL", name: "Retail" },
  { value: "WHOLESALE", name: "Wholesale" },
  { value: "MANUFACTURING", name: "Manufacturing" },
  { value: "SERVICES", name: "Services" },
  { value: "MANUFACTURING_AND_SERVICES", name: "Manufacturing and Services" },
  { value: "REPAIR_AND_MAINTENANCE", name: "Repair and Maintenance" },
  { value: "RETAIL_AND_SERVICES", name: "Retail and Services" },
  { value: "WHOLESALE_AND_SERVICES", name: "Wholesale and Services" },
  { value: "AGENCY", name: "Agency" },
  { value: "AGRICULTURE", name: "Agriculture" },
  { value: "ART_AND_DESIGN", name: "Art and Design" },
  { value: "AUTOMOTIVE", name: "Automotive" },
  { value: "CONSTRUCTION", name: "Construction" },
  { value: "CONSULTING", name: "Consulting" },
  { value: "EDUCATION", name: "Education" },
  { value: "ENGINEERING", name: "Engineering" },
  { value: "ENTERTAINMENT", name: "Entertainment" },
  { value: "FINANCIAL_SERVICES", name: "Financial Services" },
  { value: "FOOD_SERVICES", name: "Food Services (Restaurants or Fast Food)" },
  { value: "GAMING", name: "Gaming" },
  { value: "GOVERNMENT", name: "Government" },
  { value: "HEALTH_CARE", name: "Health Care" },
  { value: "INTERIOR_DESIGN", name: "Interior Design" },
  { value: "INTERNAL", name: "Internal" },
  { value: "LEGAL", name: "Legal" },
  { value: "MARKETING", name: "Marketing" },
  { value: "MINING_AND_LOGISTICS", name: "Mining and Logistics" },
  { value: "NON_PROFIT", name: "Non-Profit" },
  { value: "PUBLISHING_AND_WEB_MEDIA", name: "Publishing and Web Media" },
  { value: "REAL_ESTATE", name: "Real Estate" },
  { value: "ECOMMERCE", name: "E-Commerce" },
  { value: "TECHNOLOGY", name: "Technology" },
  { value: "TELECOMMUNICATIONS", name: "Telecommunications" },
  { value: "TRAVEL_OR_HOSPITALITY", name: "Travel or Hospitality" },
  {
    value: "WEB_DESIGNING_AND_DEVELOPMENT",
    name: "Web Designing and Development",
  },
  { value: "OTHERS", name: "Others" },
];

export const accountingPeriodChoices = [
  { value: "Jan_to_Dec", name: "January to December" },
  { value: "Apr_to_Mar", name: "April to March" },
  { value: "Jun_to_May", name: "June to May" },
  { value: "Other", name: "Other" },
];

export const accountPeriodOtherChoices = [
  { value: "Jan", name: "January" },
  { value: "Feb", name: "February" },
  { value: "Mar", name: "March" },
  { value: "Apr", name: "April" },
  { value: "May", name: "May" },
  { value: "Jun", name: "June" },
  { value: "Jul", name: "July" },
  { value: "Aug", name: "August" },
  { value: "Sep", name: "September" },
  { value: "Oct", name: "October" },
  { value: "Nov", name: "November" },
  { value: "Dec", name: "December" },
];

export const roundOffOptions = [
  { value: "no_round", key: null, label: "No Round" },
  { value: "0.05", key: "NEAREST_0_05", label: "Nearest 0.05" },
  { value: "0.10", key: "NEAREST_0_10", label: "Nearest 0.10" },
  { value: "0.25", key: "NEAREST_0_25", label: "Nearest 0.25" },
  { value: "0.50", key: "NEAREST_0_50", label: "Nearest 0.50" },
  { value: "1.00", key: "NEAREST_1_00", label: "Nearest 1.00" },
];

export const voucherTypesList = [
  {
    value: "SALE",
    label: "Sale",
  },
  {
    value: "PURCHASE",
    label: "Purchase",
  },
  {
    value: "EXPENSE",
    label: "Expense",
  },
  {
    value: "SALERETURN",
    label: "Sale Return",
  },
  {
    value: "PURCHASERETURN",
    label: "Purchase Return",
  },
  {
    value: "SaleReceipt",
    label: "Sale Receipt",
  },
  { value: "PurchasePayment", label: "Purchase Payment" },
  { value: "ExpensePayment", label: "Expense Payment" },
  { value: "SaleReturnPayment", label: "Sale Return Payment" },
  { value: "PurchaseReturnReceipt", label: "Purchase Return Receipt" },
  { value: "OtherReceipt", label: "Other Receipt" },
  { value: "OtherPayment", label: "Other Payment" },
  {
    value: "JOURNAL",
    label: "Journal",
  },

  {
    value: "STOCKJOURNALRAW",
    label: "Stock Journal Raw",
  },
  {
    value: "STOCKJOURNALPRODUCT",
    label: "Stock Journal Product",
  },
];

export const estimateStatusUnderOptions = [
  { id: 1, label: "SALE ESTIMATE", value: "SALE_ESTIMATE" },
  { id: 2, label: "SALES ORDER", value: "SALES_ORDER" },
  { id: 3, label: "PURCHASE ORDER", value: "PURCHASE_ORDER" },
  { id: 4, label: "PURCHASE REQUISITION", value: "PURCHASE_REQUISITION" },
  { id: 5, label: "GOODS RECEIPT NOTE", value: "GOODS_RECEIPT_NOTE" },
  { id: 6, label: "GOODS ISSUE ORDER", value: "GOODS_ISSUE_ORDER" },
  { id: 7, label: "RETAINER INVOICE", value: "RETAINER_INVOICE" },
  { id: 8, label: "PROFORMA INVOICE", value: "PROFORMA_INVOICE" },
];
