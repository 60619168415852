import { useQuery } from "react-query";
import api, { API_SETTINGS } from "utils/axios";

const getSettings = () => api.get(API_SETTINGS).then((res) => res.data);

export function useSettings() {
  return useQuery("settings", getSettings, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
