import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Text,
  InputGroup,
  InputLeftElement,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  useDisclosure,
  InputRightElement,
  Heading,
  HStack,
  Spacer,
  Skeleton,
  MenuButton,
  Icon,
  Menu,
  MenuList,
  MenuItem,
  useToast,
  VStack,
  Flex,
  Divider,
  Select,
} from "@chakra-ui/react";
import { FiMoreVertical, FiEdit3, FiTrash2, FiEye } from "react-icons/fi";
import { Search2Icon, CloseIcon, AddIcon } from "@chakra-ui/icons";
import { BiExport } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useKeypress from "react-use-keypress";

import AddItemForm from "../../components/AddItemForm";
import { useItems } from "hooks/Item/useItems";
import { exportItemsListReport } from "../../slices/itemreport.slice";
import checkSimiliarity from "../../utils/utils/similarityUtils";
import { useDecimalLength } from "hooks/useDecimalLength";
import { getLocalStore } from "utils/utils";
import { getStandardFormat } from "utils/utils/digitStandards";
import { useDigitStandardModel } from "hooks/useDigitStandardModel";
import { isMobile } from "react-device-detect";

export default function ItemList(props) {
  const {
    compact,
    onDelete,
    onClick,
    handleAddStockItemData,
    transactionName,
    ...rest
  } = props;
  const { data, isLoading } = useItems();

  const [type, setType] = useState("add");
  const [searchType, setSearchType] = useState("name");
  const [editValue, setEditValue] = useState(null);
  const [listData, setlistData] = useState(null);

  const inputRef = useRef(null);
  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [decimalLength] = useDecimalLength(2);
  const [digitStandardModel] = useDigitStandardModel();

  const [searchKey, setSearchKey] = useState("");

  const [altPressed, setAltPressed] = useState(false);
  const [datasInPopup, setDatasInPopup] = useState(null);

  const stockItemSearchModel = getLocalStore("STOCKITEM_SEARCH_DEFAULT_MODEL");
  const datasInItemPopupModel = getLocalStore("DATAS_IN_ITEM_POPUP_MODEL");

  useEffect(() => {
    if (stockItemSearchModel) {
      switch (stockItemSearchModel) {
        case "NAME":
          setSearchType("name");
          break;
        case "ALIAS_NAME":
          setSearchType("name_alias");
          break;
        case "BAR_CODE":
          setSearchType("bar_code");
          break;
        case "ITEM_CODE":
          setSearchType("item_code");
          break;
        case "HSN_CODE":
          setSearchType("hsn_number");
          break;
        default:
          setSearchType("name");
          break;
      }
    }
  }, [stockItemSearchModel]);

  useEffect(() => {
    setDatasInPopup(datasInItemPopupModel || null);
  }, [datasInItemPopupModel]);

  useEffect(() => {
    setlistData(data?.data.slice(0, 20));
  }, [data]);

  useEffect(() => {
    if (!isMobile) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, []);

  const handleChange = (event) => {
    let value = event.target.value;
    setSearchKey(value);
    if (value.length > 0) {
      if (searchType === "name") {
        const a = data.data.filter((name) =>
          name.name.toUpperCase().includes(value.toUpperCase())
        );
        setlistData(a.slice(0, 20));
      } else if (searchType === "name_alias") {
        const filteredData = data.data.filter(
          (item) =>
            item.name_alias &&
            item.name_alias.toUpperCase().includes(value.toUpperCase())
        );
        setlistData(filteredData.slice(0, 20));
      } else if (searchType === "bar_code") {
        const filteredData = data.data.filter(
          (item) =>
            item.bar_code &&
            item.bar_code.toUpperCase().includes(value.toUpperCase())
        );
        setlistData(filteredData.slice(0, 20));
      } else if (searchType === "hsn_number") {
        const filteredData = data.data.filter(
          (item) =>
            item.hsn_number &&
            item.hsn_number.toUpperCase().includes(value.toUpperCase())
        );
        setlistData(filteredData.slice(0, 20));
      } else if (searchType === "item_code") {
        const filteredData = data.data.filter(
          (item) =>
            item.item_code &&
            item.item_code.toUpperCase().includes(value.toUpperCase())
        );
        setlistData(filteredData.slice(0, 20));
      }
    } else {
      setlistData(data?.data.slice(0, 20));
    }
  };

  const toast = useToast();
  const openItemDetail = (item, value) => {
    // history.push({
    //   pathname: `/item-detailed-report/${value.id}/`,
    //   state: { item: item },
    // });
    window.open(`/item-detailed-report/${value.id}/`, "_blank");
  };
  const handleExportClick = async () => {
    const fileUrl = await exportItemsListReport();
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    }
  };

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const selectedItemRef = useRef(null);
  useKeypress(["Enter", "ArrowUp", "ArrowDown"], (event) => {
    const searchList = listData.slice(0, 10);
    if (!isOpen) {
      if (event.key === "Enter") {
        onClick(searchList[selectedItemIndex]);
      } else if (event.key === "ArrowUp") {
        if (selectedItemIndex - 1 >= 0) {
          setSelectedItemIndex(selectedItemIndex - 1);
        } else {
          setSelectedItemIndex(searchList.length - 1);
        }
      } else if (event.key === "ArrowDown") {
        if (selectedItemIndex + 1 < searchList.length) {
          setSelectedItemIndex(selectedItemIndex + 1);
        } else {
          setSelectedItemIndex(0);
        }
      }
      selectedItemRef.current?.scrollIntoView();
      event.stopPropagation();
      event.preventDefault();
    }
  });
  //add item modal
  useKeypress(["Alt", "c", "C"], (event) => {
    if (event.key === "Alt") {
      setAltPressed(true);
      setTimeout(() => setAltPressed(false), 1000);
    }
    if (event.key === "c" || event.key === "C") {
      if (altPressed) {
        onOpen();
      }
    }
    if (event.key !== "c" && event.key !== "Alt" && event.key !== "C") {
      event.stopPropagation();
      event.preventDefault();
    }
  });

  return (
    <>
      <Stack bg="white" width="100%" {...rest}>
        <HStack pb="1">
          <Heading size="lg">Items</Heading>
          <Spacer />
          {!isMobile ? (
            <Button
              colorScheme="blue"
              leftIcon={<AddIcon />}
              onClick={(value) => {
                setType("add");
                setEditValue(null);
                onOpen();
              }}
            >
              Create Stock Item
            </Button>
          ) : (
            <Button
              colorScheme="blue"
              leftIcon={<AddIcon />}
              onClick={(value) => {
                setType("add");
                setEditValue(null);
                onOpen();
              }}
            ></Button>
          )}
        </HStack>
        <Box pb="2">
          <InputGroup>
            <InputLeftElement
              color="gray.700"
              width={isMobile ? "6rem" : "8rem"}
              children={
                <Select
                  bg="grey.300"
                  placeholder="select"
                  value={searchType}
                  onChange={(e) => setSearchType(e.target.value)}
                >
                  <option value="name">Item name</option>
                  <option value="name_alias">Alias name</option>
                  {/* <option value="description">Item description</option> */}
                  <option value="bar_code">Bar code</option>
                  <option value="hsn_number">HSN code</option>
                  <option value="item_code">Item code</option>
                </Select>
              }
            />
            <Input
              // type="tel"
              pl={isMobile ? "6.5rem" : "9rem"}
              pr="2rem"
              placeholder="Search"
              value={searchKey}
              ref={inputRef}
              onChange={handleChange}
              inputMode="text"
            />
            {searchKey && (
              <InputRightElement
                cursor="pointer"
                children={<CloseIcon color="gray.300" />}
                onClick={() => {
                  setSearchKey("");
                  setlistData(data?.data.slice(0, 20));
                }}
              />
            )}
          </InputGroup>
        </Box>
        <Box overflowY="scroll" width="100%">
          <Skeleton isLoaded={!isLoading}>
            {listData &&
              listData.length > 0 &&
              listData.map((value, key) => (
                <VStack
                  spacing="0"
                  w="100%"
                  key={key}
                  _hover={{ bg: "gray.100" }}
                  background={key === selectedItemIndex ? "gray.100" : ""}
                  ref={key === selectedItemIndex ? selectedItemRef : null}
                >
                  <HStack w="100%">
                    <Flex
                      w="100%"
                      margin={1}
                      p={1}
                      as="button"
                      onClick={() => {
                        onClick && onClick({ ...value });
                      }}
                      justifyContent="space-between"
                    >
                      <Text size="md" noOfLines={1} align="left" maxW="80%">
                        {value.name}
                        <Text
                          // fontSize="sm"
                          color="gray.600"
                          noOfLines={1}
                          align="left"
                          fontSize="12px"
                        >
                          {value.item_code}
                        </Text>
                      </Text>
                      {datasInPopup === "PRICE_WITH_TAX" && (
                        <Text fontSize="sm" align="right">
                          {getStandardFormat(
                            [
                              "Purchase",
                              "goods-receipt-note",
                              "purchase-requesition",
                              "purchase-order",
                            ].includes(transactionName)
                              ? parseFloat(value.purchase_price_with_tax || 0)
                              : parseFloat(value.price_with_tax || 0),
                            digitStandardModel,
                            decimalLength
                          )}
                        </Text>
                      )}
                      {datasInPopup === "PRICE_WITHOUT_TAX" && (
                        <Text fontSize="sm" align="right">
                          {getStandardFormat(
                            [
                              "Purchase",
                              "goods-receipt-note",
                              "purchase-requesition",
                              "purchase-order",
                            ].includes(transactionName)
                              ? parseFloat(
                                  value.purchase_price_without_tax || 0
                                )
                              : parseFloat(value.price || 0),
                            digitStandardModel,
                            decimalLength
                          )}
                        </Text>
                      )}
                      {datasInPopup === "BOTH_PRICE" && (
                        <Text fontSize="sm" align="right">
                          {getStandardFormat(
                            [
                              "Purchase",
                              "goods-receipt-note",
                              "purchase-requesition",
                              "purchase-order",
                            ].includes(transactionName)
                              ? parseFloat(value.purchase_price_with_tax || 0)
                              : parseFloat(value.price_with_tax || 0),
                            digitStandardModel,
                            decimalLength
                          )}
                          <Text fontSize="10px" align="right">
                            {getStandardFormat(
                              [
                                "Purchase",
                                "goods-receipt-note",
                                "purchase-requesition",
                                "purchase-order",
                              ].includes(transactionName)
                                ? parseFloat(
                                    value.purchase_price_without_tax || 0
                                  )
                                : parseFloat(value.price || 0),
                              digitStandardModel,
                              decimalLength
                            )}
                          </Text>
                        </Text>
                      )}
                      {datasInPopup === "QUANTITY" && (
                        <Text fontSize="sm" align="right">
                          {" "}
                        </Text>
                      )}
                      {datasInPopup === "EMPTY" && (
                        <Text fontSize="sm" align="right">
                          {" "}
                        </Text>
                      )}
                      {datasInPopup === "TAX%" && (
                        <Text fontSize="sm" align="right">
                          {parseInt(value.tax)}%
                        </Text>
                      )}
                      {datasInPopup === "HSN" && (
                        <Text fontSize="sm" align="right">
                          {value.hsn_number}
                        </Text>
                      )}
                      {datasInPopup === "BAR_CODE" && (
                        <Text fontSize="sm" align="right">
                          {value.bar_code}
                        </Text>
                      )}
                      {datasInPopup === "ITEM_CODE" && (
                        <Text fontSize="sm" align="right">
                          {value.item_code}
                        </Text>
                      )}
                      {datasInPopup === "MRP" && (
                        <Text fontSize="sm" align="right">
                          {getStandardFormat(
                            parseFloat(value.maximum_retail_price || 0),
                            digitStandardModel,
                            decimalLength
                          )}
                        </Text>
                      )}
                    </Flex>
                    <VStack maxW="30px">
                      <Menu spacing="2">
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<Icon as={FiMoreVertical} />}
                          size="xs"
                          variant="solid"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                        <MenuList>
                          <MenuItem
                            icon={<Icon as={FiEye}></Icon>}
                            onClick={(event) => {
                              //event.stopPropagation();
                              openItemDetail(true, value, "view");
                            }}
                          >
                            View
                          </MenuItem>
                          <MenuItem
                            icon={<Icon as={FiEdit3}></Icon>}
                            onClick={(event) => {
                              event.stopPropagation();
                              setType("edit");
                              setEditValue(value);
                              onOpen();
                            }}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem
                            color="red.500"
                            icon={<Icon as={FiTrash2}></Icon>}
                            onClick={(event) => {
                              event.stopPropagation();
                              onDelete(value);
                            }}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </VStack>
                  </HStack>
                  <Divider />
                </VStack>
              ))}
          </Skeleton>
        </Box>
      </Stack>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <AddItemForm
              type={type}
              editValue={editValue}
              changeType={() => {
                setType("add");
              }}
              onSuccess={onClose}
              handleAddStockItemData={handleAddStockItemData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
