import styled from 'styled-components';

const MessageContainer = styled.div`
color: ${({ type, theme }) =>
    type === 'error'
      ? 'red'
      : 'black'};
  width: 100%;
  p {
    font-size: 12px;
    text-transform: none;
    font-weight: normal;
  }
`;

export default MessageContainer;
