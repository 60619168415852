import { useMutation, useQueryClient } from "react-query";
import api, { API_ITEM_UNIT_LIST_CREATE } from "utils/axios";

const createItemUnit = (ItemUnit) =>
  api.post(API_ITEM_UNIT_LIST_CREATE, ItemUnit).then((res) => res.data);

export function useCreateItemUnit() {
  const queryClient = useQueryClient();
  return useMutation(createItemUnit, {
    onSuccess: () => queryClient.refetchQueries("itemunit"),
  });
}
